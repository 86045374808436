import { useState } from 'react'

import BuildingCard from './Card'

import css from '../style.module.css'

export default function BuildingCards({ buildings })
{

    const [ buildIndex, setBuildIndex ] = useState(0)

    const pages = []
    for (let index = 1; index <= buildings.length; index++) {
        pages.push(index)
    }

    const builds = buildings[buildIndex]

    return (
        <div>
            <div className={css.title}>
                {builds?.data?.[0]?.project_name}, {builds?.data?.[0]?.apartment_name}
            </div>
            <div className={css.cardContainer}>
                {
                    builds.data.map(
                        (building, idx) =>
                        {
                            return (
                                <BuildingCard
                                    key={idx}
                                    building={building}
                                    apartId={builds.id}
                                />
                            )
                        }
                    )
                }
            </div>
            {
                buildings.length > 1
                ?
                    <>
                        <hr />
                        <div className='d-flex'>
                            <div>
                                Нийт: {buildings.length}
                            </div>
                            <div className='ms-auto flex-row d-flex'>
                                {
                                    pages.map(
                                        (page, idx) =>
                                        {
                                            return (
                                                <div
                                                    role={"button"}
                                                    onClick={() => setBuildIndex(page - 1)}
                                                    className={`ps-1 pe-1 hover-primary ${buildIndex + 1 === page ? "text-primary" : ""}`}
                                                >
                                                    {page}
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </div>
                        </div>
                    </>
                :   null
            }
        </div>
    )
}
