import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Col, Container, Row } from 'react-bootstrap';

import css from '../style.module.css'

import DanAuth from 'utils/dan'

const RoleSignin = ({ orgPath, danPath }) =>
{
    const navigate = useNavigate()

    const handleDan = async () =>
    {
        const dan = new DanAuth()

        const payload = [
            {
                'services' : [
                    "WS100101_getCitizenIDCardInfo",                                    // сервис код
                ],
                'wsdl' : "https://xyp.gov.mn/citizen-1.3.0/ws?WSDL",                    // wsdl зам
            }
        ]
        /** dan руу оруулдаг URL ийг үүсгэх нь */
        const uri = dan.buildRedirectUri(payload)
        window.open(uri, '_blank');
    }

    return (
        <Container fluid>
			<Row>
				<Col lg={6} sm={12} className={`${css.loginBanner} p-0`}></Col>
				<Col lg={6} sm={12} className={`${css.customCenter} d-flex align-items-center`}>
					<div className={css.roleWrapper}>
                        <Row className="w-100">
                            <Col md={6}>
                                <div className={css.card1} onClick={handleDan}>
                                    <i className="fa-regular fa-user"></i>
                                    <h3>Иргэн</h3>
                                    <div className={css.goCorner}>
                                        <div className={css.goArrow}>
                                            →
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={css.card1} onClick={() => navigate(orgPath)}>
                                    <i className="fa-regular fa-building"></i>
                                    <h3>Байгууллага</h3>
                                    <div className={css.goCorner}>
                                        <div className={css.goArrow}>
                                            →
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
					</div>
				</Col>
			</Row>
		</Container>
    );
}

export default RoleSignin;
