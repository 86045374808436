import React from 'react';
import { Nav } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import MainNavLink from 'components/main/NavLink';

const ProjectTabs = () => {
    const { id } = useParams();

    return (
		<Nav fill variant="tabs" className="tabNav-tabs" style={{ marginBottom: "1px" }}>
			<Nav.Item>
				<MainNavLink className="tabNav-link" activeClassName="tabActive" exact to={`/gov/project/form/${id}/details/`}>
					Дэлгэрэнгүй
				</MainNavLink>
			</Nav.Item>
			{/* <Nav.Item>
				<MainNavLink className="tabNav-link" activeClassName="tabActive" to={"map/"}>
					Газрын зураг
				</MainNavLink>
			</Nav.Item> */}
			<Nav.Item>
				<MainNavLink className="tabNav-link" activeClassName="tabActive" to={"apartment/"}>
					Орон сууц
				</MainNavLink>
			</Nav.Item>
			<Nav.Item>
				<MainNavLink className="tabNav-link" activeClassName="tabActive" to={"residence/"}>
					Байрны зохион байгуулалт
				</MainNavLink>
			</Nav.Item>
			<Nav.Item>
				<MainNavLink className="tabNav-link" activeClassName="tabActive" to={"building/"}>
					Байр
				</MainNavLink>
			</Nav.Item>
		</Nav>
	)
}

export default ProjectTabs;
