import { useState } from "react";

import useApi from "hooks/useApi";
import { dataToValueLabel } from "utils/format";

const { createContext } = require("react");

export const ConfigContext = createContext()

const initConfig = {}
export default function ConfigContextProvider(props)
{
    /** Сайтын тохиргоонуудыг халгалах state */
    const [configs, setConfigs] = useState(initConfig)
    const configApi = useApi({ isDisplayToast: false }).config
    const actionConfigApi = useApi({ isDisplayToast: true }).config

    const [ roomChoices, setRoomChoices ] = useState([])

    const roomApi = useApi().roomType

    const fixData = (data) =>
    {
        const valueOfConfigs = data.reduce(
            (prevValue, currentValue) =>
            {
                prevValue[currentValue.name] = currentValue.value
                return prevValue
            },
            {}
        )
        return valueOfConfigs
    }

    /** Тохиргоог back аас авах */
    const getConfigs = async () =>
    {
        /** Back аас авах config үүдийн нэрийн жагсаалт */
        const names = 'NAME,AGE'
        const { success, data, error } = await configApi.get(names).catch(err => err)
        if (success)
        {
            const valueOfConfigs = fixData(data)
            setConfigs(valueOfConfigs)
        }
        else
        {
            setConfigs({})
        }

    }

    const getConfig = async (names, setValue=true) =>
    {
        const { success, data, error } = await configApi.get(names).catch(err => err)
        if (success)
        {
            const valueOfConfigs = fixData(data)
            if (setValue)
            {
                setConfigs((prevValue) => ({ ...prevValue, ...valueOfConfigs }))
            }
            return valueOfConfigs
        }
        return {}
    }

    /**  */
    const updateConfig = async (body, needConvert=false) =>
    {
        let newBody = body
        if (needConvert)
        {
            let parsed = []
            Object.keys(body).forEach(
                (el) =>
                {
                    parsed.push(
                        {
                            "name": el,
                            "value": body[el]
                        }
                    )
                }
            )
            newBody = parsed
        }

        const rsp = await actionConfigApi.update(newBody).catch(err => err)
        if (rsp.success)
        {
            const valueOfConfigs = fixData([newBody])
            setConfigs((prevValue) => ({ ...prevValue, ...valueOfConfigs }))
            return rsp
        }
        return rsp
    }

    /** Өрөөний сонголтуудыг татаж авах нь */
    const getRoomChoices = async () =>
    {
        const { success, data, error } = await roomApi.get()
        if (success)
        {
            const selectdata = dataToValueLabel(data, 'id', 'name')
            setRoomChoices(selectdata)
        }
    }

    return (
        <ConfigContext.Provider
            value={{
                configs,
                getConfigs,
                getRoomChoices,
                roomChoices,
                getConfig,
                updateConfig,
            }}
        >
            {props.children}
        </ConfigContext.Provider>
    )
}
