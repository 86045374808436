import React from 'react';
import { Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import ImageFetch from 'components/main/Image';

import { getRandomInt } from 'utils';
import { quantityFormat, timeZoneToDateString } from 'utils/format';

import css from '../style.module.css'

const NewsCardGrid = ({ title='Иргэний өмчлөх эрхийн тухай', date='2021.11.17', views=getRandomInt(10000), id, image, desc, pageId }) => {

	const navigate = useNavigate()

	const navigater = () =>
	{
		navigate(`${pageId}/${id}/`)
	}

    return (
		<Col md={6} className={css.col}>
			<div className={css.gridCard}>
				<div className={css.imageContainerZ}>
					<ImageFetch onClick={navigater} className={css.image} src={image} />
				</div>
				<div onClick={navigater} className={css.newsTitle}>
					{title}
				</div>
				<div className={css.gridDesc}>{desc}</div>
				<div className={css.numberContainer}>
					<div className={css.newsSmallText}>
						<i className={`far fa-clock tx-blue ${css.txBlue}`} /> {timeZoneToDateString(date)}
					</div>
					<div className={css.newsSmallText}>
						<i className={`far fa-eye tx-blue ${css.txBlue}`} /> {quantityFormat(views)}
					</div>
				</div>
				<div onClick={navigater} className={css.readMore}>
					Дэлгэрэнгүй <i className="far fa-angle-double-right"></i>
				</div>
			</div>
		</Col>
	)
}

export default NewsCardGrid;
