import useMapContext from "hooks/useMapContext"
import { useEffect, useRef } from "react"
import { useMemo, useState } from "react"

import css from './style.module.css'
import { FILTER_PERPAGE, FILTER_MAXPAGE } from "context/special/mapContext"
import { NO_IMAGES } from "utils/consts"

import { styleClusterPoint, selectClusterFeatureStyle } from "hooks/map/helper"

export default function SearchResults()
{
    const { filterResult, allFilters, allFiltersToMap, cMapState, setSelectedFeature } = useMapContext()
    const initPagination = {
        page: 1,
        perPage: FILTER_PERPAGE,
        maxPage: filterResult.maxPage || FILTER_MAXPAGE,
    }
    const [ pagination, setPagination ] = useState(initPagination)

    const didMount = useRef(false);
    const paginationDots = "..."
    const showStep = 2

    useEffect(
        () =>
        {
            if (didMount.current) {
                allFilters['pPage'] = pagination.page || 1
                allFilters['pPerPage'] = pagination.perPage
                allFiltersToMap("btn")
            } else {
                didMount.current = true;
            }
        },
        [pagination.page]
    )

    useEffect(
        () =>
        {
            // хайгаад утга ирсэн үе
            setPagination(
                (prev) => (
                    {
                        ...prev,
                        page: filterResult.type === "btn" ? prev.page : 1,
                        maxPage: filterResult?.maxPage
                    }
                )
            )
        },
        [filterResult]
    )

    /** Тухайн хуудас руу үсрэх нь */
    const jumpPage = (toPage) =>
    {
        if (toPage === paginationDots)
        {
            return
        }

        setPagination(
            (prev) => (
                {
                    ...prev,
                    page: toPage
                }
            )
        )
    }

    /** хамгийн эхний хуудас руу үсрэх */
    const firstPage = () =>
    {
        setPagination(
            (prev) => (
                {
                    ...prev,
                    page: 1
                }
            )
        )
    }

    /** өмнөх хуудас руу үсрэх */
    const prevPage = () =>
    {
        setPagination(
            (prev) => ({
                ...prev,
                page: Math.max(1, prev.page - 1)
            })
        )
    }

    /** дараачийн хуудас руу үсрэх */
    const nextPage = () =>
    {
        setPagination(
            (prev) => ({
                ...prev,
                page: Math.min(prev.maxPage, prev.page + 1)
            })
        )
    }

    /** сүүлийн хуудас руу үсрэх нь */
    const lastPage = () =>
    {
        setPagination(
            (prev) => ({
                ...prev,
                page: prev.maxPage
            })
        )
    }

    /** тухайн сонгосон барилга руу нисэж очих */
    const jumpToApartment = (event, sdi, isEvent) =>
    {
        if (!isEvent)
        {
            cMapState.removeLayer("building-point")
            cMapState
                .map
                .getInteractions()
                .forEach(
                    (interaction) =>
                    {
                        if (interaction && interaction.get("name") === "building-point")
                        {
                        }
                    }
                )
        }

        const pointString = sdi.point_geojson
        const point = JSON.parse(pointString)

        //  coord ийг хөрвүүлж тухайн коорд руу үсэргэх
        const coords = cMapState.transformCoordinate(point.coordinates)
        // const coords = point.coordinates
        cMapState.animateToCoordinate(coords, { zoom: 18 })

        if (!isEvent)
        {
            const feature = cMapState.createPoint(coords, sdi)
            const vSource = cMapState.createVectorSource([feature])
            const clusterSource = cMapState.createCluster(vSource)
            const { vectorLayer, vectorSource } = cMapState.addVectorLayer([feature], clusterSource, "building-point", styleClusterPoint)
            cMapState.createSelect(
                "click",
                (event) => jumpToApartment(event, sdi, true),
                selectClusterFeatureStyle,
                [vectorLayer],
                [],
                "building-point",
            )
        }

        //  тухайн coord дээр overlay ийг context оор дамжуулж overlay доторх датаг шинэчлэх нь
        cMapState.displayOverlay(coords)
        const convertedData = [
            {
                id: sdi.id,
                apartmentId: (typeof sdi.apartment === "object") ? sdi.apartment.id : sdi.apartment
            }
        ]
        setSelectedFeature({ feature: convertedData, state: "clicked" })
    }

    const result = useMemo(
        () =>
        {
            if (!filterResult.data.length)
            {
                return <div className="d-flex justify-content-center align-items-center"><span>Хайлтын үр дүн байхгүй байна</span></div>
            }

            const pages = []
            if (pagination.page !== 1)
            {
                pages.push(pagination.page - 1)
            }
            const maxToo = Math.min(pagination.page + showStep - 1, pagination.maxPage)
            for (let index = pagination.page; index <= maxToo; index++)
            {
                pages.push(index)
            }

            if (pagination.page > showStep)
            {
                pages.unshift(paginationDots)
            }

            if (maxToo !== pagination.maxPage)
            {
                pages.push(paginationDots)
            }

            return (
                <div>
                    {
                        filterResult.data.map(
                            (sdi, idx) =>
                            {
                                return (
                                    <div className={css.item} key={idx} onClick={() => jumpToApartment(null, sdi)}>
                                        <div>
                                            <img
                                                className={css.zurag}
                                                src={`${process.env.REACT_APP_SERVER_URL}${sdi.apartment.zurag}`}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null
                                                    currentTarget.src = NO_IMAGES['53x53']
                                                }}
                                                width={50}
                                                height={50}
                                            />
                                        </div>
                                        <div className={css.itemInfo}>
                                            <div>
                                                {sdi.apartment.name}
                                            </div>
                                            <div className={css.location}>
                                                {sdi.apartment.unit1}, {} {sdi.apartment.unit2}, {} {sdi.apartment.unit3}, {} {sdi.apartment.location}
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end align-items-center ms-2">
                                            <i className="fas fa-arrow-right"></i>
                                        </div>
                                    </div>
                                )
                            }
                        )
                    }

                    <div className="d-flex justify-content-center">
                        <span className={css.pItem} onClick={firstPage}>
                            <i className="far fa-chevron-double-left"></i>
                        </span>
                        <span className={css.pItem} onClick={prevPage}>
                            <i className="far fa-chevron-left"></i>
                        </span>
                        {
                            pages.map(
                                (page, idx) =>
                                {
                                    return (
                                        <span
                                            key={idx}
                                            className={`${css.pItem} ${pagination.page === page ? css.active : ""}`}
                                            onClick={() => jumpPage(page)}
                                        >
                                            {page}
                                        </span>
                                    )
                                }
                            )
                        }
                        <span className={css.pItem} onClick={nextPage}>
                            <i className="far fa-chevron-right"></i>
                        </span>
                        <span className={css.pItem} onClick={lastPage}>
                            <i className="far fa-chevron-double-right"></i>
                        </span>
                    </div>

                </div>
            )

        },
        [filterResult, pagination]
    )

    const filterCount = useMemo(
        () =>
        {
            return filterResult.total || 0
        },
        [filterResult]
    )

    return (
        <div className="pb-3">
            <h6 className='p-1'>Хайлтын үр дүн ({filterCount})</h6>
            {result}
        </div>
    )
}
