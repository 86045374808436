import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';
import useAuth from 'hooks/useAuth';

import { subAgendaValidate } from 'utils/validate';
import { dataToValueLabel, timeZoneToDateString } from 'utils/format';

/** дэд арга хэмжээ дээр create, update хийнэ */
const SubAgendaAction = () => {
    const [ inputValues, setInputValues ] = useState('');
    const [ budgetTypes, setBudgetType ] = useState([]);
    const [ companies, setCompanies ] = useState([])
    const [ projects, setProjects ] = useState([])
    const [ unitMeasures, setUnitMeasures ] = useState([])
    const [ isFromSystem, setFromSystem ] = useState(false)

    const { id, agendaId, programId } = useParams()
    const navigate = useNavigate();

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const { userDetail } = useAuth()
    const budgetApi = useApi().budgetType
    const subAgendaApi = useApi().subAgenda
    const companyApi = useApi().company
    const unitMeasureApi = useApi().unitMeasure

    /** true бол create, false бол update буцаана */
    const isCreate = id === 'create'

    /** budgetType, agenda, program уудыг авна */
    useEffect(
        async () =>
        {
            const budgetRsp = await fetchData(budgetApi.get())
            const companyRsp = await fetchData(companyApi.get())
            const subAgendaRsp = await fetchData(subAgendaApi.getProjects())
            const unitMeasureRsp = await fetchData(unitMeasureApi.get())
            if (budgetRsp.success && companyRsp.success && subAgendaRsp.success && unitMeasureRsp.success)
            {
                setBudgetType(dataToValueLabel(budgetRsp.data, 'id', 'name', null, "is_from_system"))
                setCompanies(dataToValueLabel(companyRsp.data, "id", "company", null, "is_from_system"))
                setProjects(dataToValueLabel(subAgendaRsp.data, "id", "project_name", null, "is_from_system"))
                setUnitMeasures(dataToValueLabel(unitMeasureRsp.data, "id", 'name', null, "is_from_system"))
            }
        },
        []
    )

    /** create, update гэдгээс хамаарч утга авна */
    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data, error } = await fetchData(subAgendaApi.getOne(programId, agendaId, id))
                if (success)
                {
                    setInputValues(data)
                    setFromSystem(data.units.is_from_system)
                }
            }
        },
        []
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        formData.agenda = agendaId
        formData.hutulbur = programId
        formData.end_date = timeZoneToDateString(formData.end_date, false)
        formData.currentstatus = !isFromSystem ? parseFloat(formData.currentstatus) : 0
        formData.basestatus = parseFloat(formData.basestatus)
        formData.futurestatus = parseFloat(formData.futurestatus)
        if (isCreate)
        {
            // үүсгэх үед
            const { success, data, errors } = await fetchData(subAgendaApi.post(programId, agendaId, formData)).catch((error) => error)
            if (success) return navigate(-1)
            else return errors
        }
        // засах үед
        const { success, data, errors } = await fetchData(subAgendaApi.put(programId, agendaId, id, formData)).catch((error) => error)
        if (success) navigate(-1)
        else return errors
    }

    const handleMeasure = (value) =>
    {
        setFromSystem(value.extraValue)
    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "subagenda",
                type: "textArea",
                placeholder: "Шалгуур үзүүлэлтийн нэр оруулна уу",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
                label: {
                    text: "Шалгуур үзүүлэлт: ",
                    empty: false,
                },
            },
            {
                inputType: "select",
                isMulti: true,
                registerName: "project",
                disabled: isLoading,
                options: projects,
            },
            userDetail.user_role.is_tosk &&
            {
                inputType: "select",
                registerName: "hariutsah_baiguullaga",
                disabled: isLoading,
                options: companies,
                async: true,
                isMulti: true,
                helperText: "Эхний 2 тэмдэгтийг оруулна уу",
            },
            {
                inputType: "input",
                registerName: "amount",
                type: "number",
                placeholder: "Хөрөнгө оруулна уу",
                inputGroup: {
                    start: <i className="fa-brands fa-bimobject"></i>
                },
                disabled: isLoading,
                label: {
                    text: "Хөрөнгө: ",
                    empty: false,
                },
                step: '0.1'
            },
            {
                inputType: "select",
                options: unitMeasures,
                registerName: "units",
                type: "text",
                placeholder: "Хэмжих нэгж сонгоно уу",
                disabled: isLoading,
                onChange: handleMeasure,
                label: {
                    text: "Хэмжих нэгж: ",
                    empty: false,
                },
            },
            {
                inputType: "select",
                isMulti: true,
                registerName: "budget_type",
                placeholder: "Xөрөнгө оруулалтын төрөл сонгоно уу",
                disabled: isLoading,
                options: budgetTypes,
                label: {
                    text: "Xөрөнгө оруулалтын төрөл: ",
                    empty: false,
                },
            },
            {
                inputType: "datePicker",
                registerName: "end_date",
                placeholder: "Дуусах хугацаа оруулна уу",
                disabled: isLoading,
                label: {
                    text: "Дуусах хугацаа: ",
                    empty: false,
                },
            },
            {
                inputType: "input",
                registerName: "basestatus",
                type: "text",
                placeholder: "Суурь түвшин оруулна уу",
                inputGroup: {
                    start: <i className="fas fa-line-height"></i>
                },
                colMd: isFromSystem ? 6 : 4,
                disabled: isLoading,
                label: {
                    text: "Суурь түвшин: ",
                    empty: false,
                },
            },
            !isFromSystem
            &&
                {
                    inputType: "input",
                    registerName: "currentstatus",
                    type: "text",
                    inputGroup: {
                        start: <i className="fas fa-line-height"></i>
                    },
                    disabled: isLoading,
                    colMd: 4,
                    helperText: "Одоогийн түвшинийг системээс авах боломжгүй тул гараас оруулна уу"
                },
            {
                inputType: "input",
                registerName: "futurestatus",
                type: "text",
                placeholder: "Хүрэх түвшин оруулна уу",
                inputGroup: {
                    start: <i className="fal fa-shekel-sign"></i>
                },
                disabled: isLoading,
                label: {
                    text: "Хүрэх түвшин: ",
                    empty: false,
                },
                colMd: isFromSystem ? 6 : 4,
            },
            isFromSystem
            && {
                component: <div className='col-md-12 justify-content-center align-items-center mb-0'>
                    <Alert variant={"warning"}>
                        <i class="fas fa-exclamation-triangle"></i> Одоогийн түвшинийг системээс бодох тул гараас оруулах шаардлагагүй
                    </Alert>
                </div>
            }
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "all",
            yupValidate: subAgendaValidate,
        },
    }

    return (
        <CForm inputValues={inputValues} form={form}/>
    );
}

export default SubAgendaAction;
