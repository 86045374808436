import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Pagination, Form, Dropdown, Row, Col } from 'react-bootstrap'

import useMobile from 'hooks/useMobile'

import { range } from 'utils/array'
import { setOptions } from 'utils'

import './style.css'
import CButton from 'components/main/Button'
import { MAIN_BLUE } from 'utils/consts/colors'

const initConfig = {
    container: {
        isShow: true,
        className: ""
    },
    checkboxActionBtn: {
        className: "containerPages",
        isShow: true,
        position: "center",
        order: 1,
    },
    containerPages: {
        className: "containerPages",
        isShow: true,
        position: "center",
        order: 2,
    },
    containerPerPage: {
        className: "containerPerPage",
        isShow: true,
        position: "center",
        order: 3,
    },
    containerPagination: {
        className: "containerPagination",
        isShow: true,
        position: "center",
        order: 4,
    },
    containerJumpPage: {
        className: "containerJumpPage",
        isShow: true,
        position: "center",
        order: 5,
    },
}
export default function CPagination({
    page,
    onPageChange,
    perPage,
    maxPage,
    onPerPageChange,
    isLoading,
    paginationConfig,
    checkboxButton,
    checkedList
})
{

    const { isMobile } = useMobile()

    const ref = useRef(null)

    //  хамгийн бага хуудас
    const minPage = 1
    const defPerPage = 20
    const perPages = [10, 20, 50, 75, 100]
    const showStep = 2
    const paginationDots = "..."

    const [ cMaxPage, setMaxPage ] = useState(maxPage ? maxPage : minPage)  //  хамгийн их хуудас
    const [ selectedPage, setSelectedPage ] = useState({ value: page ? page : minPage, canUpdate: false })  //  яг одоо сонгогдсон байгаа хуудас
    const [ cPerPage, setPerPage ] = useState(perPage ? perPage : defPerPage)  //  яг одоо сонгогдсон байгаа хуудас
    const [ jumpPage, setJumpPage ] = useState(selectedPage.value) /** үсрэх хуудасны state */
    const [ configs, setConfigs ] = useState(initConfig)

    useEffect(
        () =>
        {
            if (!Object.keys(paginationConfig)) return
            const newConfig = setOptions(initConfig, paginationConfig)
            setConfigs(newConfig)
        },
        [paginationConfig]
    )


    /**
     * хуудас солигдох болгонд ажиллах функц
     */
    useEffect(() => {
        if (isLoading) return
        if (onPageChange && selectedPage.canUpdate)
        {
            onPageChange(
                selectedPage.value,
                //  хайсны дараагаар input ийг focus болгох зорилготой
                () =>
                {
                    if (selectedPage.value !== jumpPage)
                    {
                        setJumpPage(selectedPage.value)
                    }
                    ref?.current?.focus()
                }
            )
        }
    }, [selectedPage])

    /**
     * Шинэ дата ирэх болгонд maxPage ийг авах
     */
    useEffect(() => {
        if (isLoading) return
        if (maxPage)
        {
            setMaxPage(maxPage)
        }
    }, [maxPage])

    /**
     * Per page солигдох болгонд perpage утгийг буцаах нь
     */
    const updateDataChangePerPage = (perPage) =>
    {
        if (isLoading) return
        if (onPerPageChange)
        {
            onPerPageChange(
                perPage,
                (newConfig) =>
                {
                    /** Өөрлчөгдсөн хуудас */
                    const newPage = parseInt(newConfig.page)
                    /** Өөрчдлөгсөн хуудас нь одоо сонгодсон хуудаснаас өөр байх юм бол set хийх */
                    if (selectedPage.value !== newPage)
                    {
                        setSelectedPage({ value: newPage, canUpdate: false })
                    }
                }
            )
        }
    }

    /**
     *  Хуудасны дугаар дарагдах үед
     * @param {number} page дарагдсан хуудасны дугаар
     */
    const onClickPage = (page) =>
    {
        if (isLoading) return
        setSelectedPage({ value: page, canUpdate: true })
    }

    const prevPage = () =>
    {
        const newPage = selectedPage.value
        if (newPage === 1) return
        if (isLoading) return
        setSelectedPage({ canUpdate: true, value: newPage - 1 })
    }

    const nexPage = () =>
    {
        const newPage = selectedPage.value
        if (newPage === maxPage) return
        if (isLoading) return
        setSelectedPage({ canUpdate: true, value: selectedPage.value + 1 })
    }

    /** Тухайн бичсэн хуудсыг хадгалах */
    const setPage = (page) =>
    {
        if (cMaxPage < page) return
        if (isLoading) return
        setSelectedPage({ canUpdate: true, value: page })
    }

    const handleJumpPage = (jumpPage) =>
    {
        if (jumpPage < minPage) return
        if (cMaxPage < jumpPage) return
        if (isLoading) return
        setJumpPage(isNaN(jumpPage) ? "" : jumpPage)
        if (isNaN(jumpPage)) return
        setSelectedPage({ canUpdate: true, value: jumpPage })
    }

    /**
     *  Хуудасний дугааруудыг харуулах нь
     */
    const displayPageNumbers = () =>
    {
        /** Хамгийн захын 2 талыг сумыг дарахад үсрэх алхам */
        const ultraMoreStep = 2
        /** Одоо байгаа хуудас захаасаа хэр их алхамын наана байж цэгийг алгах болгох эсэх */
        const dotBolonZahHurtelZai = 2

        /** Одоо байгаа хуудаснаас хэд хүртэл харуулах хуудасны тоо */
        let toPage = selectedPage.value + showStep
        if ((toPage + dotBolonZahHurtelZai) > cMaxPage)
        {
            toPage = cMaxPage
        }

        const isPushedMaxPage = toPage === cMaxPage
        const isNegeesHoldson = (minPage + showStep + dotBolonZahHurtelZai) <= selectedPage.value

        /** Нийт хуудасны дугаарын эхлэх дугаар */
        let startPage = !isNegeesHoldson ? 1 : selectedPage.value - showStep

        /** Нийт хуудасны дугаарыг авах нь */
        const pages = range(startPage, toPage)
        /** double arrow prev дээр дарахад очих хуудас */
        const ultraPrev = Math.max(1, selectedPage.value - ultraMoreStep)
        /** double arrow next дээр дарахад очих хуудас */
        const ultraNext = Math.min(cMaxPage, selectedPage.value + ultraMoreStep)

        const dots = (
            <Pagination.Item
                disabled={true}
            >
                {paginationDots}
            </Pagination.Item>
        )

        /** double arrow */
        const first = (
            <Pagination.First
                onClick={() => setPage(ultraPrev)}
                disabled={selectedPage.value === minPage || isLoading}
            />
        )

        /* Хуудасны өмнө нь харагдах < */
        const prev = (
            <Pagination.Prev
                onClick={prevPage}
                disabled={selectedPage.value === minPage || isLoading}
            />
        )
        /* хамгийн их хуудас руу тулаагүй байх юм бол хамгийн их хуудсыг харуулах */
        const minBtn = (
            isNegeesHoldson
            &&
                <>
                    <Pagination.Item
                        disabled={isLoading}
                        onClick={() => setPage(minPage)}
                    >
                        {minPage}
                    </Pagination.Item>
                    {dots}
                </>
        )

        /* Хуудасны ард нь харагдах > */
        const next = (
            <Pagination.Next
                onClick={nexPage}
                disabled={selectedPage.value === cMaxPage || isLoading}
            />
        )

        const last = (
            <Pagination.Last
                onClick={() => setPage(ultraNext)}
                disabled={selectedPage.value === cMaxPage || isLoading}
            />
        )

        /** Утсаар биш байх юм бол том pagination */
        if (!isMobile)
        {
            return (
                <>
                    {first}
                    {prev}
                    {minBtn}
                    {/* Хуудасны дугаарууд */}
                    {
                        pages.map(
                            (page, idx) =>
                            {
                                return (
                                    <Pagination.Item
                                        disabled={isLoading}
                                        key={idx}
                                        onClick={() => onClickPage(page)}
                                        active={selectedPage.value === page}
                                    >
                                        {page}
                                    </Pagination.Item>
                                )
                            }
                        )
                    }
                    {/* хамгийн их хуудас руу тулаагүй байх юм бол хамгийн их хуудсыг харуулах */}
                    {
                        !isPushedMaxPage
                        &&
                            <>
                                {dots}
                                <Pagination.Item
                                    disabled={isLoading}
                                    onClick={() => setPage(cMaxPage)}
                                >
                                    {cMaxPage}
                                </Pagination.Item>
                            </>
                    }
                    {next}
                    {last}
                </>
            )
        }
        /** утсаар орвол */
        else
        {
            return (
                <>
                    {first}
                    {prev}
                    {next}
                    {last}
                </>
            )
        }

    }

    const displayNumber = useMemo(
        displayPageNumbers,
        [cMaxPage, selectedPage, isLoading, isMobile]
    )

    const displayDetail = useMemo(
        () =>
        {
            return (
                <small className="text-gray">
                    Хуудас {selectedPage.value} - {cMaxPage}
                </small>
            )
        },
        [selectedPage, cMaxPage]
    )

    /** Rows per page солигдох үед утгыг state -д хадгалах */
    const onChangePerPage = (newPerPage) =>
    {
        const parsedPerPage = parseInt(newPerPage)
        setPerPage(parsedPerPage)
        updateDataChangePerPage(parsedPerPage)
    }

    const displayRows = useMemo(
        () =>
        {
            return (
                <Dropdown
                    className='jagsaah'
                    onSelect={onChangePerPage}
                >
                    <Dropdown.Toggle
                        disabled={isLoading}
                    >
                        {cPerPage}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {
                            perPages.map((_perPage, idx) => {
                                return (
                                    <Dropdown.Item
                                        key={idx}
                                        eventKey={_perPage}
                                        disabled={isLoading}
                                        active={_perPage + "" === cPerPage + ""}
                                    >
                                        {_perPage}
                                    </Dropdown.Item>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            )
        },
        [cPerPage, isLoading]
    )

    const displayInput = useMemo(
        () =>
        {
            return (
                <Form.Control
                    ref={ref}
                    value={jumpPage}
                    disabled={isLoading}
                    onChange={(e) => handleJumpPage(e.target.valueAsNumber)}
                    style={{
                        width: '55px'
                    }}
                    type="number"
                    step={1}
                    className="go_to_page form-control-sm"
                />
            )
        },
        [jumpPage, ref]
    )

    const displayCheckBoxBtn = useMemo(
        () =>
        {
            if(!checkboxButton?.show) return
            if (checkedList.isAll)
				return (
					<Col
						lg={2}
						md={6}
						sm={6}
						xs={6}
						className={`order-${configs.checkboxActionBtn.order} mb-md-2 mb-lg-0 ${configs.checkboxActionBtn.className}`}>
						<div
							className={`d-flex flow-row align-items-center justify-content-${configs.checkboxActionBtn.position}`}
							style={{ height: "100%" }}>
							<CButton
								style={{ backgroundColor: MAIN_BLUE }}
								onClick={() => checkboxButton.onClick && checkboxButton.onClick()}
								title={checkboxButton.btnText ? checkboxButton.btnText : "Үйлдэл"}
							/>
						</div>
					</Col>
				)
            if (checkedList.checked.length !== 0)
				return (
					<Col
						lg={2}
						md={6}
						sm={6}
						xs={6}
						className={`order-${configs.checkboxActionBtn.order} mb-md-2 mb-lg-0 ${configs.checkboxActionBtn.className}`}>
						<div
							className={`d-flex flow-row align-items-center justify-content-${configs.checkboxActionBtn.position}`}
							style={{ height: "100%" }}>
							<CButton
								style={{ backgroundColor: MAIN_BLUE }}
								onClick={() => checkboxButton.onClick && checkboxButton.onClick()}
								title={checkboxButton.btnText ? checkboxButton.btnText : "Үйлдэл"}
							/>
						</div>
					</Col>
				)
        },
        [checkboxButton?.show, checkedList]
    )

    return (
        configs.container.isShow
        ?
            <Row className={`mt-3 ${configs.container.className}`}>
                {displayCheckBoxBtn}
                {
                    configs.containerPages.isShow
                    &&
                        <Col lg={2} md={6} sm={6} xs={6} className={`order-${configs.containerPages.order} mb-md-2 mb-lg-0 ${configs.containerPages.className}`}>
                            <div className={`d-flex flow-row align-items-center justify-content-${configs.containerPages.position}`} style={{ height: "100%"}}>
                                {displayDetail}
                            </div>
                        </Col>
                }
                {
                    configs.containerPerPage.isShow
                    &&
                        <Col lg={3} md={6} sm={6} xs={6} className={`order-${configs.containerPerPage.order} mb-md-2 mb-lg-0 ${configs.containerPerPage.className}`}>
                            <div className={`d-flex flow-row align-items-center justify-content-${configs.containerPerPage.position}`}>
                                <small className='me-1 text-gray'>
                                    Жагсаах:
                                </small>
                                {displayRows}
                            </div>
                        </Col>
                }
                {
                    configs.containerPagination.isShow
                    &&
                        <Col lg={3} md={6} sm={6} xs={6} className={`order-${configs.containerPagination.order} mb-sm-3 mb-md-3 mb-lg-0 mb-xs-0 my-xs-3 ${configs.containerPagination.className}`}>
                            <Pagination className={`m-0 huudaslalt justify-content-${configs.containerPagination.position}`}>
                                {displayNumber}
                            </Pagination>
                        </Col>
                }
                {
                    configs.containerJumpPage.isShow
                    &&
                        <Col lg={2} md={6} sm={6} xs={6} className={`order-${configs.containerJumpPage.order} ${configs.containerJumpPage.className}`}>
                            <div className={`d-flex align-items-center justify-content-${configs.containerJumpPage.position}`}>
                                <small className='me-1 text-gray'>
                                    Хуудас
                                </small>
                                {displayInput}
                                {
                                    isMobile
                                    ?
                                        null
                                    :
                                        <small className="ms-1 text-gray">
                                            очих
                                        </small>

                                }
                            </div>
                        </Col>
                }
            </Row>
        :
            null
    )
}
