import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import RadioCheck from 'components/main/Form/InputTypes/RadioCheck';
import CSelect from 'components/main/Form/InputTypes/Select';
import useAuth from 'hooks/useAuth';
import CTable from 'components/main/Table';
import CButton from 'components/main/Button';

import DangerInfo from './DangerInfo';
import Item from './Item';

import useCState from 'hooks/useCState';
import useLoader from 'hooks/useLoader';
import useMobile from 'hooks/useMobile';
import useModal from 'hooks/useModal';
import useApi from 'hooks/useApi';

import css from './style.module.css'

const PetitionerMain = () => {

    const [ salaryPdfLoading, setSalaryLoading ] = useState(false)
    const [ allPdfLoading, setPdfLoading ] = useState(false)

    const { setCanAccess, canAccess } = useCState()

    // Хүсэлт илгээх товч харуулах эсэх
    const [ showRequestBtn, setShowRequestBtn ] = useState(false)

    // Хамтрах хүсэлт илгээх form харуулах эсэх
    const [ showFamilyReqeust, setShowFamilyRequest ] = useState(false)

    // // Хэрвээ алдааны мэссижүүд
    // const [ allErrorMessages, setAllErrorMessages ] = useState([])

    // Хамтрагч сонгохоор checkbox дарсан эсэх
    const [ withPartner, setWithPartner ] = useState(false)

    //pdf and other datas
    const [ detailz, setDetailz ] = useState([])

    // Хамтрахаар болоод нэг хүн сонгогдоно
    const [ choosedMember, setMember ] = useState('')

    // loading
    const [ familyLoading, setFamilyLoading ] = useState(false)

    // Нийт хамтрах хүсэлт илгээсэн түүх
    const [ requestedMember, setRequestedMember ] = useState([])

    // Хүсэлт нь хүлээгдэж байгаа эсэх
    const [ isPending, setPending ] = useState(false)

    // гэр бүлийн гишүүд
    const [ family, setFamily ] = useState([])

    const [ salaryPdf, setSalaryPdf ] = useState(null)

    const navigate = useNavigate()
	const { lotteryId, bulegId, resId } = useParams()

	const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
    const { userDetail } = useAuth()
    const { setDisplay } = useModal()
    const songonApi = useApi().songon
    const { isMobile } = useMobile()

    const getSalaryPdf = async (register) =>
    {
        setSalaryLoading(true)
        const body = {
            register,
            songon: lotteryId,
            zoriltot_buleg: bulegId,
            residence_id: resId
        }
        const { success, data } = await songonApi.salaryPdf(body).catch(err => err).finally(() => setSalaryLoading(false))
        if(success)
        {
            setSalaryPdf(data)
            setCanAccess(prev => { return {...prev, salaryCheck: true }})
            setShowRequestBtn(true)
        }
    }

    // Хамтрах хүсэлт илгээсэн түүх авах
    const getRequestedMembers = async () =>
    {
        setFamilyLoading(true)
            const body = {
                songon: lotteryId,
                zoriltot_buleg: bulegId,
                residence_id: resId
            }
            const { success, data } = await fetchData(songonApi.requestStatus(body)).finally(() => setFamilyLoading(false))
            if(success)
            {
                setRequestedMember(data)

                if (data.length != 0)
                {
                    // Амжилттай эсвэл хүлээгдэж байвал 1, 2 гэж ирнэ
                    if(data[data.length - 1].butets_type === 2 || data[data.length - 1].butets_type === 1)
                    {
                        setPending(true)
                    }
                }
                return
            }
    }

    useEffect(
        async () =>
        {
            if(requestedMember.length === 0) return
            if(salaryPdf) return
            if(requestedMember[requestedMember.length - 1].butets_type === 1 && canAccess.pdf && detailz.length !== 0){
                // Энэ үед цалингийн pdf авна
                await getSalaryPdf(requestedMember[requestedMember.length - 1]?.by_requested_user?.register)
            }
        },
        [requestedMember, canAccess, detailz]
    )

    useEffect(
        async () =>
        {
            await getRequestedMembers()
        },
        [showFamilyReqeust]
    )


    // Хэрэглэгч ийн мэдээллийг шалгах
    useEffect(
        async () =>
        {
            setPdfLoading(true)
            const body = {
                songon: parseInt(lotteryId),
                zoriltot_buleg: parseInt(bulegId),
                residence_id: resId
            }
            const { success, data, error } = await fetchData(songonApi.postUserDetail(body)).catch(err => err).finally(() => setPdfLoading(false))
            if(success)
            {
                setShowRequestBtn(data.salary_check)
                setCanAccess(prev => { return {...prev, salaryCheck: data.salary_check }})
                // salary_check ээр цалин хүрэх эсэх ирнэ хэрэв цалин хүрэхгүй бол хамтрах хүсэлт илгээх товч харуулна хүрэхгүй бол False ирнэ
                setShowFamilyRequest(!data.salary_check)

                // setAllErrorMessages(data.all_error_messages)

                setDetailz(data.pdf_data)
                return
            }
        },
        [lotteryId, bulegId, resId]
    )

    /** status ийн дагуу харуулах */
    const displayWord = (sta) =>
    {
        let words = {
            "Амжилттай": <span className={css["success"] + " ms-5"}> <i className='fa-solid fa-circle-check' />  амжилттай</span>,
            "Хүлээлтэнд байгаа": <span className={css["warning"] + " ms-5"}> <i className='fa-solid fa-circle-exclamation' /> шалгаж байна</span>,
            "Амжилтгүй": <span className={css["danger"] + " ms-5"}>  <i className='fas fa-times-circle' /> амжилтгүй</span>,
        }
        return words[sta]
    }

    /** pc нь дээр харуулах */
    const displayItems = useMemo(
        () =>
        {
            return detailz.map(
                (element, index) =>
                {
                    if (element.state_value === "Амжилтгүй")
                    {
                        setShowRequestBtn(false)
                    }
                    if(element.state_value === "Амжилттай"){
                        setCanAccess(prev => { return {...prev, pdf: true }})
                    }
                    return <Item error_message={element.error_message} displayWord={displayWord} key={index} name={element.title} description={element.description} status={element.state_value} pdf={element.pdf_url}/>
                }
            )
        },
        [detailz]
    )

    // const displayErrors = useMemo(
    //     () =>
    //     {
    //         return allErrorMessages.map(
    //             (element, index) =>
    //             {
    //                 return <span className="fs-13 text-blue">{element}<br /></span>
    //             }
    //         )
    //     },
    //     [allErrorMessages]
    // )

    /** утас нь дээр харуулах */
    const displayMobileItems = useMemo(
        () =>
        {
            if(!isMobile) return
            return detailz.map(
                (element, index) =>
                {
                    if (element.state_value === "Амжилтгүй")
                    {
                        setShowRequestBtn(false)
                    }
                    if(element.state_value === "Амжилттай"){
                        setCanAccess(prev => { return {...prev, pdf: true }})
                    }
                    return (
                        <Row key={index} className={css.item}>
                            <Col md={12}>Материалуудын нэр: {element.title} </Col>
                            <Col md={12}>Тайлбар: <span className={css.desc}>{element.description}</span></Col>
                            <Col md={12}>Төлөв: {displayWord(element.state_value)}</Col>
                            {
                                element.state_value !== 'danger'
                                ?
                                    <Col md={12}>
                                        Үзэх:
                                        <a href={`${process.env.REACT_APP_SERVER_PUBLIC_URL + element.pdf_url}`} target="_blank">
                                            <i className={`fas fa-file me-2 ${css.fileIcon}`}/>
                                        </a>
                                    </Col>
                                :
                                    <DangerInfo/>
                            }
                        </Row>
                    )
                }
            )
        },
        [detailz, isMobile]
    )


    // Хамтрах хүсэлт илгээнэ
    const btnOnSubmit = async () =>
    {
        if(requestedMember[requestedMember?.length - 1]?.butets_type === 2)
        {
            setPending(true)
            return
        }
        setFamilyLoading(true)
        const body = {
            register: choosedMember.value,
            songon: lotteryId,
            zoriltot_buleg: bulegId,
            residence_id: resId
        }
        const { success, data } = await songonApi.postFamily(body).catch(err => err).finally( () => setFamilyLoading(false) )
        if(success)
        {
            setPending(true)
            await getRequestedMembers()
        }
    }

    // Хамтрах хүсэлт илгээхээр Checkbox check хийсэн үед
    useEffect(
        async () =>
        {
            if(withPartner)
            {
                const { success, data } = await songonApi.getFamily()
                if(success)
                {
                    setFamily(
                        data.filter( element => element.regnum !== userDetail.register).map(
                            (element, index) =>
                            {
                                return { value: element.regnum , label: element.lastname + " " + element.firstname }
                            }

                        )
                    )
                }
            }
        },
        [withPartner]
    )

    // Хамтрах хүсэлт илгээсэн түүх хүснэгт багана
    const columns =
    [
        {
            field: "by_requested_user",
            headerName: "Овог",
            order: false,
            fieldComponent: (fieldValue) => <span>{fieldValue.last_name}</span>,
        },
        {
            field: "by_requested_user",
            headerName: "Нэр",
            order: false,
            fieldComponent: (fieldValue) => <span>{fieldValue.first_name}</span>,
        },
        {
            field: "butets_type_value",
            headerName: "Төлөв",
            order: false,
            fieldComponent: (fieldValue) => <span>{fieldValue === 'Амжилттай' ? 'Зөвшөөрсөн' : fieldValue}</span>,
        },
    ]


    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    // Хүсэлт илгээх эсэхийг асуусан checkbox харуулах
    const displayRequestFamilyMember = useMemo(
        () =>
        {
            if(!showFamilyReqeust) return
            //Хамтрах хүсэлт илгээсэн & Зөвшөөрцөн үед харуулахгүй
            if(!isPending) return (
				<Col md={4}>
					<RadioCheck
						onChange={e => setWithPartner(e.target.checked)}
						disabled={isLoading || familyLoading}
						options={[{ value: "1", label: "Хамтрагч сонгох эсэх" }]}
					/>
				</Col>
			)
        },
        [showFamilyReqeust, isPending, familyLoading, withPartner, family]
    )

    // Гэр бүлийн гишүүдээс сонгох Slect гарж ирнэ
    const displayFamilySelect = useMemo(
        () =>
        {
            if(withPartner) return(
                <>
                    <Col md={4}>
                        <CSelect
                            disabled={familyLoading || isPending}
                            placeholder="Гэр бүлийн гишүүн сонгох"
                            options={family}
                            onChange={value => setMember(value)}
                        />
                    </Col>
                    <Col md={4}>
                        <CButton
                            style={{ float: "right" }}
                            onClick={btnOnSubmit}
                            isLoading={isLoading || familyLoading || isPending}
                            title={isPending ? "Хамтрах хүсэлт хүлээгдэж байна" : "Хамтрах хүсэлт илгээх"}
                        />
                    </Col>
                </>
            )
        },
        [withPartner, isLoading, familyLoading, isPending, family, choosedMember]
    )

    const displaySalaryPdf = useMemo(
        () =>
        {
            if(!salaryPdf) return

            if(!isMobile) return (
				<Item
					error_message={salaryPdf.error_message}
					displayWord={displayWord}
					name={salaryPdf.title}
					description={salaryPdf.desc}
					status={salaryPdf.state === 1 ? "Амжилттай" : salaryPdf.state === 2 ? "Амжилтгүй" : "Хүлээлтэнд байгаа" }
					pdf={salaryPdf.url}
				/>
			)
            if(isMobile) return (
                <Row className={css.item}>
                    <Col md={12}>Материалуудын нэр: {salaryPdf.title} </Col>
                    <Col md={12}>Тайлбар: <span className={css.desc}>{salaryPdf.desc}</span></Col>
                    <Col md={12}>Төлөв: {displayWord( salaryPdf.state === 1 ? "Амжилттай" : salaryPdf.state === 2 ? "Амжилтгүй" : "Хүлээлтэнд байгаа"  )}</Col>
                    {
                        salaryPdf.state === 1
                        ?
                            <Col md={12}>
                                Үзэх:
                                <a href={`${process.env.REACT_APP_SERVER_PUBLIC_URL + salaryPdf.url}`} target="_blank">
                                    <i className={`fas fa-file me-2 ${css.fileIcon}`}/>
                                </a>
                            </Col>
                        :
                            <DangerInfo/>
                    }
                </Row>
            )
        },
        [salaryPdf, isMobile]
    )

    return (
		<div className={css.container}>
			{isLoading || allPdfLoading ? (
				<div style={{ marginTop: "10vh", marginBottom: "10vh" }}>{Loader}</div>
			) : !isMobile ? (
				<>
					<Row>
						<Col>Материалуудын нэр:</Col>
						<Col>Тайлбар:</Col>
						<Col className="ms-5">Төлөв:</Col>
					</Row>
					{displayItems}
				</>
			) : (
				<>{displayMobileItems}</>
			)}
            {/* {allErrorMessages.length !== 0 && (
                <>
                    <span className="d-block fs-13 text-danger">
					    <i className="fal fa-exclamation-circle" /> Алдаа:
                    </span>
                    {displayErrors}
                </>
            )} */}
			{requestedMember.length !== 0 && (
				<Row className="mt-3">
					<Col>
						<div>Хамтрах хүсэлт илгээсэн түүх</div>
						<CTable
							isLoading={isLoading}
							columns={columns}
							options={{
								tableOuterClassName: css.tableOuter,
							}}
							rows={requestedMember}
							sequenceColumn={sequenceColumn}
						/>
					</Col>
				</Row>
			)}
			<Row className={css.partnerchoose}>
				{displayRequestFamilyMember}
                {displayFamilySelect}
			</Row>
            {salaryPdfLoading ? Loader : displaySalaryPdf}
			<div className={css.sanamj}>
				<span className="d-block fs-13 text-blue">
					<i className="fal fa-exclamation-circle" /> Санамж:
				</span>
				<span className="fs-13 text-blue">
					1. Та бүх мэдээллээ оруулж дууссаны дараа шалгах процесс эхэлнэ. <br />
					<span>
						2. Бүх мэдээлэл <span className="fs-13 text-success">амжилттай</span> шалгагдаж дууссаны дараа та хүсэлтээ илгээж болно.
					</span>
				</span>
			</div>
            {
                showRequestBtn &&
                <CButton
                    isLoading={isLoading || familyLoading}
                    className="mt-3"
                    onClick={() => navigate("form/")}
                    title={"Хүсэлт илгээх"}
                />
            }
		</div>
	)
}

export default PetitionerMain;
