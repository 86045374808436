
import React from 'react';

import Report from './Report'
import NewsPageRoutes from './NewsPage'
import UserMain from './User/main'
import AddBigPeople from './AddPeople/main';
import SelectRoomCrudMain from './SelectRoom/main';
import LicenseTypeCrudMain from './LicenseType/main';

import {
    adminPageRoles,
    adminForNews,
} from 'utils/consts/roles'
import NowUsageRoute from './NowUsage/main';
import ActivityType from 'pages/Gov/Activity/main';
import { PERM_IS_SUPER_USER } from 'utils/consts';
import UnitMeasure from 'pages/Admin/UnitMeasure/main';
import AdvantageCrudMain from 'pages/Company/Type/Advantage/main';
import DoorTypeCrudMain from 'pages/Company/Type/DoorType/main';
import WindowTypeCrudMain from 'pages/Company/Type/WindowType/main';
import ParkingTypeCrudMain from 'pages/Company/Type/ParkingType/main';
import PropertyTypeCrudMain from 'pages/Company/Type/PropertyType/main';
import CrudAccess from './Access/CrudAccess';
import SignAccess from './Access/SignAccess';
import ErrorAccess from './Access/ErrorAccess';
import Error500Access from './Access/Error500';
import ResetBuilding from './ResetBuilding';
import AbuotUsConfig from './AboutUsConfig'
import OrgTypeMain from './OrgType/main'
import HelperMain from './Helper/main';

const Links = [
    {
        name: "Мэдээ нэмэх",
        path: "/news-page/*",
        icon: <i className="fal fa-book-reader"></i>,
        component: NewsPageRoutes,
        layout: "/admin",
        needRoles: adminForNews,
        isNavigate: false,
    },
    {
        name: "Хэрэглэгчид",
        path: "/users/*",
        icon: <i className="fal fa-user"></i>,
        component: UserMain,
        layout: "/admin",
        needRoles: adminPageRoles,
        isNavigate: false,
    },
    // {
    //     name: "Тайлан",
    //     path: "/report/",
    //     component: Report,
    //     icon: <i className="fal fa-paste"></i>,
    //     layout: "/admin",
    //     needRoles: adminPageRoles,
    //     isNavigate: false,
    // },
    {
        name: "Хандалт",
        path: "/access/*",
        icon: <i className="fal fa-chart-pie-alt"></i>,
        layout: "/admin",
        needRoles: adminPageRoles,
        isNavigate: false,
        subMenu: [
            {
                name: "Хүсэлт",
                component: CrudAccess,
                path: "/crudaccess/*",
                needRoles: adminPageRoles,
                isNavigate: false,
                firstActive: true,
            },
            {
                name: "Системд нэвтэрсэн",
                component: SignAccess,
                path: "/signaccess/*",
                needRoles: adminPageRoles,
                isNavigate: false,
            },
            {
                name: "Хүсэлтийн алдаа",
                component: ErrorAccess,
                path: "/error/*",
                needRoles: adminPageRoles,
                isNavigate: false,
            },
            {
                name: "Систем дээр гарсан алдаа",
                component: Error500Access,
                path: "/error500/*",
                needRoles: adminPageRoles,
                isNavigate: false,
            },
        ],
    },
    {
        name: "Өрөөний сонголт",
        path: "/selectroom/*",
        icon: <i className="fal fa-th-large"></i>,
        component: SelectRoomCrudMain,
        layout: "/admin",
        needRoles: adminPageRoles,
        isNavigate: false,
    },
    {
        name: "Тусгай зөвшөөрлийн төрөл",
        path: "/licensetype/*",
        component: LicenseTypeCrudMain,
        icon: <i className="fal fa-vote-yea"></i>,
        layout: "/admin",
        needRoles: adminPageRoles,
        isNavigate: false,
    },
    {
        name: "Tөрийн байгууллага болон СЗХ ийн хүн бүртгэх",
        path: "/addpeople/*",
        icon: <i className="fal fa-user-secret"></i>,
        component: AddBigPeople,
        layout: "/admin",
        needRoles: adminPageRoles,
        isNavigate: false,
    },
    {
        name: "Одоогийн зориулалт",
        path: "/nowusage/*",
        icon: <i className="fal fa-books"></i>,
        component: NowUsageRoute,
        layout: "/admin",
        needRoles: adminPageRoles,
        isNavigate: false,
    },
    {
        name: "Үйл ажиллагааны төрөл",
        path: "/activitytype/*",
        icon: <i className="fal fa-chart-network"></i>,
        component: ActivityType,
        layout: "/admin",
        needRoles: adminPageRoles,
        isNavigate: false,
    },
    {
        name: "Хэмжих нэгж",
        path: "/unitmeasure/*",
        icon: <i className="fal fa-balance-scale-left"></i>,
        component: UnitMeasure,
        layout: "/admin",
        needRoles: adminPageRoles,
        isNavigate: false,
    },
    {
        name: "Төрөл",
        path: "/type/*",
        icon: <i className="fal fa-code-branch"></i>,
        needRoles: adminPageRoles,
        isNavigate: false,
        subMenu: [
            {
                name: "Давуу тал",
                component: AdvantageCrudMain,
                path: "/advantage/*",
                needRoles: adminPageRoles,
                isNavigate: false,
                firstActive: true,
            },
            {
                name: "Xаалганы төрөл",
                component: DoorTypeCrudMain,
                path: "/doortype/*",
                needRoles: adminPageRoles,
                isNavigate: false,
            },
            {
                name: "Цонхны төрөл",
                component: WindowTypeCrudMain,
                path: "/windowtype/*",
                needRoles: adminPageRoles,
                isNavigate: false,
            },
            {
                name: "3огсоолын төрөл",
                component: ParkingTypeCrudMain,
                path: "/parkingtype/*",
                needRoles: adminPageRoles,
                isNavigate: false,
            },
            {
                name: "Өмчлөх төрөл",
                component: PropertyTypeCrudMain,
                path: "/property-type/*",
                needRoles: adminPageRoles,
                isNavigate: false,
            },
            {
                name: "Байгууллагын төрөл",
                path: "/org-type/*",
                icon: <i className="far fa-chart-pie-alt"></i>,
                component: OrgTypeMain,
                needRoles: adminPageRoles,
                isNavigate: false,
            },
        ],
        layout: "/admin",
    },
    {
        name: "Барилга geom шинэчлэх",
        path: "/reset-apartmentsdi-data/*",
        icon: <i className="fal fa-window-restore "></i>,
        component: ResetBuilding,
        layout: "/admin",
        needRoles: adminPageRoles,
        isNavigate: false,
    },
    {
        name: "Бидний тухай хуудсын тохиргоо",
        path: "/about-us-config/*",
        icon: <i className="fas fa-ellipsis-h-alt"></i>,
        component: AbuotUsConfig,
        layout: "/admin",
        needRoles: adminPageRoles,
        isNavigate: false,
    },
    {
        name: "Видео бичлэг",
        path: "/helper/*",
        icon: <i className="fal fa-video-camera"></i>,
        component: HelperMain,
        layout: "/admin",
        needRoles: adminPageRoles,
        isNavigate: false,
    },
];

export default Links;
