import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import CButton from 'components/main/Button';
import ImageFetch from 'components/main/Image';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import label from 'utils/validate/label';

import css from '../style.module.css'

const initState = {
    index: '',
    desciption: '',
    plan_image: '',
    real_photo: ''
}

const BuildingImageUpdate = ({ projectId, buildingId }) => {

    const [ data, setData ] = useState([]);
    const [ canEdit, setCanEdit ] = useState(false);
    const [ changedDatas, setChangedDatas ] = useState(initState)

    const buildingImagesApi = useApi().building.buildingImages

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })

    const getData = async () =>
    {
        const { success, data } = await fetchData(buildingImagesApi.get(projectId, buildingId))
        if(success)
        {
            setData(data)
        }
    }

    useEffect(
        async () =>
        {
            getData()
        },
        []
    )

    const handeEditBtn = (index) =>
    {
        setChangedDatas(
            {
                index: index,
                desciption: data[index].desciption,
                real_photo: ''
            }
        )
        setCanEdit(true)
    }

    const handleCancel = (index) =>
    {
        setChangedDatas(initState)
        setCanEdit(false)
        document.getElementById('real_photo' + index).value = ''
    }

    const handleDeleteBtn = async (id) =>
    {
        const { success, data } = await fetchData(buildingImagesApi.delete(projectId, buildingId, id))
        if(success)
        {
            getData()
        }
    }

    const checkEditable = (index) =>
    {
        return canEdit && changedDatas.index === index
    }

    const handleSaveBtn = async (id, index) =>
    {
        const fData = new FormData()
        fData.append('building', buildingId)
        fData.append('desciption', changedDatas.desciption)
        if(typeof changedDatas.real_photo === 'object') fData.append('real_photo', changedDatas.real_photo)

        const config =
        {
            headers: { 'content-type': 'multipart/form-data' }
        }
        const { success } = await fetchData(buildingImagesApi.put(projectId, buildingId, id, fData, config))
        if(success)
        {
            getData()
            setChangedDatas(initState)
            setCanEdit(false)
            // document.getElementById('real_photo' + index).value = 'undefined'
            // document.getElementById('plan_image' + index).value = 'undefined'
        }
    }

    const showInput = (index) => document.getElementById(index).click()

    const displayImageWithDesc = useMemo(
        () =>
        {
            return data.map(
                (element, index) =>
                {
                    return (
						<Row key={index}>
							<Col> <hr/>
								<div className="mb-3">
									<label>Тайлбар: </label>{" "}<br/>
									<label>
										{
                                            checkEditable(index)
                                            ?
                                                <input
                                                    className='form-control'
                                                    onChange={e => setChangedDatas({ ...changedDatas, desciption: e.target.value })}
                                                    value={changedDatas !== "" ? changedDatas.desciption : element.desciption}
                                                />
                                            :
                                                element.desciption
										}
									</label>
								</div>
								<Row className="d-flex justify-content-center">
									<Col md={6}>
                                        <label>{label['real_photo']}:</label>
										<ImageFetch
                                            className={ checkEditable(index) ? css.editImage : '' }
											style={{ width: "100%", height: '320px' }}
                                            onClick={ () => checkEditable(index) &&  showInput('real_photo' + index) }
											src={
                                                checkEditable(index) && changedDatas.real_photo !== ''
                                                ?
                                                    URL.createObjectURL(changedDatas.real_photo)
                                                :
                                                    process.env.REACT_APP_SERVER_URL +
                                                    "/media/" + element.real_photo
											}
										/>
                                        <input
                                            type="file"
                                            id={'real_photo' + index}
                                            style={{ display: "none" }}
                                            accept="image/*"
                                            onChange={ (e) => setChangedDatas({ ...changedDatas, real_photo: e.target.files[0] }) }
                                        />
									</Col>
								</Row>
								<div className={`mt-2 ${checkEditable(index) ? 'd-flex justify-content-between' : "floatRight"}`}>
                                    {checkEditable(index) && <label className='fs-13'><i className="fas fa-exclamation-circle"></i> Зураг нь дээр дарж өөрчилнө үү</label>}
                                    {
                                        checkEditable(index)
                                        ?
                                            <div>
                                                <CButton variant='second' onClick={() => handleSaveBtn(element.id, index)} className="me-2">
                                                    Хадгалах
                                                </CButton>
                                                <CButton variant='danger' onClick={() => handleCancel(index)} className="me-2">
                                                    Болих
                                                </CButton>
                                            </div>
                                        :
                                            <div>
                                                <CButton variant='second' onClick={() => handeEditBtn(index)} className="me-2">
                                                    Өөрчлөх
                                                </CButton>
                                                <CButton onClick={ () => handleDeleteBtn(element.id) } variant="danger">Устгах</CButton>
                                            </div>
                                    }
								</div>
							</Col>
						</Row>
					)
                }
            )
        },
        [data, canEdit, changedDatas]
    )

    return (
        <>
            {isLoading ? Loader : displayImageWithDesc}
            {displayImageWithDesc.length === 0 && <div className='text-center mt-5 mb-5'>Хоосон байна</div>}
        </>
    )
}

export default BuildingImageUpdate;
