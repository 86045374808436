import { useState, useCallback } from "react"

export default function useForceUpdate() {
    const [, setState] = useState(true)
    const forceUpdate = useCallback(() => {
        setState(s => !s)
    }, [])

    return forceUpdate
}
