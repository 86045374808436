import React, { useEffect, useState } from "react"

import useLoader from "hooks/useLoader"
import useApi from "hooks/useApi"

import { getCurrentMonthDate, getCurrentWeekDate, getLastWeeksDate, getPreviousDay, getPrevMonthDate } from "utils/date"
import { quantityFormat, timeZoneToDateString } from "utils/format"

import css from "../../style.module.css"

const SignAccessDifference = () => {
	const [allData, setAllData] = useState({
		week: [],
		month: [],
	})

	const calculatePercent = (curr, prev) =>
	{
		let number = (curr * 100 / prev) - 100
		return number
	}

	const signaccessApi = useApi().adminAccessFront
	const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })

	useEffect(async () => {
        Promise.all([
			fetchData(signaccessApi.getSignLog(getPrevMonthDate().start, getPrevMonthDate().end)),
			fetchData(signaccessApi.getSignLog(getCurrentMonthDate().start, new Date().getTime())),
			fetchData(signaccessApi.getSignLog(getLastWeeksDate().start, getLastWeeksDate().end)),
			fetchData(signaccessApi.getSignLog(getCurrentWeekDate().start, new Date().getTime())),
		]).then(values => {
			setAllData({
				month: {
					prev: values[0].data.line.login.success_count,
					current: values[1].data.line.login.success_count,
				},
				week: {
					prev: values[2].data.line.login.success_count,
					current: values[3].data.line.login.success_count,
				},
			})
		})
	}, [])

	return (
		<div className={css.todaySignWrapper} style={{ marginBottom: "10px" }}>
			<div className={css.subagendaDetail}>
				<div className="d-flex justify-content-between align-items-center">
					<div>
						<div style={{ fontSize: "13px" }}>Энэ долоо хоногт нэвтэрсэн хэрэглэгчид</div>
						<div style={{ fontSize: "11px", color: "grey" }}>Өмнөх долоо хоногоос</div>
					</div>
					<div className={css.numberWithPercent}>
						<div className={css.number}>{quantityFormat(allData?.week?.current)}</div>
						<div
							className={css.percent}
							style={{ color: calculatePercent(allData?.week?.current, allData?.week?.prev) >= 0 ? "#005B9A" : "#bd1c1c" }}>
							<i
								className={`far fa-long-arrow-${calculatePercent(allData?.week?.current, allData?.week?.prev) >= 0 ? "up" : "down"}`}
								style={{
									fontSize: "9px",
								}}></i>{" "}
							{calculatePercent(allData?.week?.current, allData?.week?.prev).toFixed(1)}%
						</div>
					</div>
				</div>
			</div>
			<div className={css.subagendaDetail} style={{ marginTop: '10px' }}>
				<div className="d-flex justify-content-between align-items-center">
					<div>
						<div style={{ fontSize: "13px" }}>Энэ сард нэвтэрсэн хэрэглэгчид</div>
						<div style={{ fontSize: "11px", color: "grey" }}>Өмнөх сараас</div>
					</div>
					<div className={css.numberWithPercent}>
						<div className={css.number}>{quantityFormat(allData?.month?.current)}</div>
						<div
							className={css.percent}
							style={{ color: calculatePercent(allData?.month?.current, allData?.month?.prev) >= 0 ? "#005B9A" : "#bd1c1c" }}>
							<i
								className={`far fa-long-arrow-${
									calculatePercent(allData?.month?.current, allData?.month?.prev) >= 0 ? "up" : "down"
								}`}
								style={{
									fontSize: "9px",
								}}></i>{" "}
							{calculatePercent(allData?.month?.current, allData?.month?.prev).toFixed(1)}%
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SignAccessDifference
