
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ActivityTypeMain from '.';

import ActivityTypeAction from './ActivivtyTypAction';

import BoxBackground from 'components/special/BoxBackground';

const ActivityType = () => {
    return (
        <Routes>
            <Route path="/" element={<BoxBackground title='Үйл ажиллагааны төрөл' body={<ActivityTypeMain/>} />} />
            <Route path="/:id/" element={<ActivityTypeAction/>} />
        </Routes>
    );
}

export default ActivityType;
