import useRole from 'hooks/useRole';

import { PERM_READ } from 'utils/consts';

/** Role оос хамаарч тухайн element ийг харуулах эсэх */
const RoleContainer = ({ needRoles=[], children, checkRole=PERM_READ }) =>
{
    const hasPerm = useRole(needRoles, checkRole)

    /** Хэрвээ ямар нэгэн role байхгүй бол element ийг харуулах */
    if (needRoles.length === 0)
        return children

    return hasPerm ? children : null
}

export default RoleContainer;
