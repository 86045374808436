import React, { useContext } from "react";

import { HeaderContext } from "context/special/headerContext";

import useToggler from "hooks/useToggler";

import MenuItemSub from "../MenuItemSub";

import MainNavLink from "components/main/NavLink";
import RoleRequired from 'components/main/RoleRequired';

import css from "../style.module.css";

const MenuItem = ({ element }) =>
{
    const { Toggler, togglerState, setTogglerState } = useToggler({
        togglerOn: <i className="fa-solid fa-chevron-down"/>,
        togglerOff: <i className="fa-solid fa-chevron-down fa-rotate-270"/>,
    });

    /** Header context оос toggle хийх setState авна. Menu-нээс сонголт хийхэд state ийг toggle хийнэ */
    const { setTogglerState: setSidebarToggler } = useContext(HeaderContext)

    function growDiv() {
        var growDiv = document.getElementById(element.path);

        if (growDiv.clientHeight) {
            growDiv.style.maxHeight = 0;
        } else {
            growDiv.style.maxHeight = element.subMenu.length * 50 + "px";
        }
    }

    return (
        <div className={css.navItemContainer}>
            <RoleRequired needRoles={element?.needRoles ? element.needRoles : []} isNavigate={element.isNavigate || false}>
                <MainNavLink
                    onClick={
                        () =>
                        {
                            setTogglerState(!togglerState);
                            !element.subMenu && setSidebarToggler(false);
                            element.subMenu && growDiv()
                        }
                    }
                    exact={element.exact}
                    className={`d-flex align-items-center ${css.navItem}`}
                    activeClassName={`${css.navActive}`}
                    to={element.layout + element.path.replace("/*", "/")}
                    hasActive={element.subMenu && false}
                >
                    <div className={`me-3 ${css.sidebarIcon}`}>{element.icon}</div>
                        {element.name}
                    <div className="ms-auto">{element.subMenu && Toggler}</div>
                </MainNavLink>
            </RoleRequired>
            <MenuItemSub
                path={element.path}
                layout={element.layout}
                elements={element.subMenu}
                setSidebarToggler={setSidebarToggler}
                show={togglerState}
            />
        </div>
    );
};

export default MenuItem;
