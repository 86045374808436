import { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import useMapContext from "hooks/useMapContext"

import Container from "./container"
import ProjectDetail from './ProjectDetail'
import ProjectMapAction from "./ProjectMapAction"
import ApartmentDetail from './ApartmentDetail'
import ProjectResTabs from "./ProjectResTabs"
import ResidenceList from "./ResidenceList"
import ApartTabs from "./ApartmentDetail/ApartTabs"
import Building from "./ApartmentDetail/Building"

import { searchParamsToObject } from "utils/browser"
import Borluulalt from "../../ProjectTabs/Apartment/Borluulalt"

export default function OverlayDetail({ removeOverlay, cMap })
{
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ key, setKey ] = useState(searchParamsToObject(searchParams).tab || 'project')
    const { choosedFeature } = useMapContext()

    const feature = choosedFeature.feature
    const isProject = choosedFeature.isProject
    const isCreateProject = choosedFeature.isCreateProject
    const isApartment = choosedFeature.isApartment
    const apartmentId = choosedFeature.apartmentId
    const data = feature && Object.keys(feature).length > 0 ? feature.get("data") : {}

    const projectId = choosedFeature.projectId

    const handleClose = () =>
    {
        removeOverlay()
        setSearchParams({})
    }

    const displayTitle = useMemo(
        () =>
        {
            if (isCreateProject) return "Шинэ төсөл"
            if(isProject) return <ProjectResTabs setKey={setKey} />
            if(isApartment) return <ApartTabs setKey={setKey} apartmentId={apartmentId} />
        },
        [isProject, data, key, isApartment, apartmentId]
    )

    const displaybody = useMemo(
        () =>
        {
            if(key === 'project')
            {
                if(isProject) return <ProjectDetail id={data.id} name={data.name} removeOverlay={removeOverlay}/>
                if(isCreateProject) return <ProjectMapAction removeOverlay={removeOverlay} point={choosedFeature.point} cadasterID={choosedFeature.cadasterID}/>
            }
            if(key === 'building') return apartmentId && <Building removeOverlay={removeOverlay} mapProjectId={projectId} feature={feature} apartmentId={apartmentId} />
            if(key === 'borluulalt') return apartmentId && <Borluulalt projectId={projectId} apartId={apartmentId} />
            if(key === 'apartment') return <ApartmentDetail apartmentId={apartmentId} data={data} removeOverlay={removeOverlay} feature={feature} cMap={cMap} projectId={projectId} />
            if(key === 'residence') return <ResidenceList removeOverlay={removeOverlay} mapProjectId={projectId}/>
        },
        [key, data, projectId, isApartment, feature, apartmentId, isCreateProject]
    )

    useEffect(
        () =>
        {
            setKey(searchParamsToObject(searchParams).tab || 'project')
        },
        [searchParamsToObject(searchParams).tab]
    )

    return (
		<div id="custom-ol-popup-project" className="none">
			<Container title={displayTitle} handleClose={handleClose}>
				{displaybody}
			</Container>
		</div>
	)
}
