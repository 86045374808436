import React, { useEffect, useMemo, useState } from 'react';

import useLoader from 'hooks/useLoader'

import DisplayFullArray from 'components/special/DisplayOneDetails/DisplayFullArray';
import DisplaySingleValue from 'components/special/DisplayOneDetails/DisplaySingleValue';
import DisplayImage from 'components/special/DisplayOneDetails/DisplayImage';
import label from 'utils/validate/label';
import { BUTETS_TYPE } from 'utils/consts';
import DisplayNestedObject from 'components/special/DisplayOneDetails/DisplayNestedObject';

const ApartmentDetails = ({ api }) => {

    const { isLoading, Laoder, fetchData } = useLoader({ isSmall: false })
    const [ details, setDetails ] = useState({});

    useEffect(
        async () =>
        {
            const resp = await fetchData(api)
            if(resp.success)
            {
                setDetails(resp.data.data[0])
            }
        },
        []
    )

    const butets = () =>
    {
        return BUTETS_TYPE.find(
            (element) =>
            {
                return element.value === details?.butets_type
            }
        )
    }

    return (
        <div>
            <DisplaySingleValue item={'name'} details={details} />
            <DisplayNestedObject item={'project'} subItem='project_name' details={details} />
            <DisplaySingleValue name={label['butets_type']} item={'label'} details={butets()} />
            <DisplayImage item='real_photo' details={details} />
            <DisplayFullArray item='advantage' subItem='name' details={details} />
            <DisplayFullArray item='dohiolol' subItem='name' details={details} />
            <DisplayFullArray item='door_type' subItem='door_type' details={details} />
            <DisplayFullArray item='lift_ype' subItem='name' details={details} />
            <DisplayFullArray item='use_type' subItem='name' details={details} />
            <DisplayFullArray item='window_type' subItem='window_type' details={details} />
            <DisplaySingleValue item={'service_area'} details={details} />
            <DisplaySingleValue item={'service_floor'} details={details} />
            <DisplaySingleValue item={'total_ail'} details={details} />
            <DisplaySingleValue item={'total_floor'} details={details} />
            <DisplaySingleValue item={'total_service_area'} details={details} />
            <DisplaySingleValue item={'total_under_floor'} details={details} />
            <DisplaySingleValue item={'state_name'} details={details} />
            <DisplaySingleValue item={'buulgasan_shaltgaan'} details={details} />
            <DisplaySingleValue item={'shiidver_garsan_on'} details={details} />
        </div>
    );
}

export default ApartmentDetails;
