/**
    @param {} props
*/
const ProjectStep = ({ feature }) =>
{

    return (
        <div>
            <h6>{feature.get("Text")}</h6>
            {
                feature.get("Text") === "Барилга" && feature.get("apartment_name")
                ? (
                    <ul>
                        <li>
                            <b>Барилгын нэр:</b> {feature.get("apartment_name")}
                        </li>
                        <li>
                            <b>Хэрэгжилт:</b> {feature.get("cproject").tuluwluguu_heregjilt_name}
                        </li>
                    </ul>
                )
                : null
            }
        </div>
    )
}
export default ProjectStep
