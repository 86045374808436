import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'

import CreateButton from 'components/main/CudButtons/Create'
import Datatable from 'components/main/Datatable'

import useApi from 'hooks/useApi'

import { toskPageRoles } from 'utils/consts/roles'
import { moneyFormat, timeZoneToDateString } from 'utils/format'

import BorluulaltCreate from './BorluulaltCreate'
import { DESC } from 'components/main/Table/Thead'

const sequenceColumn =
{
    headerName: "#",
    startNumber: 1,
    order: false,
    width: 50
}

const Borluulalt = ({ projectId, apartId }) =>
{

    const [ isCreate, setCreate ] = useState(false)
    const borluulaltApi = useApi().apartment.borluulalt

    const columns =
    [
        {
            field: "sales_manager",
            order: true,
            fieldComponent: (fieldValue, index, row) => fieldValue.manager,
            searchField: "sales__manager"
        },
        {
            field: "sales_manager",
            order: true,
            fieldComponent: (fieldValue, index, row) => fieldValue.contact,
            searchField: "sales__contact"
        },
        {
            field: "sales_manager",
            order: true,
            fieldComponent: (fieldValue, index, row) => fieldValue.email,
            searchField: "sales__email"
        },
        {
            field: "price_mkv",
            headerName: "м² ийн үнэ",
            order: true,
            fieldComponent: (fieldValue, index, row) => moneyFormat(fieldValue),
        },
        {
            field: "total_price",
            order: true,
            fieldComponent: (fieldValue, index, row) => moneyFormat(fieldValue),
        },
        {
            field: "created_at",
            order: true,
            fieldComponent: (fieldValue, index, row) => timeZoneToDateString(fieldValue)
        }
    ]

    return (
		<Row>
			<Col sm={12}>
                {
                    !isCreate &&
                    <CreateButton
                        btnClassName="floatRight"
                        onClick={() => setCreate(true)}
                        needRoles={[toskPageRoles]}
                    />
                }
			</Col>
			<Col>
            {
                !isCreate
                ?
                    <Datatable
                        columns={columns}
                        sequenceColumn={sequenceColumn}
                        request={{
                            axios: borluulaltApi.get,
                            params: [projectId, apartId],
                        }}
                        isFirstGetData={true}
                        tableOptions={{
                            defaultSort: {
                                field: "created_at",
                                type: DESC,
                            },
                        }}
                    />
                :
                    <BorluulaltCreate projectId={projectId} apartId={apartId} setCreate={setCreate}/>
            }
            </Col>
		</Row>
	)
}

export default Borluulalt
