import React from 'react'
import { Button } from 'react-bootstrap'

import { SmallLoader } from '../Loader'

import Icon from 'components/main/Icon'

/**
 * Үндсэн товч
 * @param {string}              title       Товчны үг
 * @param {string}              icon        Товчны үгий өмнө байрлах icon
 * @param {('sm'|'lg')}         size        Товчны хэмжээ 'sm' | 'lg'
 * @param {string}              className   Товчны загвар дээр нэмээд загвар өгөх бол
 * @param {Function}            onClick     товч дарагдах үед
 * @param {('button'|'submit')} type        товчны төрөл
 * @param {boolean}             disabled    идэвхитэй эсэх
 * @param {object}              iconStyle   icon ий style
 * @param {style}               style       товчний style
 * @returns товч
 */
export default function CButton({ _ref, title, size="lg", icon, className, type="button", onClick, disabled, variant="main", children, style, iconStyle, isLoading, show=true, id=null })
{
    const text = title ? title : children

    return (
            show
            &&
            <Button
                id={id}
                ref={_ref}
                type={type}
                size={size}
                variant={variant}
                onClick={!isLoading ? onClick : null}
                className={`px-4 fs-13 ${className}`}
                disabled={isLoading || disabled}
                style={style}
            >
                {
                    icon === null
                    ?
                        isLoading ? <> <SmallLoader/> {text} </> :  text
                    :
                        null
                }
                {
                    text && icon !== null
                    ? (
                        <>
                            {isLoading && <SmallLoader/>}{" "}<Icon icon={icon} iconStyle={iconStyle}/>{" "}{text}
                        </>
                    )
                    : <Icon icon={icon}/>
                }
            </Button>
        )
}
