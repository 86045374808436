import React, { useMemo, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';
import CButton from 'components/main/Button'

import LotteryCountDown from './LotteryCountDown';
import TotalMembers from './TotalMembers';
import LotteryChart from './LotteryChart';

import useLoader from 'hooks/useLoader';
import useMobile from 'hooks/useMobile'
import useModal from 'hooks/useModal';
import useApi from 'hooks/useApi';

import css from './style.module.css'
import BuildingInfo from './BuildingInfo';

const LotteryDetail = () => {

    const fakeData = {
		data: [
			{
				songon: 1,
				user: {
					first_name: "Одхүү",
					last_name: "Түвшинбаяр",
				},
				zoriltot_buleg: {
					code: 4,
				},
				residence_total_room: 2,
				residence_total_mkv: 33,
				residence_plan_img: "zurgiin_zam",
				is_won: true,
				created_at: "огноо",
			},
            {
				songon: 1,
				user: {
					first_name: "Одхүү",
					last_name: "Түвшинбаяр",
				},
				zoriltot_buleg: {
					code: 4,
				},
				residence_total_room: 2,
				residence_total_mkv: 33,
				residence_plan_img: "zurgiin_zam",
				is_won: true,
				created_at: "огноо",
			},
            {
				songon: 1,
				user: {
					first_name: "Одхүү",
					last_name: "Түвшинбаяр",
				},
				zoriltot_buleg: {
					code: 4,
				},
				residence_total_room: 2,
				residence_total_mkv: 54,
				residence_plan_img: "zurgiin_zam",
				is_won: true,
				created_at: "огноо",
			},
            {
				songon: 1,
				user: {
					first_name: "Одхүү",
					last_name: "Түвшинбаяр",
				},
				zoriltot_buleg: {
					code: 4,
				},
				residence_total_room: 2,
				residence_total_mkv: 54,
				residence_plan_img: "zurgiin_zam",
				is_won: true,
				created_at: "огноо",
			},
            {
				songon: 1,
				user: {
					first_name: "Одхүү",
					last_name: "Түвшинбаяр",
				},
				zoriltot_buleg: {
					code: 4,
				},
				residence_total_room: 2,
				residence_total_mkv: 54,
				residence_plan_img: "zurgiin_zam",
				is_won: true,
				created_at: "огноо",
			},
            {
				songon: 1,
				user: {
					first_name: "Одхүү",
					last_name: "Түвшинбаяр",
				},
				zoriltot_buleg: {
					code: 4,
				},
				residence_total_room: 2,
				residence_total_mkv: 54,
				residence_plan_img: "zurgiin_zam",
				is_won: true,
				created_at: "огноо",
			},
            {
				songon: 1,
				user: {
					first_name: "Одхүү",
					last_name: "Түвшинбаяр",
				},
				zoriltot_buleg: {
					code: 4,
				},
				residence_total_room: 2,
				residence_total_mkv: 54,
				residence_plan_img: "zurgiin_zam",
				is_won: true,
				created_at: "огноо",
			},
		],
		name: "Залуу гэр бүл",
		count: 7,
		user_count: 123123123,
	}

    const [ lotteryData, setLotteryData ] = useState({})
    const { lotteryId } = useParams()
    const navigate = useNavigate()

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: false, hasBackground: false })
    const lotteryApi = useApi().songon
    const { setDisplay } = useModal();
    const { isMobile } = useMobile()

    /** Modal гаргаж ирэх funct */
    const displayGrapic = (id) =>
    {
        setDisplay(
            {
                header:
                {
                    title: "График",
                },
                body: <LotteryChart data={lotteryData?.zoriltot_buleg_count} />,
                size: "lg",
                fullscreen: true,
                footer:
                {
                    show:false
                }
            }
        )
    }

    useEffect(
        async () =>
        {
            const lotteryRsp = await fetchData(lotteryApi.getOne(lotteryId))
            if(lotteryRsp.success)
            {
                setLotteryData(lotteryRsp.data)
            }
        },
        [lotteryId]
    )

    const displayNumber = useMemo(
        () =>
        {
            return lotteryData?.zoriltot_buleg_count?.map(
                (el, index) =>
                {
                    return (
						<Col key={index} lg={4} md={6} xs={12} className="mt-3">
							<BoxBackground
								headerClassName={css.boxHeader}
								title={el.name}
								body={
                                    <TotalMembers
                                        id={index}
                                        realId={el.code}
                                        joined={lotteryData.is_joined}
                                        number={el.total_count}
                                        resideneceInfo={el.residenece_info}
                                        join={el.is_joined}
                                        isEnd={lotteryData.is_end}
                                        winUser={el.user_count}
                                    />
                                }
							/>
						</Col>
					)
                }
            )
        },
        [lotteryData]
    )

    return (
		<Container className={`${css.container}`}>
			<Row className="skipHeader">
				<Col md={12}>
                    <BoxBackground
                        title={lotteryData.name}
                        body={
                            <div>
                                {
                                    isLoading
                                    ?
                                        Loader
                                    :
                                        <>
                                            <LotteryCountDown
                                                title='Сонгон шалгаруулалт эхлэх хугацаа'
                                                end_date={
                                                    isNaN(new Date(lotteryData?.end_date).getTime())
                                                        ? Date.now() + 100000000
                                                        : new Date(lotteryData?.end_date).getTime()
                                                }
                                                isJoinedUser={lotteryData.is_joined}
                                                zoriltotGroups={lotteryData?.zoriltot_buleg_count}
                                                name={lotteryData.name}
                                                endDate={lotteryData.end_date}
                                                wonDetail={lotteryData}
                                            />
                                        </>
                                }
                                    <BuildingInfo
                                    projectName={lotteryData?.project?.project_name}
                                    description={lotteryData?.project?.description}
                                    use={lotteryData?.project?.current_use_name}
                                    location = {
                                        {
                                            h1: lotteryData?.project?.unit1_name,
                                            h2: lotteryData?.project?.unit2_name,
                                            h3: lotteryData?.project?.unit3_name,
                                            h4: lotteryData?.project?.unit4,
                                        }
                                    }
                                    eskiz={lotteryData?.project?.eskiz}
                                />
                            </div>
                        }
                    />
				</Col>
				{displayNumber}
				{isMobile ? (
					<Col md={4} xs={6} className="mt-3 col-12">
						<BoxBackground title="Оролцогчдын ангилал харах" body={<CButton onClick={() => displayGrapic()} title="Xарах" />} />
					</Col>
				) : null}
			</Row>
			<Row>
				<Col className="mt-3 mb-4">
					{isMobile ? null : <BoxBackground title="Оролцогчдын ангилал" body={<LotteryChart data={lotteryData?.zoriltot_buleg_count} />} />}
				</Col>
			</Row>
		</Container>
	)
}

export default LotteryDetail;
