import { Col, Row } from "react-bootstrap";
import label from "utils/validate/label";

import css from '../GetOneDetail/style.module.css'

const DisplayFullArray = ({ item, subItem='name', details }) => {

    const displayArray = (item, subItem) =>
    {
        return details?.[item]?.map(
            (element, index) =>
            {
                return <span key={index}>{element?.[subItem]} {Object.keys(details?.[item]).length - 1 !== index ? ", " : '' }</span>
            }
        )
    }

    return (
        <div className="d-flex">
			<div className={css.columnOne}>
                <span className={css.fieldName} style={{ fontWeight: '500' }}>{label?.[item]}:</span>
			</div>
			<div className={css.columnTwo} >
                <span className={css.valueName}>{displayArray(item, subItem)}</span>
			</div>
		</div>
	)
}

export default DisplayFullArray;
