import React, { useState } from 'react';

import StepWay from 'components/special/StepWay';

import MailInput from './MailInput';
import SeparatedInput from './SeparatedInput';
import NewPasswords from './NewPasswords';

import css from '../../style.module.css'

const ResetPassword = ({ defaultMail='' }) => {

    const [ activeStep, setActiveStep ] = useState(0)
    const [ mail, setMail ] = useState(defaultMail);

    const steps = [
		{
			name: "И-мэйл",
			icon: "fa-solid fa-envelope",
			component: <MailInput defaultMail={defaultMail} setActiveStep={setActiveStep}  setMail={setMail}/>,
		},
		{
			name: "Баталгаажуулах",
			icon: "fas fa-shield-alt",
			component: <SeparatedInput setActiveStep={setActiveStep} />,
		},
		{
			name: "Шинэ нууц үг",
			icon: "fa-solid fa-key",
			component: <NewPasswords setActiveStep={setActiveStep} mail={mail} />,
		},
	]

    return (
        <div className={css.passReset}>
			<StepWay activeStep={activeStep} steps={steps}/>
		</div>
	)
}

export default ResetPassword;
