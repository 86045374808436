import useMapContext from "hooks/useMapContext"
import { useCallback, useEffect, useRef, useState } from "react"

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CButton from 'components/main/Button';

import useLoader from 'hooks/useLoader'

import css from '../style.module.css'

import Menu from './Menu'
import SearchResults from "./SearchResults";
import useApi from "hooks/useApi";
import { objectToQueryString } from "utils/browser";
import axios from "axios";
import { selectStyle, styleFunction, projectStepStyle, selectedAuColor, styleOfAuColor } from "hooks/map/helper";
import { HEREGJILT, HEREGJILT_COLOR } from "utils/consts";
import useToast from "hooks/useToast";

/**
    @param {} props
*/
const ProjectStep = (props) =>
{

    const { resetAllFilters, cMapState, setSelectedFeature, units, getUnitsList } = useMapContext()
    const [ fns, setFns ] = useState({})
    const [ layers, setLayers ] = useState([])
    const [ data, setData ] = useState({})
    const [ changedAuColor, setChanedAuColor ] = useState([])
    const [ ovData, setOvData ] = useState({})
    const { addToast } = useToast()

    const filters = useRef({}).current
    const api = useApi().map.search
    const { isLoading, Loader, fetchData } = useLoader({ absolute: true })

    const inputRef = useRef()

    useEffect(
        () =>
        {
            if (!units.length)
            {
                fetchData(getUnitsList())
            }
        },
        []
    )

    const clearFilter = () =>
    {
        delete filters['unit1-code']
        delete filters['unit2-code']
        delete filters['unit3-code']
        delete filters[`project_name`]
    }

    const handleClick = (event) =>
    {
        const [whatUnit, id, code, label] = event.value.split("||")
        filters[`unit${whatUnit}-code`] = code
        if (!code)
        {
            delete filters[`unit${whatUnit}-code`]
        }
    }

    /** plan ий json ийг арилгах нь */
    const removePlan = () =>
    {
        let removeIdx = []
        layers.map(
            (layer, idx) =>
            {
                if (layer.get("name") === "step-plan")
                {
                    cMapState.deleteLayer(layer)
                    removeIdx.push(idx)
                }
            }
        )
        removeIdx.map(
            (idx, index) =>
            {
                layers.splice(idx, 1)
            }
        )
    }

    const removeAllLayer = () =>
    {
        let removeIdx = []
        layers.map(
            (layer, idx) =>
            {
                cMapState.deleteLayer(layer)
                removeIdx.push(idx)
            }
        )
        removeIdx.map(
            (idx, index) =>
            {
                layers.splice(idx, 1)
            }
        )
    }

    const handlePlanlayer = (event) =>
    {
        cMapState.displayOverlay()
        const selectedFeature = event.selected?.[0]
        if (!selectedFeature)
            return

        const selectedCoordinate = event.mapBrowserEvent.coordinate;
        cMapState.displayOverlay(selectedCoordinate)
        setSelectedFeature({ feature: selectedFeature, state: "project-step" })
    }

    useEffect(
        () =>
        {
            return () =>
            {
                removeAuColor()
            }
        },
        []
    )

    /** Тухайн нэг et дээр дарах үед */
    const handleEtFeature = (event) =>
    {
        removePlan()

        const feature = event?.selected?.[0]

        if (!feature)
        {
            return
        }

        /** Тухайн feature рүү үсрэх */
        const extent = feature.getGeometry().getExtent()
        cMapState.fitToExtent(extent, { duration: 500, padding: [ 400, 0, 200, 500 ] })

        // Тухайн et ийн general plan ийг харуулах
        const project = feature.get("data")
        if (project.general_plan)
        {
            axios
                .get(process.env.REACT_APP_SERVER_URL + project.general_plan + `?dt=${Date.now()}`)
                .catch(err => err)
                .then(
                    (data) =>
                    {
                        if (data?.success !== false)
                        {
                            const features = cMapState.parseGeoJsonToFeature(data.data)
                            features.map(
                                (feat) =>
                                {
                                    feat.set("cproject", project)
                                }
                            )
                            const { vectorLayer, vectorSource } = cMapState.addVectorLayer(features, null, "step-plan", (...args) => styleFunction(...args, false))
                            cMapState.createSelect(
                                'click',
                                (evt) => handlePlanlayer(evt),
                                selectStyle,
                                [vectorLayer],
                            )
                            layers.push(vectorLayer)
                        }
                    }
                )
        }
        else {
            addToast(
                {
                    text: "Төлөвлөгөө зураг оруулаагүй байна.",
                    time: 3000,
                    type: "warning"
                }
            )
        }
    }


    const handleSearchFirst = useCallback(
        async (extra) =>
        {
            removeAllLayer()
            extra['limit'] = extra['perPage']
            const newFilters = Object.assign(filters, extra)
            const query = objectToQueryString(newFilters)
            const { success, data, errors, error } =  await fetchData(api.stepProjects(query)).catch(err => err)
            if (success)
            {
                changeColorAu(data.au)
                await fetchData(projectsOnMap(data.data))
                setData(data)
            }
        },
        [filters]
    )

    const changeColorAu = (featureText) =>
    {
        if (!featureText)
        {
            return
        }
        const features = cMapState.parseGeoJsonToFeature(featureText)
        const { vectorLayer, vectorSource } = cMapState.addVectorLayer(features, null, "step-au", selectedAuColor)
        layers.push(vectorLayer)
        // removeAuColor()
        // //  улс
        // let gsCode = "gs_au_1"
        // let code = ""

        // // аймаг хот
        // if (filters['unit1-code']) {
        //     gsCode = "gs_au_2"
        //     code = filters['unit1-code']
        // }
        // // сум дүүрэг
        // if (filters['unit2-code']) {
        //     gsCode = "gs_au_3"
        //     code = filters['unit2-code']

        // }
        // // bag horoo
        // if (filters['unit3-code']) {
        //     gsCode = "gs_au_4"
        //     code = filters['unit3-code']
        // }

        // const layer = cMapState
        //     .map
        //     .getLayers()
        //     .getArray()
        //     .find(
        //         (layer, idx) =>
        //         {
        //             return layer.get("name") === gsCode
        //         }
        //     )

        // if (!layer)
        // {
        //     return
        // }
        // const source = layer.getSource();
        // const features = source.getFeatures();
        // const feature = features.find(
        //     (f, idx) =>
        //     {
        //         return f.getProperties().localid === code
        //     }
        // )

        // if (!feature)
        // {
        //     return
        // }

        // changedAuColor.push(feature)
        // feature.setStyle(selectedAuColor)
    }

    const removeAuColor = () =>
    {
        changedAuColor.forEach(
            (f, idx) =>
            {
                f.setStyle(styleOfAuColor)
            }
        )
    }

    const jumpToBu = (vectorSource) =>
    {
        const extent = cMapState.getSourceExtent(vectorSource)
        cMapState.fitToExtent(extent, { duration: 2000, padding: [ 400, 400, 400, 400 ] })
        // changeColorAu()
    }

    /** project уудыг газрын зураг дээр харуулах */
    const projectsOnMap = async (projects) =>
    {
        let etFeatures = []
        let pointFeatures = []
        await Promise.all(projects.map(
            async (project, idx) =>
            {
                //  et file байвал et file ийг нь харуулах
                if (project.et_file)
                {
                    const data = await axios.get(process.env.REACT_APP_SERVER_URL + project.et_file + `?dt=${Date.now()}`).catch(err => err)
                    if (data?.status === 200)
                    {
                        const fdata = data.data
                        const features = cMapState.parseGeoJsonToFeature(fdata)
                        features.map(
                            (feature, idx) =>
                            {
                                // feature.set('cMap-style', styles[feature.getGeometry().getType()])
                                feature.set('text', project.name)
                                feature.set("data", project)
                                feature.set("name", "et")
                                etFeatures.push(feature)
                            }
                        )
                    }
                }
                //  байхгүй бол Point ийг газрын зурган дээр харуулах
                else if (project.general_point)
                {
                    const pointFeature = cMapState.parseGeoJsonToFeature(project.general_point)
                    pointFeature.map(
                        (feature, idx) =>
                        {
                            // feature.set('cMapState-style', styles[feature.getGeometry().getType()])
                            feature.set('text', project.name)
                            feature.set("data", project)
                            feature.set("name", "point")
                            pointFeatures.push(feature)
                        }
                    )
                }
            }
        ))
        const mapFeatures = pointFeatures.concat(etFeatures)
        if (!mapFeatures.length)
        {
            return
        }
        const { vectorLayer, vectorSource } = cMapState.addVectorLayer(mapFeatures, null, "step-data", (...args) => projectStepStyle(...args, true))
        cMapState.createSelect(
            'click',
            (evt) => handleEtFeature(evt),
            selectStyle,
            [vectorLayer],
        )
        layers.push(vectorLayer)
        cMapState.removeLoader()
        jumpToBu(vectorSource)
    }

    const handleClearFirst = useCallback(
        () =>
        {
            fns.au()
            inputRef.current.value = ""
            removeAllLayer()
            setData([])
        },
        [inputRef, fns]
    )

    const handleINput = useCallback(
        () =>
        {
            const value = inputRef.current.value
            filters[`project_name`] = value
        },
        []
    )

    return (
        <Row
            style={{
                position: "relative"
            }}
        >
            {
                isLoading && Loader
            }
            <Col sm={12}>
                <form
                    onSubmit={
                        (e) =>
                        {
                            e.preventDefault()
                            handleSearchFirst({ page: 1, perPage: 5 })
                        }
                    }
                >

                    <div className={css.choiceContainer}>
                        <h6>Засаг захиргаа нэгжийн хайлт</h6>
                        <div className="mx-2">
                            <Menu
                                onClick={handleClick}
                                fns={fns}
                                clearFilter={clearFilter}
                            />
                        </div>
                    </div>

                    <hr />

                    <div className={css.choiceContainer}>
                        <h6>Төслийн нэрийн хайлт</h6>
                        <div className="mx-2">
                            <input ref={inputRef} className="form-control" placeholder="Төслийн нэр бичиж хайх" onChange={handleINput} />
                        </div>
                    </div>

                    <hr />

                    <div className='d-flex justify-content-end align-items-end mt-2'>
                        <CButton
                            variant="primary"
                            className="ms-2"
                            type="submit"
                            onClick={(e) => {
                                e.preventDefault()
                                handleSearchFirst({ page: 1, perPage: 5 })
                            }}
                        >
                            Хайх
                        </CButton>
                        <CButton
                            variant="secondary"
                            className="ms-2"
                            style={
                                {
                                    margin: "10px"
                                }
                            }
                            onClick={handleClearFirst}
                        >
                            Болиулах
                        </CButton>
                    </div>

                    <hr />

                    <div className={css.choiceContainer}>
                        <SearchResults
                            data={data}
                            fns={fns}
                            search={handleSearchFirst}
                        />
                    </div>

                    <hr />

                    <div>
                        <ul className={css.sanamj}>
                            {/* <li className={css.sanamjLi}>
                                Тайлбар:
                                <ol>
                                    Засар захиргаагааг насны ангилалаар харах нь
                                </ol>
                            </li> */}
                            <li className={css.sanamjLi}>
                                <i className='fa fa-info-circle text-primary me-1'></i>
                                Таних тэмдэг:
                                <Row className="mx-2">
                                    {
                                        HEREGJILT
                                            .map((item, idx) =>
                                            {
                                                const key = item.value
                                                const value = item.label
                                                return <Col md={4} key={idx} className="d-flex">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <div
                                                            className="d-flex me-1"
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                                backgroundColor: HEREGJILT_COLOR[key],
                                                            }}
                                                        >
                                                        </div>
                                                        <div
                                                            className="d-flex"
                                                        >
                                                            {value}
                                                        </div>
                                                    </div>
                                                </Col>
                                            })
                                    }
                                </Row>
                            </li>
                            <li className={css.sanamjLi}>
                                <i className='fa fa-info-circle text-primary me-1'></i>
                                Санамж:
                                <ol>
                                    Хэрэв засаг захиргааны нэгжээр хайвал тухайн нэгж дотор байх төслүүдийн хэрэгжилтийг харуулна.
                                    <br />
                                    Хэрэв нэрэнд багтах барилгын мэдээллүүдийг харуулж барилгаас сонгож харах
                                </ol>
                            </li>
                        </ul>
                    </div>
                </form>
            </Col>
        </Row>
    )
}
export default ProjectStep
