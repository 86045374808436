
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import PeopleType from '.';
import PeopleTypeAction from './PeopleTypeAction'

import BoxBackground from 'components/special/BoxBackground';

const AddBigPeople = () =>
{
    return (
        <Routes>
            <Route path="/*" element={<BoxBackground title='Tөрийн байгууллага болон СЗХ ийн хүн бүртгэх' body={<PeopleType />} />} />
            <Route path="/:id/" element={<PeopleTypeAction />} />
        </Routes>
    );
}

export default AddBigPeople;
