import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import Datatable from 'components/main/Datatable';
import AccessIcons from 'components/special/AccessIcons'
import CreateButton from 'components/main/CudButtons/Create';

import useApi from 'hooks/useApi';

import { PERM_IS_SUPER_USER } from 'utils/consts';
import label from 'utils/validate/label';

const PeopleType = () => {

    const navigate = useNavigate();
    const superUserApi = useApi().user.superuser

    /** Хүснэгтийн багана */
    const columns =
    [
        {
            field: "user",
            headerName: label['email'],
            order: false,
            fieldComponent: (fieldValue, index, row) => fieldValue.email,
            searchField: 'user__email'
        },
        {
            field: "user",
            headerName: label['register'],
            order: false,
            fieldComponent: (fieldValue, index, row) => fieldValue.register,
            searchField: 'user__register'
        },
        {
            field: "user",
            headerName: label['last_name'],
            order: false,
            fieldComponent: (fieldValue, index, row) => fieldValue.last_name,
            searchField: 'user__last_name'
        },
        {
            field: "user",
            headerName: label['first_name'],
            order: false,
            fieldComponent: (fieldValue, index, row) => fieldValue.first_name,
            searchField: 'user__first_name'
        },
        {
            field: "is_government_organization",
            headerName: "Tөрийн байгууллага",
            order: false,
            fieldComponent: (fieldValue, index, row) => <AccessIcons isTrue={fieldValue} />
        },
        {
            field: "is_financial_regulatory_commission_super",
            headerName: "Санхүүгийн зохицуулах хороо",
            order: false,
            fieldComponent: (fieldValue, index, row) => <AccessIcons isTrue={fieldValue} />
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    return (
        <Row>
            <Col sm={12}>
                <CreateButton btnClassName='floatRight' onClick={() => navigate('create/')} needRoles={[PERM_IS_SUPER_USER]} />
            </Col>
            <Col>
                <Datatable
                    columns={columns}
                    sequenceColumn={sequenceColumn}
                    request={
                        {
                            axios: superUserApi.get,
                            params: [],
                        }
                    }
                />
            </Col>
        </Row>
    );
}

export default PeopleType;
