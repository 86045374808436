import React, {  useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom"
import OverlayElement from 'components/main/OverlayElement';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';
import useModal from 'hooks/useModal';

import Badge from 'components/main/Badge';

import { quantityFormat } from 'utils/format';

import css from '../style.module.css'

export const WARNING_TEXT = "Та бүртгүүлэхдээ итгэлтэй байна уу?"

const SongonList = () => {

	const params = useParams()
    const { isLoading, Loader, fetchData } = useLoader({ isFullScreen: true })
    const songonToApi = useApi().building
    const [ data, setData ] = useState([]);
    const navigate = useNavigate()
    const { showWarning } = useModal()

    const getData = async () =>
    {
        const { success, data } = await fetchData(songonToApi.getSongon(params.residenceId))
        if(success)
        {
            setData(data)
        }
    }

    useEffect(
		() =>
		{
			getData()
		},
		[]
	)

    const handleModal = (songonId, regId) =>
    {
        showWarning(
            {
                type: "warning",
                question: WARNING_TEXT,
                onClick: () => navigate(`/songon/${songonId}/register/${regId}/`),
            }
        )
    }

    const displaySongonList = useMemo(
        () =>
        {
            return data.map(
                (el, idx) =>
                {
                    let el_id = el.id
                    return (
                        <div key={idx} className={`${css.songonListCard} d-flex position-relative`}>
                            {
                                el.is_joined
                                &&
                                    <Badge
                                        text={"Бүртгүүлсэн"}
                                        style={{
                                            left: '-6px',
                                            top: '4px'
                                        }}
                                    />
                            }
                            <div style={{ width: '50%' }}>
                                <Link to={`/songon/${el.id}`}>
                                    <label className={`${css.songonName} d-block fs-13`}>{el.name}</label>
                                </Link>
                            </div>
                            <div style={{ width: '50%' }}>
                                {el.zoriltot_buleg.map(
                                    (element, idx) =>
                                    {
                                        return <div className={css.borderBottom} key={idx}>
                                                    <label className="fs-12 ps-2" style={{ width: '60%', borderRight: '1px dashed #cfbbbb' }}>
                                                        {element.name}
                                                    </label>
                                                    <label className="fs-12 text-center" style={{ width: '20%' }}>
                                                        {element.user_count}
                                                    </label>
                                                    <label className="fs-12 text-center" style={{ width: '20%' }}>
                                                        {quantityFormat(element.count)}
                                                        &nbsp;
                                                        {
                                                            !el.is_joined
                                                            ?
                                                                <OverlayElement text="Бүртгүүлэх">
                                                                    <span onClick={() => handleModal(el_id, element.id)}><i className="far fa-long-arrow-right text-darkBlue cursorPointer fs-11"></i></span>
                                                                </OverlayElement>
                                                            :
                                                                null
                                                        }
                                                    </label>
                                                </div>
                                    }
                                )}
                            </div>
                        </div>
                    )
                }
            )
        },
        [data]
    )

    return (
        <div className={css.songonListContainer}>
            {
                data.length === 0
                ?
                    <div className={css.empty}>Хоосон байна</div>
                :
                    <div>
                        <div style={{ padding: '10px' }} className='d-flex'>
                            <div style={{ width: '50%' }}>
                                <label className={`${css.titleColor} fs-12`}>Сонгон шалгаруулалт нэр:</label>
                            </div>
                            <div style={{ width: '50%' }}>
                                <label className={`${css.titleColor} fs-12`} style={{ width: '60%' }}>Зорилтот бүлэг:</label>
                                <label className={`${css.titleColor} fs-12 text-center`} style={{ width: '20%' }}>
                                    <OverlayElement text="Шалгарах хүмүүсийн тоо">
                                        <i className="far fa-trophy"></i>
                                    </OverlayElement>
                                </label>
                                <label className={`${css.titleColor} fs-12 text-center`} style={{ width: '20%' }}>
                                    <OverlayElement text="Оролцогчдын тоо">
                                        <i className="far fa-users"></i>
                                    </OverlayElement>
                                </label>
                            </div>
                        </div>
                        {displaySongonList}
                    </div>
            }
        </div>
    );
}

export default SongonList;
