import React, { useEffect, useRef, useState } from "react"

import css from './style.module.css'

const SlowInput = ({callback, delay = 1000, placeholder='Хайх...', className, style={}}) => {

	const [ loading ,setLoading ] = useState(false)

	const inputRef = useRef()

    const debounce = (cd, delay) => {
		let timeOut
        return (...args) => {
            clearTimeout(timeOut)
            timeOut = setTimeout(() => {
                cd(...args)
				setLoading(false)
				inputRef.current.classList.remove('slowInputLoading');
				inputRef.current.style.background = ''
			}, delay)
        }
    }

	const updateText = debounce(text => {
		callback(text)
	}, delay)

	return (
		<div style={{ position: "relative" }}>
			<div className={className}>
				<input
					className={`form-control ${className} ${css.searchInput}`}
					ref={inputRef}
					style={{ width: "150px", ...style }}
					placeholder={placeholder}
					onChange={e => {
						if(e.target.value.length === 0){
							updateText(e.target.value)
							inputRef.current.classList.add('slowInputLoading');
							inputRef.current.style.background = `url(${process.env.PUBLIC_URL + '/images/loading.gif'}) no-repeat right center`
						}
						if(e.target.value.length < 2) return
						updateText(e.target.value)
							inputRef.current.classList.add('slowInputLoading');
							inputRef.current.style.background = `url(${process.env.PUBLIC_URL + '/images/loading.gif'}) no-repeat right center`
						}}
				/>
			</div>
		</div>
	)
}

export default SlowInput
