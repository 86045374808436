import React, { useEffect } from 'react';

import Mobile from './Mobile'

import useMobile from 'hooks/useMobile';

const COverlay = ({ cMap, map, component, onClose, margin=40, shouldClosePopup, setShouldClosePopup, id='popupMap' }) => {


    const { isMobile } = useMobile()

    useEffect(
        () =>
        {
            if (map)
            {
                cMap.addOverlay({ margin })
            }
        },
        [map]
    );

    /** overlay ийг хаах нь */
    const handleClose = () =>
    {
        if (onClose)
            onClose()
        cMap.displayOverlay(undefined)
        cMap.clearSelect()
        setShouldClosePopup && setShouldClosePopup(false)
    }

    if (isMobile)
    {
        return <Mobile handleClose={handleClose}>{component}</Mobile>
    }

    return (
        <div id={id} className={`ol-popup ${isMobile ? "mobile" : ""}`}>
            <span className='ol-c-times' id='ol-popup-close' onClick={handleClose}>&times;</span>
            <div id="popup-content">
                {component}
            </div>
        </div>
    );
}

export default COverlay;
