import React, { forwardRef, useState } from 'react';

import Label from 'components/main/Form/Label';

import CDatepicker from '..';

import '../style.css'

/**
 * @param {String} props.label Input -ийн label-д утга өгөх
 * @param {String} props.inputPlaceholder datepicker ийн input ийн placeholder
 *
 * @param {className} props.inputClassName input -д className өгөх
 *
 * @param {array} props.errors validate ийн алдаа
 *
 * @param {object} props.register useform-д бүртгүүлэх
 *
 * @returns Date picker input ийн хамт
 */
const DatepickerInput = (props) =>
{
    const { label, inputClassName, inputPlaceholder, errors, register, onCalendarClose, onCalendarOpen, disabled } = props
    const [ isOpen, setIsOpen ] = useState(false);

    return <CDatepicker
        {...props}
        setIsOpen={setIsOpen}
        className={`
            fs-13 cursor-pointer datepickerInput
            ${!errors?.[register.name]?.message && isOpen ? "active-border" : "form-control" }
            ${inputClassName}
            ${!!errors?.[register.name]?.message ? "validateErrorBorder inputErrorIcon" : null}
        `}
        placeholderText={inputPlaceholder ? inputPlaceholder : "Сонгоно уу"}
        showDisabledMonthNavigation={true}
    />
}

export default DatepickerInput;
