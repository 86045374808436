import React, { useRef } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'

import useApi from 'hooks/useApi'

import CreateButton from 'components/main/CudButtons/Create'
import UpdateButton from 'components/main/CudButtons/Update'
import DeleteButton from 'components/main/CudButtons/Delete'
import IsSpecial from 'components/special/isSpecial';
import Datatable from 'components/main/Datatable'

import { adminForNews, adminPageRoles } from 'utils/consts/roles'
import { makeFullName } from 'utils'
import { timeZoneToDateString } from 'utils/format'
import { DESC } from 'components/main/Table/Thead'

export default function News()
{
    const newsApi = useApi().news
    const navigate = useNavigate()
    const { pageId } = useParams()
    const dRef = useRef(null)
    const needRoles = [ ...adminPageRoles, ...adminForNews ]

    /** Хүснэгтийн багана */
    const columns = [
        {
            field: "page_info",
            headerName: "Хуудасны нэр",
            order: false,
            fieldComponent: (fieldValue, index, row) => fieldValue?.page_name,
            searchField: 'page__page_name'
        },
        {
            field: "title",
            headerName: "Мэдээний гарчиг",
            order: false,
            fieldComponent: (fieldValue, index, row) => fieldValue
        },
        {
            field: "reporter_info",
            headerName: "Мэдээ оруулсан",
            order: false,
            fieldComponent: (fieldValue, index, row) => makeFullName(fieldValue?.last_name, fieldValue?.first_name),
            searchField: 'reporter__first_name'
        },
        {
            field: "is_special",
            headerName: "Онцлох эсэх",
            order: true,
            fieldComponent: (fieldValue, index, row) => <IsSpecial fieldValue={fieldValue} isStar={true} />
        },
        {
            field: "created_at",
            order: true,
            fieldComponent: (fieldValue, index, row) => timeZoneToDateString(fieldValue)
        }
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтний утгыг авах */
    const getData = async () =>
    {
        if (!dRef.current) return
        dRef.current.firstGet = true
        dRef.current.reData({}, null, [pageId])
    }

    /** Тухайн мөрийг устгах нь */
    const deleteRow = async (newsId) =>
    {
        const { success, data, error } = await newsApi.delete(pageId, newsId).catch(err => err)
        if (success)
        {
            getData()
        }
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.id}/`)} needRoles={needRoles} />
                        <DeleteButton className='me-2' onClick={() => deleteRow(item.id)} needRoles={needRoles} />
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
        <Row>
            <Col lg={12}>
                <CreateButton btnClassName='floatRight' onClick={() => navigate('create/')} needRoles={needRoles} />
            </Col>
            <Datatable
                dRef={dRef}
                columns={columns}
                sequenceColumn={sequenceColumn}
                extraColumns={extraColumns}
                request={
                    {
                        axios: newsApi.getPagination,
                        params: [pageId],
                    }
                }
                tableOptions={
                    {
                        defaultSort: {
                            type: DESC,
                            field: "created_at"
                        }
                    }
                }
            />
        </Row>
    )
}
