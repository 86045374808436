import React from 'react';
import { Route, Routes } from 'react-router-dom';

import AdvantageAction from './AdvantageAction'
import Advantage from '.';

import BoxBackground from 'components/special/BoxBackground';

const AdvantageCrudMain = () => {
    return (
        <Routes>
            <Route path="/*" element={<BoxBackground title='Давуу тал' body={<Advantage />} />} />
            <Route path="/:id/" element={<AdvantageAction/>} />
        </Routes>
    );
}

export default AdvantageCrudMain;
