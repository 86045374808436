import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import BoxBackground from "components/special/BoxBackground"
import CTable from "components/main/Table"

import useApi from "hooks/useApi"
import useLoader from "hooks/useLoader"

import { moneyFormat } from "utils/format"

const ProjectLinkedSubAgendaMain = () =>
{
    const [ projects, setProjects ] = useState([])
    const navigate = useNavigate()

    const projectLinkedSubAgendaApi = useApi().projectLinkedSubAgenda
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })

    const getData = async () =>
    {
        const { success, data } = await fetchData(projectLinkedSubAgendaApi.get()).catch(err => err)
        if(success)
        {
            setProjects(data)
        }
    }

    useEffect(
        () =>
        {
            getData()
        },
        []
    )

    /** Хүснэгтийн багана */
    const columns =
    [
        {
            field: "sub_names",
            headerName: "Шалгуур үзүүлэлтийн нэр",
            order: false,
            width: 250,
            fieldComponent: (fieldValue, index, row) => <span className='tablerow-detail' onClick={() => navigate(`${row.id}/details/`)} >{fieldValue}</span>,
        },
        {
            field: "project_name",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span className='tablerow-detail' onClick={() => navigate(`${row.id}/details/`)} >{fieldValue}</span>
        },
        {
            field: "budget_amount",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue || 0)}</span>
        },
        {
            field: "total_ail",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "approval",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "approval_date",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "use_date",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "price_mkv",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue)}</span>
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    return (
        <BoxBackground title='Төслүүд' body={
            <CTable
                rows={projects}
                isLoading={isLoading}
                columns={columns}
                sequenceColumn={sequenceColumn}
            />
        } />
    )
}

export default ProjectLinkedSubAgendaMain
