import React, { useEffect, useState } from 'react';

import { Loader, FullScreenLoader, SmallLoader } from 'components/main/Loader'
// import { useNavigate } from 'react-router-dom';

/**
 * Loading ийг хүсэлт явуулж байх хооронд харуулах эсэхийг ашиглах
 * @param {boolean} isFullScreen    бүтэн дэлгэцээр харуулах эсэх
 * @param {boolean} isSmall         Товч болон text зэргийн өмнө унших жижиг loader авах эсэх
 * @param {boolean} absolute        HTML ийн өмнө гарч ирж уншдаг эсэх
 * @param {boolean} initValue       Loader ийн анхны утга
 * @param {boolean} hasBackground   background харуулах эсэх
 */

export default function useLoader({ isFullScreen=false, isSmall=false, absolute=false, initValue=false, hasBackground=true, timeout=false, source=null})
{
    const [isLoading, setLoading] = useState(initValue);

    // const navigate = useNavigate();
    const fetchData = async (axios) =>
    {
        setLoading(true)
        const rsp = await axios
            .catch((err) => {
                if (timeout)
                {
                    setTimeout(() => {
                        setLoading(false)
                    }, 1000);
                }
                else
                {
                    setLoading(false)
                }
                // navigate('/')
                return Promise.reject(err)
            })
        if (timeout)
        {
            setTimeout(() => {
                setLoading(false)
            }, 1000);
        }
        else
        {
            setLoading(false)
        }
        return rsp
    }

    const cancel = () =>
    {
        source && source.cancel('cancel')
    }

    return { Loader: isFullScreen ? <FullScreenLoader absolute={absolute}/> : isSmall ? <SmallLoader /> : <Loader absolute={absolute} hasBackground={hasBackground}/>, fetchData, isLoading, cancel};
}
