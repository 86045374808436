import React from 'react'

import MainError from '../main'

export default function NotFound() {
    return (
        <MainError>
            Хуудас олдсонгүй
        </MainError>
    )
}
