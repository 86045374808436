import { quantityFormat } from "utils/format";

export default function PriceHover({ feature })
{

    const properties = feature.getProperties()

    return (
        <div>
            <h6>{properties['unit_name']}</h6>
            <ul>
                <li>
                    <b>Хамгийн бага үнэ:</b> {quantityFormat(properties['min_price'])}
                </li>
                <li>
                    <b>Хамгийн их үнэ:</b> {quantityFormat(properties['max_price'])}
                </li>
                <li>
                    <b>Дундаж үнэ:</b> {quantityFormat(properties['avg_price'])}
                </li>
                <li>
                    <b>Тоо хэмжээ:</b> {properties['building_count']}
                </li>
            </ul>
        </div>
    )
}
