import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import useLoader from 'hooks/useLoader';
import useApi from 'hooks/useApi';

import { moneyFormat, quantityFormat } from 'utils/format';
import DataNotFound from 'components/main/DataNotFound';
import { ONE_MONTH_TO_MILLISECONDS } from 'utils/consts';
import useMobile from 'hooks/useMobile';
import { getblueToRed, priceStyle } from 'utils/color';

const BuildingPriceChart = ({ selectedUnitType, selectedDataType, selectedUnits, units, sortType, endDate, selectedMaterial }) =>
{

	const [ chartData, setChartData ] = useState([])
	// const [ seriesNames, setSeriesNames ] = useState([])
	const [ codes, setCodes ] = useState([])

	const { isMobile } = useMobile()
	const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })

	const analytacApi = useApi({ isDisplayToast: false }).getAnalytac

	// Бүх UNIT 1,2,3 уудын code үүдийг цуглуулах функц
	const getAllUnit1 = () => setCodes(units.map(element => parseInt(element.code)))
	const getAllUnit2 = () =>
	{
		const found = units.find(
			element =>
			{
				if(element.code === selectedUnits['unit1-code'])
				{
					return element
				}
			}
		)
		setCodes(found.unit2.map(element => parseInt(element.code)))
	}

	const getAllUnit3 = () =>
	{
		const found = units.find(
			element =>
			{
				if(element.code === selectedUnits['unit1-code'])
				{
					return element
				}
			}
		).unit2.find(
			element =>
			{
				if(element.code === selectedUnits['unit2-code'])
				{
					return element
				}
			}
		)
		setCodes(found.unit3.map(element => parseInt(element.code)))
	}

	useEffect(
		() =>
		{
			let whatUWant = {
				1: getAllUnit1,
				2: getAllUnit2,
				3: getAllUnit3,
			}

			whatUWant[selectedUnitType]()

		},
		[selectedUnitType, selectedUnits, units]
	)

	useEffect(
		async () =>
		{
			if(codes.length === 0) return
			const body = {
				codes
			}
			const { success, data } = await fetchData(
				selectedDataType === 0
					? analytacApi.postHudaldaa(body, "1", Date.now() - endDate.value * ONE_MONTH_TO_MILLISECONDS, Date.now(), sortType)
					: analytacApi.postTurees(body, "1", Date.now() - endDate.value * ONE_MONTH_TO_MILLISECONDS, Date.now(), sortType),
			).catch(err => err)
			if(success)
			{
				setChartData(data)
			}
		},
		[selectedDataType, codes, sortType, endDate]
	)

	const [ materialData, setMaterialData ] = useState([])

	const materialApi = useApi({ isDisplayToast: false }).material
	useEffect(
		async () =>
		{
            if(!selectedMaterial) return
			const { success, data } = await fetchData(materialApi.getOne(selectedMaterial, Date.now() - endDate.value * ONE_MONTH_TO_MILLISECONDS, Date.now(), codes)).catch(err => err)
			if(success)
			{
                const newData = Object.values(data)
				setMaterialData(
					Object.values(newData).map(element => {
						return element.map(el => {
							return { ...el, date: new Date(el.date).getTime() }
						})
					}),
				)
			}
		},
		[selectedMaterial, endDate, codes]
	)

	useEffect(
        () =>
        {
			let root = am5.Root.new("chartdiv")

			root.setThemes([am5themes_Animated.new(root)])

			var chart = root.container.children.push(
				am5xy.XYChart.new(root, {
					panX: true,
					panY: false,
					maxTooltipDistance: 0,
					pinchZoomX: true,
				}),
			)

			chart.leftAxesContainer.set("layout", root.verticalLayout)

			var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 50 })
			xRenderer.labels.template.setAll({
				multiLocation: 0.5,
				location: 0.5,
				centerY: am5.p50,
				centerX: am5.p50,
				paddingTop: 10,
			})

			xRenderer.grid.template.set("location", 0.5)

			let xAxis = chart.xAxes.push(
				am5xy.DateAxis.new(root, {
					maxDeviation: 0.2,
					baseInterval: {
						timeUnit: "day",
						count: 1,
					},
					renderer: xRenderer,
					tooltip: am5.Tooltip.new(root, {}),
				}),
			)
			xAxis.get("dateFormats")["day"] = "MM/dd"

			var materielSeries = []

			const materialChart = () => {
				var yAxis = chart.yAxes.push(
					am5xy.ValueAxis.new(root, {
						renderer: am5xy.AxisRendererY.new(root, {}),
						tooltip: am5.Tooltip.new(root, {
							animationDuration: 0,
						}),
						x: am5.p100,
						centerX: am5.p100,
						marginTop: 50,
					}),
				)

				yAxis.axisHeader.set(
					"background",
					am5.Rectangle.new(root, {
						fillOpacity: 1,
						fill: root.interfaceColors.get("background"),
					}),
				)

				if (!materialData.length)
				{
					chart.plotContainer.children.push(
						am5.Label.new(root, {
							text: "Дата байхгүй байна",
							fontSize: "3em",
							opacity: 0.2,
							x: am5.p50,
							y: 700,
							centerY: am5.p50,
							centerX: am5.p50
						}
					));
				}

				materielSeries = materialData.map((element, index) => {
					let series = chart.series.push(
						am5xy.LineSeries.new(root, {
							name: element[0].name,
							xAxis: xAxis,
							yAxis: yAxis,
							valueYField: "price",
							valueXField: "date",
							tooltip: am5.Tooltip.new(root, {
								pointerOrientation: "horizontal",
								labelText: element[0]?.name + " {valueY}" + " ₮",
							}),
						}),
					)
					series.data.setAll(element)
					series.bullets.push(function () {
						return am5.Bullet.new(root, {
							locationY: 1,
							locationX: 0.5,
							sprite: am5.Circle.new(root, {
								radius: 4,
								fill: series.get("fill"),
							}),
						})
					})
					series.appear()
					return series
				})
			}

			var boundSeries

			const apartmentPrice = () => {
				var yAxis = chart.yAxes.push(
					am5xy.ValueAxis.new(root, {
						renderer: am5xy.AxisRendererY.new(root, {}),
						tooltip: am5.Tooltip.new(root, {
							animationDuration: 0,
						}),
						x: am5.p100,
						centerX: am5.p100,
					}),
				)

				yAxis.axisHeader.set(
					"background",
					am5.Rectangle.new(root, {
						fillOpacity: 1,
						fill: root.interfaceColors.get("background"),
					}),
				)

				if (!chartData.length)
				{
					chart.plotContainer.children.push(
						am5.Label.new(root, {
							text: "Дата байхгүй байна",
							fontSize: "3em",
							opacity: 0.2,
							x: am5.p50,
							y: 200,
							centerY: am5.p50,
							centerX: am5.p50
						}
					));
				}

				boundSeries = chartData.map((element, id) => {
					const color = getblueToRed(id, 1, chartData.length - 1)
					let series = chart.series.push(
						am5xy.LineSeries.new(root, {
							name: element.name,
							xAxis: xAxis,
							yAxis: yAxis,
							valueYField: "_price",
							valueXField: "day",
							legendValueText: "{valueY}" + " ₮",
							legendRangeValueText: quantityFormat(parseInt(element.data[element.data.length - 1]._price)) + " ₮",
							tooltip: am5.Tooltip.new(root, {
								pointerOrientation: "horizontal",
								labelText: element.name + ": {valueY}" + " ₮",
							}),
							fill: color,
							stroke: color
						}),
					)
					if (element.code === selectedUnits[`unit${selectedUnitType}-code`]) {
						series.strokes.template.setAll({
							strokeWidth: 3,
						})
					} else {
						series.strokes.template.setAll({
							strokeWidth: 1,
						})
					}
					series.bullets.push(function () {
						return am5.Bullet.new(root, {
							locationY: 1,
							locationX: 0.5,
							sprite: am5.Circle.new(root, {
								radius: 4,
								fill: series.get("fill"),
							}),
						})
					})
					series.unitCode = element.code
					series.data.setAll(
						element.data.map(element => {
							return { _price: parseInt(element._price), day: new Date(element.day).getTime() }
						}),
					)

					series.appear()
					return series
				})
			}

			apartmentPrice()
			materialChart()

			let cursor = chart.set(
				"cursor",
				am5xy.XYCursor.new(root, {
					behavior: "none",
				}),
			)
			cursor.lineY.set("visible", false)

			if(!isMobile)
			{
				let boundLegend

				boundLegend = chart.rightAxesContainer.children.push(
					am5.Legend.new(root, {
						width: 250,
						paddingLeft: 0,
						height: 500,
						verticalScrollbar: am5.Scrollbar.new(root, {
							orientation: "vertical",
						}),
					}),
				)

				let materialLegend = chart.rightAxesContainer.children.push(
					am5.Legend.new(root, {
						width: 250,
						paddingLeft: 0,
						height: 500,
						x: 0,
						y: 500,
						verticalScrollbar: am5.Scrollbar.new(root, {
							orientation: "vertical",
						}),
					}),
				)

				boundLegend.itemContainers.template.events.on("pointerover", function (e) {
					let itemContainer = e.target

					let series = itemContainer.dataItem.dataContext

					chart.series.each(function (chartSeries) {
						if (chartSeries != series) {
							chartSeries.strokes.template.setAll({
								strokeOpacity: 0.15,
								stroke: am5.color(0x000000),
							})
						} else {
							chartSeries.strokes.template.setAll({
								strokeWidth: 3,
							})
						}
					})
				})

				boundLegend.itemContainers.template.events.on("pointerout", function (e) {
					let itemContainer = e.target
					let series = itemContainer.dataItem.dataContext

					chart.series.each(function (chartSeries) {
						chartSeries.strokes.template.setAll({
							strokeOpacity: 1,
							strokeWidth: 1,
							stroke: chartSeries.get("fill"),
						})
					})
				})

				boundLegend.itemContainers.template.set("width", am5.p100)
				boundLegend.valueLabels.template.setAll({
					width: am5.p100,
					textAlign: "right",
				})

				materialLegend.data.setAll(materielSeries)
				boundLegend.data.setAll(boundSeries)
			}

			chart.set(
				"scrollbarX",
				am5.Scrollbar.new(root, {
					orientation: "horizontal",
				}),
			)

			chart.appear(1000, 100)
			return () => {
				root.dispose()
			}
		},
        [chartData, isMobile, materialData]
    )

    return (
		<div id="chartdiv" style={{ width: "100%", height: "1020px", position: 'relative' }}>
			<div className="hidelogo"></div>
		</div>
	)
}

export default BuildingPriceChart
