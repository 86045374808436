import { checkEmpty, checkStatement } from "utils"
import { moneyFormat, quantityFormat, timeZoneToDateString } from "utils/format"
import { quantity } from "utils/validate"
import label from "utils/validate/label"

export const tableColumns1 = {
	columns: [
		{
            field: "zuuch_baig_ner",
            order: false,
            fieldComponent: (fieldValue, index, row) => {
			return (<span>{checkEmpty(fieldValue)}</span>)},
            hide: true
        },
		{
			field: "hudaldagch_ner",
			headerName: 'Иргэн, хуулийн этгээдийн нэр',
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue)}</span>,
		},
		{
			field: "hudaldagch_register",
			headerName: 'Регистрийн дугаар',
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue)}</span>,
		},
		{
			field: "hudaldagch_itgemjleleer_toloolj_bui",
			headerName: 'Итгэмжлэлээр төлөөлж буй эсэх',
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkStatement(fieldValue)}</span>,
		},

		{
			field: "hariltsagch_info",
			headerName: 'Иргэн, хуулийн этгээдийн нэр',
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.irgen_huuliin_etgeedin_ner)}</span>,
		},
		{
			field: "hariltsagch_info",
			headerName: 'Регистрийн дугаар',
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.register)}</span>,
		},
		{
			field: "itgemjleleer_toloolj_bui",
			headerName: 'Итгэмжлэлээр төлөөлж буй эсэх',
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkStatement(fieldValue)}</span>,
		},
		{
			field: "ipotek_hamragdaj_baisan_eseh",
			headerName: 'Ипотекийн зээлд хамрагдсан эсэх',
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkStatement(fieldValue)}</span>,
		},
		{
			field: "ul_hudluh_hurungiin_turul_display",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue)}</span>,
			searchField: false,
			hide: true,
		},
		{
			field: "ul_hudluh_hurungiin_turul_v2_display",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue)}</span>,
			searchField: false,
			hide: true,
		},
		{
			field: "ul_hudluh_hurungiin_turul_v3_display",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue)}</span>,
			searchField: false,
			hide: true,
		},
		{
			field: "uil_ajillagaanii_turul_display",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue)}</span>,
			searchField: false,
			hide: true,
		},
		{
			field: "bairshil_info",
			headerName: label['county'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.county)}</span>,
			searchField: false,
		},
		{
			field: "bairshil_info",
			headerName: label['aimar_hot_info'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.aimar_hot_info?.name)}</span>,
			searchField: false,
		},
		{
			field: "bairshil_info",
			headerName: label['sum_duureg_info'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.sum_duureg_info?.name)}</span>,
			searchField: false,
		},
		{
			field: "mkv",
			fieldComponent: (fieldValue, index, row) => <span>{quantityFormat(fieldValue)}</span>,
			searchField: false,
            hide: true
		},
		{
			field: "price",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue)}</span>,
			searchField: false,
            hide: true
		},
		{
			field: "price",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{(row?.mkv || 0) * (fieldValue || 0)}</span>,
			searchField: false,
            hide: true
		},
		{
			field: "ul_hodloh_horongiin_gerchilge_dugar",
			headerName: label['ul_hodloh_horongiin_gerchilge_dugar'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue)}</span>,
			searchField: false,
		},
		{
			field: "ul_hodloh_horongiin_zah_gere_dugar",
			headerName: label['ul_hodloh_horongiin_zah_gere_dugar'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue)}</span>,
			searchField: false,
		},
		{
			field: "geree__ognoo",
			headerName: label['ognoo'],
			order: true,
			fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue, false)}</span>,
			searchField: false,
			minWidth: 100
		},
		{
			field: "geree_info",
			headerName: label['dugaar'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.dugaar)}</span>,
			searchField: false,
		},
		{
			field: "geree__shimtgel_dun",
			headerName: label['shimtgel_dun'],
			order: true,
			fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue || 0)}</span>,
			searchField: false,
		},
		{
			field: "geree__huwi",
			headerName: label['huwi'],
			order: true,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(row?.geree_info?.huwi || 0)}%</span>,
			searchField: false,
		},
		{
			field: "medeelliin_ezen_zovshorson_eseh",
			headerName: label['medeelliin_ezen_zovshorson_eseh'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkStatement(fieldValue)}</span>,
			hide: true,
		},
		{
			field: "created_at",
			order: false,
			hide: true,
			fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue, false)}</span>,
			searchField: false,
		},
	],

	colSpanHeader: [
		{
			rowspan: 2,
			label: 'zuuch_baig_ner',
		},
		{
			colspan: 3,
			label: 'hudaldagch'
		},
		{
			colspan: 4,
			label: 'hudaldan_avagch'
		},
		{
			rowspan: 2,
			label: 'ul_hudluh_hurungiin_turul',
		},
		{
			rowspan: 2,
			label: 'ul_hudluh_hurungiin_turul_v2',
		},
		{
			rowspan: 2,
			label: 'ul_hudluh_hurungiin_turul_v3',
		},
		{
			rowspan: 2,
			label: 'uil_ajillagaanii_turul',
		},
		{
			colspan: 3,
			label: "real_estate_location",
		},
		{
			order: true,
			rowspan: 2,
			label: "mkv",
			field: "mkv",
		},
        {
			rowspan: 2,
			label: "cost",
			order: true,
			field: "price",
		},
		{
			rowspan: 2,
			label: "niit_une_urtug",
		},
		{
			colspan: 2,
			label: "uhh_iin_medeelel",
		},
		{
			colspan: 4,
			label: "zuuchlaliin_geree",
		},
		{
			rowspan: 2,
			label: "medeelliin_ezen_zovshorson_eseh",
		},
		{
			rowspan: 2,
			order: true,
			label: "created_at",
			field: "created_at",
		},
	],
}

export const tableColumns2 = {
    columns: [
		{
            field: "zuuch_baig_ner",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            hide: true
        },
		{
			field: "company_info",
			headerName: label['company_name'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.company)}</span>,
		},
		{
			field: "created_by_info",
			headerName: label['created_by'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.user_info?.first_name)}</span>,
		},
		{
			field: "hariltsagch_info",
			headerName: label['irgen_huuliin_etgeedin_ner'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.irgen_huuliin_etgeedin_ner)}</span>,
		},
		{
			field: "hariltsagch_info",
			headerName: label['register'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.register)}</span>,
		},
        {
            field: "ul_hudluh_hurungiin_turul_display",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            hide: true
        },
        {
            field: "uil_ajillagaanii_turul_display",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            hide: true
        },
        {
			field: "county",
			headerName: label['county'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(row?.bairshil_info?.county)}</span>,
		},
		{
			field: "aimar_hot_info",
			headerName: label['aimar_hot_info'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(row?.bairshil_info?.aimar_hot_info?.name)}</span>,
		},
		{
			field: "sum_duureg_info",
			headerName: label['sum_duureg_info'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(row?.bairshil_info?.sum_duureg_info?.name)}</span>,
		},
		{
			field: "mkv",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{quantityFormat(fieldValue)}</span>,
            hide: true
		},
        {
			field: "per_month_price",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            hide: true
		},
		{
			field: "two_month_price",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            hide: true
		},
		{
			field: "time",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            hide: true
		},
        {
			field: "gereenii_hugatsaa",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            hide: true
		},
        {
			field: "total_price",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue)}</span>,
            hide: true
		},
		{
			field: "geree__ognoo",
			headerName: label['ognoo'],
			order: true,
			fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue, false)}</span>,
			minWidth: 100
		},
		{
			field: "geree_info",
			headerName: label['dugaar'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.dugaar)}</span>,
		},
		{
			field: "geree_info",
			headerName: label['geree_gerchilgee_dugaar'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.geree_gerchilgee_dugaar)}</span>,
		},
		{
			field: "geree__shimtgel_dun",
			headerName: label['shimtgel_dun'],
			order: true,
			fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue || 0)}</span>,
		},
		{
			field: "geree__huwi",
			headerName: label['huwi'],
			order: true,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(row?.geree_info?.huwi)}%</span>,
		},
		{
			field: "geree_info",
			headerName: label['medeelliin_ezen_zovshorson_eseh'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkStatement(fieldValue)}</span>,
			hide: true,
		},
		{
			field: "created_at",
			order: false,
			hide: true,
			fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue, false)}</span>,
			searchField: false,
		},
	],

	colSpanHeader: [
		{
			rowspan: 2,
			label: 'zuuch_baig_ner',
		},
		{
			colspan: 2,
			label: "company"
		},
		{
			colspan: 2,
			label: "customer",
		},
		{
			rowspan: 2,
			label: 'ul_hudluh_hurungiin_turul_display',
		},
		{
			rowspan: 2,
			label: "uil_ajillagaanii_turul",
		},
		{
			colspan: 3,
			label: "real_estate_location",
		},
        {
			rowspan: 2,
			label: "mkv",
			field: "mkv",
			order: true,
		},
        {
			rowspan: 2,
			label: "per_month_price",
			field: "per_month_price",
			order: true,
		},
		{
			rowspan: 2,
			label: "two_month_price",
			field: "two_month_price",
			order: true,
		},
		{
			rowspan: 2,
			label: "time",
			field: "time",
			order: true,
		},
        {
			rowspan: 2,
			label: "gereenii_hugatsaa",
			field: "gereenii_hugatsaa",
			order: true,
		},
        {
			rowspan: 2,
			label: "total_price",
			order: true,
			field: "total_price",
		},
        {
			colspan: 5,
			label: "contract",
		},
		{
			rowspan: 2,
			label: "medeelliin_ezen_zovshorson_eseh",
		},
		{
			rowspan: 2,
			order: true,
			label: "created_at",
			field: "created_at",
		},
	],
}

export const tableColumns3 = {
	columns: [
		{
			field: "hariltsagch_info",
			headerName: label['irgen_huuliin_etgeedin_ner'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.irgen_huuliin_etgeedin_ner)}</span>,
		},
		{
			field: "hariltsagch_info",
			headerName: label['register'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.register)}</span>,
		},
		{
			field: "ul_hudluh_hurungiin_turul_display",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
			hide: true,
		},
		{
			field: "bairshil_info",
			headerName: label['county'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.county)}</span>,
		},
		{
			field: "bairshil_info",
			headerName: label['aimar_hot_info'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.aimar_hot_info?.name)}</span>,
		},
		{
			field: "bairshil_info",
			headerName: label['sum_duureg_info'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.sum_duureg_info?.name)}</span>,
		},
		{
			field: "mkv",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{quantityFormat(fieldValue)}</span>,
			hide: true,
		},
		{
			field: "hudaldaj_awsan_une",
			headerName: label['real_estate_cost'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue)}</span>,
			hide: true,
		},
		{
			field: "zaswar_zardal",
			headerName: label['upgrade_cost'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue)}</span>,
			hide: true,
		},
		{
			field: "zarsan_une",
			headerName: label['sold_price'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue)}</span>,
			hide: true,
		},
		{
			field: "olz_garz",
			headerName: label["down_or_up"],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue)}</span>,
			hide: true,
		},
		{
			field: "geree_info",
			headerName: "Огноо",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue?.ognoo, false)}</span>,
		},
		{
			field: "geree_info",
			headerName: label['dugaar'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.dugaar)}</span>,
		},
		{
			field: "geree_info",
			headerName: label['geree_gerchilgee_dugaar'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.geree_gerchilgee_dugaar)}</span>,
		},
	],

	colSpanHeader: [
		{
			colspan: 2,
			label: "customer",
		},
		{
			rowspan: 2,
			label: "ul_hudluh_hurungiin_turul_display",
		},
		{
			colspan: 3,
			label: "ul_hudluh_hurungiin_turul_display",
		},
		{
			rowspan: 2,
			label: "mkv",
		},
		{
			rowspan: 2,
			label: "real_estate_cost",
		},
		{
			rowspan: 2,
			label: "upgrade_cost",
		},
		{
			rowspan: 2,
			label: "sold_price",
		},
		{
			rowspan: 2,
			label: "down_or_up",
		},
		{
			colspan: 3,
			label: "real_estate_traded_contract",
		},
	],
}


export const tableColumns4 = {
	columns: [
		{
			field: "hariltsagch_info",
			headerName: label['irgen_huuliin_etgeedin_ner'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.irgen_huuliin_etgeedin_ner)}</span>,
		},
		{
			field: "hariltsagch_info",
			headerName: label['register'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.register)}</span>,
		},
		{
			field: "hariltsagch_info",
			headerName: label['irget_huuliin_etgeedin_haryalal'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.irget_huuliin_etgeedin_haryalal)}</span>,
		},
		{
            field: "ul_hudluh_hurungiin_turul_display",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
			hide: true,
		},
        {
            field: "uilchilgee_description",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            hide: true,
        },
		{
			field: "bairshil_info",
			headerName: label['county'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.county)}</span>,
		},
		{
			field: "bairshil_info",
			headerName: label['aimar_hot_info'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.aimar_hot_info?.name)}</span>,
		},
		{
			field: "bairshil_info",
			headerName: label['sum_duureg_info'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.sum_duureg_info?.name)}</span>,
		},
		{
			field: "mkv",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{quantityFormat(fieldValue || 0)}</span>,
			hide: true,
		},
		{
			field: "geree_info",
			headerName: label['ognoo'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue?.ognoo)}</span>,
			minWidth: 100
		},
		{
			field: "geree_info",
			headerName: label['dugaar'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.dugaar)}</span>,
		},
		{
			field: "geree_info",
			headerName: label['niit_dun'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue?.niit_dun || 0)}</span>,
		},
		{
			field: "geree_info",
			headerName: label['shimtgel_dun'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue?.shimtgel_dun || 0)}</span>,
		},
		{
			field: "geree_info",
			headerName: label['huwi'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.huwi)}%</span>,
		},
	],

	colSpanHeader: [
		{
			colspan: 3,
			label: "customer",
		},
		{
			rowspan: 2,
			label: "ul_hudluh_hurungiin_turul_display",
		},
		{
			rowspan: 2,
			label: "uilchilgee_description",
		},
		{
			colspan: 3,
			label: "real_estate_location",
		},
		{
			rowspan: 2,
			label: "mkv",
		},
		{
			colspan: 5,
			label: "menegenment_service",
		},
	],
}

export const tableColumns5 = {
	columns: [
		{
			field: "hariltsagch_info",
			headerName: label['irgen_huuliin_etgeedin_ner'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.irgen_huuliin_etgeedin_ner)}</span>,
		},
		{
			field: "hariltsagch_info",
			headerName: label['register'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.register)}</span>,
		},
		{
			field: "hariltsagch_info",
			headerName: label['irget_huuliin_etgeedin_haryalal'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{checkEmpty(fieldValue?.irget_huuliin_etgeedin_haryalal)}</span>,
		},
        {
            field: "uilchilgee_description",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            hide: true,
        },
		{
            field: "uilchilgee_total_price",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue || 0)}</span>,
            hide: true,
        },
	],

	colSpanHeader: [
		{
			colspan: 3,
			label: "customer",
		},
		{
			rowspan: 2,
			label: "uilchilgee_description",
		},
		{
			rowspan: 2,
			label: "uilchilgee_total_price",
		},
	],
}

export const tableColumns6 = {
	columns: [
		{
			field: "irgen_name",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
			hide: true,
		},
		{
			field: "register",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
			hide: true,
		},
		{
			field: "guilgee_dun",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue)}</span>,
			hide: true,
		},
		{
			field: "guilgee_turul_display",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
			hide: true,
		},
		{
			field: "guilgee_ognoo",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue, false)}</span>,
			hide: true,
			minWidth: 100
		},
		{
			field: "SMA_medegdsen_ognoo",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue, false)}</span>,
			hide: true,
			minWidth: 100
		},
		{
			field: "hariltsagchdiin_too",
			headerName: label['hariltsagchdiin_too'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{quantityFormat(fieldValue)}</span>,
		},
		{
			field: "NUBAZ_horig_tulgsan_hariltsagchdiin_too",
			headerName: label['NUBAZ_horig_tulgsan_hariltsagchdiin_too'],
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{quantityFormat(fieldValue)}</span>,
		},
	],

	colSpanHeader: [
        {
            rowspan: 2,
			label: "irgen_name",
		},
		{
            rowspan: 2,
			label: "register",
		},
        {
            rowspan: 2,
			label: "guilgee_dun",
		},
		{
            rowspan: 2,
			label: "guilgee_turul_display",
		},
        {
            rowspan: 2,
			label: "guilgee_ognoo",
		},
		{
            rowspan: 2,
			label: "SMA_medegdsen_ognoo",
		},
        {
            colspan: 2,
            label: "customrs_info",
        },
	],
}

/** OC1 тайлангийн fields */
export const tableColumns7 = {
	columns: [
		{
            field: "unit1",
            order: true,
            fieldComponent: (fieldValue, index, row) =>  <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'unit1',
			hide: true,
        },
		{
            field: "unit2",
            order: true,
            fieldComponent: (fieldValue, index, row) =>  <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'unit2',
			hide: true,
        },
		{
            field: "unit3",
            order: true,
            fieldComponent: (fieldValue, index, row) =>  <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'unit3',
			hide: true,
        },
        {
            field: "unit4",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span style={{ fontSize: '11px' }}>{fieldValue}</span>,
            searchField: 'unit4',
			hide: true,
			minWidth: 250
        },
        {
            field: "passport_number",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'passport_number',
			hide: true,
        },
		{
            field: "housing_type",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "omch_helber",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "bairshil",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "niit_suutsnii_too",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'niit_suutsnii_too',
			hide: true,
        },
		{
            field: "oroonii_too_1",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'oroonii_too_1'
        },
		{
            field: "oroonii_too_2",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'oroonii_too_2'
        },
		{
            field: "oroonii_too_3",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'oroonii_too_3'
        },
		{
            field: "oroonii_too_4",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'oroonii_too_4'
        },
		{
            field: "oroonii_too_5",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'oroonii_too_5'
        },
		{
            field: "oroonii_too_6",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'oroonii_too_6'
        },
		{
            field: "oroonii_too_7_plus",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'oroonii_too_7_plus'
        },
		{
            field: "aguulah_oroonii_too",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'aguulah_oroonii_too',
			hide: true
        },
		{
            field: "barilga_niit_talbai",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'barilga_niit_talbai',
			hide: true
        },
		{
            field: "suutsnii",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'suutsnii',
        },
		{
            field: "aguulahiin",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'aguulahiin',
        },
		{
            field: "uilchilgeenii",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'uilchilgeenii',
        },
		{
            field: "avto_zogsool",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'avto_zogsool',
        },
		{
            field: "tsever_usnii_hangamj",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'tsever_usnii_hangamj',
        },
		{
            field: "haluun_usnii_hangamj",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'haluun_usnii_hangamj',
        },
		{
            field: "ariutgah_tatuurga",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'ariutgah_tatuurga',
        },
		{
            field: "halaalt",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'halaalt',
        },
		{
            field: "davhriin_too",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "ortsnii_too",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "tsahilgaan_shatnii_too",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "niit_avto_zogsool",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "dulaan_avto_zogsool",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'dulaan_avto_zogsool',
        },
		{
            field: "gadna_avto_zogsool",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'gadna_avto_zogsool',
        },
		{
            field: "barilgiin_undsen_hiits",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "naluu_zamtai_eseh",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "gadna_tohijiltiin_talbai",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "huuhdiin_togloomiin_talbai",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "suutsnii_mkv_talbain_une",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "niit_ortog",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "tosviin_horongo_oruulalt",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
        },
		{
            field: "aanb_horongo_oruulalt",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
        },
		{
            field: "gadaadiin_horongo_oruulalt",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
        },
		{
            field: "unet_tsaas_hudaldsan_orlogo",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
        },
		{
            field: "banknii_zeel",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
        },
		{
            field: "busad",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
        },
		{
            field: "dan_ganz_barilga_eseh",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true
        },
		{
            field: "urtrag",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
        },
		{
            field: "orgorog",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
        },
	],

	colSpanHeader: [
        {
			order: true,
            rowspan: 2,
			label: "unit1",
			field: "unit1",
		},
		{
			order: true,
            rowspan: 2,
			label: "unit2",
			field: "unit2",
		},
		{
			order: true,
            rowspan: 2,
			label: "unit3",
			field: "unit3",
		},
		{
			order: true,
            rowspan: 2,
			label: "unit4",
			field: "unit4",
		},
		{
			order: true,
            rowspan: 2,
			label: "passport_number",
			field: "passport_number",
		},
		{
			order: true,
            rowspan: 2,
			label: "housing_type",
			field: "housing_type",
		},
		{
			order: true,
            rowspan: 2,
			label: "omch_helber",
			field: "omch_helber",
		},
		{
			order: true,
            rowspan: 2,
			label: "bairshil",
			field: "bairshil",
		},
		{
			order: true,
			rowspan: 2,
			label: "niit_suutsnii_too",
			field: "niit_suutsnii_too",
		},
		{
            colspan: 7,
            label: "oroonii_toogoor",
        },
		{
			order: true,
			rowspan: 2,
			label: "aguulah_oroonii_too",
			field: "aguulah_oroonii_too",
		},
		{
			order: true,
			rowspan: 2,
			label: "barilga_niit_talbai",
			field: "barilga_niit_talbai",
		},
		{
            colspan: 4,
            label: "zoriulaltaar",
        },
		{
			colspan: 4,
			label: "ded_butziin_uzuuleltuud"
		},
		{
			order: true,
			rowspan: 2,
			label: "davhriin_too",
			field: "davhriin_too",
		},
		{
			order: true,
			rowspan: 2,
			label: "ortsnii_too",
			field: "ortsnii_too",
		},
		{
			order: true,
			rowspan: 2,
			label: "tsahilgaan_shatnii_too",
			field: "tsahilgaan_shatnii_too",
		},
		{
			order: true,
			rowspan: 2,
			label: "niit_avto_zogsool",
			field: "niit_avto_zogsool",
		},
		{
			colspan: 2,
			label: "torloor"
		},
		{
			order: true,
			rowspan: 2,
			label: "barilgiin_undsen_hiits",
			field: "barilgiin_undsen_hiits",
		},
		{
			order: true,
			rowspan: 2,
			label: "naluu_zamtai_eseh",
			field: "naluu_zamtai_eseh",
		},
		{
			order: true,
			rowspan: 2,
			label: "gadna_tohijiltiin_talbai",
			field: "gadna_tohijiltiin_talbai",
		},
		{
			order: true,
			rowspan: 2,
			label: "huuhdiin_togloomiin_talbai",
			field: "huuhdiin_togloomiin_talbai",
		},
		{
			order: true,
			rowspan: 2,
			label: "suutsnii_mkv_talbain_une",
			field: "suutsnii_mkv_talbain_une",
		},
		{
			order: true,
			rowspan: 2,
			label: "niit_ortog",
			field: "niit_ortog",
		},
		{
			colspan: 6,
			label: "eh_uusvereer"
		},
		{
			order: true,
			rowspan: 2,
			label: "dan_ganz_barilga_eseh",
			field: "dan_ganz_barilga_eseh",
		},
		{
			colspan: 2,
			label: "bairshliin_tseg"
		},
	]
}

/** OC2 тайлангийн fields */
export const tableColumns8 = {
	columns: [
		{
            field: "unit1",
            order: true,
            fieldComponent: (fieldValue, index, row) =>  <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'unit1',
			hide: true,
        },
		{
            field: "unit2",
            order: true,
            fieldComponent: (fieldValue, index, row) =>  <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'unit2',
			hide: true,
        },
		{
            field: "unit3",
            order: true,
            fieldComponent: (fieldValue, index, row) =>  <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'unit3',
			hide: true,
        },
        {
            field: "unit4",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span style={{ fontSize: '11px' }}>{fieldValue}</span>,
            searchField: 'unit4',
			hide: true,
			minWidth: 250,
        },
        {
            field: "passport_number",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'passport_number',
			hide: true,
        },
		{
            field: "housing_type",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "omch_helber",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
			field: "ahiglaltad_oruulsan_on",
			order: true,
			fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
			searchField: false,
			hide: true,
		},
		{
            field: "niit_suuts",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'niit_suuts',
			hide: true,
        },
		{
            field: "oroonii_too_1",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'oroonii_too_1'
        },
		{
            field: "oroonii_too_2",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'oroonii_too_2'
        },
		{
            field: "oroonii_too_3",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'oroonii_too_3'
        },
		{
            field: "oroonii_too_4",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'oroonii_too_4'
        },
		{
            field: "oroonii_too_5",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'oroonii_too_5'
        },
		{
            field: "oroonii_too_6",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'oroonii_too_6'
        },
		{
            field: "oroonii_too_7_plus",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'oroonii_too_7_plus'
        },
		{
            field: "barilga_niit_talbai",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'barilga_niit_talbai',
			hide: true
        },
		{
            field: "suutsnii",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'suutsnii',
        },
		{
            field: "aguulahiin",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'aguulahiin',
        },
		{
            field: "uilchilgeenii",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'uilchilgeenii',
        },
		{
            field: "avto_zogsool",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'avto_zogsool',
        },

		{
            field: "davhriin_too",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "ortsnii_too",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "tsahilgaan_shatnii_too",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "niit_avto_zogsool",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
			hide: true,
        },
		{
            field: "barilgiin_undsen_hiits",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: 'barilgiin_undsen_hiits',
			hide: true,
        },
		{
            field: "urtrag",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
        },
		{
            field: "orgorog",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue ? fieldValue : "-"}</span>,
            searchField: false,
        },
		{
            field: "shiidver_garsan_on",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span style={{ fontSize: '11px' }}>{fieldValue}</span>,
            searchField: false,
			hide: true,
			minWidth: 250,
        },
		{
            field: "buulgasan_shaltgaan",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span style={{ fontSize: '11px' }}>{fieldValue}</span>,
            searchField: false,
			hide: true,
			minWidth: 250,
        },
	],

	colSpanHeader: [
        {
            rowspan: 2,
			label: "unit1"
		},
		{
            rowspan: 2,
			label: "unit2"
		},
		{
            rowspan: 2,
			label: "unit3"
		},
		{
            rowspan: 2,
			label: "unit4"
		},
		{
            rowspan: 2,
			label: "passport_number"
		},
		{
            rowspan: 2,
			label: "housing_type"
		},
		{
            rowspan: 2,
			label: "omch_helber"
		},
		{
            rowspan: 2,
			label: "ahiglaltad_oruulsan_on"
		},
		{
			rowspan: 2,
			label: "niit_suuts"
		},
		{
            colspan: 7,
            label: "oroonii_toogoor",
        },
		{
			rowspan: 2,
			label: "barilga_niit_talbai"
		},
		{
            colspan: 4,
            label: "zoriulaltaar",
        },
		{
			rowspan: 2,
			label: "davhriin_too"
		},
		{
			rowspan: 2,
			label: "ortsnii_too"
		},
		{
			rowspan: 2,
			label: "tsahilgaan_shatnii_too"
		},
		{
			rowspan: 2,
			label: "niit_avto_zogsool"
		},
		{
			rowspan: 2,
			label: "barilgiin_undsen_hiits"
		},
		{
			colspan: 2,
			label: "bairshliin_tseg"
		},
		{
			rowspan: 2,
			label: "shiidver_garsan_on"
		},
		{
			rowspan: 2,
			label: "buulgasan_shaltgaan"
		}
	]
}
