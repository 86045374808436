import React, { useEffect, useMemo, useState } from 'react'

import Form from 'components/main/Form'

import useLoader from 'hooks/useLoader'
import useApi from 'hooks/useApi'

import { oldSongonWinnerValidateExtra, oldSongonWinnerValidateLess } from 'utils/validate'
import { dataToValueLabel } from 'utils/format'

const OldSongonWinner = ({ songonId, setShow }) =>
{
    const [ extraInput, setExtraInput ] = useState(false)
    const [ inputValues, setInputValues ] = useState({})
    const [ groupType, setGroupType ] = useState([])
    const [ orgTypes, setOrgTypes ] = useState([])

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })

    const orgTypeApi = useApi().orgType
    const songonApi = useApi().songon

    useEffect(
        async () =>
        {
            Promise.all([fetchData(songonApi.getOne(songonId)), fetchData(orgTypeApi.get())]).then(
                resp =>
                {
                    setGroupType(dataToValueLabel(resp[0].data.zoriltot_buleg_count, "id", "name"))
                    setOrgTypes(dataToValueLabel(resp[1].data, "id", "name"))
                }
            )
        },
        []
    )

    const sendFormData = async (formData) =>
    {
        if(!extraInput)
        {
            formData.income_exp_info = "income_exp_info"
            formData.until_work_year = "until_work_year"
            formData.work_position = "work_position"
            formData.org_work_year = "org_work_year"
            formData.work_place = "work_place"
            formData.org_type = 3
        }
        const { success, data, errors } = await fetchData(songonApi.oldSongon.winners(songonId, formData)).catch(err => err)
        if( success )
        {
            setShow(false)
            return
        }
        return errors
    }

    const displayExtraInputs = useMemo(() =>
    {
        if(!extraInput) return []
        return [
            {
                component: <hr/>
            },
            {
                registerName: "org_type",
                inputType: "select",
                options: orgTypes
            },
            {
                registerName: "condition",
                inputType: "input",
                type: "text",
            },
            {
                registerName: "until_work_year",//
                inputType: "input",
                type: "text",
            },
            {
                registerName: "income_exp_info",//
                inputType: "input",
                type: "text",
            },
            {
                registerName: "work_position",//
                inputType: "input",
                type: "text",
            },
            {
                registerName: "org_work_year",//
                inputType: "input",
                type: "text",
            },
            {
                registerName: "work_place",//
                inputType: "input",
                type: "text",
            },
        ]
    },[extraInput])

    const from =
    {
        inputs: [
            {
                inputType: "radio",
                type: "checkbox",
                registerName: "is_special",
                onChange: (event) => setExtraInput(event.target.checked) ,
                options: [
                    { label: "Дэлгэрэнгүй мэдээлэл бөглөх эсэх" },
                ],
                label: {
                    text: " ",
                    empty: true,
                },
                colMd: 12
            },
            {
                registerName: "register",
                inputType: "input",
                type: "text",
            },
            {
                registerName: "zoriltot_buleg",
                inputType: "select",
                options: groupType
            },
            ...displayExtraInputs,
        ],
        button:
        {
            onSubmit: sendFormData,
            title: "Үүсгэх",
            isLoading
        },
        validate:
        {
            yupValidate: extraInput ? oldSongonWinnerValidateExtra : oldSongonWinnerValidateLess,
            validateMode: "all",
        }
    }

    return (
		<>
			<Form inputValues={inputValues} form={from} />
		</>
	)
}

export default OldSongonWinner
