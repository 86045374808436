import React, { useState, useMemo, Fragment, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    CCloseButton,
    CContainer,
    CNavbar,
    CNavbarBrand,
    CNavbarNav,
    CNavbarToggler,
    COffcanvas,
    COffcanvasBody,
    COffcanvasHeader,
    COffcanvasTitle,

    CDropdown,
    CDropdownMenu,
    CDropdownToggle,
    CDropdownItem,
} from '@coreui/react'

import useAuth from 'hooks/useAuth'
import useMobile from 'hooks/useMobile'
import useRole from 'hooks/useRole'

import TopHeader from './TopHeader'

import MainNavLink from 'components/main/NavLink'
import RoleContainer from 'components/main/RoleRequired/Container'

import { HeaderContext } from 'context/special/headerContext'


import { HOME_PAGE, LEGAL_PAGE_NAME, PERM_IS_SUPER_USER, PERM_READ } from 'utils/consts'
import { adminForNews, adminPageRoles, bhTuvPageRoles, companyPageRoles, financialAdminPageRoles, finanialEmployeePageRoles, govPageRoles, materialPageRoles, userPageRoles } from 'utils/consts/roles'

import css from './style.module.css'
import './style.css'

export default function Header()
{
    /** утасны navbar харагдах эсэх  */
    const [visible, setVisible] = useState(false)

    const { Toggler } = useContext(HeaderContext)

    const navigate = useNavigate()
    const { userDetail, signOut } = useAuth()
    const { isMobile } = useMobile()
    const isLogged = Object.keys(userDetail).length > 0

    const userMenu = {
        "to": "/user/",
        "title": "Хэрэглэгч",
        "needRoles": userPageRoles
    }

    /** Хэрэглэгчийн эрхээс хамаарч хэрэглэгчийн мэдээлэл panel дээр ямар ямар хуудас харуулахаа шийдэх нь */
    const findUserMenus = () =>
    {
        const menus = [
            {
                "to": "/admin/",
                "title": "Aдмин",
                roles: [ ...adminPageRoles, ...adminForNews ]
            },
            {
                "to": "/gov/",
                "title": "Төрийн байгууллага",
                roles: [ ...govPageRoles, ...financialAdminPageRoles, ...bhTuvPageRoles ]
            },
            {
                "to": "/company/",
                "title": "Компани",
                roles: [ ...companyPageRoles, ...finanialEmployeePageRoles, ...materialPageRoles ]
            },
        ]

        const extra = []
        const userRole = userDetail.user_role
        if (!userRole)
        {
            return {}
        }
        menus.map(
            (menu, idx) =>
            {
                const hasRole = menu['roles'].find((el) => userRole[el])
                if (hasRole)
                {
                    extra.push(menu)
                }
            }
        )

        if (extra.length > 1)
        {
            extra.push(userMenu)
            return {
                "title": "Хэрэглэгчийн хэсэг",
                "sub-menu": extra
            }
        }
        else {
            if (extra.length)
            {
                extra[0]['title'] = 'Хэрэглэгчийн хэсэг'
                return extra[0]
            }
            return userMenu
        }

    }

    const pcOptions = [
        findUserMenus()
    ]

    const mobileOptions = [
        {
            "to": "/admin/",
            "title": "Aдмин",
            "needRoles": [ ...adminPageRoles, ...adminForNews ]
        },
        {
            "to": "/gov/",
            "title": "Төрийн байгууллага",
            "needRoles": [...govPageRoles, ...financialAdminPageRoles]
        },
        {
            "to": "/company/",
            "title": "Компани",
            "needRoles": [...companyPageRoles, ...finanialEmployeePageRoles]
        },
        {
            "to": "/user/",
            "title": "Хэрэглэгч",
            "needRoles": userPageRoles
        }
    ]

    const displayOptions = useMemo(
        () =>
        {
            if(isLogged){
                if(isMobile)
                {
                    return mobileOptions
                }
                return pcOptions
            }
            return []
        },
        [isMobile, isLogged]
    )

    const headerNavs = [
        {
            'to': HOME_PAGE,
            "title": "Нүүр хуудас",
            "exact": true
        },
        {
            "component": (
                <>
                    <span className='verticalSlash'>|</span>
                </>
            )
        },
        {
            'to': `/abouts/`,
            "title": "Бидний тухай"
        },
        {
            "component": (
                <>
                    <span className='verticalSlash'>|</span>
                </>
            )
        },
        {
            'to': "/news/",
            "title": "Мэдээ мэдээлэл",
        },
        {
            "component": (
                <>
                    <span className='verticalSlash'>|</span>
                </>
            )
        },
        {
            'to': `/${LEGAL_PAGE_NAME}/`,
            "title": "Хууль, эрх зүй"
        },
        {
            "component": (
                <>
                    <span className='verticalSlash'>|</span>
                </>
            )
        },
        {
            'to': "/rules/",
            "title": "Төсөл, хөтөлбөр"
        },
        {
            "component": (
                <>
                    <span className='verticalSlash'>|</span>
                </>
            )
        },
        {
            'to': `/songon/`,
            "component": (
                <>
                    <i style={{ color: '#0ec6d5'}}className="fa-solid fa-star"></i> &nbsp; Сонгон шалгаруулалт
                </>
            )
        },
        {
            "component": (
                <>
                    <span className='verticalSlash'>|</span>
                </>
            )
        },
        !isLogged
        &&
        {
            'to': "/sign/in/",
            "component": (
                <>
                    <i style={{ color: "#0ec6d5" }} className="fas fa-user"></i><span>&nbsp;Нэвтрэх</span>
                </>
            )
        },
        !isLogged
        &&
        {
            "to": "/sign/up/",
            "component": (
                <>
                    <button className="btn btn-primary">
                        <i className="fal fa-plus btn-icon"></i> Бүртгүүлэх
                    </button>
                </>
            )
        },
        ...displayOptions,
        isLogged
        &&
            {
                "to": "/sign/out/",
                "onClick": signOut,
                "component": (
                    <>
                        <button className="btn btn-primary"
                        >
                            <i className="fal fa-sign-out"></i> Гарах
                        </button>
                    </>
                )
            }
    ]

    const displayNavs = useMemo(() =>
    {
        const navLinks = headerNavs.map(
            (nav, idx) =>
            {

                if (!nav)
                    return

                /** Menu дотор Menu байгаа эсэх */
                const hasSubMenu = !!nav['sub-menu']

                if (!nav?.to && nav?.component)
                {
                    return (
                        <RoleContainer key={idx} needRoles={nav.needRoles || []}>
                            {nav.component}
                        </RoleContainer>
                    )
                }

                if (hasSubMenu)
                {
                    return (
                        <CDropdown variant="nav-item"
                            key={idx}
                        >
                            <CDropdownToggle color="secondary">{nav.title}</CDropdownToggle>
                            <CDropdownMenu className={css.dropdownMenu}>
                                {
                                    nav['sub-menu'].map(
                                        (item, index) =>
                                        {
                                            return (
												<RoleContainer key={idx} needRoles={item.needRoles || []}>
                                                    <MainNavLink
                                                        key={index}
                                                        className="dropdown-item"
                                                        to={item.to}
                                                        exact={item.exact}
                                                        onClick={() => setVisible(false)}
                                                    >
                                                        {item.title}
                                                    </MainNavLink>
												</RoleContainer>
											)
                                        }
                                    )
                                }
                            </CDropdownMenu>
                        </CDropdown>
                    )
                }

                return (
                    <RoleContainer key={idx} needRoles={nav.needRoles || []}>
                        <MainNavLink
                            to={nav.to}
                            exact={nav.exact}
                            className={hasSubMenu ? "dropdown-toggle" : ""}
                            onClick={
                                () => {
                                    nav.onClick && nav.onClick()
                                    setVisible(false)
                                }
                            }
                        >
                            {nav.component ? nav.component : nav.title}
                        </MainNavLink>
                    </RoleContainer>
                )
            }
        )

        return navLinks
    }, [userDetail, isMobile, displayOptions, isLogged])

    return (
        <header className='main-header'>
            <TopHeader />
            <CNavbar colorScheme='light' className="d-flex" expand="lg">
                <CContainer style={{padding: 0}}>
                    <div className="headerLogo">
                        <div className="d-flex align-items-center">
                            {Toggler}
                            <img style={{ width: "260px", cursor: 'pointer' }} className={css.logoDesktopNone} src={process.env.PUBLIC_URL + "/images/logo.png"} alt={"headerLogo"} onClick={() => navigate(HOME_PAGE)}/>
                            <CNavbarToggler
                                aria-controls="offcanvasNavbar2"
                                aria-label="Toggle navigation"
                                onClick={() => setVisible(!visible)}
                            />
                        </div>
                    </div>
                    <COffcanvas className="d-flex align-items-center justify-content-between" id="offcanvasNavbar2" placement="end" portal={false} visible={visible} onHide={() => setVisible(false)}>
                        <COffcanvasHeader className='offCanvasHeader' >
                            <COffcanvasTitle  >
                                <img
                                    style={{ width: "260px", paddingTop: "10px" }}
                                    src={process.env.PUBLIC_URL + "/images/logo.png"}
                                    alt={"headerLogo"}
                                />
                            </COffcanvasTitle>
                            <CCloseButton className="text-reset ms-5" onClick={() => setVisible(false)} />
                        </COffcanvasHeader>
                        <COffcanvasBody className='offCanvasBody w-100' >
                            <CNavbarNav style={{ height: '54px' }} className="d-flex align-items-center justify-content-between w-100">
                                {displayNavs}
                            </CNavbarNav>
                        </COffcanvasBody>
                    </COffcanvas>
                </CContainer>
            </CNavbar>
        </header>
    )
}
