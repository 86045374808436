import { toast } from 'react-toastify';

import WKT from 'ol/format/WKT';
import KML from 'ol/format/KML';
import VectorSource from 'ol/source/Vector'
import Cluster from 'ol/source/Cluster'
import VectorLayer from 'ol/layer/Vector'
import {Heatmap as HeatmapLayer} from 'ol/layer';
import GeoJSON from 'ol/format/GeoJSON';
import { tile } from 'ol/loadingstrategy';
import { createXYZ } from 'ol/tilegrid';
import Overlay from 'ol/Overlay'
import { getPointResolution, METERS_PER_UNIT, transform, transformExtent as tsExtent } from 'ol/proj'
import { getCenter, boundingExtent } from 'ol/extent';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {Fill, Stroke, Style} from 'ol/style';
import { altKeyOnly, click, pointerMove, platformModifierKeyOnly, always } from 'ol/events/condition';
import {bbox as bboxStrategy} from 'ol/loadingstrategy';
import {DragBox, Select} from 'ol/interaction';
import {Image as ImageLayer, Tile as TileLayer} from 'ol/layer';
import ImageWMS from 'ol/source/ImageWMS';
import TileWMS from 'ol/source/TileWMS';

import { loaderWFS, selectStyle, styleFunction, styles as mainStyle, styleResCircle, radiusCircle } from './helper'
import { userLocationStyle } from "./helper";
import { Circle } from 'ol/geom';

export const DATA_PROJECTION = 'EPSG:4326'
export const FEATURE_PROJECTION = 'EPSG:3857'
// export const FEATURE_PROJECTION = 'EPSG:4326'
export const CLUSTER_DISTANCE = 40
export const CENTER_OF_MAP = [11902078.273941008, 6093305.60714214]
// export const CENTER_OF_MAP = [106.9210201590113, 47.92365320244934]
// export const FIRST_ZOOM_LEVEL = 13.49137662670972
export const FIRST_ZOOM_LEVEL = 14.728043293376386

export const POPUP_MOBILE_ID = "popupMapMobile"

export default class CMap {
	/**
	 */
	constructor(map) {
		this.map = map
		this.overlay = null
		this.select = null
		this.dragBox = null
		this.loadeElement = null
		this.userMarker = null
	}

	/** Газрын зурган дээр cursor ийн төрлийг солих */
	changeCursor = (cursorType="default") =>
	{
		const element = this.map.getTargetElement()
		element.style.cursor = cursorType
	}

	isMobile = () => {
		let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches
		return isMobile
	}

	userMakerSetCoordinate = coordinates => {
		this.userMarker.setCoordinates(coordinates)
	}

	calcRadius = (coordinates, radius) =>
	{
		return radius / getPointResolution(FEATURE_PROJECTION, 1, coordinates, 'm')
	}

	createRadius = (coordinates, radius) =>
	{
		const newRadius = this.calcRadius(coordinates, radius)
		const circleFeature = new Feature({
			geometry: new Circle(coordinates, newRadius),
		})
		circleFeature.setStyle(radiusCircle)
		const vectorSource = new VectorSource({
			features: [circleFeature],
		})
		const vectorLayer = new VectorLayer({
			source: vectorSource,
			name: "radius"
		})
		this.map.addLayer(vectorLayer)
		return { feature: circleFeature }
	}

	newIconFeature = () => {
		const point = new Point([0, 0])
		const iconFeature = new Feature({
			geometry: point,
		})
		iconFeature.setStyle(userLocationStyle)
		const vectorSource = new VectorSource({
			features: [iconFeature],
		})
		const vectorLayer = new VectorLayer({
			source: vectorSource,
		})
		this.map.addLayer(vectorLayer)
		this.userMarker = point
	}

	createPoint = (coordinate, properties) =>
	{
		const point = new Point(coordinate)
		const feature = new Feature({
			geometry: point,
		})
		if (properties)
		{
			feature.setProperties(properties)
		}
		return feature
	}

	/** Газрын зураг дээр feature ийн утга нэмэх */
	addFeatureToSource = feature => {
		this.map.addFeature(feature)
	}

	/** Газрын зураг дээр feature үүдийг нэмэх нь */
	addFeaturesToSource = features => {
		this.map.addFeatures(features)
	}

	/** geojson ийг feature болгож авах нь */
	parseGeoJsonToFeature = geojsonObject => {
		const geoJson = new GeoJSON()
		const parsedFeatures = geoJson.readFeatures(geojsonObject, {
			dataProjection: DATA_PROJECTION,
			featureProjection: FEATURE_PROJECTION,
		})
		return parsedFeatures
	}

	writeToWKTGeom = (feature) =>
	{
		const format = new WKT();

		const wkt = format.writeGeometry(feature.getGeometry(), {
			dataProjection: DATA_PROJECTION,
			featureProjection: FEATURE_PROJECTION,
		});
		return wkt
	}

	/** feature -ээр vector source үүсгэх нь */
	createVectorSource = features => {
		return new VectorSource({ features: features || [] })
	}

	/** GEOSERVER ээс wfs ийг тухайн харагдаж байгаа хэсгийг дуудах
	 * @param {string} url wfs ийн холбоос
	 * @param {string} typeName layer name
	 */
	wfsFromGeoserver = (url, typeName) =>
	{
		const vectorSource = new VectorSource(
			{
				format: new GeoJSON(),
				url: function (extent) {
					return (
						url + '?service=WFS&' +
						'version=1.1.0&request=GetFeature&' +
						`typeName=${typeName}&` +
						`outputFormat=application/json&srsname=${FEATURE_PROJECTION}&` +
						'bbox=' +
						extent.join(',') +
						`,${FEATURE_PROJECTION}`
					);
				},
				strategy: bboxStrategy,
			}
		);
		return vectorSource
	}

	/** Backaas ээс wfs ийг тухайн харагдаж байгаа хэсгийг дуудах
	 * @param {string} url wfs ийн холбоос */
	wfsFromBackup = (url, layer, type, extraQuery="") =>
	{
		const vectorSource = new VectorSource(
			{
				format: new GeoJSON(),
				url: (extent) =>
				{
					const parsed = this.transformExtent(extent)
					return (
						url +
						'?bbox=' + parsed.join(',') +
						`&srs=${DATA_PROJECTION}` +
						`&layer=${layer}` +
						`&type=${type}` +
						extraQuery
					);
				},
				strategy: bboxStrategy,
			}
		);
		return vectorSource
	}

	/** Шинэ layer нэмэх
	 * @param {Array} features шинээрэ layer нэмэхдээ features тэй хамт нэмж болно
	 * @param {Function} styleFn тухайн layer дээрх style
	 */
	addVectorLayer = (features, vSoure=null, name="", styleFn=styleFunction, options={}) => {
		let vectorSource = vSoure
		if (!vSoure) {
			vectorSource = this.createVectorSource(features)
		}

		const vectorLayer = new VectorLayer({
			source: vectorSource,
			style: styleFn,
			name: name,
			...options?.minZoom ? { minZoom: options.minZoom } : {},
			...options?.maxZoom ? { maxZoom: options.maxZoom } : {},
		})
		/** газрын зураг дээр layer нэмэх нь */
		this.map.addLayer(vectorLayer)

		return { vectorLayer, vectorSource }
	}

	/** Тухайн vSource -д style оноох нь */
	setSourceStyle = vectorSource => {
		const featuresOfSource = vectorSource.getFeatures()
	}

	/** vector source аас  */
	getSourceExtent = vectorSource => {
		return vectorSource.getExtent()
	}

	/** Холбоосоор дамжуулж vector soure үүсгэх*/
	makeVectorSourceFromUrl = ({ url, handleLoad, loader }) => {
		const vectorSource = new VectorSource({
			format: new GeoJSON(),
			url,
			strategy: tile(createXYZ({ tileSize: 512 })),
			loader: (extent, resolution, projection) => loader && loader(extent, resolution, projection),
		})

		vectorSource.once("featuresloadend", async event => {
			if (handleLoad) await handleLoad(event)
			this.removeLoader()
		})
		vectorSource.once("featuresloaderror", event => {
			toast.error("Feature дуудахад алдаа гарсан байна")
		})
		return vectorSource
	}

	/** газрын зураг дээр харуулж байгаа control уудын жагсаалтыг буцаах нь */
	getControls = () => {
		const controls = this.map.getControls().getArray()
		return controls
	}

	/** select ийг шинээр үүсгэх
	 * @param {'click'|'hover'|'altclick'} condition select хийх нөхцөл
	 */
	createSelect = (condition = "click", callback, style=selectStyle, layers=[], features=[], name="select") => {

		let selectCondition =
			{
				click: click,
				hover: pointerMove,
				altclick: altKeyOnly,
			}[condition] || click

		const select = new Select({
			condition: selectCondition,
			style: style,
			...(layers.length > 0 ? { layers: layers } : {}),
			...(features.length > 0 ? { features } : {}),
		})
		select.setProperties({ name: name })
		// event нэмэх
		this.map.addInteraction(select)
		select.on("select", event => {
			if (callback) callback(event)
		})
		this.select = select
	}

	/** Газрын зураг дээр select ийг unselect хийх */
	clearSelect = () => {
		this.select.getFeatures().clear()
	}

	createSelectArea = (callback, layers = [], alwaysDrag) => {
		this.map.removeInteraction(this.dragBox)
		const dragBox = new DragBox({
			condition: alwaysDrag ? always : platformModifierKeyOnly,
		})

		var selectedFeatures = this.select.getFeatures();

		dragBox.on("boxstart", function () {
			selectedFeatures.clear()
		})

		// event нэмэх
		this.map.addInteraction(dragBox)
		dragBox.on("boxend", event => {
			var info = []
			var extent = dragBox.getGeometry().getExtent()
			layers[0].getSource().forEachFeatureIntersectingExtent(extent, function(feature) {
				selectedFeatures.push(feature);
			})
			if (callback) callback(event, selectedFeatures)
		})
		this.dragBox = dragBox
	}

    /** map нд overlay үүсгэх */
	addOverlay = ({ margin=40, customContainerId="popupMap" }) => {
		const container = document.getElementById(customContainerId)

		if (!container) return

		const overlay = new Overlay({
			element: container,
			autoPan: {
				animation: {
					duration: 250,
				},
				margin: margin,
			},
		})

		this.map.addOverlay(overlay)
		this.overlay = overlay
	}

	/**
	 * @param {Array} coordinate [ coordi1, coordi2  ]
	 * @description popup арилгахаар бол coordinate гэсэн arg руу undefined явуулна
	 * @return map нд overlay харуулах
	 */
	displayOverlay = coordinate => {
		const isMobile = this.isMobile()

		/** хэрвээ утсаар орж байгаа бол утасны overlay харуулах */
		if (isMobile === true) {
			const popMobileElem = document.getElementById(POPUP_MOBILE_ID)
			if (coordinate)
			{
				popMobileElem.classList.toggle("d-none")
			}
			else {
				popMobileElem.classList.add("d-none")
			}
			return
		}

		this.overlay.setPosition(coordinate)
	}

	/** Газрын зураг дээр loader харуулах нь */
	addLoader = () => {
		const mapElement = this.map.getTargetElement()
		if (this.loadeElement === null) {
			const loaderContainer = document.createElement("div")
			loaderContainer.className = "ol-cloader-container"
			const loaderElement = document.createElement("div")
			loaderContainer.appendChild(loaderElement)
			mapElement.appendChild(loaderContainer)
			this.loadeElement = loaderElement
		}
		this.loadeElement.classList.add("ol-cloader")
		mapElement.classList.add("has-loader")
	}

	/** Loader ийг нуух нь */
	removeLoader = () => {
		const mapElement = this.map.getTargetElement()
		this.loadeElement && this.loadeElement.classList.remove("ol-cloader")
		if (mapElement) {
			mapElement.classList.remove("has-loader")
		}
	}

	/** Тухайн extent рүү газрын зураг дээр нисэж очих нь */
	fitToExtent = (extent, option = { padding: [25, 25, 25, 25], duration: 5000, maxZoom: 20 }) => {
		const view = this.map.getView()
		view.fit(extent, option)
	}

	/** feature дээр ямар нэгэн style оноогдсон байгаад түүнийг анхны style-д буцаах */
	resetFeatureStyle = feature => {
		var style = mainStyle[feature.getGeometry().getType()]
		feature.setStyle(style)
	}

	/** нэг proj оос нөгөө proj руу transform extent ийг хийх */
	transformExtent = (extent, sourceProj=FEATURE_PROJECTION, destinitionProj=DATA_PROJECTION) => {
		return tsExtent(extent, sourceProj, destinitionProj)
	}

	/** нэг proj оос нөгөө proj руу transform хийх */
	transformCoordinate = (coordinate, sourceProj=DATA_PROJECTION, destinitionProj=FEATURE_PROJECTION) => {
		return transform(coordinate, sourceProj, destinitionProj)
	}

	/** 3857 той coordinate ийг 4326 болгох нь */
	transfrorm3857to4326 = coordinate => {
		return this.transformCoordinate(coordinate, FEATURE_PROJECTION, DATA_PROJECTION)
	}

	/** 4326 той coordinate ийг 3857 болгох нь */
	transfrorm4326to3857 = coordinate => {
		return this.transformCoordinate(coordinate, DATA_PROJECTION, FEATURE_PROJECTION)
	}

	createCluster = (vectorSource, distance = CLUSTER_DISTANCE, minDistance = 20) => {
		const clusterSource = new Cluster({
			distance: parseInt(distance, 10),
			minDistance: parseInt(minDistance, 10),
			source: vectorSource,
		})

		return clusterSource
	}

	/** extent ээс center ийг нь олох нь */
	getCenterFromExtent = extent => {
		return getCenter(extent)
	}

	/** coordinate уудаас extent гаргаж авах */
	getBoundingExtent = coordinates => {
		return boundingExtent(coordinates)
	}

	deleteLayer = layer => {
		this.map.removeLayer(layer)
	}

	/** газрын зургаас давхарга устгах нь */
	removeLayer = (name = "") => {
		if (!name) return
		this.map
			.getLayers()
			.getArray()
			.filter(layer => layer.get("name") === name)
			.forEach(layer => {
				this.deleteLayer(layer)
			})
	}

	/** давхаргыг эхлэл үгээр нь хайж олж устгах нь */
	removeLayerStartName = (startName="") =>
	{
		this.map
			.getLayers()
			.forEach(
				(layer) =>
				{
					if (layer && layer.get("name") && layer.get("name").startsWith(startName))
					{
						this.deleteLayer(false)
					}
				}
			)
	}

	/** тухайн давхаргыг нуух */
	hideLayerName = (layerName) =>
	{
		this.map
			.getLayers()
			.forEach(
				(layer) =>
				{
					if (layer && layer.get("name") === layerName)
					{
						layer.setVisible(false)
					}
				}
			)
	}

	/** тухайн давхаргыг харуулах */
	showLayerName = (layerName) =>
	{
		this.map
			.getLayers()
			.forEach(
				(layer) =>
				{
					if (layer && layer.get("name") === layerName)
					{
						layer.setVisible(true)
					}
				}
			)
	}

	/** тухайн нэг coordinate руу нисэж очих */
	animateToCoordinate = (coordinate, { zoom, duration = 1000 }) => {
		this.map.getView().animate({ center: coordinate, zoom, duration })
	}

	/** төв цэг рүү үсрэх */
	flyToCenter = () => {
		this.animateToCoordinate(CENTER_OF_MAP, { zoom: FIRST_ZOOM_LEVEL, duration: 1000 })
	}

	/** cluster мөн эсэхийг шалгах нь */
	isCluster = feature => {
		return !feature || !!feature.get("features")
	}

	createKMLLayer = (url, name, { style=styleFunction, minZoom, maxZoom }) =>
	{
		const source = new VectorSource({
			url: url,
			crossOrigin: 'anonymous',
			format: new KML({
				extractStyles: false,
			}),
		})
		let vectorLayer = new VectorLayer({
			source: source,
			name: name,
			...minZoom ? { minZoom } : {},
			...maxZoom ? { maxZoom } : {},
		});
		vectorLayer.setStyle(style)
		this.map.addLayer(vectorLayer)
		return { vectorSource: source, vectorLayer }
	}

	createClusterWithUrl = (url, name, style=styleResCircle) =>
	{

		const source = new Cluster({
			distance: 40,
			source: new VectorSource({
				url: url,
				crossOrigin: 'anonymous',
				format: new KML({
					extractStyles: false,
				}),
			}),
		})
		let vectorlayer = new VectorLayer({
			source: source,
			name: name,
		});

		vectorlayer.setStyle((...args) => style(...args, vectorlayer, this.map, ))
		this.map.addLayer(vectorlayer)

		return vectorlayer
	}

	/** Тухайн extent доторх feature үүдийг авах нь */
	getFeaturesInExtent(source)
	{
		const viewExtent = this.map.getView().calculateExtent(this.map.getSize());
		let features = []

		source.forEachFeatureInExtent(
			viewExtent,
			(sfeature) =>
			{
				features.push(sfeature)
			}
		)

		return features
	}

	addWMSLayer = (url, layer, { name="", clqFilter="", maxZoom, minZoom }) =>
	{

		const wmsLayer = new TileLayer({
			source: new TileWMS({
				url: url,
				params: {
					'LAYERS': layer,
					'FORMAT': 'image/png',
					...clqFilter ? { 'CQL_FILTER': clqFilter } : {}
				},
			}),
			maxZoom,
			minZoom,
			name
		})

		this.map.addLayer(wmsLayer)
		return wmsLayer
	}

}
