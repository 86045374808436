import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import useLoader from 'hooks/useLoader';
import useCState from 'hooks/useCState';
import useApi from 'hooks/useApi';

import CForm  from 'components/main/Form';

import { petitionerValidate } from 'utils/validate';
import { dataToValueLabel } from 'utils/format';

const PetitionerForm = () => {

    const { isLoading, Loader, fetchData } = useLoader({isSmall: true});
    const [ orgTypes, setOrgTypes ] = useState([])
    const navigate = useNavigate()

    const songonApi = useApi().songon
    const orgApi = useApi().orgType

    const { canAccess } = useCState()

    const { lotteryId, bulegId, resId } = useParams()

    useEffect(
        async () =>
        {
            const { data, success } = await fetchData(orgApi.get())
            if(success)
            {
                setOrgTypes(dataToValueLabel(data, 'id', 'name'))
            }
        },
        []
    )

    const btnOnSubmit = async (data) =>
    {
        const body = {
            ...data,
            songon: lotteryId,
            zoriltot_buleg: bulegId,
            residence_id: resId
        }
        const { success } = await fetchData(songonApi.postOrgodol(body))
        if(success)
        {
            navigate(`/songon/${lotteryId}/`)
        }
    }

    const form = {
        inputs: [
            {
                inputType: "input",
                registerName: "condition",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
            },
            {
                inputType: "input",
                registerName: "work_place",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
            },
            {
                inputType: "select",
                registerName: "org_type",
                disabled: isLoading,
                options: orgTypes,
            },
            {
                inputType: "input",
                registerName: "work_position",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
            },
            {
                inputType: "input",
                registerName: "until_work_year",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
            },
            {
                inputType: "input",
                registerName: "org_work_year",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
            },
            {
                inputType: "input",
                registerName: "income_exp_info",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
            },
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: 'Илгээх',
        },
        validate: {
            validateMode: "all",
            yupValidate: petitionerValidate,
        },
    }

    if(!canAccess.salaryCheck || !canAccess.pdf)
    {
        return <Navigate to={'/'} />
    }

    return (
        <div>
            <CForm form={form} />
        </div>
    );
}

export default PetitionerForm;
