import OverlayElement from 'components/main/OverlayElement'
import UnitsMenu from 'components/special/Units'
import { useEffect, useRef } from 'react'

export default function Units({ errors, watch, register, setValue, setError})
{

    const unit1Ref = useRef()
    const unit2Ref = useRef()
    const unit3Ref = useRef()

    const obj = {
        "1": unit1Ref,
        "2": unit2Ref,
        "3": unit3Ref
    }

    useEffect(
        () =>
        {
            register("unit1")
            register("unit2")
            register("unit3")
        },
        []
    )

    const clear = () =>
    {
        setValue(`unit1`, null)
        setValue(`unit2`, null)
        setValue(`unit3`, null)
        unit1Ref.current.innerText = ""
        unit2Ref.current.innerText = ""
        unit3Ref.current.innerText = ""
    }

    const handleClick = (event) =>
    {
        const [whatUnit, id, code, label] = event.value.split("||")
        setValue(`unit${whatUnit}`, id)
        const ref = obj[whatUnit + ""]
        ref.current.innerText = `${label}${whatUnit+"" !== "3" ? `, ` : ""}`
    }

    return (
        <div className='mb-3'>
            <UnitsMenu
                onClick={handleClick}
            />
            <div className='d-flex'>
                <div className='me-2'>Сонголт:</div>
                <span ref={unit1Ref} className='col-auto'></span>
                <span ref={unit2Ref} className='col-auto'></span>
                <span ref={unit3Ref} className='col-auto'></span>
                <OverlayElement
                    text="Арилгах"
                    placement='right'
                >
                    <div className='ms-2' role={"button"} onClick={clear}>
                        <i className={"fa fa-times text-danger"}></i>
                    </div>
                </OverlayElement>
            </div>
        </div>
    )
}
