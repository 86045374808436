import React from 'react'
import { useParams } from 'react-router-dom'

import GetOneDetail from 'components/special/GetOneDetail';

import useApi from 'hooks/useApi';

import css from './style.module.css'

export default function Detail()
{
    const projectApi = useApi().project
    const { id } = useParams();

    return (
        <div>
            <div className={`${css.container}`} style={{ marginBottom: '12px' }}>
                <div className={css.padding}>
                    <GetOneDetail
                        ignoreList={['unit1', 'unit2', 'unit3',  'is_project', 'updated_at', 'created_at']}
                        api={projectApi.getOne(id)}
                    />
                </div>
            </div>
        </div>
    )
}
