import React from 'react'
import { Modal } from 'react-bootstrap'

import Button from 'components/main/Button'

import useModal from 'hooks/useModal';

export const initialModal = {
    header: {
        show: true,
        title: "",
        comp: null
    },
    body: null,
    footer: {
        show: true
    },
    btnClose: {
        icon: "",
        show: true,
        on: null,
        className: "",
        text: "Хаах",
        type: "button",
        variant: "secondary"
    },
    btnAction: {
        show: false,
        icon: "",
        on: null,
        className: "",
        text: "Хаах",
        type: "button",
        variant: 'warning'
    },
    handleHide: null,
    backdrop: true,
    animation: true,
    centered: true,
    size: "", // 'sm' | 'lg' | 'xl';
    fullscreen: "md-down", // true | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'
    dialogClassName: "",
    contentClassName: "bg-custom text-dark",
}

function CModal()
{
    /** Модал нээгдсэн хаагдсан эсэх төлөв */
    const { show, modal, setHide } = useModal()

    const onHide = () =>
    {
        if (modal.handleHide)
            modal.handleHide()
        setHide(modal.btnClose.on)
    }

    return (
        <Modal
            show={show}
            size={modal.size}
            centered={modal.centered}
            backdrop={modal.backdrop}
            fullscreen={modal.fullscreen}
            onHide={onHide}
            keyboard={true}
            animation={modal.animation}
            dialogClassName={modal.modalClassName}
            contentClassName={modal.contentClassName}
            scrollable={false}
        >
            {
                modal.header.show
                ? (
                    <Modal.Header closeButton closeVariant={'white'}>
                        {
                            modal.header.title
                            ? (
                                <Modal.Title>
                                    {modal.header.title}
                                </Modal.Title>
                            )
                            : modal.header.comp
                                ? modal.header.comp
                                : null
                        }
                    </Modal.Header>
                )
                : null
            }
            <Modal.Body>
                {modal.body}
            </Modal.Body>
            {
                modal.footer.show
                ?
                    <Modal.Footer>
                        {
                            modal.btnAction.show
                            &&
                                <Button
                                    disabled={modal.btnAction.disabled}
                                    icon={modal.btnAction.icon}
                                    type={modal.btnAction.type}
                                    className={modal.btnAction.className}
                                    onClick={ () => modal.btnAction.on() }
                                    title={modal.btnAction.text}
                                    variant={modal.btnAction.variant}
                                />
                        }
                        {
                            modal.btnClose.show
                            &&
                                <Button
                                    icon={modal.btnClose.icon}
                                    type={modal.btnClose.type}
                                    className={modal.btnClose.className}
                                    onClick={onHide}
                                    title={modal.btnClose.text}
                                    variant={modal.btnClose.variant}
                                />
                        }
                    </Modal.Footer>
                : null
            }
        </Modal>
    );
}

export default CModal
