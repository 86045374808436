import React, { useEffect } from 'react';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5 from "@amcharts/amcharts5";

import useMobile from 'hooks/useMobile';

import css from '../style.module.css'

function LotteryChart({ data=[] })
{

    const { isMobile, orientation } = useMobile()

    useEffect(() => {
        let root = am5.Root.new("chartdiv");

        root.setThemes([am5themes_Animated.new(root)]);

        let chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                layout: root.verticalHorizontal,
            })
        );

        let series = chart.series.push(
            am5percent.PieSeries.new(root, {
                name: "Series",
                categoryField: "name",
                valueField: "total_count",
                radius: am5.percent(isMobile ? 80 : 100),
                centerY: am5.percent(0),
                centerX: am5.percent(isMobile ? -25 : -20),
                alignLabels: false,
            })
        );
        series.data.setAll(data);

        series.labels.template.set("forceHidden", true);

        series.labels.template.setAll({
            radius: 0,
        });

        let legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.percent(isMobile ? 10 : -25),
                centerY: am5.percent(isMobile ? (orientation ? 0 : -25) : -50),
                layout: root.verticalLayout,
            })
        );
        legend.markers.template.setAll({
            width: 10,
            height: 10,
        });

        legend.setAll({
            width: 50,
        })

        legend.data.setAll(series.dataItems);

        return () => {
            root.dispose();
        };
    }, [data, isMobile, orientation]);

    return (
        <div className={!orientation && isMobile ? css.rotate : null}>
            <div id="chartdiv" className={`${orientation && isMobile ? css.mobileLand : css.chart}`}>
                {!isMobile && <div className="hidelogo"></div>}
            </div>
        </div>
    );
}
export default LotteryChart;
