import { MONEY_REGEX, QUANTITY_REGEX, MONEY_REGEX_0 } from "../consts"
import { fullZero } from "utils"

/**
 * Мөнгийг format руу оруулж буцаах нь
 * @param {number} money мөнгө
 * @returns formatтай мөнгө
 */
export const moneyFormat = (money, toFix=2) => {
    money = parseInt(money)
    if(isNaN(money)) return money = 0
    return money.toFixed(toFix).replace(toFix === 0 ? MONEY_REGEX_0 : MONEY_REGEX, '$&,')
}

/**
 * Тоо ширхэгийн format руу оруулж буцаах нь
 * @param {number} quantity тоо ширхэг
 * @returns таслалтай болж буцна
 */
export const quantityFormat = (quantity) =>
{
    let numQuantity = parseFloat(quantity)
    if(numQuantity < 0) return null
    if(isNaN(numQuantity)) return 0
    return String(numQuantity).replace(QUANTITY_REGEX, '$1,')
}

/**
 * timezone ийг string date болгох
 * @param {string | number} timezone    date
 * @param {boolean}         hasHour     цагийг хамтад нь авах эсэх
 * @param {string}          symbol      өдрийн голд байх тэмдэгт
 * @returns бүтэн string date
 */
export function timeZoneToDateString(timezone, hasHour=true, symbol="-", hasMs=false)
{

    if(timezone === '') return timezone

    const date = new Date(timezone)

    /** сар */
    const month = fullZero(date.getMonth() + 1)
    /** өдөр */
    const day = fullZero(date.getDate())
    /** жил */
    const year = date.getFullYear()

    /** цаг */
    const hour = fullZero(date.getHours())
    /** минут */
    const minute = fullZero(date.getMinutes())
    /** с */
    const seconds = fullZero(date.getSeconds())
    /** мс */
    const ms = date.getMilliseconds()

    const hours = `${hour}:${minute}:${seconds}${hasMs ? `:${ms}` : ""}`

    const full = `${year}${symbol}${month}${symbol}${day}${hasHour ? " " + hours : ""}`

    return full
}

/**
 * Voucher болон money оос хамаарч format ийг ялгах
 * @param {number} number       үнэ
 * @param {object} row          нэг мөрийн дата
 * @param {string} currencyKey  Валютын мөрийн нэр
 */
export function numberToMoneyOrTicket(number, row, currencyKey='currency')
{
    const currency = row?.[currencyKey]
    return currency?.isVoucher ? number : moneyFormat(number)
}

// data to ====>  {value: 'value1', label: 'label'}
export const dataToValueLabel = (data, value, label, customLabel, extraValue, split='-') =>
{
    return data?.map(
        (e) =>
        {
            return {
                value: e[value],
                label: customLabel ? e[customLabel[0]] + split + e [customLabel[1]] : e[label],
                extraValue: e[extraValue]
            }
        }
    )
}

// {value: 'value1', label: 'label'} to ====> data
export const valueLabelToArray = (data) =>
{
    return data.map(
        (element) =>
        {
            return element.value
        }
    )
}

// bytes ийг хүн харж болохоор болгож format хйинэ
export function getReadableFileSizeString(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [' kb', ' mb', ' gb', ' tb', ' pb', ' eb', ' zb', ' yb'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

export function arrayRemoveElement(arr, index) {
    return arr.filter(function(ele, idx){
        return idx !== index;
    });
}

// 302.4K geh meteer K === 1000 gej haruulna

export function numberToKillo(number)
{
    let isNegative = false
    if(isNaN(number)) return
    if(number < 0) isNegative = true
    if(isNegative) number = number * -1
    if(number < 100) return number
    let numberK = ''
    let cuttedNumbers = quantityFormat(number)
    cuttedNumbers = cuttedNumbers.split(',')
    cuttedNumbers.map(
        (element, index) =>
        {
            if(index === cuttedNumbers.length - 1)
            {
                numberK = numberK + element[0]
                return
            }
            numberK = numberK + element + (index === cuttedNumbers.length - 2 ? '.' : ',')
        }
    )
    return isNegative ? "-" + numberK : numberK
}


export const arrayToJSON = (array=[], key, value) =>
{
    if(array.length === 0) return
    let json = {}
    array.map(
        (element) =>
        {
            Object.assign(json, { [element?.[key]]: element?.[value] } )
        }
    )
    return json
}

export const moneyFormatString = (money, toFix=2) => {
    money = parseInt(money)
    if(isNaN(money)) return money = 0
    let mny = 0
    let code = ''
    if (money >= 1_000)
    {
        mny = money / 1_000
        code = 'мян'
    }
    if (money >= 1_000_000)
    {
        mny = money / 1_000_000
        code = 'сая'
    }
    if (money >= 1_000_000_00)
    {
        mny = money / 1_000_000_00
        code = 'тэрбум'
    }
    return `${mny.toFixed(toFix)} ${code}`
}
