import React from 'react';

import CTable from 'components/main/Table';
import BoxBackground from 'components/special/BoxBackground';

import css from '../style.module.css'

import label from 'utils/validate/label';
import useModal from 'hooks/useModal';
import ImageFetch from 'components/main/Image';

const RoomSizes = ({ rooms }) => {

    const { setDisplay } = useModal()

    const displayRoomImage = (row) =>
    {
        setDisplay({
            header: {
                title: row.room_name,
            },
            body: (
                <div>
                    <ImageFetch style={{ width: '100%', height: '100%' }} src={row.image} />
                </div>
            ),
            size: "xl",
            footer: {
                show: false,
            },
        })
    }

    /** Хүснэгтийн багана */
    const columns =
    [
        {
            field: "room_name",
            headerName: label['room'],
            order: false,
            fieldComponent: (fieldValue, index, row) => <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => displayRoomImage(row)}>{fieldValue}</span>
        },
        {
            field: "room_mkv",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue + ' м'}<sup>2</sup></span>
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    return (
		<BoxBackground className={css.roomSizes} title="ӨРӨӨНИЙ ХЭМЖЭЭ ">
			<CTable rows={rooms} columns={columns} sequenceColumn={sequenceColumn} />
		</BoxBackground>
	)
}

export default RoomSizes;
