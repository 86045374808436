import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';

import YearpickerInput from 'components/main/Yearpicker/YearpickerInput';
import BoxBackground from 'components/special/BoxBackground';
import Datatable from 'components/main/Datatable';
import CreateButton from 'components/main/CudButtons/Create';
import DeleteButton from 'components/main/CudButtons/Delete';
import UpdateButton from 'components/main/CudButtons/Update';

import useLoader from 'hooks/useLoader';
import useApi from 'hooks/useApi';

import { tableColumns7, tableColumns8 } from 'components/special/NestedTables/SanhvvTable/TableColumns';

import { bhTuvPageRoles } from 'utils/consts/roles';

const Report = () =>
{

    const navigate = useNavigate()

    const { chosed_year } = useParams()
    var date = chosed_year ? new Date(chosed_year) : Date.now()

    const dataTableOc1Ref = useRef(null)
    const dataTableOc2Ref = useRef(null)

    const [ chosedYear, setChosedYear ] = useState(date)
    const [ data, setData ] = useState([]);
    const [ excelOc1Url, setExcelOc1Url ] = useState('');
    const [ excelOc2Url, setExcelOc2Url ] = useState('');
    const [ excelOc1Date, setExcelOc1Date ] = useState('');
    const [ excelOc2Date, setExcelOc2Date ] = useState('');

    const { fetchData, isLoading, Loader } = useLoader({ isFullScreen: true, initValue: true })

    const reportApi = useApi().report

    /** Сонгосон он */
    var year = new Date(chosedYear).getFullYear()

    /** DATATABLE шинэчлэх */
    const getData = () =>
    {
        /** Datatable Oc1 restart хийнэ */
        if (!dataTableOc1Ref.current) return
        dataTableOc1Ref.current.firstGet = true
        dataTableOc1Ref.current.reData({}, null, [year])

        /** Datatable Oc2 restart хийнэ */
        if (!dataTableOc2Ref.current) return
        dataTableOc2Ref.current.firstGet = true
        dataTableOc2Ref.current.reData({}, null, [year])
    }

    /** OC1 Datatable шинэчлэх */
    useEffect(
		async () =>
		{
            let year = new Date(chosedYear).getFullYear()
            let formData = {year}

            const { errors, success, data } = await fetchData(reportApi.getUrlExcelOc1(formData)).catch(error => error)
            if (success)
            {
                /** OC1 Excel замыг баазаас авч хадгалах SET-лэчээд */
                setExcelOc1Url(data)
                /** Зам байвал Format-ын дагуу бодож (хэзээ шинэчлэгдсэнийг) шинэчлэнэ, байхгүй бол '' */
                if (data) setExcelOc1Date(`${data.substring(0, 4)}-${data.substring(4, 6)}-${data.substring(6, 8)} ${data.substring(8, 10)}:${data.substring(10, 12)}:${data.substring(12, 14)}`)
                else setExcelOc1Date('')
            }

            getData()
		},
		[chosedYear]
	)

    /** OC2 Datatable шинэчлэх */
    useEffect(
		async () =>
		{
            let year = new Date(chosedYear).getFullYear()
            let formData = {year}

            const { errors, success, data } = await fetchData(reportApi.getUrlExcelOc2(formData)).catch(error => error)
            if (success)
            {
                /** OC2 Excel замыг баазаас авч хадгалах SET-лэчээд */
                setExcelOc2Url(data)
                /** Зам байвал Format-ын дагуу бодож (хэзээ шинэчлэгдсэнийг) шинэчлэнэ, байхгүй бол '' */
                if (data) setExcelOc2Date(`${data.substring(0, 4)}-${data.substring(4, 6)}-${data.substring(6, 8)} ${data.substring(8, 10)}:${data.substring(10, 12)}:${data.substring(12, 14)}`)
                else setExcelOc2Date('')
            }

            getData()
		},
		[chosedYear]
	)

    /** Datatable-ийн хамгийн урд байрлах багана */
    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** OC1 Хүснэгтийн мөрийн утгыг устгах */
    const deleteRowOc1 = async (id) =>
    {
        await fetchData(reportApi.deleteOc1(id))
        getData()
    }

    /** OC2 Хүснэгтийн мөрийн утгыг устгах */
    const deleteRowOc2 = async (id) =>
    {
        await fetchData(reportApi.deleteOc2(id))
        getData()
    }

    /** OC1 Excel шинэчлэх */
    const updateExcelOc1 = async () =>
    {
        let year = new Date(chosedYear).getFullYear()
        let formData = {year}
        const { errors, success, data } = await fetchData(reportApi.createExcelOc1(formData)).catch(error => error)
        if (success)
        {
            setExcelOc1Url(data)
            if (data) setExcelOc1Date(`${data.substring(0, 4)}-${data.substring(4, 6)}-${data.substring(6, 8)} ${data.substring(8, 10)}:${data.substring(10, 12)}:${data.substring(12, 14)}`)
            else setExcelOc1Date('')
        }
        else return errors
    }

    /** OC2 Excel шинэчлэх */
    const updateExcelOc2 = async () =>
    {
        let year = new Date(chosedYear).getFullYear()
        let formData = {year}
        const { errors, success, data } = await fetchData(reportApi.createExcelOc2(formData)).catch(error => error)
        if (success)
        {
            setExcelOc2Url(data)
            if (data) setExcelOc2Date(`${data.substring(0, 4)}-${data.substring(4, 6)}-${data.substring(6, 8)} ${data.substring(8, 10)}:${data.substring(10, 12)}:${data.substring(12, 14)}`)
            else setExcelOc2Date('')
        }
        else return errors
    }

    /** Хүснэгтний үйлдэлүүд */
    const extraColumnsOc1 =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton
                            className='me-2'
                            onClick={() => navigate(`/gov/report/${year}/createoc1/${item.id}/`)}
                            needRoles={bhTuvPageRoles}
                        />
                        <DeleteButton onClick={() => deleteRowOc1(item.id)} needRoles={bhTuvPageRoles} />
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    /** Хүснэгтний үйлдэл */
    const extraColumnsOc2 =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton
                            className='me-2'
                            onClick={() => navigate(`/gov/report/${year}/createoc2/${item.id}/`)}
                            needRoles={bhTuvPageRoles}
                        />
                        <DeleteButton onClick={() => deleteRowOc2(item.id)} needRoles={bhTuvPageRoles} />
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    /** OC1 Datatable */
    const dipslayOc1Table = useMemo(
        () =>
        {
            return (
                <Datatable
					dRef={dataTableOc1Ref}
                    tableId="headerTable"
					columns={tableColumns7?.columns || []}
					colSpanHeader={tableColumns7?.colSpanHeader || []}
					sequenceColumn={sequenceColumn}
					// extraColumns={extraColumnsOc1}
					request={{
						axios: reportApi.getOc1,
                        params: [ year ],
					}}
					isFirstGetData={true}
				/>
            )
        },
        [chosedYear]
    )

    /** OC2 Datatable */
    const dipslayOc2Table = useMemo(
        () =>
        {
            return (
                <Datatable
					dRef={dataTableOc2Ref}
                    tableId="headerTable"
					columns={tableColumns8?.columns || []}
					colSpanHeader={tableColumns8?.colSpanHeader || []}
					sequenceColumn={sequenceColumn}
					// extraColumns={extraColumnsOc2}
					request={{
						axios: reportApi.getOc2,
                        params: [ year ],
					}}
					isFirstGetData={true}
				/>
            )
        },
        [chosedYear]
    )

    return (
        <BoxBackground title='Тайлан'>

            <Row>
                <Col xs={3} className='text-center d-flex align-items-center'>
                    <label className='ms-3'>Тайлангийн он:</label>
                </Col>
                <Col xs={9}>
                    <YearpickerInput
                        maxDate={Date.now()}
                        selected={chosedYear}
                        onChange={(value) => { setChosedYear(value) }}
                        inputClassName='w-50 m-3'
                    />
                </Col>
            </Row>

            <Tabs className="tab-main nav-justified mb-3 mt-3" variant="tabs" defaultActiveKey={'oc1'} >

                <Tab className="tab-main" eventKey="oc1" title="OC1">
                    <Row>
                        {/* <Col sm={12}>
                            <CreateButton btnClassName='floatRight' onClick={() => navigate(`/gov/report/${year}/createoc1/`)} needRoles={bhTuvPageRoles} />
                        </Col> */}
                        <Col>
                            {dipslayOc1Table}
                        </Col>
                        <hr />
                        <Col xs={6}>
                            <label className='ms-3'>Excel татах</label>&nbsp;&nbsp;
                            <span className='text-secondary' style={{ fontSize: '12px' }}>
                                Сүүлд шинэчлэгдсэн: ({excelOc1Date})
                            </span>
                            <CreateButton
                                title='Шинэчлэх'
                                needRoles={bhTuvPageRoles}
                                btnClassName={`floatRight ${ isLoading ? 'disabled' : '' }`} className='' onClick={ () => updateExcelOc1() }
                            />
                        </Col>
                        <Col xs={6}>
                            <a
                                className={`btn btn-success ${ excelOc1Url ? excelOc1Url : 'd-none' } ${ isLoading ? 'disabled' : '' }`}
                                href={`${process.env.REACT_APP_SERVER_URL}/media/report/${excelOc1Url}`}
                                download={excelOc1Url}
                                style={{ fontSize: '13px' }}
                            ><i className={`far fa-file-excel`}></i>{" "}Excel A-OC1</a>
                        </Col>
                    </Row>
                </Tab>

                <Tab className="tab-main" eventKey="oc2" title="OC2">
                    <Row>
                        {/* <Col sm={12}>
                            <CreateButton btnClassName='floatRight' onClick={() => navigate(`/gov/report/${year}/createoc2/`)} needRoles={bhTuvPageRoles} />
                        </Col> */}
                        <Col>
                            {dipslayOc2Table}
                        </Col>
                        <hr />
                        <Col xs={6}>
                            <label className='ms-3'>Excel татах</label>&nbsp;&nbsp;
                            <span className='text-secondary' style={{ fontSize: '12px' }}>
                                Сүүлд шинэчлэгдсэн: ({excelOc2Date})
                            </span>
                            <CreateButton
                                title='Шинэчлэх'
                                needRoles={bhTuvPageRoles}
                                btnClassName={`floatRight ${ isLoading ? 'disabled' : '' }`} className='' onClick={ () => updateExcelOc2() }
                            />
                        </Col>
                        <Col xs={6}>
                            <a
                                className={`btn btn-success ${ excelOc2Url ? excelOc2Url : 'd-none' } ${ isLoading ? 'disabled' : '' }`}
                                href={`${process.env.REACT_APP_SERVER_URL}/media/report/${excelOc2Url}`}
                                download={excelOc2Url}
                                style={{ fontSize: '13px' }}
                            ><i className={`far fa-file-excel`}></i>{" "}Excel A-OC2</a>
                        </Col>
                    </Row>
                </Tab>

            </Tabs>
        </BoxBackground>
    );
}

export default Report;
