import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import CButton from 'components/main/Button';

import useApi from 'hooks/useApi';
import useCState from 'hooks/useCState';
import useLoader from 'hooks/useLoader';
import useMobile from 'hooks/useMobile';

import ListItem from './ListItem';

import css from '../style.module.css'

const LegalList = () => {

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })
    const legalApi = useApi().news.front

    const { initState, params, setParams } = useCState()

    const { pageCode } = useParams()

    const [ legals, setLegals ] = useState([])
    const [ finished, setFinished ] = useState(false)
    const { isMobile } = useMobile()

    const getNewsList = async (limit) =>
    {
        const { success, data } = await fetchData(legalApi.get(pageCode,  0, limit))
        if(success)
        {
            setLegals(data)
            if(Math.ceil(data.length % initState.limit !== 0)) setFinished(true)
        }
    }

    useEffect(
        () =>
        {
            getNewsList(params.limit)
        },
        []
    )

    useEffect(
        () =>
        {
            getNewsList(initState.limit)
            setParams(initState)
            setFinished(false)
        },
        [pageCode]
    )

    const getData = async (skip, limit) =>
    {
        if(skip === 0) return
        const { success, data } = await fetchData(legalApi.get(pageCode, skip, limit))
        if (success)
        {
            setLegals([...legals, ...data])
            if( data.length < initState.limit ) setFinished(true)
        }
    }

    const handleReadMore = async () =>
    {
        await setParams({ skip: params.skip + initState.limit, limit: params.limit + initState.limit })
        await getData(params.skip + initState.limit, params.limit + initState.limit)
    }

    const displayItem = useMemo(
        () =>
        {
            if(legals.length === 0) return <div className={css.emptyList}>Хоосон байна</div>
            return legals.map(
                (element, index) =>
                {
                    return <ListItem element={element} key={index}/>
                }
            )
        },
        [legals]
    )

    return (
        <div className='position-relative'>
            {displayItem}
			{
				isLoading
                ?
                    Loader
                :
                    !finished && legals.length >= initState.limit &&
                    <div>
                        <CButton onClick={handleReadMore} id="button" variant="second" className={`${isMobile ? isLoading && 'invisible' : ''} w-100 mt-3`}>Илүүг харах</CButton>
                    </div>
			}
        </div>
    );
}

export default LegalList;
