import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import useRole from 'hooks/useRole';
import useModal from 'hooks/useModal';
import CButton from 'components/main/Button';

export const WARNING_TEXT = "Та хадгалахдаа итгэлтэй байна уу ?"

/** crud үйлдэл хийхдээ энэхүү delete ийг ашиглана */
const SaveButton = ({
    onClick,
    icon="far fa-save",
    className,
    warningText=WARNING_TEXT,
    style={},
    title="Хадглах",
    children
}) =>
{
    const { showWarning } = useModal()

    // const hasPerm = useRole(needRoles, PERM_DELETE)

    // if (!hasPerm)
    //     return null

    /** товч дарахад эхлээд modal харуулж баталгаажуулах */
    const handleModal = () =>
    {
        showWarning(
            {
                type: "warning",
                question: warningText,
                onClick: onClick,
            }
        )
    }

    return (
        <CButton className={className} icon={icon} onClick={handleModal} style={{ cursor: 'pointer', ...style }} title={title}>
            {children}
        </CButton>
    )
}

export default SaveButton;
