import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import Select from 'components/main/Form/InputTypes/Select'
import CButton from 'components/main/Button';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { dataToValueLabel } from 'utils/format';

const MapPopupProject = ({ geoId, cMap, sdis, handleSave, clickedCoordinate }) =>
{
    const [ apartments, setApartments ] = useState([]);
    const [ apartId, setApartId ] = useState('');
    const apartmentsdiApi = useApi().apartmentsdi
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })

    const { id: projectId } = useParams();

    useEffect(
        () =>
        {
            setApartments(dataToValueLabel(sdis, 'apartment_id', 'apartment_name'))
        },
        [sdis]
    )

    const handleClick = async () =>
    {
        const transformedCoordinate = cMap.transfrorm3857to4326(clickedCoordinate)
        const resp = await fetchData(apartmentsdiApi.post(projectId, apartId.value, { geo_id: geoId, point: transformedCoordinate })).catch( err => err )
        if(resp.success)
        {
            cMap.displayOverlay(undefined)
            cMap.clearSelect()
            handleSave && handleSave(resp.data)
        }
    }

    const selectOnChange = (value) =>
    {
        setApartId(value)
    }

    return (
        <div>
            <div id='title-tusul' className='mb-2'></div>
            <Select onChange={selectOnChange} options={apartments} value={geoId} />
            <CButton isLoading={isLoading} onClick={() => handleClick()} className='mt-3'>Хадгалах</CButton>
        </div>
    );
}

export default MapPopupProject;
