import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CForm from 'components/main/Form';
import BoxBackground from 'components/special/BoxBackground';
import HrText from 'components/main/HrTitle';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

const AgendaAction = () => {
    const [ inputValues, setInputValues ] = useState({});

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const navigate = useNavigate();
    const { id: userId } = useParams()

    const userApi = useApi().user
    const userRoleApi = useApi().user.role

    /** true бол create, false бол update буцаана */
    const isCreate = userId === 'create'

    /** create, update гэдгээс хамаарч утга авна */
    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data, error } = await fetchData(userApi.getOne(userId)).catch(err => err)
                if (success)
                {
                    const userRoles = data.user_role
                    if (data.hasOwnProperty('user_role') && data.user_role)
                    {
                        userRoles['user_role_id'] = userRoles.id
                        delete data.user_role
                        delete userRoles.id
                    }
                    Object.assign(data, userRoles)
                    setInputValues(data)
                }
            }
        },
        []
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        const roleId = inputValues.user_role_id
        formData.user = userId
        // засах үед
        const { success, data, errors } = await fetchData(userRoleApi.update(roleId, formData)).catch((error) => error)
        if (success) navigate(-1)
        else return errors
    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                component: (
                    <HrText>
                        Эрх
                    </HrText>
                )
            },
            {
                inputType: "radio",
                type: "checkbox",
                registerName: "is_financial_regulatory_commission_super",
                disabled: false,
                readOnly: false,
                options: [
                    { label: "Санхүүгийн зохицуулах хороо super эрх" },
                ],
                label: {
                    text: "Санхүүгийн зохицуулах хороо",
                    empty: true,
                },
                colMd: 12
            },
            {
                inputType: "radio",
                type: "checkbox",
                registerName: "is_government_organization",
                disabled: false,
                readOnly: false,
                options: [
                    { label: "Төрийн байгууллага super эрх" },
                ],
                label: {
                    text: "Төрийн байгууллага",
                    empty: true,
                },
                colMd: 12
            },
            {
                inputType: "radio",
                type: "checkbox",
                registerName: "is_news_admin",
                disabled: false,
                readOnly: false,
                options: [
                    { label: "Мэдээний админ" },
                ],
                label: {
                    empty: true,
                },
                colMd: 12
            },
            // {
            //     inputType: "radio",
            //     type: "checkbox",
            //     registerName: "is_only_read",
            //     disabled: false,
            //     readOnly: false,
            //     options: [
            //         { label: "Бүх мэдээлэл харах" },
            //     ],
            //     label: {
            //         empty: false,
            //     },
            //     colMd: 12
            // },

            // TODO:  энийг checkbox байгууллагын жагсаалтаас
            // ямар байгууллагад харьяа оруулахыг нь сонгодог болгох
            // {
            //     inputType: "radio",
            //     type: "checkbox",
            //     registerName: "is_org_admin",
            //     disabled: false,
            //     readOnly: false,
            //     options: [
            //         { label: "Байгууллагын админ" },
            //     ],
            //     label: {
            //         empty: false,
            //     },
            //     colMd: 12
            // },
            {
                inputType: "radio",
                type: "checkbox",
                registerName: "is_bhtuv",
                disabled: false,
                readOnly: false,
                options: [
                    { label: "Барилгын хөгжлийн төв" },
                ],
                label: {
                    empty: true,
                },
                colMd: 12
            },
            {
                inputType: "radio",
                type: "checkbox",
                registerName: "is_super_user",
                disabled: false,
                readOnly: false,
                options: [
                    { label: "Системийн админ" },
                ],
                label: {
                    empty: true,
                },
                colMd: 12
            },
            {
                component: <HrText>
                    Хэрэглэгч
                </HrText>
            },
            {
                inputType: "input",
                type: "text",
                registerName: "last_name",
                disabled: false,
                readOnly: false,
                label: {
                    empty: true,
                },
                colMd: 6
            },
            {
                inputType: "input",
                type: "text",
                registerName: "first_name",
                disabled: false,
                readOnly: false,
                label: {
                    empty: true,
                },
                colMd: 6
            },
            {
                inputType: "input",
                type: "email",
                registerName: "email",
                disabled: false,
                readOnly: false,
                label: {
                    empty: true,
                },
                colMd: 12
            },
            {
                inputType: "radio",
                type: "checkbox",
                registerName: "is_active",
                disabled: false,
                readOnly: false,
                options: [
                    { label: "Нэвтрэх эрх" },
                ],
                label: {
                    empty: true,
                },
                colMd: 12
            },
            inputValues.is_SSO
            ?
            {
                component: (
                    <div className='mb-3'>
                        ДАН гаар бүртгүүлсэн хэрэглэгч байна
                    </div>
                )
            }
            : {},
            inputValues.email
            ? (
                {
                    inputType: "radio",
                    type: "checkbox",
                    registerName: "is_new_password",
                    disabled: false,
                    readOnly: false,
                    options: [
                        { label: "Нууц үгийг шинэчлэх эсэх" },
                    ],
                    label: {
                        text: "Нууц үгийг шинэчлэх эсэх",
                        empty: true,
                    },
                    colMd: 12
                }
            )
            : {},
            inputValues.email
            ? {
                component: (
                    <small className='mb-3 d-flex flex-row align-items-center'>
                        <i className="fas fa-exclamation-circle text-warning me-2"></i>
                        Нууц үгийг шинэчлэхийг чагталж хадгалах дарснаар тухайн хүний бүртгэлтэй мэйл хаяг руу шинэ нууц үгийг явуулна
                    </small>
                )
            }
            : {}
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "all",
        },
    }
    return (
        <BoxBackground
            title={isCreate ? 'Хэрэглэгч үүсгэх' : 'Хэрэглэгч засах'}
            body={
                <>
                    <CForm
                        inputValues={inputValues}
                        form={form}
                    />
                </>
            }
        />
    );
}

export default AgendaAction;
