import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';

import BoxBackground from 'components/special/BoxBackground'
import Form from 'components/main/Form';

import { orgAdminValidate, orgFromFinancialAdminValidate } from 'utils/validate';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';
import useAuth from 'hooks/useAuth'

export default function OrgAdmin()
{
    const { userDetail:{user_role} } = useAuth()
    const companyApi = useApi().company
    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const [ options, setOptions ] = useState([]);
    const [ company, setCompanyData ] = useState([]);

    /** ROLE шалгах */
    if (user_role.is_financial_regulatory_commission_super)
    {
        var inputType = 'input',
            inputName = 'company',
            validateName = orgFromFinancialAdminValidate
    }
    else
    {
        var inputType = 'select',
            inputName = 'companyId',
            validateName = orgAdminValidate
    }

    const getData = async () =>
    {
        if (user_role.is_financial_regulatory_commission_super)
            return

        const { success, data, error } = await fetchData(companyApi.get())
        if(success)
        {
            setOptions(data.map((text) =>
            {
                return(
                    {
                        label : text.company,
                        value : text.id
                    }
                )
            }))
        }
    }

    const regist = async (body, ) =>
    {
        const { success, data, errors } = await fetchData(companyApi.post(body)).catch(error => error)
        if (!success)
        {
            return errors
        }
    }

    const registerCompany = async (body) =>
    {
        const { success, data, errors } = await fetchData(companyApi.createBarilgaService(body)).catch(error => error)
        if (!success)
        {
            return errors
        }
        else
        {
            getData()
        }
    }

    const registerInputs =
    {
        inputs: [
            {
                inputType: inputType,
                registerName: inputName,
                disabled: isLoading,
                isLoading: isLoading,
                options: options,
                async: true,
                helperText: inputName === "company" ? "Компаний нэрийг бичнэ үү" : "Эхний 2 тэмдэгтийг оруулна уу",
            },
            {
                inputType: "input",
                registerName: "email",
                type: "email",
                label:
                {
                    text: "Цахим шуудан",
                    empty: false,
                },
            },
        ],
        button:
        {
            onSubmit: regist,
            title: "Бүртгүүлэх",
            disabled: isLoading,
            isLoading: isLoading,
        },
        validate:
        {
            validateMode: "all",
            yupValidate: validateName
        }
    }

    const registerCompanyInputs =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "company",
                type: "number",
                colMd: 12,
                label:
                {
                    text: "Компанийн регистр",
                    empty: false,
                },
            }
        ],
        button:
        {
            onSubmit: registerCompany,
            title: "Бүртгүүлэх",
            disabled: isLoading,
            isLoading: isLoading,
        },
        validate:
        {
            validateMode: "all",
        }
    }

    useEffect(
        () =>
        {
            getData()
        },
        []
    )

    return (
        <>
            <Row>
                <Col className='mb-3' xl={6}>
                    <BoxBackground
                        title={"MCIS-ээс компани бүртгэх"}
                        body={
                            <Form form={registerCompanyInputs} />
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col className='mb-3' xl={6}>
                    <BoxBackground
                        title={"БАЙГУУЛЛАГЫН АДМИН БҮРТГЭХ"}
                        body={
                            <Form form={registerInputs} />
                        }
                    />
                </Col>
            </Row>
        </>
    )
}
