import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ParkingTypeAction from './ParkingTypeAction';
import ParkingType from '.';

import BoxBackground from 'components/special/BoxBackground';

const ParkingTypeCrudMain = () => {
    return (
        <Routes>
            <Route path="/*" element={<BoxBackground title='Зогсоолын төрөл' body={<ParkingType/>} />} />
            <Route path="/:id/" element={<ParkingTypeAction/>} />
        </Routes>
    );
}

export default ParkingTypeCrudMain;
