import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import CButton from 'components/main/Button';

import { getReadableFileSizeString } from 'utils/format';

import css from '../../style.module.css'

const ImageUpload = ({ id="imageUpload", boxWord='Зураг оруулах', register, errors, btnTitle, setValue, inputValues, value, placeholder, multiple=false, disabled=false,  }) => {

    const [ image, setImage ] = useState('')
    const [ images, setImages ] = useState([])

    const [ names, setNames ] = useState('')
    const [ sizes, setSizes ] = useState('')

    useEffect(
        () =>
        {
            const initialValue = 0;
            const sumWithInitial = Array.from(images).reduce(
              (previousValue, currentValue) => previousValue + currentValue.size,
              initialValue
            );
            setSizes(sumWithInitial)
            let namez = ''
            Array.from(images).map(
                (element, index) =>
                {
                    namez = namez + element.name + ', '
                    setNames(namez)
                }
            )
        },
        [images]
    )

    const showInput = () => document.getElementById(id).click()

    const imageUrl = typeof value === 'string' && !value?.startsWith("http") ? process.env.REACT_APP_SERVER_PUBLIC_URL + value : value

    return (
        <Row>
            <Col sm={12} md={12} lg={6} xxl={4}>
                <div className={css.imgContainer} onClick={() => showInput()}>
                    {image?.length !== 0 || value ? <img src={image ? URL.createObjectURL(image) : imageUrl} /> : <div>{boxWord}</div>}
                </div>
            </Col>
            <Col sm={12} md={12} lg={6} xxl={8}>
                <Form.Control
                    isInvalid={!!errors?.[register.name]?.message}
                    placeholder={placeholder}
                    type="text"
                    className="mt-2 cursorPointer"
                    readOnly={true}
                    value={ multiple ? names.slice(0, -2) : image?.name ? image?.name : typeof value === 'string' && value?.split('/')[value?.split('/')?.length - 1] || ''}
                    onClick={() => showInput()}
                />
                <input
                    {...register}
                    type="file"
                    id={id}
                    style={{ display: "none" }}
                    accept="image/*"
                    multiple={multiple}
                    onChange={(e) => {
                        register && register.onChange(e);
                        setImage(e.target.files[0]);
                        multiple && setImages(e.target.files)
                    }}
                    disabled={disabled}
                />
                {
                    !disabled
                    &&
                        <div className="d-flex justify-content-between">
                            {image ? <div className="mt-2">Файлын хэмжээ: {getReadableFileSizeString(multiple ? sizes : image?.size)}</div> : null}
                            <CButton className="mt-3 ms-auto" onClick={() => showInput()}>
                                {btnTitle ? btnTitle : "Файл оруулах"}
                            </CButton>
                        </div>
                }
            </Col>
        </Row>
    );
}

export default ImageUpload;
