import NestedTables from 'components/special/NestedTables';

import OldApartmentBuildingMain from './OldApartmentBuilding';

import ParkingTypeCrudMain from './Type/ParkingType/main';
import WindowTypeCrudMain from './Type/WindowType/main';
import DoorTypeCrudMain from './Type/DoorType/main';
import AdvantageCrudMain from './Type/Advantage/main';

import SongonMain from './Songon/main';

import ProjectLinkedSubAgenda from './ProjectLinkedSubAgenda/routes';
import ProjectCrudMain from './Project/main';
import EmployeeMain from "./Employee/main"
import SalesCrudMain from './Sales/main';
import CompaniesMain from './Companies/main'

import CompanyUser from "./CompanyUser";
import NewsLayout from './NewsLayout';
import Graphic from "./Graphic";
import Service from "./Service";
import Access from "./Access";
import Material from './Material';
import Helper from './Helper';

import {
    companyPageRoles,
    materialPageRoles,
    bhTuvPageRoles,
    finanialEmployeePageRoles,
    companyWithoutProject,
    toskPageRoles,
} from 'utils/consts/roles'

const companyRoutes = [
    // {
    //     name: "Мэдээллийн давхарга",
    //     path: "/",
    //     icon: <i className="fal fa-layer-group"></i>,
    //     component: NewsLayout,
    //     layout: "/company",
    //     exact: true
    // },
    // {
    //     name: "Хэрэглэгч",
    //     path: "/compnayuser/*",
    //     icon: <i className="fal fa-user"></i>,
    //     subMenu: [
    //         {
    //             name: "Хэрэглэгч1",
    //             component: CompanyUser,
    //             path: "/",
    //             exact: true
    //         },
    //         {
    //             name: "Хэрэглэгч2",
    //             component: CompanyUser,
    //             path: "/submenu2/*",
    //         },
    //     ],
    //     layout: "/company",
    // },
    {
        name: "Хандалт",
        path: "/access/*",
        component: Access,
        icon: <i className="fal fa-chart-pie-alt"></i>,
        layout: "/company",
        firstActive: true,
        needRoles: companyPageRoles,
        isNavigate: false,
    },
    // {
    //     name: "Төрөл",
    //     path: "/type/*",
    //     icon: <i className="fal fa-code-branch"></i>,
    //     needRoles: companyPageRoles,
    //     isNavigate: false,
    //     subMenu: [
    //         {
    //             name: "Давуу тал",
    //             component: AdvantageCrudMain,
    //             path: "/advantage/*",
    //             needRoles: companyPageRoles,
    //             isNavigate: false,
    //             firstActive: true,
    //         },
    //         {
    //             name: "Xаалганы төрөл",
    //             component: DoorTypeCrudMain,
    //             path: "/doortype/*",
    //             needRoles: companyPageRoles,
    //             isNavigate: false,
    //         },
    //         {
    //             name: "Цонхны төрөл",
    //             component: WindowTypeCrudMain,
    //             path: "/windowtype/*",
    //             needRoles: companyPageRoles,
    //             isNavigate: false,
    //         },
    //         {
    //             name: "3огсоолын төрөл",
    //             component: ParkingTypeCrudMain,
    //             path: "/parkingtype/*",
    //             needRoles: companyPageRoles,
    //             isNavigate: false,
    //         },
    //     ],
    //     layout: "/company",
    // },
    {
        name: "Төсөл",
        path: "/project/*",
        component: ProjectCrudMain,
        icon: <i className="fal fa-tasks"></i>,
        layout: "/company",
        needRoles: companyPageRoles,
        isNavigate: false,
    },
    // {
    //     name: "Компаниуд",
    //     path: "/companies/*",
    //     component: CompaniesMain,
    //     icon: <i className="fal fa-building"></i>,
    //     layout: "/company",
    //     needRoles: companyPageRoles,
    //     isNavigate: false,
    // },
    {
        name: "Ажилчид",
        path: "/employee/*",
        component: EmployeeMain,
        icon: <i className="fal fa-users"></i>,
        layout: "/company",
        needRoles: companyPageRoles,
        isNavigate: false,
    },
    // {
    //     name: "График дизайн ",
    //     path: "/graphic/*",
    //     icon: <i className="fal fa-palette"></i>,
    //     subMenu: [
    //         {
    //             name: "График1",
    //             component: Graphic,
    //             path: "/",
    //             exact: true
    //         },
    //         {
    //             name: "График2",
    //             component: Graphic,
    //             path: "/grapic2/*",
    //         },
    //     ],
    //     layout: "/company",
    // },
    {
        name: "Маркетингийн алба",
        path: "/sales/*",
        component: SalesCrudMain,
        icon: <i className="fal fa-analytics"></i>,
        layout: "/company",
        needRoles: companyPageRoles,
        isNavigate: false,
    },
    {
        name: "Санхүүгийн зохицуулах хороо",
        path: "/tables/*",
        icon: <i className="fal fa-table"></i>,
        layout: "/company",
        needRoles: finanialEmployeePageRoles,
        isNavigate: false,
        subMenu: [
            {
                name: "Худалдах",
                component: NestedTables,
                path: "/buy-sell/*",
                pathId: '/:tableId/*',
                needRoles: finanialEmployeePageRoles,
                isNavigate: false,
                firstActive: true,
            },
            {
                name: "Түрээслэх",
                component: NestedTables,
                path: "/turees/*",
                pathId: '/:tableId/*',
                needRoles: finanialEmployeePageRoles,
                isNavigate: false,
            },
            // {
            //     name: "Хүснэгт 3",
            //     component: NestedTables,
            //     path: "/zassan/*",
            //     pathId: '/:tableId/*',
            //     needRoles: finanialEmployeePageRoles,
            //     isNavigate: false,
            // },
            // {
            //     name: "Хүснэгт 4",
            //     component: NestedTables,
            //     path: "/menegment/*",
            //     pathId: '/:tableId/*',
            //     needRoles: finanialEmployeePageRoles,
            //     isNavigate: false,
            // },
            // {
            //     name: "Хүснэгт 5",
            //     component: NestedTables,
            //     path: "/busad/*",
            //     pathId: '/:tableId/*',
            //     needRoles: finanialEmployeePageRoles,
            //     isNavigate: false,
            // },
            // {
            //     name: "Хүснэгт 6",
            //     component: NestedTables,
            //     path: "/zohih-guilgee/*",
            //     pathId: '/:tableId/*',
            //     needRoles: finanialEmployeePageRoles,
            //     isNavigate: false,
            // },
        ],
    },
    {
        name: "Сонгон шалгаруулалт",
        path: "/songon/*",
        component: SongonMain,
        icon: <i className="fal fa-star"></i>,
        layout: "/company",
        needRoles: toskPageRoles,
        isNavigate: false,
    },
    {
        name: "Шалгуур үзүүлэлттэй холбоотой төслүүд",
        path: "/projectlinkedsubagenda/*",
        component: ProjectLinkedSubAgenda,
        icon: <i className="fal fa-tasks"></i>,
        layout: "/company",
        needRoles: companyPageRoles,
        isNavigate: false,
    },
    {
        name: "Материал бүртгэл",
        path: "/material/*",
        component: Material,
        icon: <i className="fal fa-toolbox"></i>,
        layout: "/company",
        needRoles: materialPageRoles,
        isNavigate: false,
    },
    // {
    //     name: "Хуучин барилга, орон сууц бүртгэх",
    //     path: "/oldapartment/*",
    //     component: OldApartmentBuildingMain,
    //     icon: <i className="far fa-building"></i>,
    //     layout: "/company",
    //     needRoles: companyPageRoles,
    //     isNavigate: false,
    // },
    // {
    //     name: "Сервис",
    //     path: "/service/*",
    //     component: Service,
    //     icon: <i className="fal fa-tools"></i>,
    //     layout: "/company",
    // },
    {
        name: "Видео хичээл",
        path: "/helper/*",
        component: Helper,
        icon: <i className="fal fa-video-camera"></i>,
        layout: "/company",
        needRoles: [ ...materialPageRoles, ...companyPageRoles, ...toskPageRoles, ...companyPageRoles ],
        isNavigate: false,
    },
];

export default companyRoutes;
