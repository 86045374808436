import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';
import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import MapChoice from '../MapChoice'

import { menegmentValidate, menegmentValidate2, oldMenegmentValidate, oldMenegmentValidate2, OldTureesValidate, OldTureesValidate2, tureesValidate2 } from 'utils/validate';
import { dataToValueLabel } from 'utils/format';
import { UL_HODLOH_TURUL } from 'utils/consts';
import useMapContext from 'hooks/useMapContext';

const MenegmentAction = ({ hasTitle=true, isNew=null, setTogglerState, updateId }) => {

    const [ inputValues, setInputValues ] = useState('');
    const [ realValues, setRealValues ] = useState('')

    const [ projectNested, setProjectNested ] = useState([]);
    const [ apartmentNested, setApartmentNested ] = useState([])
    const [ estateTypes, setEstateTypes ] = useState({})
    const [ buildingsOfApart, setBuildings ] = useState([]);

    const [ choosedNew, setChoosedNew ] = useState(null)
    const [ units, setUnits ] = useState([])
    const [ selectUnit1, setUnit1 ] = useState('')
    const [ selectUnit2, setUnit2 ] = useState('')
    const [ unit2Options, setUnit2Options ] = useState([])
    const [ project, setProject ] = useState({});
    const [ apartment, setApartment ] = useState({});
    const [ activitys, setActivitys ] = useState([])
    const navigate = useNavigate();
    let { id, tableId } = useParams()

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const buildingTableApi = useApi().buildingTable
    const projectNestedApi = useApi().projectNested
    const unitApi = useApi().unit
    const activityApi = useApi().activity

    const { selectedUnits, choosedFeature, setShouldClosePopup } = useMapContext()

    /** create бол true, update бол false буцаана */
    let isCreate = !hasTitle ? true : id === 'create'
    isCreate = !isCreate ? isCreate : updateId ? false : true
    id = updateId ? updateId : id

    useEffect(
        async () =>
        {
            if(!isNew)
            {
                setEstateTypes(UL_HODLOH_TURUL.filter(
                    (element, index) =>
                    {
                        if(index === 0) return
                        return element
                    }
                ))
            }
            else
            {
                setEstateTypes([UL_HODLOH_TURUL[0]])
            }

            if (isNew === true)
            {
                const { success, data, error } = await fetchData(buildingTableApi.buildingOfApartment(choosedFeature.feature.get("apartment_id"), tableId)).catch(err => err)
                if (success)
                {
                    setBuildings(dataToValueLabel(data, 'id', 'name'))
                }
            }
            else {
                const unitRsp = await fetchData(unitApi.get())
                const projectNestedRsp = await fetchData(projectNestedApi.get())
                if(unitRsp.success && projectNestedRsp.success)
                {
                    setProjectNested(projectNestedRsp.data)
                    setUnits(unitRsp.data)
                }
            }
            const activityRsp = await fetchData(activityApi.get())
            if (activityRsp.success)
            {
                setActivitys(dataToValueLabel(activityRsp.data, 'id', 'name'))
            }

        },
        [isNew]
    )

    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data } = await fetchData(buildingTableApi['menegment'].getOne(id))
                if (success)
                {
                    setInputValues(data)
                }
            }
        },
        []
    )

    const handleIsNew = (value) =>
    {
        if(value === 1) setChoosedNew(true)
        else setChoosedNew(false)
    }

    useEffect(
        () =>
        {
            if(inputValues === '') return
            let zadalsanData = {

                irgen_huuliin_etgeedin_ner: inputValues.hariltsagch_info.irgen_huuliin_etgeedin_ner,
                register: inputValues.hariltsagch_info.register,
                irget_huuliin_etgeedin_haryalal: inputValues.hariltsagch_info.irget_huuliin_etgeedin_haryalal,

                project: inputValues.building_info?.building?.project,
                apartment: inputValues.building_info.building?.apartment,
                building: inputValues.building_info.building?.id,

                mkv: inputValues.mkv,

                ognoo: new Date(inputValues.geree_info.ognoo),
                ul_hudluh_hurungiin_turul: inputValues.ul_hudluh_hurungiin_turul,
                uilchilgee_description: inputValues.uilchilgee_description,
                niit_dun: inputValues.geree_info?.niit_dun,
                shimtgel_dun: inputValues.geree_info.shimtgel_dun,
                dugaar: inputValues.geree_info.dugaar,
                huwi: inputValues.geree_info.huwi,
                geree_gerchilgee_dugaar: inputValues.geree_info.geree_gerchilgee_dugaar,
                unit1: inputValues.bairshil_info.aimar_hot_info?.id,
                unit2: inputValues.bairshil_info.sum_duureg_info?.id,
                unit3: inputValues.bairshil_info.bag_horoo_info?.id,
                full_name: inputValues.building_info.full_name,
                geoid: inputValues.building_info.geo_id,

            }
            setRealValues(zadalsanData)
            setProject(inputValues.building_info.building?.project)
            setApartment(inputValues.building_info.building?.apartment)
            setUnit1(inputValues.bairshil_info.aimar_hot_info?.id)
            setUnit2(inputValues.bairshil_info.sum_duureg_info?.id)
            handleIsNew(inputValues.ul_hudluh_hurungiin_turul)
        },
        [inputValues]
    )

    const apartments = useMemo(
        () =>
        {
            if(!project) return null
            let unit1Id = typeof project === "object" ? project.id : project
            const unit = projectNested?.find(
                (el) =>
                {
                    return el.id === unit1Id
                }
            )
            setApartmentNested(unit?.apartments)
            return dataToValueLabel(unit?.apartments, 'id', 'name')
        },
        [project, projectNested]
    )

    const buildings = useMemo(
        () =>
        {
            if(!apartment) return null
            let unit1Id = typeof apartment === "object" ? apartment.id : apartment
            const unit = apartmentNested?.find(
                (el) =>
                {
                    return el.id === unit1Id
                }
            )
            return dataToValueLabel(unit?.buildings, 'id', 'name')
        },
        [apartment, apartmentNested]
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData, { reset }) =>
    {
        const body = {
            ul_hudluh_hurungiin_turul: formData.ul_hudluh_hurungiin_turul,
            mkv: formData.mkv,
            uilchilgee_description: formData.uilchilgee_description,
            hariltsagch: {
                irgen_huuliin_etgeedin_ner: formData.irgen_huuliin_etgeedin_ner,
                register: formData.register,
                irget_huuliin_etgeedin_haryalal: formData.irget_huuliin_etgeedin_haryalal
            },
            geree: {
                ognoo: formData.ognoo,
                dugaar: formData.dugaar,
                shimtgel_dun: formData.shimtgel_dun,
                niit_dun: formData.niit_dun,
                huwi: formData.huwi,
                geree_gerchilgee_dugaar: formData.geree_gerchilgee_dugaar
            },
            barilga: {
                building: formData.building,
                apartment: isNew ? choosedFeature?.feature?.get("apartment_id") : apartment
            },
            bairshil: {
                aimag_hot: "",
                sum_duureg: "",
                bag_horoo: "",
            },
        }

        const oldBody = {
            ul_hudluh_hurungiin_turul: formData.ul_hudluh_hurungiin_turul,
            mkv: formData.mkv,
            uilchilgee_description: formData.uilchilgee_description,
            hariltsagch: {
                irgen_huuliin_etgeedin_ner: formData.irgen_huuliin_etgeedin_ner,
                register: formData.register,
                irget_huuliin_etgeedin_haryalal: formData.irget_huuliin_etgeedin_haryalal
            },
            geree: {
                ognoo: formData.ognoo,
                dugaar: formData.dugaar,
                shimtgel_dun: formData.shimtgel_dun,
                niit_dun: formData.niit_dun,
                huwi: formData.huwi,
                geree_gerchilgee_dugaar: formData.geree_gerchilgee_dugaar
            },
            bairshil: {
                aimag_hot: typeof isNew === 'boolean' && !isNew ? selectedUnits.unit1 : formData.unit1,
                sum_duureg: typeof isNew === 'boolean' && !isNew ? selectedUnits.unit2 : formData.unit2,
                bag_horoo: typeof isNew === 'boolean' && !isNew ? selectedUnits.unit3 : formData.unit3,
            },
            barilga: {
                full_name: formData.full_name,
                geo_id: isNew ? choosedFeature?.feature?.getId() : formData.geoid
            },
        }

        if(!typeof isNew === 'boolean')
        {
            delete body.barilga.geo_id
            delete oldBody.barilga.geo_id
        }

        if (isCreate)
        {
            const { errors, success, data } = await fetchData(buildingTableApi['menegment'].post(choosedNew ? body : oldBody)).catch(error => error)
            if(success)
            {
                setTogglerState && setTogglerState(false)
                reset()
                typeof isNew !== 'boolean' && navigate(-1)
            }
            if (!success) return errors
            return
        }
        const { errors, success, data } = await fetchData(buildingTableApi['menegment'].put(choosedNew ? body : oldBody, id)).catch(error => error)
        if(success)
            {
                setTogglerState && setTogglerState(false)
                reset()
                typeof isNew !== 'boolean' && navigate(-1)
                return
            }
        if (!success) return errors
    }

    const secondUnits = useMemo(
        () =>
        {
            if(!selectUnit1) return null
            let unit1Id = typeof selectUnit1 === "object" ? selectUnit1.id : selectUnit1
            const unit = units?.find(
                (el) =>
                {
                    return el.id === unit1Id
                }
            )
            setUnit2Options(unit?.unit2)
            return dataToValueLabel(unit?.unit2, 'id', 'name')
        },
        [selectUnit1, units]
    )

    const thirdUnits = useMemo(
        () =>
        {
            if(!selectUnit2) return []
            let unit2Id = typeof selectUnit2 === "object" ? selectUnit2.id : selectUnit2
            const unit = unit2Options?.find(
                (el) =>
                {
                    return el.id === unit2Id
                }
            )
            return dataToValueLabel(unit?.unit3, 'id', 'name', null, 'code')
        },
        [unit2Options, selectUnit2]
    )

    const displaySomeInputs = useMemo(
        () =>
        {
            if (isNew == true)
            {
                return [
                    {
                        inputType: "select",
                        registerName: "building",
                        options: buildingsOfApart,
                        colMd: 6,
                        disabled: !isCreate,
                    },
                ]
            }
            if(typeof choosedNew !== 'boolean') return []
            if(typeof isNew === 'boolean' && !isNew) return (
                [
                    {
                        inputType: "input",
                        registerName: "full_name",
                        type: "textArea",
                        colMd: 12
                    },
                ]
            )
            if(!choosedNew) return [
                {
                    inputType: "select",
                    registerName: "unit1",
                    onChange: value => setUnit1(value.value),
                    disabled: isLoading,
                    options: dataToValueLabel(units, 'id', 'name'),
                    disabled: !isCreate,
                    colMd: 4
                },
                {
                    inputType: "select",
                    registerName: "unit2",
                    onChange: value => setUnit2(value.value),
                    disabled: isLoading,
                    nested: 'unit1',
                    options: secondUnits,
                    disabled: !isCreate,
                    colMd: 4
                },
                {
                    inputType: "select",
                    registerName: "unit3",
                    disabled: isLoading,
                    nested: 'unit1',
                    options: thirdUnits,
                    disabled: !isCreate,
                    colMd: 4
                },
                {
                    inputType: "input",
                    registerName: "full_name",
                    type: "textArea",
                    colMd: 12
                },
                {
                    component: <MapChoice units={thirdUnits} disabled={!isCreate}/>
                }
            ]
            return [
                {
                    inputType: "select",
                    registerName: "project",
                    onChange: value => setProject(value.value),
                    options: dataToValueLabel(projectNested, 'id', 'name'),
                    colMd: 12,
                    disabled: !isCreate,
                },
                {
                    inputType: "select",
                    registerName: "apartment",
                    onChange: value => setApartment(value.value),
                    nested: 'project',
                    options: apartments,
                    colMd: 6,
                    disabled: !isCreate,
                },
                {
                    inputType: "select",
                    registerName: "building",
                    nested: 'apartment',
                    options: buildings,
                    colMd: 6,
                    disabled: !isCreate,
                },
            ]
        },
        [choosedNew, projectNested, apartments, buildings, selectUnit1, units, unit2Options, selectUnit2, isNew, buildingsOfApart, apartment, project, thirdUnits]
    )

    const displayEstateTypes = useMemo(
        () =>
        {
            if (typeof isNew === "boolean") {
				return {
					inputType: "select",
					registerName: "ul_hudluh_hurungiin_turul",
					onChange: value => handleIsNew(value.value),
                    shouldReset: isNew,
                    disabled: !isCreate,
					options: estateTypes,
				}
			}
            else
            {
				return {
					inputType: "select",
					registerName: "ul_hudluh_hurungiin_turul",
					onChange: value => handleIsNew(value.value),
                    disabled: !isCreate,
					options: UL_HODLOH_TURUL,
				}
			}
        },
        [isNew, estateTypes, UL_HODLOH_TURUL]
    )

    /** Form утгууд */
    const form =
    {
        inputs: [
            displayEstateTypes,
            {
                component: (
                    <hr />
                )
            },
            {
                inputType: "input",
                registerName: "dugaar",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
            },
            {
                inputType: "input",
                registerName: "geree_gerchilgee_dugaar",
                type: "number",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                step: 0.01,
            },
            {
                inputType: "input",
                registerName: "irgen_huuliin_etgeedin_ner",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                colMd: 4,
            },
            {
                inputType: "input",
                registerName: "register",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                colMd: 4,
            },
            {
                inputType: "input",
                registerName: "irget_huuliin_etgeedin_haryalal",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                colMd: 4,
            },
            {
                component: (
                    <hr />
                )
            },
            ...displaySomeInputs,
            isNew === true || !choosedNew
            &&
                {
                    inputType: "input",
                    registerName: "mkv",
                    type: "number",
                    step: 0.01,
                },
            {
                inputType: "input",
                registerName: "uilchilgee_description",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
            },
            {
                component: (
                    <hr />
                )
            },
            {
                inputType: "datePicker",
                registerName: "ognoo",
            },
            {
                inputType: "input",
                registerName: "niit_dun",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
            },
            {
                inputType: "input",
                registerName: "shimtgel_dun",
                type: "number",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                step: 0.01,
            },
            {
                inputType: "input",
                registerName: "huwi",
                type: "number",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                step: 0.01,
            },
        ],
        button: {
            onSubmit: btnOnSubmit,
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "all",
            yupValidate: (
                typeof isNew === 'boolean'
                ?
                    isNew
                    ?
                        menegmentValidate2
                    :
                        oldMenegmentValidate2
                :
                    choosedNew
                    ?
                        menegmentValidate
                    :
                        oldMenegmentValidate
            ),
        },
    }
    return (
        <BoxBackground title={hasTitle ? 'Зуучлалын мэдээ' : ''} body={<CForm isLoading={isLoading} inputValues={realValues} form={form}/>} />
    );
}

export default MenegmentAction;
