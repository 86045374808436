import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';
import CForm from 'components/main/Form';

import useLoader from 'hooks/useLoader';
import useAuth from 'hooks/useAuth';
import useApi from 'hooks/useApi';

import { salesValidate } from 'utils/validate';

const SalesAction = () => {

    const [ inputValues, setInputValues ] = useState('');

    const navigate = useNavigate();
    const { id } = useParams()

    const { companyId } = useAuth()

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const salesApi = useApi().sales

    /** create бол true, update бол false буцаана */
    const isCreate = id === 'create'

    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data, errors } = await fetchData(salesApi.getOne(id)).catch( err => err )
                if (success)
                {
                    setInputValues(data)
                }
            }
        },
        []
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        formData['company'] = companyId
        if (isCreate)
        {
            const { errors, success, data } = await fetchData(salesApi.post(formData)).catch(error => error)
            if (success) return navigate(-1)
            else return errors
        }
        const { errors, success, data } = await fetchData(salesApi.put(formData, id)).catch(error => error)
        if (success) navigate(-1)
        else return errors
    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "manager",
                type: "text",
                placeholder: "Mенежер оруулна уу",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
            },
            {
                inputType: "input",
                registerName: "contact",
                type: "text",
                placeholder: "Холбоо барих",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
            },
            {
                inputType: "input",
                registerName: "email",
                type: "text",
                placeholder: "И-мэйл оруулна уу",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
            },
            {
                inputType: "input",
                registerName: "website",
                type: "text",
                placeholder: "Вэб сайт оруулна уу",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
            },
            {
                inputType: "input",
                registerName: "facebook",
                type: "text",
                placeholder: "Facebook оруулна уу",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
            },
            {
                inputType: "input",
                registerName: "instagram",
                type: "text",
                placeholder: "Instagram оруулна уу",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
            },
            {
                inputType: "input",
                registerName: "twitter",
                type: "text",
                placeholder: "Twitter оруулна уу",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
            },
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "all",
            yupValidate: salesValidate,
        },
    }
    return (
        <BoxBackground title={isCreate ? 'Маркетингийн алба үүсгэх' : 'Маркетингийн алба засах'} body={<CForm inputValues={inputValues} form={form}/>}/>
    );
}

export default SalesAction;
