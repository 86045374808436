import BoxBackground from 'components/special/BoxBackground'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import EmailChange from './EmailChange'

import UserDetails from './UserDetails'

export default function Profile()
{
    return (
        <Container fluid className='fs-14'>
            <Row>
                <Col md={8} className="mb-4">
                    <BoxBackground title='Хэрэглэгчийн мэдээлэл' body={<UserDetails/>} />
                    <BoxBackground className='mt-4' title='И-мэйл хаяг солих' body={ <EmailChange />} />
                </Col>
            </Row>
        </Container>
    )
}
