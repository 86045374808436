import React, { useEffect, useState } from 'react';

import CButton from 'components/main/Button';
import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useAuth from 'hooks/useAuth';
import useLoader from 'hooks/useLoader';

import { changeNames } from 'utils/validate';

const ChangeNames = () => {

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })
    const { userDetail, getDetail } = useAuth()
    const [ inputValues, setInputValues ] = useState({})
    const nameApi = useApi().user.name

    const [ canEdit, setCanEdit ] = useState(false)

    const isSSO = userDetail.is_SSO

    const handleSubmitMail = async (body) =>
    {
        if (isSSO) return
        const { success } = await fetchData(nameApi.changeName(body))
        if(success)
        {
            getDetail()
            setCanEdit(false)
        }
    }

    useEffect(
        () =>
        {
            setInputValues({
                first_name: userDetail.first_name,
                last_name: userDetail.last_name
            })
        },
        [userDetail]
    )

    const nameForm = {
        inputs: [
            {
                inputType: "input",
                registerName: "last_name",
                type: "text",
                disabled: !canEdit ? true : isLoading,
                colMd: 12
            },
            {
                inputType: "input",
                registerName: "first_name",
                type: "text",
                disabled: !canEdit ? true : isLoading,
                colMd: 12
            },
        ],
        button: {
            onSubmit: (data) => handleSubmitMail(data),
            title: "Нэр хадгалах",
            variant: 'main',
            disabled: isLoading,
            className: isSSO ? "d-none" : "",
            isLoading: isLoading,
            show: canEdit ? true : false
        },
        validate: {
            validateMode: "all",
            yupValidate: changeNames,
        }
    }

    const toggle = () =>
    {
        setCanEdit(true)
    }

    return (
        <div>
            <CForm inputValues={inputValues} form={nameForm} />
            {
                !canEdit && <CButton onClick={toggle}>Нэр өөрчлөх</CButton>
            }
        </div>
    );
}

export default ChangeNames;
