import { useContext, useEffect, useMemo, useState } from 'react'
import { Col, Container, Overlay, Row, Tooltip } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

import CSelect from 'components/main/Form/InputTypes/Select'

import BuildingAnalytacSelects from './BuildingAnalytacSelects'
import BuildingAveragePriceList from './BuildingAveragePriceList'
import BuildingPriceChart from './BuildingPriceChart'
import BuildingPriceDiffrence from './BuildingPriceDiffrence'

import useApi from 'hooks/useApi'
import useLoader from 'hooks/useLoader'
import useMobile from 'hooks/useMobile'
import useMapContext from 'hooks/useMapContext'

import { StateContext } from 'context/stateContext'

import { searchParamsToObject } from 'utils/browser'
import { dataToValueLabel } from 'utils/format'

import css from '../OverLayBuilding/style.module.css'
import { DATETYPES, ONE_MONTH_TO_MILLISECONDS } from 'utils/consts'
import BuildingMaterialChart from './BuildingMaterialChart'

const selectType = [
	{ value: 2, label: 'Өмчлөх нөхцөл:', isDisabled: true },
	{ value: 0, label: "Худалдах" },
	{ value: 1, label: "Түрээслэх" },
]

const sortTypes = [
	{ value: 'sort', label: 'Эрэмбэ:', isDisabled: true },
	{ value: 'asc', label: 'Буурах' },
	{ value: 'desc', label: 'Өсөх' },
]

const BuildingPriceAnalytacs = () =>
{

	const { sideShow, setSideShow, type, selectedFeature } = useContext(StateContext)
	const { auSource } = useMapContext()
    const [ units, setUnits ] = useState([])
	const [ selectedUnits, setSelectedUnits ] = useState({})
	const [ selectedUnitType, setUnitType ] = useState(1)
	const [ selectedDataType, setDataType ] = useState(0)
	const [ selectedMaterial, setSelectedMaterial ] = useState()
	const [ materials, setMaterials ] = useState([])
	const [ ableUnit, setAbleUnit ] = useState([])
	const [ sortType, setSortType ] = useState('asc')
	const [ endDate, setEndDate ] = useState({ value: 6, label: '6 сар' })

	const location = useLocation()

	const { isMobile } = useMobile()
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
    const unitApi = useApi().unit
	const materialApi = useApi().material

    const getData = async () =>
    {
        const { success, data, errors } = await fetchData(unitApi.get()).catch(err => err)
        if(success)
        {
            setUnits(data.sort(function(a, b){return b.code - a.code}))
        }
    }

	const getMaterials = async () =>
    {
        const { success, data, errors } = await fetchData(materialApi.get()).catch(err => err)
        if(success)
        {
            setMaterials(dataToValueLabel(data, "id", "name"))
        }
    }

    useEffect(async () =>
	{
		await getData()
		await getMaterials()
	}, [])

	useEffect(
		() =>
		{
			setDataType(type)
		},
		[type]
	)

	const whatUnitIsThis = () =>
	{
		if(selectedFeature.values_.administrativeunitprimaryclass) return 3
		if(selectedFeature.values_.administrativeunitsubclass) return 2
		if(selectedFeature.values_.administrativeunitmajorclass) return 1
	}

	useEffect(
		() =>
		{
			let unitsz = {}
			if(!selectedFeature) return
			let unitType = whatUnitIsThis()
			if(unitType === 1)
			{
				let foundUnit1 = units.find(element => element.code === selectedFeature.values_.localid)
				Object.assign(unitsz, {'unit1-code': foundUnit1?.code})
				setSelectedUnits(unitsz)
			}
			if(unitType === 2)
			{
				let foundUnit1 = units.find(element => element.name === selectedFeature.values_.administrativeunitmajorclass)
				let foundUnit2 = foundUnit1?.unit2?.find( element => element.code === selectedFeature.values_.localid)
				Object.assign(unitsz, {'unit1-code': foundUnit1?.code})
				Object.assign(unitsz, {'unit2-code': foundUnit2?.code})
				setSelectedUnits(unitsz)
			}
			if(unitType === 3)
			{
				let foundUnit1 = units.find(element => element.name === selectedFeature.values_.administrativeunitmajorclass)
				let foundUnit2 = foundUnit1?.unit2?.find( element => element.name === selectedFeature.values_.administrativeunitsubclass)
				let foundUnit3 = foundUnit2?.unit3?.find( element => element.code === selectedFeature.values_.localid)
				Object.assign(unitsz, {'unit1-code': foundUnit1?.code})
				Object.assign(unitsz, {'unit2-code': foundUnit2?.code})
				Object.assign(unitsz, {'unit3-code': foundUnit3?.code})
				setSelectedUnits(unitsz)
			}

		},
		[selectedFeature?.values_?.localid]
	)

	useEffect(
		() =>
		{
			let searchObj = searchParamsToObject(location.search)
			let unitsz = {}
			if(Object.keys(searchObj).includes('unit1-code')) Object.assign(unitsz, {'unit1-code': searchObj['unit1-code']})
			if(Object.keys(searchObj).includes('unit2-code')) Object.assign(unitsz, {'unit2-code': searchObj['unit2-code']})
			if(Object.keys(searchObj).includes('unit3-code')) Object.assign(unitsz, {'unit3-code': searchObj['unit3-code']})
			setSelectedUnits(unitsz)
		},
		[location, auSource]
	)

    useEffect(
        () =>
        {
            getData()
        },
        []
    )

	const displayOthers = (length, isNumber=false) =>
	{
		const unitTypes = [
			{ value: 1, label: 'Аймаг/хот', },
			{ value: 2, label: 'Сум/дүүрэг', },
			{ value: 3, label: 'Баг/хороо', },
		]
		if(isNumber)
		{
			if(length === 1) return unitTypes[0]
			if(length === 2) return unitTypes[1]
			if(length === 3) return unitTypes[2]
			return
		}
		if(length === 2) return unitTypes[0]
		if(length === 4) return unitTypes[1]
		if(length === 6) return unitTypes[2]
	}

	const displayPriceDiffrence = useMemo(
		() =>
		{
			if(!selectedUnits) return
			if(Object.keys(selectedUnits).length === 0) return
			let ableUnitz = []
			return Object.entries(selectedUnits).map(
				(element, index) =>
				{
					ableUnitz.push(displayOthers(element[1].length))
					setAbleUnit(ableUnitz)
					return <BuildingPriceDiffrence endDate={endDate} sortType={sortType} selectedDataType={selectedDataType} key={index} code={element[1]} />
				}
			)
		},
		[selectedUnits, selectedDataType, sortType, endDate]
	)

	const handleUnitType = (value) =>
	{
		setUnitType(value.value)
	}

	const handleSelectType = (value) =>
	{
		setDataType(value.value)
	}

	// Юу сонгоод яаж харуулаад байгааг ойлгомжтой харуулна
	const displayUnitRoutes = useMemo(
		() =>
		{
			let aimagName = ''
			let sumName = ''
			units.find(
				(element) =>
				{
					if(element.code === selectedUnits['unit1-code'])
					{
						aimagName = element.name
						return element
					}
				}
			)?.unit2?.find(
				(element) =>
				{
					if(selectedUnitType === 3 && element.code === selectedUnits['unit2-code'])
					{
						sumName = element.name
						return element
					}
				}
			)

			let routes = {
				1: displayOthers(selectedUnitType, true).label,
				2: `${aimagName ? aimagName + '-' : ''}${displayOthers(selectedUnitType, true).label}`,
				3: `${aimagName ? aimagName + '-' : ''}${sumName ? sumName + '-' : ''}${displayOthers(selectedUnitType, true).label}`,
			}
			return routes[selectedUnitType]
		},
		[selectedUnits, selectedUnitType, units]
	)


	useEffect(
		() =>
		{
			if (!sideShow) return
			var analytacWrapper = document.getElementById("analytac")
			var resizer = document.getElementById("resizer")
			if (!resizer) return
			resizer.addEventListener("mousedown", initDrag, false)
			var startX, startWidth

			function initDrag(e) {
				startX = e.clientX
				startWidth = parseInt(document.defaultView.getComputedStyle(analytacWrapper).width, 10)
				document.documentElement.addEventListener("mousemove", doDrag, false)
				document.documentElement.addEventListener("mouseup", stopDrag, false)
			}

			function doDrag(e) {
				analytacWrapper.style.width = startWidth + e.clientX - startX + "px"
			}
			function stopDrag(e) {
				document.documentElement.removeEventListener("mousemove", doDrag, false)
				document.documentElement.removeEventListener("mouseup", stopDrag, false)
			}
		},
		[isMobile, sideShow]
	)

	useEffect(
		() =>
		{
			if(materials.length === 0) return
			setSelectedMaterial(materials[0].value)
		},
		[materials]
	)

    return (
		<>
			<Container
				id="analytac"
				className={`${isMobile ? css.buildingPriceWrapperMobile : css.buildingPriceWrapper}`}
				style={
					sideShow
						? { left: 0, zIndex: 1043 }
						: { left: "-500px", width: "500px" }
				}>
				<div className={css.statistacsWrapper}>
					<Row>
						<BuildingAnalytacSelects
							handleSelectType={handleSelectType}
							selectedDataType={selectedDataType}
							setSelectedUnits={setSelectedUnits}
							setSortType={setSortType}
							setEndDate={setEndDate}
							selectType={selectType}
							sortType={sortType}
							endDate={endDate}
							units={units}
							sortTypes={sortTypes}
							selectedUnits={selectedUnits}
							dateTypes={DATETYPES}
						/>
					</Row>
					<Row>{displayPriceDiffrence}</Row>
					<Row>
						<Col className={css.boxWrapper}>
							<div className={css.boxWrapperTitle}>
								<div style={{ marginLeft: "5px" }}>{displayUnitRoutes} график</div>
								<div className="d-flex position-relative">
									<CSelect onChange={handleUnitType} borderColor="none" value={1} options={ableUnit} />
									<CSelect onChange={(value) => setSelectedMaterial(value.value)} value={materials?.[0]?.value} borderColor="none" options={materials} />
								</div>
							</div>
							<BuildingPriceChart
								selectedUnitType={selectedUnitType}
								selectedMaterial={selectedMaterial}
								selectedDataType={selectedDataType}
								selectedUnits={selectedUnits}
								sortType={sortType}
								endDate={endDate}
								units={units}
							/>
						</Col>
					</Row>
					{/* <Row>
						<Col className={css.boxWrapper}>
							<div className={css.boxWrapperTitle}>
								<div style={{ marginLeft: "5px" }}>Материалын үнэ</div>
							</div>
							<BuildingMaterialChart endDate={endDate} selectedMaterial={selectedMaterial} materials={materials}/>
						</Col>
					</Row> */}
					{/* <BuildingAveragePriceList units={units} /> */}
				</div>
				{sideShow && (
					<div onClick={() => setSideShow(!sideShow)} className={css.sideHide}>
						<i className="fas fa-arrow-alt-to-bottom"></i>
					</div>
				)}
				{
					sideShow && isMobile
					?
						null
					:
						<div
							id={"resizer"}
							onClick={() => !sideShow && setSideShow(!sideShow)}
							className={`${css.resizer} ${sideShow ? css.resizerReal : ""}`}
							title={!sideShow ? "Үнийн мэдээлэл" : ""}
						>
							{sideShow ? "Чирч томруулах" : ""} <i className={sideShow ? "fal fa-arrows-v ms-2" : "fas fa-chart-pie"}></i>
							{/* {sideShow ? <i className="fas fa-arrow-alt-to-bottom"></i> : <i className="fas fa-arrow-alt-to-top"></i>} */}
						</div>
				}
			</Container>
		</>
	)
}

export default BuildingPriceAnalytacs
