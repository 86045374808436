import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

export default function Helper()
{
    const helperApi = useApi().helper

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })

    const [ data, setData ] = useState([])

    const getData = async () =>
    {
        const { success, data } = await fetchData(helperApi.getUser())
        if(success)
        {
            setData(data)
        }
    }

    useEffect(
        () =>
        {
            getData()
        },
        []
    )

    return (
        <Row>
            {
                data.map((val, idx) =>
                {
                    return (
                        <Col md={4} key={idx}>
                            {val.name}
                            {
                                val.pdf
                                ?
                                    <a href={process.env.REACT_APP_SERVER_PUBLIC_URL + val.pdf} target='_blank'><i className="fa fa-file-pdf text-danger ms-1" aria-hidden="true"></i></a>
                                :
                                    null
                            }
                            <video controls className='w-100 my-3'>
                                <source src={process.env.REACT_APP_SERVER_PUBLIC_URL + val.video} type='video/mp4' />
                            </video>
                        </Col>
                    )
                })
            }
        </Row>
    )
}
