import React from 'react'

import css from 'components/special/GetOneDetail/style.module.css'
import label from 'utils/validate/label'

export default function TitleValue({ title, value }) {
    return (
        <div className="d-flex">
            <div className={css.columnOne}>
                <span className={css.fieldName}>{label[title] ? label[title] : title}:</span>
            </div>
            <div className={css.columnTwo} >
                <span className={css.valueName}>{value}</span>
            </div>
        </div>
    )
}
