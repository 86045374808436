import Slider from 'rc-slider';
import { useEffect, useMemo, useState } from 'react';

import useMapContext from 'hooks/useMapContext';

import css from '../../style.module.css'

export default function Floor({ fns })
{

    const { allFilters } = useMapContext()
    const initValue = {
        'floor-begin': allFilters['floor-begin'] ?? 0,
        'floor-end': allFilters['floor-end'] ?? 0
    }
    const [ value, setValue ] = useState(initValue)

    const max = 30
    const min = 1
    const step = 1

    useEffect(
        () =>
        {
            fns.floor = setValue
        },
        []
    )

    const handleOnChange = (value) =>
    {
        setValue(
            {
                'floor-begin': value[0],
                'floor-end': value[1],
            }
        )
        allFilters['floor-begin'] = value[0]
        allFilters['floor-end'] = value[1]
    }

    const defaultMarks = useMemo(
        () =>
        {
            let oneStep = max / 10
            let marks = {}
            for(var step = oneStep; step <= max; step = step + oneStep)
            {
                marks[step] = Math.ceil(step)
            }
            return marks
        },
        [min ,max]
    )

    const values = useMemo(
        () =>
        {
            return (
                <div className={`${css.title} mt-4`}>
                    {value['floor-begin']} - {value['floor-end']}
                </div>
            )
        },
        [value]
    )

    return (
        <div className='mb-1'>
            <h6>Хэдэн давхарт</h6>
            <Slider
                range={true}
                allowCross={false}
                max={max}
                marks={defaultMarks}
                min={min}
                value={[value['floor-begin'], value['floor-end']]}
                step={step}
                draggableTrack
                onChange={handleOnChange}
            />
            {values}
        </div>
    )
}
