import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const ProgramChart = ({ chartData, setChoosedProgram }) =>
{
	useEffect(
        () =>
        {
			let root = am5.Root.new("chartdiv")

			root.setThemes([am5themes_Animated.new(root)])

			let chart = root.container.children.push(
				am5xy.XYChart.new(root, {
					panX: false,
					panY: false,
					wheelX: "none",
					wheelY: "none",
				}),
			)

			let yAxis = chart.yAxes.push(
				am5xy.CategoryAxis.new(root, {
					maxDeviation: 0,
					categoryField: "name",
					renderer: am5xy.AxisRendererY.new(root, { minGridDistance: 30 }),
				}),
			)

			let xAxis = chart.xAxes.push(
				am5xy.ValueAxis.new(root, {
					maxDeviation: 0,
					min: 0,
					max: 100,
					renderer: am5xy.AxisRendererX.new(root, {}),
				}),
			)

			let series = chart.series.push(
				am5xy.ColumnSeries.new(root, {
					name: "Series 1",
					xAxis: xAxis,
					yAxis: yAxis,
					valueXField: "result",
					sequencedInterpolation: true,
					categoryYField: "name",
				}),
			)

			series.bullets.push(function () {
				return am5.Bullet.new(root, {
					locationX: 1,
					locationY: 0.5,
					sprite: am5.Label.new(root, {
						centerX: am5.p100,
						centerY: am5.p50,
						text: "{result}%",
						fill: am5.color(0xffffff),
						populateText: true,
					}),
				})
			})

			let columnTemplate = series.columns.template

			columnTemplate.setAll({
				tooltipText: "Сонгоно уу",
				maxHeight: 20,
				cursorOverStyle: 'pointer',
			});

			columnTemplate.events.on("click", function (ev) {
				setChoosedProgram(ev.target.dataItem.dataContext)
			})

			columnTemplate.setAll({
				cornerRadiusBR: 5,
				cornerRadiusTR: 5,
			})
			columnTemplate.adapters.add("fill", (fill, target) => {
				return chart.get("colors").getIndex(series.columns.indexOf(target))
			})

			columnTemplate.adapters.add("stroke", (stroke, target) => {
				return chart.get("colors").getIndex(series.columns.indexOf(target))
			})

			yAxis.data.setAll(chartData)
			series.data.setAll(chartData)

			series.appear(1000)
			chart.appear(1000, 100)

			return () => {
				root.dispose()
			}
		},
        [chartData]
    )

    return (
		<div id="chartdiv" style={{ width: "100%", height: chartData?.length * 60 + 'px' }}>
			<div className="hidelogo"></div>
		</div>
	)
}

export default ProgramChart
