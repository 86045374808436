import { createContext, useContext, useState, useEffect } from "react";

export const FrameContext = createContext()

const initFramOption =
{
    url: '',
    width: "1200",
    height: "700"
}
export default function FrameContextProvider(props)
{

    const [ frame, setFrame ] = useState(initFramOption)

    useEffect(() =>
        {

            const close = (event) =>
            {
                /** ESC дарах үед цонхыг хаах */
                if (event.keyCode === 27)
                {
                    handleClose()
                }
            }

            document.addEventListener(
                'keyup',
                close
            )

            return () =>
            {
                document.removeEventListener('keyup', close)
            }
        },
        []
    )

    /** Frame үзэх гэж хуудас нээх */
    const handleOpen = (frameOption) =>
    {
        /** frame ийн тохиргоог оноох нь */
        setFrame({ ...initFramOption, ...frameOption })
        /** scroll ийг ажиллуулахгүй болгох */
        // disableScroll()
    }

    /** Frame үзэж нээсэн хуудсыг хаах нь */
    const handleClose = () =>
    {
        /** frame ийн тохиргоог буцаах нь */
        setFrame(initFramOption)
        /** scroll ийг ажилладаг болгох */
        // enableScroll()
    }

    return (
        <FrameContext.Provider value={{ handleClose, handleOpen, frame }}>
            {props.children}
        </FrameContext.Provider>
    )
}

export const useFrame = () =>
{
    return useContext(FrameContext)
}
