import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import DeleteButton from 'components/main/CudButtons/Delete';
import UpdateButton from 'components/main/CudButtons/Update';
import CreateButton from 'components/main/CudButtons/Create';
import GetOneDetail from 'components/special/GetOneDetail';
import CTable from 'components/main/Table';

import useLoader from 'hooks/useLoader';
import useModal from 'hooks/useModal';
import useApi from 'hooks/useApi';

import { adminPageRoles } from 'utils/consts/roles';
import { timeZoneToDateString } from 'utils/format';

/** OrgType crud үйлдэл */
const OrgType = () =>
{
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: true })

    /** Хүснэгтийн мөрүүд */
    const [ rows, setRows ] = useState([]);
    const { setDisplay } = useModal()
    const navigate = useNavigate();
    const orgTypeApi = useApi().orgType

    const displayDetail = (orgTypeId) =>
    {
        setDisplay(
            {
                header:
                {
                    title: "Байгууллагын төрлийн дэлгэрэнгүй",
                },
                body: <GetOneDetail api={orgTypeApi.getOne(orgTypeId)} ignoreList={['created_at', 'updated_at']}/>,
                size: "lg",
                footer:
                {
                    show:false
                }
            }
        )
    }

    /** Хүснэгтийн багана */
    const columns =
    [
        {
            field: "name",
            headerName: "Нэр",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span className='tablerow-detail' onClick={() => displayDetail(row.id)}>{fieldValue}</span>
        },
        {
            field: "created_at",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue)}</span>
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтний утгыг авах */
    const getData = async () =>
    {
        const resp = await fetchData(orgTypeApi.get())
        if(resp.success)
        {
            setRows(resp.data)
        }
    }

    useEffect(
        () =>
        {
            getData()
        },
        []
    )

    /** Хүснэгтийн мөрийн утгыг устгах */
    const deleteRow = async (id) =>
    {
        await fetchData(orgTypeApi.delete(id))
        await getData()
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.id}/`)} needRoles={adminPageRoles} />
                        <DeleteButton onClick={() => deleteRow(item.id)} needRoles={adminPageRoles}/>
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
        <Row>
            <Col sm={12}>
                <CreateButton btnClassName='floatRight' onClick={() => navigate('create/')} needRoles={adminPageRoles}/>
            </Col>
            <Col>
                <CTable
                    isLoading={isLoading}
                    columns={columns}
                    sequenceColumn={sequenceColumn}
                    extraColumns={extraColumns}
                    rows={rows}
                />
            </Col>
        </Row>
    );
}

export default OrgType;
