import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';
import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { roomValidate } from 'utils/validate';

const WindowTypeAction = () => {

    const [ inputValues, setInputValues ] = useState('');

    const navigate = useNavigate();
    const { id } = useParams()

    const { fetchData, isLoading } = useLoader({ isSmall: true })
    const selectRoomApi = useApi().selectRoom

    /** create бол true, update бол false буцаана */
    const isCreate = id === 'create'

    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data } = await fetchData(selectRoomApi.getOne(id))
                if (success)
                {
                    setInputValues(
                        {
                            "room_name": data.name,
                            "room_code": data.code,
                            "icon": data.icon
                        }
                    )
                }
            }
        },
        []
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        const body = {
            "name": formData.room_name,
            "code": formData.room_code,
            "icon": formData.icon
        }

        if (isCreate)
        {
            const { errors, success, data } = await fetchData(selectRoomApi.post(body)).catch(error => error)
            if (success) return navigate(-1)
            else return errors
        }
        const { errors, success, data } = await fetchData(selectRoomApi.put(id, body)).catch(error => error)
        if (success) navigate(-1)
        else return errors
    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "room_name",
                type: "text",
                inputGroup: {
                    start: <i className="fa-brands fa-windows"></i>,
                },
                disabled: isLoading,
            },
            {
                inputType: "input",
                registerName: "room_code",
                type: "text",
                inputGroup: {
                    start: <i className="fa-brands fa-windows"></i>,
                },
                disabled: isLoading,
            },
            {
                inputType: "input",
                registerName: "icon",
                type: "text",
                inputGroup: {
                    start: <i className="fa-brands fa-windows"></i>,
                },
                disabled: isLoading,
            }
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "all",
            yupValidate: roomValidate,
        },
    }
    return (
        <BoxBackground title={isCreate ? 'Өрөөний сонголт үүсгэх' : 'Өрөөний сонголт засах'} body={<CForm inputValues={inputValues} form={form}/>}/>
    );
}

export default WindowTypeAction;
