import React, { useEffect, useState } from "react"
import * as am5 from "@amcharts/amcharts5"
import * as am5percent from "@amcharts/amcharts5/percent"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

import DataNotFound from "components/main/DataNotFound"

import { quantityFormat } from "utils/format"

import css from '../../style.module.css'

const SignDonutChart = ({ logged }) => {

    const [ emptyData, setEmptyData ] = useState(false)
	useEffect(() => {
        if(!logged) return
        if(logged.line.login === 0 && logged.line.logout === 0) return setEmptyData(true)
		setEmptyData(false)
        const login = { value: logged.line.login.success_count, category: 'Нэвтэрсэн', color: '#03bafc' }
        const logout = { value: logged.line.logout.success_count, category: 'Гарсан', color: '#fcba03' }
        const logerror = { value: logged.line.logout.error_count + logged.line.login.error_count, category: "Алдаа", color: '#fc2403' }

        let pieData = [login, logout, logerror]

		let root = am5.Root.new("signChartDivDate")

		root.setThemes([am5themes_Animated.new(root)])

		let chart = root.container.children.push(
			am5percent.PieChart.new(root, {
				layout: root.verticalLayout,
				innerRadius: am5.percent(80),
			}),
		)

		let series = chart.series.push(
			am5percent.PieSeries.new(root, {
				valueField: "value",
				categoryField: "category",
				tooltip: am5.Tooltip.new(root, {
					labelText: "{category}: {value}",
				}),
                legendValueText: "{value}",
			}),
		)

        series.get("colors").set("colors", ["#3A9BCF", "#F07A3B", "#D93E35"])

        series.labels.template.set("forceHidden", true);
        series.ticks.template.set("forceHidden", true);

		series.data.setAll(pieData)

		let legend = chart.children.push(
			am5.Legend.new(root, {
				centerX: am5.percent(50),
				x: am5.percent(50),
				marginTop: 15,
				marginBottom: 55,
            }),
		)
        let label = chart.seriesContainer.children.push(
			am5.Label.new(root, {
				textAlign: "center",
				centerY: am5.percent(50),
				centerX: am5.p50,
				text: `[fontSize:18px]Нэвтэрсэн[/]:\n[bold fontSize:30px]${quantityFormat(logged.line.login.success_count)}[/]`,
			}),
		)

		legend.data.setAll(series.dataItems)

		series.appear(1000, 100)

		return () => {
			root.dispose()
		}
	}, [logged])

	return (
		<div
			className={css.customBottom}
			id="signChartDivDate"
			style={{ width: "100%", height: "500px", position: "relative", border: "1px solid rgba(0,0,0,0.1)", borderRadius: "10px" }}>
			<div className="hidelogo"></div>
            {emptyData && <div><DataNotFound /></div>}
		</div>
	)
}

export default SignDonutChart
