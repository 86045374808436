import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';

import { styleFunction, selectStyle, styleOneApartment } from 'hooks/map/helper'
import useMapContext from 'hooks/useMapContext';
import useLoader from 'hooks/useLoader';
import useModal from 'hooks/useModal';
import useToast from 'hooks/useToast';
import useMap from 'hooks/map/useMap'
import useApi from 'hooks/useApi';

import MapControls from 'components/special/MapControls'

import ModalOverLay from './ModalOverlay';

import GeoJSON from 'ol/format/GeoJSON';

import label from 'utils/validate/label';
import { DATA_PROJECTION, FEATURE_PROJECTION } from 'hooks/map/CMap';

export default function ApartmentMap()
{

    const { id: projectId, apartId } = useParams()
    const [ layers, setLayers ] = useState([])
    const { map, cMap } = useMap({})
    const { setShouldClosePopup, shouldClosePopup, searchResets } = useMapContext()
    const { addToast } = useToast()
    const projectApi = useApi().project
    const apartmentApi = useApi().apartment
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
    const { showWarning } = useModal()
    const [ projectState, setProject ] = useState([])

    useEffect(
        () =>
        {
            if(!map) return
            handleChange()
        },
        [map]
    )

    const overlay = useMemo(
        () =>
        {
            return (
				<ModalOverLay
					map={map}
					cMap={cMap}
					setShouldClosePopup={setShouldClosePopup}
					shouldClosePopup={shouldClosePopup}
					margin={0}
				/>
			)
        },
        [map, shouldClosePopup]
    )

    const saveApartment = async (centerOfFeature, feature) =>
    {
        cMap.addLoader()
        const { success, data: apartName, error } = await apartmentApi.getApartName(apartId)
        if (!success)
        {
            cMap.removeLoader()
            return
        }
        // одоо сонгогдсон feature дээр apartm_id оноож өгөх
        const format = new GeoJSON()
        let storage = []

        layers.map(
            (element, index) =>
            {
                let feauters = element.getSource().getFeatures()
                feauters.find(
                    (element) =>
                    {
                        if(element.get('Text') === 'Барилга' && element.get('apartment_id') === apartId)
                        {
                            element.unset('apartment_id')
                            element.unset('apartment_name')
                            return
                        }
                        return
                    }
                )
                storage = [ ...storage, ...feauters ]
            }
        )
        feature.setProperties({ 'apartment_id': apartId })
        feature.setProperties({ 'apartment_name': apartName })

        const stringFeatures = format.writeFeatures(storage, { dataProjection: DATA_PROJECTION, featureProjection: FEATURE_PROJECTION})
        const transformedCoordinate = cMap.transfrorm3857to4326(centerOfFeature)
        const body = {
            point: transformedCoordinate,
            general_plan: stringFeatures,
            polygon: format.writeFeature(feature, { dataProjection: DATA_PROJECTION, featureProjection: FEATURE_PROJECTION})
        }
        await fetchData(apartmentApi.put(projectId, apartId, body)).catch(err => err)
        cMap.removeLoader()
    }

    /** Сонгох гэж байгаад болих үед оноож өгсөн id г устгах */
    const hided = (feature) =>
    {
        feature.unset('apartment_id')
        feature.unset('apartment_name')
    }

    /** geojson ий дата дээр дарагдах үед */
    const handleFeature = (event) =>
    {
        const feature = event?.selected?.[0]

        if (!feature || feature.get("apartment_id"))
            return

        const centerOfFeature = cMap.getCenterFromExtent(feature.getGeometry().getExtent())

        showWarning({
            type: "warning",
            question: label['apartment'] + 'ийг сонгохдоо итгэлтэй байна уу',
            onClick: () => saveApartment(centerOfFeature, feature),
            handleHide: () => hided(feature)
        })
    }

    /** төслөөр хайгаад газрын зураг дээр geoJson харуулах нь */
    const handleChange = async () =>
    {
        cMap.addLoader()
        const { success, data: project, error } = await projectApi.map(projectId).catch(err => err)
        if (success)
        {
            projectValueToMap(project)
            projectState.push(project)
        }
        cMap.removeLoader()
    }

    /** тухайн сонгогдсон project ийн geojson ийг газрын зураг дээр харуулах нь */
    const projectValueToMap = (project) =>
    {
        const geojson = project.geojson
        if (!geojson)
        {
            addToast(
                {
                    text: "Ерөнхий төлөвлөгөө байхгүй байна",
                    time: 3000,
                    type: "warning"
                }
            )
            return
        }

        const features = cMap.parseGeoJsonToFeature(geojson)

        /** бүртгэл явуулах feature үүдийн жагсаалт */
        let onlyBuilding = []
        /** зүгээр харуулж буй featue үүдийн жагсаалт */
        let notBuilding = []

        // General plan ий feature үүдийг дарагддаг дарагддаггүй гэж ялгах нь
        features.map(
            (feature) =>
            {
                if (feature.get("Text") === "Барилга")
                {
                    if(feature.get("apartment_id") === apartId.toString())
                    {
                        styleOneApartment(feature)
                    }
                    onlyBuilding.push(feature)
                }
                else {
                    notBuilding.push(feature)
                }
            }
        )

        if (notBuilding.length > 0)
        {
			const { vectorLayer: sVectorLayer, vectorSource: sVectorSource } = cMap.addVectorLayer(notBuilding, null, "talbai", styleFunction)
			sVectorLayer.setZIndex(9)
			layers.push(sVectorLayer)
		}
        const { vectorLayer, vectorSource } = cMap.addVectorLayer(onlyBuilding, null, "plan", styleFunction)

        let jumpExtent = vectorSource.getExtent()
        jumpExtent[0] !== Infinity
        &&
            cMap.fitToExtent(jumpExtent, { padding: [25, 25, 25, 25], duration: 1000, maxZoom: 20 })

        vectorLayer.setZIndex(10)
        cMap.createSelect(
            'click',
            (evt) => handleFeature(evt),
            selectStyle,
            [vectorLayer],
        )
        layers.push(vectorLayer)
    }

    return (
        <div
            id="map"
            className='position-relative'
            style={{ height: "calc(95vh - 60px - 20px - 18px - 65px - 40px - 14px - 20px)" }}
        >
            <MapControls cMap={cMap} map={map} showButtons={['fs']}/>
            {overlay}
        </div>
    )
}
