import "rc-tree/assets/index.css"
import Tree, { TreeNode } from 'rc-tree';
import { useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";

import BoxBackground from "components/special/BoxBackground";

import css from './style.module.css'

import TypeForm from './TypeForm'
import NameForm from './NameForm'

import useApi from "hooks/useApi";
import useLoader from 'hooks/useLoader'

const prefix = "/company/material/"
export default function Material()
{
    const materialApi = useApi().material
    const treeRef = useRef()
    const [ treeData, setData ] = useState([])
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
    const navigate = useNavigate()

    useEffect(
        () =>
        {
            getData()
        },
        []
    )

    const getData = async () =>
    {
        const { success, data, error, errors } = await fetchData(materialApi.getList()).catch(err => err)
        if (success)
        {
            setData(data)
        }
    }

    const onSelect = (value) =>
    {
        if (value.length)
        {
            const [ type, id, action ] = value[0].split("-")
            navigate(`${prefix}${type}/${id}${action ? `-${action}/` : "/"}`)
        }
    }

    const treeNodesRender = (list) =>
    {
        return list
            .filter(el => !el.key.endsWith("new"))
            .map(
                (item, idx) =>
                {
                    if (item?.children?.length)
                    {
                        return (
                            <TreeNode title={item.title} key={item.key}>
                                {treeNodesRender(item.children)}
                            </TreeNode>
                        )
                    }
                    return (
                        <TreeNode
                            {
                                ...item.key.endsWith("new")
                                ? { "icon": <i className="fa fa-plus-circle text-primary"></i> }
                                : {}
                            }
                            title={item.title}
                            key={item.key}
                        ></TreeNode>
                    )
                }
            )
    }

    const treeComp = useMemo(
        () =>
        {
            if (isLoading)
            {
                return Loader
            }
            return <Tree
                ref={treeRef}
                showLine
                className={css.treeContainer}
                onSelect={onSelect}
            >
                {treeNodesRender(treeData)}
            </Tree>
        },
        [treeRef, treeData, isLoading]
    )

    return (
        <Row>
            <Col md={6}>
                <BoxBackground
                    title="Материал"
                >
                    {treeComp}
                </BoxBackground>
            </Col>
            <Col md={6}>
                <Routes>
                    <Route path="/type/" element={<Navigate to={"-new/"} />} />
                    <Route path="/type/:id/" element={<TypeForm getData={getData} />} />
                    <Route path="/name/" element={<Navigate to={`${prefix}type/`} />} />
                    <Route path="/name/:id/*" element={<NameForm getData={getData} />} />
                </Routes>
            </Col>
        </Row>
    )
}
