import React, { useRef } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import useApi from 'hooks/useApi'

import CreateButton from 'components/main/CudButtons/Create'
import UpdateButton from 'components/main/CudButtons/Update'
import AddMore from 'components/main/CudButtons/AddMore'
import DeleteButton from 'components/main/CudButtons/Delete'
import Datatable from 'components/main/Datatable'

import { adminForNews, adminPageRoles } from 'utils/consts/roles'
import { timeZoneToDateString } from 'utils/format'
import { DESC } from 'components/main/Table/Thead'

export default function NewsPageList()
{
    const newsPageApi = useApi().news.page
    const navigate = useNavigate()
    const needRoles = [ ...adminPageRoles, ...adminForNews ]
    const dRef = useRef(null)

    /** Хүснэгтийн багана */
    const columns = [
        {
            field: "page_name",
            order: false,
            fieldComponent: (fieldValue, index, row) => fieldValue
        },
        {
            field: "code",
            order: false,
            fieldComponent: (fieldValue, index, row) => fieldValue
        },
        {
            field: "created_at",
            order: true,
            fieldComponent: (fieldValue, index, row) => timeZoneToDateString(fieldValue)
        }
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтний утгыг авах */
    const getData = async () =>
    {
        if (!dRef.current) return
        dRef.current.firstGet = true
        dRef.current.reData({}, null, [])
    }

    /** Тухайн мөрийг устгах нь */
    const deleteRow = async (pageId) =>
    {
        const { success, data, error } = await newsPageApi.delete(pageId).catch(err => err)
        if (success)
        {
            getData()
        }
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.id}/`)} needRoles={needRoles} />
                        <DeleteButton className='me-2' onClick={() => deleteRow(item.id)} needRoles={needRoles} />
                        <AddMore onClick={() => navigate(`${item.id}/news/`)} needRoles={needRoles} tooltipText='Мэдээ оруулах' />
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
        <Row>
            <Col lg={12}>
                <CreateButton btnClassName='floatRight' onClick={() => navigate('create/')} needRoles={needRoles} />
            </Col>
            <Datatable
                dRef={dRef}
                columns={columns}
                sequenceColumn={sequenceColumn}
                extraColumns={extraColumns}
                request={
                    {
                        axios: newsPageApi.getPagination,
                        params: [],
                    }
                }
                tableOptions={
                    {
                        defaultSort: {
                            type: DESC,
                            field: "created_at"
                        }
                    }
                }
            />
        </Row>
    )
}
