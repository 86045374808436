import Profile from "./Profile";
import WonSongonList from "./WonSongonList";
import UserOptions from "./UserOptions";
import Requests from "./Requests";
import NotificationList from "./NotificationList";

import {
    userPageRoles,
} from 'utils/consts/roles'

const userRoutes = [
    {
        name: "Миний хаяг",
        path: "/profile/*",
        icon: <i className="fal fa-user"></i>,
        layout: "/user",
        firstActive: true,
        component: Profile,
        needRoles: userPageRoles,
        isNavigate: false,
    },
    {
        name: "Хүсэлтүүд",
        path: "/requests/*",
        icon: <i className="fal fa-file-alt"></i>,
        needRoles: userPageRoles,
        isNavigate: false,
        subMenu: [
            // {
            //     name: "Миний илгээсээн хүсэлт",
            //     component: Requests,
            //     path: "/list",
            //     needRoles: userPageRoles,
            //     isNavigate: false,
            //     firstActive: true,
            // },
            {
                name: "Шалгарсан хүсэлт",
                component: WonSongonList,
                path: "/wonlist",
                needRoles: userPageRoles,
                isNavigate: false,
            },
        ],
        layout: "/user",
    },
    {
        name: "Мэдэгдлүүдийн жагсаалт",
        needRoles: userPageRoles,
        isNavigate: false,
        path: "/notification/",
        icon: <i className="fal fa-bell"></i>,
        layout: "/user",
        component: NotificationList,
    },
    {
        name: "Тохиргоо",
        needRoles: userPageRoles,
        isNavigate: false,
        path: "/options/",
        icon: <i className="fal fa-cogs"></i>,
        layout: "/user",
        component: UserOptions,
    },
];

export default userRoutes;
