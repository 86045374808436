import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CForm from 'components/main/Form';
import BoxBackground from 'components/special/BoxBackground';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';
import { peopleTypeValidate } from 'utils/validate';

const PeopleTypeAction = () => {
    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const navigate = useNavigate();
    const { id: userId } = useParams()

    const superuserApi = useApi().user.superuser

    /** true бол create, false бол update буцаана */
    const isCreate = userId === 'create'

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        const body = {
            email: formData.email,
            register: formData.register,
            is_government_organization: formData.type === 1 ? true : false,
            is_financial_regulatory_commission_super: formData.type === 2 ? true : false,
        }

        const { success, data, errors } = await fetchData(superuserApi.post(body)).catch((error) => error)
        if (success) navigate(-1)
        else return errors
    }

    const options = [
        {value: 1, label: 'Төрийн байгуулга'},
        {value: 2, label: 'Санхүүгийн зохицуулах хороо'}
    ]

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "email",
                type: "text",
                disabled: isLoading,
            },
            {
                inputType: "input",
                registerName: "register",
                type: "text",
                disabled: isLoading,
            },
            {
                inputType: 'select',
                registerName: 'type',
                disabled: isLoading,
                options: options,
                colMd: 12
            }
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "all",
            yupValidate: peopleTypeValidate,
        },
    }
    return (
        <BoxBackground
            title={isCreate ? 'Tөрийн байгууллага болон СЗХ ийн хүн бүртгэх' : 'Tөрийн байгууллага болон СЗХ ийн хүн бүртгэх'}
            body={
                <>
                    <CForm
                        form={form}
                    />
                </>
            }
        />
    );
}

export default PeopleTypeAction;
