import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import css from './style.module.css'

const BackButton = ({ onClick=null, className='', links, text="Буцах" }) => {

    let navigate = useNavigate()

	const [ histories, setHistories ] = useState([])
	const [ ignore, setIgnore ] = useState([])
	const location = useLocation()

	useEffect(
		() =>
		{
			if(!links) return
			links.map(
				(element) =>
				{
					if(element.firstActive)
					{
						setIgnore(element.layout + '/')
					}
				}
			)
		},
		[links]
	)

	const goBack = () =>
	{
		if(histories.includes(location.pathname))
		{
			let currentRouteIdx = histories.indexOf(location.pathname)
			if(currentRouteIdx === 0)
			{
				navigate('/')
				return
			}
			let pathLength = location.pathname.split('/').length
			let filteredHistories = histories.filter((e, index) => index < currentRouteIdx && e.split('/').length < pathLength)
			navigate(filteredHistories[filteredHistories.length - 1] || '/')
		}
	}

	useEffect(
		() =>
		{
			setTimeout(
				() =>
				{
					if(ignore === location.pathname) return
					let uniq = () => [...new Set([ ...histories, location.pathname ])];
					setHistories(uniq().sort((a, b) => a.length - b.length))
				},
				50
			)
		},
		[location, ignore]
	)

    return (
		<div className={`${css.backbutton} ${className}`} onClick={() => onClick ? onClick() : goBack()}>
			<i className={`far fa-angle-double-left`}></i>{text}
		</div>
	)
}

export default BackButton;
