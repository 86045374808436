import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation, FreeMode } from "swiper";

import './style.css'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import { useMemo } from "react";

/**
 * @param items Slider доторх зүйлсийг өгнө {JSX}
 * @param spaceBetween slider нь доторх зүйлсийн хоорондын зай {number} default: 10
 * @param autoplay slider-ийг автоматаар хөдөлдөг болгох эсэх {true | false}
 * @param autoplayDelay autoplay=true үед автоматаар хөдөллөх хугацаа {number} default: 3000
 * @param navigation slider-ийн 2 талд сум гаргах {true | false}
 * @param pagination slider-ийн доор хуудасны цэг харуулах {true | false}
 * @param slidesPerView slider-д харуулах тоо
 * @param breakpoints responsive болгох бол хэмжээ өнгө
 */
export default function Slider({
    items=[],
    spaceBetween=10,
    freeMode=false,
    loop=false,
    autoplay=false,
    autoplayDelay=3000,
    navigation=true,
    pagination=true,
    slidesPerView=1,
    breakpoints,
    centeredSlides=false,
    className
}) {

    /** Slider ийг дэлгэцнд харуулах  */
    const displaySwiper = useMemo(() =>
    {
        const _items = items.map((item, key) =>
        {
            return <SwiperSlide key={key}>{item}</SwiperSlide>
        })
        return _items
    }, [items])

    return (
        <div>
            <Swiper
                centeredSlides={centeredSlides}
                loop={loop}
                freeMode={freeMode}
                slidesPerView={slidesPerView}
                spaceBetween={spaceBetween}
                autoplay={ autoplay && {
                        delay: autoplayDelay,
                }}
                pagination={ pagination && {
                    clickable: true,
                }}
                navigation={navigation}
                breakpoints={breakpoints}
                modules={[Pagination, Autoplay, Navigation, FreeMode]}
                className={`mySwiper ${className}`}
            >
                {displaySwiper}
            </Swiper>
        </div>
    );
}
