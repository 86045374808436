import React, { useEffect } from 'react'

import { ScaleLine } from 'ol/control';

/** Газрын зураг дээр mashtab ийг гаргах нь
 * @param {HTMLElement} map газрын зургийн element
 */
export default function Scale({ map, cMap })
{

    /** маштабыг газрын зураг дээр харуулах */
    const renderScaleLine = () =>
    {

        /** газрын зураг дээр scale байгааг шалгах */
        if (cMap)
        {
            const controls = cMap.getControls()
            const foundScale = controls.findIndex(
                (control, idx) =>
                {
                    return control.constructor.name === "ScaleLine"
                }
            )

            /** байвал дахиж нэмэхгүй */
            if (foundScale !== -1)
                return
        }

        const scaleLine = new ScaleLine(
            {
                target: "map-scale-line"
            }
        )
        map.addControl(scaleLine)
    }

    useEffect(
        () =>
        {
            if (map)
            {
                renderScaleLine()
            }
        },
        [map]
    )

    return (
        <div className="mashtab" id={"map-scale-line"}>
        </div>
    )
}
