import React, { createContext, useEffect, useState } from "react"

export const StateContext = createContext()

// TODO: хэрэг гарсан үедээ хэрэглэх
export default function StateContextProvider(props) {
	const initState = { skip: 0, limit: 8 }
	const [params, setParams] = useState(initState)
    const [ selectUnit1, setUnit1 ] = useState('')
    const [ selectUnit2, setUnit2 ] = useState('')
    const [ selectUnit3, setUnit3 ] = useState('')
	const [ first, setFirst ] = useState(true)
	const [ gotUserDetail, setGotUserDetail ] = useState(false)

	// nvvr huudasnii zvnn taliin sideAnalytac haruulahad ashigladag
	const [sideShow, setSideShow] = useState(false)

	// gazriin zurgaas hudaldah eswel tvreesleh songohod heregledeg
	const [type, setType] = useState(0)

	// nvvr huudasnii songogdson feature g hadgalj ashiglna
	const [selectedFeature, setSelectedFeature] = useState()

	// Сонгон шалгаруулалт форм руу орж чадах эсэх хэрэв true үед л форм луу орно!
	const [ canAccess, setCanAccess ] = useState({ salaryCheck: false, pdf: false})
	useEffect(
		() =>
		{
			if(first) return
			setUnit2('')
			setUnit3('')
		},
		[selectUnit1, first]
	)

	const checkedListInit = {isAll: false, unChecked: [], checked: [], total: 0}
	// DataTable checkbox state
    const [ checkedList, setCheckedList ] = useState(checkedListInit)

	return (
		<StateContext.Provider
			value={{
				initState,
				params,
				sideShow,
				type,
				selectedFeature,
				selectUnit1,
				setUnit1,
				selectUnit2,
				setUnit2,
				selectUnit3,
				gotUserDetail,
				canAccess,
				checkedList,
				checkedListInit,
				setCheckedList,
				setCanAccess,
				setFirst,
				setGotUserDetail,
				setUnit3,
				setSelectedFeature,
				setType,
				setSideShow,
				setParams,
			}}>
			{props.children}
		</StateContext.Provider>
	)
}
