import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Col, Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import useLoader from 'hooks/useLoader';
import useMobile from 'hooks/useMobile';
import useApi from 'hooks/useApi';

import Item from './SpecialNewsItem';

import css from '../style.module.css'
import '../style.css'

const SpecialNews = ({ page }) => {

	const [ key, setKey ] = useState('special')
	const [ news, setNews ] = useState([])
	const [ viewNews, setViewNews ] = useState([])
	const { newsId } = useParams()

	const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
	const { isMobile } = useMobile()
	const newsApi = useApi().news

	const getSpecialNews = async () =>
	{
		const { success, data } = await fetchData(newsApi.front.get(page, 0, 5, true))
		if(success)
		{
			setNews(data)
		}
	}
	const getMostViewedNews = async () =>
	{
		const { success, data } = await fetchData(newsApi.front.get(page, 0, 5, null, 'desc', 'count'))
		if(success)
		{
			setViewNews(data)
		}
	}

	useEffect(
		async () =>
		{
			await getSpecialNews()
			await getMostViewedNews()
		},
		[]
	)

	useEffect(
		() =>
		{
			if(!isMobile) return
			setKey('')
		},
		[isMobile]
	)

	const displaySpecialNews = useMemo(
		() =>
		{
			return news.map(
				(element, index) =>
				{
					if(!element.is_special) return
					return (
						<Fragment key={index}>
							<Item pageId={element.page} id={element.id} image={element.image} desc={element.title} date={element.created_at} />
							{
								news.length - 1 === index ? null : <hr />
							}
						</Fragment>
					)
				}
			)
		},
		[news]
	)

	const displayViewedNews = useMemo(
		() =>
		{
			return viewNews.map(
				(element, index) =>
				{
					return (
						<Fragment key={index}>
							<Item pageId={element.page} id={element.id} image={element.image} desc={element.title} date={element.created_at} />
							{
								viewNews.length - 1 === index ? null : <hr />
							}
						</Fragment>
					)
				}
			)
		},
		[viewNews]
	)

    return (
		newsId && isMobile
		?
			null
		:
			<div className={css.specialNews}>
				<Tabs className='newsTabs mb-3' activeKey={key} onSelect={k => setKey(k)} fill variant="tabs">
					<Tab className='newsTab' eventKey="special" title={<span><i className='far fa-star tx-blue'/><span> Онцлох мэдээ</span></span>}>
						{isLoading ? Loader : displaySpecialNews}
					</Tab>
					<Tab className='newsTab' eventKey="views" title={<span><i className='far fa-users tx-blue'/><span> Их уншсан</span></span>}>
						{isLoading ? Loader : displayViewedNews}
					</Tab>
				</Tabs>
			</div>
	)
}

export default SpecialNews;
