import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import CButton from 'components/main/Button';

import { getReadableFileSizeString } from 'utils/format';

import css from '../../style.module.css'

const FileUpload = ({ id, register, errors, btnTitle, setValue, inputValues, value, placeholder, fileType, onChange, disabled=false }) => {

    const showInput = () => document.getElementById(id).click()

    const [ file ,setFile ] = useState('')

    return (
        <Row>
            <Col>
                <Form.Control
                    isInvalid={!!errors?.[register.name]?.message}
                    placeholder={placeholder}
                    type="text"
                    className="mt-2 cursorPointer"
                    readOnly={true}
                    value={file?.name ? file?.name : value?.split('/')[value?.split('/')?.length - 1] || ''}
                    onClick={() => !disabled && showInput()}
                    disabled={disabled}
                />
                <input
                    {...register}
                    type="file"
                    id={id}
                    style={{ display: "none" }}
                    accept={`.${fileType}`}
                    onChange={(e) => {
                        register.onChange(e);
                        setFile(e.target.files[0]);
                        onChange && onChange(e, { setValue })
                    }}
                />
                {
                    !disabled
                    &&
                        <div className="d-flex justify-content-between">
                            {file ? <div className="mt-2">Файлын хэмжээ: {getReadableFileSizeString(file?.size)}</div> : null}
                            <CButton className="mt-3 ms-auto" onClick={() => showInput()}>
                                {btnTitle ? btnTitle : "Файл оруулах"}
                            </CButton>
                        </div>
                }
            </Col>
        </Row>
    );
}

export default FileUpload;
