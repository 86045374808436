import React, { useMemo } from 'react';

import ImageFetch from 'components/main/Image';
import Slider from 'components/main/Slider';

import useMobile from 'hooks/useMobile';

import css from '../style.module.css'

const DelgerengviSlider = ({ data }) => {

    const { isMobile } = useMobile()

    const displayItems = useMemo(
        () =>
        {
            const _partners = data?.map(
                (item, idx) =>
                {
                    return (
                        <ImageFetch
                            className="w-100"
                            style={{ height: isMobile ? '250px' : '360px' }}
                            src={item.image}
                            title={item.title}
                        />
                    )
                }
            )
            return _partners
        },
        [data, isMobile]
    )

    return (
        <div className={css.sliderContainer}>
            <Slider
                freeMode={false}
                autoplay={true}
                autoplayDelay={2000}
                spaceBetween={30}
                pagination={true}
                navigation={false}
                items={displayItems}
                slidesPerView={1}
                loop={true}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    1280: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                }}
            />
        </div>
    );
}

export default DelgerengviSlider;
