import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { newsValidate } from 'utils/validate';

const NewsAction = () =>
{
    const [ inputValues, setInputValues ] = useState('');

    const navigate = useNavigate();
    const { pageId, newsId } = useParams()

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const newsApi = useApi().news

    /** create бол true, update бол false буцаана */
    const isCreate = newsId === 'create'

    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data, errors } = await fetchData(newsApi.getOne(pageId, newsId)).catch( err => err )
                if (success)
                {
                    setInputValues(data)
                }
            }
        },
        []
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        const fData = new FormData()
        fData.append('page', pageId)
        fData.append('title', formData.title)
        fData.append('commentary', formData.commentary)
        fData.append('content', formData.content)
        fData.append('is_special', formData.is_special)

        if (isCreate)
        {
            fData.append('image', formData.image[0])
            const { errors, success, data } = await fetchData(newsApi.post(pageId, fData)).catch(error => error)
            if (success) return navigate(-1)
            else return errors
        }

        if(inputValues.image !== formData.image) fData.append('image', formData.image[0])

        const { errors, success, data } = await fetchData(newsApi.put(pageId, newsId, fData)).catch(error => error)
        if (success) navigate(-1)
        else return errors
    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "title",
                label: {
                    text: "Мэдээний гарчиг"
                },
                placeholder: "Мэдээний гарчиг оруулна уу",
                type: "text",
                disabled: isLoading,
            },
            {
                inputType: "radio",
                type: "checkbox",
                registerName: "is_special",
                disabled: false,
                readOnly: false,
                options: [
                    { label: "Онцлох мэдээ эсэх" },
                ],
                label: {
                    text: "Онцлох мэдээ эсэх",
                    empty: false,
                },
            },
            {
                inputType: "imageUpload",
                registerName: "image",
                label: {
                    text: "Зураг"
                },
                placeholder: "Зураг оруулна уу",
                colMd: 12
            },
            {
                inputType: "input",
                registerName: "commentary",
                type: "textArea",
                disabled: isLoading,
                colMd: 12
            },
            {
                inputType: "editor",
                registerName: "content",
                disabled: isLoading,
                colMd: 12
            },
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "all",
            yupValidate: newsValidate,
        },
    }

    return (
        <CForm inputValues={inputValues} form={form}/>
    );
}

export default NewsAction;
