import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Information from '../Information';

import useAuth from 'hooks/useAuth';

import { makeFullName } from 'utils';

import css from '../../style.module.css'

const UserDetails = () => {

    const { userDetail } = useAuth()

    const {
        first_name: firstName,
        register,
        last_name: lastName,
        email
    } = userDetail

    return (
        <Row>
            <Col sm={4}>
                <div className={`${css.userprofile}`}>
                    <img
                        style={{ width: "100%", }}
                        src={process.env.PUBLIC_URL + userDetail.gender === 2 ? '/images/emegtei.png' : userDetail.gender === 1 ? '/images/eregtei.png' : '/images/busad.png'}
                        alt={"headerLogo"}
                    />
                </div>
            </Col>
            <Col sm={8} className="d-flex flex-column justify-content-center">
                <p className="mt-2 mb-4 fontsize-17" >{makeFullName(lastName, firstName)}</p>
                <Information icon={"far fa-list-alt"} label={"Регистрийн дугаар"} value={register}/>
                <Information icon={"fa-solid fa-inbox"} label={"Имэйл"} value={email} />
                {/* <Information icon={"far fa-user"} label={"Хэрэглэгч төрөл"} value={<span style={{backgroundColor: '#0dad13', borderRadius: '0.25rem', padding: '2px 10px', color: '#fff', fontSize: '12px'}}>Энгийн хэрэглэгч</span>} /> */}
            </Col>
        </Row>
    );
}

export default UserDetails;
