import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

export default function MainNavLink({ children, to, className, exact, onClick, activeClassName})
{
    const location = useLocation()

    const getMatch = () => {
        /** url нь тухайн navlink ийн замтай таарч байгаа эсэх */
        let isMatch = false;
        if (exact)
        {
            isMatch = location.pathname === to
        }
        else
        {
            isMatch = location.pathname.includes(to)
        }
        return isMatch
    }

    return (
        <NavLink
            onClick={onClick}
            to={to}
            className={({ isActive }) => `nav-link ${className} ${getMatch() ? (activeClassName ? activeClassName : "active") : ""}`}>
            {children}
        </NavLink>
    );
}
