import React from 'react';
import { useNavigate } from 'react-router-dom';

import ImageFetch from 'components/main/Image';

import { quantityFormat, timeZoneToDateString } from 'utils/format';

import css from '../style.module.css'

const NewsCardList = ({ desc, views, title, date, id, image, pageId }) => {

	const navigate = useNavigate()

	const navigater = () =>
	{
		navigate(`${pageId}/${id}/`)
	}

	return (
		<div className={css.listView}>
			<div className={css.imageDesc}>
				<div style={{ width: '200px' }}>
					<div className={css.imageContainerList}>
						<ImageFetch onClick={navigater} className={css.imageList} src={image} />
					</div>
				</div>
				<div className={css.textContainer}>
					<div onClick={navigater} className={css.newsTitle}>
						{title}
					</div>
					<div className={css.desc}>{desc}</div>
				</div>
				<div className={css.numberContainer}>
					<div className={css.newsSmallText}>
						<i className={`far fa-clock tx-blue ${css.txBlue}`} /> {timeZoneToDateString(date)}
					</div>
					<div className={css.newsSmallText}>
						<i className={`far fa-eye tx-blue ${css.txBlue}`} /> {quantityFormat(views)}
					</div>
				</div>
				<div className={css.containerItemz}>
					<div onClick={navigater} className={css.readMore}>
						Дэлгэрэнгүй <i className="far fa-angle-double-right"></i>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NewsCardList;
