import React from 'react';

import BackButton from 'components/main/BackButton';
import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { buildingImageValidate } from 'utils/validate';

const BuildingImageCreate = ({ projectId, buildingId, setIsCreate }) => {

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const buildingImageApi = useApi().building.buildingImages

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        const fData = new FormData()
        fData.append('building', buildingId)
        fData.append('desciption', formData.desciption)
        fData.append('real_photo', formData.real_photo[0])

        const config =
        {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const { errors, success, data } = await fetchData(buildingImageApi.post(projectId, buildingId, fData, config)).catch(error => error)
        if (success) return setIsCreate(false)
        else return errors
    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "desciption",
                type: "textArea",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
                colMd: 12
            },
            {
                inputType: "imageUpload",
                registerName: "real_photo",
                colMd: 12
            },
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: 'Үүсгэх',
        },
        validate: {
            validateMode: "all",
            yupValidate: buildingImageValidate,
        },
    }
    return (
        <>
            <BackButton className='mb-2' onClick={ () => setIsCreate(false) }/>
            <CForm form={form}/>
        </>
    );
}

export default BuildingImageCreate;
