import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import DisplayNestedObject from 'components/special/DisplayOneDetails/DisplayNestedObject';
import DisplaySingleValue from 'components/special/DisplayOneDetails/DisplaySingleValue';
import DisplayFullArray from 'components/special/DisplayOneDetails/DisplayFullArray';
import ImageFetch from 'components/main/Image';
import CButton from 'components/main/Button';
import CTable from 'components/main/Table';

import { quantityFormat, timeZoneToDateString } from 'utils/format';
import label from 'utils/validate/label';

import css from '../style.module.css'

const ProgramBody = ({ data }) => {

    const navigate = useNavigate()
    const { hutulburId, bulegId } = useParams()

    const columns =
    [
        {
            field: "name",
            headerName: "Нэр",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "start_date",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue)}</span>
        },
        {
            field: "end_date",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue)}</span>
        },
        {
            field: "is_end",
            headerName: 'Төлөв',
            order: false,
            fieldComponent: (fieldValue, index, row) => <span className={!fieldValue ? 'text-success' : 'text-danger'} >{!fieldValue ? 'Идэвхтэй' : 'Дууссан'}</span>
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <CButton onClick={ () => navigate(`/choosepath/songon/${item.id}/`) } className='me-2'>Шалгаруулалт</CButton>
                        {
                            !item.is_joined
                            &&
                                <CButton onClick={ () => navigate(`/choosepath/hutulburlist/${bulegId}/hutulbur/${hutulburId}/songon/${item.id}/`) } >Бүртгүүлэх</CButton>
                        }
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
		<div className={css.container}>
			<div className="all-center">
                <ImageFetch
                    className={css.eskizImg}
                    src={process.env.REACT_APP_SERVER_URL + data?.project?.eskiz}
                />
			</div>
			<div className={css.item}>
                <DisplaySingleValue details={data} item='name' />
                <div>
                    <span style={{ fontWeight: '500' }}>{label['budget_amount']}: </span>{quantityFormat(parseInt(data.budget_amount))}
                </div>
                <DisplayNestedObject details={data} item='budget_type' />
                <DisplayNestedObject details={data} item='main_subject' />
                <DisplaySingleValue details={data} item='end_date' />
                <DisplaySingleValue details={data} item='start_date' />
                <DisplaySingleValue details={data} item='result' />
                <DisplayFullArray details={data} item='zorilgot_buleg' />
                <div className='mt-3'>
                {
                    data.songon
                    ?
                        <div>
                            Нийт сонгон шалгаруулалт:
                            <CTable
                                columns={columns}
                                sequenceColumn={sequenceColumn}
                                extraColumns={extraColumns}
                                rows={data.songon}
                            />
                        </div>
                    :
                        null
                }
                </div>
			</div>
		</div>
	)
}

export default ProgramBody;
