import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { residenceValidate } from 'utils/validate';
import { ZEREGLEL_TYPE } from 'utils/consts';
import { dataToValueLabel } from 'utils/format'

const ResidenceAction = ({ residenceId, mapProjectId, removeOverlay }) =>
{

    const [ inputValues, setInputValues ] = useState('');

    const [ allApartment, setAllapartment ] = useState([])

    const navigate = useNavigate();
    let { resId, id: projectId } = useParams()
    resId = residenceId ? residenceId : resId
    projectId = mapProjectId ? mapProjectId : projectId


    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const apartmentApi = useApi().apartment
    const residenceApi = useApi().residence

    /** create бол true, update бол false буцаана */
    const isCreate = resId === 'create'

    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data, errors } = await fetchData(residenceApi.getOne(projectId, resId)).catch( err => err )
                if (success)
                {
                    setInputValues(data)
                }
            }


            const apartmentRsp = await fetchData(apartmentApi.getAll(projectId))
            if(apartmentRsp.success)
            {
                setAllapartment(dataToValueLabel(apartmentRsp.data, 'id', 'name'))
            }
        },
        []
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        const fData = new FormData()
        fData.append('project_id', projectId)
        fData.append('total_mkv', formData.total_mkv)
        fData.append('total_room', formData.total_room)
        fData.append('apartment', `[${formData.apartment}]`)

        const config =
        {
            headers: { 'content-type': 'multipart/form-data' }
        }
        if (isCreate)
        {

            fData.append('plan_img', formData.plan_img[0])
            const { errors, success, data } = await fetchData(residenceApi.post(projectId, fData, config)).catch(error => error)
            if (success) {
				removeOverlay && removeOverlay()
				return navigate(-1)
			} else return errors
        }

        if(inputValues.plan_img !== formData.plan_img) fData.append('plan_img', formData.plan_img[0])

        const { errors, success, data } = await fetchData(residenceApi.put(projectId, resId, fData, config)).catch(error => error)
        if (success) {
			removeOverlay && removeOverlay()
			return navigate(-1)
		} else return errors
    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "select",
                registerName: "apartment",
                isMulti: true,
                disabled: isLoading,
                options: allApartment,
            },
            {
                inputType: "imageUpload",
                registerName: "plan_img",
                colMd: 12
            },
            {
                inputType: "input",
                registerName: "total_mkv",
                type: "number",
                step: 0.01,
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
            },
            {
                inputType: "input",
                registerName: "total_room",
                type: "number",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
            },
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "all",
            yupValidate: residenceValidate,
        },
    }

    return (
        <CForm inputValues={inputValues} form={form}/>
    );
}

export default ResidenceAction;
