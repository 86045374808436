export default {
    "username": "Нэвтрэх нэр",
    "email": "И-мэйл хаяг",
    "mail": "И-мэйл хаяг",
    "price": "Үнэ",
    "kindType": "Захиалгын төрөл",
    "kind": "Захиалгын нөхцөл",
    "family": "Нийт өрх",
    "square": "Нийт талбай",
    "perSquare": "Нэгж талбай",
    "description": "Тайлбар",
    "name": "Нэр",
    "location": "Байршил",
    "password": "Нууц үг",
    "newPassword": "Шинэ нууц үг",
    "confirmPassword": "Шинэ нууц үг давтах",
    "firstName": "Өөрийн нэр",
    "lastName": "Эцэг/эхийн нэр",
    "phoneNumber": "Утасны дугаар",
    "code": "Товчлол",
    "currencyName": "Эрхийн бичгийн нэр",
    "mnt": "Төгрөг",
    "quantity": "Тоо ширхэг",
    "withDraw": "Зарлагын",
    "singleQuantity": "Ширхэг",
    "term": "Үйлчилгээний нөхцөл",
    "reason": "Учир шалтгаан",
    "TestEmail": "test email",
    "TestPassword": "test password",
    "TestNumber": "test number",
    "testDatePickerRange": " test 2 хугацаа",
    "testDatePicker": "test datepicker",
    "TestSelect": "test select",
    "TestSelectMulti": "TestSelectMulti",
    "TestRadio": "TestRadio",
    "budget_amount": "Хөрөнгийн хэмжээ",
    "budget_type_id": "Xөрөнгө оруулалтын төрөл",
    "budget_type": "Xөрөнгө оруулалтын төрөл",
    "result": "Үр дүн(%)",
    "main_subject": "Үндсэн төрийн байгууллага",
    "contrib_subject": "Төрийн байгууллага",
    "end_date": "Дуусах хугацаа",
    "start_date": "Эхэлсэн хугацаа",
    "agenda": "Зорилт",
    "amount": "Хөрөнгө",
    "units": "нэгж",
    "subagenda": "Шалгуур үзүүлэлт",
    "basestatus": "Суурь түвшин",
    "currentstatus": "Одоогийн түвшин",
    "futurestatus": "Хүрэх түвшин",
    "topic": "Гарчиг",
    "index": "Индекс",
    "licensed_by": "Тусгай зөвшөөрлийг олгосон",
    "license_year": "Тусгай зөвшөөрлийн хүчэнтэй жил",
    "company": "Компани",
    "company_name": "Компани нэр",
    "created_company": "Бүртгэсэн компани",
    "manager": "Mенежер",
    "contact": "Xолбоо барих",
    "website": "Вэб сайт",
    "facebook": "Facebook",
    "instagram": "Instagram",
    "twitter": "Twitter",
    "condition": "Өмчлүүлэх нөхцөл",
    "work_place": "Ажлын газар",
    "work_position": "Албан тушаал",
    "org_type": "Байгууллагын төрөл",
    "until_work_year": "Ажилласан нийт жил",
    "org_work_year": "Ажилласан жил",
    "income_exp_info": "Орлого зарлагын мэдээлэл",
    "door_type": "Хаалганы төрөл",
    "window_type": "Цонхны төрөл",
    "parking_type": "Зогсоолын төрөл",
    "parking_info": "Зогсоолын төрөл",
    "apartment": "Oрон сууц",
    "is_SSO": "Данд бүртгэлтэй эсэх",
    "first_name": "Нэр",
    "last_name": "Овог",
    "is_active": "Нэвтрэх эрх байгаа эсэх",
    "joined_date": "Системд бүртгүүлсэн огноо",
    "gender": "Хүйс",
    "is_super_user": "Системийн админ мөн эсэх",
    "is_zb_of_rent": "Түрээсийн орон сууц хөтөлбөрийн зохион байгуулагч",
    "is_news_admin": "Мэдээний админ",
    "is_read_graphic": "Graphic хардаг",
    "is_only_read": "Бүх мэдээлэл харах",
    "is_citizen": "Иргэн мөн эсэх",
    "is_will_rent": "Түрээсийн орон сууцад хамрагдах иргэн",
    "is_from_building": "Барилга угсралтын компани",
    "is_from_project": "Зураг төслийн компани",
    "is_org_admin": "Байгууллагын админ",
    "zorilgot_buleg": "Зорилтот бүлэг",
    "zoriltot_buleg": "Зорилтот бүлэг",
    "imageUpload": "Зураг",
    "fileUpload": "Файл",
    "project_name": "Төслийн нэр",
    "res_area": "res_area",
    "et_boundary": "Ерөнхий төлөвлөгөөний хил",
    "approval": "Зөвшөөрөл",
    "approval_date": "Батлагдсан огноо",
    "price_mkv": "Төслийн м² ийн үнэ",
    "barilgiin_torol": "Барилгын төрөл",
    "et_file": 'Ерөнхий төлөвлөгөөний хил',
    "unit4": "Гудамж хороолол, хашаа, хаалганы дугаар",
    "use_date": "Ашиглалтанд орох огноо",
    "eskiz": "eskiz зураг",
    "eskiz_info": "eskiz зураг",
    "unit1": "Аймаг/хот",
    "unit2": "Сум/Дүүрэг",
    "unit3": "Баг/хороо",
    "unit1_name": "Аймаг/хот",
    "unit2_name": "Сум/Дүүрэг",
    "unit3_name": "Баг/хороо",
    "unit1_id": "Аймаг/хот",
    "unit2_id": "Сум/Дүүрэг",
    "unit3_id": "Баг/хороо",
    "unit1-code": "Аймаг/хот",
    "unit2-code": "Сум/Дүүрэг",
    "unit3-code": "Баг/хороо",
    "sales_manager_id": "Маркетингийн албаны менежер",
    "sales_manager": "Маркетингийн албаны менежер",
    "total_ail": "Нийт айлын тоо",
    "general_play": "Ерөнхий төлөвлөгөө",
    "hutulbur_id": "Хөтөлбөр",
    "hutulbur": "Хөтөлбөр",
    "general_plan": "Ерөнхий төлөвлөгөө",
    "property_type": "Өмчлөх төрөл",
    "property_info": "Зогсоолын төрөл",
    "total_service_area": "Нийт үйлчилгээний талбай",
    "zereglel": "Зэрэглэл",
    "plan_img": "Өрөөний plan зураг",
    "total_mkv": "Нийт м²",
    "total_room": "Нийт өрөөний тоо",
    "from_school": "Сургуулийн зай",
    "from_super_market": "Хүнсний дэлгүүрийн зай",
    "from_store": "Худалдааны төвийн зай",
    "from_kinder_garden": "Цэцэрлэгийн зай",
    "from_bus_station": "Тээврийн үйлчилгээний зай",
    "service_floor": "Үйлчилгээний давхар",
    "service_area": "Үйлчилгээний талбай",
    "butets_type": "Барилгын материалын төрөл",
    "real_photo": "Гаднаас дарсан жинхэнэ зураг",
    "has_lift": "Лифттэй эсэх",
    "total_floor": "Нийт давхар",
    "price_update_date": "price_update_date",
    "total_under_floor": "Нийт пудвол",
    "advantage": "Давуу тал",
    "created_at": "Үүссэн огноо",
    "employees": "Ажилчид",
    "door_number": "Хаалганы дугаар",
    "custom_door_number": "Хаалганы дугаар",
    "floor": "Давхар",
    "input_number": "Орцны дугаар",
    "is_rent": "Түрээсийх эсэх",
    "residence": "Байрны зохион байгуулалт",
    "state": "Захиалгын төлөв",
    "companyId":"Компани",
    'zoriltotUser': "Зорилтот бүлэг",
    "hutulbur_name": "Хөтөлбөрийн нэр",
    "is_end": "Дууссан эсэх",
    "room_name": "Өрөөний нэр",
    "room_code": "Өрөөний код",
    "icon": "Icon",
    "current_use": "Барилгын үндсэн төрөл",
    "director": "Захирлын нэр",
    "is_tosk": "ТОСК",
    "is_financial": "Санхүүгийн зохицуулах хороо",
    "hariutsah_baiguullaga": "Хариуцах байгууллага",
    "floor-begin": "Давхар эхлэх",
    "floor-end": "Давхар дуусах",
    "mkv-begin": "м² эхлэх",
    "mkv-end": "м² дуусах",
    "is_from_system": "Системээс утга авах",
    "created_by": "Бүртгэсэн ажилтан",
    'construction': 'Хийц, бүтээцийн нэр',

    // Nested Table labels
    "zuuch_baig_ner": 'Зуучлалын байгууллагын нэр',
    "two_month_price": '2 сарын төлбөр',
    "time": 'Хугацаа',
    "irgen_huuliin_etgeedin_ner": 'Иргэн, хуулийн этгээдийн нэр',
    "register": "Регистрийн дугаар",
    "ul_hudluh_hurungiin_turul_display": 'Үл хөдлөх хөрөнгийн төрөл',
    "ul_hudluh_hurungiin_turul": "Үл хөдлөх хөрөнгийн төрөл",
    "uil_ajillagaanii_turul": 'Үйл ажиллагааны төрөл',
    "county": 'Улс',
    "mkv": 'Талбайн хэмжээ(м²)',
    "cost": 'Үнэ/төгрөг/',
    "date": 'Огноо',
    "number": 'Дугаар',
    "geree_gerchilgee_dugaar": 'Гэрчилгээний дугаар',
    "shimtgel_dun": 'Шимтгэлийн дүн /төгрөг/',
    "huwi": 'Хувь/%/',
    "customer": 'Харилцагч',
    "real_estate_location": 'Үл хөдлөх хөрөнгийн байршил',
    "contract": 'Гэрээ',
    "per_month_price": '1 сарын түрээсийн төлбөр /төгрөг/',
    "gereenii_hugatsaa": 'Гэрээний хугацаа /сар/',
    "total_price": 'Нийт үнэ /төгрөг/',
    "real_estate_cost": 'Үл хөдлөх хөрөнгийн худалдан авсан үнэ /төгрөг/',
    "upgrade_cost": 'Засварын зардал /төгрөг/',
    "sold_price": 'Үл хөдлөх хөрөнгийн худалдсан үнэ /төгрөг/',
    "down_or_up": 'Олз/гарз /төгрөг/',
    "real_estate_traded_contract": 'Үл хөдлөх хөрөнгийн худалдан борлуулсан гэрээ',
    "irget_huuliin_etgeedin_haryalal": 'Иргэн хуулийн этгээдийн хаьяалал',
    "uilchilgee_description": 'Үйлчилгээний товч утга',
    "total_price_togrog": 'Нийт дүн /төгрөг/',
    "menegenment_service": 'Менежментийн үйлчилгээ',
    "uilchilgee_total_price": 'Үйлчилгээний нийт дүн /төгрөг/',
    "trans_total": 'Гүйлгээний дүн /төгрөг/',
    "trans_type": 'Гүйлгээний төрөл* /БМГ, ГТТГ, СГ/',
    "trans_date": 'Гүйлгээ хийгдсэн огноо',
    "sma_date": 'CMA-д мэдэгдсэн огноо',
    "customrs_info": 'Харилцагчдын мэдээлэл',
    "hariltsagchdiin_too": 'Харилцагчийн тоо',
    "NUBAZ_horig_tulgsan_hariltsagchdiin_too": 'НҮБАЗ-ийн хориг арга хэмжээний жагсаалттай тулгаж шалгасан харилцагчийн тоо',
    "plan_image": "План зураг",
    "desciption": "Тайлбар",
    "building": "Байр",
    "room": 'Өрөө',
    "roomSize": "Өрөөний хэмжээ",
    "room_type": "Өрөөний төрөл",
    "room_type_id": "Өрөөний төрөл",
    "room_mkv": "Өрөөний м²",
    "room_count": "Өрөөний тоо",
    "aimar_hot_info": "Аймаг/хот",
    "sum_duureg_info": "Сум/Дүүрэг",
    "ognoo": 'Огноо',
    "dugaar": 'Гэрээний дугаар',
    "niit_dun": "Нийт үнэ /төгрөг/",
    "irgen_name": 'Иргэн, хуулийн этгээдийн нэр',
    'guilgee_dun': "Гүйлгээний дүн /төгрөг/",
    'guilgee_turul_display': 'Гүйлгээний төрөл* /БМГ, ГТТГ, СГ/',
    "guilgee_turul": 'Гүйлгээний төрөл* /БМГ, ГТТГ, СГ/',
    "guilgee_ognoo": "Гүйлгээ хийгдсэн огноо",
    "SMA_medegdsen_ognoo": "CMA-д мэдэгдсэн огноо",
    "project": "Төсөл",
    "hudaldaj_awsan_une": "Үл хөдлөх хөрөнгийн худалдан авсан үнэ /төгрөг/",
    "zaswar_zardal": 'Засварын зардал /төгрөг/',
    "zarsan_une": 'Үл хөдлөх хөрөнгийн худалдсан үнэ /төгрөг/',
    "olz_garz": 'Олз/гарз /төгрөг/',

    "from_school": "Сургуулиас",
    "from_super_market": "Супермаркетаас",
    "from_store": "Дэлгүүрээс",
    "from_kinder_garden": "Xүүхдийн цэцэрлэгээс",
    "from_bus_station": "Автобусны буудлаас",

    "has_domofon": "Домофон",
    "has_extra_light": "Нэмэлт гэрэлтүүлэг",
    "has_garden": "Цэцэрлэг",
    "has_garrage": "Гараж",
    "has_guardian": "Хамгаалагч",
    "has_negdsen_shugam": "Нэгдсэн шугам",
    "has_toy_square": "Тоглоомын талбай",
    "has_wall": "Хашаа",
    "type": 'Төрөл',

    "page_name": "Хуудасны нэр",
    "commentary": "Мэдээний тайлбар",
    "content": "Контент",
    "reporter": "Мэдээ оруулсан",

    "full_name": "Байршилын бүтэн мэдээлэл",
    "geoid": "Обьект",

    "regnum": "Байгууллагын регистр",
    "prop_type": "Өмчийн хэлбэр",
    "license": "Тусгай зөвшөөрлийн хэлбэр",
    "sub_license": "БҮАТЗ",

    'has-lift': "Лифт",
    "zereglel-type": "Зэрэглэл",
    "total-room": "Өрөө",
    "building-is-rent": "Түрээс",
    "project-name": "Төслийн нэр",
    "geoid": "Обьект",
    "has_guardian": "Харуул",
    "has_garrage": "Граж",
    "has_garden": "Ногоон байгууламж",
    "has_negdsen_shugam": "Инженерийн нэгдсэн шугам сүлжээ",
    "has_wall": "Гаднаа хашаа",
    "has_toy_square": "Тоглоомын талбай",
    "has_extra_light": "Нэмэлт цахилгаан үүсгүүр",
    "has_domofon": "Домофон системтэй эсэх",

    "has_dulaan": "Дулаан нь төвд холбогдсон эсэх",
    "has_tsahil": "Цахилгаан нь төвд холбогдсон эсэх",
    "has_bohir": "Бохир нь төвд холбогдсон эсэх",
    "has_tsewer": "Цэвэр ус нь төвд холбогдсон эсэх",
    "has_holboo": "Холбоо нь төвд холбогдсон эсэх",
    "has_camera": "Гаднаа камертай эсэх",
    "has_suh": "СӨХтэй эсэх",
    "is_shaard_hangasan": "Ашиглалтын шаардлага хангасан",
    "use_type": "Ашиглалтын төрөл",
    "lift_ype": "Лифтний төрөл",
    "dohiolol": "Дохиолол",
    "zereglel": "Зэрэглэл",
    "parking_type": "Зогсоолын төрөл",
    'sales': "Борлуултын менежер",

    "has_dulaan": "Дулаан",
    "has_tsahil": "Цахилгаан",
    "has_bohir": "Бохир",
    "has_tsewer": "Цэвэр ус",
    "has_holboo": 'Холбоо',
    "has_camera": 'Гаднаа камертай',
    "has_suh": "СӨХтэй",

    "butets-type": "Барилгын бүтэц",
    "room-choice": "Өрөөний сонголт",

    // oc1 тайлан
    "passport_number": "Паспортын дугаар",
    "housing_type": "Орон сууцны төрөл",
    "omch_helber": "Өмчийн хэлбэр",
    "bairshil": "Байршил",
    "niit_suutsnii_too": "Нийт сууцны тоо",
    "oroonii_toogoor": "Өрөөний тоогоор",
    "oroonii_too_1": "Өрөөний тоогоор 1",
    "oroonii_too_2": "Өрөөний тоогоор 2",
    "oroonii_too_3": "Өрөөний тоогоор 3",
    "oroonii_too_4": "Өрөөний тоогоор 4",
    "oroonii_too_5": "Өрөөний тоогоор 5",
    "oroonii_too_6": "Өрөөний тоогоор 6",
    "oroonii_too_7_plus": "Өрөөний тоогоор 7 түүнээс олон утга",
    "aguulah_oroonii_too": "Агуулах өрөөний тоо",
    "barilga_niit_talbai": "Барилгын нийт талбайн хэмжээ",
    "zoriulaltaar" :"Зориулалтаар",
    "suutsnii": "Сууцны",
    "aguulahiin": "Агуулахын",
    "uilchilgeenii": "Үйлчилгээний",
    "avto_zogsool": "Дулаан авто зогсоолын",
    "ded_butziin_uzuuleltuud": "Дэд бүтцийн үзүүлэлтүүд",
    "tsever_usnii_hangamj": "Цэвэр усны хангамж",
    "haluun_usnii_hangamj": "Халуун усны хангамж",
    "ariutgah_tatuurga": "Ариутгах татуурга",
    "halaalt": "Халаалт",
    "davhriin_too": "Давхрын тоо",
    "ortsnii_too": "Орцны тоо",
    "tsahilgaan_shatnii_too": "Цахилгаан шатны тоо",
    "niit_avto_zogsool": "Нийт авто зогсоол",
    "torloor": 'Төрлөөр',
    "dulaan_avto_zogsool": "Дулаан авто зогсоол",
    "gadna_avto_zogsool": "Гадна авто зогсоол",
    "barilgiin_undsen_hiits": "Барилгын үндсэн хийц",
    "naluu_zamtai_eseh": "Хөгжлийн бэрхшээлтэй иргэнд зориулсан тоноглол",
    "gadna_tohijiltiin_talbai": "Гадна тохижилтын ажил хийгдсэн талбайн хэмжээ",
    "huuhdiin_togloomiin_talbai": "Хүүхдийн тоглоомын талбайн хэмжээ",
    "suutsnii_mkv_talbain_une": "Сууцны 1 кв.м талбайн үнэ",
    "niit_ortog": "Нийт өртөг",
    "tosviin_horongo_oruulalt": "Төсвийн хөрөнгө оруулалт",
    "aanb_horongo_oruulalt": "Монгол Улсын ААНБ-ийн хөрөнгө оруулалт",
    "gadaadiin_horongo_oruulalt": "Гадаадын хөрөнгө оруулалт",
    "unet_tsaas_hudaldsan_orlogo": "Үнэт цаас худалдсанаас олсон орлого",
    "banknii_zeel": "Банкны зээл",
    "busad": "Бусад",
    "eh_uusvereer": "Эх үүсвэрээр",
    "dan_ganz_barilga_eseh": "Хотхон байдлаар зохион байгуулсан барилга эсвэл дан ганц барилга эсэх",
    "urtrag": "Уртраг",
    "orgorog": "Өргөрөг",
    "bairshliin_tseg": "Байршлын цэг",

    // oc2 тайлан
    "ahiglaltad_oruulsan_on": "Ашиглалтанд оруулсан он",
    "niit_suuts": "Нийт сууц",
    "shiidver_garsan_on": "Ашиглалтын шаардлага хангахгүй гэж шийдвэр гарсан он",
    "buulgasan_shaltgaan": "Буулгасан шалтгаан",

    "mattype": "Төрлийн нэр",
    "matname": "Нэр",
    "unit": "Хэмжүүр",
    "tonoglol": "Хөгжлийн бэрхшээлтэй иргэнд зориулсан тоноглол",
    "is_bhtuv": "Барилгын хөгжлийн төв",
    "state_name": "Төлөв",
    "omchiin_helber": "Өмчийн хэлбэр",
    "shiidver_garsan_on": "Шийдвэр гаргасан огноо",
    "buulgasan_shaltgaan": "Буулгасан шалтгаан",
    "employee_count": "Нийт ажилчид",

    "aorson_ognoo": "Ашиглалтанд орсон огноо",

    "tuluwluguu_heregjilt": "Төлөвлөгөөний хэрэгжилт",

    // Төсөл
    "unit5": "Хаяг дугаар",
    "architecture_tolovlolt_datetime": "Архитектур төлөвлөлтийн даалгаварын огноо",
    "architecture_tolovlolt_number": "Архитектур төлөвлөлтийн даалгаварын дугаар",
    "zurag_tosol_datetime": "Зураг төслийн магадлалын ерөнхий дүгнэлтийн огноо",
    "zurag_tosol_number": "Зураг төслийн магадлалын ерөнхий дүгнэлтийн дугаар",
    "tolovshil_angilal": "Төвөгшлийн ангилал",
    "heseg_blok": "Хэсэг /блок/",
    "huchin_chadal": "Хүчин чадал",
    "huchin_chadal_hemjih_negj": "Хүчин чадал хэмжих нэгж",
    "tehnik_tsahilgan_hangamj": "Цахилгаан хангамж",
    "tehnik_dulan_hangamj": "Дулаан хангамж",
    "tehnik_us_hangamj": "Ус хангамж, ариутгах татуурга",
    "tehnik_holbo_dohiolol": "Холбоо, дохиолол",
    "barilgiin_zoriulalt": "Барилгын зориулалт",

    "barilga_ajlin_gerchilge_ogno": "Барилгын ажлын зөвшөөрлийн гэрчилгээ олгосон огноо",
    "barilga_ajlin_gerchilge_dugar": "Барилгын ажлын зөвшөөрлийн гэрчилгээ олгосон дугаар",
    "barilga_ajlin_gerchilge_hugatsa": "Барилгын ажлын зөвшөөрлийн гэрчилгээ олгосон хүчинтэй хугацаа",
    "barilga_ajlin_sungasan_ogno": "Барилгын ажлын зөвшөөрлийн хугацаа сунгасан огноо",
    "barilga_ajlin_sungasan_tailbar": "Барилгын ажлын зөвшөөрлийн хугацаа сунгасан тайлбар",
    "nemelt_orchlolt_ogno": "Нэмэлт өөрчлөлт огноо",
    "nemelt_orchlolt_tailbar": "Нэмэлт өөрчлөлт тайлбар",

    "barilga_zahialagch": "Барилгын ажлын захиалагч, хөрөнгө оруулагчийн мэдээлэл",
    "barilga_zohiogch": "Барилгын зураг төсөл зохиогчийн мэдээлэл",
    "barilga_ajil_guitsetgegch": "Барилгын ажил гүйцэтгэгчийн мэдээлэл",
    "barilga_tuslan_guitsetgegch": "Барилгын ажил туслан гүйцэтгэгчийн мэдээлэл",
    "company_register_number": "Компаний регистрийн дугаар",

    "zuragiin_shipr": "Зургийн шифр",
    "barilga_omchlogch": "Барилга байгууламжийн ашиглагч өмчлөгч байгууллага утга",
    "barilga_omchlogch_select": "Барилга байгууламжийн ашиглагч өмчлөгч байгууллага сонгох",
    "komis_zohion_baigulagch": "Комиссын үйл ажиллагаа зохион байгуулагч байгууллага",

    "zovshorol_olgoson_baigullaga": "Барилгын ажил эхлүүлэх, үргэлжлүүлэх зөвшөөрөл олгосон байгууллагын нэр",
    "ashiglaltad_ognoo": 'Ашиглалтад орсон огноо',
    "barilga_ajlin_torol": "Барилгын ажлын төрөл",

    "komis_ognoo": "Комиссын үйл ажиллагааны онгоо",
    "komis_dugaar": "Комиссын үйл ажиллагааны дугаар",
    "updated_at": "Сүүлд зассан огноо",

    "ul_hudluh_hurungiin_turul_v2": 'Үл хөдлөх хөрөнгийн төрөл (Зөвхөн орон сууцны зориулалттай ҮХХ)',
    "ul_hudluh_hurungiin_turul_v3": 'Үл хөдлөх хөрөнгийн төрөл (орон сууцны зориулалттай ҮХХ-өөс бусад)',
    'hudaldagch_itgemjleleer_toloolj_bui': 'Итгэмжлэлээр төлөөлж буй эсэх',
    'hudaldagch_ner': 'Иргэн, хуулийн этгээдийн нэр',
    'hudaldagch_register': 'Регистрийн дугаар',
    'ul_hodloh_horongiin_zah_gere_dugar': 'ҮХХ-ийн захиалгын гэрээний дугаар',
    'ul_hodloh_horongiin_gerchilge_dugar': 'ҮХХ-ийн гэрчилгээний дугаар',

    'hudaldagch': 'Худалдагч',
    'hudaldan_avagch': 'Худалдан авагч',
    'niit_une_urtug': 'Нийт үнэ (төгрөг)',
    'uhh_iin_medeelel': 'ҮХХ-ийн мэдээлэл',
    'zuuchlaliin_geree': 'Зуучлалын гэрээ',
    'medeelliin_ezen_zovshorson_eseh': 'Мэдээллийн эзэн зөвшөөрсөн эсэх',

    'target_group': 'хэрэглэгчийн role',
    'pdf': 'PDF',
    'video': 'Бичлэг',
};
