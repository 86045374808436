import RadioCheck from 'components/main/Form/InputTypes/RadioCheck'
import { Loader } from 'components/main/Loader'
import useCState from 'hooks/useCState'
import React, { useEffect, useMemo, useRef } from 'react'

import { range } from 'utils/array'
import { EXTRA_SEQUENCE_KEY } from 'utils/consts'

import style from '../style.module.css'

export default function Tbody({ sequenceColumn, rows, columns, extraColumns, extraRows, className, extraFirstRows=[], getWidth, setChoosenOrder, isLoading, hasCheckbox=false, onCheck, checkBoxOptions }) {


    const { checkedList, setCheckedList, checkedListInit } = useCState()
    /**
     * Ямар нэгэн баганад хамаарахгүй мөрийн датаг харуулах нь
     * @param {object || number} field харуулах data
     * @param {number} idx index
     * @returns td
     */
     const displayNotColumnTd = (field, idx) => {
        const isFieldObj = typeof field === 'object'
        const value = isFieldObj
                        ?
                            field?.value
                            ? field.value
                            : null
                        :
                            field

        return (
            <td
                key={idx}
                className={field.className}
                colSpan={field.colSpan}
            >
                {value}
            </td>
        )
    }

    /**
     * Дараалалсан тоонуудыг харуулах нь
     * @param {number} idx index
     */
    const getSequence = (idx) => {
        let col = null
        if (sequenceColumn) {
            const index = sequenceColumn.startNumber + idx
            if (isNaN(parseInt(index))) return
            col = (
                <td className={sequenceColumn.className}
                    style={{
                        width: `${sequenceColumn?.width ? `${sequenceColumn?.width}px` : ""}`,
                        minWidth: sequenceColumn.minWidth ? sequenceColumn.minWidth + 'px' : "",
                        maxWidth: sequenceColumn.maxWidth ? sequenceColumn.maxWidth + 'px' : "",
                    }}
                >
                    {index}
                </td>
            )
        }
        return col
    }

    /**
     * Дараалалсан тоонуудыг харуулах нь
     * @param {number} idx index
     */
     const getCheckbox = (row, idx) => {
        let col = null
        if (hasCheckbox) {
            col = checkedList.isAll ? (
				<td>
					<input
                        checked={!checkedList.unChecked.includes(row[checkBoxOptions.rowsElement])}
						type="checkbox"
                        className='form-check-input'
						onChange={event => onCheck(event, row, idx)}
					/>
				</td>
			) : (
				<td>
					<input
						checked={checkedList.checked.includes(row[checkBoxOptions.rowsElement])}
						type="checkbox"
                        className='form-check-input'
						onChange={event => onCheck(event, row, idx)}
					/>
				</td>
			)
        }
        return col
    }

    /**
     * option байвал тухайн option -г авах байхгүй байвал initValue -г оноох
     */
    function getOption(optionName, hasAccess, col, initValue=null) {
        if (!hasAccess) {
            return initValue
        }
        return col?.[optionName] ? col[optionName] : initValue
    }

    /**
     * нэг td ийн мэдээллийг авах нь
     * @param {Array.<number>} isFieldNotDisplay colSpan -д арилах field ийн idx ийг санах
     * @param {object} col тухайн баганы мэдээлэл
     * @param {number} cidx тухайн нэг field ийн index
     * @param {Object} row тухайн нэг мөрийн мэдээлэл
     * @param {number} rowIndex тухайн нэг мөрийн ийн index
     * @returns td
     */

    const tdData = (isFieldNotDisplay, col, cidx, row, rowIndex) => {

        //  colSpan -д багтсан idx уудыг null болгох нь
        if (isFieldNotDisplay.includes(cidx)) {
            const foundIndex = isFieldNotDisplay.findIndex((el) => el === cidx)
            isFieldNotDisplay.slice(foundIndex, 1)
            return null
        }

        let field = row[col.field]
        const isFieldConfig = typeof field === 'object' && field?.value !== undefined

        //  colSpan орж ирсэн тухайн colSpan дотор багтсан field ийн idx ийг хадгалах нь
        const colSpan = getOption('colSpan', isFieldConfig, field, 1)
        if (colSpan > 1) {
            const total = cidx + colSpan - 1
            const ranges = range(cidx, total)
            for (const idx in ranges)
            {
                const element = ranges[idx]
                isFieldNotDisplay.push(element)
            }
        }

        const value = getOption('value', isFieldConfig, field)

        return (
            <td
                key={cidx}
                className={`${getOption('className', isFieldConfig, field, '')} ${col.fieldClassName ?? ""}`}
                colSpan={colSpan}
                style={{
                    width: col?.width ? col?.width + 'px' : "",
                    minWidth: col?.minWidth ? col?.minWidth + 'px' : "",
                    maxWidth: col?.maxWidth ? col?.maxWidth + 'px' : "",
                    ...col?.style ?? {},
                }}
                onClick={ () => typeof setChoosenOrder === 'function' && setChoosenOrder(row) }
            >
                {
                    !isFieldConfig
                    ?
                        col?.fieldComponent
                        ? col.fieldComponent(field, rowIndex, row)
                        : field ? field : "-"
                    :
                        typeof value === 'function'
                        ? value(field, rowIndex, row)
                        : value ? value : "-"
                }
            </td>
        )
    }

    /**
     * Нэг талбарын датаг харуулах нь
     */
    const getRowData = (row, rowIndex) => {

        let isFieldNotDisplay = []

        if (row?.hasNotColumn === true) {
            return (
                displayNotColumnTd(row, rowIndex)
            )
        }

        const _row = columns.map((col, cidx) => {
            return (
                tdData(isFieldNotDisplay, col, cidx, row, rowIndex)
            )
        })

        if (row.hasOwnProperty(EXTRA_SEQUENCE_KEY)) {
            const idxTd = displayNotColumnTd(row[EXTRA_SEQUENCE_KEY], _row.length)
            _row.unshift(idxTd)
        }

        //  Хоосон field ийг арилгах нь
        const filteredRow = _row.filter(el => el !== null)
        return filteredRow

    }

    /**
     * Үндсэн мөрийн датаны араас нэмэлт баганы датаг харуулах нь
    */
    const displayExtraColumn = (row, rowIndex) => {

        if (!extraColumns || extraColumns.length === 0) {
            return null
        }

        const _row = extraColumns.map((extraCol, idx) => {
            return (
                <td
                    key={idx}
                    className=''
                >
                    {extraCol.field(row, rowIndex)}
                </td>
            )
        })

        return _row
    }

    /** Мөр нь хоосон байвал хоосон байна гэж харуулах */
    const checkEmpty = (rows) =>
    {

        // дата байвал дата харуулах
        if (rows?.length > 0) return rows

        /** sequence гэсэн багана байвал нэгийг нэмнэ */
        let colSpan = !!sequenceColumn ? columns.length + 1 : columns.length
        /** extra column байвал бас нэмэх */
        colSpan = !!extraColumns ? colSpan + extraColumns.length : colSpan

        // дата байхгүй бол хоосон гэж харуулах
        return (
            <tr className='position-relative'>
                <td colSpan={colSpan} className={`${style.emptycenter} text-muted`} style={{ height: 180 }}>
                    Хоосон байна
                </td>
            </tr>
        )
    }

    /** Мөр нь хоосон байвал хоосон байна гэж харуулах */
    const loader = () =>
    {
        /** sequence гэсэн багана байвал нэгийг нэмнэ */
        let colSpan = !!sequenceColumn ? columns.length + 1 : columns.length
        /** extra column байвал бас нэмэх */
        colSpan = !!extraColumns ? colSpan + extraColumns.length : colSpan

        // дата байхгүй бол хоосон гэж харуулах
        return (
            <tr className='position-relative'>
                <td colSpan={colSpan} className={`${style.emptycenter} text-muted`} style={{ height: 180 }}>
                    <Loader />
                </td>
            </tr>
        )
    }

    const ref = useRef();

    /**
     * Үндсэн мөрийн датануудыг харуулах нь
    */
    const displayRows = useMemo(() => {
        const displayRows = rows?.map((row, idx) => {
            return (
                <tr ref={ref} key={idx}>
                    {getCheckbox(row, idx)}
                    {getSequence(idx)}
                    {getRowData(row, idx)}
                    {displayExtraColumn(row, idx)}
                </tr>
            )
        })

        return checkEmpty(displayRows)
    }, [rows, sequenceColumn, columns, extraColumns, checkedList, checkBoxOptions])

    /**
     * Нэмэлтийн мөрийн датаг харуулах нь
     */
    const _displayBottomExtraRows = (extraRows) => {
        if (!extraRows || extraRows.length === 0) {
            return null
        }

        return (
            extraRows.map((extraRow, idx) => {
                return (
                    <tr key={idx}>
                        {getRowData(extraRow, idx)}
                    </tr>
                )
            })
        )
    }

    const displayBottomExtraRows = useMemo(
        () =>
        {
            return _displayBottomExtraRows(extraRows)
        },
        [extraRows, columns]
    )

    const displayFirstRows = useMemo(
        () =>
        {
            return _displayBottomExtraRows(extraFirstRows)
        },
        [extraFirstRows, columns]
    )

    useEffect(
        () =>
        {
            getWidth && getWidth(displayRows)
        },
        [displayRows]
    )

    return (
        <tbody className={`${className}`}>
            {
                isLoading
                ?
                    loader()
                :
                    <>
                        {displayFirstRows}
                        {displayRows}
                        {displayBottomExtraRows}
                    </>
            }
        </tbody>
    )
}
