import React from 'react';
import { Col } from 'react-bootstrap';

import { ROOM_ICONS } from 'utils/consts';

const IconNumber = ({ code, title, count }) => {
    return (
        <Col sm={6}>
            <span className='me-2'>{count}</span><i className={ROOM_ICONS?.[code] || "far fa-square"}></i>
            <p>{title}</p>
        </Col>
    );
}

export default IconNumber;
