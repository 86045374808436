
import BuldingList from "./list"

export default function Building({ mapProjectId, removeOverlay, feature })
{

    if (!feature)
        return null

    const apartId = feature.get("apartment_id")

    return (
        <div>
            <BuldingList projectId={mapProjectId} apartId={apartId} />
        </div>
    )
}
