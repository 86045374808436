import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';
import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { busadValidate } from 'utils/validate';
import { dataToValueLabel } from 'utils/format';

const BusadAction = ({ hasTitle=true, isNew=null, setTogglerState, updateId }) => {

    const [ inputValues, setInputValues ] = useState('');
    const [ realValues, setRealValues ] = useState('')

    const navigate = useNavigate();
    const { id, tableId } = useParams()

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const buildingTableApi = useApi().buildingTable

    /** create бол true, update бол false буцаана */
    const isCreate = id === 'create'

    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data } = await fetchData(buildingTableApi['busad'].getOne(id))
                if (success)
                {
                    setInputValues(data)
                }
            }
        },
        []
    )

    useEffect(
        () =>
        {
            if(inputValues === '') return
            let zadalsanData = {
                uilchilgee_description: inputValues.uilchilgee_description,
                uilchilgee_total_price: inputValues.uilchilgee_total_price,
                irgen_huuliin_etgeedin_ner: inputValues.hariltsagch_info.irgen_huuliin_etgeedin_ner,
                register: inputValues.hariltsagch_info.register,
                irget_huuliin_etgeedin_haryalal: inputValues.hariltsagch_info.irget_huuliin_etgeedin_haryalal
            }
            setRealValues(zadalsanData)
        },
        [inputValues]
    )

    const btnOnSubmit = async (formData) =>
    {
        const body = {
            uilchilgee_description: formData.uilchilgee_description,
            uilchilgee_total_price: formData.uilchilgee_total_price,
            hariltsagch: {
                irgen_huuliin_etgeedin_ner: formData.irgen_huuliin_etgeedin_ner,
                register: formData.register,
                irget_huuliin_etgeedin_haryalal: formData.irget_huuliin_etgeedin_haryalal
            },
        }

        if (isCreate)
        {
            const { errors, success, data } = await fetchData(buildingTableApi['busad'].post(body)).catch(error => error)
            if (success) return navigate(-1)
            else return errors
        }
        const { errors, success, data } = await fetchData(buildingTableApi['busad'].put(body, id)).catch(error => error)
        if (success) navigate(-1)
        else return errors
    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "irgen_huuliin_etgeedin_ner",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                colMd: 4,
            },
            {
                inputType: "input",
                registerName: "register",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                colMd: 4,
            },
            {
                inputType: "input",
                registerName: "irget_huuliin_etgeedin_haryalal",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                colMd: 4,
            },
            {
                component: (
                    <hr />
                )
            },
            {
                inputType: "input",
                registerName: "uilchilgee_description",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
            },
            {
                inputType: "input",
                registerName: "uilchilgee_total_price",
                type: "number",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
            },
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "all",
            yupValidate: busadValidate,
        },
    }
    return (
        hasTitle
        ?
            <BoxBackground title={'Зуучлалын мэдээ'} body={<CForm isLoading={isLoading} inputValues={realValues} form={form}/>}/>
        :
            <CForm isLoading={isLoading} inputValues={realValues} form={form}/>
    );
}

export default BusadAction;
