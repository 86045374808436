import { useEffect, useMemo, useState } from "react"

import useLoader from "hooks/useLoader"
import useApi from "hooks/useApi"

import OneBuildingInfo from "./OneBuildingInfo"

const MapBuildingList = ({ tableId, feature, isNew, goUpdate }) =>
{
    const [ oldBuildings, setOldBuildings ] = useState([])
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
    const buildingTableApi = useApi().buildingTable

    useEffect(
        async () =>
        {
            if(!feature) return
            const { success, data } = await fetchData(buildingTableApi[tableId][isNew ? 'getBuildingsNew' : "getBuildingsOld"](isNew ? feature.get("apartment_id") : feature.getId())).catch( err => err )
            if(success)
            {
                setOldBuildings(data)
            }
        },
        [feature, tableId]
    )

    const displayTable = useMemo(
        () =>
        {
            return oldBuildings.map(
                (element, index) =>
                {
                    return <OneBuildingInfo goUpdate={goUpdate} key={index} data={element} />
                }
            )
        },
        [oldBuildings]
    )

    return (
        isLoading
        ?
            <div style={{ margin: '15vh 0' }}>{Loader}</div>
        :
            displayTable.length !== 0
            ?
                displayTable
            :
                <div style={{ margin: '15vh 0', textAlign: 'center', fontSize: '20px' }}>Бүртгэлтэй байр олдсонгүй...</div>
    )
}

export default MapBuildingList
