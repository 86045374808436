import Datatable from "components/main/Datatable"
import useApi from "hooks/useApi"
import useAuth from "hooks/useAuth"
import useModal from "hooks/useModal"
import { useFavicon, useTitle } from "react-use"

const TestDataTableFilter = () =>
{

    useTitle('TEST DATA TABLE !!!!')
    useFavicon('https://cdn.sstatic.net/Sites/stackoverflow/img/favicon.ico')
    const { companyId } = useAuth()
    const testApi = useApi().employee
    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }
    const { setDisplay } = useModal()
    const handleClick= (data) =>
    {
        setDisplay(
            {
                header: {
                    title: "Дэлгэрэнгүй",
                },
                body: <div>GG</div>,
                size: "xl"
            }
        )
    }
    const columns =
    [
        {
            field: "user",
            headerName: "И-мэйл",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue.email}</span>,
            searchField: 'email'
        },
        {
            field: "user",
            headerName: "Овог",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue.last_name}</span>,
            searchField: 'last_name',
            filter: {
                filterOptions: [
                    {
                        value: 'B1',
                        label: 'B',
                    },
                    {
                        value: 'tester2',
                        label: 'tester',
                    },
                    {
                        value: 'bataa3',
                        label: 'bataa',
                    },
                ],
                fieldName: 'last_name'
            }
        },
        {
            field: "user",
            headerName: "Нэр",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue.first_name}</span>,
            searchField: 'first_name',
            filter: {
                filterOptions: [
                    {
                        value: 'gg1',
                        label: 'gg1',
                    },
                    {
                        value: 'gg2',
                        label: 'gg2',
                    },
                    {
                        value: 'gg3',
                        label: 'gg3',
                    },
                ],
                fieldName: 'first_name'
            }
        },
        {
            field: "user",
            headerName: "Нэвтрэх нэр",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue.username}</span>
        },
    ]

    const checkBoxOptions = {
        show: true,
        onCheckAll: (event, value) => event,
        onCheck: (event, value) => event,
        onClick: () => handleClick('123123'),
        btnText: 'Сонгогдсон утгуудыг устгах',
    }

    return (
        <Datatable
            columns={columns}
            sequenceColumn={sequenceColumn}
            checkBoxOptions={checkBoxOptions}
            request={
                {
                    axios: testApi.get,
                    params: [companyId],
                }
            }
        />
    )
}

export default TestDataTableFilter
