/**
 * start тооноос end тоо хүртэлх тоонуудыг буцаана
 * @param {number} start тоо
 * @param {number} end тоо
 * @returns range тоо
 */
export function range(start, end) {
    var foo = [];
    for (var i = start; i <= end; i++) {
        foo.push(i);
    }
    return foo;
}

/**
 * Array ийн object ийг array аас гаргаж object болгох
 * @param {Array} array object агуулсан array
 */
export const arrayOfObjToObj = (array) =>
{
    if (!Array.isArray(array))
    {
        return array
    }

    /** Array аас гаргасан нэг том object */
    const bigObject = array.reduce(
        (beforeValue, currentValue) =>
        {
            beforeValue = Object.assign(beforeValue, currentValue)
            return beforeValue
        },
        {}
    )

    return bigObject
}

export const extractArray = (array, object) =>
{
    if(array?.length === 0 || !array) return
    let text = ''
    array.map(
        (element, index) =>
        {
            text = text + (object ? element[object] : element) + ', '
        }
    )
    return text.slice(0, -2)
}
