import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import BoxBackground from 'components/special/BoxBackground';
import CButton from 'components/main/Button';

import PetitionerForm from './PetitionerForm';
import PetitionerMain from './main';

const Petitioner = () => {

    const navigate = useNavigate();

    return (
		<Container className='skipHeader'>
			<Routes>
				<Route
					path="/*"
					element={
						<BoxBackground
							title="Материал бүрдүүлэх"
							body={<PetitionerMain/>}
							className='mb-2'
						/>
					}
				/>
				<Route path="/form/*" element={<BoxBackground title="Хүсэлт илгээх" body={<PetitionerForm />} />} />
			</Routes>
		</Container>
	)
}

export default Petitioner;
