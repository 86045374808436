import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';
import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { activityValidate } from 'utils/validate';

const UnitMeasureAction = () => {

    const [ inputValues, setInputValues ] = useState('');

    const navigate = useNavigate();
    const { id } = useParams()

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const unitMeasureApi = useApi().unitMeasure

    /** create бол true, update бол false буцаана */
    const isCreate = id === 'create'

    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data } = await fetchData(unitMeasureApi.getOne(id))
                if (success)
                {
                    setInputValues(data)
                }
            }
        },
        []
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        if (isCreate)
        {
            const { errors, success, data } = await fetchData(unitMeasureApi.post(formData)).catch(error => error)
            if (success) return navigate(-1)
            else return errors
        }
        const { errors, success, data } = await fetchData(unitMeasureApi.put(id, formData)).catch(error => error)
        if (success) navigate(-1)
        else return errors
    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "name",
                type: "text",
                placeholder: "Хэмжих нэгжийн нэр оруулна уу",
                disabled: isLoading,
                label: {
                    text: "Хэмжих нэгжийн нэр: ",
                    empty: false,
                },
            },
            {
                inputType: "input",
                registerName: "code",
                type: "text",
                placeholder: "Хэмжих нэгжийг илэрхийлэх код оруулна уу",
                disabled: isLoading,
                label: {
                    text: "Хэмжих нэгжийг илэрхийлэх код: ",
                    empty: false,
                },
            },
            {
                inputType: "chooseItem",
                registerName: "is_from_system",
                disabled: isLoading,
                options: [
                    { value: true, label: 'Тийм' },
                    { value: false, label: 'Үгүй' }
                ]
            },
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "all",
            yupValidate: activityValidate,
        },
    }
    return (
        <BoxBackground title={isCreate ? 'Хэмжих нэгж үүсгэх' : 'Хэмжих нэгж засах'} body={<CForm inputValues={inputValues} form={form}/>}/>
    );
}

export default UnitMeasureAction;
