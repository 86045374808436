import React from 'react';

const TableSelect = ({ options, value, disabled, valueName, onChange }) => {

	const displayStates = () => {
		return options.map((element, index) => {
			return <option value={element.value} key={index}> {element.label} </option>
		})
	}

    const handleChange = (value) =>
    {
        onChange({ [valueName]: value })
    }

	return (
		<select
			className="form-control"
			style={{ width: "125px", height: "25px", padding: "0 10px", margin: "0 auto" }}
			disabled={disabled}
			value={value}
			onChange={value => handleChange(value.target.value)}
		>
			<option value={'not'}>Сонгоно уу</option>
			{displayStates()}
		</select>
	)
}

export default TableSelect;
