
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Agenda from '.';
import AgendaAction from './AgendaAction'

const AgendaCrudMain = () => {
    return (
        <Routes>
            <Route path="/" element={<Agenda/>} />
            <Route path="/:id/" element={<AgendaAction/>} />
        </Routes>
    );
}

export default AgendaCrudMain;
