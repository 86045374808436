import { useMemo, useState } from 'react'

import useApi from 'hooks/useApi'
import useLoader from 'hooks/useLoader'
import DatepickerInput from 'components/main/Datepicker/DatepickerInput'

import CButton from 'components/main/Button'
import useToast from 'hooks/useToast'
import { timeZoneToDateString } from 'utils/format'

export default function ApartStateChange({ getData, checkCount, projectId, setHide, checkedList })
{
    const apartmentApi = useApi().apartment

    const [ values, setValues ] = useState({
        state: "",
        nuraasanDate: new Date(),
        shaltgaan: "",
        isValid: true,
    })

    const { addToast } = useToast()

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: true, absolute: true })
    const states = [
        {
            "name": "Баригдаж буй",
            "id": 1,
        },
        {
            "name": "Баригдсан",
            "id": 2,
        },
        {
            "name": "Нураасан",
            "id": 3,
        },
    ]

    const setChange = async () =>
    {
        if (!values.isValid)
        {
            addToast(
                {
                    text: "Нэмэлт талбаруудыг бөглөнө үү",
                    type: "warning"
                }
            )
            return
        }

        const body = {
            "ids": checkedList.checked,
            "state": values.state,
        }
        if (values.state + "" === "3")
        {
            body['shiidver_garsan_on'] = timeZoneToDateString(values.nuraasanDate, false)
            body['buulgasan_shaltgaan'] = values.shaltgaan
        }
        const { success, data, errors } = await fetchData(apartmentApi.setChange(body))
        if (success)
        {
            setHide()
            getData()
        }
    }

    const handleChange = (event) =>
    {
        const _stateValue = event.target.value
        setValues((prev) => ({  ...prev, state: _stateValue, isValid: _stateValue + "" !== "3" }))
    }

    const extraFields = useMemo(
        () =>
        {
            // Хэрвээ нураасан барилга байвал нэмэлт  input үүдийг гаргаж ирнэ
            if (values.state + "" === "3")
            {
                return (
                    <>
                        <div className='form-group mt-2'>
                            <label htmlFor="shiidver_garsan_on" className='form-label'>Шийдвэр гарсан огноо:</label>
                            <DatepickerInput
                                selected={values.nuraasanDate}
                                onChange={(date) => setValues((prev) => ({ ...prev, nuraasanDate: date, isValid: !!values.shaltgaan }))}
                                disabled={false}
                                required
                            />
                        </div>
                        <div className='form-group mt-2'>
                            <label htmlFor="buulgasan_shaltgaan" className='form-label'>Буулгасан шалтгаан:</label>
                            <textarea
                                required
                                onChange={(e) => {
                                    setValues((prev) => ({ ...prev, shaltgaan: e.target.value, isValid: !!values.nuraasanDate }))
                                }}
                                name='buulgasan_shaltgaan'
                                placeholder='Шалтгааныг оруулна уу'
                                id="buulgasan_shaltgaan"
                                className='form-control'
                                value={values.shaltgaan}
                            />
                        </div>
                    </>
                )
            }
            return null
        },
        [values.state, values.shaltgaan, values.nuraasanDate]
    )

    return (
        <div className='position-relative'>
            <div className='d-flex flex-row align-items-center'>
                <i className="fas fa-exclamation-circle text-warning me-2"></i>
                Таны сонгосон {checkCount} утгуудыг шинэчлэнэ
            </div>
            {isLoading && Loader}
            <div className='form-group'>
                <label htmlFor="apart-state" className='form-label'>Барилгын төлөв:</label>
                <select value={values.state} onChange={handleChange} id="apart-state" className='form-select'>apart-state
                    <option value="">-- Сонгоно уу --</option>
                    {
                        states.map(
                            ({ name, id }, idx) =>
                            {
                                return <option key={idx} value={id}>{name}</option>
                            }
                        )
                    }
                </select>
            </div>
            {extraFields}
            <div className='mt-3'>
                <CButton onClick={setChange}>
                    Хадгалах
                </CButton>
            </div>
        </div>
    )
}
