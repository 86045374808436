import { useEffect, useMemo, useState } from 'react'

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import CForm from 'components/main/Form';

import { dataToValueLabel } from 'utils/format';
import { unitFormValidate } from 'utils/validate';

/** Засаг захиргааны нэгжээр хайх нь */
export default function UnitsForm({ onChange, onSubmit, btnTitle="Хайх", extraButtons, uRef, setSelectedUnits=null })
{
    const [ inputValues, setInputValues ] = useState('');

    const [ units, setUnits ] = useState([])
    const [ unit2Options, setUnit2Options ] = useState([])
    const [ selectUnit1, setUnit1 ] = useState('')
    const [ selectUnit2, setUnit2 ] = useState('')

    const unitApi = useApi().unit
    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })

    const resetInputs = () =>
    {
        setUnit1("")
        setUnit2("")
        setInputValues(
            {
                unit1: null,
            }
        )
    }

    useEffect(
        () =>
        {
            if (uRef)
            {
                uRef.current = {
                    ...uRef.current,
                    reset: resetInputs
                }
            }
        },
        [uRef]
    )

    useEffect(
        async () =>
        {
            const { success, data, error } = await fetchData(unitApi.get())
            if (success)
            {
                setUnits(data)
            }
        },
        []
    )

    const secondUnits = useMemo(
        () =>
        {
            if(!selectUnit1) return []
            let unit1Id = typeof selectUnit1 === "object" ? selectUnit1.code : selectUnit1
            const unit = units?.find(
                (el) =>
                {
                    return el.code === unit1Id
                }
            )
            setUnit2Options(unit?.unit2)
            return dataToValueLabel(unit?.unit2, 'code', 'name', null, 'id')
        },
        [selectUnit1, units]
    )

    const thirdUnits = useMemo(
        () =>
        {
            if(!selectUnit2) return []
            let unit2Id = typeof selectUnit2 === "object" ? selectUnit2.code : selectUnit2
            const unit = unit2Options?.find(
                (el) =>
                {
                    return el.code === unit2Id
                }
            )
            return dataToValueLabel(unit?.unit3, 'code', 'name', null, 'id')
        },
        [unit2Options, selectUnit2]
    )

    const handleChange = (value, name) =>
    {
        let obj = {
            "unit1": setUnit1,
            "unit2": setUnit2,
        }

        if (obj?.[name])
            obj[name](value.value)

        setSelectedUnits(prev =>
            {
                return {
                    ...prev,
                    [name]: value.extraValue
                }
            })

        onChange && onChange(value, name)
    }

    const form = {
        inputs: [
            {
                inputType: "select",
                registerName: "unit1",
                onChange: value => handleChange(value, 'unit1'),
                disabled: isLoading,
                sort: true,
                options: dataToValueLabel(units, "code", "name", null, 'id'),
                colMd: 12,
            },
            {
                inputType: "select",
                registerName: "unit2",
                onChange: value => handleChange(value, 'unit2'),
                disabled: isLoading,
                nested: 'unit1',
                sort: true,
                options: secondUnits,
                colMd: 12
            },
            {
                inputType: "select",
                registerName: "unit3",
                onChange: value => handleChange(value, 'unit3'),
                nested: 'unit2',
                sort: true,
                disabled: isLoading,
                options: thirdUnits,
                colMd: 12
            },
        ],
        button: {
            onSubmit: data => onSubmit && onSubmit(data),
            show: !!onSubmit,
            title: btnTitle
        },
        validate: {
            validateMode: "all",
        }
    }

    return (
        <CForm
            inputValues={inputValues}
            form={form}
            extraButtons={extraButtons}
        />
    )
}
