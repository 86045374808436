import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

import NewsMore from 'pages/Helper/News/NewsMore';

import TabContent from './TabContent';
import BackButton from 'components/main/BackButton';

import css from './style.module.css'

const Rules = () =>
{
    return (
		<Container>
			<Row className="skipHeader">
				<Col xl={12}>
					<div className={css.pageHeader}>
						<div className={css.vLine}></div>Дүрэм, журам
						<BackButton />
					</div>
					<hr />
				</Col>
				<Routes>
					<Route path='/*' element={<TabContent/>} />
					<Route path='/:pageCode/:pageId/:newsId' element={<NewsMore/>} />
				</Routes>
			</Row>
		</Container>
	)
}

export default Rules;
