import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import UpdateButton from 'components/main/CudButtons/Update';
import Datatable from 'components/main/Datatable';
import AccessIcons from 'components/special/AccessIcons'
import GenderIcons from 'components/special/GenderIcons'

import useModal from 'hooks/useModal';
import useApi from 'hooks/useApi';

import UserDetail from './UserDetail'

import { adminPageRoles } from 'utils/consts/roles';

const Agenda = () => {

    const navigate = useNavigate();
    const userApi = useApi().user
    const { setDisplay } = useModal()

    /** Modal гаргаж ирэх funct */
    const displayDetail = (id) =>
    {
        setDisplay(
            {
                header:
                {
                    title: "Хэрэглэгчийн дэлгэрэнгүй",
                },
                body: <UserDetail api={userApi.getOne(id)} />,
                size: "lg",
                footer:
                {
                    show:false
                }
            }
        )
    }

    /** Хүснэгтийн багана */
    const columns =
    [
        {
            field: "last_name",
            headerName: "Овог",
            order: false,
            fieldComponent: (fieldValue, index, row) =><p>{fieldValue}</p>
        },
        {
            field: "first_name",
            headerName: "Нэр",
            order: false,
            fieldComponent: (fieldValue, index, row) =><p>{fieldValue}</p>
        },
        {
            field: "email",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span className='tablerow-detail' onClick={() => displayDetail(row.id)}>{fieldValue}</span>
        },
        {
            field: "gender",
            order: false,
            fieldComponent: (fieldValue, index, row) => <GenderIcons gender={fieldValue}/>
        },
        {
            field: "is_SSO",
            headerName: "ДАН",
            order: false,
            fieldComponent: (fieldValue, index, row) => <AccessIcons isTrue={fieldValue} />
        },
        {
            field: "is_active",
            headerName: "Нэвтрэх эрх",
            order: false,
            fieldComponent: (fieldValue, index, row) => <AccessIcons isTrue={fieldValue} />
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.id}/`)} needRoles={adminPageRoles} />
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
        <Datatable
            columns={columns}
            sequenceColumn={sequenceColumn}
            extraColumns={extraColumns}
            request={
                {
                    axios: userApi.all,
                    params: [],
                }
            }
        />
    );
}

export default Agenda;
