import React from 'react'

export default function IsSpecial({ fieldValue, isStar=false })
{
    return (
        <span>
            {
                fieldValue === true
                ?
                    <i style={{ color: '#ffc000' }} className={isStar ? "fas fa-star" : `fas fa-trophy-alt`}></i>
                :
                    null
            }
        </span>
    )
}
