import React, { useContext, useMemo, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Nav } from "react-bootstrap";

import OverlayElement from "../OverlayElement";

import useAuth from "hooks/useAuth";
import useMobile from "hooks/useMobile";

import { HeaderContext } from "context/special/headerContext";

import SidebarHeader from "./SidebarHeader";
import SidebarFooter from "./SidebarFooter";
import { Loader } from "../Loader";
import MenuItem from "./MenuItem";

import { makeFullName } from "utils";

import css from "./style.module.css";

/**
 * @param {array} links энүүгээр ямар зам, ямар layot, нэр өнгө
 * @param {string} sideBarTitle Гарчиг menu нүүдийн дээр н гарах
 *
 * @param {boolean} hasHeader header menu харуулах эсэх
 * @param {boolean} hasFooter footer харуулах эсэх
 * @param {boolean} hasOptions logo, хэдэн товчнуудыг харуулах эсэх
 *
 * @param {number} marginTop sidebar дотор дээрээсээ зай авах бол
 *
 * @returns
 */

const Sidebar = ({ links=[], sideBarTitle, hasHeader=true, hasFooter=true, hasOptions=true, marginTop=15, isLoading, sideBarBodyClassName, mobileInitValue=false }) => {

    const { Toggler, togglerState, setTogglerState, setDisplaySideBar } = useContext(HeaderContext)
    const navigate = useNavigate()

    const { signOut } = useAuth()
    const { isMobile } = useMobile(800)
	const { userDetail } = useAuth()

    const navLinks = useMemo(() =>
    {
        return links.map((element, index) =>
        {
            return <MenuItem key={index} element={element} />;
        });
    }, [links]);

	useEffect(
		() =>
		{
			if(isMobile && mobileInitValue)
			{
				setTogglerState(true)
			}
		},
		[isMobile]
	)

    useEffect(
        () =>
        {
            setDisplaySideBar(true)
            return () =>
            {
                setTogglerState(false)
                setDisplaySideBar(false)
            };
        },
        []
    );

	const bodyClassName = useMemo(
		() =>
		{
			if(hasHeader && hasFooter && hasOptions) return css.sideBarbody
			if(!hasHeader && !hasFooter && !hasOptions) return css.sideBarbody2
		},
		[]
	)

    return (
		<>
			{hasHeader && <SidebarHeader Toggler={Toggler} links={links} />}
			<Nav className={`flex-column ${css.container} ${isMobile ? (togglerState ? css.show : css.hide) : ""}`} variant="pills">
				<div className={css.logo}>
					<Link
						to={"/"}
						onClick={() => setTogglerState(false)}
					>
						<img
							src={process.env.PUBLIC_URL + "/images/logo.png"}
						/>
					</Link>
				</div>
				{hasOptions ? (
					<div className={css.companyInfo}>
						<div className={css.companyImage}>
							<img src={process.env.PUBLIC_URL + "/images/defaultHuman.jpg"} />
							<div className="mb-3 mt-2 fs-13" style={{ fontSize: "13px" }}>
								{makeFullName(userDetail.last_name, userDetail.first_name)}
							</div>
							<div className={css.buttons}>
								<OverlayElement
									text="Тохиргоо"
									placement="bottom"
								>
									<div className={css.setting}
										onClick={() => {
											setTogglerState(false)
											navigate("/user/options/")
										}}
									>
										<i className="fal fa-cog"></i>
									</div>
								</OverlayElement>
								<OverlayElement
									text="Гарах"
									placement="bottom"
								>
									<div className={css.power}
										onClick={() => {
											setTogglerState(false)
											signOut()
										}}
									>
										<i className="fa-solid fa-power-off"></i>
									</div>
								</OverlayElement>
							</div>
						</div>
					</div>
				) : (
					<div style={{ marginTop: marginTop + "px" }}></div>
				)}
				<div className={bodyClassName}>
					<div className={css.cardHeader}>
						<span className={css.sideBarTitle}>{sideBarTitle}</span>
					</div>
					{isLoading ? <div style={{ marginTop: '15vh' }}>{<Loader/>}</div> : navLinks}
					{isMobile && (
						<div className={css.closeContainer}>
							<div onClick={() => setTogglerState(false)} className={css.close}>
								<i className="fal fa-times"></i>
							</div>
						</div>
					)}
				</div>
				{/* {hasFooter && <SidebarFooter footerComponent={<span>Туршилтын хувилбар</span>} />} */}
			</Nav>
		</>
	)
};

export default Sidebar;
