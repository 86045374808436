import React from 'react';

const DangerInfo = ({ info='Та ямар нэгэн зүйл хийх хэрэгтэй' }) => {
    return (
        <div className='mt-2'>
            {info}
        </div>
    );
}

export default DangerInfo;
