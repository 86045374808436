import React, { useMemo, useEffect, useState } from 'react';
import { Col, Row, Tab, Tabs  } from 'react-bootstrap';

import BoxBackground from 'components/special/BoxBackground';

import ApartItemList from './ApartItemList';
import SpecialItem from './item';
import ResultsItems from '../ResultsItems';
import Statistic from './Statistic'

import useMap from 'hooks/map/useMap'

import label from 'utils/validate/label';

import css from '../style.module.css'
import { extractArray } from 'utils/array';

const AboutApartment = ({ apartment, data }) =>
{

    const { map, cMap } = useMap({})

    const [ fn, setFn ] = useState([])
    const busad = data?.busad

    const displayItem = useMemo(
        () =>
        {
            let advantages
            advantages = apartment?.advantage_info?.map(
                (el, index) =>
                {
                    return <SpecialItem key={index} text={el.name}/>
                }
            )
            return advantages
        },
        [apartment]
    )

    const displayOuterInfo = useMemo(
        () =>
        {
            if(!apartment) return
            if(!apartment?.apartment_outside_info?.[0]) return <div>Мэдээлэл байхгүй байна</div>
            let hasTrue = Object.entries(apartment?.apartment_outside_info?.[0]).find(([key, value]) => key !== 'is_shaard_hangasan')
            if (!hasTrue)
            {
                return <div>Мэдээлэл байхгүй байна</div>
            }
            return Object.entries(apartment?.apartment_outside_info?.[0]).map(
                (element, index) =>
                {
                    if(element[0] === 'id' || element[0] === 'is_shaard_hangasan') return
                    return <SpecialItem status={element[1]} key={index} text={label[element[0]]} />
                }
            )
        },
        [apartment]
    )

    const displayShugam = useMemo(
        () =>
        {
            const shugam = apartment?.shugam_info
            if(!shugam) return <div>Мэдээлэл байхгүй байна</div>
            return Object.entries(shugam).map(
                (element, index) =>
                {
                    if(element[0] === 'id') return null
                    return <SpecialItem status={element[1]} key={index} text={label[element[0]]} />
                }
            )
        },
        [apartment?.shugam_info]
    )


    const apartmentItemList = useMemo(
        () =>
        {
            let items = []
            items?.push(
                {
                    icon: 'fa-solid fa-square-rss',
                    number: apartment?.service_area + ' м²',
                    text: label['service_area']
                },
                {
                    icon: 'fa-solid fa-layer-group',
                    number: apartment?.service_floor,
                    text: label['service_floor']
                },
                {
                    icon: 'fa-solid fa-chart-area',
                    number: apartment?.total_service_area + ' м²',
                    text: label['total_service_area']
                },
                {
                    icon: 'fa-solid fa-house-chimney',
                    number: apartment?.total_ail,
                    text: label['total_ail']
                },
                {
                    icon: 'fa-solid fa-layer-group',
                    number: apartment?.total_floor,
                    text: label['total_floor']
                },
                {
                    icon: 'fas fa-bold',
                    number: apartment?.total_under_floor,
                    text: label['total_under_floor']
                },
            )
            return items
        },
        [apartment]
    )

    // const busadElem = useMemo(
    //     () =>
    //     {
    //         if (!busad)
    //             return null
    //         return (
    //             <>
    //                 <Col md={6} className="mt-3">
    //                     <h6>Хүн амын тоо</h6>
    //                     <Row>
    //                         {
    //                             busad.stats.hun_amiin_stats.map(
    //                                 (hun_am, idx) =>
    //                                 {
    //                                     return (
    //                                         <Col md={4}>
    //                                             <span className="d-block mt-2">{hun_am.count}{hun_am.name === "Нийт дүн" ? "" : `(${hun_am.name})`}</span>
    //                                         </Col>
    //                                     )
    //                                 }
    //                             )
    //                         }
    //                     </Row>
    //                 </Col>
    //                 <Col md={6} className="mt-3">
    //                     <h6>Өрхийн тоо</h6>
    //                     <span className="d-block mt-2">{busad.nemelt.urhiin_too}</span>
    //                 </Col>
    //             </>
    //         )
    //     },
    //     [busad]
    // )

    /** Tab СОЛИХ үед хүчээр газрын зургийг resize хийж гаргаж ирүүлэх */
    const handleSelect = (tabkey) =>
    {
        if (tabkey === 'life')
        {
            map.updateSize();
            setTimeout(() => {
                map.updateSize();
                if (fn.length > 0)
                {
                    fn[0]()
                }
            }, 10);
        }
    }

    return (
        <BoxBackground className={css.specialContainer} title='Орон сууц' >
            <Tabs onSelect={(e) => handleSelect(e)} className="tab-main nav-justified mb-3" variant="tabs" defaultActiveKey={'hiits'}>
                <Tab className="tab-main" eventKey="hiits" title="Барилга">
                    <Row>
                        <Col md={6} sm={12} className={css.locationItem}>
                            <span>Орон сууцын нэр</span>
                            <p>{apartment?.name}</p>
                        </Col>
                        <Col md={6} sm={12} className={css.locationItem}>
                            <span>{label['butets_type']}</span>
                            <p>{apartment?.butets_type_display}</p>
                        </Col>
                        <Col md={6} sm={12} className={css.locationItem}>
                            <span>{label['window_type']}</span>
                            <p>{extractArray(apartment?.window_type_info, 'window_type')}</p>
                        </Col>
                        <Col md={6} sm={12} className={css.locationItem}>
                            <span>{label['door_type']}</span>
                            <p>{extractArray(apartment?.door_type_info, 'door_type')}</p>
                        </Col>
                        {/* <Col md={6} sm={12} className={css.locationItem}>
                            <span>{label['has_lift']}</span>
                            <p>{apartment?.has_lift ? 'Тийм': 'Үгүй'}</p>
                        </Col> */}
                    </Row>
                    <Row>
                        <ApartItemList itemList={apartmentItemList} />
                    </Row>
                    <Row>
                        <h6 className='mb-2 '>Төвийн инженерийн шугам сүлжээнд холбогдсон эсэх:</h6>
                        {displayShugam}
                    </Row>
                </Tab>
                <Tab className="tab-main" eventKey="other" title="Байрны орчин">
                    <Row>
                        <h6 className='mb-2 mt-2'>Гадна орчин:</h6>
                        {displayOuterInfo}
                    </Row>
                    <Row>
                        <h6 className='mb-2 '>Давуу тал:</h6>
                        {displayItem}
                    </Row>
                </Tab>
                <Tab className="tab-main" eventKey="life" title="Төрийн үйлчилгээ">
                    <Row>
                        <Col md={12} className="col-12 p-0">
                            <ResultsItems data={apartment?.apartment_location_info?.[0]} map={map} cMap={cMap} point={apartment?.point} fn={fn}/>
                        </Col>
                        <Col md={12} className="col-12 p-0">
                            <div id='map' style={{ height: "40vh", zIndex: '999', maxWidth: "100%", minWidth: "100%" }}>
                            </div>
                        </Col>
                    </Row>
                </Tab>
                <Tab className="tab-main" eventKey="stats" title="Статистик">
                    <Row>
                        <Statistic data={data?.busad} apartment={apartment}></Statistic>
                    </Row>
                </Tab>
            </Tabs>
        </BoxBackground>
    );
}

export default AboutApartment;
