import { useNavigate, useParams } from "react-router-dom"
import BackButton from "components/main/BackButton";
import CForm from "components/main/Form"

import { materialPriceValidate } from 'utils/validate';

import useLoader from 'hooks/useLoader'
import useApi from "hooks/useApi";
import { timeZoneToDateString } from "utils/format";

export default function PriceCreate()
{
    const { id } = useParams()
    const priceApi = useApi().material.name.price
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
    const navigate = useNavigate()

    const register = async (body) =>
    {
        body['date'] = timeZoneToDateString(body['date'], false)
        const { success, data, error, errors } = await fetchData(priceApi.post(id, body)).catch(err => err)
        if (!success)
        {
            return errors
        }
        else {
            navigate(-1)
        }
    }

    const registerInputs =
    {
        inputs: [
            {
                inputType: "datePicker",
                registerName: "date",
                disabled: isLoading,
                isLoading: isLoading,
                colMd: 12
            },
            {
                inputType: "input",
                registerName: "price",
                disabled: isLoading,
                isLoading: isLoading,
                colMd: 12
            },
            {
                inputType: "input",
                registerName: "unit",
                disabled: isLoading,
                isLoading: isLoading,
                colMd: 12
            },
        ],
        button:
        {
            onSubmit: register,
            title: "Үүсгэх",
            disabled: isLoading,
            isLoading: isLoading,
        },
        validate:
        {
            validateMode: "all",
            yupValidate: materialPriceValidate
        }
    }

    return (
        <>
            <div className="mb-3">
                <BackButton onClick={() => navigate(-1)} />
            </div>
            <CForm
                form={registerInputs}
            />
        </>
    )
}
