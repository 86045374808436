import React, { useMemo, useState } from 'react'

import { NO_IMAGES } from 'utils/consts'

import css from './style.module.css'

const initSrc = {
    path: "",
    prefix: process.env.REACT_APP_SERVER_URL,
}
/**
 * Зургийг дуудаж байх хооронд loader тай бас зураг олдохгүй бол зураг байхгүй гэсэн зураг үзүүлэх
 * @param {string} src зургийн бүтэн зам байх
 * @param {boolean} src.fromServer манай системийн авдаг зураг эсэх
 * @param {string} src.path зургийн холбоос
 *
 * @param {string} noImageSize зураг байхгүй үед харуулах зургийн хэмжээ
 */
export default function ImageFetch({ src, alt="icon", className="", noImageSize='400x245', onClick, style, title })
{

    const [ isLoading, setLoading ] = useState(true)

    /** зургийн замыг props ын утгаас хамаарч үүсгэх */
    const imageSrc = useMemo(
        () =>
        {
            /** зургийн src */
            let imageSrc = src
            /** хэрвээ зураг нь object маягаар ирвэл замыг үүсгэх= */
            if (typeof imageSrc === "object" && imageSrc?.fromServer === true)
            {
                imageSrc = initSrc.prefix + "/" + imageSrc.path
            }
            if(!imageSrc?.includes('http'))
            {
                return initSrc.prefix + imageSrc
            }

            return imageSrc
        },
        [src]
    )

    if (!imageSrc || !src)
    {
        return (
            <div className='position-relative'>
				{title ? <div className={css.imageTitle}>{title}</div> : ""}
                <img
                    src={NO_IMAGES[noImageSize]}
                    alt={alt}
                    style={style}
                    className={className}
                />
            </div>
        )
    }

    /** зургийг татаж дууссаны дараа ажиллах функц */
    const handleLoaded = (e) =>
    {
        setLoading(false)
    }

    if (isLoading)
    {
        return (
            <div className={`position-relative d-flex justify-content-center align-items-center ${className}`} >
                <img
                    src={imageSrc}
                    className={`${css.hideImage}`}
                    onLoad={handleLoaded}
                    onError={handleLoaded}
                />
                {/* TODO: Loader ийг сольж зөвхөн зурган дээр уншдаг loader гаргах */}
            </div>
        )
    }
    if(title)
    {
        return (
			<div className="position-relative">
				{title ? <div className={css.imageTitle}>{title}</div> : ""}
				<img
					src={imageSrc}
					className={className}
					onClick={() => onClick && onClick()}
					alt={alt}
					style={style}
					onError={({ currentTarget }) => {
						currentTarget.onerror = null
						currentTarget.src = NO_IMAGES[noImageSize]
					}}
				/>
			</div>
		)
    }

    return (
        <img
            src={imageSrc}
            className={`${className}`}
            onClick={() => onClick && onClick()}
            alt={alt}
            style={style}
            onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src=NO_IMAGES[noImageSize]; }}
        />
    )
}
