import React from 'react'

import css from './style.module.css'

export default function Index({ children })
{
    return (
        <div className={`${css['error-box']} ${css['error-center']} text-danger`}>
            <div className={css['error-main-text']}>
                {children}
            </div>
        </div>
    )
}
