import React, { useEffect, useMemo, useState } from "react"
import { Col, Row } from "react-bootstrap"

import BoxBackground from "components/special/BoxBackground"
import CSelect from "components/main/Form/InputTypes/Select"

import Counting from "./Counting"
import AccessChart from "./AccessChart"

import useLoader from "hooks/useLoader"
import useApi from "hooks/useApi"

import label from "utils/validate/label"
import { ACCESS_FIELD_NAMES_SELECT, DATETYPES, ONE_MONTH_TO_MILLISECONDS } from "utils/consts"

const icons = {
	project: <i className="far fa-user-hard-hat"></i>,
	apartment: <i className="far fa-building"></i>,
	building: <i className="far fa-vector-square"></i>,
}

const Access = () => {

	const [ companyInfo, setCompanyInfo ] = useState({})
	const [ searchedNumber, setSearchedNumbers ] = useState([])
	const [ endDate, setEndDate ] = useState({ value: 6, label: '6 сар' })

	const [ chartItems, setChartItems ] = useState(["unit1-code", "unit2-code", "unit3-code", "project-name", "total-room", "butets-type"])

	const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false, absolute: false })
	const accessApi = useApi().companyAccessFront

	useEffect(
		() =>
		{
			fetchData(accessApi.getBaiguullagaInfo()).then(({ success, data}) => {
				if(success)
				{
					setCompanyInfo(data)
				}
			})
		},
		[]
	)

	const getData = async () =>
	{
		const { success, data, errors } = await fetchData(accessApi.getBaiguullaga(Date.now() - endDate.value * ONE_MONTH_TO_MILLISECONDS, Date.now(), chartItems, "asc")).catch(err => err)
		if(success)
		{
			setSearchedNumbers(data)
		}
	}

	useEffect(
		async () =>
		{
			await getData()
		},
		[chartItems, endDate]
	)

	const displayCounts = useMemo(
		() =>
		{
			return Object.entries(companyInfo).map(
				(el, index) =>
				{
					return <Counting key={index} title={label[el[0]]} icon={icons[el[0]]} number={el[1]} />
				}
			)
		},
		[companyInfo]
	)

	const handleSelectChartItems = (value) =>
	{
		setChartItems(value.map(element => element.value))
	}

	return (
		<>
			<Row>
				<Col className="mb-3" xl={12}>
					<BoxBackground title="Ерөнхий мэдээлэл" body={displayCounts} />
				</Col>
			</Row>
			<Row>
				<Col className="mb-3" xl={12}>
					<BoxBackground
						title="Хэрэглэгчдийн хайлт"
						body={
							<>
								<Row>
									<Col md={10}>
										<CSelect
											isLoading={isLoading}
											onChange={handleSelectChartItems}
											value={chartItems}
											options={ACCESS_FIELD_NAMES_SELECT}
											isMulti
										/>
									</Col>
									<Col md={2}>
										<CSelect
											onChange={e => setEndDate({ value: e.value, label: e.label })}
											value={endDate.value}
											options={DATETYPES}
										/>
									</Col>
								</Row>
								<AccessChart Loader={Loader} searchedNumber={searchedNumber} isLoading={isLoading} />
							</>
						}
					/>
				</Col>
			</Row>
		</>
	)
}

export default Access
