import BusadAction from "components/special/NestedTables/SanhvvAction/BusadAction";
import BuySellAction from "components/special/NestedTables/SanhvvAction/BuySellAction";
import MenegmentAction from "components/special/NestedTables/SanhvvAction/MenegmentAction";
import TureesAction from "components/special/NestedTables/SanhvvAction/TureesAction";
import ZassanAction from "components/special/NestedTables/SanhvvAction/ZassanAction";
import ZohihGuilgeeAction from "components/special/NestedTables/SanhvvAction/ZohihGuilgeeAction";

export const form = {
    'buy-sell': BuySellAction,
    'turees': TureesAction,
    'zassan': ZassanAction,
    'menegment': MenegmentAction,
    'busad': BusadAction,
    'zohih-guilgee': ZohihGuilgeeAction,
}
