import React from 'react';

/**
 * Icon харуулах component
 * @param {string} className icon ий нэр
 * @returns icon
 */
export default function index({ icon, className="", iconStyle })
{
    return <i className={`${icon} ${className}`} style={iconStyle}></i>;
}
