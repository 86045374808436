import React, { useEffect, useState } from 'react'

import CForm from 'components/main/Form'

import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'
import useCState from 'hooks/useCState'
import useLoader from 'hooks/useLoader'

import { IS_RENT, STATES } from 'utils/consts'
import { dataToValueLabel } from 'utils/format'
import { buildingValidate, manyBuildingValidate } from 'utils/validate'
import { Col } from 'react-bootstrap'
import CButton from 'components/main/Button'
import useModal from 'hooks/useModal'
import { WARNING_TEXT } from 'components/main/CudButtons/Delete'

const UpdateMany = ({ setShow, projectId, apartId, getData }) =>
{

    const { checkedList, setCheckedList, checkedListInit } = useCState()

    const [ residence, setResidence ] = useState([]);
    const [ isDelete, setIsDelete ] = useState(false)
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
    const residenceApi = useApi().residence
    const buildingApi = useApi().building
    const { userDetail } = useAuth()

    useEffect(
        async () =>
        {
            const residenceRsp = await fetchData(residenceApi.getAll(projectId, apartId))
            if(residenceRsp.success)
            {
                setResidence(dataToValueLabel(residenceRsp.data, "id", 'full_name'))
            }
        },
        []
    )

    const btnOnSubmit = async (formData) =>
    {

        const body = {
            buildings: checkedList.isAll ? checkedList.unChecked : checkedList.checked,
            is_all: checkedList.isAll,
            apartment_id: apartId,
            update_data: {
                state: formData.state,
                description: formData.description.length === 0 ? undefined : formData.description,
                input_number: formData.input_number.length === 0 ? undefined : formData.input_number,
                floor: formData.floor.length === 0 ? undefined : formData.floor,
                is_rent: formData.is_rent,
                residence_id: formData.residence,
                is_delete: isDelete,
            }
        }

        const { success, data, errors } = await fetchData(buildingApi.updateMany(body)).catch((error) => error)
        if (success)
        {
            setShow(false)
            setCheckedList(checkedListInit)
            getData()
        }
        else return errors
    }

    const hanldeDelete = (value) =>
    {
        setIsDelete(value)
    }

    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "input_number",
                type: "text",
                disabled: isLoading || isDelete,
                colMd: 6,
                label: {
                    empty: true
                }
            },
            {
                inputType: "input",
                registerName: "floor",
                type: "text",
                disabled: isLoading || isDelete,
                colMd: 6,
                label: {
                    empty: true
                }
            },
            {
                inputType: "select",
                registerName: "state",
                disabled: isLoading || isDelete,
                options: STATES,
                colMd: 6,
                label: {
                    empty: true
                }
            },
            {
                inputType: "select",
                registerName: "residence",
                disabled: isLoading || isDelete,
                options: residence,
                colMd: 6,
                label: {
                    empty: true
                }
            },
            {
                inputType: "input",
                registerName: "description",
                type: "textArea",
                disabled: isLoading || isDelete,
                colMd: 6,
                label: {
                    empty: true
                }
            },
            userDetail?.user_role?.is_tosk && {
                inputType: "select",
                registerName: "is_rent",
                disabled: isLoading || isDelete,
                options: IS_RENT,
                colMd: 6,
                label: {
                    empty: true
                }
            },
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: isDelete ? "Устгах" : 'Хадгалах',
        },
        validate: {
            validateMode: "all",
            yupValidate: manyBuildingValidate,
        },
    }
    return (
		<div>
			<p style={{ fontSize: "14px" }}>
				<i className="fa-solid fa-circle-exclamation"></i> Өөрчлөх талбаруудыг бөглөнө үү. Хоосон талбаруудад нөлөөлөхгүй.
			</p>
			<CForm
				extraButtons={
					<CButton
						variant="outline-danger"
						className="ms-2"
						onClick={() => setIsDelete(!isDelete)}
						icon={isDelete ? '' : "fas fa-trash me-1"}
						title={isDelete ? "Болих" : "Сонгосон утгуудыг утсгах"}
					/>
				}
				form={form}
			/>
		</div>
	)
}

export default UpdateMany
