import useMapContext from 'hooks/useMapContext'
import React, { useEffect, useState } from 'react'

import css from './style.module.css'

const BaseLayerSwitcher = ({ map }) =>
{
	const { showBaseLayer, setShowBaseLayer } = useMapContext()
    const [ active, setActive ] = useState('Google')

    useEffect(
        () =>
        {
            if(!map) return
            map.getLayers().forEach( function(el, index, array){
                let layerTitle = el.get('title');
                if(!layerTitle) return
                el.setVisible(layerTitle === active);
            })
        },
        [active, map]
    )

    return (
		<div className={css.baselayerSwitcher} style={showBaseLayer ? { right: '-10px' } : { right: "-700px" }}>
			<div onClick={() => setShowBaseLayer(!showBaseLayer)} className={css.closeButton}>
				<i className="fas fa-arrow-alt-to-right"></i>
			</div>
			{/* <div onClick={() => setActive("ArcGIS")} className={`${css.layer} ${active === "ArcGIS" && css.active}`}>
				<img src={process.env.REACT_APP_SERVER_URL + "/media/images/arcgis.jpg"} />
				<div>ArcGIS</div>
			</div> */}
			<div onClick={() => setActive("Google")} className={`${css.layer} ${active === "Google" && css.active}`}>
				<img src={process.env.PUBLIC_URL + "/images/gogole.jpg"} />
				<div>Google</div>
			</div>
			<div onClick={() => setActive("NSDI")} className={`${css.layer} ${active === "NSDI" && css.active}`}>
				<img src={process.env.PUBLIC_URL + "/images/nsdi.jpg"} />
				<div>NSDI</div>
			</div>
			{/* <div onClick={() => setActive("OpenStreetMap")} className={`${css.layer} ${active === "OpenStreetMap" && css.active}`}>
				<img src={process.env.REACT_APP_SERVER_URL + "/media/images/openstreet.jpg"} />
				<div>OpenStreetMap</div>
			</div> */}
		</div>
	)
}

export default BaseLayerSwitcher
