import React from 'react';
import { Form } from 'react-bootstrap';

/**
 * @param {string} label -ийн харагдах text
 * @param {boolean} empty хоосон байж болох, болохгүй гэсэн тэмдэглэгээ *
 * @returns form ийн label
 */
const Label = ({ label, empty=false, htmlFor, registerName }) =>
{
    return <Form.Label htmlFor={htmlFor}> {!empty && <span className='text-danger'>*</span>} {label} </Form.Label>
}

export default Label;
