import ProgramCrudMain from "./ProjectProgram/Program/main";
import BudgetCrudMain from "./ProjectProgram/BudgetType/main";

import Register from "./Company/Register";
import OrgAdmin from './Company/OrgAdmin'

import GovCrudMain from "./Gov/main";
import NestedTables from "components/special/NestedTables";
import ReportMain from './Report/main'
import SalesCrudMain from './Sales/main';

import {
    companyPageRoles,
    govPageRoles,
    financialAdminPageRoles,
    bhTuvPageRoles,
} from 'utils/consts/roles'
import ActivityType from "./Activity/main";
import Companies from "./Companies";
import ProgramResult from "./ProgramResult";
import MaterialAdmin from "./MaterialAdmin";
import BhTuv from "./Company/BhTuv";
import ProjectCrudMain from "./Project/main";
import Helper from "./Helper";

const companyRoutes = [
    {
        name: "Төрийн байгууллага",
        path: "/govorg/*",
        icon: <i className="fal fa-place-of-worship"></i>,
        component: GovCrudMain,
        layout: "/gov",
        needRoles: govPageRoles,
        firstActive: true,
        isNavigate: false,
    },
    {
        name: "Үр дүн",
        path: "/result/*",
        icon: <i className="fal fa-chart-bar"></i>,
        component: ProgramResult,
        layout: "/gov",
        needRoles: govPageRoles,
        firstActive: true,
        isNavigate: false,
    },
    {
        name: "Тайлан",
        path: "/report/*",
        component: ReportMain,
        icon: <i className="fal fa-print"></i>,
        layout: "/gov",
        needRoles: bhTuvPageRoles,
        isNavigate: false,
    },
    {
        name: "Төсөл хөтөлбөр",
        path: "/projectprogram/*",
        icon: <i className="fal fa-tasks"></i>,
        needRoles: govPageRoles,
        isNavigate: false,
        subMenu: [
            {
                name: "Хөтөлбөр",
                component: ProgramCrudMain,
                path: "/program/*",
                needRoles: govPageRoles,
                isNavigate: false,
                firstActive: true
            },
            // {
            //     name: "Арга хэмжээ",
            //     component: AgendaCrudMain,
            //     path: "/agenda/*",
            //     needRoles: govPageRoles,
            //     isNavigate: false,
            // },
            // {
            //     name: "Дэд арга хэмжээ",
            //     component: SubAgendaCrudMain,
            //     path: "/subagenda/*",
            //     needRoles: govPageRoles,
            //     isNavigate: false,
            // },
            {
                name: "Хөрөнгийн эх үүсвэр",
                component: BudgetCrudMain,
                path: "/budget/*",
                needRoles: govPageRoles,
                isNavigate: false,
            },
        ],
        layout: "/gov",
    },
    {
        name: "Компани",
        path: "/company/*",
        icon: <i className="fal fa-building"></i>,
        needRoles: [ ...govPageRoles, ...financialAdminPageRoles ],
        isNavigate: false,
        subMenu: [
            // {
            //     name: "Бүртгүүлэх",
            //     component: Register,
            //     path: "/register/",
            //     needRoles: [ ...govPageRoles, ...financialAdminPageRoles ],
            //     isNavigate: false,
            //     firstActive: true,
            // },
            {
                name: "Байгууллагын жагсаалт",
                path: "/companies/*",
                component: Companies,
                needRoles: [ ...govPageRoles, ...financialAdminPageRoles ],
                isNavigate: false,
            },
            {
                name: "Байгууллагын админ нэмэх",
                path: "/org-admin/*",
                component: OrgAdmin,
                layout: "/admin",
                needRoles: [ ...govPageRoles, ...financialAdminPageRoles ],
                isNavigate: false,
            },
        ],
        layout: "/gov",
        exact: false
    },
    {
        name: "Барилгын хөгжлийн төв",
        path: "/bhtuv/*",
        icon: <i className="far fa-chart-pie-alt"></i>,
        component: BhTuv,
        layout: "/gov",
        needRoles: govPageRoles,
        firstActive: true,
        isNavigate: false,
    },
    {
        name: "Санхүүгийн зохицуулах хороо",
        path: "/tables/*",
        icon: <i className="fal fa-table"></i>,
        layout: "/gov",
        needRoles: financialAdminPageRoles,
        isNavigate: false,
        subMenu: [
            {
                name: "Худалдах",
                component: NestedTables,
                path: "/buy-sell/*",
                pathId: '/:tableId/*',
                needRoles: financialAdminPageRoles,
                isNavigate: false,
                firstActive: true,
            },
            {
                name: "Түрээслэх",
                component: NestedTables,
                path: "/turees/*",
                pathId: '/:tableId/*',
                needRoles: financialAdminPageRoles,
                isNavigate: false,
            },
            // {
            //     name: "Хүснэгт 3",
            //     component: NestedTables,
            //     path: "/zassan/*",
            //     pathId: '/:tableId/*',
            //     needRoles: financialAdminPageRoles,
            //     isNavigate: false,
            // },
            // {
            //     name: "Хүснэгт 4",
            //     component: NestedTables,
            //     path: "/menegment/*",
            //     pathId: '/:tableId/*',
            //     needRoles: financialAdminPageRoles,
            //     isNavigate: false,
            // },
            // {
            //     name: "Хүснэгт 5",
            //     component: NestedTables,
            //     path: "/busad/*",
            //     pathId: '/:tableId/*',
            //     needRoles: financialAdminPageRoles,
            //     isNavigate: false,
            // },
            // {
            //     name: "Хүснэгт 6",
            //     component: NestedTables,
            //     path: "/zohih-guilgee/*",
            //     pathId: '/:tableId/*',
            //     needRoles: financialAdminPageRoles,
            //     isNavigate: false,
            // },
        ],
    },
    {
        name: "Материалын админ бүртгэл",
        path: "/material/*",
        icon: <i className="fal fa-toolbox"></i>,
        component: MaterialAdmin,
        layout: "/gov",
        needRoles: govPageRoles,
        firstActive: true,
        isNavigate: false,
    },
    // {
    //     name: "Үйл ажиллагааны төрөл",
    //     path: "/activitytype/*",
    //     icon: <i className="far fa-chart-network"></i>,
    //     component: ActivityType,
    //     layout: "/gov",
    //     needRoles: financialAdminPageRoles,
    //     isNavigate: false,
    // },
    // {
    //     name: "Хэмжих нэгж",
    //     path: "/unitmeasure/*",
    //     icon: <i className="fas fa-balance-scale-left"></i>,
    //     component: UnitMeasure,
    //     layout: "/gov",
    //     needRoles: govPageRoles,
    //     isNavigate: false,
    // },
    {
        name: "Төсөл",
        path: "/project/*",
        component: ProjectCrudMain,
        icon: <i className="fal fa-tasks"></i>,
        layout: "/gov",
        needRoles: govPageRoles,
        isNavigate: false,
    },
    {
        name: "Маркетингийн алба",
        path: "/sales/*",
        component: SalesCrudMain,
        icon: <i className="fal fa-analytics"></i>,
        layout: "/gov",
        needRoles: govPageRoles,
        isNavigate: false,
    },
    {
        name: "Видео хичээл",
        path: "/helper/*",
        component: Helper,
        icon: <i className="fal fa-video-camera"></i>,
        layout: "/gov",
        needRoles: govPageRoles,
        isNavigate: false,
    },
];

export default companyRoutes;
