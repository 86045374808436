import VideoUrl from './VideoUrl'
import OurServices from './OurServices'

export default function AboutusConfig()
{

    return (
        <div>
            <div className='mb-3'>
                <VideoUrl />
            </div>
            <OurServices />
        </div>
    )
}
