
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import User from '.';
import UserAction from './UserAction'

import BoxBackground from 'components/special/BoxBackground';

const UserMain = () =>
{
    return (
        <Routes>
            <Route path="/" element={<BoxBackground title='Хэрэглэгч' body={<User />} />} />
            <Route path="/:id/" element={<UserAction />} />
        </Routes>
    );
}

export default UserMain;
