
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import UnitMeasureMain from '.';

import UnitMeasureAction from './UnitMeasureAction';

import BoxBackground from 'components/special/BoxBackground';

const UnitMeasure = () => {
    return (
        <Routes>
            <Route path="/" element={<BoxBackground title='Хэмжих нэгж' body={<UnitMeasureMain/>} />} />
            <Route path="/:id/" element={<UnitMeasureAction/>} />
        </Routes>
    );
}

export default UnitMeasure;
