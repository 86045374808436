import React from 'react';
import { ToastContainer, Zoom } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { MAIN_TOAST_THEME } from 'utils/consts';
import './style.css'

function Toast()
{
    return (
        <ToastContainer
            theme={MAIN_TOAST_THEME}
            role='alert'
            transition={Zoom}
        />
    );
}

export default Toast
