import React, { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"

import * as am5 from "@amcharts/amcharts5"
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

import LineSlider from "components/main/Form/InputTypes/LineSlider"

import { arrayToJSON } from "utils/format"
import label from "utils/validate/label"
import { ACCESS_FIELD_ENG_NAMES } from "utils/consts"

import css from "../style.module.css"

function AccessChart({ searchedNumber, isLoading, Loader }) {
	function processData(newdata) {
		let treeData = []

		let smallBrands = { name: "Other", children: [] }

		am5.object.eachOrdered(
			newdata,
			brand => {
				let brandData = { name: brand, children: [] }
				let brandTotal = 0
				for (var model in newdata[brand]) {
					brandTotal += newdata[brand][model]
				}

				for (var model in newdata[brand]) {
					// do not add very small
					if (newdata[brand][model] >= skipSlider) {
						brandData.children.push({ name: model, value: newdata[brand][model] })
					}
				}

				if (brandTotal >= skipSlider) {
					treeData.push(brandData)
				}
			},
			(a, b) => {
				let aval = 0
				let bval = 0
				am5.object.each(newdata[a], (key, val) => (aval += val))
				am5.object.each(newdata[b], (key, val) => (bval += val))
				if (aval > bval) return -1
				if (aval < bval) return 1
				return 0
			},
		)

		return [
			{
				name: "Бүтэн",
				children: treeData,
			},
		]
	}

	const [skipSlider, setSkipSlider] = useState(30)

	useEffect(() => {
		if (isLoading) return

		let newdata = {}

		Object.entries(searchedNumber).map(element => {
			element[1].data.length > 0
			&&
				Object.assign(newdata, { [label[element[0]]]: arrayToJSON(element[1].data, "field_value", "count") })
		})

		let root = am5.Root.new("chartdivTreeMap")

		const myTheme = am5.Theme.new(root)

		myTheme.rule("RoundedRectangle", ["hierarchy", "node", "shape", "depth1"]).setAll({
			strokeWidth: 2,
		})

		myTheme.rule("RoundedRectangle", ["hierarchy", "node", "shape", "depth2"]).setAll({
			fillOpacity: 0,
			strokeWidth: 1,
			strokeOpacity: 0.2,
		})

		myTheme.rule("Label", ["node", "depth1"]).setAll({
			fontSize: 10,
			// forceHidden: true,
		})

		myTheme.rule("Label", ["node", "depth2"]).setAll({
			fontSize: 10,
		})

		root.setThemes([am5themes_Animated.new(root), myTheme])

		let container = root.container.children.push(
			am5.Container.new(root, {
				width: am5.percent(100),
				height: am5.percent(93),
				layout: root.verticalLayout,
				centerX: am5.percent(50),
				x: am5.percent(50),
			}),
		)

		let series = container.children.push(
			am5hierarchy.Treemap.new(root, {
				sort: "descending",
				singleBranchOnly: false,
				downDepth: 1,
				upDepth: 0,
				initialDepth: 1,
				valueField: "value",
				categoryField: "name",
				childDataField: "children",
				nodePaddingOuter: 0,
				nodePaddingInner: 0,
				width: am5.percent(95),
				centerX: am5.percent(40),
				x: am5.percent(40),
			}),
		)

		series.get("colors").set("step", 1)

		container.children.moveValue(
			am5hierarchy.BreadcrumbBar.new(root, {
				series: series,
			}),
			0,
		)
		series.events.on("dataitemselected", function (ev) {
			if (ev.dataItem.dataContext.name === "Бүтэн") {
				myTheme.rule("Label", ["node", "depth1"]).setAll({
					fontSize: 10,
					forceHidden: false,
				})

				myTheme.rule("Label", ["node", "depth2"]).setAll({
					fontSize: 10,
				})

				root.setThemes([am5themes_Animated.new(root), myTheme])
				return
			}

			myTheme.rule("Label", ["node", "depth1"]).setAll({
				fontSize: 10,
				forceHidden: true,
			})

			myTheme.rule("Label", ["node", "depth2"]).setAll({
				fontSize: 10,
			})

			root.setThemes([am5themes_Animated.new(root), myTheme])
		})

		series.labels.template.setAll({
			fontSize: 14,
			text: "{category}: : [bold]{sum}[/]",
		})

		series.data.setAll(processData(newdata))
		series.set("selectedDataItem", series.dataItems[0])

		series.bullets.push(function (root, series, dataItem) {
			let depth = dataItem.get("depth")

			if (depth == 1) {
				let picture = am5.Picture.new(root, {
					src: process.env.PUBLIC_URL + "/images/" + ACCESS_FIELD_ENG_NAMES[dataItem.dataContext.name] + ".png",
					centerX: am5.p50,
					centerY: am5.p50,
					width: am5.percent(35),
					isMeasured: true,
				})

				picture.states.lookup("default").setAll({ opacity: 0.15 })

				return am5.Bullet.new(root, { sprite: picture })
			}
		})

		return () => {
			root.dispose()
		}
	}, [searchedNumber, isLoading, skipSlider])

	const { watch, setValue, register } = useForm()

	useEffect(() => {
		setValue("treeMapSlider", skipSlider)
	}, [])

	return (
		<>
			<div id="chartdivTreeMap" style={{ width: "100%", height: "550px", position: "relative", marginTop: "15px" }}>
				{isLoading && <div style={{ marginTop: "15vh" }}>{Loader}</div>}
				<div className="hidelogo"></div>
			</div>
			<div>
				<label className={css.sliderLabel}>Хандалтын тоо {skipSlider}-с ихийг харуулах </label>
				<LineSlider max={100} onChange={setSkipSlider} register={register("treeMapSlider")} watch={watch} setValue={setValue} />
			</div>
		</>
	)
}
export default AccessChart
