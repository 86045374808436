import { useEffect, useState } from 'react';
import { useMemo } from 'react';
import Col from 'react-bootstrap/Col';
// import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import useMobile from 'hooks/useMobile';
import useMapContext from 'hooks/useMapContext';

import css from '../style.module.css'
import { populationStyle, POPULATION_SVG, POPULATION_COLOR } from 'hooks/map/helper';

function Population()
{
    const { isMobile } = useMobile()
    const { allFilters, cMapState, setSelectedFeature } = useMapContext()
    const START_YEAR = 2021
    const initFilters = {
        age: allFilters['age'] ?? 'all',
        year: allFilters['year'] ?? START_YEAR
    }

    const [ filters, setFilters ] = useState(initFilters)
    const YEAR_LIMIT = 5

    useEffect(
        () =>
        {
            Object.keys(initFilters).map(
                (key, idx) =>
                {
                    allFilters[key] = initFilters[key]
                }
            )
            filterKML()
        },
        []
    )

    const ages = [
        {
            name: "Бүгд",
            key: "all",
        },
        {
            name: "20-д",
            key: "20",
        },
        {
            name: "30-д",
            key: "30",
        },
        {
            name: "40-д",
            key: "40",
        },
        {
            name: "50-д",
            key: "50",
        },
        {
            name: "60-д",
            key: "60",
        }
    ]

    const handleClickFeature = (event) =>
    {
        const hasSelected = event.selected.length
        const hasDeSelected = event.deselected.length
        if (hasDeSelected && !hasSelected)
        {
            cMapState.displayOverlay(undefined)
        }

        if (hasSelected)
        {
            const selectedFeatures = event.selected
            if (selectedFeatures.length)
            {
                const selectedFeature = selectedFeatures[0]
                let extent = selectedFeature.getGeometry().getExtent();
                let selectedCoordinate = cMapState.getCenterFromExtent(extent);
                cMapState.displayOverlay(selectedCoordinate)
                setSelectedFeature({ feature: selectedFeature, state: "population" })
            }
        }
    }

    /** back аас зөв kml ийг дуудаж газрын зурган дээр харуулах нь */
    const filterKML = () =>
    {
        cMapState.displayOverlay(undefined)
        const beforeLayerName = `population-${filters.year}-${filters.age}`
        const layerName = `population-${allFilters.year}-${allFilters.age}`

        let foundBeforeLayer = []
        let foundNowLayer = []
        cMapState.map.getLayers().forEach(
            (layer) =>
            {
                if (layer && layer.get("name") === beforeLayerName)
                {
                    foundBeforeLayer.push(layer)
                }
            }
        )
        cMapState.map.getLayers().forEach(
            (layer) =>
            {
                if (layer && layer.get("name") === layerName)
                {
                    foundNowLayer.push(layer)
                }
            }
        )

        if (foundBeforeLayer.length)
        {
            foundBeforeLayer.forEach(
                (layer, idx) =>
                {
                    layer.setVisible(false)
                }
            )
            if (foundNowLayer.length)
            {
                foundNowLayer.forEach(
                    (layer, idx) =>
                    {
                        layer.setVisible(true)
                    }
                )
                return
            }
        }

        const urlAu1 = `${process.env.REACT_APP_SERVER_PUBLIC_URL}/media/kml/population/${allFilters.year}/${allFilters.age}/AU1/${allFilters.age}.kml`
        const urlAu2 = `${process.env.REACT_APP_SERVER_PUBLIC_URL}/media/kml/population/${allFilters.year}/${allFilters.age}/AU2/${allFilters.age}.kml`
        const urlAu3 = `${process.env.REACT_APP_SERVER_PUBLIC_URL}/media/kml/population/${allFilters.year}/${allFilters.age}/AU3/${allFilters.age}.kml`
        const { vectorSource: au1Source, vectorLayer: au1Layer } = cMapState.createKMLLayer(urlAu1, layerName, { style: populationStyle, minZoom: 1, maxZoom: 12 })
        const { vectorSource: au2Source, vectorLayer: au2Layer } = cMapState.createKMLLayer(urlAu2, layerName, { style: populationStyle, minZoom: 12, maxZoom: 16 })
        const { vectorSource: au3Source, vectorLayer: au3Layer } = cMapState.createKMLLayer(urlAu3, layerName, { style: populationStyle, minZoom: 16, maxZoom: 22 })
        cMapState.createSelect(
            isMobile ? "click" : 'hover',
            handleClickFeature,
            null,
            [au1Layer, au2Layer, au3Layer],
            [],
            'population'
        )
    }

    const handleFilter = (value, key) =>
    {
        setFilters(
            (prev) =>
            (
                {
                    ...prev,
                    [key]: value
                }
            )
        )
        allFilters[key] = value
        filterKML()
    }

    const ageChoices = useMemo(
        () =>
        {
            return ages.map(
                (age, idx) =>
                {
                    return (
                        <div key={idx} className={`${css.choicesItem} ${filters.age === age.key ? css.active : ""}`} onClick={() => handleFilter(age.key, 'age')}>
                            {age.name}
                        </div>
                    )
                }
            )
        },
        [ages, filters]
    )

    const yearsChoices = useMemo(
        () =>
        {
            const elems = []
            for (let index = 0; index < YEAR_LIMIT; index++)
            {
                const year = START_YEAR - index
                elems.push(
                    <div key={index} className={`${css.choicesItem} ${filters.year === year ? css.active : ""}`} onClick={() => handleFilter(year, 'year')}>
                        {year} он
                    </div>
                )
            }
            return elems
        },
        [START_YEAR, filters]
    )

    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
                {/* <Col sm={12}>
                    <Nav variant="pills" className="row">
                        <Nav.Item className='col-6'>
                            <Nav.Link eventKey="first">Жилээр</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='col-6'>
                            <Nav.Link eventKey="second">Сараар</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col> */}
                <Col sm={12}>
                    {/* <Tab.Content>
                         <Tab.Pane eventKey="first"> */}
                            <div className={css.choiceContainer}>
                                <h6>Насыг сонгоно уу</h6>
                                <div className={css.choices}>
                                    {ageChoices}
                                </div>
                            </div>

                            <hr />

                            <div className={css.choiceContainer}>
                                <h6>Өмнөх статистик</h6>
                                <div className={css.choices}>
                                    {yearsChoices}
                                </div>
                            </div>
                        {/* </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe corrupti eveniet enim, ipsa temporibus nihil pariatur exercitationem quia earum, porro a neque ab blanditiis eum obcaecati, vel non libero aperiam.
                        </Tab.Pane>
                    </Tab.Content> */}

                    <hr />

                    <div>
                        <ul className={css.sanamj}>
                            <li className={css.sanamjLi}>
                                <i className='fa fa-info-circle text-primary me-1'></i>
                                Тайлбар:
                                <ol>
                                    Засар захиргаагааг насны ангилалаар харах нь
                                </ol>
                            </li>
                            <li className={css.sanamjLi}>
                                <i className='fa fa-info-circle text-primary me-1'></i>
                                Санамж:
                                <ol>
                                    20-д сонголоо гэхэд 20-29 насны хоорондох датаг харуулах болно
                                </ol>
                            </li>
                            <li className={css.sanamjLi}>
                                <i className='fa fa-info-circle text-primary me-1'></i>
                                Таних тэмдэг:
                                <div className='d-flex align-items-center justify-content-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80">
                                        <defs>
                                            <filter id="shadow">
                                                <feDropShadow dx="3" dy="3"/>
                                            </filter>
                                        </defs>
                                        <g style={{ filter: "url(#shadow)" }}>
                                            <path opacity="0.65" style={{ fill: POPULATION_COLOR }} d={POPULATION_SVG[0]} />
                                        </g>
                                    </svg>

                                    <div className='position-relative'
                                        style={
                                            {
                                                width: "100px",
                                                height: "115px",
                                            }
                                        }
                                    >
                                        <div style={{
                                            height: '2px',
                                            position: 'absolute',
                                            width: '100px',
                                            backgroundColor: '#3291C8',
                                            bottom: '18px',
                                            transform: 'rotate(7deg)',
                                        }}>
                                        </div>
                                        <div style={{
                                            height: '2px',
                                            position: 'absolute',
                                            width: '100px',
                                            backgroundColor: '#3291C8',
                                            bottom: '42px',
                                            transform: 'rotate(4deg)',
                                        }}>
                                        </div>
                                        <div style={{
                                            height: '2px',
                                            position: 'absolute',
                                            width: '100px',
                                            backgroundColor: '#3291C8',
                                            bottom: '97px',
                                            transform: 'rotate(-7deg)',
                                        }}>
                                        </div>
                                        <div style={{
                                            height: '2px',
                                            position: 'absolute',
                                            width: '100px',
                                            backgroundColor: '#3291C8',
                                            bottom: '73px',
                                            transform: 'rotate(-7deg)',
                                        }}>
                                        </div>
                                    </div>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="120">
                                        <defs>
                                            <filter id="shadow">
                                                <feDropShadow dx="3" dy="3"/>
                                            </filter>
                                        </defs>
                                        <g style={{ filter: "url(#shadow)" }}>
                                            <path opacity="0.65" style={{ fill: POPULATION_COLOR }} d={POPULATION_SVG[POPULATION_SVG.length - 1]} />
                                        </g>
                                    </svg>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    Тухайн насны ангилын нягтаршлаас хамаарч өндөр болно
                                </div>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </Tab.Container>
    );
}

export default Population;
