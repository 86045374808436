import React, { useEffect } from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import LotteryCard from 'components/special/LotteryCard';
import MultiImageUpload from 'components/main/Form/InputTypes/MultiImageUpload'

const TestComponent = () => {


    const data = [
        {
           title: "НАРЫН ХОРООЛОЛ 'A' БЛОК ТӨСЛИЙН БАЙРНЫ СОНГОН ШАЛГАРУУЛАЛТ",
           image: '/images/nisekh-cover.jpg',
           date: '2020-12-12',
           players: 123123,
           status: "active"
        },
        {
            title: "НАРЫН ХОРООЛОЛ 'A' БЛОК ТӨСЛИЙН БАЙРНЫ СОНГОН ШАЛГАРУУЛАЛТ",
            image: '/images/nisekh-cover.jpg',
            date: '2020-12-12',
            players: 123123,
            status: "inactive"
        },
    ]

    const displaySongon = () =>
    {
        return data.map(
            (el, index) =>
            {
                return(
                    <Col lg={4} md={6} sm={12} className="mb-4" key={index}>
                        <LotteryCard title={el.title} image={el.image} players={el.players} status={el.status} date={el.date} />
                    </Col>
                )
            }
        )
    }

    return (
        <Container>
            <Row>
                {/* {displaySongon()} */}
                {/* <MultiImageUpload/> */}
            </Row>
        </Container>
    );
}

export default TestComponent;
