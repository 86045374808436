export function getColor(value, diffrence=150, number=6){
    var hue=((value + number)*diffrence).toString(10);
    return ["hsl(",hue,",70%, 60%)"].join("");
}

export function getRedGreenColor(value, opacity=0.3){
    var hue = ((1 - value) * 120).toString(10);
    return ["hsla(", hue, ",100%,50%," + opacity +")"].join("");
}

export const priceStyle = {
	0: {
		color: "rgb(102,214,255)",
		height: 0.3,
	},
	1: {
		color: "rgb(102,214,255)",
		height: 0.3,
	},
	2: {
		color: "rgb(56,153,255)",
		height: 0.3,
	},
	3: {
		color: "rgb(22,179,52)",
		height: 0.3,
	},
	4: {
		color: "rgb(246,142,22)",
		height: 0.4,
	},
	5: {
		color: "rgb(246,142,22)",
		height: 0.5,
	},
	6: {
		color: "rgb(244,78,20)",
		height: 0.6,
	},
	7: {
		color: "rgb(244,78,20)",
		height: 0.7,
	},
	8: {
		color: "rgb(211,33,44)",
		height: 0.8,
	},
}

// export const priceStylev2 =
// [
// 	[[1, 1.5], "rgb(102,214,255)", 5 / 10]
// 	[[1.5, 2], "rgb(56,153,255)", 6 / 10]
// 	[[2, 3], "rgb(22,179,52)", 7 / 10]
// 	[[3, 5], "rgb(246,142,22)", 8 / 10]
// 	[[5, 8], "rgb(244,78,20)", 9 / 10]
// 	[[8, 100000000000000000000], "rgb(211,33,44)", 10 / 10]
// ]

export const greenToRedColor50 = [
	"hsl(120,100%,50%)",
	"hsl(117.6,100%,50%)",
	"hsl(115.19999999999999,100%,50%)",
	"hsl(112.8,100%,50%)",
	"hsl(110.4,100%,50%)",
	"hsl(108,100%,50%)",
	"hsl(105.6,100%,50%)",
	"hsl(103.2,100%,50%)",
	"hsl(100.8,100%,50%)",
	"hsl(98.4,100%,50%)",
	"hsl(96,100%,50%)",
	"hsl(93.60000000000001,100%,50%)",
	"hsl(91.2,100%,50%)",
	"hsl(88.8,100%,50%)",
	"hsl(86.39999999999999,100%,50%)",
	"hsl(84,100%,50%)",
	"hsl(81.6,100%,50%)",
	"hsl(79.19999999999999,100%,50%)",
	"hsl(76.8,100%,50%)",
	"hsl(74.4,100%,50%)",
	"hsl(72,100%,50%)",
	"hsl(69.60000000000001,100%,50%)",
	"hsl(67.2,100%,50%)",
	"hsl(64.80000000000001,100%,50%)",
	"hsl(62.400000000000006,100%,50%)",
	"hsl(60,100%,50%)",
	"hsl(57.599999999999994,100%,50%)",
	"hsl(55.199999999999996,100%,50%)",
	"hsl(52.8,100%,50%)",
	"hsl(50.400000000000006,100%,50%)",
	"hsl(48,100%,50%)",
	"hsl(45.6,100%,50%)",
	"hsl(43.199999999999996,100%,50%)",
	"hsl(40.8,100%,50%)",
	"hsl(38.39999999999999,100%,50%)",
	"hsl(36.00000000000001,100%,50%)",
	"hsl(33.6,100%,50%)",
	"hsl(31.200000000000003,100%,50%)",
	"hsl(28.799999999999997,100%,50%)",
	"hsl(26.4,100%,50%)",
	"hsl(23.999999999999993,100%,50%)",
	"hsl(21.600000000000005,100%,50%)",
	"hsl(19.200000000000003,100%,50%)",
	"hsl(16.8,100%,50%)",
	"hsl(14.399999999999999,100%,50%)",
	"hsl(9.599999999999994,100%,50%)",
	"hsl(7.200000000000006,100%,50%)",
	"hsl(4.800000000000004,100%,50%)",
	"hsl(0,100%,50%)",
	"hsl(2.400000000000002,100%,50%)",
	"hsl(11.999999999999996,100%,50%)",
]

export function getblueToRed(value, opacity=0.3, max=100){
    var hue = Math.abs((value * 120 / max) + 250)
    return ["hsla(", hue, ",100%,50%," + opacity +")"].join("");
}
