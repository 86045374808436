import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import CSelect from 'components/main/Form/InputTypes/Select';
import CInput from 'components/main/Form/InputTypes/Input';
import CButton from 'components/main/Button';
import ImageUpload from 'components/main/Form/InputTypes/ImageUpload'

import useLoader from 'hooks/useLoader'
import useToast from 'hooks/useToast';
import useApi from 'hooks/useApi'

import CreateRoom from './CreateRoom';

import { dataToValueLabel } from 'utils/format';

import css from './style.module.css'

const AddRoom = ({ residenceId, totalRoom, projectId }) => {
    const initInputState = [
        {
            "id": "",
            "room_type": "",
            "room_mkv": "",
            "room_count": "",
            "residence": residenceId,
            "image": "",
        }
    ]

    const [ inputs, setInputs ] = useState(initInputState)
    const [ changedData, setChangedData ] = useState({})
    const [ roomTypes, setRoomTypes ] = useState([]);
    const [ ableRooms, setAbleRooms ] = useState(0)
    const [ canEdit, setCanEdit ] = useState(false)
    const [ errors, setErrors ] = useState({})

    const { isLoading, fetchData, Loader } = useLoader({ isSmall: false, hasBackground: false })
    const { addToast, clearAllToast } = useToast()
    const roomApi = useApi().residence.room
    const roomTypeApi = useApi().roomType

    useMemo(
        () =>
        {
            const initialValue = 0;
            const rooms_now = inputs.reduce(
                (previousValue, currentValue) => previousValue + currentValue.room_count,
                initialValue
            );

            setAbleRooms(totalRoom - rooms_now)
        },
        [inputs]
    )

    const getRoomTypes = async () =>
    {
        const { data, success } = await fetchData(roomTypeApi.get())
        if(success) setRoomTypes(dataToValueLabel(data, 'id', 'name'))
    }

    const getRooms = async () =>
    {
        const { data, success } = await fetchData(roomApi.get(projectId, residenceId))
        if(success) setInputs(data)
    }

    useEffect(
        async () =>
        {
            getRoomTypes()
            getRooms()
        },
        []
    )

    const toggleEdit = (index, id) =>
    {
        setChangedData({
            "id": id,
            "room_type": inputs[index]?.room_type,
            "room_mkv": inputs[index]?.room_mkv,
            "room_count": inputs[index]?.room_count,
            "residence": residenceId,
            "image": inputs[index].image
        })
        setCanEdit(true)
    }

    const handleOnChange = (event, id) =>
    {
        if(event.value)
        {
            return setChangedData(
                {
                    ...changedData,
                    room_type: event.value
                }
            )
        }

        if(parseFloat(event.target.value) <= 0)
        {
            addToast({ text:'Эерэг тоо оруулна уу', type: 'warning' })
            return setErrors(
                {
                    ...errors,
                    [event.target.name]: {
                        message: 'Эерэг тоо оруулна уу'
                    }
                }
            )
        } else{
            delete errors[event.target.name]
        }

        if (event.target.name === "image") {
			return setChangedData({
				...changedData,
				image: event.target.files[0],
			})
		}

        if(event.target.name === 'room_count')
        {
            let rooms = 0
            inputs.map(
                (element, index) =>
                {
                    if(element.id === id) return
                    rooms = rooms + element.room_count
                }
            )
            rooms = rooms + parseInt(event.target.value || 0)
            if(totalRoom < rooms)
            {
                setErrors(
                    {
                        ...errors,
                        room_count: {
                            message: 'Боломжтой өрөөний тооноос хэтэрлээ'
                        }
                    }
                )
                addToast({ text:'Боломжтой өрөөний тооноос хэтэрлээ', type: 'warning' })
                setAbleRooms(0)
            } else{
                setAbleRooms(totalRoom - rooms)
                clearAllToast()
                delete errors.room_count
            }
        }
        setChangedData(
            {
                ...changedData,
                [event.target.name]: event.target.value
            }
        )
    }

    const cancel = () =>
    {
        setChangedData(initInputState)
        setCanEdit(false)
    }

    const save = async (id) =>
    {
        if(Object.keys(errors).length !== 0 ) return
        delete changedData.id
        const formData = new FormData()

        formData.append('room_type', changedData.room_type)
        formData.append('room_mkv', changedData.room_mkv)
        formData.append('room_count', changedData.room_count)
        changedData.image && formData.append('image', changedData.image)
        formData.append('residence', changedData.residence)
        const { success, data } = await fetchData(roomApi.put(projectId, residenceId, id, formData))
        if(success)
        {
            getRooms()
            cancel()
        }
    }

    const deleteItem = async (id) =>
    {
        const { success } = await fetchData(roomApi.delete(projectId, residenceId, id))
        if(success)
        {
            getRooms()
            cancel()
        }
    }

    const displayInputs = useMemo(
        () =>
        {
            return inputs.map(
                (element ,index) =>
                {
                    return (
						<div className='mb-3' key={index}>
							<div key={element.id}>
                                <Row>
                                    <Col md={4}>
                                        <label><span style={{ width: "20px", marginLeft: '5px' }}>{index + 1}.</span> Өрөө:</label>
                                        <CSelect
                                            disabled={changedData.id === element.id ? false : true}
                                            value={changedData.id === element.id ? changedData.room_type : element.room_type}
                                            className={css.inputSize}
                                            onChange={handleOnChange}
                                            options={roomTypes}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <label>
                                            м<sup>2</sup>:
                                        </label>
                                        <CInput
                                            disabled={changedData.id === element.id ? false : true}
                                            realValue={changedData.id === element.id ? changedData.room_mkv : element.room_mkv}
                                            register={ {name: 'room_mkv'} }
                                            type='number'
                                            errors={changedData.id === element.id && errors}
                                            onChange={handleOnChange}
                                            step='0.01'
                                            className={css.inputSize}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <label>Тоо:</label>
                                        <CInput
                                            disabled={changedData.id === element.id ? false : true}
                                            realValue={changedData.id === element.id ? changedData.room_count : element.room_count}
                                            type='number'
                                            register={ {name: 'room_count'} }
                                            onChange={ e => handleOnChange(e, element.id)}
                                            errors={changedData.id === element.id && errors}
                                            className={css.inputSize}
                                        />
                                    </Col>
                                    <Col md={12} className='mt-3'>
                                        <ImageUpload
                                            value={changedData.id === element.id ? changedData.image : element.image}
                                            errors={errors}
                                            disabled={changedData.id === element.id ? false : true}
                                            multiple={false}
                                            id={'goyzurag' + element.id}
                                            register={{ name: "image", onChange: handleOnChange }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className='mt-2'>
                                    {
                                        changedData.id === element.id && canEdit
                                        ?
                                            <>
                                                <CButton isLoading={isLoading} className={`${css.buttonSize} me-2`} onClick={() => save(element.id)} >
                                                    Хадгалах
                                                </CButton>
                                                <CButton isLoading={isLoading} className={`${css.buttonSize}`} onClick={() => cancel()} variant='danger'>Болих</CButton>
                                            </>
                                        :
                                            <>
                                                <CButton isLoading={isLoading} className={`${css.buttonSize} me-2`} variant='second' onClick={() => toggleEdit(index, element.id)} >
                                                    Өөрчлөх
                                                </CButton>
                                                <CButton isLoading={isLoading} className={`${css.buttonSize}`} onClick={() => deleteItem(element.id)} variant='danger'>Устгах</CButton>
                                            </>
                                    }
                                    </Col>
                                </Row>
							</div>
							<br />
						</div>
					)
                }
            )
        },
        [inputs, changedData, errors, roomTypes]
    )

    return (
		<div>
			{ isLoading ? Loader : displayInputs}
			<div className="d-flex justify-content-center">
				<div className={css.ableInfo}>Нэмэх боломжтой өрөөний тоо {ableRooms}</div>
			</div>
			{ableRooms !== 0 && (
				<CreateRoom
					roomTypesAll={roomTypes}
					getRooms={getRooms}
					initInputState={initInputState}
					residenceId={residenceId}
					totalRoom={totalRoom}
					projectId={projectId}
					inputs={inputs}
					roomTypes={roomTypes}
				/>
			)}
		</div>
	)
}

export default AddRoom;
