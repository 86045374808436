import React from 'react';
import { Route, Routes } from 'react-router-dom';
import News from '.';

const NewsRoute = () => {
    return (
        <Routes>
            <Route path='/' element={<News/>}/>
            <Route path='/:pageId/:newsId' element={<News/>}/>
        </Routes>
    );
}

export default NewsRoute;
