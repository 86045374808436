import CSelect from "components/main/Form/InputTypes/Select"

import { dataToValueLabel } from "utils/format"

const ProgramChooseInputs = ({ chartData, setChoosedProgram, choosedProgram, placeholder='Хөтөлбөр сонгох' }) =>
{
    const handleProgram = (value) =>
    {
        let found = chartData.find(element => element.id === value.value)
        setChoosedProgram(found)
    }

    return (
		<div>
			<CSelect
				value={choosedProgram}
				onChange={value => handleProgram(value)}
				placeholder={placeholder}
				options={dataToValueLabel(chartData, "id", "name")}
			/>
		</div>
	)
}

export default ProgramChooseInputs
