import React from "react";

import MainNavLink from "components/main/NavLink";
import RoleRequired from "components/main/RoleRequired";

import css from "../style.module.css";

const MenuItemSub = ({ show, elements, layout, path, setSidebarToggler }) =>
{
    if (!elements) {
        return null;
    }

    const dispalySubMenu = () =>
    {
        return elements?.map(
            (element, index) =>
            {
                return (
                    <RoleRequired key={index} needRoles={element?.needRoles ? element.needRoles : []} isNavigate={element.isNavigate || false}>
                        <MainNavLink
                            onClick={() => setSidebarToggler(false)}
                            exact={element.exact}
                            className={`d-flex align-items-center ${css.navSubItem} `}
                            activeClassName={css.navSubItemActive}
                            to={layout + path.replace("/*", "") + element.path.replace("/*", "/")}>
                            {element.name}
                        </MainNavLink>
                    </RoleRequired>
                );
            }
        );
    };
    return <div id={path} className={`${css.navItemSub}` }>{dispalySubMenu()}</div>;
};

export default MenuItemSub;
