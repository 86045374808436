import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';

import MapPopupProject from './MapPopupProject';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';
import useMap from 'hooks/map/useMap'
import useToast from 'hooks/useToast';

import COverlay from 'components/special/MapControls/Overlay';
import MapControls from 'components/special/MapControls'
import Scale from 'components/special/MapControls/Scale'

import { selectStyle, styleOfBuilding } from 'hooks/map/helper';
import { BU_URL } from 'context/special/mapContext';

export default function Map()
{
    const { map, cMap, mapContext } = useMap({ mapId: "map" })
    const [ geoData, setGeoData ] = useState("");
    const [ sdis, setSDI ] = useState([])
    const [ mapFeatures, setFeatures ] = useState([])
    const [ clickedCoordinate, setCoordinate ] = useState([])
    const { addToast } = useToast()

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })
    const apartmentsdiApi = useApi().apartmentsdi
    const projectApi = useApi().project
    const { id: projectId } = useParams();

    // const wfsUrl = 'https://nsdi.gov.mn/api/service/42a66d85e3b845b8bce52c2f38c1e31a/gp_layer_c_2ndorder_administrativeunit_au_view.geojson'

    const titleElement = document.getElementById('title-tusul')
    /** Тухайн нэг feature дээр дарагдах үед */
    const handleFeature = (event) =>
    {
        const feature = event?.selected?.[0]
        /** хэрвээ feature дээр дарагдайгүй байх юм бол popup алга болго нь */
        if (!feature)
        {
            cMap.displayOverlay(undefined)
            return
        }

        /** бүртгэлтэй feature бол түүний apart ийн нэрийг харуулах */
        const sdiData = feature?.get('cMap-data')
        if (sdiData)
        {
            titleElement.innerText = sdiData.apartment_name
            setGeoData(sdiData.apartment_id)
        }
        else {
            titleElement.innerText = "Сонгоно уу"
            setGeoData(event.selected[0].getId())
        }

        const _clickedCoordinate = event.mapBrowserEvent.coordinate
        cMap.displayOverlay(_clickedCoordinate)
        const centerOfFeature = cMap.getCenterFromExtent(feature.getGeometry().getExtent())
        setCoordinate(centerOfFeature)
    }

    /** apartment SDI ийг дуудах */
    const getSDIData = async () =>
    {
        const apartRsp = await fetchData(apartmentsdiApi.get(projectId))
        if(apartRsp.success)
        {
            setSDI(apartRsp.data)
        }
        return apartRsp.data
    }

    /** өмнө нь бүртгэлтэй feature ийг газрын зурган дээр харуулах нь */
    const removeFeatures = (features, beforeGeoIds) =>
    {
        let filteredFeature = []
        for (const feature of features)
        {
            if (beforeGeoIds.length === 0)
            {
                filteredFeature.push(feature)
                continue
            }

            const featureId = feature.getId()

            const foundGeoIndex = beforeGeoIds.findIndex(element => element === featureId)
            if (foundGeoIndex !== -1)
            {
                beforeGeoIds.splice(foundGeoIndex, 1)
            }
            else {
                filteredFeature.push(feature)
            }
        }
        return filteredFeature
    }

    /** feature ууд уншиж дууссаны дараа style өгөх */
    const featuresLoadEnd = (features, rspSDIS, beforeGeoIds) =>
    {

        /** өмнө нь бүртгэлтэй feature ийг газрын зурган дээр харуулах нь */
        if (beforeGeoIds.length > 0)
        {
            features = removeFeatures(features, beforeGeoIds)
        }

        /** SDI ийг clone хийж үүнээсээ хэрэглэх нь */
        const ourSDIS = [ ...rspSDIS ]

        const loadedFeatures = features
        /** Харагдаж feature үүдээс сонгогдсон apartment болон түрээслэх боломжтойгоор нь өнгөөр ялгах нь */
        for (const feature of loadedFeatures)
        {
            /** Хэрвээ sdi байхгүй эсвэл бүгд олдсон бол давталтыг зогсоох */
            if (ourSDIS.length === 0)
            {
                break
            }

            const featureId = feature.getId()
            const foundSDIIndex = ourSDIS.findIndex(element => element?.sdi?.geo_id === featureId)

            /** sdi олдоогүй бол continue хийх */
            if (foundSDIIndex === -1)
                continue

            /** APARTMENT sdi олдсон бол oursdis аас олдсон sdi ийг устгах */
            const foundAPSDI = ourSDIS[foundSDIIndex]

            /** тухайн олдсон sdi ийг жагсаалтнаас хасах */
            ourSDIS.splice(foundSDIIndex, 1)

            /** Тухайн feature -д барилгын style ийг өгөх нь */
            const bStyle = styleOfBuilding(
                {
                   name: foundAPSDI.apartment_name,
                   isRent: foundAPSDI.sdi.in_rent
                }
            )
            feature.setStyle(bStyle)
            feature.setProperties({ 'cMap-style': bStyle })
            feature.setProperties({ 'cMap-data': foundAPSDI })
        }
        return features
    }

    /** Хадгалсны дараа feature ийн өнгийг солих нь */
    const handleSave = async (apartSdi) =>
    {
        for (const feature of mapFeatures)
        {
            /** Тухайн нэг feature update хийгдэж байгаа үед өмнө нь байсан feature ийн өнгийг буцаах */
            if (apartSdi.apartment_id === feature.get("cMap-data")?.apartment_id)
            {
                cMap.resetFeatureStyle(feature)
                feature.unset('cMap-style')
                feature.unset('cMap-data')
                break
            }
        }
        featuresLoadEnd(mapFeatures, [apartSdi], sdis.before_geo_ids)
        handleClose()
    }

    /** тухайн дарж орсон төслийн мэдээллийг авах нь үүнээс unit3 ийн ID хэрэглэнэ */
    const getProject = async () =>
    {
        const { success, data, error } = await projectApi.getOne(projectId).catch(err => err)
        return data
    }

    /** газрын зургийн дээр барилгын үндсэн датаг nsdi аас дуудах */
    const getMainData = async () =>
    {
        const project = await getProject()
        const boundaryId = project?.unit3?.code
        const response = await axios
            .post(BU_URL, JSON.stringify({ "boundary_id": `${boundaryId}`, "cql_filter": "gm_type='Обьект'" }) )
            .catch(
                err =>
                {
                    addToast(
                        {
                            text: "NSDI аас дуудахад алдаа гарсан байна",
                            time: 3000,
                            type: "warning"
                        }
                    )
                    cMap.removeLoader()
                }
            )
        if (response && response.statusText === "OK")
        {
            return response.data
        }
    }

    useEffect(
        async () =>
        {
            if (map && cMap)
            {
                cMap.addLoader()
                const rspSDIS = await getSDIData()
                const geoJson = await getMainData()
                if (!geoJson)
                    return
                const features = cMap.parseGeoJsonToFeature(geoJson)
                if (features.length === 0)
                {
                    addToast(
                        {
                            text: "Барилгын мэдээлэл олдсонгүй",
                            time: 3000,
                            type: "warning"
                        }
                    )
                    cMap.removeLoader()
                    return
                }
                const filterdFeature = featuresLoadEnd(features, rspSDIS.sdi_data, rspSDIS.before_geo_ids)
                setFeatures(filterdFeature)
                const { vectorLayer, vectorSource } = cMap.addVectorLayer(filterdFeature)
                const extent = cMap.getSourceExtent(vectorSource)
                cMap.fitToExtent(extent, { duration: 2000 })
                cMap.createSelect(
                    'click',
                    (evt) => handleFeature(evt),
                    selectStyle,
                    [vectorLayer]
                )
                cMap.removeLoader()

            }
        },
        [map, cMap]
    )

    /** overlay хаагдах үед */
    const handleClose = () =>
    {
        setGeoData("")
        setCoordinate([])
    }

    const overlay = useMemo(
        () =>
        {
            return (
                <COverlay
                    map={map}
                     cMap={cMap}
                     component={
                        <MapPopupProject cMap={cMap} geoId={geoData} clickedCoordinate={clickedCoordinate} sdis={sdis.sdi_data} handleSave={handleSave}/>
                    }
                    onClose={handleClose}
                />
            )
        },
        [map, geoData, clickedCoordinate]
    )

    return (
        <>
            <div id='map' style={{ height: "75vh", position: "relative", padding: "0px" }}>
                <MapControls map={map} showButtons={["layers"]} cMap={cMap} />
                <Scale map={map} cMap={cMap} />
                {overlay}
            </div>
        </>
    )
}
