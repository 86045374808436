import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Countdown from 'react-countdown';

import CTable from 'components/main/Table';
import Select from 'components/main/Form/InputTypes/Select'

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { dataToValueLabel, quantityFormat, timeZoneToDateString } from 'utils/format';

import css from '../style.module.css'

/**
 * @param {JSON} data захиалга хийх компонент рүү өгөгдөл дамжуулна
 * @return CountDown буцаана
 * */
const LotteryCountDown = ({ end_date=(Date.now() + 100000000), title, zoriltotGroups, name, endDate, wonDetail }) =>
{

    const { lotteryId } = useParams()
    const [ diffrence, setDiffrence ] = useState(0)

    const [ selectedBuleg, setBuleg ] = useState(null)
    const [ showList, setShowList ] = useState([])

    const OneHourMS = 3600000

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: true })
    const [ rows, setRows ] = useState([]);
    const endResultApi = useApi().endResult

    const columns =
    [
        {
            field: "user",
            headerName: "Овог",
            order: false,
            fieldComponent: (fieldValue) => <span>{fieldValue.last_name}</span>,
        },
        {
            field: "user",
            headerName: "Нэр",
            order: false,
            fieldComponent: (fieldValue) => <span>{fieldValue.first_name}</span>,
        },
        {
            field: "created_at",
            headerName: "Өргөдөл гаргасан огноо",
            order: false,
            fieldComponent: (fieldValue) => <span>{timeZoneToDateString(fieldValue)}</span>,
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтний утгыг авах */
    const getData = async () =>
    {
        if (!selectedBuleg) return
        const resp = await fetchData(endResultApi.get(lotteryId, selectedBuleg))
        if(resp.success)
        {
            setRows(resp.data)
        }
    }

    useEffect(
        () =>
        {
            getData()
        },
        [selectedBuleg]
    )

    useEffect(
        () =>
        {
            if (zoriltotGroups && zoriltotGroups.length > 0)
            {
                setBuleg(zoriltotGroups[0].code)
            }
        },
        [zoriltotGroups]
    )

    const handleChange = (event) =>
    {
        setBuleg(event.value)
    }

    const onShowList = (idx) =>
    {

        if(showList.includes(idx))
        {
            setShowList(
                prev =>
                {
                    return prev.filter(e => e !== idx)
                }
            )
            return
        }
        setShowList(
            prev =>
            {
                return [ ...prev, idx ]
            }
        )
    }



    const displayMkvWinner = useMemo(
        () =>
        {
            const winners = wonDetail?.data?.map(elemnt => {
				return elemnt.residence_total_mkv
			})

            const uniqMkv = [...new Set(winners)]

            const residences = uniqMkv.map(el => {
                return {
                    residence: el,
                    count: winners.filter(e => e === el).length,
                    users: wonDetail.data.filter(
                        (data, index) =>
                        {
                            if(data.residence_total_mkv === el)
                            {
                                return data
                            }
                        }
                    )
                }
            })

            return residences.map(
                (res, idx) =>
                {
                    return (
						<Fragment key={idx}>
							<section
								key={idx}
								onClick={() => onShowList(idx)}
								className={`d-flex align-items-center justify-content-between me-3 w-100 ${css.winnerItem}`}
                                style={{
                                    backgroundColor: showList.includes(idx) && '#F5F6FA'
                                }}
                            >
								<div className="d-flex">
									<div className={css.iconExpress2} style={{ color: "white", borderRadius: 0, fontSize: "13px" }}>
										{res.residence}мкв
									</div>
									-тай байрнаас
									<span className={css.total_winner}>{res.count} хүн ялсан</span>
								</div>
								<div
									className="float-end"
									style={{ transform: showList.includes(idx) ? "rotate(180deg)" : "rotate(0)", transition: "0.2s" }}>
									<i className={`far fa-angle-down`}></i>
								</div>
							</section>
							<div style={{ padding: "10px", paddingTop: 0 }}>
								<div
									className={`${css.winnersList} ${!showList.includes(idx) && css.hideBottomBorder}`}
									style={{ maxHeight: showList.includes(idx) ? wonDetail.data.length * 25 + "px" : 0 }}
                                >
									{res.users.map((data, index) => {
										if (data.residence_total_mkv === res.residence) {
											return (
												<div key={index} className={css.oneWinner}>
													{index + 1}. {data.user.last_name} овогтой {data.user.first_name}
												</div>
											)
										}
									})}
								</div>
							</div>
						</Fragment>
					)
                }
            )
        },
        [wonDetail, showList]
    )

    /** Countdown дууссан  бол захиалга хийх component-ийг харуулна*/
    const complete = useMemo(
        () =>
        {
            return (
                <Row>
                    <Col md={6} xs={{ order: 2 }}>
                        <label className={`${css.headTop}`}>{rows.name} <span className={css.totalWinner}>{rows.count} / {rows.user_count}</span></label>
                        <CTable
                            isLoading={isLoading}
                            columns={columns}
                            options={
                                {
                                    tableOuterClassName: css.noHeight
                                }
                            }
                            rows={rows.data}
                            sequenceColumn={sequenceColumn}
                        />
                    </Col>
                    <Col md={6} xs={{ order: 1 }}>
                        <Select
                            label={'Зорилтот бүлэг сонгох'}
                            onChange={handleChange}
                            options={dataToValueLabel(zoriltotGroups, `code`, `name`)}
                            value={selectedBuleg}
                            placeholder={"Бүлэг сонгох"}
                        />
                        <div className={`${css.description} mt-3 mb-3 d-flex`}>
                            <div className="me-2">📣</div>
                            <div>"{name}" сонгон шалгаруулалт {timeZoneToDateString(endDate)}-нд амжилттай явагдаж дууссан. Шалгарсан хүмүүсийн мэдээллийг бүлгээ сонгоод хүснэгтээс харна уу.</div>
                        </div>
                        {displayMkvWinner}
                    </Col>
                </Row>
            )
        },
        [rows, columns]
    )

    useEffect(
        () =>
        {
            const date = new Date();
            const offset = date.getTimezoneOffset();
            if(offset * -1/60 !== 8)
            {
                setDiffrence(8 - ((offset * (-1)) / 60))
            }
        },
        []
    )

    /** countDown дуусаагүй үед countdown харуулна */
    const inComplete = ( days ,hours, minutes, seconds ) => (
        <Col md={12}>
            <div className='text-center fs-13 text-black-50 mb-3'>{title}</div>
            <div className={css.countContainer}>
                <div id={css.clockdiv}>
                    <div>
                        <span>{days}</span>
                        <div className={css.smalltext}>Өдөр</div>
                    </div>
                    <div>
                        <span> {hours}</span>
                        <div className={css.smalltext}>Цаг</div>
                    </div>
                    <div>
                        <span> {minutes}</span>
                        <div className={css.smalltext}>Минут</div>
                    </div>
                    <div>
                        <span> {seconds}</span>
                        <div className={css.smalltext}>Секунд</div>
                    </div>
                </div><br/>
            </div>
        </Col>
    )

    /** countdown дууссан эсэхийг шалгаж буй */
    const renderer = ({ days, hours, minutes, seconds, completed }) =>
    {
        if (completed) return complete;
        else return inComplete(days, hours, minutes, seconds,);
    };

    return (
        <Countdown
            date={end_date - diffrence * OneHourMS}
            renderer={renderer}
        />
    );
}

export default LotteryCountDown;
