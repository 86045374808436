import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';
import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { parkingtypeValidate } from 'utils/validate';


const ParkingTypeAction = () => {
    const [ inputValues, setInputValues ] = useState({});

    const navigate = useNavigate();
    const { id } = useParams()

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const parkingtypeApi = useApi().parkingtype

    /** create бол true, update бол false буцаана */
    const isCreate = id === 'create'

    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data } = await fetchData(parkingtypeApi.getOne(id))
                if (success)
                {
                    setInputValues(data)
                }
            }
        },
        []
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        if (isCreate)
        {
            const { errors, success, data } = await fetchData(parkingtypeApi.post(formData)).catch(error => error)
            if (success) return navigate(-1)
            else return errors
        }
        const { errors, success, data } = await fetchData(parkingtypeApi.put(formData, id)).catch(error => error)
        if (success) navigate(-1)
        else return errors
    }

    /** Form утгууд */
    const form = {
        inputs: [
            {
                inputType: "input",
                registerName: "name",
                type: "text",
                inputGroup: {
                    start: <i className="fas fa-parking"></i>,
                },
                disabled: isLoading,
                colMd: 12
            },
        ],
        button: {
            onSubmit: async (data) =>{
                return btnOnSubmit(data);
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? "Үүсгэх" : "Хадгалах",
        },
        validate: {
            validateMode: "all",
            yupValidate: parkingtypeValidate,
        },
    };
    return (
        <BoxBackground title={isCreate ? 'Зогсоолын төрөл үүсгэх' : 'Зогсоолын төрөл засах'} body={<CForm inputValues={inputValues} form={form}/>}/>
    );
}

export default ParkingTypeAction;
