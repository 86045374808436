import { useEffect, useRef } from 'react'

import useMapContext from 'hooks/useMapContext'

import css from '../../style.module.css'

export default function PriceMinMax({ fns })
{

    const { allFilters } = useMapContext()

    const minRef = useRef()
    const maxRef = useRef()

    useEffect(
        () =>
        {
            fns.price = {
                minRef,
                maxRef
            }
        },
        []
    )

    const handleChange = () =>
    {
        allFilters['price_min'] = minRef.current.valueAsNumber
        allFilters['price_max'] = maxRef.current.valueAsNumber
    }

    return (
        <div className={css.choiceContainer}>
            <h6>Дээд доод үнэ</h6>
            <div className='d-flex align-items-center'>
                <input ref={minRef} type="number" className='form-control me-1' placeholder='доод' onChange={handleChange}/>
                ~
                <input ref={maxRef} type="number" className='form-control me-1' placeholder='дээд' onChange={handleChange}/>
                m<sup>2</sup>
            </div>
        </div>
    )
}
