import React, { useEffect, useState } from 'react'

import MainNavLink from 'components/main/NavLink'

import { addPropsToComponent } from 'utils'

/** Газрын зургийн menu ний нэг item */
export default function MapMenuItem({ containerStyle, onClick, image, icon, title, titleStyle, children, to, active, setActive, isToggler=false, childId, activeMenu, singleOpen })
{
    const [ openSubMenu, setActiveSub ] = useState(false)

    /** Menu нь өөрөө дотроо sub menu тэй эсэх */
    const hasChildren = !!children
    const menuOpen = singleOpen ? activeMenu : openSubMenu

    /** menu дээр дарахад ажиллах функц */
    const handleClick = () =>
    {
        if(!active && !onClick && to === '/')
        {
            setActive(true)
        }

        if(isToggler && !active && openSubMenu && !singleOpen)
        {
            setActiveSub(false)
        }

        if(!active)
            setActive && setActive(true)

        /** props оор onClick гэж байвал ажиллуулана */
        if (onClick)
            onClick()

        /** submenu тэй байвал доторх menu -г toggle хийх нь */
        if (hasChildren && !singleOpen)
            setActiveSub(!openSubMenu)

    }

    // Map menu сунгагдах үед
    useEffect(
        () =>
        {
            // Map menu-г хураах үед submenu-г хураана
            if(!active && !singleOpen) setActiveSub(false)
        },
        [active]
    )

    const childElement = () =>
    {
        return (
            <>
                {
                    icon
                    ?
                        <span className="icon"><i className={icon}></i></span>
                    :
                        <span className="icon"><img src={image} alt={"logo"}/></span>
                }
                <span style={titleStyle} className="title">{title}</span>
                {
                    hasChildren
                    &&
                        //  арын доошоо харсан сум
                        <div  style={{zIndex: '0'}} className={`fa ${menuOpen ? "fa-circle-minus" : "fa-circle-plus"} right-icon`}></div>
                }
            </>
        )
    }

    return (
        <li
            style={containerStyle}
            className={hasChildren ? "sub-menu" : ""}
        >
            {
                to
                ?
                    <MainNavLink to={to} className="toggle" onClick={handleClick}>
                        {childElement()}
                    </MainNavLink>
                :
                    <a className='toggle' onClick={handleClick}>
                        {childElement()}
                    </a>
            }
            {
                hasChildren && true
                ?
                    <ul style={{background: '#fff'}} id={childId} className={menuOpen ? 'subPadding' : 'subPaddingClosing'}>
                        {addPropsToComponent(children, { isOpen: menuOpen })}
                    </ul>
                :
                    null
            }
        </li>
    )
}
