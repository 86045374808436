import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import ProgramBody from './ProgramBody';

const ProgramDetail = () => {

    const { lotteryId, hutulburId } = useParams()
    const [ program, setProgram ] = useState({});
    const songonApi = useApi().songon
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })

    useEffect(
        async () =>
        {
            if(lotteryId)
            {
                const { success, data, errors }  = await fetchData(songonApi.getHutulbur(lotteryId)).catch( err => err )
                if(success)
                {
                    setProgram(data[0])
                }
            }
            if(hutulburId)
            {
                const { success, data, errors }  = await fetchData(songonApi.getHutulburToHutulbur(hutulburId)).catch( err => err )
                if(success)
                {
                    setProgram(data[0])
                }
            }
        },
        []
    )

    return (
		<Container>
			<Row className={lotteryId && "skipHeader"}>
				<Col>
                {
                    isLoading
                    ?
                        <div style={{ marginTop: '10vh' }}>
                            {Loader}
                        </div>
                    :
					    <BoxBackground title="Хөтөлбөр" body={<ProgramBody data={program} />} />
                }
				</Col>
			</Row>
		</Container>
	)
}

export default ProgramDetail;
