import { useNavigate } from 'react-router-dom';
import axios from 'axios'

import { rspText } from 'utils/request'

import useAuth from 'hooks/useAuth';
import useToast from 'hooks/useToast'

import { setOptions } from "utils";
import { config as imageConfig, objectToQuery } from 'utils/browser'

/** API дээр хийгдэх тохиргоо */
const mainOptions =
{
    isDisplayToast: true // алдаа юм уу мэдэгдэл ирхэд toast харуулах эсэх
}
export default function useApi(config={})
{
	const navigate = useNavigate()
    /** тохиргоо */
    const options = setOptions(mainOptions, config)

    const auth = useAuth()
    /** Toast нэмэх */
    const { addToast } = useToast()

    const source = axios.CancelToken.source()
    const instance = axios.create(
    {
        baseURL: process.env.REACT_APP_SERVER_URL,
        withCredentials: true, // Хүсэлт болгонд ээр cookie явуулах нь
        cancelToken: source.token,
		xsrfHeaderName: 'X-CSRFTOKEN',
		xsrfCookieName: 'csrftoken',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    });

    /**
     * Axios ийн response датанаас
     * миний back аас ирсэн data -г л буцаадаг болсон
     */
    instance.interceptors.response.use(
        function (rsp)
        {
            const data = rsp.data
            //  хэрвээ хүсэлт амжилттай болоод тэр нь info буцааж байх юм бол toast гаргах
            if (typeof data?.success === 'boolean' && data?.info)
            {
                /** Хүсэлтийн хариуг харуулах нь */
                if (options.isDisplayToast)
                {
                    const text = rspText(data.info)
                    addToast(
                        {
                            text,
                            time: 3000,
                            type: "success",
                        }
                    )
                }
            }
            return data;
        },
        function (err)
        {
            if(err.message === 'cancel')
            {
                return
            }

            let error = err?.response?.data

            /** хүсэлт явахгүй алдаа гарсан бол backend асуудалтай болсон байнаа гэж үзээд алдааны toast харуулах */
            if (error?.success === undefined)
            {
                error =
                {
                    success: false,
                    error:
                    {
                        message: "Сервер дээр алдаа гарсан байна",
                        name: "ERROR 004",
                        code: 4,
                    }
                }
            }

			if(error?.error?.code === 403)
			{
				navigate("/")
			}

            // Нэвтрэх эрх нь дууссан байвал гаргах
            if (error?.error?.code === 6)
            {
                auth && auth.signOut(false)
            }

            /** Алдааны messege ийг харуулах нь */
            if (error?.error?.message)
            {
                if (options.isDisplayToast)
                {
                    /** Хүсэлтийн хариуг харуулах нь */
                    const text = rspText(error.error, true)
                    addToast({
                        text,
                        time: 3000,
                        type: "error",
                    })
                }
            }
            else if (typeof(error?.error) === "string")
            {
                if (options.isDisplayToast)
                {
                    /** Хүсэлтийн хариуг харуулах нь */
                    addToast({
                        text: error?.error,
                        time: 3000,
                        type: "error",
                    })
                }
            }

            return Promise.reject(error);
        }
    );

    const dataTableQuerys = (page, limit, sort, match, search) =>
    {
		let text = objectToQuery(match)
		let searchQuery = objectToQuery(search)
        const field = sort?.field === 'sequence' ? 'id' : sort?.field
        return `page=${page}&limit=${limit}&sort=${sort?.type || ""}&sort_field=${field || ""}${text}${searchQuery}`
    }

	const newsSortQeury = (sort, sortField) =>
	{
		if(sort !== '')
		{
			return `&sort=${sort}&sort_field=${sortField}`
		}
		return ''
	}

	const newsSkipLimitQeury = (skip, limit) =>
	{
		if(typeof skip === 'number' && typeof limit === 'number')
		{
			return `skip=${skip}&limit=${limit}`
		}
		return ''
	}

	const newsIsSpecialQuery = (isSpecial='') =>
	{
		if(typeof isSpecial === 'boolean')
		{
			return `&is-special=${isSpecial ? 'True' : 'False'}`
		}
		return ''
	}

	// 15800000000 = 6 sar
	const analytacQuery = (has_data=0, start_time=Date.now() - 15800000000, end_time=Date.now(), sort='asc') =>
	{
		return `has_data=${has_data}&start_time=${start_time}&end_time=${end_time}&sort=${sort}`
	}

	// 15800000000 = 6 sar
	const accessQuery = ( start_date=Date.now() - 15800000000, end_date=Date.now(), field_name, order='asc' ) =>
	{
		return `start_date=${start_date}&end_date=${end_date}&field_name=${field_name}&order=${order}`
	}

	const createQuery = (key, value) =>
	{
		if( key && value ) return `&${key}=${value}`
		return ''
	}


	return {
		source,
		instance,
		gov: {
			get: () => instance.get(`/govorg/`),
			getOne: id => instance.get(`/govorg/${id}/`),
			post: data => instance.post(`/govorg/`, data),
			put: (data, id) => instance.put(`/govorg/${id}/`, data),
			delete: id => instance.delete(`/govorg/${id}/`),
		},

		map: {
			get: () => instance.get(`/sdi/`),
			getApartments: (querys = "") => instance.get(`/apartments/?${querys}`),
			getBuildings: (apartmentId, querys = "") => instance.get(`apartment-${apartmentId}-buildings/${querys}`),
			search: {
				getProjects: () => instance.get(`/api/projects/`),
				stepProjects: (query) => instance.get(`/project-step/${query ? `?${query}` : ""}`)
			},
			old: {
				getAparts: () => instance.get(`/company/old/map-apartments/`),
				getBuilds: apartId => instance.get(`/company/old/apartment/${apartId}/all-buildings/`),
			},
		},

		program: {
			get: (...args) => instance.get(`/govorg/hutulbur/?${dataTableQuerys(...args)}`),
			getAll: () => instance.get(`/govorg/all-hutulbur/`),
			getOne: id => instance.get(`/govorg/hutulbur/${id}/`),
			post: data => instance.post(`/govorg/hutulbur/`, data),
			put: (data, id) => instance.put(`/govorg/hutulbur/${id}/`, data),
			delete: id => instance.delete(`/govorg/hutulbur/${id}/`),
		},

		budgetType: {
			get: () => instance.get("/govorg/bugdettype/"),
			getOne: id => instance.get(`/govorg/bugdettype/${id}/`),
			post: data => instance.post(`/govorg/bugdettype/`, data),
			put: (data, id) => instance.put(`/govorg/bugdettype/${id}/`, data),
			delete: id => instance.delete(`/govorg/bugdettype/${id}/`),
		},

		company: {
			get: () => instance.get("/company/get/all/"),
			getPagination: (...args) => instance.get(`/company/?${dataTableQuerys(...args)}`),
			post: data => instance.post(`/company/admin/`, data),
			put: (id, data) => instance.put(`/company/${id}/`, data),
			getPublic: () => instance.get(`/company/public/`),
			materialUsers: (...args) => instance.get(`/material/admin/?${dataTableQuerys(...args)}`),
			materialAdmin: (body) => instance.post(`/material/admin/`, body),
			v2: {
				post: data => instance.post(`/company/admin/v2/`, data),
			},
			companies: (...args) => instance.get(`/company/companies/?${dataTableQuerys(...args)}`),
			shortDetail: (id) => instance.get(`/company/short-detail/${id}/`),
			users: (companyId, ...args) => instance.get(`/company/users-${companyId}/?${dataTableQuerys(...args)}`),

			getAll: () => instance.get("/company/get/all/"),


			barilgaTorolByZoriulalt: (pk) => instance.get(`/company/barilga-torol/${pk}/`),
			barilgaZoriulaltByTorol: (pk) => instance.get(`/company/barilga-zoriulalts-by-torol/${pk}/`),
			measurementByBarZoriulalt: (pk) => instance.get(`/company/measurement-by-torol/${pk}/`),
			construction: () => instance.get(`/company/construction/`),
			getBarilgaService: (data) => instance.post("/company/get-barilga-service/", data),
			createBarilgaService: (data) => instance.post("/company/create-barilga-service/", data),
			getBarilgaAjlinTorol: () => instance.get(`/company/barilga-ajlin-torol/`),
			getBarilgaBaiguulagchAllList: () => instance.get(`/company/barilga-baiguulamj-ashiglagch-torol/`),
		},

		projectCompanyDetails: {
			get: () => instance.get(`company/project-company-details/`),
		},

		projectCompanies: {
			get: (...args) => instance.get(`company/projectcompanies/?${dataTableQuerys(...args)}`),
			getOne: id => instance.get(`/company/projectcompanies/${id}/`),
			post: data => instance.post(`/company/projectcompanies/`, data),
			put: (data, id) => instance.put(`/company/projectcompanies/${id}/`, data),
			delete: id => instance.delete(`/company/projectcompanies/${id}/`),
		},

		projectLinkedSubAgenda: {
			get: () => instance.get(`/company/sub-agenda/`),
		},

		employee: {
			get: (companyId, ...args) => instance.get(`/company/${companyId}/employee/?${dataTableQuerys(...args)}`),
			staff: {
				post: body => instance.post(`/company/staff/`, body),
			},
		},

		agenda: {
			get: hutulburId => instance.get(`/govorg/hutulbur/${hutulburId}/agenda/`),
			getOne: (hutulburId, id) => instance.get(`/govorg/hutulbur/${hutulburId}/agenda/${id}/`),
			post: (hutulburId, data) => instance.post(`/govorg/hutulbur/${hutulburId}/agenda/`, data),
			put: (hutulburId, data, id) => instance.put(`/govorg/hutulbur/${hutulburId}/agenda/${id}/`, data),
			delete: (hutulburId, id) => instance.delete(`/govorg/hutulbur/${hutulburId}/agenda/${id}/`),
		},

		subAgenda: {
			get: (projectId, agendaId) => instance.get(`/govorg/hutulbur/${projectId}/agenda/${agendaId}/subagenda/`),
			post: (projectId, agendaId, data) => instance.post(`/govorg/hutulbur/${projectId}/agenda/${agendaId}/subagenda/`, data),
			getOne: (projectId, agendaId, id) => instance.get(`/govorg/hutulbur/${projectId}/agenda/${agendaId}/subagenda/${id}/`),
			put: (projectId, agendaId, id, data) => instance.put(`/govorg/hutulbur/${projectId}/agenda/${agendaId}/subagenda/${id}/`, data),
			delete: (projectId, agendaId, id) => instance.delete(`/govorg/hutulbur/${projectId}/agenda/${agendaId}/subagenda/${id}/`),
			getProjects: () => instance.get(`/govorg/sub-agenda/projects/`),
		},

		allSales: {
			get: () => instance.get("/company/all-sales/"),
		},

		licenseType: {
			get: () => instance.get("/company/license-type/"),
			getOne: id => instance.get(`/company/license-type/${id}/`),
			post: data => instance.post(`/company/license-type/`, data),
			put: (data, id) => instance.put(`/company/license-type/${id}/`, data),
			delete: id => instance.delete(`/company/license-type/${id}/`),
			getPagination: (...args) => instance.get(`/company/license-type/pagination/?${dataTableQuerys(...args)}`),
		},

		sales: {
			get: (...args) => instance.get(`/company/sales/?${dataTableQuerys(...args)}`),
			getOne: id => instance.get(`/company/sales/${id}/`),
			post: data => instance.post(`/company/sales/`, data),
			put: (data, id) => instance.put(`/company/sales/${id}/`, data),
			delete: id => instance.delete(`/company/sales/${id}/`),
		},

		doortype: {
			get: () => instance.get(`/company/doortype/`),
			getOne: id => instance.get(`/company/doortype/${id}/`),
			post: data => instance.post(`/company/doortype/`, data),
			put: (data, id) => instance.put(`/company/doortype/${id}/`, data),
			delete: id => instance.delete(`/company/doortype/${id}/`),
		},

		windowtype: {
			get: () => instance.get(`/company/windowtype/`),
			getOne: id => instance.get(`/company/windowtype/${id}/`),
			post: data => instance.post(`/company/windowtype/`, data),
			put: (data, id) => instance.put(`/company/windowtype/${id}/`, data),
			delete: id => instance.delete(`/company/windowtype/${id}/`),
		},

		parkingtype: {
			getParkingType: () => instance.get(`/company/parkingtypes/`),
			get: () => instance.get(`/company/parkingtype/`),
			getOne: id => instance.get(`/company/parkingtype/${id}/`),
			post: data => instance.post(`/company/parkingtype/`, data),
			put: (data, id) => instance.put(`/company/parkingtype/${id}/`, data),
			delete: id => instance.delete(`/company/parkingtype/${id}/`),
		},

		propertyType: {
			getPropertyType: () => instance.get(`/company/property-type/`),
			get: () => instance.get(`/company/property-types/`),
			getOne: id => instance.get(`/company/property-types/${id}/`),
			post: data => instance.post(`/company/property-types/`, data),
			put: (data, id) => instance.put(`/company/property-types/${id}/`, data),
			delete: id => instance.delete(`/company/property-types/${id}/`),
		},

		barilgaZoriulalt: {
			getBarilgaZoriulalts: () => instance.get(`/company/barilga-zoriulalts/`),
		},

		roomType: {
			get: () => instance.get(`/company/room-type/`),
			getOne: roomTypeId => instance.get(`/company/room-type/${roomTypeId}/`),
			post: data => instance.post(`/company/room-type/`, data),
			put: (data, roomTypeId) => instance.put(`/company/room-type/${roomTypeId}/`, data),
			delete: roomTypeId => instance.delete(`/company/room-type/${roomTypeId}/`),
		},

		project: {
			getAll: () => instance.get(`/company/project/all/`),
			get: (...args) => instance.get(`/company/project/?${dataTableQuerys(...args)}`),
			programOfget: (programId, ...args) => instance.get(`/company/${programId}-project/?${dataTableQuerys(...args)}`),
			getOne: id => instance.get(`/company/project/${id}/`),
			post: data => instance.post(`/company/project/`, data, config),
			put: (id, data) => instance.put(`/company/project/${id}/`, data, config),
			delete: id => instance.delete(`/company/project/${id}/`),
			map: id => instance.get(`/company/project/${id}/geojson/`),
			onmap: {
				get: () => instance.get(`/company/map/projects/`),
				getOne: projectId => instance.get(`/company/map/project/${projectId}/`),
			},
		},

		sign: {
			in: body => instance.post(`/account/login/`, body),
			out: () => instance.post(`/account/logout/`),
			dan: body => instance.post(`/account/login/dan/`, body),
		},

		user: {
			all: (...args) => instance.get(`/account/user/all/?${dataTableQuerys(...args)}`), // бүх хэрэглэгчийн жагсаалтыг авах
			getProfile: () => instance.get(`/account/user/`), // нэвтэрч орсон хэрэглэгчийн мэдээллийг авах
			getOne: userId => instance.get(`/account/user/${userId}/`), // тухайн ID тай хэрэглэгчийн мэдээллийг авах нь,
			mail: {
				sendMail: body => instance.post(`/account/mail/`, body),
				sendNumber: body => instance.post(`/account/changemail/`, body),
			},
			superuser: {
				post: body => instance.post(`/account/superuser/`, body),
				get: (...args) => instance.get(`/account/superuser/?${dataTableQuerys(...args)}`),
			},
			role: {
				update: (userRoleId, body) => instance.put(`/account/userrole/${userRoleId}/`, body), // role ийг update хийдэг api
			},
			password: {
				sendMail: body => instance.post(`/account/password/`, body),
				sendNumber: body => instance.post(`/account/checkpassword/`, body),
				sendPasswords: body => instance.post(`/account/changepassword/`, body),
			},
			name: {
				changeName: body => instance.put(`/account/user/update/`, body),
			},
			edit: {
				edit: (body) => instance.put('/account/edit-user/', body),
				get: (id) => instance.get(`/account/edit-user/${id}/`),
			}
		},

		building: {
			get: (id, apartId, ...args) => instance.get(`/company/project/${id}/apartment/${apartId}/building/?${dataTableQuerys(...args)}`),
			getOne: (id, apartId, buildingId) => instance.get(`/company/project/${id}/apartment/${apartId}/building/${buildingId}/`),
			post: (id, apartId, data) => instance.post(`/company/project/${id}/apartment/${apartId}/building/`, data),
			put: (id, apartId, buildingId, data) => instance.put(`/company/project/${id}/apartment/${apartId}/building/${buildingId}/`, data),
			delete: (id, apartId, buildingId) => instance.delete(`/company/project/${id}/apartment/${apartId}/building/${buildingId}/`),
			allInfo: (apartId, residenceId) => instance.get(`/residence/${apartId}/${residenceId}/`),
			updateMany: (body) => instance.put(`/customer/building/update-all/`, body),
			buildingImages: {
				get: (projecId, buildingId) => instance.get(`/company/project/${projecId}/building/${buildingId}/buildingimages/`),
				post: (projecId, buildingId, body) => instance.post(`/company/project/${projecId}/building/${buildingId}/buildingimages/`, body),
				put: (projecId, buildingId, id, data) =>
					instance.put(`/company/project/${projecId}/building/${buildingId}/buildingimages/${id}/`, data),
				delete: (projecId, buildingId, id) => instance.delete(`/company/project/${projecId}/building/${buildingId}/buildingimages/${id}/`),
			},
			getSongon: resId => instance.get(`/residence/songon/${resId}/`),
		},

		residence: {
			getAll: (id, apartId) => instance.get(`/company/project/${id}/apartment/${apartId}/all-residence/`),
			get: (id, ...args) => instance.get(`/company/project/${id}/residence/?${dataTableQuerys(...args)}`),
			getOne: (id, resId) => instance.get(`/company/project/${id}/residence/${resId}/`),
			post: (id, data) => instance.post(`/company/project/${id}/residence/`, data),
			put: (id, resId, data) => instance.put(`/company/project/${id}/residence/${resId}/`, data),
			delete: (id, resId) => instance.delete(`/company/project/${id}/residence/${resId}/`),
			room: {
				get: (projectId, residenceId) => instance.get(`/company/project/${projectId}/residence/${residenceId}/roomchoice/`),
				post: (projectId, residenceId, body) => instance.post(`/company/project/${projectId}/residence/${residenceId}/roomchoice/`, body, config),
				getOne: (projectId, residenceId, id) => instance.get(`/company/project/${projectId}/residence/${residenceId}/roomchoice/${id}/`),
				delete: (projectId, residenceId, id) => instance.delete(`/company/project/${projectId}/residence/${residenceId}/roomchoice/${id}/`),
				put: (projectId, residenceId, id, body) =>
					instance.put(`/company/project/${projectId}/residence/${residenceId}/roomchoice/${id}/`, body, config),
			},
		},

		apartmentsdi: {
			get: projectId => instance.get(`/company/project/${projectId}/apartmentsdi/`),
			post: (projectId, apartId, body) => instance.post(`/company/project/${projectId}/apartment/${apartId}/sdi/`, body),
			getResetBuliding : () => instance.get('/company/apartmentsdi-reset-geom/'),
		},

		apartment: {
			getAll: projectId => instance.get(`/company/project/${projectId}/all-apartment/`),
			get: (projectId, ...args) => instance.get(`/company/project/${projectId}/apartment/?${dataTableQuerys(...args)}`),
			getOne: (projectId, id) => instance.get(`/company/project/${projectId}/apartment/${id}/`),
			post: (projectId, data, config) => instance.post(`/company/project/${projectId}/apartment/`, data, config),
			put: (projectId, id, data, config) => instance.put(`/company/project/${projectId}/apartment/${id}/`, data, config),
			delete: (projectId, id) => instance.delete(`/company/project/${projectId}/apartment/${id}/`),
			duplicate: (projectId, apartmentId, body) => instance.post(`/company/project/${projectId}/apartment-${apartmentId}-duplicate/`, body),
			createdAparts: projectId => instance.get(`/company/project/${projectId}/apartment-created/`),
			getApartName: apartId => instance.get(`/company/apartment-name/${apartId}/`),

			getUseType: () => instance.get(`/company/usetype/`),
			getLiftType: () => instance.get(`/company/lifttype/`),
			getMenu: () => instance.get(`/company/menu/`),
			getDohiololType: () => instance.get(`/company/dohiololtype/`),

			setChange: (body) => instance.put(`/company/apartments/change/`, body),

			borluulalt: {
				get: (projectId, apartmentId, ...args) =>
					instance.get(
						`/company/project/${projectId}/apartment/${apartmentId}/borluulalt/?${dataTableQuerys(...args)}`,
					),
				getOne: (projectId, apartmentId, borluulaltId) =>
					instance.get(`/company/project/${projectId}/apartment/${apartmentId}/borluulalt/${borluulaltId}/`),
				post: (projectId, apartmentId, body) =>
					instance.post(`/company/project/${projectId}/apartment/${apartmentId}/borluulalt/`, body),
			},
		},

		advantage: {
			get: () => instance.get("/company/advantage/"),
			getOne: adId => instance.get(`/company/advantage/${adId}/`),
			create: body => instance.post(`/company/advantage/`, body),
			update: (adId, body) => instance.put(`/company/advantage/${adId}/`, body),
			delete: adId => instance.delete(`/company/advantage/${adId}/`),
		},

		apartLocation: {
			get: projectID => instance.get(`/company/project/${projectID}/apartmentlocation/`),
			getOne: (projectID, apLoId) => instance.get(`/company/project/${projectID}/apartmentlocation/${apLoId}/`),
			create: (projectID, body) => instance.post(`/company/project/${projectID}/apartmentlocation/`, body),
			update: (projectID, apLoId, body) => instance.put(`/company/project/${projectID}/apartmentlocation/${apLoId}/`, body),
			delete: (projectID, apLoId) => instance.delete(`/company/project/${projectID}/apartmentlocation/${apLoId}/`),
		},

		orgType: {
			get: () => instance.get(`/govorg/orgtype/`),
			getOne: orgTypeId => instance.get(`/govorg/orgtype/${orgTypeId}/`),
			create: body => instance.post(`/govorg/orgtype/`, body),
			update: (orgTypeId, body) => instance.put(`/govorg/orgtype/${orgTypeId}/`, body),
			delete: orgTypeId => instance.delete(`/govorg/orgtype/${orgTypeId}/`),
		},
		activity: {
			get: () => instance.get(`/govorg/activity/`),
			post: body => instance.post(`/govorg/activity/`, body),
			getOne: id => instance.get(`/govorg/activity/${id}/`),
			delete: id => instance.delete(`/govorg/activity/${id}/`),
			put: (body, id) => instance.put(`/govorg/activity/${id}/`, body),
		},

		songon: {
			admin: {
				projects: () => instance.get(`/company/projects-songon/`),
				pagination: (projectId, ...args) => instance.get(`/govorg/project/${projectId}/songon/?${dataTableQuerys(...args)}`),
				residences: (projectId, isCreate) => instance.get(`/company/project/${projectId}/residences/${isCreate ? "create" : "update"}/`),
				update: (songonId, body) => instance.put(`/songon/shalgaruulalt/${songonId}/`, body),
				create: body => instance.post(`/songon/shalgaruulalt/`, body),
			},
			getBulegOfHutulbur: projectId => instance.get(`/govorg/project/${projectId}/zoriltotbuleg/`),
			postUserDetail: body => instance.post(`/songon/songonshalgaruulaltuserdetail/`, body),
			postOrgodol: body => instance.post(`/songon/songonshalgaruulaltuser/`, body),
			getHutulburToHutulbur: id => instance.get(`/songon/hutulbur/${id}/`),
			getHutulburs: bulegId => instance.get(`/songon/zoriltot/${bulegId}/`),
			getHutulbur: id => instance.get(`/songon/${id}/hutulbur/`),
			getOne: id => instance.get(`/songon/shalgaruulalt/${id}/`),
			delete: id => instance.delete(`/songon/shalgaruulalt/${id}/`),
			getFamily: () => instance.get(`/songon/family/`),
			postFamily: (body) => instance.post(`/songon/chooseonefamily/`, body),
			memberComfirm: (body) => instance.post(`/songon/confirmbutetstype/`, body),
			requestStatus: (body) => instance.post(`/songon/chooseonefamilystatus/`, body),
			salaryPdf: (body) => instance.post(`/songon/chooseonefamilysalarypdf/`, body),
			get: (state, skip, limit, unit1_code, unit2_code, unit3_code) =>
				instance.get(
					`/songon/shalgaruulalt/?is_end=${state ? "True" : "False"}&skip=${skip}&limit=${limit}${createQuery(
						"unit1_code",
						unit1_code,
					)}${createQuery("unit2_code", unit2_code)}${createQuery("unit3_code", unit3_code)}`,
				),
			oldSongon: {
				winners: (songonId, body) => instance.post(`/songon/${songonId}/songonshalgaruulaltuser/iswon/`, body),
			},
		},

		unit: {
			get: () => instance.get("/unit/"),
			getList:  () => instance.get("/unit-list/"),
			findUnit: body => instance.post(`/api/find-unit/`, body),
		},

		zorilgotBuleg: {
			getAll: () => instance.get("/govorg/zoriltot-buleg/"),
		},

		buildingTable: {
			buildingOfApartment: (apartmentId, code) => instance.get(`/customer/apartment/${apartmentId}/building/${code}/`),
			mapOldApartmentGeoIds: code => instance.get(`/customer/apartment/${code}/`),
			userData: (body) => instance.post(`/customer/xyp/`, body),
			"buy-sell": {
				getOne: id => instance.get(`/customer/building-buy-sell/${id}/`),
				get: (...args) => instance.get(`/customer/building-buy-sell/?${dataTableQuerys(...args)}`),
				post: body => instance.post(`/customer/building-buy-sell/`, body),
				delete: id => instance.delete(`/customer/building-buy-sell/${id}/`),
				put: (body, id) => instance.put(`/customer/building-buy-sell/${id}/`, body),
				getBuildingsOld: geo_id => instance.get(`/customer/building-buy-sell/geo/${geo_id}/`),
				getBuildingsNew: apartId => instance.get(`/customer/building-buy-sell/new/${apartId}/`),
			},
			turees: {
				getOne: id => instance.get(`/customer/building-turees/${id}/`),
				get: (...args) => instance.get(`/customer/building-turees/?${dataTableQuerys(...args)}`),
				post: body => instance.post(`/customer/building-turees/`, body),
				delete: id => instance.delete(`/customer/building-turees/${id}/`),
				put: (body, id) => instance.put(`/customer/building-turees/${id}/`, body),
				getBuildingsOld: geo_id => instance.get(`/customer/building-turees/geo/${geo_id}/`),
				getBuildingsNew: apartId => instance.get(`/customer/building-turees/new/${apartId}/`),
			},
			zassan: {
				getOne: id => instance.get(`/customer/building-zasaj-zarsan/${id}/`),
				get: (...args) => instance.get(`/customer/building-zasaj-zarsan/?${dataTableQuerys(...args)}`),
				post: body => instance.post(`/customer/building-zasaj-zarsan/`, body),
				delete: id => instance.delete(`/customer/building-zasaj-zarsan/${id}/`),
				put: (body, id) => instance.put(`/customer/building-zasaj-zarsan/${id}/`, body),
				getBuildingsOld: geo_id => instance.get(`/customer/building-zasaj-zarsan/geo/${geo_id}/`),
				getBuildingsNew: apartId => instance.get(`/customer/building-zasaj-zarsan/new/${apartId}/`),
			},
			menegment: {
				getOne: id => instance.get(`/customer/building-menegment/${id}/`),
				get: (...args) => instance.get(`/customer/building-menegment/?${dataTableQuerys(...args)}`),
				post: body => instance.post(`/customer/building-menegment/`, body),
				delete: id => instance.delete(`/customer/building-menegment/${id}/`),
				put: (body, id) => instance.put(`/customer/building-menegment/${id}/`, body),
				getBuildingsOld: geo_id => instance.get(`/customer/building-menegment/geo/${geo_id}/`),
				getBuildingsNew: apartId => instance.get(`/customer/building-menegment/new/${apartId}/`),
			},
			busad: {
				getOne: id => instance.get(`/customer/building-busad/${id}/`),
				get: (...args) => instance.get(`/customer/building-busad/?${dataTableQuerys(...args)}`),
				post: body => instance.post(`/customer/building-busad/`, body),
				delete: id => instance.delete(`/customer/building-busad/${id}/`),
				put: (body, id) => instance.put(`/customer/building-busad/${id}/`, body),
				getBuildingsOld: geo_id => instance.get(`/customer/building-busad/geo/${geo_id}/`),
				getBuildingsNew: apartId => instance.get(`/customer/building-busad/new/${apartId}/`),
			},
			"zohih-guilgee": {
				getOne: id => instance.get(`/customer/medbel-zohih-guilgee/${id}/`),
				get: (...args) => instance.get(`/customer/medbel-zohih-guilgee/?${dataTableQuerys(...args)}`),
				post: body => instance.post(`/customer/medbel-zohih-guilgee/`, body),
				delete: id => instance.delete(`/customer/medbel-zohih-guilgee/${id}/`),
				put: (body, id) => instance.put(`/customer/medbel-zohih-guilgee/${id}/`, body),
			},
		},

		projectNested: {
			get: (type) => instance.get(`/company/project-nested/?type=${type}`),
		},
		userRequest: {
			get: () => instance.get(`/songon/user/songonshalgaruulaltuserdetail/`),
		},

		wonSongon: {
			get: () => instance.get(`/songon/user/songonshalgaruulaltlist/`),
		},

		selectRoom: {
			get: () => instance.get(`/company/room-type/`),
			getOne: id => instance.get(`/company/room-type/${id}/`),
			post: body => instance.post(`/company/room-type/`, body),
			put: (id, body) => instance.put(`/company/room-type/${id}/`, body),
			delete: id => instance.delete(`/company/room-type/${id}/`),
		},

		news: {
			page: {
				getPagination: (...args) => instance.get(`/news/page/?${dataTableQuerys(...args)}`),
				getAll: pagePrefix => instance.get(`/news/pages/code/${pagePrefix}/?prefix=True`),
				getOne: newsPageId => instance.get(`/news/page/${newsPageId}/`),
				post: body => instance.post(`/news/page/`, body),
				put: (pageId, body) => instance.put(`/news/page/${pageId}/`, body),
				delete: pageId => instance.delete(`/news/page/${pageId}/`),
			},
			getPagination: (pageId, ...args) => instance.get(`/news/page/${pageId}/news/?${dataTableQuerys(...args)}`),
			getOne: (pageId, newsId) => instance.get(`/news/page/${pageId}/news/${newsId}/`),
			post: (pageId, body) => instance.post(`/news/page/${pageId}/news/`, body, imageConfig),
			put: (pageId, newsId, body) => instance.put(`/news/page/${pageId}/news/${newsId}/`, body, imageConfig),
			delete: (pageId, newsId) => instance.delete(`/news/page/${pageId}/news/${newsId}/`),
			front: {
				get: (pageCode, skip = "", limit = "", isSpecial = "", sort = "", sortField = "count") =>
					instance.get(
						`/news/page/code/${pageCode}/?${newsSkipLimitQeury(skip, limit)}${newsIsSpecialQuery(isSpecial)}${newsSortQeury(
							sort,
							sortField,
						)}`,
					),
				getOne: (pageId, newsId) => instance.get(`/news/page/${pageId}/news-info/${newsId}/`),
			},
		},

		unitMeasure: {
			get: () => instance.get(`/config/unitmeasure/`),
			getOne: id => instance.get(`/config/unitmeasure/${id}/`),
			post: body => instance.post(`/config/unitmeasure/`, body),
			put: (id, body) => instance.put(`/config/unitmeasure/${id}/`, body),
			delete: id => instance.delete(`/config/unitmeasure/${id}/`),
		},

		image: {
			upload: body => instance.post(`/config/image/`, body, imageConfig),
			delete: body => instance.delete(`/config/image/`, { data: body }),
		},

		nowUsage: {
			get: () => instance.get(`/config/odooginzoriulalt/`),
			getOne: id => instance.get(`/config/odooginzoriulalt/${id}/`),
			post: body => instance.post(`/config/odooginzoriulalt/`, body),
			put: (id, body) => instance.put(`/config/odooginzoriulalt/${id}/`, body),
			delete: id => instance.delete(`/config/odooginzoriulalt/${id}/`),
		},

		oldApartment: {
			getAll: () => instance.get(`/company/old/all-apartments/`),
			get: () => instance.get(`/company/old/apartment/`),
			getOne: id => instance.get(`/company/old/apartment/${id}/`),
			post: (body, config) => instance.post(`/company/old/apartment/`, body, config),
			put: (id, body, config) => instance.put(`/company/old/apartment/${id}/`, body, config),
			delete: id => instance.delete(`/company/old/apartment/${id}/`),
		},
		oldBuilding: {
			get: apartId => instance.get(`/company/old/apartment/${apartId}/building/`),
			post: (apartId, body) => instance.post(`/company/old/apartment/${apartId}/building/`, body),
			put: (apartId, buildingId, body) => instance.put(`/company/old/apartment/${apartId}/building/${buildingId}/`, body),
			delete: (apartId, body) => instance.delete(`/company/old/apartment/${apartId}/building/`, body),
		},

		endResult: {
			get: (id, code) => instance.get(`/songon/won/${id}/zoriltotbuleg/${code}/`),
		},

		getAnalytac: {
			postTurees: (body, has_data, start_time, end_time, sort) =>
				instance.post(`/price/turees/?${analytacQuery(has_data, start_time, end_time, sort)}`, body),
			postHudaldaa: (body, has_data, start_time, end_time, sort) =>
				instance.post(`/price/hudaldaa/?${analytacQuery(has_data, start_time, end_time, sort)}`, body),
		},

		programResult: {
			getAllProgram: () => instance.get(`/govorg/all-hutulbur/`),
			getAllResult: () => instance.get(`/govorg/result/`),
			getOneResult: id => instance.get(`/govorg/result/${id}/`),
			refresh: () => instance.get(`/govorg/result/update/`),
		},

		getOnlyPrice: {
			getHudaldaa: codes => instance.get(`/only-price/hudaldaa/?codes=${codes.join(",")}`),
			getTurees: codes => instance.get(`/only-price/turees/?codes=${codes.join(",")}`),
		},
		companyAccessFront: {
			getBaiguullagaInfo: () => instance.get(`/company/baiguullagainfo/`),
			getBaiguullaga: (start_date, end_date, field_name, order) =>
				instance.get(`/company/baiguullaga/?${accessQuery(start_date, end_date, field_name, order)}`),
		},

		adminAccessFront: {
			getRequestLog: (start_date = Date.now() - 15800000000, end_date = Date.now()) =>
				instance.get(`/access/requestlog/?start_date=${start_date}&end_date=${end_date}`),
			getRequestLogPaginition: (type = "get", start_date = Date.now() - 15800000000, end_date = Date.now(), ...args) =>
				instance.get(`/access/requestlog-pagination/${type}/?start_date=${start_date}&end_date=${end_date}&${dataTableQuerys(...args)}`),
			getChart: (start_date = Date.now() - 15800000000, end_date = Date.now()) =>
				instance.get(`/access/requestlog-line/?start_date=${start_date}&end_date=${end_date}`),

			getSignLog: (start_date = Date.now() - 15800000000, end_date = Date.now()) =>
				instance.get(`/access/requestlog-loginout/?start_date=${start_date}&end_date=${end_date}`),
			getSignLogChart: (start_date = Date.now() - 15800000000, end_date = Date.now()) =>
				instance.get(`/access/requestlog-line-loginout/?start_date=${start_date}&end_date=${end_date}`),
			getSignLogLine: (start_date = Date.now() - 15800000000, end_date = Date.now()) =>
				instance.get(`/access/requestlog-line-loginout/?start_date=${start_date}&end_date=${end_date}`),
			getErrorPagination: (start_date = Date.now() - 15800000000, end_date = Date.now(), ...args) =>
				instance.get(`/access/requestlog-errors/?start_date=${start_date}&end_date=${end_date}&${dataTableQuerys(...args)}`),
			error500: {
				pagination: (start_date = Date.now() - 15800000000, end_date = Date.now(), ...args) =>
					instance.get(`/access/requestlog-error500/?start_date=${start_date}&end_date=${end_date}&${dataTableQuerys(...args)}`),
				get: id => instance.get(`/access/requestlog-error500/${id}/`),
			},
		},

		notification: {
			get: () => instance.get(`/news/notification/`),
			getOne: id => instance.get(`/news/notification/${id}/`),
			delete: id => instance.delete(`/news/notification/${id}/`),
			deleteAll: () => instance.delete(`/news/notification/`),
			readAll: () => instance.get(`/news/notification/read-all/`),
		},
		config: {
			get: names => instance.get(`/config/?name=${names}`),
			update: body => instance.put(`/config/`, body),
			create: body => instance.get(`/config/`, body),
		},

		material: {
			getOne: (id, start_date = Date.now() - 15800000000, end_date = Date.now(), codes=[]) =>
				instance.get(`/materials/${id}/?start_time=${start_date}&end_time=${end_date}&codes=${codes}`),
			get: () => instance.get(`/material-types/`),
			getList: () => instance.get(`/material/`),
			type: {
				create: (body) => instance.post(`/material/type/`, body),
				getOne: (id) => instance.get(`/material/type/${id}/`),
			},
			name: {
				getOne: (id) => instance.get(`/material/name/${id}/`),
				price: {
					get: (id, ...args) => instance.get(`/material/name/${id}/price/?${dataTableQuerys(...args)}`),
					post: (id, body) => instance.post(`/material/name/${id}/price/`, body),
					delete: (nameId, id) => instance.delete(`/material/name/${nameId}/price/${id}/`),
				}
			}
		},

		report: {
			getOc1: (year, ...args) => instance.get(`/company/report/oc1/${year}/?${dataTableQuerys(...args)}`),
			getOc1One: id => instance.get(`company/report/oc1/get-one/${id}/`),
			createOc1: (body) => instance.post(`/company/report/oc1/create/`, body),
			updateOc1: (body, id) => instance.put(`/company/report/oc1/create/${id}/`, body),
			deleteOc1: id => instance.delete(`/company/report/oc1/create/${id}/`),
			createExcelOc1: body => instance.post(`/company/report/oc1/create-excel/`, body),
			getUrlExcelOc1: body => instance.post(`/company/report/oc1/url-excel/`, body),

			getOc2: (year, ...args) => instance.get(`/company/report/oc2/${year}/?${dataTableQuerys(...args)}`),
			getOc2One: id => instance.get(`company/report/oc2/get-one/${id}/`),
			createOc2: (body) => instance.post(`/company/report/oc2/create/`, body),
			updateOc2: (body, id) => instance.put(`/company/report/oc2/create/${id}/`, body),
			deleteOc2: id => instance.delete(`/company/report/oc2/create/${id}/`),
			createExcelOc2: body => instance.post(`/company/report/oc2/create-excel/`, body),
			getUrlExcelOc2: body => instance.post(`/company/report/oc2/url-excel/`, body),

			createExcelOne: () => instance.get(`/company/report/project/get-one/`),
			createExcelProject: () => instance.get(`/company/report/project/create-excel/`),
			createExcelTypeProject: () => instance.get(`/company/report/type-project/create-excel/`),
		},

		bh: {
			list: (...args) => instance.get(`/company/bh/?${dataTableQuerys(...args)}`),
			register: (body) => instance.post('/company/bh/admin/', body),
		},

		search: (value) => instance.get(`/big-search-suggestions/?big_search=${value}`),
		priceGraph: (code, type, extra="") => instance.get(`/price-graph/?code=${code}&type=${type}${extra}`),
		getApartmentSdi: (id) => instance.get(`/apartments/?apartment_sdi=${id}&pPage=1&pPerPage=1`),

		lifeStyle: (query) => instance.get(`/life-search/?${query}`),

		geomGet: (code, type) => instance.get(`/get-geom/${code}/${type}/`),
		buGet: (code, type) => instance.get(`/get-bu/${code}/${type}/`),

		helper: {
			get: () => instance.get(`/helper/`),
			getOne: id => instance.get(`/helper/${id}/`),
			delete: id => instance.delete(`/helper/${id}/`),
			put: (data, id) => instance.put(`/helper/${id}/`, data),
			post: (data) => instance.post(`/helper/`, data),
			getUser: () => instance.get(`/helper/datas/`),
		}
	}
}
