import React, { useRef } from 'react'
import { useState } from 'react'

import OverlayElement from 'components/main/OverlayElement'

export default function ServiceItem({ icon, title, link, description, isEdit=false, index="", onChange })
{

    const [ _isEdit, setEdit ] = useState(isEdit)
    const [ values, setValues ] = useState({
        'title': title,
        "icon": icon,
        "link": link
    })

    const handleIconChange = (event) =>
    {
        const value = event.target.value
        const iconElement = document.getElementById(`service-icon-${index}`)
        iconElement.className = value
        setValues((prev) => ({ ...prev, 'icon': value }))
        onChange('icon', value, index)
    }

    const jumpToLink = (url) =>
    {
        window.open(
            url,
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    return (
        <div style={{ height: "200px", width: "100%", backgroundColor: "#fff", boxShadow: "0 3px 8px 0 #d9e2ec", borderRadius: "4px" }}
            className={`d-flex align-items-center justify-content-center position-relative ${!isEdit && link ? "hover-primary cursor-pointer" : ""}`}
            role={`${!isEdit && link ? "button" : ""}`}
            onClick={!isEdit && link ? () => jumpToLink(link) : null}
        >
            {
                isEdit
                &&
                    <OverlayElement text='Урьдчилж харах'>
                        <div className='position-absolute hover-primary' onClick={() => setEdit((prev) => !prev)} style={{ top: "5px", right: '40px'}} role="button">
                            <i className='fa fa-eye'></i>
                        </div>
                    </OverlayElement>
            }
            {
                isEdit && values.link
                &&
                    <OverlayElement text='Хуудас руу үсрэх'>
                        <div className='position-absolute hover-primary' onClick={() => jumpToLink(values.link)} style={{ top: "5px", right: '80px' }} role="button">
                            <i className='fa fa-chevron-right'></i>
                        </div>
                    </OverlayElement>
            }
            {
                !isEdit && link
                &&
                    <OverlayElement text='Үсрэх'>
                        <div className='position-absolute' style={{ top: "5px", right: '10px'}}>
                            <i className='fa fa-chevron-right'></i>
                        </div>
                    </OverlayElement>
            }
            <div>
                <i style={{ fontSize: "40px", display: "block", textAlign: "center", color: "#005b9a" }} id={`service-icon-${index}`}
                    className={icon}
                ></i>
                {
                    _isEdit
                    &&
                        <input
                            type={"text"}
                            className="form-control text-center"
                            title={"Icon"}
                            onChange={handleIconChange}
                            placeholder="Icon оруулах"
                            value={values.icon}
                        />
                }
                <div style={{ fontSize: "16px", color: "#333" }}
                    className="mt-3 text-center"
                >
                    {
                        !isEdit
                        ?
                            title
                        :
                            _isEdit
                            ?
                                <>
                                    <input
                                        onChange={(e) => {
                                            setValues((prev) => ({ ...prev, 'title': e.target.value }))
                                            onChange('title', e.target.value, index)
                                        }}
                                        type={"text"}
                                        value={values.title}
                                        className="form-control text-center"
                                        title={"Гарчиг"}
                                        placeholder="Гарчиг оруулах"
                                    />
                                    <input
                                        onChange={(e) => {
                                            setValues((prev) => ({ ...prev, 'link': e.target.value }))
                                            onChange('link', e.target.value, index)
                                        }}
                                        type={"text"}
                                        value={values.link}
                                        className="form-control text-center"
                                        title={"Холбоос"}
                                        pattern="http*"
                                        placeholder="Холбоос оруулах"
                                    />
                                </>
                            :
                                values.title
                    }
                </div>
                {
                    description
                    &&
                        <div>
                            {description}
                        </div>
                }
            </div>
        </div>
    )
}
