import React from 'react';

import ImageFetch from 'components/main/Image';

import label from 'utils/validate/label';

const DisplayImage = ({ item, details}) => {
    return (
		<div>
			<span style={{ fontWeight: "500" }}>{label?.[item]}</span>:
            <ImageFetch
                className='w-50'
                src={process.env.REACT_APP_SERVER_URL + details?.[item]}
                noImageSize
            />
		</div>
	)
}

export default DisplayImage;
