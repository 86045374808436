import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Budget from '.';
import BudgetTypeAction from './BudgetTypeAction';

import BoxBackground from 'components/special/BoxBackground';

const BudgetCrudMain = () => {
    return (
        <Routes>
            <Route path="/" element={<BoxBackground title='Хөрөнгийн эх үүсвэр' body={<Budget />} />} />
            <Route path="/:id/" element={<BudgetTypeAction />} />
        </Routes>
    );
}

export default BudgetCrudMain;
