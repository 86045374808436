import { useEffect, useState } from "react"

import UpdateButton from 'components/main/CudButtons/Update';
import OverlayElement from "components/main/OverlayElement";
import RoleRequired from "components/main/RoleRequired";

import { companyPageRoles } from 'utils/consts/roles';

import ProjectAction from "pages/Company/Project/ProjectAction"

export default function ProjectDetail({ id, name, removeOverlay })
{
    const [ isDetail, setIsDetail ] = useState(true);

    /** Update формыг дуудах нь */
    const handleUpdate = () =>
    {
        setIsDetail((prev) => !prev)
    }

    const handleDone = () =>
    {
        removeOverlay(true)
    }

    return (
        <div>
            <div className="d-flex">
                <div className="ms-auto">
                    {
                        !isDetail
                        ?
                            <RoleRequired
                                isNavigate={false}
                                needRoles={companyPageRoles}
                            >
                                <OverlayElement
                                    placement="left"
                                    text="Дэлгэрэнгүй"
                                >
                                    <div
                                        role="button"
                                        onClick={handleUpdate}
                                    >
                                        <i className="far fa-eye"></i>
                                    </div>
                                </OverlayElement>
                            </RoleRequired>
                        :
                            <UpdateButton className='me-2' onClick={handleUpdate} needRoles={companyPageRoles} />
                    }
                </div>
            </div>
            <ProjectAction id={id} isDetail={isDetail} handleDone={handleDone} />
        </div>
    )
}
