import React, { useEffect, useMemo } from 'react'

import useMap from 'hooks/map/useMap'
import useMapContext from 'hooks/useMapContext';

import MapControls from 'components/special/MapControls'
import MapMenu from 'components/special/MapMenu'
import COverlay from 'components/special/MapControls/Overlay';

import PopupContent from './PopUpContent'
import Search from './Search'
import ProjectSearch from './ProjectSearch'

export default function CXZMap()
{
    const { map, cMap } = useMap({})
    const { setCMap, setShouldClosePopup, alwaysDrag } = useMapContext()

    useEffect(
        () =>
        {
            setCMap(cMap)
        },
        [map]
    )

    const menus = [
        {
            title: "Төслөөр",
            icon: "far fa-book-open",
            isToggle: true,
            component: <ProjectSearch />
        },
        {
            title: "Засаг захиргаа",
            icon: "far fa-map",
            isToggle: true,
            component: <Search />
        }
    ]

    /** overlay хаагдах үед */
    const handleClose = () =>
    {
    }

    const overlay = useMemo(
        () =>
        {
            return (
				<COverlay
					map={map}
					cMap={cMap}
					setShouldClosePopup={setShouldClosePopup}
					component={<PopupContent cMap={cMap} />}
					margin={100}
					onClose={handleClose}
				/>
			)
        },
        [map]
    )

    return (
        <div
            id="map"
            className={`position-relative`}
            style={{ height: "calc(95vh - 60px - 20px - 18px - 65px - 40px - 14px - 20px)" }}
        >
            <MapMenu menus={menus} singleOpen={true} />
            <MapControls setCMap={setCMap} cMap={cMap} map={map} showButtons={['fs']}/>
            {overlay}
        </div>
    )
}
