import React, { useEffect, useState } from 'react';

import CForm from 'components/main/Form';

import useLoader from 'hooks/useLoader';
import useToast from 'hooks/useToast';
import useApi from 'hooks/useApi';

import { resetPasswordValidate } from 'utils/validate';
import useAuth from 'hooks/useAuth';

const MailInput = ({ setActiveStep, setMail, defaultMail='' }) => {
    const [ inputValues, setInputValues ] = useState({})

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })
    const passwordApi = useApi().user.password
    const { addToast } = useToast()
    const { isLogged } = useAuth()

    const handleSubmitMail = async (body) =>
    {
        if(isLogged && defaultMail !== body.email)
        {
            return addToast({ text: 'И-мэйл хаяг тохирохгүй байна.', type: 'warning' })
        }
        const { success, data } = await fetchData(passwordApi.sendMail(body))
        if(success)
        {

            setActiveStep(1)
            setMail(body.email)
        }
    }

    useEffect(
        () =>
        {
            setInputValues({
                email: defaultMail
            })
        },
        [defaultMail]
    )

    const mailForm = {
        inputs: [
            {
                inputType: "input",
                registerName: "email",
                type: "email",
                label: {
                    text: "Цахим шуудан",
                    empty: false,
                },
                disabled: defaultMail !== '' ? true : isLoading,
                colMd: 12
            },
        ],
        button: {
            onSubmit: (data) => handleSubmitMail(data),
            title: "Баталгаажуулах код илгээх",
            variant: 'main',
            disabled: isLoading,
            isLoading: isLoading,
        },
        validate: {
            validateMode: "all",
            yupValidate: resetPasswordValidate,
        }
    }
    return <CForm inputValues={inputValues} form={mailForm}/>;
}

export default MailInput;
