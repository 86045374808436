
import React, { useState, useMemo, useRef } from 'react'

const NemdegInput = (props) =>
{
    const [ inputs, setInputs ] = useState([])
    const isFirst = useRef(true)

    if (props.firstDatas && props.firstDatas.length > 0)
    {
        if (isFirst.current && inputs.length == 0)
        {
            for (let fData of props.firstDatas)
            {
                setInputs(prev =>
                {
                    const newMsg = [...prev]
                    newMsg.push({ name: fData.name, position: fData.position })

                    return newMsg
                })
            }
            isFirst.current = false
        }
    }

    const addInput = () =>
    {
        setInputs(prev =>
        {
            const newMsg = [...prev]
            newMsg.push({ name: '', position: '' })

            props.returnData(newMsg)
            return newMsg
        })
    }

    const removeInput = (idx) =>
    {
        setInputs(prev =>
        {
            const newMsg = [...prev]
            newMsg.splice(idx, 1)

            props.returnData(newMsg)
            return newMsg
        })
    }

    const changeValue = (e, idx, type) =>
    {
        setInputs(prev =>
        {
            const newInputs = [...prev]
            newInputs[idx][type] = e.target.value

            props.returnData(inputs)
            return newInputs
        })
    }

    const displayInputs = useMemo(
        () =>
        {
            let memoInputs = []

            if (inputs && inputs.length > 0)
            {
                for (let idx = 0; idx < inputs.length; idx++)
                {
                    memoInputs.push(
                        <>
                            <div className='col-md-6 d-inline-block' key={idx}>
                                <div className='mv-3'>
                                    <label className='form-label'>
                                        Албан тушаал <i className="far fa-minus-circle text-danger" style={{ cursor: 'pointer' }} onClick={() => removeInput(idx)} ></i>
                                    </label>
                                    <div className='input-group'>
                                        <input type="text" name={`position${idx}`} className='form-control' value={inputs[idx]['position']} onChange={(e) => changeValue(e, idx, 'position')} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 d-inline-block' key={idx}>
                                <div className='mv-3'>
                                    <label className='form-label'>
                                    Овог, нэр
                                    </label>
                                    <div className='input-group'>
                                        <input type="text" name={`name${idx}`} className='form-control' value={inputs[idx]['name']} onChange={(e) => changeValue(e, idx, 'name')} />
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            }

            return memoInputs
        },
        [inputs]
    )

    return (
        <>
            <h6>Комиссын гишүүд <button type='button' className='btn btn-main' onClick={addInput}><i className="far fa-plus-circle"></i></button></h6>
            {displayInputs}
        </>
    )
}

export default NemdegInput
