import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom"
import { Routes, Route } from "react-router-dom";

import CForm from "components/main/Form"
import BoxBackground from "components/special/BoxBackground"

import { materialNameValidate } from 'utils/validate';

import useLoader from 'hooks/useLoader'
import useApi from "hooks/useApi";
import PriceList from "./PriceList";
import PriceCreate from './PriceCreate'

export default function NameForm()
{
    const materialApi = useApi().material
    const { id } = useParams()
    const isCreate = id.endsWith("new")
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
    const [ inputValues, setInputValues ] = useState({})

    useEffect(
        () =>
        {
            if (!isCreate)
            {
                fetchData(materialApi.name.getOne(id))
                    .catch(error => error)
                    .then(
                        ({ success, data, errors }) =>
                        {
                            if (success)
                            {
                                setInputValues(data)
                            }
                        }
                    )
            }
            else {
                setInputValues({})
            }
        },
        [id]
    )

    const register = async () =>
    {

    }

    const registerInputs =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "matname",
                disabled: true,
                isLoading: isLoading,
                colMd: 12
            },
        ],
        button:
        {
            show: false,
            onSubmit: register,
            title: isCreate ? "Үүсгэх" : "Засах",
            disabled: isLoading,
            isLoading: isLoading,
        },
        validate:
        {
            validateMode: "all",
            yupValidate: materialNameValidate
        }
    }

    return (
        <>
            <BoxBackground title="Материалын нэр" className="mb-3">
                <CForm
                    form={registerInputs}
                    inputValues={inputValues}
                />
            </BoxBackground>
            <BoxBackground title="Материалын үнэ">
                <Routes>
                    <Route path="/" element={<PriceList />} />
                    <Route path="/create/" element={<PriceCreate />} />
                </Routes>
            </BoxBackground>
        </>
    )
}
