import SlowInput from 'components/main/SlowInput'
import React from 'react'

const TestSlowInput = () =>
{
    const callback = (event) =>
    {
        alert(event)
    }

    return (
        <div>
            <SlowInput callback={callback} />
        </div>
    )
}

export default TestSlowInput
