import React from 'react'

import CButton from 'components/main/Button';

export default function ResetBtn({ handleReset, reset, resetField })
{
    return (
        <CButton
            className="floatRight"
            onClick={(e) => handleReset(e, { reset, resetField })}
        >
            Шинэчлэх
        </CButton>
    )
}
