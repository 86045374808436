import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';

import css from '../../style.module.css'

const ApartItemList = ({ itemList }) => {

    const displayItems = useMemo(
        () =>
        {
            return itemList?.map(
                (element, index) =>
                {
                    return (
						<Col className={css.apartSmallItem} key={index} md={4} xs={6} >
							<span>{element.number}</span>
							<i className={element.icon} />
							<div>{element.text}</div>
						</Col>
					)
                }
            )
        },
        [itemList]
    )
    return displayItems
}

export default ApartItemList;
