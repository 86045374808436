import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import GoTop from 'components/special/GoTop';
import NewsCardGrid from 'components/special/NewsCards/Grid';
import NewsCardList from 'components/special/NewsCards/List';
import CButton from 'components/main/Button';

import NewsMore from '../NewsMore';

import useApi from 'hooks/useApi';
import useCState from 'hooks/useCState';
import useLoader from 'hooks/useLoader';
import useMobile from 'hooks/useMobile';

const NewsList = ({ page, listView }) => {
	const [ news, setNews ] = useState([])
	const { newsId } = useParams()
    const [ finished, setFinished ] = useState(false)
	const [ atBottom, setAtBottom ] = useState(false)

	const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })
    const { initState, params, setParams } = useCState()
	const newsApi = useApi().news.front
	const { isMobile } = useMobile()

	useEffect(
		async () =>
		{
			const { success, data } = await fetchData(newsApi.get(page, 0, params.limit ))
			if(success)
			{
				setNews(data)
                if(Math.ceil(data.length % initState.limit !== 0)) setFinished(true)
			}
		},
		[]
	)

	const getData = async (skip, limit) =>
	{
        if(skip === 0) return
		const { success, data } = await fetchData(newsApi.get(page, skip, limit ))
		if(success)
		{
			setNews([ ...news, ...data])
			if( data.length < initState.limit ) setFinished(true)
		}
	}

	const loadMore = async () =>
	{
		setAtBottom(false)
		await setParams({ skip: params.skip + initState.limit, limit: params.limit + initState.limit })
		await getData(params.skip + initState.limit, params.limit + initState.limit)
	}

	const callback  = useCallback(
		(event) =>
		{
			if(event?.[0].isIntersecting)
			{
				setAtBottom(true)
			}
			else{
				setAtBottom(false)
			}
		},
		[]
	)

	useEffect(
		() =>
		{
			if(isMobile) return
			var buttonElement = document.getElementById('button')
			if(!buttonElement) return
			let options = {
				root: null,
				rootMargin: '-30px',
				threshold: 0
			}
			  let observer = new IntersectionObserver(callback, options);
			  observer.observe(buttonElement)
		},
		[callback, isMobile, newsId]
	)

	useEffect(
		async () =>
		{
			if(isMobile) return
			if(finished) return
			if(atBottom === false) return
			if(news.length < initState.limit) return
			await loadMore()
		},
		[atBottom, isMobile]
	)

    const displayNews = useMemo(
        () =>
        {
			if(newsId)
			{
				return (
					<GoTop>
						<NewsMore isNews={true}/>
					</GoTop>
				)
			}
			if(listView === 'grid')
			{
				return news.map(
					(element, index) =>
					{
						return <NewsCardGrid pageId={element.page} image={element.image} id={element.id} key={index} desc={element.commentary} title={element.title} date={element.created_at} views={element.count}/>
					}
				)
			}
			return news.map(
				(element, index) =>
				{
					return <NewsCardList pageId={element.page} image={element.image} id={element.id} key={index} desc={element.commentary} title={element.title} date={element.created_at} views={element.count}/>
				}
			)
        },
        [news, listView, newsId]
    )

    return (
		<Row>
			{displayNews}
			{isLoading && Loader}
			{
				!finished && !newsId &&
				<div>
					<CButton onClick={loadMore} id="button" variant="second" className={`${isMobile ? isLoading && 'invisible' : 'invisible'} w-100`}>Илүүг харах</CButton>
				</div>
			}
		</Row>
    );
}

export default NewsList;
