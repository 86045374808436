import { useContext, useEffect, useMemo, useState } from "react"
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import DataNotFound from "components/main/DataNotFound";
import useLoader from "hooks/useLoader";
import useApi from "hooks/useApi";
import { useSearchParams } from "react-router-dom";
import { searchParamsToObject } from "utils/browser";
import { StateContext } from "context/stateContext";

const PieChartMap = ({ auSource, auCode }) =>
{

	const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false, absolute: true })
	const averagePriceApi = useApi().getAnalytac

	const { setSideShow, setType, selectedFeature } = useContext(StateContext)
	const [ searchParams ] = useSearchParams()

	const [ lastUnit, setLastUnit ] = useState()
	const [ chartData, setChartData ] = useState([])

	useEffect(
		() =>
		{
			const units = Object.values(searchParamsToObject(searchParams))
			setLastUnit(units[units.length - 1])
		},
		[auSource]
	)


	useEffect(
		async () =>
		{
			if(!lastUnit && !auCode) return
			let codes = []

			if(auCode) codes.push(parseInt(auCode))
			else if(lastUnit) codes.push(parseInt(lastUnit))

			const body = {
				codes
			}

			const [ hudaldaaRsp, tureesRsp ] = await fetchData(
				Promise.all(
					[
						averagePriceApi.postTurees(body),
						averagePriceApi.postHudaldaa(body),
					]
				)
			)

			if(hudaldaaRsp.success && tureesRsp.success)
			{
					setChartData([
						{
							...hudaldaaRsp.data[0],
							type: "Худалдах",
						},
						{
							...tureesRsp.data[0],
							type: "Түрээслэх",
						},
					])
			}
		},
		[lastUnit, auCode]
	)

    useEffect(
        () =>
        {
			if(!chartData || chartData.length === 0) return
			if(Object.keys(chartData[0]).length === 1 && Object.keys(chartData[1]).length === 1) return
			let root = am5.Root.new("mapPieChart")

			root.setThemes([am5themes_Animated.new(root)])

			var chart = root.container.children.push(am5percent.PieChart.new(root, {
                layout: root.verticalLayout
            }))

			var series = chart.series.push(
				am5percent.PieSeries.new(root, {
					name: "Series",
					valueField: "avg",
					categoryField: "type",
					alignLabels: false,
					legendLabelText: "{category}",
    				legendValueText: "{value}₮"
				}),
			)

			series.slices.template.events.on('click', function(event){
				const type = event.target.dataItem.dataContext.type
				setSideShow(true)
				setType( type === 'Түрээслэх' ? 1 : 0 )
			})

			series.data.setAll(chartData)

			series.labels.template.setAll({
				fontSize: 12,
				text: "{type}",
				textType: "radial",
				radius: 0,
				centerX: am5.percent(100),
				fill: am5.color(0xffffff),
			})

			let legend = chart.children.push(
				am5.Legend.new(root, {
					centerX: am5.percent(50),
					x: am5.percent(50),
					centerY: am5.percent(90),
					y: am5.percent(90),
					clickTarget: "none",
				}),
			)

			series.slices.template.set("tooltipText", "{category}: {value}₮");

			legend.itemContainers.template.events.on('click', function(event){
				const type = event.target.dataItem.dataContext.dataContext.type
				setSideShow(true)
				setType( type === 'Түрээслэх' ? 1 : 0 )
			})

			legend.data.setAll(series.dataItems)

			series.appear(1000, 100)
			return () => {
				root.dispose()
			}
		},
        [chartData]
    )

	const displayAuNames = useMemo(
		() =>
		{
			if(!selectedFeature) return
			/** тухайн засаг захиргааны нэгжийн нэрийг харуулах нь */
			const properties = selectedFeature.getProperties()
			const aimag = properties.administrativeunitmajorclass
			const sum = properties.administrativeunitsubclass
			const horoo = properties.administrativeunitprimaryclass
			const text = properties.text
			return !aimag ? text : `${aimag}${sum ? ", " + sum : ""}${horoo ? ", " + horoo : ""}`
		},
		[selectedFeature]
	)

    return (
		<>
			<div style={{ marginTop: '1rem', textAlign: 'center' }}>
				{displayAuNames}
			</div>
			<div id="mapPieChart" style={{ width: "100%", height: "250px", position: 'relative' }}>
			<div className="hidelogo"></div>
				{
					isLoading
					?
						Loader
					:
						chartData.length === 0 || Object.keys(chartData[0]).length === 1 && Object.keys(chartData[1]).length === 1 && <DataNotFound />
				}
			</div>
		</>
	)
}

export default PieChartMap
