import DisplayFullArray from 'components/special/DisplayOneDetails/DisplayFullArray';
import DisplayNestedObject from 'components/special/DisplayOneDetails/DisplayNestedObject';
import DisplaySingleValue from 'components/special/DisplayOneDetails/DisplaySingleValue';
import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { quantityFormat } from 'utils/format';
import label from 'utils/validate/label';

const ProgramDetails = () => {

    const programApi = useApi().program
    const [ program, setProgram ] = useState()
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })

    const { programId } = useParams()

    useEffect(
        async () =>
        {
            const programRsp = await fetchData(programApi.getOne(programId))
            if(programRsp.success)
            {
                setProgram(programRsp.data)
            }
        },
        []
    )

    return (
		<div>
			{
                isLoading
                ?
                    <div style={{ marginTop: "10vh" }}>{Loader}</div>
                :
                    <>
                        <DisplaySingleValue details={program} item={"name"} />
                        <DisplaySingleValue details={program} item={"budget_amount"} formatter={quantityFormat} />
                        <DisplayNestedObject details={program} item={"main_subject"} />
                        <DisplayNestedObject details={program} item={"budget_type"} />
                        <DisplayFullArray details={program} item={"contrib_subject"} />
                        <DisplaySingleValue details={program} item={"end_date"} />
                        <DisplaySingleValue details={program} item={"start_date"} />
                        <DisplaySingleValue details={program} item={"result"} />
                    </>
			}
		</div>
	)
}

export default ProgramDetails;
