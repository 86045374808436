import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import useApi from "hooks/useApi"
import useLoader from "hooks/useLoader"

import { dataToValueLabel } from "utils/format"
import CSelect from "components/main/Form/InputTypes/Select"

const ChooseAgenda = () =>
{
    const [ agendas, setAgendas ] = useState([])
    const { programId } = useParams()
    const navigate = useNavigate()

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
    const agendaApi = useApi().agenda

    const getAgendas = async () =>
    {
        const { success, data } = await fetchData(agendaApi.get(programId)).catch(err => err)
        if(success)
        {
            setAgendas(dataToValueLabel(data, 'id', 'agenda'))
        }
    }

    useEffect(
        () =>
        {
            getAgendas()
        },
        []
    )

    const handleSelect = (value) =>
    {
        navigate(`${value.value}/`)
    }

    return (
        <div>
            <CSelect onChange={handleSelect} options={agendas} />
        </div>
    )
}

export default ChooseAgenda
