import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import useToast from 'hooks/useToast'

import CButton from 'components/main/Button'

import useMapContext from 'hooks/useMapContext'
import useApi from 'hooks/useApi'

import SelectSearch from './input'

import { styleFunction, selectStyle } from 'hooks/map/helper'

export default function Projects({ isOpen })
{
    const { projects, getProjects, getAuBoundary, resetSearch, cMapState, setChoosedFeature, setSearchResets, searchResets, setShouldClosePopup, searchLayers, setSearchLayers } = useMapContext()
    const { watch, setValue, register } = useForm()

    const { addToast } = useToast()

    const projectApi = useApi().project

    useEffect(
        () =>
        {
            setSearchResets(
                v => ({ ...v, shzProject: handleReset })
            )
            /** төслийн жагсаалт авах нь */
            if (projects.length === 0)
            {
                getProjects()
            }
        },
        []
    )

    /** geojson ий дата дээр дарагдах үед */
    const handleFeature = (event) =>
    {
        const feature = event?.selected?.[0]

        if (!feature)
        {
            cMapState.displayOverlay(undefined)
            return
        }

        const _clickedCoordinate = event.mapBrowserEvent.coordinate
        const centerOfFeature = cMapState.getCenterFromExtent(feature.getGeometry().getExtent())
        cMapState.displayOverlay(_clickedCoordinate)

        setShouldClosePopup(true)
        setChoosedFeature({ feature, isNew: true, isFromMap: true })
    }

    /** тухайн сонгогдсон project ийн geojson ийг газрын зураг дээр харуулах нь */
    const projectValueToMap = (project) =>
    {
        const geojson = project.geojson
        if (!geojson)
        {
            addToast(
                {
                    text: "Ерөнхий төлөвлөгөө байхгүй байна",
                    time: 3000,
                    type: "warning"
                }
            )
            return
        }

        const features = cMapState.parseGeoJsonToFeature(geojson)

        /** бүртгэл явуулах feature үүдийн жагсаалт */
        let onlyBuilding = []
        /** зүгээр харуулж буй featue үүдийн жагсаалт */
        let notBuilding = []

        // General plan ий feature үүдийг дарагддаг дарагддаггүй гэж ялгах нь
        features.map(
            (feature) =>
            {
                if (feature.get("apartment_id"))
                {
                    onlyBuilding.push(feature)
                }
                else {
                    notBuilding.push(feature)
                }
            }
        )

        if (notBuilding.length > 0)
        {
            const { vectorLayer: sVectorLayer, vectorSource: sVectorSource } = cMapState.addVectorLayer(notBuilding, null, "talbai", styleFunction)
            sVectorLayer.setZIndex(9)
            searchLayers.push(sVectorLayer)
        }
        const { vectorLayer, vectorSource } = cMapState.addVectorLayer(onlyBuilding, null, "plan", styleFunction)

        const extent = cMapState.getSourceExtent(vectorSource)
        cMapState.fitToExtent(extent, { duration: 2000 })

        vectorLayer.setZIndex(10)
        cMapState.createSelect(
            'click',
            (evt) => handleFeature(evt),
            selectStyle,
            [vectorLayer],
        )
        searchLayers.push(vectorLayer)
        const searchObj = {
            "unit1-code": project?.unit1?.code,
            "unit2-code": project?.unit2?.code,
            "unit3-code": project?.unit3?.code,
        }
        getAuBoundary(searchObj, { duration: 2000 }, cMapState, false)
    }

    /** тухайн засаг захиргааны нэгж рүү үсрэх нь */
    const jumpAu = async (project) =>
    {
        cMapState.removeLayer('au')
        projectValueToMap(project)
    }

    /** төслөөр хайгаад газрын зураг дээр geoJson харуулах нь */
    const handleChange = async (value) =>
    {
        if (!value)
            return

        cMapState.addLoader()
        searchLayers.map(
            (l, idx) =>
            {
                cMapState.deleteLayer(l)
            }
        )
        if (searchLayers.length > 0)
        {
            searchLayers.pop()
            searchLayers.pop()
        }
        await searchResets.shzUnit && searchResets.shzUnit()

        const { success, data: project, error } = await projectApi.map(value).catch(err => err)
        if (success)
        {
            await jumpAu(project)
        }
        cMapState.removeLoader()
    }

    /** төслөөр хайсан утгыг хоослохнь */
    const handleReset = (event, cMap) =>
    {
        /** газрын зурган дээр гарсан geojson ийг хоослох нь */
        searchLayers.map(layer =>
        {
            if (cMap)
            {
                cMap.deleteLayer(layer)
            }
            else{
                cMapState.deleteLayer(layer)
            }
        })
        /** layer ийг state ээс хоослох нь */
        setSearchLayers([])
        /** газрын зурган дээрх хилийн зураасыг арилгана */
        resetSearch()
        /** input ийн сонгогдсон утгыг хоослоно */
        setValue("project", "")
    }

    return (
        <div className='position-relative mb-5'>
            <SelectSearch onChange={handleChange} label={"Төсөл:"} options={projects} register={register('project')} setValue={setValue} watch={watch} />
            <CButton
                className="floatRight"
                onClick={handleReset}
            >
                Шинэчлэх
            </CButton>
        </div>
    )
}
