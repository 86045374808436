import React, { createContext, useState } from 'react';
import { useLocation } from "react-router-dom"
import axios from 'axios';

import useApi from 'hooks/useApi';
import useToast from 'hooks/useToast';

import { dataToValueLabel } from 'utils/format';
import { styleOfBoundary, styleFunction } from 'hooks/map/helper';
import { objectToQuery } from 'utils/browser';

import { data as unitData } from './help'

const BASE_URL = "https://nsdi.gov.mn"
// export const BASE_URL = "http://192.168.0.195:8000"
export const TOKEN = "42a66d85e3b845b8bce52c2f38c1e31a"

export const FILTER_PERPAGE = 5
export const FILTER_MAXPAGE = 1

export const MapContext = createContext()
export const NEED_PARSE_KEYS = ['zereglel-type', 'butets-type', 'room-choice']
export const AIMAG_WFS_URL = `${BASE_URL}/api/service/${TOKEN}/gp_layer_c_2ndorder_administrativeunit_au_view.geojson`
export const SUM_WFS_URL = `${BASE_URL}/api/service/${TOKEN}/gp_layer_c_3rdorder_administrativeunit_au_view.geojson`
export const BAG_WFS_URL = `${BASE_URL}/api/service/${TOKEN}/gp_layer_c_4thorder_administrativeunit_au_view.geojson`
export const AU_WFS_URL = `${BASE_URL}/api/service/${TOKEN}/gp_layer_administrativeunit_au_view.geojson`
export const BU_URL = `${BASE_URL}/api/service/${TOKEN}/gp_layer_building2d_b_view.geojson`
export const CADASTER_URL = `${BASE_URL}/api/service/${TOKEN}/gp_layer_cadastral_parcel_cp_view.geojson`

// TODO: хэрэг гарсан үедээ хэрэглэх
export default function MapContextProvider(props)
{
    const [ buildings, setBuildings ] = useState(null)
    const [ units, setUnits ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ selectedUnits, setSelectedUnits ] = useState([])
    const [ projects, setProjects ] = useState([])
    const [ isFly, setFly ] = useState(false)
    const [ cMapState, setCMap ] = useState({})
    const [ auLayer, setAuLayer ] = useState(null)
    const [ auSource, setAuSource ] = useState(null)
    const [ buLayer, setBuLayer ] = useState(null)
    const [ searchLayers, setSearchLayers ] = useState([])
    const [ oldAparts, setOldAparts ] = useState([])
    const [ shzGeoIds, setSHZGeoIds ] = useState([])
    const [ shouldClosePopup, setShouldClosePopup ] = useState(false)
    const [ searchResets, setSearchResets ] = useState(
        {
            "shzUnit": null,
            "shzProject": null,
        }
    )

    const [ choosedFeature, setChoosedFeature ] = useState({})
    const [ alwaysDrag, setAlwaysDrag ] = useState(false)

    const [ showBaseLayer, setShowBaseLayer ] = useState(false)

    //  Хайлт
    const [ selectedFeature, setSelectedFeature ] = useState({})
    const [ allFilters, setAllFilters ] = useState({})
    const initFilterResult = {
        data: [],
        maxPage: FILTER_MAXPAGE,
        type: "",
    }
    const [ filterResult, setFilterResult ] = useState(initFilterResult)

    const { addToast } = useToast()
    const location = useLocation()

    const sdiApi = useApi().map
    const unitApi = useApi({ isDisplayToast: false }).unit
    const searchApi = useApi({ isDisplayToast: false }).map.search
    const oldApi = useApi({ isDisplayToast: false }).map.old
    const shzApi = useApi({ isDisplayToast: false }).buildingTable

    const api = useApi({ isDisplayToast: false })

    /** Газрын зураг дээр харагдаж буй цэгийн датаг авах нь
     * @param {boolean} isFly газрын зураг дээр feature рүү нисэх эсэх
    */
    const getBuilding = async (isFly=false, query) =>
    {
        setLoading(true)
        let querys = location.search

        const { success, data, error } = await sdiApi.getApartments(query ? `?${query}` : querys).catch(err => err);
        if (success)
        {
            setFly(isFly)
            setBuildings(data)
        }
        setLoading(false)
    }

    /** засаг захиргааны шатлалыг дуудах нь */
    const getUnits = async () =>
    {
        setLoading(true)
        const { success, data, error } = await unitApi.get().catch(err => err)
        if (success)
        {
            setUnits(data)
        }
        setLoading(false)
    }

    /** засаг захиргааны шатлалыг tree component тохируулж дуудах нь */
    const getUnitsList = async () =>
    {
        setLoading(true)
        const { success, data, error } = await unitApi.getList().catch(err => err)
        /// FOR DEBUG:
        // const { success, data, error } = unitData
        if (success)
        {
            setUnits(data)
        }
        setLoading(false)
    }


    /** төслийн жагсаалт авах нь */
    const getProjects = async () =>
    {
        setLoading(true)
        const { success, data, error } = await searchApi.getProjects().catch(err => err)
        if (success)
        {
            setProjects(dataToValueLabel(data, "id", "name"))
        }
        setLoading(false)
    }

    const removeQuery = (searchQuery) =>
    {
        const query = location.search
        let isFly = false
        if (query)
            isFly = true
        getBuilding(isFly, searchQuery)
        if (!isFly)
        {
            cMapState.flyToCenter()
        }
    }

    /** Хайлтын үр дүнг буцаах */
    const resetSearch = async (searchQuery) =>
    {
        if (searchQuery)
        {
            removeQuery(searchQuery)
        }
        if (auLayer)
        {
            cMapState.deleteLayer(auLayer)
            setAuLayer(null)
        }
        if (buLayer)
        {
            cMapState.deleteLayer(buLayer)
            setBuLayer(null)
        }
    }

    /** засаг захиргааны нэгжээр хайхаар geojson ийг нь авч газрын зураг дээр харуулах */
    const getAuBoundary = async (searchObj, fitoptions={ duration: 2000 }, cMap=cMapState, hasJump=true) =>
    {
        setLoading(true)
        if (auLayer)
        {
            cMap.deleteLayer(auLayer)
            setAuLayer(null)
        }
        let boundaryId = ""
        let wfsUrl = AU_WFS_URL
        let nationallevel = ""

        let type = ""
        if (searchObj['unit1-code'])
        {
            boundaryId = searchObj['unit1-code']
            wfsUrl = AIMAG_WFS_URL
            type = 1
            nationallevel = "Аймаг, нийслэл"
        }
        if (searchObj['unit2-code'])
        {
            boundaryId = searchObj['unit2-code']
            type = 2
            wfsUrl = SUM_WFS_URL
            nationallevel = "Сум, дүүрэг"
        }
        if (searchObj['unit3-code'])
        {
            boundaryId = searchObj['unit3-code']
            type = 3
            wfsUrl = BAG_WFS_URL
            nationallevel = "Баг, хороо"
        }

        const { success, data } = await api.geomGet(boundaryId, type).catch(
            (err) => err
        )

        if (!success)
        {
            addToast(
                {
                    text: "Хил заагын мэдээллийг авахад алдаа гарсан байна",
                    time: 3000,
                    type: "warning"
                }
            )
            return
        }

        const featureCollection = data

        // const response = await axios
        //     .post(wfsUrl, JSON.stringify({ "boundary_id": `${boundaryId}`, "cql_filter": "" }))
        //     // .post(wfsUrl, JSON.stringify({ "boundary_id": `${boundaryId}`, "cql_filter": "namespace='ALMGAC'" }))
        //     .catch(
        //         err =>
        //         {
        //             addToast(
        //                 {
        //                     text: "Хил заагын мэдээллийг авахад алдаа гарсан байна",
        //                     time: 3000,
        //                     type: "warning"
        //                 }
        //             )
        //         }
        //     )
        // if (!response || response.statusText !== "OK")
        //     return

        // const featureCollection = response.data
        // if (typeof featureCollection !== "object")
        // {
        //     addToast(
        //         {
        //             text: "Засаг захиргаагаар хайхад хайлт олдсонгүй",
        //             time: 3000,
        //             type: "warning"
        //         }
        //     )
        //     return
        // }
        const features = cMap.parseGeoJsonToFeature(featureCollection)
        if (features.length === 0)
        {
            addToast(
                {
                    text: "Засаг захиргаагаар хайхад хайлт олдсонгүй",
                    time: 3000,
                    type: "warning"
                }
            )
            return
        }
        const { vectorLayer, vectorSource } = cMap.addVectorLayer(features, null, "au", styleOfBoundary)
        setAuLayer(vectorLayer)
        setAuSource(vectorSource)
        if (hasJump)
        {
            const extent = cMap.getSourceExtent(vectorSource)
            cMap.fitToExtent(extent, fitoptions)
        }
        setLoading(false)
    }

    /** nsdi аас барилгын датаг дуудах нь */
    const getBuildingFromNsdi = async (searchObj, showText=false, style=styleFunction) =>
    {

        setLoading(true)
        if (buLayer)
        {
            cMapState.deleteLayer(buLayer)
            setBuLayer(null)
        }

        let boundaryId = ""
        let type = ""
        if (searchObj['unit1-code'])
        {
            boundaryId = searchObj['unit1-code']
            type = 1
        }
        if (searchObj['unit2-code'])
        {
            boundaryId = searchObj['unit2-code']
            type = 2
        }
        if (searchObj['unit3-code'])
        {
            boundaryId = searchObj['unit3-code']
            type = 3
        }

        const { success, data } = await api.buGet(boundaryId, type).catch(err => err)
        if (!success)
        {
            addToast(
                {
                    text: "NSDI аас дуудахад алдаа гарсан байна",
                    time: 3000,
                    type: "warning"
                }
            )
            cMapState.removeLoader()
        }

        // const response = await axios
        //     .post(BU_URL, JSON.stringify({ "boundary_id": `${boundaryId}`, "cql_filter": "" }) )
        //     // .post(BU_URL, JSON.stringify({ "boundary_id": "${boundaryId}", "cql_filter": "gm_type='Обьект'" }) )
        //     .catch(
        //         err =>
        //         {
        //             addToast(
        //                 {
        //                     text: "NSDI аас дуудахад алдаа гарсан байна",
        //                     time: 3000,
        //                     type: "warning"
        //                 }
        //             )
        //             cMapState.removeLoader()
        //         }
        //     )

        // const featureCollection = response.data
        const featureCollection = data
        const features = cMapState.parseGeoJsonToFeature(featureCollection)
        const { vectorLayer, vectorSource } = cMapState.addVectorLayer(features, null, "bu", (...args) => style(...args, showText))
        setBuLayer(vectorLayer)
        setLoading(false)
        return vectorLayer
    }

    /** nsdi аас cadaster датаг дуудах нь */
    const getCadasterFromNsdi = async (searchObj, showText=false, style=styleFunction) =>
    {

        setLoading(true)
        if (buLayer)
        {
            cMapState.deleteLayer(buLayer)
            setBuLayer(null)
        }

        let boundaryId = ""
        if (searchObj['unit1-code'])
        {
            boundaryId = searchObj['unit1-code']
        }
        if (searchObj['unit2-code'])
        {
            boundaryId = searchObj['unit2-code']
        }
        if (searchObj['unit3-code'])
        {
            boundaryId = searchObj['unit3-code']
        }

        const response = await axios
            .post(CADASTER_URL, JSON.stringify({ "boundary_id": `${boundaryId}`, "cql_filter": "namespace='Барилга Хот Байгуулалтын Яам'" }) )
            .catch(
                err =>
                {
                    addToast(
                        {
                            text: "NSDI аас дуудахад алдаа гарсан байна",
                            time: 3000,
                            type: "warning"
                        }
                    )
                    cMapState.removeLoader()
                }
            )

        const featureCollection = response.data
        const features = cMapState.parseGeoJsonToFeature(featureCollection)
        const { vectorLayer, vectorSource } = cMapState.addVectorLayer(features, null, "bu", (...args) => style(...args, showText))
        setBuLayer(vectorLayer)
        setLoading(false)
        return vectorLayer
    }

    /** хуучин apartment ийн жагсаалтыг авах нь */
    const getOldApartmentsForMap = async () =>
    {
        setLoading(true)
        const { success, data, error } = await oldApi.getAparts().catch(err => err)
        if (success)
        {
            setOldAparts(data)
        }
        setLoading(false)
    }

    /** shz ийн бүртгэсэн apart ийн geo id уудыг авах нь */
    const getSHZOldGeoids = async (tableId) =>
    {
        setLoading(true)
        const { success, data, error } = await shzApi.mapOldApartmentGeoIds(tableId).catch(err => err)
        if (success)
        {
            setSHZGeoIds(data)
        }
        setLoading(false)
    }

    /** шинээр хийсэн филтер утгаар хайх */
    const allFiltersToMap = async (type="search") =>
    {
        setLoading(true)
        // TODO: газрын зурган дээр өөрчлөлт харуулах
        const querys = objectToQuery(allFilters)
        const { success, data, error } = await sdiApi.getApartments(querys).catch(err => err);
        if (success)
        {
            setFilterResult(
                (prev) =>
                ({
                    ...prev,
                    ...data,
                    type,
                })
            )
            if (data.maxPage)
            {
                cMapState.hideLayerName('building')
            }
            else {
                setBuildings(data)
            }
        }
        setLoading(false)
    }

    /** шинээр хийсэн филтерийг reset хийх */
    const resetAllFilters = (panelIndex) =>
    {
        Object.keys(allFilters).map(
            (key, idx) =>
            {
                delete allFilters[key]
            }
        )

        if (panelIndex === undefined)
            return

        // үнийн хайлт
        if (panelIndex !== 1)
        {
            const mainLayers = ['price-hudaldah', 'price-turees', 'price-borluulah']
            cMapState
                .map
                .getLayers()
                .forEach(
                    (layer) =>
                    {
                        if (layer && layer.get("name") && layer.get("name").startsWith("price-") && layer.get("name").endsWith("-filter"))
                        {
                            cMapState.map.removeLayer(layer)
                        }
                        if (layer && layer.get("name") && mainLayers.includes(layer.get("name")))
                        {
                            layer.setVisible(false)
                        }
                    }
                )
        }
        //  хүн амын нягтаршил
        if (panelIndex !== 2)
        {
            cMapState
                .map
                .getLayers()
                .forEach(
                    (layer) =>
                    {
                        if (layer && layer.get("name") && layer.get("name").startsWith("population"))
                        {
                            layer.setVisible(false)
                        }
                    }
                )
        }
        //  амьдрах орчин
        if (panelIndex !== 3)
        {
            cMapState
                .map
                .getLayers()
                .forEach(
                    (layer) =>
                    {
                        if (layer && layer.get("name") && layer.get("name").startsWith("lifeStyle") || layer.get("name") === "radius-circle")
                        {
                            layer.setVisible(false)
                        }
                    }
                )
        }
        //  Төслийн хэрэгжилт
        if (panelIndex !== 4)
        {
            cMapState
                .map
                .getLayers()
                .forEach(
                    (layer) =>
                    {
                        if (layer && layer?.get("name"))
                        {
                            if (layer?.get("name") === "step-data" || layer?.get("name") === "step-plan" || layer?.get("name") === "step-au")
                            {
                                cMapState.map.removeLayer(layer)
                            }
                        }
                    }
                )
        }
    }

    return (
		<MapContext.Provider
			value={{
				getBuilding,
				setBuildings,
				buildings,
				getUnits,
				units,
				getProjects,
				projects,
				isFly,
				setCMap,
				selectedUnits,
				shouldClosePopup,
				alwaysDrag,
                auSource,
                auLayer,
				setAlwaysDrag,
				setShouldClosePopup,
				setSelectedUnits,
				loading,
				cMapState,
				resetSearch,
				getAuBoundary,
				getBuildingFromNsdi,
                getCadasterFromNsdi,
				getOldApartmentsForMap,
				oldAparts,
				setChoosedFeature,
				choosedFeature,
				setSearchResets,
				searchResets,
				getSHZOldGeoids,
				shzGeoIds,
				searchLayers,
				setSearchLayers,
                showBaseLayer,
                setShowBaseLayer,
                getUnitsList,

                allFilters,
                allFiltersToMap,
                resetAllFilters,

                filterResult,
                setFilterResult,

                selectedFeature,
                setSelectedFeature,
                initFilterResult,
			}}>
			{props.children}
		</MapContext.Provider>
	)
}
