import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'

import BoxBackground from 'components/special/BoxBackground'
import CButton from 'components/main/Button'
import DeleteButton from 'components/main/CudButtons/Delete'
import SaveButton from 'components/main/CudButtons/Save'

import useConfig from 'hooks/useConfig'
import useLoader from 'hooks/useLoader'

import ServiceItem from 'pages/Helper/Abouts/service'
import { adminPageRoles } from 'utils/consts/roles'

export default function OurServices()
{
    const { getConfig, configs, updateConfig } = useConfig()
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
    const config = configs['ABOUT_PAGE_OUR_SERVICES'] ? JSON.parse(configs['ABOUT_PAGE_OUR_SERVICES']) : []

    useEffect(
        () =>
        {
            fetchData(getConfig("ABOUT_PAGE_OUR_SERVICES"))
        },
        []
    )

    const handleSubmit = async (data) =>
    {
        let needConvert = true
        const { success, errors } = await updateConfig(data, needConvert)
        if (!success)
        {
            return errors
        }
    }

    const makeBody = (data) =>
    {
        let body = {
            "name": 'ABOUT_PAGE_OUR_SERVICES',
            "value": JSON.stringify(data)
        }
        return body
    }

    const handleAdd = async () =>
    {
        const body = makeBody(
            [
                ...config,
                {
                    "title": "",
                    "icon": "",
                    "link": ""
                }
            ]
        )
        const { success, errors } = await updateConfig(body)
    }

    const handleRemove = async (index) =>
    {
        const newConfig = config.filter((item, idx) => idx !== index)
        const body = makeBody(newConfig)
        const { success, errors } = await updateConfig(body)
    }

    const handleSave = async () =>
    {
        const body = makeBody(config)
        const { success, errors } = await updateConfig(body)
    }

    const handleChangeService = (name, value, index) =>
    {
        config[index][name] = value
    }

    if (isLoading)
    {
        return Loader
    }

    return (
        <BoxBackground title='Бидний үйлчилгээ'>
            <div className='mb-3'>
                <i className='fa-solid fa-circle-exclamation text-warning'></i>
                <span className='ms-1'>
                    Icon ийг <a href="https://fontawesome.com/v6/search" target={"_blank"} title='https://fontawesome.com/v6/search'>fontawesome</a> уг сайтнаас icon ийг харж болно
                </span>
            </div>
            <Row>
            {
                config.map(
                    (service, idx) =>
                    {
                        return (
                            <Col md={3} className="mb-3 position-relative" key={idx}>
                                <DeleteButton
                                    containerClassName={'position-absolute hover-primary'}
                                    onClick={() => handleRemove(idx)}
                                    style={{ top: "5px", right: "20px", zIndex: 2 }}
                                    needRoles={adminPageRoles}
                                />
                                <ServiceItem onChange={handleChangeService} icon={service.icon} title={service.title} link={service.link} isEdit={true} index={idx} />
                            </Col>
                        )
                    }
                )
            }
            </Row>
            <Row>
                {
                    config.length
                    ?
                        <SaveButton
                            icon="fa fa-save"
                            className="mb-3"
                            title={"Өөрчлөлтийг хадгалах"}
                            onClick={handleSave}
                            needRoles={adminPageRoles}
                        />
                    : null
                }
                <CButton icon="fa fa-plus" onClick={handleAdd}>
                    Шинэ үйлчилгээ нэмэх
                </CButton>
            </Row>
        </BoxBackground>
    )
}
