import React, { useMemo, useState } from 'react'

import MapMenuItem from './MenuItem'

export default function MapMenu({ title="МЭДЭЭЛЭЛ ШҮҮХ ТАЛБАР", menus=[], singleOpen=false })
{
    /** menu нээж хаадаг state */
    const [ active, setActive ] = useState(false)

    const [ activeMenuIdx, setActiveMenuIdx ] = useState(null)

    /** menu нээж хаах */
    const toggleMenu = () =>
    {
        const changeValue = !active
        setActive(changeValue)
        if (changeValue === false)
        {
            setActiveMenuIdx(null)
        }
    }

    function growDiv(id, length, idx)
    {
        setActiveMenuIdx(activeMenuIdx === idx ? null : idx)
        var growDiv = document.getElementById(id);

        if (growDiv.clientHeight) {
            growDiv.style.maxHeight = 0;
        } else {
            growDiv.style.maxHeight = length * 100 + "px";
        }
    }

    const menusElement = useMemo(
        () =>
        {
            return menus.map(
                ({ title, icon, isToggle, component }, idx) =>
                {
                    return (
                        <MapMenuItem
                            singleOpen={singleOpen}
                            key={idx}
                            title={title}
                            icon={icon}
                            onClick={() => isToggle && growDiv("menu-" + idx, 4, idx)}
                            childId={"menu-" + idx}
                            active={active}
                            setActive={setActive}
                            activeMenu={activeMenuIdx === idx}
                        >
                            {component}
                        </MapMenuItem>
                    )
                }
            )
        },
        [menus, active, activeMenuIdx]
    )

    return (
        <div className="menus" id="style-3">
            <nav className={active ? "active" : ""} id="style-3">
                <ul>
                    <MapMenuItem
                        title={title}
                        icon={"fa fa-bars"}
                        onClick={toggleMenu}
                        isToggler={true}
                        titleStyle={{ fontSize: "13px", fontWeight: "bold" }}
                        containerStyle={{ borderBottom: "1px dashed #ddd" }}
                    >
                    </MapMenuItem>
                    {menusElement}
                </ul>
            </nav>
        </div>
    )
}
