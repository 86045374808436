import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function OverlayElement({ placement="top", text="", children })
{
    return (
        <OverlayTrigger
            placement={placement}
            delay={{ show: 0, hide: 0 }}
            overlay={<Tooltip id="button-tooltip-2">{text}</Tooltip>}
        >
            {children}
        </OverlayTrigger>
    )
}
