import React from 'react'
import { Link } from 'react-router-dom'

import OverlayElement from 'components/main/OverlayElement'
import ImageFetch from 'components/main/Image'
import Badge from 'components/main/Badge'

import Room from './Room'

import css from '../../style.module.css'

export default function BuildingCard({ building, apartId })
{
    return (
        <div className={`${css.buildingOCardContainer2} `} style={{ paddingRight: "0px", paddingBottom: "0px", maxWidth: "648px", maxHeight: "324px", minWidth: "0px", position: 'relative' }}>
            {
                building.is_rent
                ?
                    <Badge
                        text={"Түрээс"}
                        style={{top: '16px'}}
                    />
                :
                    ""
            }
            <div className={`d-flex`}>
                <div className={css.imageContainer}>
                    <ImageFetch
                        src={building.plan_img}
                        noImageSize="53x53"
                    />
                </div>
                <div className={css.informationCard}>
                    <Link to={`/building/${apartId}/${building.id}`}>
                        <h3>{building.mkv} мк - {building.total_room} өрөө</h3>
                    </Link>
                    <label className="d-block fs-12">
                        <i style={{ color: "#005b9a" }} className="fas fa-map-marker-alt"></i> {building.unit2_name}, {building.unit3_name}
                    </label>
                    <ul className="mt-2 pt-2">
                        {
                            building.rooms.map(
                                (room, idx) =>
                                {
                                    return (
                                        <Room
                                            key={idx}
                                            name={room.room_name}
                                            code={room.room_code}
                                            count={room.room_count}
                                        />
                                    )
                                }
                            )
                        }
                        <li>
                            <OverlayElement
                                text="Нийт өрөө"
                            >
                                <i className="far fa-border-all"></i>
                            </OverlayElement>
                            <label className="ms-1">{building.total_room}</label>
                        </li>
                    </ul>
                    {
                        building.has_rent
                        &&
                            <div className={css.songonLabel}>
                                <label><i className="far fa-star"></i> сонгон шалгаруулалт</label>
                                <label>|</label>
                                <label>нийт: {building.total_songon}</label>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}
