import CButton from 'components/main/Button'
import css from './style.module.css'

const AnimatedDeleteBtn = ({ className, onClick }) =>
{
    return (
		<CButton onClick={onClick} className={`${css.btn} ${css['btn-delete']} ${className} ${css['buzz-out-on-hover']}`}>
			<span className={`${css.mdi} ${css['mdi-delete']} ${css['mdi-24px']}`} />
			<span className={`${css.mdi} ${css['mdi-delete-empty']} ${css['mdi-24px']}`} />
			<span><i className="fas fa-trash-alt"></i></span>
		</CButton>
	)
}

export default AnimatedDeleteBtn
