import useMobile from "hooks/useMobile";
import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";

import AsyncSelect from 'react-select/async';

import { valueLabelToArray } from "utils/format";

/**
 *
 * @param {className} className select -ийн className
 * @param {Array} options select ийн утгуудыг дамжуула {value: 'value1', label: 'label'} гэсэн маягаар array
 *
 * @param {boolean} disabled select нь disabled байх эсэх
 * @param {boolean} isMulti select нь олоныг сонгож болох эсэх
 * @param {boolean} isSearchable select -ээс хайлт хийж болдог болгох?
 * @param {boolean} closeMenuOnSelect select ээс утга сонгогдвол select ийг popupper ийг арилгах
 * @param {boolean} isLoading select ийн loading state
 *
 * @param {object} value input ийн value {value: 'value1', label: 'label'} гэсэн маягаар
 *
 * @param {string} placeholder select-ийн placeholder
 * @param {string} noOptionsMessage Сонголт байхгүй үед гарах үг
 * @param {string} inputValue select ээс хайлт хийх input -ийн утга
 * @param {string} defaultInputValue select ээс хайлт хийх input -ийн анхны утга
 * @param {string} defaultValue input ийн анхны утга {value: 'value1', label: 'label'} гэсэн маягаар
 *
 * @param {object} errors yup -аас ирсэн validate ийн алдаанууд
 * @param {Function} setValue useform-ийн register -д сонгогдсон утга өгөх
 *
 * @param {function} onInputChange select ээс хайлт хийх input өөрчлөгдөх үед ажиллах fucniton
 * @param {function} onChange Select ийн утга өөрлөгдөх үед ажиллах fcunion
 *
 * @param {object} label -ийн тохиргоо
 * @param {boolean} label.empty label дээр * харуулах эсэх энэ input хоосон байж болох бол true илгээнэ
 * @param {boolean} label.hide input-ийн label ийг харуулах эсэх
 * @param {String} label.text label-ийн харагдах text
 * @param {String} sort object-ийн key ээр нь sort хийхэд ашиглана
 * @returns
 */

const CSelect = ({
    className,
    options,
    disabled,
    isMulti,
    isSearchable,
    placeholder="сонгох",
    noOptionsText,
    value=null,
    onInputChange,
    inputValue,
    watch,
    nestedValue,
    defaultValue,
    defaultInputValue,
    setValue,
    register,
    _async=false,
    errors,
    onChange,
    nested,
    closeMenuOnSelect,
    name,
    inputValues,
    nestedObjectKey='id',
    shouldReset=null,
    isLoading=false,
    borderColor,
    notBorder=false,
    notIndicatorSeparator=false,
    sort,
}) =>
{
    let watchValue = null
    const [selectedOption, setSelectedOption] = useState(null);

    if (watch)
    {
        watchValue = watch(register.name)
    }

    if (sort && options)
    {
        var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

        options.sort(function(first, secound){
            if('label' in first && 'label' in secound)
            {
                let xyArray = [first.label, secound.label]
                xyArray.sort(collator.compare)

                if (first.label === xyArray[0] && first.label === xyArray[1] && secound.label === xyArray[0] && secound.label === xyArray[1])
                {
                    return 0
                }
                else if (first.label === xyArray[0])
                {
                    return -1
                }
                else if (secound.label === xyArray[0])
                {
                    return 1
                }
            }
        })
    }

    const { isMobile } = useMobile()
    // inputErrorIcon
    const errorIcon = {
        "backgroundImage": `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")`,
        "backgroundRepeat": `no-repeat`,
        "backgroundPosition": `right calc(0.375em + 0.1875rem) center`,
        "backgroundSize": `calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)`,
    }

    //NODE: Одко хараарай

    useEffect(
        () =>
        {
            if (options && (options.length > 0 || Object.keys(options).length > 0))
            {
                if (!isMulti)
                {
                    const valu = options.find(e => e.value === watchValue)
                    setSelectedOption(!valu ? null : valu)
                }
                else
                {
                    let khValue = []
                    if (watchValue)
                    {
                        watchValue.map((value) => {
                            khValue.push(options.find(e => e.value === value))
                        })
                        setSelectedOption(khValue)
                    }
                }
            }
        },
        [watchValue, options]
    )

    /**
     * @param defaultStyle Анхны утга
     * @param state Төлөв
     * @returns style
     */
    const customStyle =
    {
        valueContainer: (defaultStyle, state) =>
        {
            let styles =
            {
                ...defaultStyle,
                padding: "1.75px 2px 1.75px 7px"
            }

            if(!!errors?.[register.name]?.message)
            {
                Object.assign(styles, errorIcon)
            }

            return styles
        },
        control: (defaultStyle, state) =>
        {
            if(notBorder)
            {
                return {
                        ...defaultStyle,
                    "&:hover": {
                        borderColor: "none",
                    },
                    borderRadius: "0",
                    boxShadow: "none",
                    borderColor: errors?.[register.name]?.message ? "#dc3545" : state.menuIsOpen ? "#86b7fe" : "hsl(0, 0%, 80%)",
                    border: 'none',

                }
            }
            if(borderColor)
            {
                return {
                        ...defaultStyle,
                    "&:hover": {
                        borderColor: "none",
                    },
                    borderRadius: "0",
                    boxShadow: "none",
                    borderColor: errors?.[register.name]?.message ? "#dc3545" : state.menuIsOpen ? "#86b7fe" : "hsl(0, 0%, 80%)",
                    border: borderColor,
                    borderRight: '1px solid #c2c2c2',

                }
            }
            return {
                ...defaultStyle,
                "&:hover": {
                    borderColor: "none",
                },
                borderRadius: "0.25rem",
                boxShadow: "none",
                borderColor: errors?.[register.name]?.message ? "#dc3545" : state.menuIsOpen ? "#86b7fe" : "rgb(220, 222, 226)",
                border: borderColor
            };
        },
        placeholder:(defaultStyle, state) =>
        {
            return {
                ...defaultStyle,
                fontSize: isMobile ? '12px' : '14px',
                color: '#797979'
            }
        },
        dropdownIndicator: (defaultStyle, state) =>
        {
            if(borderColor)
            {
                return { ...defaultStyle, padding: "0" }
            }
            return {
                ...defaultStyle,
            }
        },
        indicatorSeparator: (defaultStyle, state) =>
        {
            if(notIndicatorSeparator)
            {
                return {
                    ...defaultStyle,
                    display: 'none'
                }
            }
            if(borderColor)
            {
                return {
                    ...defaultStyle,
                    display: 'none'
                }
            }
            return {
                ...defaultStyle,
            }
        },
    }

    const reset = () =>
    {
        setSelectedOption(null)
        setValue && setValue(register?.name, null, { shouldValidate: true })
        if(inputValues) inputValues[register?.name] = null
    }

    const resets = () =>
    {
        setSelectedOption(null)
        setValue && setValue(register?.name, null, { shouldValidate: false })
        if(inputValues) inputValues[register?.name] = null
    }

    useEffect(
        () =>
        {
            if(shouldReset === null) return
            resets()
        },
        [shouldReset]
    )

    /** Хэрэв nested тэй бол өөрийн утга солигдоход түүний утгийг хоослох */
    const resetNestedValue = () =>
    {
        if(!nestedValue) return
        if(selectedOption)
        {
            reset()
        }
    }

    useEffect(
        () =>
        {
            resetNestedValue()
        },
        [nestedValue]
    )

    useEffect(
        () =>
        {
            if(nested && selectedOption)
            {
                reset()
            }
        },
        [options]
    )

    // Анхны утга өгөх
    useEffect(
        () =>
        {
            if(!isMulti)
            {
                if (value !== null)
                {
                    const valuex = options?.find((el) =>
                    {
                        if(typeof value === 'object')
                        {
                            if (el.value === value?.[nestedObjectKey]) return el
                        }
                        else {
                            if (el.value === value) return el;
                        }
                    })
                    setSelectedOption(valuex ? valuex : null);
                    setValue && setValue(register?.name, valuex?.value ? valuex?.value : null)
                }
            }
            else
            {
                if (value !== null)
                {
                    // Одоо байгаа сонголтууд дээр filter хйиж байна
                    const values = options?.filter(
                        (el) =>
                        {
                            // сонгогдсон байгаа утга дээр filter хйиж байна
                            const foundValues = value?.filter(
                                (val) =>
                                {
                                    if(typeof val === 'object')
                                    {
                                        if (el.value === val[nestedObjectKey]) return el
                                    }
                                    else {
                                        if (el.value === val) return el;
                                    }
                                }
                            )
                            return foundValues.length > 0
                        }
                    )
                    setSelectedOption(values);
                    setValue && setValue(register?.name, values.map((el) => el.value))
                }
            }
        },
        [value, options, isMulti]
    );

    const NoOptionsMessage = (props) => {
        const _defText = _async ? "Хайх үгээ оруулна уу" : 'Сонголт олдсонгүй...'
        return (
            <components.NoOptionsMessage {...props}>
                <span>{noOptionsText ? noOptionsText : _defText}</span>
            </components.NoOptionsMessage>
        );
    };

    const filterOptions = (inputValue) => {
        return options.filter((i) =>
            i?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
        );
    };

    const promiseOptions = (inputValue) =>
        new Promise((resolve) => {
            resolve(inputValue.length >= 2 ? filterOptions(inputValue) : []);
        })

    if (_async)
    {
        return (
            <AsyncSelect
                name={name}
                cacheOptions
                defaultOptions
                loadOptions={promiseOptions}
                className={className}
                defaultValue={defaultValue}
                styles={customStyle}
                isDisabled={disabled}
                onChange={(selectedValue) => {
                    setValue && setValue(register?.name, (isMulti ? valueLabelToArray(selectedValue) : selectedValue.value), { shouldValidate: true });
                    setSelectedOption(selectedValue);
                    onChange && onChange(selectedValue, { setSelectedOption })
                    // resetNestedValue()

                    //  input value аас value аа авдаг учраас тухайн state ийн утгыг нь одоо сольсон утгаар сольсон
                    if (inputValues?.[register.name])
                    {
                        inputValues[register?.name] = selectedValue.value
                    }
                }}
                options={options}
                isLoading={false}
                isMulti={isMulti}
                closeMenuOnSelect={isMulti ? false : closeMenuOnSelect}
                isSearchable={isSearchable}
                onInputChange={onInputChange}
                inputValue={inputValue}
                components={{NoOptionsMessage}}
                placeholder={placeholder}
                isClearable={false}
                value={selectedOption}
                defaultInputValue={defaultInputValue}
                theme={(theme) => {
                    return {
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#e6e6e6',
                            primary: '#86b7fe'
                        },
                        spacing: {
                            baseUnit: 3.5
                        }
                    }
                }
                }
            />
        )
    }

    return (
        <Select
            name={name}
            className={className}
            defaultValue={defaultValue}
            styles={customStyle}
            isDisabled={disabled}
            onChange={(selectedValue) => {
                setValue && setValue(register?.name, (isMulti ? valueLabelToArray(selectedValue) : selectedValue.value), { shouldValidate: true });
                setSelectedOption(selectedValue);
                onChange && onChange(selectedValue, { setSelectedOption, setValue })
                // resetNestedValue()

                //  input value аас value аа авдаг учраас тухайн state ийн утгыг нь одоо сольсон утгаар сольсон
                if (inputValues?.[register.name])
                {
                    inputValues[register?.name] = selectedValue.value
                }
            }}
            options={options}
            isLoading={isLoading}
            isMulti={isMulti}
            closeMenuOnSelect={isMulti ? false : closeMenuOnSelect}
            isSearchable={isSearchable}
            onInputChange={onInputChange}
            inputValue={inputValue}
            components={{NoOptionsMessage}}
            placeholder={placeholder}
            isClearable={false}
            value={selectedOption}
            defaultInputValue={defaultInputValue}
            theme={(theme) => {
                return {
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: '#e6e6e6',
                        primary: '#86b7fe'
                    },
                    spacing: {
                        baseUnit: 3.5
                    }
                }
            }
            }
        />
    );
};

export default CSelect;
