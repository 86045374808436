import React from 'react';
import { Col, Row } from 'react-bootstrap';

import label from 'utils/validate/label';

import css from '../GetOneDetail/style.module.css'

const DisplayNestedObject = ({ details={}, item, subItem='name' }) => {
    return (
		<div className="d-flex">
			<div className={css.columnOne}>
				<span className={css.fieldName} style={{ fontWeight: "500" }}>{label?.[item]}:</span>
			</div>
			<div className={css.columnTwo} >
				<span className={css.valueName}>{details?.[item]?.[subItem]}</span>
			</div>
		</div>
	)
}

export default DisplayNestedObject;
