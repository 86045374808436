import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

import useLoader from 'hooks/useLoader';
import useAuth from 'hooks/useAuth'

import ResetPassword from './ResetPassword';

import CForm from '../../../components/main/Form';

import { signinValidate } from "utils/validate";

import css from '../style.module.css'

const SignIn = () =>
{

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const { signIn } = useAuth()
    const navigate = useNavigate()

    /** Нэвтрэх гэсэн товч дарах */
    const handleSubmit = (body) =>
    {
        fetchData(
            (async () =>
            {
                await signIn(body)
            })()
        )
    }

    const loginInputs = {
        inputs: [
            {
                inputType: "input",
                registerName: "email",
                type: "email",
                placeholder: "И-мэйлээ оруулна уу",
                inputGroup: {
                    start: <i className="fa-solid fa-envelope"></i>,
                },
                label: {
                    text: "Цахим шуудан",
                    empty: false,
                },
                colMd: 12
            },
            {
                inputType: "input",
                registerName: "password",
                type: "password",
                placeholder: "Нууц үгээ оруулна уу",
                inputGroup: {
                    start: <i className="fa-solid fa-lock"></i>,
                },
                label: {
                    text: "Нууц үг",
                    empty: false,
                },
                colMd: 12
            },
        ],
        button: {
            onSubmit: (data) => handleSubmit(data),
            title: "Нэвтрэх",
            variant: 'main',
            disabled: isLoading,
            isLoading: isLoading,
        },
        validate: {
            validateMode: "all",
            yupValidate: signinValidate,
        }
    }

    return (
		<Container fluid>
			<Row>
				<Col lg={6} sm={12} className={`${css.loginBanner} p-0`}></Col>
				<Col lg={6} sm={12}>
					<div className={css.loginSection}>
						<Routes>
							<Route
								path="/"
								element={
									<div style={{ width: '400px' }}>
                                        <h3 className={`${css.signTypeTitle} text-darkBlue mt-2 mb-4`}>Системд нэвтрэх</h3>
										<CForm form={loginInputs} />
                                        <a className="cursorPointer" onClick={() => navigate("resetPassword/")}>
                                            Нууц үгээ мартсан?
                                        </a>
									</div>
								}
							/>
							<Route path="/resetPassword/" element={<ResetPassword/>} />
						</Routes>
					</div>
				</Col>
			</Row>
		</Container>
	)
}

export default SignIn;
