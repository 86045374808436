import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import useRole from 'hooks/useRole';

import { PERM_UPDATE } from 'utils/consts'
import { MAIN_DARKBLUE_COLOR } from 'utils/consts/colors';

/** crud үйлдэл хийхдээ энэхүү update ийг ашиглана */
const AddMore = ({ onClick, className, needRoles=[], tooltipText='Нэмэлт мэдээлэл бөглөх', icon='far fa-plus-circle' }) =>
{
    const hasPerm = useRole(needRoles, PERM_UPDATE)

    if (!hasPerm)
        return null

    return (
        <OverlayTrigger
            placement="top"
            delay={{ show: 0, hide: 0 }}
            overlay={<Tooltip id="button-tooltip-2">{tooltipText}</Tooltip>}
        >
            <div onClick={onClick} style={{ cursor: 'pointer' }}>
                <i style={{ color: MAIN_DARKBLUE_COLOR }} className={`${icon} ${className}`}></i>
            </div>
        </OverlayTrigger>
    );
}

export default AddMore
