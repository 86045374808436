import { useState, useEffect } from 'react';

import GeoJSON from 'ol/format/GeoJSON';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';
import useMapContext from "hooks/useMapContext"

import CButton from 'components/main/Button'
import HrText from 'components/main/HrTitle';
import CForm from 'components/main/Form';
import RoleRequired from 'components/main/RoleRequired';
import OverlayElement from 'components/main/OverlayElement';
import UpdateButton from 'components/main/CudButtons/Update';

import ApartmentAction from "pages/Company/Project/ProjectTabs/Apartment/ApartmentAction"

import { DATA_PROJECTION, FEATURE_PROJECTION } from 'hooks/map/CMap';
import { dataToValueLabel } from 'utils/format';
import { companyPageRoles } from 'utils/consts/roles';

export default function ApartmentDetail({ data, removeOverlay, feature, cMap, projectId, apartmentId })
{
    const [ isDetail, setIsDetail ] = useState(true);
    const [ hasChoose, setHasChoose ] = useState(false)
    const [ createdAparts, setCreatedAparts ] = useState([])

    const { setChoosedFeature } = useMapContext()

    const apartApi = useApi({ isDisplayToast: false }).apartment
    const apartToastApi = useApi({ isDisplayToast: true }).apartment
    const { isLoading, Loader, fetchData } = useLoader({ hasBackground: false })

    useEffect(
        () =>
        {
            getAparts()
        },
        [feature]
    )

    /** өмнө нь үүсгэсэн байсан apart ийг сонгох нь */
    const getAparts = async () =>
    {
        const { success, data, error } = await fetchData(apartApi.createdAparts(projectId)).catch(err => err)
        if (success)
        {
            if (data.length > 0)
            {
                setCreatedAparts(data)
                setHasChoose(true)
            }
        }
    }

    /** Өмнө нь үүссэн байсан apart аас сонгохгүйгээр шинээр үүсгэх нь */
    const letsCreate = () =>
    {
        setHasChoose(false)
    }

    if (!feature || Object.keys(feature).length === 0) return null
    const isCreate = apartmentId ? false : true

    const getGeneralPlan = (apartId, apartName) =>
    {
        // одоо сонгогдсон feature дээр apartm_id оноож өгөх
        const format = new GeoJSON()

        let layer = cMap.map.getLayers().getArray().find((layer, idx) => layer.get("name") === "plan")
        let features = layer.getSource().getFeatures()
        features.find(
            (element) =>
            {
                if(element.get('Text') === 'Барилга' && parseInt(element?.get('apartment_id')) === parseInt(apartId))
                {
                    element.unset('apartment_id')
                    element.unset('apartment_name')
                    return
                }
                return
            }
        )

        feature.setProperties({ 'apartment_id': parseInt(apartId) })
        feature.setProperties({ 'apartment_name': apartName })

        let storage = [ ...layer.getSource().getFeatures() ]
        storage.map(f =>
            {
                f.unset("cproject")
                f.unset("data")
            }
        )
        const stringFeatures = format.writeFeatures(storage, { dataProjection: DATA_PROJECTION, featureProjection: FEATURE_PROJECTION})

        const centerOfFeature = cMap.getCenterFromExtent(feature.getGeometry().getExtent())
        const transformedCoordinate = cMap.transfrorm3857to4326(centerOfFeature)

        const body = {
            point: transformedCoordinate,
            general_plan: stringFeatures,
            polygon: format.writeFeature(feature, { dataProjection: DATA_PROJECTION, featureProjection: FEATURE_PROJECTION })
        }
        return body
    }

    /** Өмнө нь бүртгэсэн хэрнээ геом сонгоогүй орон сууцийг хадгалах */
    const btnOnSubmit = async (fData) =>
    {
        const apartment = createdAparts.find((apart, idx) => fData.apart === apart.id)
        if (apartment === -1)
        {
            return
        }
        const { id, name } = apartment
        const body = getGeneralPlan(id, name)
        const { success, data, error } = await fetchData(apartToastApi.put(projectId, id, body)).catch(err => err)
        if (success)
        {
            setChoosedFeature(prev => ({ ...prev, feature: feature, apartmentId: id }))
        }

    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "select",
                registerName: "apart",
                placeholder: "Орон сууцаас сонгох",
                label: {
                    text: "Өмнө нь бүртгэсэн геомгүй орон сууцаас сонгох",
                    empty: true,
                },
                options: dataToValueLabel(createdAparts, "id", "name"),
                colMd: 12,
                disabled: !isCreate,
            },
        ],
        button: {
            onSubmit: btnOnSubmit,
            disabled: isLoading,
            isLoading: isLoading,
            title: 'Хадгалах',
        },
        validate: {
            validateMode: "all",
        },
    }

    const handleUpdate = () =>
    {
        setIsDetail(prev => !prev)
    }

    if (isLoading)
    {
        return Loader
    }

    return (
        isCreate
        ?
            hasChoose
            ?
                <div className='d-flex flex-column'>
                    <CForm
                        form={form}
                    />
                    <HrText>
                        эсвэл
                    </HrText>
                    <CButton onClick={letsCreate}>
                        Шинээр үүсгэх
                    </CButton>
                </div>
            :
                <ApartmentAction apartId={"create"} getGeneralPlan={getGeneralPlan} proId={projectId} />
        :
            <>
                <div className="d-flex">
                    <div className="ms-auto">
                        {
                            !isDetail
                            ?
                                <RoleRequired
                                    isNavigate={false}
                                    needRoles={companyPageRoles}
                                >
                                    <OverlayElement
                                        placement="left"
                                        text="Дэлгэрэнгүй"
                                    >
                                        <div
                                            role="button"
                                            onClick={handleUpdate}
                                        >
                                            <i className="far fa-eye"></i>
                                        </div>
                                    </OverlayElement>
                                </RoleRequired>
                            :
                                <UpdateButton onClick={handleUpdate} needRoles={companyPageRoles} />
                        }
                    </div>
                </div>
                <ApartmentAction isDetail={isDetail} apartId={apartmentId} getGeneralPlan={getGeneralPlan} proId={projectId} />
            </>
    )
}
