import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import CreateButton from 'components/main/CudButtons/Create';
import DeleteButton from 'components/main/CudButtons/Delete';
import UpdateButton from 'components/main/CudButtons/Update';
import GetOneDetail from 'components/special/GetOneDetail';
import Datatable from 'components/main/Datatable'

import useLoader from 'hooks/useLoader';
import useModal from 'hooks/useModal';
import useApi from 'hooks/useApi';

import { PERM_IS_SUPER_USER } from 'utils/consts';
import { timeZoneToDateString } from 'utils/format';

const LicenseType = () => {

    const [ rows, setRows ] = useState([]);

    const navigate = useNavigate();

    const licenseTypeApi = useApi().licenseType

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })

    const { setDisplay } = useModal()
    const dRef = useRef(null)

    /** Modal гаргаж ирэх funct */
    const displayDetail = (id) =>
    {
        setDisplay(
            {
                header:
                {
                    title: "Тусгай зөвшөөрлийн дэлгэрэнгүй",
                },
                body: <GetOneDetail api={licenseTypeApi.getOne(id)}/>,
                size: "lg",
                footer:
                {
                    show:false
                }
            }
        )
    }

    /** Хүснэгтийн багана */
    const columns =
    [
        {
            field: "topic",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span className='tablerow-detail' onClick={() => displayDetail(row.id)}>{fieldValue}</span>
        },
        {
            field: "index",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "licensed_by",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue)}</span>,
        },
        {
            field: "license_year",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>,
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтний утгыг авах */
    const getData = async () =>
    {
        if (!dRef.current) return
        dRef.current.firstGet = true
        dRef.current.reData({}, null, [])
    }

    /** Хүснэгтийн мөрийн утгыг устгах */
    const deleteRow = async (id) =>
    {
        await fetchData(licenseTypeApi.delete(id))
        await getData()
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.id}/`)} needRoles={[PERM_IS_SUPER_USER]} />
                        <DeleteButton onClick={() => deleteRow(item.id)} needRoles={[PERM_IS_SUPER_USER]} />
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
        <Row>
            <Col sm={12}>
                <CreateButton btnClassName='floatRight' onClick={() => navigate('create/')} needRoles={[PERM_IS_SUPER_USER]} />
            </Col>
            <Col>
                <Datatable
                    columns={columns}
                    extraColumns={extraColumns}
                    sequenceColumn={sequenceColumn}
                    request={
                        {
                            axios: licenseTypeApi.getPagination,
                            params: [],
                        }
                    }
                />
            </Col>
        </Row>
    );
}

export default LicenseType;
