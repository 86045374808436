import React from 'react';

import useRole from 'hooks/useRole';

import CButton from 'components/main/Button';

import { PERM_CREATE } from 'utils/consts'

/** crud үйлдэл хийхдээ энэхүү create ийг ашиглана */
const CreateButton = ({ onClick, className='', btnClassName, needRoles=[], title="Үүсгэх", icon='far fa-plus-circle' }) =>
{
    const hasPerm = useRole(needRoles, PERM_CREATE)

    if (!hasPerm)
        return <div style={{ minHeight: '30px' }}></div>

    return (
        <CButton  onClick={onClick} className={btnClassName}>
            <i className={`${icon} ${className}`}></i>{" "}{title}
        </CButton>
    );
}

export default CreateButton;
