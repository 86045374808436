import React from 'react'

import css from '../../style.module.css'

export default function Information({ icon="", label="", value="" })
{
    return (
        <p className={css.info}><i className={icon}></i> {label}: {value}</p>
    )
}
