import React, { useEffect, useMemo, useState } from 'react';

import Slider from 'components/main/Slider';

import { RESULT_ICONS } from 'utils/consts';

import { createEmpty, extend } from 'ol/extent'
import { apartLocationStyle } from 'hooks/map/helper'

import css from './style.module.css'
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

const datas = {
    from_bus_station: ["bus-stop", "Автобус зогсоол"],
    from_kinder_garden: ["kindergarten", "Цэцэрлэг"],
    from_school: ["school", "Сургууль"],
    from_emneleg: ["hospital", "Эмнэлэг"],
    from_emiin_san: ["emiin_san", "Эмийн сан"],
}

const ResultsItems = ({ data, map, cMap, point, fn }) =>
{

    const [ nearLayers, setLayers ] = useState([])
    const [ pointLayer, setPoint ] = useState([])
    const [ active, setActive ] = useState('')

    /** feature дээр дарах үед */
    const handleClickMap = (event) =>
    {
        const feature = event?.selected?.[0]
        if (!feature)
            return
        setActive(feature.get("cMap-key") ? feature.get("cMap-key") : "")
    }

    /** Цэгүүдийг газрын зурагт харуулах */
    const showPoints = () =>
    {
        if (!data) return
        let extent = createEmpty();

        let pointFeature = cMap.parseGeoJsonToFeature(point)
        pointFeature.map(f => f.set("cMap-name", 'Орон сууц'))
        if (pointLayer.length === 0)
        {
            const { vectorSource: apartVectorSource, vectorLayer } = cMap.addVectorLayer(pointFeature, null, 'apart', (...args) => apartLocationStyle(...args))
            pointLayer.push(vectorLayer)
            extend(extent, apartVectorSource.getExtent());
        }
        else {
            extend(extent, pointLayer[0].getSource().getExtent());
        }

        Object
            .entries(datas)
            .map(([key, value]) =>
                {
                    let nearPoint = data[key + "_point"]
                    if (nearPoint)
                    {
                        let nearPointFeature = cMap.parseGeoJsonToFeature(nearPoint)
                        data[key + "_ft"] = nearPointFeature
                        let nearPointName = data[key]
                        nearPointFeature.map(
                            f =>
                            {
                                f.set("cMap-name", nearPointName)
                                f.set("cMap-code", value[0])
                                f.set("cMap-key", key)
                            }
                        )
                        let source = null
                        if (nearLayers.length === 0)
                        {
                            const { vectorSource, vectorLayer } = cMap.addVectorLayer(nearPointFeature, null, 'near', (...args) => apartLocationStyle(...args))
                            source = vectorSource
                            nearLayers.push(vectorLayer)
                            cMap.createSelect(
                                "click",
                                handleClickMap,
                                null,
                                [vectorLayer]
                            )
                        }
                        else {
                            source = nearLayers[0].getSource()
                            source.addFeatures(nearPointFeature)
                        }
                        if (active && active === key)
                        {
                            extend(extent, source.getExtent());
                        }
                        else if(!active)
                        {
                            extend(extent, source.getExtent());
                        }
                    }
                }
            )
        cMap.fitToExtent(extent, { padding: [30, 30, 30, 30], duration: 200, maxZoom: 22 })
    }

    var prevFt

    const jump = (dataKey) =>
    {
        let ftkey = dataKey + "_ft"
        let fts = data[ftkey]
        if (fts.length > 0 && pointLayer.length > 0)
        {
            let clickedFeature = fts[0]

            nearLayers[0].getSource().getFeatures().map(
                (feat) =>
                {
                    feat.setStyle(undefined)
                }
            )
            prevFt = clickedFeature
            var style = new Style({
                image: new CircleStyle(
                    {
                        radius: 13,
                        stroke: new Stroke({
                            color: '#E80090',
                            width: 5
                        }),
                    }
                ),
                zIndex: 0
            });
            clickedFeature.setStyle(style);
            // let extent = createEmpty();
            // const pnt = pointLayer[0]
            // extend(extent, ft.getGeometry().getExtent());
            // extend(extent, pnt.getSource().getExtent());
            // cMap.fitToExtent(extent, { padding: [30, 30, 30, 30], duration: 200, maxZoom: 22 })
        }
    }

    /** тухайн нэг газрын карт дарах үед */
    const handleClick = (dataKey) =>
    {
        jump(dataKey)
        setActive(dataKey)
    }

    useEffect(
        () =>
        {
            if (map && point)
            {
                if (fn.length === 0)
                {
                    fn.push(showPoints)
                }
                showPoints()
            }
        },
        [map, point, fn]
    )

    const displayItems = useMemo(
        () =>
        {
            const items =  Object
                .entries(datas)
                .map(([key, value]) =>
                    {
                        if (!data?.[key]) return null
                        return (
                            <div key={key} onClick={() => handleClick(key)} className={css.cardItem + " " + (active === key ? css.activeselect : "")}>
                                <div className={`${css.listItem} align-items-center`}>
                                    <div className={css.title}>
                                        <i className={RESULT_ICONS[value[0]] + " " + "fa-lg"}></i> {value[1]}
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className={css.nameIcon}>
                                            {data[key]}
                                        </div>
                                        <div className={css.countNumber}>{data[key + "_len"]}m</div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                )
            return items
        },
        [data, active]
    )

    const isEmpty = displayItems.filter(el => el === null).length === displayItems.length

    return (
        <div className={css.sliderContainer}>
            {
                !isEmpty
                &&
                    <Slider
                        freeMode={false}
                        autoplay={true}
                        autoplayDelay={2000}
                        spaceBetween={10}
                        pagination={true}
                        navigation={false}
                        items={displayItems}
                        slidesPerView={1}
                        loop={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            1024: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            1280: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                        }}
                    />
            }
        </div>
    );
}

export default ResultsItems;
