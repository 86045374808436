import React from 'react'

const GENDER_TYPES = {
    1: <i className="fal fa-male text-darkBlue"></i>,
    2: <i className="fal fa-female text-pink"></i>,
    3: <i className="fal fa-genderless"></i>
}

/** Хүйсээр нь ялгаж icon харуулах нь */
export default function GenderIcons({ gender })
{
    const genderIcon = GENDER_TYPES?.[gender]

    if (!genderIcon)
    {
        return GENDER_TYPES[3]
    }

    return genderIcon
}
