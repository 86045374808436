import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground'
import Form from 'components/main/Form';

import { materialTypeValidate } from 'utils/validate';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

export default function MaterialTypeForm({ getData })
{
    const materialApi = useApi().material
    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const { id } = useParams()
    const isCreate = id.endsWith("-new")
    const [ inputValues, setInputValues ] = useState({})

    useEffect(
        () =>
        {
            if (!isCreate)
            {
                fetchData(materialApi.type.getOne(id))
                    .catch(error => error)
                    .then(
                        ({ success, data, errors }) =>
                        {
                            if (success)
                            {
                                setInputValues(data)
                            }
                        }
                    )
            }
            else {
                setInputValues({})
            }
        },
        [id]
    )

    const register = async (body) =>
    {
        const { success, data, errors } = await fetchData(materialApi.type.create(body)).catch(error => error)
        if (!success)
        {
            return errors
        }
        else {
            getData()
        }
    }

    const registerInputs =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "mattype",
                disabled: true,
                isLoading: isLoading,
                colMd: 12
            },
        ],
        button:
        {
            show: false,
            onSubmit: register,
            title: isCreate ? "Үүсгэх" : "Засах",
            disabled: isLoading,
            isLoading: isLoading,
        },
        validate:
        {
            validateMode: "all",
            yupValidate: materialTypeValidate
        }
    }

    return (
        <Row>
            <Col className='mb-3'>
                <BoxBackground
                    title={"Материалын төрөл"}
                    body={
                        <Form form={registerInputs} inputValues={inputValues} />
                    }
                />
            </Col>
        </Row>
    )
}
