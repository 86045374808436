import React from 'react'

import useMobile from 'hooks/useMobile'

import { useFrame } from 'context/frameContext'

import css from './style.module.css'

export default function Frame()
{

    const { isMobile } = useMobile()
    const { handleClose, frame } = useFrame()

    const overlay = !!frame?.url

    return (
        <div id="video-overlay" className={`${css['video-overlay']} ${overlay && css.open}`}>
            {
                overlay
                &&
                    <>
                        <iframe
                            width={isMobile ? "90%" : frame.width}
                            height={isMobile ? "95%" : frame.height}
                            src={frame.url}
                            frameBorder="0"
                            allowFullScreen
                        >
                        </iframe>
                        <a className={css['video-overlay-close']} onClick={handleClose}>&times;</a>
                    </>
            }
        </div>
    )
}
