import Accordion from 'react-bootstrap/Accordion';

import css from '../style.module.css'

import Loader from 'components/main/Loader/Medium'

import RoomChoice from './RoomChoice';
import Floor from './Floor';
import Mkv from './Mkv'
import SearchResults from './SearchResults'
import AU from './AU';

import useMapContext from 'hooks/useMapContext';
import CButton from 'components/main/Button';

import { FILTER_PERPAGE } from 'context/special/mapContext'
import { useEffect, useState } from 'react';
import useForceUpdate from 'hooks/useForceUpdate';

export default function General()
{
    const { cMapState, resetAllFilters, allFiltersToMap, loading, allFilters, setFilterResult, initFilterResult } = useMapContext()
    const [ fns, setFns ] = useState({})

    useEffect(
        () =>
        {
            allFilters['pPage'] = 1
            allFilters['pPerPage'] = FILTER_PERPAGE
        },
        []
    )

    const handleClear = () =>
    {
        resetAllFilters()
        allFilters['pPage'] = 1
        allFilters['pPerPage'] = FILTER_PERPAGE
        cMapState.removeLayer('building-point')
        cMapState.showLayerName("building")
        setFilterResult(initFilterResult)
        fns.mkv({
            'mkv-begin': 0,
            'mkv-end': 0,
        })
        fns.floor({
            'floor-begin': 0,
            'floor-end': 0,
        })
        fns.au()
        fns.room(null)
        cMapState.displayOverlay(undefined)
    }

    const handleFilter = () =>
    {
        cMapState.hideLayerName("building")
        allFiltersToMap()
    }

    return (
        <div className='position-relative'>
            {
                loading
                &&
                    <Loader absolute={true} hasBackground={true} />
            }
            <SearchResults />
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Засаг захиргааны нэгж</Accordion.Header>
                    <Accordion.Body>
                        <AU fns={fns}></AU>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Ерөнхий мэдээлэл</Accordion.Header>
                    <Accordion.Body>
                        <Mkv fns={fns} />
                        <hr />
                        <Floor fns={fns} />
                        <hr />
                        <RoomChoice fns={fns} />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <div className='d-flex justify-content-end align-items-end mt-2'>
                <CButton
                    variant="primary"
                    className="ms-2"
                    onClick={handleFilter}
                >
                    Хайх
                </CButton>
                <CButton
                    variant="secondary"
                    className="ms-2"
                    style={
                        {
                            margin: "10px"
                        }
                    }
                    onClick={handleClear}
                >
                    Болиулах
                </CButton>
            </div>

            <div>
                <ul className={css.sanamj}>
                    <li className={css.sanamjLi}>
                        <i className='fa fa-info-circle text-primary me-1'></i>
                        Санамж:
                        <ol>
                            <li>
                                <b>Хаяг: </b>Хаяг, зам, талбайн нэрийг оруулна уу.
                            </li>
                            <li>
                                <b>Засаг захиргааны нэгж:</b> Жагсаалтаас сонгоно уу.
                            </li>
                        </ol>
                    </li>
                    <li className={css.sanamjLi}>
                        <i className='fa fa-info-circle text-primary me-1'></i>
                        Илэрц:
                        <ol>
                            Үл хөдлөх хөрөнгийн нэр, талбай, үнэлгээ, түрээс, Орон сууцны орчны болон дэд бүтцийн мэдээллийг агуулна.
                        </ol>
                    </li>
                </ul>
            </div>
        </div>
    )
}
