import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import useApi from 'hooks/useApi'
import useLoader from 'hooks/useLoader'

import TitleValue from './TitleValue'
import { moneyFormat, timeZoneToDateString } from 'utils/format'

export default function Detail({ id })
{
    const customerApi = useApi().buildingTable
    const [ data, setData ] = useState({})
    const { fetchData, isLoading, Loader } = useLoader({})

    const apis = {
        'buy-sell': customerApi['buy-sell'].getOne,
        'turees': customerApi['turees'].getOne,
        'zassan': customerApi['zassan'].getOne,
        'menegment': customerApi['menegment'].getOne,
        'busad': customerApi['busad'].getOne,
        'zohih-guilgee': customerApi['zohih-guilgee'].getOne,
    }

    const { tableId } = useParams()

    useEffect(
        async () =>
        {
            if (!id)
                return
            const { success, data, error } = await fetchData(apis[tableId](id)).catch(er => er)
            if (success)
            {
                setData(data)
            }
        },
        [id]
    )

    if (!id)
    {
        return null
    }

    return (
        <div>
            {isLoading && Loader}
            {
                data?.ul_hudluh_hurungiin_turul
                &&
                    <TitleValue title={"ul_hudluh_hurungiin_turul"} value={data?.ul_hudluh_hurungiin_turul_display} />
            }
            {
                data?.hariltsagch_info?.irgen_huuliin_etgeedin_ner
                &&
                    <TitleValue title={"irgen_huuliin_etgeedin_ner"} value={data?.hariltsagch_info?.irgen_huuliin_etgeedin_ner} />
            }
            {
                data?.hariltsagch_info?.irget_huuliin_etgeedin_haryalal
                &&
                    <TitleValue title={"irget_huuliin_etgeedin_haryalal"} value={data?.hariltsagch_info?.irget_huuliin_etgeedin_haryalal} />
            }
            {
                data?.hariltsagch_info?.register
                &&
                    <TitleValue title={"register"} value={data?.hariltsagch_info?.register} />
            }
            {
                data?.geree_info?.dugaar
                &&
                    <TitleValue title={"dugaar"} value={data?.geree_info?.dugaar} />
            }
            {
                data?.geree_info?.geree_gerchilgee_dugaar
                &&
                    <TitleValue title={"geree_gerchilgee_dugaar"} value={data?.geree_info?.geree_gerchilgee_dugaar} />
            }
            {
                data?.bairshil_info?.aimar_hot_info?.name
                &&
                    <TitleValue title={"Аймаг/Хот"} value={data?.bairshil_info?.aimar_hot_info?.name} />
            }
            {
                data?.bairshil_info?.sum_duureg_info?.name
                &&
                    <TitleValue title={"Сум/Дүүрэг"} value={data?.bairshil_info?.sum_duureg_info?.name} />
            }
            {
                data?.bairshil_info?.bag_horoo_info?.name
                &&
                    <TitleValue title={"Баг/Хороо"} value={data?.bairshil_info?.bag_horoo_info?.name} />
            }
            {
                data?.building_info?.full_name
                &&
                    <TitleValue title={"full_name"} value={data?.building_info?.full_name} />
            }
            {
                data?.building_info?.building?.full_name
                &&
                    <TitleValue title={"full_name"} value={data?.building_info?.building?.full_name} />
            }
            {
                data?.mkv
                &&
                    <TitleValue title={"Талбайн хэмжээ"} value={`${data?.mkv} мкв`} />
            }
            {
                data?.hudaldaj_awsan_une
                &&
                    <TitleValue title={"hudaldaj_awsan_une"} value={`${moneyFormat(data?.hudaldaj_awsan_une)}`} />
            }
            {
                data?.olz_garz
                &&
                    <TitleValue title={"olz_garz"} value={`${moneyFormat(data?.olz_garz)}`} />
            }
            {
                data?.zarsan_une
                &&
                    <TitleValue title={"zarsan_une"} value={`${moneyFormat(data?.zarsan_une)}`} />
            }
            {
                data?.zaswar_zardal
                &&
                    <TitleValue title={"zaswar_zardal"} value={`${moneyFormat(data?.zaswar_zardal)}`} />
            }
            {
                data?.per_month_price
                &&
                    <TitleValue title={"per_month_price"} value={`${moneyFormat(data?.per_month_price)}`} />
            }
            {
                data?.total_price
                &&
                    <TitleValue title={"total_price"} value={`${moneyFormat(data?.total_price)}`} />
            }
            {
                data?.uil_ajillagaanii_turul_display
                &&
                    <TitleValue title={"uil_ajillagaanii_turul"} value={data['uil_ajillagaanii_turul_display']} />
            }
            {
                data?.uilchilgee_description
                &&
                    <TitleValue title={"uilchilgee_description"} value={data['uilchilgee_description']} />
            }
            {
                data?.price
                &&
                    <TitleValue title={"price"} value={moneyFormat(data?.price)} />
            }
            {
                data?.gereenii_hugatsaa
                &&
                    <TitleValue title={"gereenii_hugatsaa"} value={data['gereenii_hugatsaa']} />
            }
            {
                data?.geree_info?.huwi?.toString()
                &&
                    <TitleValue title={"Хувь"} value={`${data?.geree_info?.huwi} %`} />
            }
            {
                data?.geree_info?.shimtgel_dun
                &&
                    <TitleValue title={"shimtgel_dun"} value={`${data?.geree_info?.shimtgel_dun}`} />
            }
            {
                data?.geree_info?.ognoo
                &&
                    <TitleValue title={"ognoo"} value={`${timeZoneToDateString(data?.geree_info?.ognoo)}`} />
            }
        </div>
    )
}
