
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Program from '.';
import ProgramAction from './ProgramAction';
import TabRoutes from './ProgramTabs/routes'

import BoxBackground from 'components/special/BoxBackground';

const ProgramCrudMain = () => {
    return (
        <Routes>
            <Route path="/" element={<BoxBackground title='Хөтөлбөр' body={<Program/>} />} />
            <Route path="/:programId/" element={<ProgramAction/>} />
            <Route path="/:programId/detail/*" element={<TabRoutes/>} />
        </Routes>
    );
}

export default ProgramCrudMain;
