import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from "react-router-dom"
import { Col, Container, Row } from 'react-bootstrap';

import BoxBackground from 'components/special/BoxBackground';
import ImageFetch from 'components/main/Image';

import useLoader from 'hooks/useLoader';
import useMobile from 'hooks/useMobile';
import useApi from 'hooks/useApi';

import DelgerengviSlider from './DelgerengviSlider';
import CompanyInfo from './CompanyInfo';
import GeneralInfo from './GeneralInfo';
import AboutApartment from './AboutApartment';
import RoomSizes from './RoomSizes';
import SongonList from './SongonList';

import label from 'utils/validate/label';

import css from './style.module.css'

const BuildingDetail = () => {
	const { apartId, residenceId } = useParams()
	const [ data, setData ] = useState({});
	const { isLoading, Loader, fetchData } = useLoader({ isFullScreen: true })
	const buildingApi = useApi().building

	const { isMobile } = useMobile()

	const getData = async () =>
	{
		const { success, data } = await fetchData(buildingApi.allInfo(apartId, residenceId))
		if(success)
		{
			setData(data)
		}
	}

	useEffect(
		() =>
		{
			getData()
		},
		[]
	)

	const getImages = useMemo(
		() =>
		{
			let images = []
			data?.project_info?.eskiz?.map(
				(element, index) =>
				{
					images.push({
						image: element.eskiz,
						title: label['eskiz']
					})
				}
			)
			images.push({
				image: data?.apartment_info?.real_photo,
				title: label['real_photo']
			})
			data?.rooms?.map(
				(element) =>
				{
					images.push({
						image: element.image,
						title: element.room_name
					})
				}
			)
			// images.push({
			// 	image: data?.plan_img,
			// 	title: label['general_plan']
			// })
			return images
		},
		[data]
	)

	return (
		<div className="skipHeader">
			<DelgerengviSlider data={getImages} />
			{
				isLoading
				?
					Loader
				:
					<Container>
						<Row className={css.row}>
							<Col lg={8}>
								{
									isMobile && data.has_rent
									&&
										<BoxBackground className={css.songonContainer} title="Сонгон шалгаруулалт" body={<SongonList />} />

								}
								<GeneralInfo data={data} />
								{
									isMobile
									&&
									<RoomSizes rooms={data?.rooms} />
								}
								<AboutApartment apartment={data?.apartment_info} data={data} />
								<BoxBackground className={css.imageContainer} title="PLAN зураг">
									<ImageFetch className={css.planImage} src={data?.plan_img} />
								</BoxBackground>
							</Col>
							<Col lg={4}>
								{
									!isMobile && data.has_rent
									&&
										<BoxBackground className={css.songonContainer} title="Сонгон шалгаруулалт" body={<SongonList />} />

								}
								<CompanyInfo data={data.project_info} />
								{
									!isMobile
									&&
										<RoomSizes rooms={data?.rooms} />
								}
							</Col>
						</Row>
					</Container>
			}
		</div>
	)
}

export default BuildingDetail;
