import React, { useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import BackButton from 'components/main/BackButton';
import UserNotification from 'components/special/UserNotification';

import useMobile from 'hooks/useMobile';
import useAuth from 'hooks/useAuth';

import css from '../style.module.css'

/** Sidebar ний header */
const SidebarHeader = ({ links, Toggler }) =>
{
    const location = useLocation()
    const navigate = useNavigate()
    const { signOut } = useAuth()
    const { isMobile } = useMobile()

    /** Sidebar дээр сонгогдсон замны нэрийг нь авах функц */
    const getName = useMemo(
        () =>
        {
            let name = "";
            links.find((element, index) =>
            {
                if (element.path === "/")
                {
                    name = element.name;
                } else
                {
                    if (location.pathname.includes(element.path.replace("/*", "/"))) name = element.name;
                }
            });
            return name;
        },
        [location.pathname]
    );

    return (
        <div className={css.sidebarHeader}>
            {Toggler}
            {
                !isMobile && <BackButton links={links}/>
            }
            <span className={css.headerTitle}>{getName}</span>
            <div className="d-flex align-items-center">
                {
                    location.pathname.startsWith('/user/')
                    ?
                        <div className="mt-2">
                            <UserNotification />
                        </div>
                    :
                        null
                }
                <Dropdown >
                    <Dropdown.Toggle variant="ghost" id="dropdown-basic">
                        <i className={`far fa-user-circle ${css.dropdownIcon}`}></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={ () => navigate('/user/') } value='fucaaz'><i  className="fas fa-user me-1"/>Профайл</Dropdown.Item>
                        <Dropdown.Item onClick={ () => navigate('/user/options/') } value='asdasd'><i  className="fas fa-cog me-1"/>Тохиргоо</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => signOut()} value='gg'><i  className="fas fa-power-off me-1"/>Гарах</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
}

export default SidebarHeader;
