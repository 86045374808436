import { Route, Routes } from "react-router-dom"

import ProjectLinkedSubAgendaMain from "."
import ProjectLinkedSubAgendaAction from "./ProjectAction"
import ProjectLinkedSubAgendaActionTabRoutes from "./ProjectTabs/routes"

const ProjectLinkedSubAgenda = () =>
{

    return (
        <Routes>
            <Route path='/' element={<ProjectLinkedSubAgendaMain/>} />
            <Route path="/:id/" element={<ProjectLinkedSubAgendaAction/>} />
            <Route path="/:id/details/*" element={<ProjectLinkedSubAgendaActionTabRoutes/>} />
        </Routes>
    )
}

export default ProjectLinkedSubAgenda
