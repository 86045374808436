import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import ReactToPrint from 'react-to-print';

import CButton from '../Button'

import Thead from './Thead'
import Tbody from './Tbody'

import tableCss from './style.module.css'

/*
    нэг мөр датаг өгөх нь
    columns [
        {
            field: "phoneNumber",
            headerName: "Phone number",
            width: 70,
            description: "Ene bol tailbar"
        },
    ]

    нэг мөр датаг өгөх нь
    rows [
        {
            id: {
                value: 9,
                colSpan: 2,
                className: "bg-warning"
            },
            userName: "odko",
            phoneNumber: {
                value: <span className='text-success'>88195869</span>
            },
            age: {
                value: <span className='text-success'>20</span>
            },
        },
    ]

    Дэс дугаарыг тохиргоо
    sequenceCol = {
        headerName: "dd",
        startNumber: 100,
    }

    table ийн тохиргоо
    options = {
        bordered: false,
        striped: true,
        hover: true,
        className: "",
    }

    Нэмэлт багана оруулах нь ACTION
    const extraColumns = [{
        field: (item, idx) => {
            return (
                <button onClick={() => alert(item.id)}>
                    Устгах
                </button>
            )
        },
        headerName: "Action",
        width: 70
    }]

    Нэмэлтээр мөрийг оруулах нь
    const extraRows = [
        [
            {
                hasNotColumn: true,
                value: <small>Таны дата</small>,
                colSpan: columns.length + extraColumns.length + 1
            }
        ],
        [
            <span>39</span>, 'kherlen', '88154545', { value: 20, colSpan: 2 }
        ]
    ]

*/
export default function CTable({
    columns,
    sequenceColumn,
    rows,
    options,
    extraColumns,
    extraRows,
    onOrderChange,
    extraFirstRows,
    getWidth,
    isLoading,
    setChoosenOrder,
    tableId="",
    colSpanHeader,
    downloadAble=false,
    excelInfo={},
    printHeader=null,
    handleFilterChangeDataTable=null,
    extraHeaderElements,
    checkBoxOptions,
    onCheckAll,
    onCheck
})
{
    let [ matches, setMatches ] = useState({})
    const tableRef = useRef(null)

    const getOption = (optionName, initValue) =>
    {
        if (!options || typeof !options?.[optionName] !== 'boolean') {
            return initValue
        }

        return options[optionName]
    }
    function fnExcelReport() {
        var tab = document.getElementById(tableId); // id of table
        var start = `
        <html xmlns:x="urn:schemas-microsoft-com:office:excel">
        <head>
            <meta http-equiv="content-type" content="text/plain; charset=UTF-8"/>
            <xml>
                <x:ExcelWorkbook>
                    <x:ExcelWorksheets>
                        <x:ExcelWorksheet>
                            <x:WorksheetOptions>
                                <x:DisplayGridlines/>
                            </x:WorksheetOptions>
                        </x:ExcelWorksheet>
                    </x:ExcelWorksheets>
                </x:ExcelWorkbook>
            </xml>
        </head>
        <body>
        `
        var end = `</body>
        </html>
        `
		var tab_text = "<table border='1px'><tr bgcolor='#87AFC6'>"
        var title = `<center  >${excelInfo.title}</center>`
        var date = `<text align='right' >${excelInfo.date}</text>`
		var j = 0

		for (j = 0; j < tab.rows.length; j++) {
			tab_text = tab_text + tab.rows[j].innerHTML + "</tr>"
		}

		tab_text = tab_text + "</table>"
		tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, "")
		tab_text = tab_text.replace(/<img[^>]*>/gi, "")
		tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, "")

		return window.open("data:application/vnd.ms-excel," + encodeURIComponent(start+title + date + tab_text+end))
	}

    const printBody = `
        body
        {
            font-family: Arial;
        }
        table
        {
            border: 1px solid #ccc;
            border-collapse: collapse;
        }
        table th
        {
            background-color: #F7F7F7;
            color: #333;
            font-weight: bold;
            font-size: 10pt;
        }
        table th, table td
        {
            padding: 2px;
            border: 1px solid #ccc;
        }
        table td {
            font-size: 8pt;
        }
    `

    function printTable() {
		var printWindow = window.open("", "", "height=600,width=800")
		printWindow.document.write("<html><head><title>Table Contents</title>")

		//Print the Table CSS.
		printWindow.document.write('<style type = "text/css">')
		printWindow.document.write(printBody)
		printWindow.document.write("</style>")
		printWindow.document.write("</head>")

		//Print the DIV contents i.e. the HTML Table.
		printWindow.document.write("<body>")
		var divContents = document.getElementById("ctableOuter")
        const bigDiv = divContents
        const clonedBigDiv = bigDiv.cloneNode(true)
        const clonedTable = clonedBigDiv.firstChild
        const theadElement = clonedTable.firstChild
        const tbodyElement = clonedTable.lastChild

        const trElement = theadElement.lastChild
        const ths = trElement.children
        /** үйлдэл гэсэн багана болон бусад нэмэлтээр нэмэгдсэн багануудыг арилгах нь */
        for (let index = 0; index < extraColumns.length; index++)
        {
            const removeElement = ths[ths.length - 1 - index]
            if (theadElement.children.length > 1)
            {
                for (let index = 0; index < theadElement.children.length - 1; index++)
                {
                    const bigTrElements = theadElement.children[index]
                    const bigThs = bigTrElements.children
                    const removeElement = bigThs[bigThs.length - 1 - index]

                    removeElement.remove()
                }
            }
            removeElement.remove()
        }

        /** нэмэлтээр нэмэгдсэн баганы body ний утгыг арилгах нь */
        const trs = tbodyElement.children
        for (let index = 0; index < trs.length; index++)
        {
            const tr = trs[index]
            const tds = tr.children
            for (let j = 0; j < extraColumns.length; j++)
            {
                const removeElement = tds[tds.length - 1 - j]
                removeElement.remove()
            }
        }

        /** Хүснэгтийн толгой дээр байрлах node ийг харуулах нь */
        const div = document.createElement("div")
        if (printHeader)
        {
            div.innerHTML = printHeader
        }
        clonedTable.before(div)

		printWindow.document.write(clonedBigDiv.innerHTML)
		printWindow.document.write("</body>")

		printWindow.document.write("</html>")
		printWindow.document.close()
		printWindow.print()
	}

    const handleFilterChange = (filters) =>
    {
        setMatches({...matches, ...filters })
    }

    useEffect(
        () =>
        {
            if (Object.keys(matches).length > 0)
            {
                handleFilterChangeDataTable && handleFilterChangeDataTable(matches)
            }
        },
        [matches]
    )

    return (
        <>
            {
                downloadAble &&
                <Row>
                    <Col className='mb-2'>
                        <div className='floatRight'>
                            <CButton id="btnExport" className='me-2' onClick={() => fnExcelReport()}>
                                <i className="fas fa-file-excel"></i> EXCEL
                            </CButton>
                            <CButton onClick={printTable}>
                                <i className="fas fa-print" />
                            </CButton>
                            {extraHeaderElements}
                        </div>
                    </Col>
                </Row>
            }
            <div
                className={`table-responsive ${tableCss["table-scroll"]}  ${tableCss["table-style"]} ${getOption("tableOuterClassName", "")}`}
                id={"ctableOuter"}
                ref={tableRef}
            >
                <Table
                    striped={getOption("striped", true)}
                    bordered={getOption("bordered", true)}
                    hover={getOption("hover", true)}
                    className={`${tableCss["table"]}  ${getOption("className", "")} mb-0`}
                    id={tableId}
                >
                    <Thead
                        sequenceColumn={sequenceColumn}
                        columns={columns}
                        hasCheckbox={checkBoxOptions && checkBoxOptions.show}
                        colSpanHeader={colSpanHeader}
                        onOrderChange={onOrderChange}
                        extraColumns={extraColumns}
                        options={options}
                        onCheckAll={onCheckAll}
                        handleFilterChange={handleFilterChange}
                        isLoading={isLoading}
                        className={getOption("headerClassName", "")}
                    />
                    <Tbody
                        setChoosenOrder={setChoosenOrder}
                        getWidth={getWidth}
                        sequenceColumn={sequenceColumn}
                        matches={matches}
                        rows={rows}
                        hasCheckbox={checkBoxOptions && checkBoxOptions.show}
                        checkBoxOptions={checkBoxOptions}
                        onCheck={onCheck}
                        isLoading={isLoading}
                        columns={columns}
                        extraRows={extraRows}
                        extraColumns={extraColumns}
                        className={getOption("bodyClassName", "")}
                        extraFirstRows={extraFirstRows}
                    />
                </Table>
            </div>
        </>
	)
}
