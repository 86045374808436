
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Residence from '.';

import ResidenceAction from './ResidenceAction';

import BoxBackground from 'components/special/BoxBackground';

const ResidenceCrudMain = () => {
    return (
        <Routes>
            <Route path="/" element={<Residence/>} />
            <Route path="/:resId/" element={<ResidenceAction/>} />
        </Routes>
    );
}

export default ResidenceCrudMain;
