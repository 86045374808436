import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {getCenter} from 'ol/extent';

import useMap from 'hooks/map/useMap'
import useApi from 'hooks/useApi'
import useMapContext from 'hooks/useMapContext'

import CustomInputGroup from 'components/main/Form/CustomInputGroup'

import { styleOfAparts, selectStyle } from 'hooks/map/helper'

export default function MapChoice({ setValue, setError, inputValues, errors, register, watch, units, disabled=false , labelType })
{
    const registerName = "geoid"
    const { setCMap, getBuildingFromNsdi, getAuBoundary, getCadasterFromNsdi } = useMapContext()

    const custmoerApi = useApi().buildingTable
    const { tableId } = useParams()

    const unit3 = watch("unit3")

    const { map, cMap } = useMap({})

    useEffect(
        () =>
        {
            if (map)
            {
                setCMap(cMap)
            }
        },
        [map]
    )

    useEffect(
        () =>
        {
            register(registerName)
            setValue(registerName, inputValues[registerName])
        },
        []
    )

    const handleFeature = (event) =>
    {
        const feature = event?.selected?.[0]
        if (!feature)
            return
        setValue(registerName, feature.getId())
        setValue("point", getCenter(feature.getGeometry().getExtent()))
    }

    /** nsdi аас ирсэн барилга дээр хуучин орон сууцийг тэмдэглэх нь */
    const setRegisterApartsToFeatures = async (layer) =>
    {
        const { success, data: registeredAparts, errors, error } = await custmoerApi.mapOldApartmentGeoIds(tableId).catch(err => err)

        const source = layer.getSource()

        let fitToFeature = null

        for (const feature of source.getFeatures())
        {
            /** бүх апарт оноогдоод дууссан үед давталт болиулах */
            if (registeredAparts.length === 0)
            {
                break
            }

            const featureId = feature.getId()
            const foundApart = registeredAparts.findIndex(el => featureId === el)
            /** хэрвээ апарт олдсон бол */
            if (foundApart !== -1)
            {

                if (disabled === true && inputValues[registerName] === featureId)
                {
                    fitToFeature = feature
                }

                const aStyle = styleOfAparts(
                    {
                        name: feature.get("text"),
                    }
                )
                feature.setStyle(aStyle)
                feature.set("cMap-style", aStyle)
                registeredAparts.splice(foundApart, 1)
            }
        }

        /** Тухайн feature рүү үсрэх */
        if (fitToFeature)
        {
            const extent = fitToFeature.getGeometry().getExtent()
            cMap.fitToExtent(extent, { duration: 500, padding: [ 0, 0, 0, 0 ] })
        }
    }

    const setBuildOnMap = async (code) =>
    {
        var vectorLayer = {}
        if(cMap)
            cMap.addLoader()
        let searchObj = {
            'unit3-code': code,
        }

        await getAuBoundary(searchObj)

        if(labelType === 1)
            vectorLayer = await getBuildingFromNsdi(searchObj)
        else if(labelType === 2)
            vectorLayer = await getCadasterFromNsdi(searchObj)

        await setRegisterApartsToFeatures(vectorLayer)
        if (cMap)
        {
            cMap.createSelect(
                'click',
                (evt) => handleFeature(evt),
                selectStyle,
                [vectorLayer]
            )
        }
        if(cMap)
            cMap.removeLoader()
    }

    useEffect(
        () =>
        {
            const found = units && units.find(
                (element) =>
                {
                    return element.value === unit3
                }
            )

            let code = null
            if (!!found)
                code = found.extraValue
            if (code)
                setBuildOnMap(code)
        },
        [unit3, units, labelType]
    )

    return (
        <CustomInputGroup registerName={registerName} errors={errors} text="Газрын зургаас сонгох:" md={12}>
            <div id="map" className={`position-relative`} style={{ height: "60vh", border: `${!!errors[registerName] ? "1px solid red" : "unset" }` }}>
                {
                    disabled
                    &&
                        <div className='backdrop'></div>
                }
            </div>
        </CustomInputGroup>
    )
}
