import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'

import RoleRequired from 'components/main/RoleRequired'
import CButton from 'components/main/Button'

import useMapContext from 'hooks/useMapContext'
import useToggler from 'hooks/useToggler'
import useApi from 'hooks/useApi'

import MapBuildingList from './MapBuildingList'
import Detail from './Detail'

import { finanialEmployeePageRoles } from 'utils/consts/roles'
import { form } from './helper'

import css from './style.module.css'

const initUp = {
    id: null,
    isDetail: false
}
export default function PopupContent()
{
    const { tableId } = useParams()

    const { choosedFeature: { isNew, feature }, shouldClosePopup } = useMapContext()
    const [ updateId, setUpdateId ] = useState(initUp)

    const { Toggler, togglerState, setTogglerState } = useToggler({
		togglerOn: (
			<CButton onClick={() => setTogglerState(v => !v)}>
				<i className="fas fa-bars"></i> Жагсаалт
			</CButton>
		),
		togglerOff: (
            <RoleRequired needRoles={finanialEmployeePageRoles} isNavigate={false}>
                <CButton
                    onClick={
                        () => setTogglerState(
                            v => {
                                setUpdateId(initUp)
                                return !v
                            }
                        )
                    }
                >
                    <i className="fas fa-clipboard-list-check"></i> Үүсгэх
                </CButton>
            </RoleRequired>
		),
	})

    const oldBuildApi = useApi().map.old

    const getOldBuildings = async (oldApart) =>
    {
        const { success, data, error } = await oldBuildApi.getBuilds(oldApart.id).catch(err => err)
        if (success)
        {
            // console.lo(data);
        }
    }

    useEffect(
        ()=>
        {
            !shouldClosePopup && setTogglerState(false)
        },
        [shouldClosePopup]
    )

    const goUpdate = (id, isDetail) =>
    {
        setTogglerState(!togglerState)
        setUpdateId({ ...initUp, id, isDetail: isDetail === "detail" })
    }

    // Тухайн apartment үүдийн барилгуудыг харуулж форм бөглөх
    const Form = useMemo(
        () =>
        {
            if (!feature || Object.keys(feature).length === 0)
                return null

            const popup = document.getElementById('popupMap')
            if (popup)
            {
                popup.style.width = "550px"
            }
            const Form = form[tableId]
            return <Form updateId={updateId.id} hasTitle={false} isNew={isNew} setTogglerState={setTogglerState} />
        },
        [tableId, feature, isNew, updateId]
    )

    return (
		<Row>
			<h2>{isNew ? "энэ бол шинэ орон сууц" : "энэ бол хуучин орон сууц"}</h2>
            <Col md={12}><div className='floatRight'>{Toggler}</div></Col>
			<div className={css.height}>
                {
                    togglerState
                    ?
                        updateId.isDetail
                        ?
                            <Detail id={updateId.id} />
                        :
                            Form
                    :
                        <MapBuildingList goUpdate={goUpdate} isNew={isNew} feature={feature} tableId={tableId} />
                }
            </div>
        </Row>
	)
}
