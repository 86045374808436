import DataNotFound from "components/main/DataNotFound"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { quantityFormat } from "utils/format"

import css from "../../style.module.css"

const SignToday = ({ logged }) => {
	if (!logged) return <div><DataNotFound /></div>
	return (
		<div className={css.todaySignWrapper} style={{ border: 'unset' }}>
			<div className={css.todayTitle}>Өнөөдрийн хэрэглэгчдийн хандалт</div>
			<Row className={css.todaySignContainer}>
				<Col xl={4}>
					<div className={css.todayAccessItem} style={{ backgroundColor: "rgba( 67,191,229, 0.25)", border: '1px solid #3a9bcf' }}>
						<div className={css.titleIcon}>
							<i style={{ color: "rgb(58, 155, 207, 0.8)" }} className="fas fa-sign-in"></i> <span>Нэвтэрсэн хэрэглэгчдийн тоо</span>
						</div>
						<div className={css.accessNumber}>{quantityFormat(logged.today.login.success_count)}</div>
					</div>
				</Col>
				<Col xl={4}>
					<div className={css.todayAccessItem} style={{ backgroundColor: "rgba(240,122,59, 0.25)", border: '1px solid #f07a3b' }}>
						<div className={css.titleIcon}>
							<i style={{ color: "rgb(247,184,75)" }} className="fas fa-sign-out"></i> <span>Гарсан хэрэглэгчдийн тоо</span>
						</div>
						<div className={css.accessNumber}>{quantityFormat(logged.today.logout.success_count)}</div>
					</div>
				</Col>
				<Col xl={4}>
					<div className={css.todayAccessItem} style={{ backgroundColor: "rgba(220,53,69, 0.25)", border: '1px solid #d93e35' }}>
						<div className={css.titleIcon}>
							<i style={{ color: "rgb(220,53,69)" }} className="fas fa-exclamation-circle"></i> <span>Нэвтрэхэд гарсан алдаа</span>
						</div>
						<div className={css.accessNumber}>{quantityFormat(logged.today.logout.error_count + logged.today.login.error_count)}</div>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default SignToday
