
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SubAgendaAction from './SubAgendaAction'
import ChooseAgenda from './ChooseAgenda';
import SubAgenda from '.';

const SubAgendaCrudMain = () => {
    return (
        <Routes>
            <Route path="/" element={<ChooseAgenda />} />
            <Route path="/:agendaId/" element={<SubAgenda />} />
            <Route path="/:agendaId/:id/" element={<SubAgendaAction/>} />
        </Routes>
    );
}

export default SubAgendaCrudMain;
