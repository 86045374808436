import React, { forwardRef, useState } from 'react';

import Label from 'components/main/Form/Label';

import CYearpicker from '..';

import '../style.css'

/**
 * @param {String} props.label Input -ийн label-д утга өгөх
 * @param {String} props.inputPlaceholder datepicker ийн input ийн placeholder
 *
 * @param {className} props.inputClassName input -д className өгөх
 *
 * @param {array} props.errors validate ийн алдаа
 *
 * @param {object} props.register useform-д бүртгүүлэх
 *
 * @returns Date picker input ийн хамт
 */
const YearpickerInput = (props) =>
{
    const { label, inputClassName, inputPlaceholder, errors, register, onCalendarClose, onCalendarOpen, disabled } = props
    const [ isOpen, setIsOpen ] = useState(false);

    const Input = forwardRef(({ value, onClick }, ref) =>
    {
        return (
            <div
                className={`
                    fs-13 cursor-pointer datepickerInput
                    ${!errors?.[register.name]?.message && isOpen ? "active-border" : "form-control" }
                    ${inputClassName}
                    ${!!errors?.[register.name]?.message ? "validateErrorBorder inputErrorIcon" : null}
                `}
                onClick={onClick}
                ref={ref}
                disabled={disabled}
            >
                {
                    value
                    ?
                        value
                    :
                        <span style={{ color: "#797979", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {inputPlaceholder ? (
                                inputPlaceholder
                            ) : (
                                <>
                                    Сонгоно уу
                                    <div>
                                        <div className='verticalDateLine'></div>
                                        <i className="ms-1 fas fa-chevron-down float-end" style={{ fontSize: "14px", color: '#CCCCCC', marginRight: '4px' }}></i>
                                    </div>
                                </>
                            )}
                        </span>
                }
            </div>
        );
    });
    return <CYearpicker {...props} setIsOpen={setIsOpen} Input={Input}/>
}

export default YearpickerInput;
