import React from 'react'
import { Route, Routes } from 'react-router-dom'

import BoxBackground from 'components/special/BoxBackground'

import News from './main'
import NewsAction from './NewsAction'

export default function NewsRoutes()
{
    return (
        <Routes>
            <Route
                path={"/"}
                element={
                    <BoxBackground
                        title='Мэдээнүүд'
                    >
                        <News />
                    </BoxBackground>
                }
            />
            <Route
                path={"/:newsId/"}
                element={
                    <BoxBackground
                        title='Мэдээ'
                    >
                        <NewsAction />
                    </BoxBackground>
                }
            />
        </Routes>
    )
}
