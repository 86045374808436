
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ProjectTabs from './index';

import ProgramDetails from './ProgramDetails'
import AgendaCrudMain from './Agenda/main';
import SubAgendaCrudMain from './SubAgenda/main';

const style = {
    backgroundColor: '#fff',
    padding: '15px'
}

const TabRoutes = () => {
    return (
		<>
			<ProjectTabs />
			<div style={style}>
				<Routes>
					<Route path="/" element={<ProgramDetails />} />
					<Route path="/agenda/*" element={<AgendaCrudMain />} />
					<Route path="/subAgenda/*" element={<SubAgendaCrudMain />} />
				</Routes>
			</div>
		</>
	)
}

export default TabRoutes;
