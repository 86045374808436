import { useEffect, useMemo, useState } from "react"
import { Col, Container, Row } from "react-bootstrap";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import ProgramChooseInputs from "../ProgramChooseInputs";

import { getColor } from "utils/color";
import label from "utils/validate/label";

import css from '../style.module.css'
import useLoader from "hooks/useLoader";
import useApi from "hooks/useApi";
import { moneyFormat } from "utils/format";
import useMobile from "hooks/useMobile";

const SubAgendaChart = ({ choosedAgenda, setChoosedAgenda, choosedProgram }) => {
	const [ chartData, setChartData ] = useState([])
	const [ activeSubAgenda, setActiveSub ] = useState()
	const [ unitMeasure, setUnitMeasure ] = useState([])

	const { isMobile } = useMobile()

	const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
	const unitApi = useApi().unitMeasure

	useEffect(
		async () =>
		{
			const { success, data } = await fetchData(unitApi.get()).catch(err => err)
			if(success)
			{
				setUnitMeasure(data)
			}
		},
		[]
	)

	useEffect(
		() =>
		{
			if(choosedProgram?.agenda?.length === 0)
			{
				setChartData([])
			}
		},
		[choosedProgram]
	)

	useEffect(() => {
		if (!choosedAgenda) return
		setChartData(
			choosedAgenda?.subagenda?.map((element, index) => {
				return {
					...element,
					result: parseFloat(element.result),
					color: getColor(parseInt(element.result) + index, 50, 2),
					// color: getColor(parseInt(element.result) + index * 2, 50, 2),
				}
			}),
		)
		setActiveSub(choosedAgenda?.subagenda[0]?.id)
	}, [choosedAgenda])

	useEffect(() => {
		if (!chartData) return
		if (chartData.length === 0) return
		let root = am5.Root.new("subAgendaChartDiv")

		root.setThemes([am5themes_Animated.new(root)])

		let chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				panX: false,
				panY: false,
				wheelX: "none",
				wheelY: "none",
				pinchZoomX: true,
			}),
		)

		let xRenderer = am5xy.AxisRendererX.new(root, {})
		xRenderer.labels.template.setAll({
			visible: false,
			labelText: "{name}",
			oversizedBehavior: "wrap",
			// maxWidth: 120,
			textAlign: "center",
		})

		xRenderer.grid.template.setAll({
			forceHidden: true,
		})

		let yRenderer = am5xy.AxisRendererY.new(root, {})

		yRenderer.grid.template.setAll({
			// forceHidden: true,
		})

		let xAxis = chart.xAxes.push(
			am5xy.CategoryAxis.new(root, {
				maxDeviation: 0.3,
				categoryField: "id",
				renderer: xRenderer,
			}),
		)
		let yAxis = chart.yAxes.push(
			am5xy.ValueAxis.new(root, {
				maxDeviation: 0.3,
				min: 0,
				max: 100,
				renderer: yRenderer,
			}),
		)

		let series = chart.series.push(
			am5xy.ColumnSeries.new(root, {
				name: "Series 1",
				xAxis: xAxis,
				yAxis: yAxis,
				valueYField: "result",
				sequencedInterpolation: true,
				categoryXField: "id",
				tooltip: am5.Tooltip.new(root, {
					labelText: "{valueY}%",
				}),
			}),
		)

		series.bullets.push(function () {
			return am5.Bullet.new(root, {
				locationX: 0.5,
				sprite: am5.Label.new(root, {
					text: "{result}%",
					fill: "#000",
					centerX: am5.percent(50),
					centerY: am5.percent(50),
					populateText: true,
				}),
			})
		})

		series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 })
		series.columns.template.adapters.add("fill", function (fill, target) {
			return target.dataItem.dataContext?.color
		})

		series.columns.template.adapters.add("stroke", function (stroke, target) {
			return target.dataItem.dataContext?.color
		})

		series.columns.template.setAll({
			maxWidth: 20,
		})

		xAxis.data.setAll(chartData)
		series.data.setAll(chartData)

		series.appear(1000)
		chart.appear(1000, 100)

		return () => {
			root.dispose()
		}
	}, [chartData])

	var divStyle = (element) =>
	{
		return {
			border: element.id === activeSubAgenda ? `1px solid ${element.color}` : `none`,
		}
	}

	const displaySubAgendaLegends = useMemo(() => {
		return chartData.map((element, index) => {
			return (
				<div
					onClick={() => setActiveSub(element.id)}
					className={`${css.oneLegend} ${element.id === activeSubAgenda ? css.activeSubLegend : ""}`}
					key={index}
					style={divStyle(element)}
				>
					{element.id === activeSubAgenda && !isMobile && (
						<>
							<div
								className={`${css.activeSubLegendbefore}`}
								style={{ borderRight: "1px solid " + element.color, borderBottom: " 1px solid " + element.color }}
							/>
							<div
								className={`${css.activeSubLegendafter}`}
								style={{ borderRight: "1px solid " + element.color, borderTop: " 1px solid " + element.color }}
							/>
						</>
					)}
					{element.id !== activeSubAgenda && <div className={css.legendMark} style={{ backgroundColor: element.color }}></div>}
					<div style={{ marginLeft: "20px" }}>
						<span>{element.name}</span>
						<strong>{element.result}%</strong>
					</div>
				</div>
			)
		})
	}, [chartData, activeSubAgenda])

	const calculatePercent = (future, value) =>
	{
		let number = value * 100 / future
		return number.toFixed(1)
	}

	const displayInfoCard = useMemo(
		() =>
		{
			let activeSub = chartData.find(element => element.id === activeSubAgenda)
			if(!activeSub) return
			return (
				<div className={css.subagendaCard}>
					<div className="d-flex justify-content-between align-items-center">
						<div className={css.subagendaCardttiel}>Түвшин</div>
						<div className={css.bigPercent} style={{ color: getColor(parseInt(activeSub?.result) / 100, 120, 3) }}>
							<i className="far fa-long-arrow-up me-1" style={{ fontSize: '14px' }}></i>{" "}
							<div>{activeSub?.result}%</div>
						</div>
					</div>
					<div className={css.subagendaDetail}>
						<div className="d-flex justify-content-between align-items-center">
							<div>
								<div style={{ fontSize: "13px" }}>Хүрэх түвшин</div>
								<div style={{ fontSize: "11px", color: "grey" }}>хэмжих нэгж</div>
							</div>
							<div className={css.numberWithPercent}>
								<div className={css.number}>{activeSub.futurestatus}</div>
								<div className={css.percent}>{unitMeasure.find(el => el.id === activeSub.units)?.name}</div>
							</div>
						</div>
					</div>
					<div className={css.subagendaDetail}>
						<div className="d-flex justify-content-between align-items-center">
							<div>
								<div style={{ fontSize: "13px" }}>Суурь түвшин</div>
								<div style={{ fontSize: "11px", color: "grey" }}>ахиц</div>
							</div>
							<div className={css.numberWithPercent}>
								<div className={css.number}>{activeSub.basestatus}</div>
								<div className={css.percent}>
									<i className="far fa-long-arrow-up" style={{ fontSize: "9px" }}></i>{" "}
									{calculatePercent(parseFloat(activeSub.futurestatus), parseFloat(activeSub.basestatus))}%
								</div>
							</div>
						</div>
					</div>
					<div className={css.subagendaDetail}>
						<div className="d-flex justify-content-between align-items-center">
							<div>
								<div style={{ fontSize: "13px" }}>Одоогийн түвшин</div>
								<div style={{ fontSize: "11px", color: "grey" }}>ахиц</div>
							</div>
							<div className={css.numberWithPercent}>
								<div className={css.number}>{activeSub.currentstatus}</div>
								<div className={css.percent}>
									<i className="far fa-long-arrow-up" style={{ fontSize: "9px" }}></i>{" "}
									{calculatePercent(parseFloat(activeSub.futurestatus), parseFloat(activeSub.currentstatus))}%
								</div>
							</div>
						</div>
					</div>
					<div className={css.subagendaDetail}>
						<div className="d-flex justify-content-between align-items-center">
							<div>
								<div style={{ fontSize: "13px" }}>Хөрөнгийн хэмжээ</div>
								<div style={{ fontSize: "11px", color: "grey" }}>төрөл</div>
							</div>
							<div className={css.numberWithPercent}>
								<div className={css.number}>{moneyFormat(activeSub.amount)}₮</div>
								<div className={css.percent}>{choosedProgram?.budget_type_name}</div>
							</div>
						</div>
					</div>
				</div>
			)
		},
		[activeSubAgenda, unitMeasure]
	)

	return (
		<Container>
			<Row>
				<Col>
					<div className={css.markTitles}>
						{/* <div className={css.legendMark} style={{ backgroundColor: choosedAgenda?.columnSettings?.fill, left: '10px' }}></div> */}
						<div className={css.subAgendaTitles}>
							<ProgramChooseInputs
								choosedProgram={choosedAgenda}
								setChoosedProgram={setChoosedAgenda}
								chartData={choosedProgram?.agenda}
								placeholder={"Зорилт сонгох"}
							/>
						</div>
					</div>
				</Col>
			</Row>
			<hr />
			<Row className="mt-2">
				<Col lg={3}>
					{
						chartData.length !== 0 &&
						<>
							<div
								style={
									{
										fontSize: 15,
										textAlign: "center",
										paddingTop: 0,
										paddingBottom: 0,
										y: 0,
									}
								}
							> {label["subagenda"]} </div>
							<div id="subAgendaChartDiv" style={{ width: "100%", height: "380px", position: "relative" }}>
								<div className="hidelogo" style={{ height: "5%" }}></div>
							</div>
						</>
					}
				</Col>
				<Col lg={5} className={css.agendaLegends}>
					{displaySubAgendaLegends}
				</Col>
				<Col
					lg={4}
					// style={{
					// 	transition: "0.3s",
					// 	border: "1px solid " + chartData.find(element => element.id === activeSubAgenda).color,
					// 	borderLeft: 'none',
					// 	borderTopRightRadius: "10px",
					// 	borderBottomRightRadius: "10px",
					// }}
				>
					{chartData.length !==0 && displayInfoCard}
				</Col>
			</Row>
		</Container>
	)
}

export default SubAgendaChart
