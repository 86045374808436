import React from 'react';
import { Route, Routes } from 'react-router-dom';

import WindowTypeAction from './WindowTypeAction';
import WindowType from '.';

import BoxBackground from 'components/special/BoxBackground';

const WindowTypeCrudMain = () => {
    return (
        <Routes>
            <Route path="/*" element={<BoxBackground title='Цонхны төрөл' body={<WindowType/>} />} />
            <Route path="/:id/" element={<WindowTypeAction/>} />
        </Routes>
    );
}

export default WindowTypeCrudMain;
