import React from 'react'

import css from './style.module.css'

export default function BorderTitle({ children })
{
    return (
        <div className='mt-5' style={{ borderBottom: "1px solid #ebebeb" }}>
            <h2 className={`tx-blue ${css.maintitle}`}>{children}</h2>
        </div>
    )
}
