import React from 'react'

import OverlayElement from 'components/main/OverlayElement'

import { ROOM_ICONS } from 'utils/consts';

export default function Room({ name, count, code })
{
    return (
        <li className='mb-2'>
            <OverlayElement
                text={name}
            >
                <i className={ROOM_ICONS?.[code] || "far fa-square"}></i>
            </OverlayElement>
            <label className="ms-1">{count}</label>
        </li>
    )
}
