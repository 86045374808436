import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';
import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { zohihGuilgeeValidate } from 'utils/validate';
import { GUILGEE_TURUL } from 'utils/consts';

const ZohihGuilgeeAction = ({ hasTitle=true, isNew }) => {

    const [ inputValues, setInputValues ] = useState('');
    const [ realValues, setRealValues ] = useState('')

    const navigate = useNavigate();
    const { id } = useParams()

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const buildingTableApi = useApi().buildingTable

    /** create бол true, update бол false буцаана */
    const isCreate = id === 'create'

    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data } = await fetchData(buildingTableApi['zohih-guilgee'].getOne(id))
                if (success)
                {
                    setInputValues(data)
                }
            }
        },
        []
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        if (isCreate)
        {
            const { errors, success, data } = await fetchData(buildingTableApi['zohih-guilgee'].post(formData)).catch(error => error)
            if (success) return navigate(-1)
            else return errors
        }
        const { errors, success, data } = await fetchData(buildingTableApi['zohih-guilgee'].put(formData, id)).catch(error => error)
        if (success) navigate(-1)
        else return errors
    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "irgen_name",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
            },
            {
                inputType: "input",
                registerName: "register",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
            },
            {
                inputType: "input",
                registerName: "guilgee_dun",
                type: "number",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
            },
            {
                inputType: "select",
                registerName: "guilgee_turul",
                options: GUILGEE_TURUL,
            },
            {
                inputType: "datePicker",
                registerName: "guilgee_ognoo",
            },
            {
                inputType: "datePicker",
                registerName: "SMA_medegdsen_ognoo",
            },
            {
                inputType: "input",
                registerName: "hariltsagchdiin_too",
                type: "number",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
            },
            {
                inputType: "input",
                registerName: "NUBAZ_horig_tulgsan_hariltsagchdiin_too",
                type: "number",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
            },
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "all",
            yupValidate: zohihGuilgeeValidate,
        },
    }
    return (
        hasTitle
        ?
            <BoxBackground title={'Зуучлалын мэдээ'} body={<CForm isLoading={isLoading} inputValues={inputValues} form={form}/>}/>
        :
            <CForm isLoading={isLoading} inputValues={inputValues} form={form}/>
    );
}

export default ZohihGuilgeeAction;
