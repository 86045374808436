import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { newsPageValidate } from 'utils/validate';

const NewsPageAction = () =>
{
    const [ inputValues, setInputValues ] = useState('');

    const navigate = useNavigate();
    const { pageId } = useParams()

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const newsPageApi = useApi().news.page

    /** create бол true, update бол false буцаана */
    const isCreate = pageId === 'create'

    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data, errors } = await fetchData(newsPageApi.getOne(pageId)).catch( err => err )
                if (success)
                {
                    setInputValues(data)
                }
            }
        },
        []
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        if (isCreate)
        {
            const { errors, success, data } = await fetchData(newsPageApi.post(formData)).catch(error => error)
            if (success) return navigate(-1)
            else return errors
        }

        const { errors, success, data } = await fetchData(newsPageApi.put(pageId, formData)).catch(error => error)
        if (success) navigate(-1)
        else return errors
    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "page_name",
                disabled: isLoading,
                type: "text",
            },
            {
                inputType: "input",
                registerName: "code",
                disabled: isLoading,
                type: "text",
            },
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "all",
            yupValidate: newsPageValidate,
        },
    }

    return (
        <CForm inputValues={inputValues} form={form}/>
    );
}

export default NewsPageAction;
