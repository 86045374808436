import React from 'react';
import { Col, Row } from 'react-bootstrap';

import BoxBackground from 'components/special/BoxBackground';
import ChangeNames from './ChangeNames';

import ResetPassword from 'pages/Sign/SignIn/ResetPassword';
import useAuth from 'hooks/useAuth';

const UserOptions = () => {

    const { userDetail } = useAuth()

    const isSSO = userDetail.is_SSO

    return (
        <Row>
            <Col md={8}>
                {
                    !isSSO
                    ?
                        <>
                            <BoxBackground title='Нууц үг солих' body={<ResetPassword defaultMail={userDetail.email}/>}/>
                            <BoxBackground className='mt-4' title='Нэр солих' body={<ChangeNames/>}/>
                        </>
                    :
                        <>
                            Тохиргоо байхгүй байна
                        </>
                }
            </Col>
        </Row>
    );
}

export default UserOptions;
