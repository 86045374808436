
import React, { useEffect } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';

import Project from '.';

import ProjectAction from './ProjectAction';
import ProjectTabs from './ProjectTabs';
import ProjectMap from './Map'

import BoxBackground from 'components/special/BoxBackground';
import TabRoutes from './ProjectTabs/routes';

const ProjectCrudMain = () => {

    const [ params, setSearchParams ] = useSearchParams([])

    useEffect(
        () =>
        {
            setSearchParams({ tab: "project" })
        },
        []
    )

    return (
        <Routes>
            <Route path="/" element={<BoxBackground title='Төсөл' body={<ProjectMap />} />} />
            <Route path="/form/*" element={<BoxBackground title='Төсөл' body={<Project />} />} />
            <Route path="/form/:type/:id/" element={<ProjectAction />} />
            <Route path="/form/:id/details/*" element={<TabRoutes />} />
        </Routes>
    );
}

export default ProjectCrudMain;
