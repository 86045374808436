import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap'

import Slider from 'components/main/Slider'
import Button from 'components/main/Button'

import ImageFetch from 'components/main/Image';
import CButton from 'components/main/Button';

import MkvGraphic from './MkvGraphic'

import css from '../style.module.css'

/**
     * `count,
    id,
    is_joined,
    plan_img,
    project,
    rooms,
    total_mkv,
    total_room`
 */
const ResidenceInfo = ({ resideneceInfo, joined, isEnd, lotteryId, realId }) =>
{

    const [ active, setActive ] = useState(
        {
            id: 0,
            data: {}
        }
    )

    const [ showGraphic, setGraphic ] = useState(false)

    return (
        <div>
            <Row>
                <Col md={9} lg={9}>
                    <Row>
                        {
                            resideneceInfo.map(
                                (residence, idx) =>
                                {
                                    return (
                                        <Col
                                            key={idx}
                                            md={2}
                                            className={`${idx !== 0 ? "mt-3 md-sm-3 mt-md-0" : ""}`}
                                            onClick={() => !residence.is_joined && setActive({ id: residence.id, data: residence })}
                                        >
                                            <div className={css.cardResidence + " " + (residence.id === active.id ? css.active : "") + (residence.is_joined ? css.disabled : "") }>
                                                {residence.total_mkv}мк
                                            </div>
                                        </Col>
                                    )
                                }
                            )
                        }
                    </Row>
                </Col>
                <Col md={3} lg={3} className="mt-3 md-sm-3 mt-md-0 col-12 col-sm-12 justify-content-md-end justify-content-center d-flex">
                    <Button
                        style={
                            {
                                height: "40px"
                            }
                        }
                        onClick={() => setGraphic(prevValue => !prevValue)}
                    >
                        {showGraphic ? "Бүртгэл нуух" : "Бүртгэл харах"}
                    </Button>
                </Col>
            </Row>
            <hr />
            {
                showGraphic
                ?
                    <MkvGraphic resideneceInfo={resideneceInfo} />
                :
                    active.id === 0
                    ?
                        <div className='d-flex justify-content-center fs-15'>Та мк сонгоно уу</div>
                    :
                        <div className={css.animation}>
                            <div className="d-flex">
                                <div className={css.columnOne}>
                                    <span className={css.fieldName}>
                                        Нийт мк:
                                    </span>
                                </div>
                                <div className={css.columnTwo}>
                                    <span className={css.valueName}>
                                        {active.data.total_mkv} м2
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className={css.columnOne}>
                                    <span className={css.fieldName}>
                                        Нийт өрөө:
                                    </span>
                                </div>
                                <div className={css.columnTwo}>
                                    <span className={css.valueName}>
                                        {active.data.total_room}
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className={css.columnOne}>
                                    <span className={css.fieldName}>
                                        Бүртгүүлсэн:
                                    </span>
                                </div>
                                <div className={css.columnTwo}>
                                    <span className={css.valueName}>
                                        {active.data.count}
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className={css.columnOne}>
                                    <span className={css.fieldName}>
                                        Plan зураг:
                                    </span>
                                </div>
                                <div className={css.columnTwo}>
                                    <div className={css.valueName}>
                                        <Slider
                                            freeMode={false}
                                            autoplay={true}
                                            autoplayDelay={2000}
                                            spaceBetween={30}
                                            pagination={true}
                                            navigation={false}
                                            items={[
                                                <ImageFetch
                                                    className="mt-3 w-100"
                                                    src={active.data.plan_img}
                                                    alt={"plan зураг"}
                                                    title="Зохион байгуулалт"
                                                />,
                                                ...active.data.rooms && active.data.rooms.length > 0
                                                ?
                                                    active.data.rooms.map(
                                                        (room, idx) =>
                                                        {
                                                            return (
                                                                <ImageFetch
                                                                    className="mt-3 w-100"
                                                                    src={room.image}
                                                                    alt={"өрөө зураг"}
                                                                    title={room.room_name}
                                                                />
                                                            )
                                                        }
                                                    )
                                                :
                                                    []
                                            ]}
                                            slidesPerView={1}
                                            loop={true}
                                            breakpoints={{
                                                640: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 10,
                                                },
                                                768: {
                                                    slidesPerView: 2,
                                                    spaceBetween: 10,
                                                },
                                                1024: {
                                                    slidesPerView: 3,
                                                    spaceBetween: 10,
                                                },
                                                1280: {
                                                    slidesPerView: 3,
                                                    spaceBetween: 10,
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                active.data.rooms.length === 0
                                ?
                                    ""
                                :
                                    <div>
                                        <div className={`${css.headTitle} d-flex justify-content-between mt-3`}>
                                            <label>Өрөө</label>
                                            <label>Тоо</label>
                                            <label>Талбай</label>
                                        </div>
                                        {
                                            active.data.rooms.map(
                                                (el, idx) =>
                                                {
                                                    return  <div key={idx} className={`${css.bodyInfo} d-flex justify-content-between`}>
                                                                <label>{el.room_name} <i className={el.room_icon}></i></label>
                                                                <label>{el.room_count}</label>
                                                                <label>{el.room_mkv} мк</label>
                                                            </div>
                                                }
                                            )
                                        }
                                    </div>
                            }
                            {
                                !isEnd && !joined
                                &&
                                    <div className={`${css.oneLine} mt-2 fs-13`}>
                                        <div className="mt-3 d-flex justify-content-center">
                                            <Link to={`/songon/${lotteryId}/register/${realId}/${active.data.id}/`} target="_blank">
                                                <CButton variant="second" title="Бүртгүүлэх" />
                                            </Link>
                                        </div>
                                    </div>
                            }
                        </div>
            }
        </div>
    )
}

export default ResidenceInfo;
