import React from 'react';
import { useNavigate } from 'react-router-dom';

import css from '../../style.module.css'

const ListItem = ({ element }) => {

	const navigate = useNavigate()

    return (
		<div onClick={() => navigate(`${element.page}/${element.id}/`)} className={css.itemContainer}>
			<div>
				<div className={css.listTitle}>{element.title}</div>
			</div>
			<div className={css.itemDesc}>
				{element.commentary}
			</div>
		</div>
	)
}

export default ListItem;
