import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CForm from 'components/main/Form';
import BoxBackground from 'components/special/BoxBackground';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { programValidate } from 'utils/validate';
import { dataToValueLabel } from 'utils/format';

/** Хөтөлбөр дээр create, update хийнэ */
const ProgramAction = () => {
    const [ inputValues, setInputValues ] = useState({});
    const [ budgetTypes, setBudgetType ] = useState([]);
    const [ govs, setGovs ] = useState([]);

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const navigate = useNavigate();
    const { programId } = useParams()

    const budgetApi = useApi().budgetType
    const programApi = useApi().program
    const govApi = useApi().gov

    /** true бол create, false бол update буцаана */
    const isCreate = programId === 'create'

    /** govs, budgettype уудыг авна */
    useEffect(
        async () =>
        {
            Promise.all([fetchData(govApi.get()), fetchData(budgetApi.get())]).then(
                resp =>
                {
                    setGovs(dataToValueLabel(resp[0].data, 'id', 'name'))
                    setBudgetType(dataToValueLabel(resp[1].data, 'id', 'name'))
                }
            )
        },
        []
    )

    /** create, update гэдгээс хамаарч утга авна */
    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data, error } = await fetchData(programApi.getOne(programId))
                if (success)
                {
                    setInputValues({ ...data, main_subject: data.main_subject.id, budget_type: data.budget_type.id, contrib_subject: data.contrib_subject.map(e => e.id)  })
                }
            }
        },
        []
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        if (isCreate)
        {
            // үүсгэх үед
            const { success, data, errors } = await fetchData(programApi.post(formData)).catch((error) => error)
            if (success) return navigate(-1)
            else return errors
        }
        // засах үед
        const { success, data, errors } = await fetchData(programApi.put(formData, programId)).catch((error) => error)
        if (success) navigate(-1)
        else return errors
    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "name",
                type: "text",
                placeholder: "Хөтөлбөрийн нэр оруулна уу",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
                label: {
                    text: "Хөтөлбөрийн нэр: ",
                    empty: false,
                },
            },
            {
                inputType: "select",
                registerName: "budget_type",
                placeholder: "Xөрөнгө оруулалтын төрөл сонгоно уу",
                disabled: isLoading,
                options: budgetTypes,
                label: {
                    text: "Xөрөнгө оруулалтын төрөл: ",
                    empty: false,
                },
            },
            {
                inputType: "input",
                registerName: "budget_amount",
                type: "number",
                placeholder: "Төслийн хөрөнгийг оруулна уу",
                inputGroup: {
                    start: <i className="fa-brands fa-bimobject"></i>
                },
                disabled: isLoading,
                label: {
                    text: "Төслийн хөрөнгө: ",
                    empty: false,
                },
                step: '0.1'
            },

            {
                inputType: "datePicker",
                registerName: "end_date",
                placeholder: "Дуусах хугацаа оруулна уу",
                disabled: isLoading,
                label: {
                    text: "Дуусах хугацаа: ",
                    empty: false,
                },
            },
            {
                inputType: "select",
                registerName: "main_subject",
                disabled: isLoading,
                options: govs,
                label: {
                    text: "Хариуцах үндсэн төрийн байгууллага: ",
                    empty: false,
                },
            },
            {
                inputType: "select",
                registerName: "contrib_subject",
                isMulti: true,
                disabled: isLoading,
                options: govs,
                label: {
                    text: "Хариуцах төрийн байгууллага: ",
                    empty: false,
                },
            },
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "all",
            yupValidate: programValidate,
        },
    }

    return (
        <BoxBackground title={isCreate ? 'Хөтөлбөр үүсгэх' : 'Хөтөлбөр засах'} body={<CForm inputValues={inputValues} form={form}/>}/>
    );
}

export default ProgramAction;
