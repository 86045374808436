import React from 'react';
import { useNavigate } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';
import ImageFetch from 'components/main/Image';
import Badge from 'components/main/Badge';

import { quantityFormat, timeZoneToDateString } from 'utils/format';

import css from './style.module.css'

const LotteryCard = ({ image, status, players, date, title, navigateTo, onClickTitle, isJoinedUser }) =>
{
	const navigate = useNavigate()

	const checkState = () =>
    {
		if (status === true)
        {
			return "Идэвхтэй"
		}
		return "Идэвхгүй"
	}

	return (
		<BoxBackground
			onClickTitle={onClickTitle}
			title={title}
			footer={
				<span onClick={() => navigate(navigateTo)} className="fs-12 text-darkBlue float-end user-select-none cursorPointer">
					Дэлгэрэнгүй <i className="fa-solid fa-arrow-right"></i>
				</span>
			}
			body={
				<div
					className='position-relative'
				>
					{
						isJoinedUser
						&&
							<Badge
								text={"Бүртгүүлсэн"}
								style={{
									left: '-11px'
								}}
							/>
					}
					<div className={css.imgContainer}>
						<ImageFetch
							className="w-100 cursorPointer mb-2"
							onClick={() => navigate(navigateTo)}
							src={process.env.REACT_APP_SERVER_PUBLIC_URL + "/media/" + image}
						/>
					</div>
					{typeof status === 'boolean' && <label className={`mt-3 ${status === true ? css.active : css.inactive}`}>{checkState()}</label>}
					<label className="d-block mt-2 fs-12-5">
						<i className="far fa-clock text-darkBlue"></i> Эхэлсэн хугацаа: {timeZoneToDateString(date[1])}<br/>
					</label>
					<label className="d-block mt-2 fs-12-5">
						<i className="far fa-clock text-darkBlue"></i> {status ? 'Дуусах' : 'Дууссан'} хугацаа: {timeZoneToDateString(date[0])}
					</label>
					<label className="d-block mt-2 fs-12-5">
						<i className="far fa-users text-darkBlue"></i> {status ? 'Оролцож буй' : 'Оролцсон'}: {quantityFormat(players)}
					</label>
				</div>
			}
		/>
	)
}

export default LotteryCard;
