import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useApi from 'hooks/useApi';
import useToast from 'hooks/useToast';

import { rspText } from 'utils/request'
import { HOME_PAGE, SIGN_IN_PAGE } from 'utils/consts';

export const AuthContext = createContext()

const initUserDetail = {}
export default function AuthContextProvider(props)
{
    const userApi = useApi({ isDisplayToast: false }).user
    const signOutApi = useApi({ isDisplayToast: false }).sign
    const signInApi = useApi().sign
    /** хэрэглэгчийн мэдээлэл */
    const [ userDetail, setDetail ] = useState(initUserDetail)
    /** Нэвтэрсэн эсэх */
    const [ isLogged, setLogged ] = useState(false)
    const { addToast } = useToast()
    const navigate = useNavigate()

    const [ companyId, setCompanyId ] = useState('')

    /** системд хэрэглэгчийн нэвтэрснээр бүртгэх */
    const loggedUser = (userData) => {
        setCompanyId(userData?.employee?.company?.id)
        //  хэрэглэгчийн мэдээллийг хадгалах нь
        setDetail(userData)
        //  нэвтэрсэн гэж үзэх
        setLogged(true)
    }

    /**
     * Xэрэглэгчийн бүх мэдээллийг авах нь
     * setDetail хийнэ
     */
    const getDetail = async () =>
    {
        const { success, data, error } = await userApi.getProfile().catch(err => { return err })
        if (success)
        {
            loggedUser(data)
        }
    }

    /** системд нэвтрэх хүсэлт илгээх нь */
    const signIn = async (body) =>
    {
        const { success, data, error } = await signInApi.in(body).catch(err => err)
        if (success)
        {
            loggedUser(data)
            navigate(HOME_PAGE)
        }
    }

    /** системд dan аар нэвтрэх хүсэлт илгээх нь */
    const signInDan = async (body) =>
    {
        const { success, data, error } = await signInApi.dan(body).catch(err => err)
        if (success)
        {
            loggedUser(data)
            navigate(HOME_PAGE)
        }
        else {
            navigate(SIGN_IN_PAGE)
        }
    }

    /** Системээс гарах
     * @param {boolean} hasToast гаргах эсэх
    */
    const signOut = async (hasToast=true) =>
    {
        const { success, info, error } = await signOutApi.out().catch(err => { return err })
        navigate(SIGN_IN_PAGE)
        async function asyncHelper() {
            if (success)
            {
                /** toast гаргах эсэх */
                if (hasToast)
                {
                    const text = rspText(info)
                    addToast(
                        {
                            text,
                            time: 3000,
                            type: "success",
                        }
                    )
                }
            }
            else {
                if (hasToast)
                {
                    const text = rspText(error)
                    addToast(
                        {
                            text,
                            time: 3000,
                            type: "error",
                        }
                    )
                }
            }
            //  нэвтрээгзй гэж хадгалах
            setLogged(false)
            //  хэрэглэгчийн мэдээллийг хоослох нь
            setDetail(initUserDetail)
        }
        asyncHelper()
    }

    return (
        <AuthContext.Provider value={{
            getDetail,
            signOut,
            setDetail,
            loggedUser,
            isLogged,
            userDetail,
            companyId,
            signIn,
            signInDan,
        }}>
            {props.children}
        </AuthContext.Provider>
    );
}
