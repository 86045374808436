import { useEffect } from "react"

import useAuth from "hooks/useAuth";
import useLoader from "hooks/useLoader";

import { searchParamsToObject } from 'utils/browser'

export default function DanLogin()
{
    const { isLoading, Loader, fetchData } = useLoader({ isFullScreen: true, hasBackground: true, initValue: true })
    const { signInDan } = useAuth()

    useEffect(
        () =>
        {
            const body = searchParamsToObject(window.location.search)
            console.log('body', body)
            fetchData(
                (async () =>
                {
                    await signInDan(body)
                })()
            )
        },
        []
    )

    return (
        Loader
    )
}
