import React, { useState } from 'react';

import BoxBackground from 'components/special/BoxBackground';

import IconItem from './IconItem';

import css from '../style.module.css'

const CompanyInfo = ({ data }) => {

	const [ activeIcon, setActiveIcon ] = useState(0);

    return (
		<BoxBackground className={css.companyInfo} title="КОМПАНИ МЭДЭЭЛЭЛ">
			<p className="fw-bold text-center">{data?.company_info?.company} {data?.company_info?.prop_type_display}</p>
			<div>Захирал: {data?.company_info?.director}</div>
			<div>Маркетингийн менежер: {data?.sales_info?.manager}</div>
			<div className='d-flex align-items-center'>
				<div className='me-2'>Холбоо барих:</div>
				<div className={`${css.icons}`} >
					<IconItem setActiveIcon={setActiveIcon} code={1} active={activeIcon} text={data?.sales_info?.contact} className={css.iconPhone} icon='fas fa-phone-alt'/>
					<IconItem setActiveIcon={setActiveIcon} code={2} active={activeIcon} text={data?.sales_info?.email} className={css.iconMail} icon='fas fa-envelope-open-text'/>
				</div>
			</div>
			<div className='d-flex align-items-center'>
				<div className='me-2'>Сошиал:</div>
				<div className={`${css.icons}`} >
					<IconItem isLink={true} setActiveIcon={setActiveIcon} code={3} active={activeIcon} text={data?.sales_info?.facebook} className={css.iconFacebook} icon='fab fa-facebook-f'/>
					<IconItem isLink={true} setActiveIcon={setActiveIcon} code={4} active={activeIcon} text={data?.sales_info?.instagram} className={css.iconInstagram} icon='fab fa-instagram'/>
					<IconItem isLink={true} setActiveIcon={setActiveIcon} code={5} active={activeIcon} text={data?.sales_info?.twitter} className={css.iconTwitter} icon='fab fa-twitter'/>
					<IconItem isLink={true} setActiveIcon={setActiveIcon} code={6} active={activeIcon} text={data?.sales_info?.website} className={css.iconWeb} icon='fas fa-globe'/>
				</div>
			</div>
		</BoxBackground>
	)
}

export default CompanyInfo;
