import { useEffect, useMemo, useState, useRef } from "react"
import { Transition } from "react-transition-group"

import RadioCheck from "components/main/Form/InputTypes/RadioCheck"

import css from '../../../style.module.css'

const duration = 300

const FilterOptions = ({ show, idx, thId, options=[], handleFilterChange, fieldName }) =>
{
    const transitionStyles = {
        entering: { maxHeight: options.length * 30 + 'px', padding: '5px' },
        entered: { maxHeight: options.length * 30 + 'px', padding: '5px' },
    }

    const defaultStyle = {
        transition: `${duration}ms ease-in-out`,
        maxHeight: 0,
        overflow: "hidden",
    }

    const didmount = useRef(true)

    let checkedListx = []
    const [ checkedList, setCheckedList ] = useState(
        {
            [fieldName]: options
                .filter(el => el.default === true)
                .map(el => {
                    checkedListx.push(el.value)
                    return el.value
                })
        }
    )

    useEffect(
        () =>
        {
            if (didmount.current)
            {
                didmount.current = false
            }
            if (!didmount.current)
            {
                if (Object.keys(checkedList).length > 0)
                {
                    handleFilterChange(checkedList)
                }
            }
        },
        [checkedList]
    )

    const handleChecked = (event) =>
    {
        if(event.target.checked)
        {
            checkedListx.push(event.target.value)
            return setCheckedList({ [fieldName]: checkedListx })
        }
        let index = checkedListx.indexOf(event.target.value);
        if (index > -1) {
            checkedListx.splice(index, 1);
        }
        return setCheckedList({ [fieldName]: checkedListx })
    }

    const displayCheckBox = useMemo(
        () =>
        {
            return options.map(
                (element, index) =>
                {
                    return <RadioCheck defaultValue={element.default} onChange={ e => handleChecked(e) } options={[{ label: element.label, value: element.value }]} key={index} />
                }
            )
        },
        []
    )

    return (
		<Transition in={show && idx === thId} timeout={duration}>
			{state => (
				<div
					style={{
						...defaultStyle,
						...transitionStyles[state],
					}}
					className={css.popoverContainer}
                >
                    {displayCheckBox}
				</div>
			)}
		</Transition>
	)
}

export default FilterOptions
