import React, { useRef } from 'react'
import { useState } from 'react'
import { useMemo } from 'react'

import LifeStyle from './LifeStyle'
import Population from './Population'
import Price from './Price'
import General from './General'

import css from './style.module.css'
import useMapContext from 'hooks/useMapContext'
import { FILTER_PERPAGE } from 'context/special/mapContext'
import useApi from 'hooks/useApi'
import ProjectStep from './ProjectStep'

const initActive = {
    index: null,
    component: null
}
export default function FilterAnalyze()
{

    const searchRef = useRef()
    const { resetAllFilters, allFilters, allFiltersToMap, cMapState, setSelectedFeature } = useMapContext()
    const searchApi = useApi().search
    const getApartmentSdi = useApi().getApartmentSdi
    const [ active, setActive ] = useState(initActive)
    const [ suggetions, setSuggestions ] = useState([])

    const menus = [
        {
            name: "Орон сууцны ерөнхий мэдээлэл",
            comp: <General />,
        },
        {
            name: "Үнийн хайлт",
            comp: <Price />,
        },
        {
            name: "Хүн амын нягтаршил",
            comp: <Population />,
        },
        {
            name: "Амьдрах орчин",
            comp: <LifeStyle />,
        },
        {
            name: "Төслийн хэрэгжилт",
            comp: <ProjectStep />,
        }
    ]

    const handleActive = (index, component) =>
    {
        //  газрын зургийн хайлтыг reset хийх
        resetAllFilters(index)

        setActive(
            {
                index,
                component
            }
        )
    }

    const menusMemo = useMemo(
        () =>
        {
            return menus.map(
                (menu, idx) =>
                {
                    return (
                        <div className={`${css.listItem} ${active.index === idx ? css.active : ""}`} key={idx} onClick={() => handleActive(idx, menu.comp)}>
                            {menu.name}
                        </div>
                    )
                }
            )
        },
        [menus]
    )

    /** филтер хийж байгаа container ийг дэлгэцдээр харуулах эсэх */
    const handleFilter = () =>
    {
        const filterContainer = document.getElementById("filterContainer")
        const closeI = document.getElementById("close-i")
        const filterI = document.getElementById("filter-i")

        filterContainer.classList.toggle("d-none")
        closeI.classList.toggle("d-none")
        filterI.classList.toggle("d-none")
    }

    const handleSearch = (e) =>
    {
        e.preventDefault()
        allFilters['big_search'] = searchRef.current.value
        allFilters['pPage'] = 1
        allFilters['pPerPage'] = FILTER_PERPAGE
        allFiltersToMap("search")

        const filterContainer = document.getElementById("filterContainer")
        const closeI = document.getElementById("close-i")
        const filterI = document.getElementById("filter-i")
        filterContainer.classList.remove("d-none")
        filterI.classList.add("d-none")
        closeI.classList.remove("d-none")

        const activeMenu = menus[0]
        setActive(
            {
                index: 0,
                component: activeMenu.comp
            }
        )
    }

    const handleChange = async (event) =>
    {
        const { success, data, errors } = await searchApi(searchRef.current.value)
        if (success)
        {
            setSuggestions(data.suggetions)
        }
    }

    const comps = useMemo(
        () =>
        {
            if (active.index === null)
                return null
            return (
                <div className={css.filterBar}>
                    {active.component}
                </div>
            )
        },
        [active]
    )

    const jumpToPoint = async (id) =>
    {
        const { success, data } = await getApartmentSdi(id)
        if (success)
        {
            if (data.data.length)
            {
                const sdi = data.data[0]
                const feature = cMapState.parseGeoJsonToFeature(sdi.point_geojson)[0]
                const coords = feature.getGeometry().getCoordinates()
                // const parsedCoords = cMapState.transformCoordinate(coords)
                cMapState.animateToCoordinate(coords, { zoom: 18 })
                cMapState.displayOverlay(coords)
                setSelectedFeature({ feature: [
                    {
                        id: sdi.id,
                        apartmentId: (typeof sdi.apartment === "object") ? sdi.apartment.id : sdi.apartment
                    }
                ], state: "clicked" })
            }
        }
    }

    const suggetionsComp = useMemo(
        () =>
        {
            if (!suggetions.length)
                return null

            return (
                <div className={css.suggesContainer} id={"sugges-search"}>
                    {
                        suggetions.map(
                            (item, idx) =>
                            {
                                const str = searchRef.current.value ? item.keyword.replace(new RegExp('(^|)(' + searchRef.current.value + ')(|$)','i'), '$1<b>$2</b>$3') : item.keyword
                                return (
                                    <div key={idx} className={css.suggesItem} onMouseDown={() => jumpToPoint(item.id)}>
                                        <div dangerouslySetInnerHTML={{__html: str}}>
                                        </div>
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            )
        },
        [suggetions]
    )

    const hideSugges = (event) =>
    {
        const elem = document.getElementById("sugges-search")
        if (elem)
            elem.classList.add("d-none")
    }

    const showSugges = () =>
    {
        const elem = document.getElementById("sugges-search")
        if (elem)
            elem.classList.remove("d-none")
    }

    return (
        <div>
            <div className={css.listContainer}>
                <form className={`d-flex ${css.searchContainer}`} onSubmit={handleSearch}>
                    <div className={`${css.filterBtn} ${css.filterShuultuur}`} onClick={handleFilter} title="Шүүлтүүр">
                        <i id='filter-i' className="fa-solid fa-filter fa-1x"></i>
                        <i id='close-i' className="fa-solid fa-close fa-1x d-none"></i>
                    </div>
                    <input
                        type="text"
                        ref={searchRef}
                        className={css.searchInput}
                        placeholder={"Хаяг дугаараар хайх"}
                        onChange={handleChange}
                        onFocus={showSugges}
                        onBlur={hideSugges}
                    />
                    <button type='submit' className={css.filterBtn} onClick={handleSearch}><i className='fa fa-search'></i> Хайх</button>
                    {suggetionsComp}
                </form>
                <div className={`d-none ${css.filterContainer}`} id={"filterContainer"}>
                    <div className={css.menus}>
                        {menusMemo}
                    </div>
                    {comps}
                </div>
            </div>
        </div>
    )
}
