import React from 'react';
import { Nav } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import MainNavLink from 'components/main/NavLink';

const ProjectTabs = () => {
    const { programId } = useParams();

    return (
        <Nav fill variant="tabs" className='tabNav-tabs'>
            <Nav.Item >
                <MainNavLink className='tabNav-link' activeClassName='tabActive' exact to={`/gov/projectprogram/program/${programId}/detail/`}>
                    Дэлгэрэнгүй
                </MainNavLink>
            </Nav.Item>
            <Nav.Item>
                <MainNavLink className='tabNav-link' activeClassName='tabActive' to={'agenda/'}>
                    Зорилт
                </MainNavLink>
            </Nav.Item>
            <Nav.Item>
                <MainNavLink className='tabNav-link' activeClassName='tabActive' to={'subAgenda/'}>
                    Шалгуур үзүүлэлт
                </MainNavLink>
            </Nav.Item>
        </Nav>
    );
}

export default ProjectTabs;
