
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';

import HelperMain from '.';
import HelperAction from './HelperAction';


const ActivityType = () => {
    return (
        <Routes>
            <Route path="/" element={<BoxBackground title='Видео бичлэг' body={<HelperMain/>} />} />
            <Route path="/:id/" element={<HelperAction/>} />
        </Routes>
    );
}

export default ActivityType;
