import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';
import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';
import useMapContext from 'hooks/useMapContext';

import MapChoice from '../MapChoice'

import { zassanValidateNew, zassanValidateNewFromMap, zassanValidateOldFromMap, zassanValidateOld } from 'utils/validate';
import { dataToValueLabel } from 'utils/format';
import { UL_HODLOH_TURUL } from 'utils/consts';

const ZassanAction = ({ hasTitle=true, isNew=null, updateId, setTogglerState }) => {

    const [ inputValues, setInputValues ] = useState('');
    const [ realValues, setRealValues ] = useState('')
    const [ choosedNew, setChoosedNew ] = useState(null)

    const [ projectNested, setProjectNested ] = useState([]);
    const [ apartmentNested, setApartmentNested ] = useState([])
    const [ units, setUnits ] = useState([])
    const [ unit2Options, setUnit2Options ] = useState([])
    const [ selectUnit1, setUnit1 ] = useState('')
    const [ selectUnit2, setUnit2 ] = useState('')

    const [ buildingsOfApart, setBuildings ] = useState([]);
    const [ project, setProject ] = useState({});
    const [ apartment, setApartment ] = useState({});

    const navigate = useNavigate();
    let { id, tableId } = useParams()
    const { selectedUnits, choosedFeature, setShouldClosePopup } = useMapContext()

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const buildingTableApi = useApi().buildingTable
    const projectNestedApi = useApi().projectNested
    const unitApi = useApi().unit

    /** create бол true, update бол false буцаана */
    let isCreate = !hasTitle ? true : id === 'create'
    isCreate = !isCreate ? isCreate : updateId ? false : true
    id = updateId ? updateId : id

    useEffect(
        async () =>
        {
            if (isNew == true)
            {
                const { success, data, error } = await fetchData(buildingTableApi.buildingOfApartment(choosedFeature.feature.get("apartment_id"), tableId)).catch(err => err)
                if (success)
                {
                    setBuildings(dataToValueLabel(data, 'id', 'name'))
                }
            }
            else
            {
                const unitRsp = await fetchData(unitApi.get())
                const projectNestedRsp = await fetchData(projectNestedApi.get())
                if(unitRsp.success && projectNestedRsp.success)
                {
                    setProjectNested(projectNestedRsp.data)
                    setUnits(unitRsp.data)
                }
            }
        },
        []
    )

    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data } = await fetchData(buildingTableApi['zassan'].getOne(id))
                if (success)
                {
                    setInputValues(data)
                }
            }
        },
        []
    )

    useEffect(
        () =>
        {
            if(inputValues === '') return
            let zadalsanData = {
                "irgen_huuliin_etgeedin_ner": inputValues.hariltsagch_info.irgen_huuliin_etgeedin_ner,
                "register": inputValues.hariltsagch_info.register,
                "project": inputValues.building_info.project,
                "apartment": inputValues.building_info.apartment,
                "building": inputValues.building_info.building?.id,
                "ul_hudluh_hurungiin_turul": parseInt(inputValues.ul_hudluh_hurungiin_turul),

                "mkv": inputValues.mkv,
                "hudaldaj_awsan_une": inputValues.hudaldaj_awsan_une,
                "zaswar_zardal": inputValues.zaswar_zardal,
                "zarsan_une": inputValues.zarsan_une,
                "olz_garz": inputValues.olz_garz,

                ognoo: new Date(inputValues.geree_info.ognoo),
                "geree_gerchilgee_dugaar": inputValues.geree_info.geree_gerchilgee_dugaar,
                "dugaar": inputValues.geree_info.dugaar,

                unit1: inputValues.bairshil_info.aimar_hot_info?.id,
                unit2: inputValues.bairshil_info.sum_duureg_info?.id,
                unit3: inputValues.bairshil_info.bag_horoo_info?.id,
                full_name: inputValues.building_info.full_name,
                geoid: inputValues.building_info.geo_id,
            }
            setProject(inputValues.building_info.building?.project)
            setApartment(inputValues.building_info.building?.apartment)
            setUnit1(inputValues.bairshil_info.aimar_hot_info?.id)
            setUnit2(inputValues.bairshil_info.sum_duureg_info?.id)
            setRealValues(zadalsanData)
            handleIsNew(inputValues.ul_hudluh_hurungiin_turul)
        },
        [inputValues]
    )

    const handleIsNew = (value) =>
    {
        if(value === 1) setChoosedNew(true)
        else setChoosedNew(false)
    }

    const apartments = useMemo(
        () =>
        {
            if(!project) return null
            let unit1Id = typeof project === "object" ? project.id : project
            const unit = projectNested?.find(
                (el) =>
                {
                    return el.id === unit1Id
                }
            )
            setApartmentNested(unit?.apartments)
            return dataToValueLabel(unit?.apartments, 'id', 'name')
        },
        [project, projectNested]
    )

    const buildings = useMemo(
        () =>
        {
            if(!apartment) return null
            let unit1Id = typeof apartment === "object" ? apartment.id : apartment
            const unit = apartmentNested?.find(
                (el) =>
                {
                    return el.id === unit1Id
                }
            )
            return dataToValueLabel(unit?.buildings, 'id', 'name')
        },
        [apartment, apartmentNested]
    )

    const secondUnits = useMemo(
        () =>
        {
            if(!selectUnit1) return []
            let unit1Id = typeof selectUnit1 === "object" ? selectUnit1.id : selectUnit1
            const unit = units?.find(
                (el) =>
                {
                    return el.id === unit1Id
                }
            )
            setUnit2Options(unit?.unit2)
            return dataToValueLabel(unit?.unit2, 'id', 'name')
        },
        [selectUnit1, units]
    )

    const thirdUnits = useMemo(
        () =>
        {
            if(!selectUnit2) return []
            let unit2Id = typeof selectUnit2 === "object" ? selectUnit2.id : selectUnit2
            const unit = unit2Options?.find(
                (el) =>
                {
                    return el.id === unit2Id
                }
            )
            return dataToValueLabel(unit?.unit3, 'id', 'name', null, 'code')
        },
        [unit2Options, selectUnit2]
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData, { reset }) =>
    {
        const initBody = {
            "ul_hudluh_hurungiin_turul": formData.ul_hudluh_hurungiin_turul,
            "mkv": formData.mkv,
            "building": formData.building,
            "hudaldaj_awsan_une": formData.hudaldaj_awsan_une,
            "zaswar_zardal": formData.zaswar_zardal,
            "zarsan_une": formData.zarsan_une,
            "olz_garz": formData.olz_garz,
            "hariltsagch": {
                "irgen_huuliin_etgeedin_ner": formData.irgen_huuliin_etgeedin_ner,
                "register": formData.register,
            },
            "geree": {
                "ognoo": formData.ognoo,
                "dugaar": formData.dugaar,
                "geree_gerchilgee_dugaar": formData.geree_gerchilgee_dugaar,
            },
            bairshil: {
                aimag_hot: "",
                sum_duureg: "",
                bag_horoo: "",
            },
        }

        let body = {}

        if (choosedNew)
        {
            body = {
                ...initBody,
                barilga: {
                    building: formData.building,
                    apartment: isNew ? choosedFeature?.feature?.get("apartment_id") : apartment
                }
            }
        }
        else
        {
            body = {
                ...initBody,
                bairshil: {
                    aimag_hot: typeof isNew === 'boolean' && !isNew ? selectedUnits.unit1 : formData.unit1,
                    sum_duureg: typeof isNew === 'boolean' && !isNew ? selectedUnits.unit2 : formData.unit2,
                    bag_horoo: typeof isNew === 'boolean' && !isNew ? selectedUnits.unit3 : formData.unit3,
                },
                barilga: {
                    full_name: formData.full_name,
                    geo_id: isNew ? choosedFeature?.feature?.getId() : formData.geoid
                }
            }
        }

        if (isCreate)
        {
            const { errors, success, data } = await fetchData(buildingTableApi['zassan'].post(body)).catch(error => error)
            if(success)
            {
                setTogglerState && setTogglerState(false)
                reset()
                typeof isNew !== 'boolean' && navigate(-1)
            }
            else return errors
            return
        }
        const { errors, success, data } = await fetchData(buildingTableApi['zassan'].put(body, id)).catch(error => error)
        if(success)
        {
            setTogglerState && setTogglerState(false)
            reset()
            typeof isNew !== 'boolean' && navigate(-1)
            return
        }
        else return errors
    }


    const displaySomeInputs = useMemo(
        () =>
        {
            if (isNew == true)
            {
                return [
                    {
                        component: (
                            <hr />
                        )
                    },
                    {
                        inputType: "select",
                        registerName: "building",
                        options: buildingsOfApart,
                        colMd: 6,
                        disabled: !isCreate,
                    },
                ]
            }
            else if (isNew === false)
            {
                return [
                    {
                        inputType: "input",
                        registerName: "full_name",
                        type: "textArea",
                        colMd: 12
                    },
                ]
            }
            if(choosedNew == null) return []
            if (choosedNew === true)
            {
                return [
                    {
                        inputType: "select",
                        registerName: "project",
                        onChange: value => setProject(value.value),
                        options: dataToValueLabel(projectNested, 'id', 'name'),
                        colMd: 4,
                        disabled: !isCreate,
                    },
                    {
                        inputType: "select",
                        registerName: "apartment",
                        onChange: value => setApartment(value.value),
                        nested: 'project',
                        options: apartments,
                        colMd: 4,
                        disabled: !isCreate,
                    },
                    {
                        inputType: "select",
                        registerName: "building",
                        nested: 'apartment',
                        options: buildings,
                        colMd: 4,
                        disabled: !isCreate,
                    },
                ]
            }
            else if (choosedNew === false)
            {
                return [
                    {
                        inputType: "select",
                        registerName: "unit1",
                        onChange: value => setUnit1(value.value),
                        disabled: isLoading,
                        options: dataToValueLabel(units, 'id', 'name'),
                        disabled: !isCreate,
                        colMd: 4
                    },
                    {
                        inputType: "select",
                        registerName: "unit2",
                        onChange: value => setUnit2(value.value),
                        disabled: isLoading,
                        nested: 'unit1',
                        options: secondUnits,
                        disabled: !isCreate,
                        colMd: 4
                    },
                    {
                        inputType: "select",
                        registerName: "unit3",
                        disabled: isLoading,
                        nested: 'unit1',
                        options: thirdUnits,
                        disabled: !isCreate,
                        colMd: 4
                    },
                    {
                        inputType: "input",
                        registerName: "full_name",
                        type: "textArea",
                        colMd: 12
                    },
                    {
                        component: <MapChoice units={thirdUnits} disabled={!isCreate}/>
                    }
                ]
            }
        },
        [choosedNew, projectNested, apartments, buildings, selectUnit1, units, unit2Options, selectUnit2, isNew, buildingsOfApart, thirdUnits]
    )

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: 'select',
                registerName: 'ul_hudluh_hurungiin_turul',
                onChange: value => handleIsNew(value.value),
                options: (
                    isNew === true
                    ?
                        [UL_HODLOH_TURUL[0]]
                    :
                        isNew === false
                        ?
                            UL_HODLOH_TURUL.filter(
                                (element, index) =>
                                {
                                    if(index === 0) return
                                    return element
                                }
                            )
                        :
                            UL_HODLOH_TURUL
                ),
                disabled: !isCreate,
                colMd: 12,
            },
            {
                component: (
                    <hr />
                )
            },
            {
                inputType: "input",
                registerName: "dugaar",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: !isCreate,
            },
            {
                inputType: "input",
                registerName: "geree_gerchilgee_dugaar",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: !isCreate,
            },
            {
                inputType: "input",
                registerName: "irgen_huuliin_etgeedin_ner",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
            },
            {
                inputType: "input",
                registerName: "register",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: !isCreate,
            },
            {
                component: (
                    <hr />
                )
            },
            ...displaySomeInputs,
            !(choosedNew === true || isNew === true)
            &&
                {
                    inputType: "input",
                    registerName: "mkv",
                    type: "number",
                    inputGroup: {
                        start: <i className="fa-solid fa-address-book"></i>,
                    },
                    step: 0.01,
                    disabled: !isCreate,
                },
            {
                component: (
                    <hr />
                )
            },
            {
                inputType: "input",
                registerName: "hudaldaj_awsan_une",
                type: "number",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                colMd: 4,
                step: 0.01,
                disabled: !isCreate,
            },
            {
                inputType: "input",
                registerName: "zaswar_zardal",
                type: "number",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                colMd: 4,
                step: 0.01,
                disabled: !isCreate,
            },
            {
                inputType: "input",
                registerName: "zarsan_une",
                type: "number",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                colMd: 4,
                step: 0.01,
                disabled: !isCreate,
            },
            {
                inputType: "input",
                registerName: "olz_garz",
                type: "number",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                step: 0.01,
                disabled: !isCreate,
            },
            {
                inputType: "datePicker",
                registerName: "ognoo",
            },
        ],
        button: {
            onSubmit: btnOnSubmit,
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "all",
            yupValidate: (
                isNew !== null
                ?
                    isNew
                    ?
                        zassanValidateNewFromMap
                    :
                        zassanValidateOldFromMap
                :
                    choosedNew
                    ?
                        zassanValidateNew
                    :
                        zassanValidateOld
            ),
        },
    }
    return (
        hasTitle
        ?
            <BoxBackground title={'Зуучлалын мэдээ'} body={<CForm isLoading={isLoading} inputValues={realValues} form={form}/>} />
        :
            <CForm isLoading={isLoading} inputValues={realValues} form={form}/>
    );
}

export default ZassanAction;
