import React from 'react';
import { Route, Routes } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';

import NowUsageCreate from './NowUsageCreate';
import NowUsage from '.';

const NowUsageRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<BoxBackground title='Одоогийн зориулалт' body={<NowUsage/>} />} />
            <Route path="/:id" element={<NowUsageCreate/>} />
        </Routes>
    );
}

export default NowUsageRoute;
