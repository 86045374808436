import React, { useEffect, useState } from "react"
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

import useApi from "hooks/useApi"
import useLoader from "hooks/useLoader"

const SignAccessLineChart = ({ date }) => {
	const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
	const signAccessApi = useApi().adminAccessFront
	const [chartData, setChartData] = useState({})
	const getData = async () => {
		const { success, data, errors } = await fetchData(signAccessApi.getSignLogLine(date[0], date[1])).catch(err => err)
		if (success) {
			setChartData(data)
		}
	}

	useEffect(() => {
		getData()
	}, [date])

	useEffect(() => {
        if(Object.keys(chartData).length === 0) return
		let newData = chartData.login.map(
			(element) =>
			{
				return {
					day: new Date(element.day).getTime(),
					count: element.count
				}
			}
		)
		let root = am5.Root.new("signLineChartDiv")

		root.setThemes([am5themes_Animated.new(root)])

		let chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				panX: true,
				panY: true,
				wheelX: "panX",
				wheelY: "zoomX",
				pinchZoomX: true,
			}),
		)

		let cursor = chart.set(
			"cursor",
			am5xy.XYCursor.new(root, {
				behavior: "none",
			}),
		)
		cursor.lineY.set("visible", false)

		let xAxis = chart.xAxes.push(
			am5xy.DateAxis.new(root, {
				baseInterval: { timeUnit: "day", count: 1 },
				renderer: am5xy.AxisRendererX.new(root, {
					minGridDistance: 50,
					pan: "zoom",
				}),
				tooltip: am5.Tooltip.new(root, {}),
			}),
		)

		let yAxis = chart.yAxes.push(
			am5xy.ValueAxis.new(root, {
				renderer: am5xy.AxisRendererY.new(root, {}),
			}),
		)

		let series = chart.series.push(
			am5xy.LineSeries.new(root, {
				name: "Series",
				xAxis: xAxis,
				yAxis: yAxis,
				valueYField: "count",
				valueXField: "day",
				tooltip: am5.Tooltip.new(root, {
					labelText: "{valueY}",
				}),
			}),
		)
		xAxis.get("dateFormats")["day"] = "MM/dd"

		let scrollbar = chart.set(
			"scrollbarX",
			am5xy.XYChartScrollbar.new(root, {
				orientation: "horizontal",
				height: 60,
			}),
		)

		let sbDateAxis = scrollbar.chart.xAxes.push(
			am5xy.DateAxis.new(root, {
				baseInterval: {
					timeUnit: "day",
					count: 1,
				},
				renderer: am5xy.AxisRendererX.new(root, {}),
			}),
		)

		let sbValueAxis = scrollbar.chart.yAxes.push(
			am5xy.ValueAxis.new(root, {
				renderer: am5xy.AxisRendererY.new(root, {}),
			}),
		)

		let sbSeries = scrollbar.chart.series.push(
			am5xy.LineSeries.new(root, {
				valueYField: "count",
				valueXField: "day",
				xAxis: sbDateAxis,
				yAxis: sbValueAxis,
			}),
		)

		chart.children.unshift(
			am5.Label.new(root, {
				text: "Нэвтэрсэн хэрэглэгчид",
				fontSize: 50,
				fill: "#777777",
				x: am5.percent(50),
				y: am5.percent(50),
				centerX: am5.percent(50),
				centerY: am5.percent(50),
				opacity: 0.25
			}),
		)

		series.data.setAll(newData)
		sbSeries.data.setAll(newData)

		series.appear(1000)
		chart.appear(1000, 100)
        return () =>
        {
            root.dispose()
        }
	}, [chartData])

	return (
		<div
			id="signLineChartDiv"
			style={{ width: "100%", height: "500px", position: "relative", border: "1px solid rgba(0,0,0,0.1)", borderRadius: "10px" }}>
			<div className="hidelogo"></div>
			{/* {emptyData && (
				<div>
					<DataNotFound />
				</div>
			)} */}
		</div>
	)
}

export default SignAccessLineChart
