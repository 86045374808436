import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import useMapContext from "hooks/useMapContext";

import Map from './Map'

const Home = () =>
{

    const location = useLocation()
    const { getBuilding } = useMapContext()

    useEffect(
        () =>
        {
            /** анх ороход нисэх эсэх */
            let isFly = false
            if (location.search)
            {
                isFly = true
            }

            getBuilding(isFly)
        },
        []
    )

    return (
        <div>
            <Map />
        </div>
    );
};

export default Home;
