import React, { useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import css from './style.module.css'

const BoxBackground = ({ title='', body=[], bodyTitle, footer, headerClassName, onClickTitle, children, className='', hasHeader=true }) =>
{
    const displayBody = useMemo(
        () =>
        {
            if(!body.length) return body
            let eachCol = 12 / body.length
            const bodys = body.map(
                (element, index) =>
                {
                    return  <Col key={index} md={eachCol} className={css.column}>
                                {
                                    body.length - 1 !== index
                                    ?
                                        <div className={css.line}></div>
                                    :
                                        null
                                }
                                {element}
                            </Col>
                }
            )
            return bodys
        },
        [body]
    )

    return (
		<Card className={`${css.card} ${className}`}>
			{title !== '' && (
				<Card.Header className={css.cardHeader + " " + headerClassName}>
					<div className={css.verticalLine}></div>
					<div className={onClickTitle && "cursorPointer"} onClick={() => onClickTitle && onClickTitle()}>
						{title}
					</div>
				</Card.Header>
			)}

			<Card.Body className={css.cardBody}>
				<Row>
					{bodyTitle && <div className={css.bodyTitle}>{bodyTitle}</div>}
					{displayBody}
					{children && children}
				</Row>
			</Card.Body>
			{footer && <Card.Footer>{footer}</Card.Footer>}
		</Card>
	)
}

export default BoxBackground;
