import React from 'react';

import Layout from 'components/special/Layout';

import companyRoutes from './routes';

const Company = () =>
{
    return <Layout sidebarTitle="ҮНДСЭН ЦЭС" links={companyRoutes} />;
}

export default Company;
