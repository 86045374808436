import React, { useEffect, useMemo, useState } from 'react';

import CButton from 'components/main/Button';

import { timeZoneToDateString } from 'utils/format';

import css from '../style.module.css'
import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';
import useForceUpdate from 'hooks/useForceUpdate';

const ShowNotification = ({ el }) => {

    const { title, content, created_at } = el.notification_info

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
    const notiApi = useApi().notification
    const forceUpdate = useForceUpdate()
    const songonApi = useApi().songon

    const getData = async (boolean) =>
    {
        const body = {
            agreed: boolean,
            not_state_id: el.id,
        }
        const { success, data, errors } = await fetchData(songonApi.memberComfirm(body)).catch(err => err)
        if(success)
        {
            boolean ? el.code ='accepted' : el.code ='rejected'
            forceUpdate()
        }
    }

    const gotoUrl = (url, id) =>
    {
        window.open(url.startsWith('http') ? url : 'http://' + url, '_blank', 'noopener,noreferrer');
    }

    const displayAnswer = useMemo(
        () =>
        {
            const object = {
				partner: (
					<div className={css.agreedButtons}>
						<CButton onClick={() => getData(true)} icon="fas fa-check" variant="success">
							Зөвшөөрөх
						</CButton>
						<CButton onClick={() => getData(false)} icon="fas fa-times" variant="danger">
							Татгалзах
						</CButton>
					</div>
				),
                rejected: (
                    <div className={css.agreedButtons}>
                        <CButton style={{ margin: 'auto'}} disabled={true} icon="fas fa-times" variant="danger">
                            Татгалзсан
                        </CButton>
                    </div>
                ),
                accepted: (
                    <div className={css.agreedButtons}>
                        <CButton style={{ margin: 'auto'}} disabled={true} icon="fas fa-check" variant="success">
                            Зөвшөөрсөн
                        </CButton>
                    </div>
                )
			}
            return object?.[el.code]
        },
        [el.code]
    )

    return (
        <div>
            <span className={`${css.notificationTitle} fs-16`}>📣 {title}</span>
            <span className="mt-2 d-block">{content}</span>
            {el.url && <CButton variant='second' onClick={() => gotoUrl(el.url)}>Хуудасруу очих</CButton>}
            {displayAnswer}
            <span className={css.notificationTime}><i className="far fa-clock"></i>{timeZoneToDateString(created_at)}</span>
        </div>
    );
}

export default ShowNotification;
