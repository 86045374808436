import React, { useEffect, useState } from 'react'

import useToast from 'hooks/useToast'

export default function UserLocation({ cMap })
{
    const [ isWatching, setWatching ] = useState(false)
    const [ watchId, setWatchId ] = useState(null)
    const { addToast } = useToast()

    const stopWatch = () =>
    {
        navigator.geolocation.clearWatch(watchId)
        setWatching(false)
        setWatchId(null)
        if (cMap)
        {
            cMap.userMakerSetCoordinate([undefined, undefined])
        }
    }

    useEffect(
        () =>
        {
            /** clearWatch ийх */
            return () =>
            {
                stopWatch()
            }
        },
        []
    )

    /** тухайн хүний location ийг харуулах нь */
    const handleLocation = () =>
    {
        if (isWatching)
        {
            stopWatch()
            return
        }

        const _watchId = navigator.geolocation.watchPosition(
            (position) =>
            {
                const { latitude, longitude } = position.coords
                const mapCoordinate = cMap.transfrorm4326to3857([ longitude, latitude ])
                cMap.userMakerSetCoordinate(mapCoordinate)
                setWatching(true)
            },
            (err) =>
            {
                addToast(
                    {
                        text: `Алдаа ${err.code}: ${err.message}`,
                        type: "error",
                    }
                )
            }
        );
        setWatchId(_watchId)
    }

    return (
        <div className="btn btn-primary cborder" onClick={handleLocation}>
            {
                !isWatching
                ?
                    <i className="fas fa-location-arrow"></i>
                :
                    <i className="fas fa-times"></i>
            }
        </div>
    )
}
