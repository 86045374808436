import React from 'react';
import { Route, Routes } from 'react-router-dom';

import OrgTypeAction from './OrgTypeAction'
import OrgType from '.';

import BoxBackground from 'components/special/BoxBackground';

const AdvantageCrudMain = () => {
    return (
        <Routes>
            <Route path="/*" element={<BoxBackground title='Байгууллагын төрөл' body={<OrgType />} />} />
            <Route path="/:id/" element={<OrgTypeAction/>} />
        </Routes>
    );
}

export default AdvantageCrudMain;
