import React from 'react';
import CButton from 'components/main/Button'
import { MAIN_BLUE } from 'utils/consts/colors'
import BoxBackground from 'components/special/BoxBackground';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

const ResetBuilding = () => {

    const apartmentApi = useApi().apartmentsdi
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })

    const ResetBuildingButton = async() =>
    {
        const { success, data, errors } = await fetchData(apartmentApi.getResetBuliding()).catch(err => err)
        if(success)
        {
        }
    }

    return (
        <div>
            <BoxBackground
                title='Барилга geom шинэчлэх хуудас'
            >
                <div
                    style={{ height: "100%" }}>
                    <CButton
                        style={{ backgroundColor: MAIN_BLUE }}
                        onClick={() => ResetBuildingButton()}
                        title={"Geom шинэчлэх"}
                    />
                </div>
            </BoxBackground>
        </div>
    );
}

export default ResetBuilding;
