import React, { useRef, useState } from "react"
import { useBeforeUnload, useGeolocation, useIdle, useIntersection, useLocation, useMediaDevices, useNetworkState, usePageLeave, useRaf, useScratch, useScroll, useScrolling, useSearchParam, useStartTyping, useToggle, useWindowScroll } from "react-use"
const TestReactUse = () => {
    const [ isDirty, setIsDirty ] = useState(false)
	useBeforeUnload(isDirty, "Хадгалахгүй гарахдаа итгэлтэй байна уу ?")

    let defValue = ''


    const state = useGeolocation();

	// yum oroldohfgvi udwal screen saver maygiin yum hiih bolomjtoi
	const isIdle = useIdle(3e3);


	// tuhain dev bvten garsan eeseh
	const intersectionRef = useRef(null)
  	const intersection = useIntersection(intersectionRef, {
			root: null,
			rootMargin: "0px",
			threshold: 1,
	})

	// more info useLoacation router-dom
	const state2 = useLocation();

	// param tai ajillah ??
	const edit = useSearchParam('edit');

	// utasnii medeelel
	const state3 = useMediaDevices();

	// mouse huudasnaas garah
	usePageLeave(() => alert('Page left...'));


	// mouse aa daraad select hiij baigaag awah
	const [ref, state4] = useScratch();

	const blockStyle: React.CSSProperties = {
	  position: 'relative',
	  width: 400,
	  height: 400,
	  border: '1px solid tomato',
	};

	const preStyle: React.CSSProperties = {
	  pointerEvents: 'none',
	  userSelect: 'none',
	};

	let { x = 0, y = 0, dx = 0, dy = 0 } = state;
	if (dx < 0) [x, dx] = [x + dx, -dx];
	if (dy < 0) [y, dy] = [y + dy, -dy];

	const rectangleStyle: React.CSSProperties = {
	  position: 'absolute',
	  left: x,
	  top: y,
	  width: dx,
	  height: dy,
	  border: '1px solid tomato',
	  pointerEvents: 'none',
	  userSelect: 'none',
	};

	// network medeelel
	const network = useNetworkState();

	// scroll
	const scrollRef = useRef(null);
	const {ax, ay} = useScroll(scrollRef);

	//scrolling
	const scrolling = useScrolling(scrollRef);

	useStartTyping(() => alert('Started typing...'));

	// window scroll
	const {windowx, windowy} = useWindowScroll();

	//***********************ANIMATIONS*********************** */
	const elapsed = useRaf(1000, 1000);

	return (
		<div ref={scrollRef}>
			<pre>{JSON.stringify(state, null, 2)}</pre>
			<input
				onChange={e => {
					if (e.target.value !== defValue) {
						return setIsDirty(true)
					}
					setIsDirty(false)
				}}
			/>
			<div>
				<div>User is idle: {isIdle ? "Yes 😴" : "Nope"}</div>
			</div>
			<div ref={intersectionRef}>{intersection && intersection.intersectionRatio < 1 ? "Obscured" : "Fully in view"}</div>
			<pre>{JSON.stringify(state2, null, 2)}</pre>
			<div>
				window scrol
				<div>x: {windowx}</div>
				<div>y: {windowy}</div>
			</div>
			<div>
				<div>edit: {edit || "🤷‍♂️"}</div>
				<div>
					<button onClick={() => window.history.pushState({}, "", window.location.pathname + "?edit=123")}>
						Edit post 123 (?edit=123)
					</button>
				</div>
				<div>
					<button onClick={() => window.history.pushState({}, "", window.location.pathname + "?edit=999")}>
						Edit post 999 (?edit=999)
					</button>
				</div>
				<div>
					<button onClick={() => window.history.pushState({}, "", window.location.pathname)}>Close modal</button>
				</div>
			</div>
			<pre>{JSON.stringify(state3, null, 2)}</pre>
			<div ref={ref} style={blockStyle}>
				<pre style={preStyle}>{JSON.stringify(state4, null, 4)}</pre>
				{state4.isScratching && <div style={rectangleStyle} />}
			</div>
			<pre>{JSON.stringify(network, null, 2)}</pre>
			<div>
				<div>x: {ax}</div>
				<div>y: {ay}</div>
			</div>
			{<div>{scrolling ? "Scrolling" : "Not scrolling"}</div>}
			<div>Elapsed: {elapsed}</div>
		</div>
	)
}

export default TestReactUse
