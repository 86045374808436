import React, { useEffect } from 'react';

import css from '../style.module.css'

const Counting = ({ icon, title, number, counterId }) =>
{
    return (
        <div className={css.countContainer}>
            <div className={css.countTitle}>
                {title}:
            </div>
            <div className={css.countNumber}>
                <div className={css.iconExpress}>
                    {icon}
                </div>
                <span>{number}</span>
            </div>
        </div>
    );
}

export default Counting;
