import Slider from 'rc-slider';
import { useEffect, useMemo, useState } from 'react';

import useMapContext from 'hooks/useMapContext';

import css from '../../style.module.css'

export default function Mkv({ fns })
{

    const { allFilters } = useMapContext()
    const initValue = {
        'mkv-begin': allFilters['mkv-begin'] ?? 0,
        'mkv-end': allFilters['mkv-end'] ?? 0
    }
    const [ value, setValue ] = useState(initValue)

    useEffect(
        () =>
        {
            fns.mkv = setValue
        },
        []
    )

    const max = 200
    const min = 1
    const step = 1

    const handleOnChange = (value) =>
    {
        setValue(
            {
                'mkv-begin': value[0],
                'mkv-end': value[1],
            }
        )
        allFilters['mkv-begin'] = value[0]
        allFilters['mkv-end'] = value[1]
    }

    const defaultMarks = useMemo(
        () =>
        {
            let oneStep = max / 10
            let marks = {}
            for(var step = oneStep; step <= max; step = step + oneStep)
            {
                marks[step] = Math.ceil(step)
            }
            return marks
        },
        [min ,max]
    )

    const values = useMemo(
        () =>
        {
            return (
                <div className={`${css.title} mt-4`}>
                    {value['mkv-begin']} - {value['mkv-end']}
                </div>
            )
        },
        [value]
    )

    return (
        <div className='mb-1'>
            <h6>Талбайн хэмжээ м²</h6>
            <Slider
                range={true}
                allowCross={false}
                max={max}
                marks={defaultMarks}
                min={min}
                step={step}
                value={[value['mkv-begin'], value['mkv-end']]}
                draggableTrack
                onChange={handleOnChange}
            />
            {values}
        </div>
    )
}
