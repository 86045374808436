import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import useRole from 'hooks/useRole';

import { PERM_UPDATE } from 'utils/consts'

/** crud үйлдэл хийхдээ энэхүү update ийг ашиглана */
const MapButton = ({ onClick, className, needRoles=[], isChoosed }) =>
{
    const hasPerm = useRole(needRoles, PERM_UPDATE)

    if (!hasPerm)
        return null

    return (
        <OverlayTrigger
            placement="left"
            delay={{ show: 0, hide: 0 }}
            overlay={<Tooltip id="button-tooltip-2">Засах</Tooltip>}
        >
            <div onClick={onClick} style={{ cursor: 'pointer' }}>
                <i style={{ color: isChoosed && 'blue' }} className={`fas fa-map-marked-alt ${className}`}></i>
            </div>
        </OverlayTrigger>
    );
}

export default MapButton
