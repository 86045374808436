import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { apartmentValidate, oldApartmentValidate } from 'utils/validate';
import { BUTETS_TYPE } from 'utils/consts';
import { dataToValueLabel } from 'utils/format'
import OldApartMap from './OldApartMap';
import CustomInputGroup from 'components/main/Form/CustomInputGroup';

const OldApartmentAction = () => {

    const [ inputValues, setInputValues ] = useState('');
    const [ parkingType, setParkingType ] = useState([])
    const [ advantage, setAdvantage ] = useState([])
    const [ geoId, setGeoId ] = useState('');
    const [ customError, setError ] = useState({})

    const [ selectedUnits, setSelectedUnits ] = useState({});

    const [ units, setUnits ] = useState([])
    const [ unit2Options, setUnit2Options ] = useState([])

    const [ selectUnit1, setUnit1 ] = useState('')
    const [ selectUnit2, setUnit2 ] = useState('')
    const [ selectUnit3, setUnit3 ] = useState('')

    const navigate = useNavigate();
    const { id: apartId } = useParams()

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const apartmentApi = useApi().oldApartment
    const advantageApi = useApi().advantage
    const parkingTypeApi = useApi().parkingtype
    const unitApi = useApi().unit

    /** create бол true, update бол false буцаана */
    const isCreate = apartId === 'create'

    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data, errors } = await fetchData(apartmentApi.getOne(apartId)).catch( err => err )
                if (success)
                {
                    setInputValues(data)
                }
            }

            const advantageRsp = await fetchData(advantageApi.get())
            const unitRsp = await fetchData(unitApi.get())
            const parkingTpyeRsp = await fetchData(parkingTypeApi.get())
            if(unitRsp.success && advantageRsp.success && parkingTpyeRsp.success)
            {
                setAdvantage(dataToValueLabel(advantageRsp.data, 'id', 'name'))
                setParkingType(dataToValueLabel(parkingTpyeRsp.data, 'id', 'parking_type'))
                setUnits(unitRsp.data)
            }
        },
        []
    )

    useEffect(
        () =>
        {
            if(inputValues)
            {
                setUnit1(inputValues.unit1.id)
                setUnit2(inputValues.unit2.id)
                setUnit3({value: inputValues.unit3.code, name: inputValues.unit3.name, extraValue: inputValues.unit3.id })
                setTimeout(() => setGeoId(inputValues.geo_id), 500)
            }
        },
        [inputValues]
    )

    const secondUnits = useMemo(
        () =>
        {
            if(!selectUnit1) return null
            let unit1Id = typeof selectUnit1 === "object" ? selectUnit1.id : selectUnit1
            const unit = units?.find(
                (el) =>
                {
                    return el.id === unit1Id
                }
            )
            setUnit2Options(unit?.unit2)
            return dataToValueLabel(unit?.unit2, 'id', 'name')
        },
        [selectUnit1, units]
    )

    const thirdUnits = useMemo(
        () =>
        {
            if(!selectUnit2) return null
            let unit2Id = typeof selectUnit2 === "object" ? selectUnit2.id : selectUnit2
            const unit = unit2Options?.find(
                (el) =>
                {
                    return el.id === unit2Id
                }
            )
            return dataToValueLabel(unit?.unit3, 'code', 'name', null, 'id')
        },
        [unit2Options, selectUnit2]
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        if(geoId === '')
        {
            setError({geoid: {
                message: 'Газрын зургаас барилга сонгоно уу'
            }})
            return
        }
        const fData = new FormData()
        const config =
        {
            headers: { 'content-type': 'multipart/form-data' }
        }
        fData.append('butets_type', formData.butets_type)
        fData.append('has_lift', formData.has_lift)
        fData.append('name', formData.name)
        fData.append('service_floor', formData.service_floor)
        fData.append('total_ail', formData.total_ail)
        fData.append('total_floor', formData.total_floor)
        fData.append('total_service_area', formData.total_service_area)
        fData.append('total_under_floor', formData.total_under_floor)
        fData.append("geo_id", geoId)
        fData.append("unit1", formData.unit1)
        fData.append("unit2", formData.unit2)
        fData.append("unit3", selectUnit3.extraValue)
        if (isCreate)
        {

            fData.append('real_photo', formData.real_photo[0])

            const { success, errors } = await fetchData(apartmentApi.post(fData, config)).catch(error => error)
            if (success) return navigate(-1)
            else return errors
        }

        if(inputValues.real_photo !== formData.real_photo) fData.append('real_photo', formData.real_photo[0])

        const { errors, success, data } = await fetchData(apartmentApi.put(apartId, fData, config)).catch(error => error)
        if (success) navigate(-1)
        else return errors
    }

    useEffect(
        () =>
        {
            setGeoId('')
            if(selectUnit1 && selectUnit2 && selectUnit3)
            {
                setSelectedUnits({
					unit1: selectUnit1.toString(),
					unit2: selectUnit2.toString(),
					unit3: selectUnit3.value.toString(),
				})
                return
            }
            setSelectedUnits({})
        },
        [selectUnit1, selectUnit2, selectUnit3,]
    )

    const getGeoId = (id) =>
    {
        setGeoId(id)
    }

    useEffect(
        () =>
        {
            if(geoId === '') return
            setError({})
        },
        [geoId]
    )

    /** Form утгууд */
    const form = {
		inputs: [
			{
				inputType: "input",
				registerName: "name",
				type: "text",
				inputGroup: {
					start: <i className="fa-solid fa-address-book"></i>,
				},
				label: {
					text: "Apartment-ийн нэр: ",
				},
				placeholder: "Apartment-ийн нэр",
				disabled: isLoading,
				colMd: 12,
			},
			{
				inputType: "select",
				registerName: "has_lift",
				disabled: isLoading,
				options: [
					{ value: true, label: "Тийм" },
					{ value: false, label: "Үгүй" },
				],
				colMd: 6,
			},
			{
				inputType: "select",
				registerName: "butets_type",
				disabled: isLoading,
				options: BUTETS_TYPE,
				colMd: 6,
			},
			{
				inputType: "imageUpload",
				registerName: "real_photo",
				colMd: 12,
			},
			{
				inputType: "input",
				registerName: "service_floor",
				type: "number",
				inputGroup: {
					start: <i className="fa-solid fa-address-book"></i>,
				},
				disabled: isLoading,
				colMd: 4,
			},
			{
				inputType: "input",
				registerName: "total_floor",
				type: "number",
				inputGroup: {
					start: <i className="fa-solid fa-address-book"></i>,
				},
				disabled: isLoading,
				colMd: 4,
			},
			{
				inputType: "input",
				registerName: "total_ail",
				type: "number",
				inputGroup: {
					start: <i className="fa-solid fa-address-book"></i>,
				},
				disabled: isLoading,
				colMd: 4,
			},
			{
				inputType: "input",
				registerName: "total_service_area",
				type: "number",
				step: 0.01,
				inputGroup: {
					start: <i className="fa-solid fa-address-book"></i>,
				},
				disabled: isLoading,
				colMd: 4,
			},
			{
				inputType: "input",
				registerName: "total_under_floor",
				type: "number",
				inputGroup: {
					start: <i className="fa-solid fa-address-book"></i>,
				},
				disabled: isLoading,
				colMd: 4,
			},
			{
				component: (
					<div className="mb-2">
						Байршил сонгох <hr />
					</div>
				),
			},
			{
				inputType: "select",
				registerName: "unit1",
				onChange: value => setUnit1(value.value),
				disabled: isLoading,
				options: dataToValueLabel(units, "id", "name"),
				colMd: 4,
			},
			{
				inputType: "select",
				registerName: "unit2",
				onChange: value => setUnit2(value.value),
				disabled: isLoading,
				nested: "unit1",
				options: secondUnits,
				colMd: 4,
			},
			{
				inputType: "select",
				registerName: "unit3",
				onChange: value => setUnit3(value),
				nested: "unit2",
				nestedObjectKey: "code",
				disabled: isLoading,
				options: thirdUnits,
				colMd: 4,
			},
			{
				component: (
					<CustomInputGroup registerName='geoid' errors={customError} text="Газрын зургаас сонгох:" md={12}>
						<OldApartMap setGeoId={setGeoId} geo_id={inputValues.geo_id} getGeoId={getGeoId} units={selectedUnits} />
					</CustomInputGroup>
				),
			},
		],
		button: {
			onSubmit: async data => {
				return btnOnSubmit(data)
			},
			disabled: isLoading,
			isLoading: isLoading,
			title: isCreate ? "Үүсгэх" : "Хадгалах",
		},
		validate: {
			validateMode: "all",
			yupValidate: oldApartmentValidate,
		},
	}

    return (
        <CForm inputValues={inputValues} form={form}/>
    );
}

export default OldApartmentAction;
