import React from 'react';
import { Routes, Route } from 'react-router-dom';

import RoleSignin from './RoleSignin';
import SignIn from './SignIn';
import SignUp from './SignUp';
// import Out from './SignOut';

import { NotFound } from 'pages/Error';

export default function Sign()
{
    return (
		<Routes>
			<Route path={"/in/"} element={<RoleSignin orgPath={"/sign/in/org/"} danPath={'/sign/in/dan/'} />} />
			<Route path={"/in/org/*"} element={<SignIn />} />
			<Route path={"/up/"} element={<RoleSignin orgPath={"/sign/up/org/"} danPath={'/sign/up/dan/'} />} />
			<Route path={"/up/org/"} element={<SignUp />} />
			<Route path={"/up/dan/"} element={<SignUp />} />
            <Route path='*' element={<NotFound />} />
		</Routes>
	)
}
