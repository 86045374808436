import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import BackButton from 'components/main/BackButton';

import SpecialNews from './SpecialNews';

import useMobile from 'hooks/useMobile';

import css from './style.module.css'
import NewsList from './NewsList';

const page = 'news'

const News = () =>
{
	const [ listView, setListView ] = useState('grid')
	const { newsId } = useParams()
	const { isMobile } = useMobile()

	useEffect(
		() =>
		{
			if(!isMobile) return
			setListView('grid')
		},
		[isMobile]
	)

    return (
		<Container>
			<Row className="skipHeader">
				<Col xl={12}>
					<div className={css.pageHeader}>
						<div className={css.vLine}></div>МЭДЭЭ, МЭДЭЭЛЭЛ
						<BackButton/>
					</div>
					<hr />
					{
						isMobile
						?
							null
						:
							!newsId
							&&
							<div className="mb-3">
								<i
									onClick={() => setListView("grid")}
									style={{ fontSize: "15px" }}
									className={`fas fa-th cursorPointer ${css.newsSmallText} ${listView === 'grid' ? css.activeList : ""}`}
								/>
								<i
									onClick={() => setListView("list")}
									style={{ fontSize: "15px" }}
									className={`fas fa-list cursorPointer ${css.newsSmallText} ${listView === 'list' ? css.activeList : ""}`}
								/>
							</div>
					}
				</Col>
				<Col lg={{span: 4, order: 2}}>
					<SpecialNews page={page} />
				</Col>
				<Col lg={{span: 8, order: 1}}>
					<NewsList listView={listView} page={page}/>
				</Col>
			</Row>
		</Container>
	)
}

export default News;
