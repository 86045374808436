import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ReportCreateOc1 from './ReportCreateOc1';
import ReportCreateOc2 from './ReportCreateOc2';

import Report from '.';

const ReportMain = () => {
    return (
        <Routes>
            <Route path="/" element={<Report />} />
            <Route path="/:chosed_year" element={<Report />} />
            <Route path="/:year/createoc1" element={<ReportCreateOc1/>} />
            <Route path="/:year/createoc1/:id" element={<ReportCreateOc1/>} />
            <Route path="/:year/createoc2" element={<ReportCreateOc2/>} />
            <Route path="/:year/createoc2/:id" element={<ReportCreateOc2/>} />
        </Routes>
    );
}

export default ReportMain;
