import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import Datatable from 'components/main/Datatable';
import CreateButton from 'components/main/CudButtons/Create';

import useApi from 'hooks/useApi';
import useAuth from 'hooks/useAuth';

import CTable from 'components/main/Table';
import useLoader from 'hooks/useLoader';
import UpdateButton from 'components/main/CudButtons/Update';
import DeleteButton from 'components/main/CudButtons/Delete';
import { adminPageRoles } from 'utils/consts/roles';
import { timeZoneToDateString } from 'utils/format';

const NowUsage = () =>
{
    const [ tableRows, setTableRows ] = useState([])
    const { companyId } = useAuth()
    const nowUsageApi = useApi().nowUsage
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
    const navigate = useNavigate()

    const getData = async () =>
    {
        const { success, data } = await fetchData(nowUsageApi.get()).catch(err => err)
        if(success)
        {
            setTableRows(data)
        }
    }

    useEffect(
        () =>
        {
            getData()
        },
        []
    )

    const columns =
    [
        {
            field: "name",
            order: true,
            fieldComponent: (fieldValue, index, row) => fieldValue
        },
        {
            field: "created_at",
            order: false,
            fieldComponent: (value) => timeZoneToDateString(value)
        },
        {
            field: "updated_at",
            order: false,
            fieldComponent: (value) => timeZoneToDateString(value)
        },
    ]
    /** Хүснэгтийн мөрийн утгыг устгах */
    const deleteRow = async id =>
    {
            await fetchData(nowUsageApi.delete(id))
            await getData()
    }
        const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }
    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.id}/`)} needRoles={adminPageRoles} />
                        <DeleteButton onClick={() => deleteRow(item.id)} needRoles={adminPageRoles} />
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
		<Row>
			<Col sm={12}>
				<CreateButton
					btnClassName="floatRight"
					onClick={() => navigate("create/")}
					needRoles={adminPageRoles}
				/>
			</Col>
			<Col>
				<CTable isLoading={isLoading} columns={columns} sequenceColumn={sequenceColumn} extraColumns={extraColumns} rows={tableRows} />
			</Col>
		</Row>
	)
}

export default NowUsage;
