import { toast } from 'react-toastify';

import { MAIN_TOAST_THEME } from 'utils/consts';
// import { ToastContext } from 'context/toastContext'

//  toast ийн context ийг хэрэглэх hook
/**
 * @returns toast
 */
const useToast = () =>
{

    /**
     * Toast нэмэх нь
     * @param {string}      text        TOAST дээр харагдах msg
     * @param {number}      time        TOAST ийн харагдах хугацаа
     * @param {Function}    onClose     TOAST ийн хаагдах үеийн event
     * @param {string}      type        TOAST ийн төрөл
     * @param {string}      theme       TOAST theme
     */
    const addToast = ({ text, onClose, time, type='success', theme=MAIN_TOAST_THEME }) =>
    {
        toast(
            text,
            {
                position: toast.POSITION.TOP_RIGHT,
                onClose: () => { if (onClose) onClose() },
                type: type,
                // delay: time ? time : 0,
                autoClose: time,
                theme,
            }
        )
    }

    const clearAllToast = () =>
    {
        toast.dismiss()
    }

    return { addToast, clearAllToast }
}

export default useToast;
