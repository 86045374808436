import React, { createContext, useState } from 'react';

import { initialModal } from 'components/main/Modal';

import { setOptions } from 'utils'

export const ModalContext = createContext()

// Modal ийн нээгдэж хаагдхийг удирдах нь
export default function ModalContextProvider(props)
{
    // модалын төлөв
    const [show, setShow] = useState(false)
    // Модалын доторх мэдээллүүд
    const [modal, setModal] = useState(initialModal)

    /**
     * Modal ийг хааж callback байх юм бол тухайн функцийг ажиллуулна
     * @param {Function} callback хаагдсны дараа ажиллах функц
     */
    const setHide = (callback) =>
    {
        setShow(false)
        if (callback)
        {
            callback()
        }
        // setTimeout(() => {
        setModal(initialModal)
        // }, 500);
    }

    /**
     * Модал харуулах мэдээллүүдийг modal гэсэн state -д хадгална
     * show ийг true болгоно
     * @param {object} modal модалын мэдээлэл
     */
    const setDisplay = (modal) =>
    {
        setShow(true)
        const mainOption = { ...initialModal }
        const option = setOptions(mainOption, modal)
        setModal(option)
    }

    /**
     * Модал харуулах мэдээллүүдийг modal гэсэн state -д хадгална
     * show ийг true болгоно
     * @usage Энэ бол анхааруулга маягийн жижиг модал харуулахад хэрэглэнэ
     * @param {object} modal модалын мэдээлэл
     * @param {warning, success, danger} modal.type эдгээрээс сонгож icon гаргах боломжтой showIcon нь icons object ээс шууд icon нэр буцаана
     * @param {Component} modal.icon модал дээр гарж ирэх icon-ийг гаднаас явуулна
     * @param {Component || string} modal.question модалын асуултыг явуулна
     * @param {boolean} modal.btnShow модалын Тийм товчийг харуулах эсэх
     * @param {Component} modal.btnIcon модалын Тийм товчний icon явуулна
     * @param {Function} modal.onClick модалын Тийм товч дарахад ажиллах функц
     * @param {css className} modal.btnClassName модалын Тийм товчны className явуулна
     * @param {String} modal.btnText модалын Тийм товчны оронд харуулах үгээ явуулна
     * @param {String} modal.btnVariant модалын Тийм товчны variant явуулна danger, warning, success etc..
     * @return Жижиг анхааруулгын модал гарч ирнэ
     */
    const showWarning = (modal) =>
    {
        setShow(true)

        // Модалд icon сонгож өгөх
        const showIcon = (type='warning') =>
        {
            let icons = {
                warning: 'fas fa-exclamation-triangle text-warning',
                success: 'fas fa-check-circle text-success',
                danger: 'fas fa-times-circle text-danger',
            }
            return icons?.[type]
        }

        const handleAction = () =>
        {
            if (modal.onClick)
            {
                modal.onClick()
            }
            setHide()
        }

        setModal(
            {
                ...initialModal,
                header: {
                    show: true,
                },
                size: "md",
                body: <div  className='text-center'>
                        {modal.icon ? modal.icon : <i className={`${showIcon(modal.type)} fa-3x mb-2`} />}
                        <div>{modal.question || 'Уг үйлдэлийг хийхдээ итгэлтэй байна уу?'} </div>
                    </div>,
                btnAction: {
                    show: typeof modal.btnShow === 'boolean' ? modal.btnShow : true,
                    icon: modal.btnIcon || "",
                    on: handleAction,
                    className: modal.btnClassName || "",
                    text: modal.btnText || "Тийм",
                    type: "button",
                    variant: modal.btnVariant || 'warning',
                },
                handleHide: modal.handleHide || null
            }
        )
    }

    return (
        <ModalContext.Provider value={{ show, modal, setDisplay, setHide, setShow, showWarning }}>
            {props.children}
        </ModalContext.Provider>
    );
}
