import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CForm from 'components/main/Form';

import useLoader from 'hooks/useLoader';
import useApi from 'hooks/useApi';

import { buildingValidate } from 'utils/validate';
import { dataToValueLabel } from 'utils/format';
import { IS_RENT, STATES } from 'utils/consts';

const BuildingCreate = () => {

    const [ residence, setResidence ] = useState([]);

    const { id: projectId, apartId } = useParams()
    const navigate = useNavigate()

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const residenceApi = useApi().residence
    const buildingApi = useApi().building

    useEffect(
        async () =>
        {
            const residenceRsp = await fetchData(residenceApi.getAll(projectId, apartId))
            if(residenceRsp.success)
            {
                setResidence(dataToValueLabel(residenceRsp.data, "id", null, ["total_mkv", "zereglel_display"]))
            }
        },
        []
    )

    const btnOnSubmit = async (formData) =>
    {
        let fData = { ...formData, project: projectId, apartment: apartId }
        const { errors, success, data } = await fetchData(buildingApi.post(projectId, apartId, fData)).catch(error => error)
            if (success) return navigate(-1)
            else return errors
    }

    /** Form утгууд */
    const form = {
        inputs: [
            {
                inputType: "input",
                registerName: "door_number",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
                colMd: 4
            },
            {
                inputType: "input",
                registerName: "input_number",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
                colMd: 4
            },
            {
                inputType: "input",
                registerName: "floor",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
                colMd: 4
            },
            {
                inputType: "select",
                registerName: "state",
                disabled: isLoading,
                options: STATES,
                colMd: 4
            },
            {
                inputType: "select",
                registerName: "residence",
                disabled: isLoading,
                options: residence,
                colMd: 4
            },
            {
                inputType: "select",
                registerName: "is_rent",
                disabled: isLoading,
                options: IS_RENT,
                colMd: 4
            },
        ],
        button: {
            onSubmit: async (data) => {
                return btnOnSubmit(data);
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: "Үүсгэх",
        },
        validate: {
            validateMode: "all",
            yupValidate: buildingValidate,
        },
    };

    return <CForm form={form}/>
}

export default BuildingCreate;
