import { getRandomInt } from "utils";

export const fakeAgendaResult = [
	{
		id: 15,
		agenda: [
			{
				id: 23,
				name: "Хө1 зорилт 1",
				result: getRandomInt(100),
				subagenda: [
					{
						id: 21,
						name: "шалгуур 2",
						result: getRandomInt(100),
						basestatus: "20",
						futurestatus: "100",
						amount: "111.0",
						currentstatus: "321",
						units: 4,
					},
					{
						id: 19,
						name: "Хө1 зорилт 1 шалгуур үзүүлэлт1",
						result: getRandomInt(100),
						basestatus: "23",
						futurestatus: "100",
						amount: "11.0",
						units: 5,
						currentstatus: "111",
					},
				],
				amount: "1222.0",
			},
			{
				id: 24,
				name: "Хө1 зорилт 2",
				result: getRandomInt(100),
				subagenda: [
					{
						id: 20,
						name: "Шалгуур 2",
						result: getRandomInt(100),
						basestatus: "123",
						futurestatus: "123",
						amount: "123.0",
						currentstatus: "321",
						units: 6,
					},
					{
						id: 22,
						name: "гадгуур2 2",
						result: getRandomInt(100),
						basestatus: "12",
						futurestatus: "100",
						amount: "123.0",
						currentstatus: "5",
						units: 7,
					},
				],
				amount: "123.0",
			},
		],
		main_subject_name: "Барилга хот байгуулалтын яам",
		budget_type_name: "Бусад эх үүсвэр",
		contrib_subject: [
			{
				name: "Барилгын хөгжлийн төв",
			},
			{
				name: "Улаанбаатар хотын захирагчийн алба",
			},
		],
		name: "Хөтөлбөр 1",
		budget_amount: "50000000000.0",
		start_date: "2022-05-31",
		end_date: "2022-06-02",
		result: getRandomInt(100),
		created_at: "2022-05-30T13:12:17.609154+08:00",
		updated_at: "2022-05-31T10:35:35.177406+08:00",
		budget_type: 14,
		main_subject: 37,
		zorilgot_buleg: [4, 6, 7],
	},
	{
		id: 16,
		agenda: [
			{
				id: 26,
				name: "zorilt 2",
				result: getRandomInt(100),
				subagenda: [
					{
						id: 24,
						name: "zzzxx vvvbbbbb",
						result: getRandomInt(100),
						basestatus: "2323",
						futurestatus: "10000",
						amount: "1123.0",
						currentstatus: "0",
						units: 8,
					},
				],
				amount: "2.0",
			},
			{
				id: 27,
				name: "zorilt 3",
				result: getRandomInt(100),
				subagenda: [
					{
						id: 25,
						name: "122122311212312",
						result: getRandomInt(100),
						basestatus: "111",
						futurestatus: "11111111",
						amount: "123.0",
						currentstatus: "321",
						units: 9,
					},
				],
				amount: "123.0",
			},
			{
				id: 25,
				name: "zorilt 1",
				result: getRandomInt(100),
				subagenda: [
					{
						id: 23,
						name: "shalguur1",
						result: getRandomInt(100),
						basestatus: "1",
						futurestatus: "100",
						amount: "1.0",
						currentstatus: "1212",
						units: 10,
					},
				],
				amount: "123.0",
			},
		],
		main_subject_name: "Барилгын хөгжлийн төв",
		budget_type_name: "Улс, орон нутгийн төсөв",
		contrib_subject: [
			{
				name: "Барилгын хөгжлийн төв",
			},
			{
				name: "Дагуул, хот эдийн засгийн тусгай бүсийн хөгжлийн газар",
			},
		],
		name: "Хөтөлбөр 2",
		budget_amount: "123.0",
		start_date: "2022-05-31",
		end_date: "2022-05-31",
		result: getRandomInt(100),
		created_at: "2022-05-30T13:13:29.887020+08:00",
		updated_at: "2022-05-31T10:35:38.784290+08:00",
		budget_type: 8,
		main_subject: 36,
		zorilgot_buleg: [2],
	},
	{
		id: 18,
		agenda: [],
		main_subject_name: "Барилга хот байгуулалтын яам",
		budget_type_name: "Улс, орон нутгийн төсөв",
		contrib_subject: [
			{
				name: "Барилгын хөгжлийн төв",
			},
			{
				name: "Улаанбаатар хотын захирагчийн алба",
			},
		],
		name: "Хөтөлбөр 5",
		budget_amount: "123.0",
		start_date: "2022-06-02",
		end_date: "2022-09-17",
		result: getRandomInt(100),
		created_at: "2022-05-30T13:14:02.367929+08:00",
		updated_at: "2022-06-02T12:29:48.316450+08:00",
		budget_type: 8,
		main_subject: 37,
		zorilgot_buleg: [2],
	},
	{
		id: 17,
		agenda: [],
		main_subject_name: "Улаанбаатар хотын захирагчийн алба",
		budget_type_name: "Улс, орон нутгийн төсөв",
		contrib_subject: [
			{
				name: "Барилгын хөгжлийн төв",
			},
			{
				name: "Улаанбаатар хотын захирагчийн алба",
			},
			{
				name: "Төрийн орон сууцны корпораци",
			},
			{
				name: "Дагуул, хот эдийн засгийн тусгай бүсийн хөгжлийн газар",
			},
		],
		name: "Хөтөлбөр 345",
		budget_amount: "1.0",
		start_date: "2022-06-02",
		end_date: "2022-11-18",
		result: getRandomInt(100),
		created_at: "2022-05-30T13:13:43.904172+08:00",
		updated_at: "2022-06-02T12:29:57.520912+08:00",
		budget_type: 8,
		main_subject: 45,
		zorilgot_buleg: [1, 2, 3],
	},
	{
		id: 10,
		agenda: [
			{
				id: 20,
				name: "Хот, суурины хөгжлийн ерөнхий төлөвлөгөө, хэсэгчилсэн ерөнхий төлөвлөгөөний дагуу гэр хорооллыг иргэдийн оролцоотой дахин төлөвлөх замаар байгаль орчинд ээлтэй, ашиглалтын зардал багатай инженерийн дэд бүтцэд холбогдсон тохилог гэр, амины болон нийтийн орон сууцны хороолол болгон хөгжүүлэхэд чиглэсэн үйл ажиллагааг эрчимжүүлэх",
				result: getRandomInt(100),
				subagenda: [
					{
						id: 6,
						name: "Гэр хорооллын газрыг дахин төлөвлөн барилгажуулах төслийн хүрээнд шинээр баригдах орон сууц",
						result: getRandomInt(100),
						basestatus: "50189",
						futurestatus: "5189",
						amount: "60000.0",
						currentstatus: "0",
						units: 5,
					},
					{
						id: 7,
						name: "Инженерийн хангамжийн эх үүсвэр, шугам сүлжээний суурилагдсан хүчин чадлын ашиглалтыг сайжруулах, гэр хорооллын газрыг дахин зохион байгуулах төслийн хүрээнд инженерийн хангамжид холбогдох амины орон сууц",
						result: getRandomInt(100),
						basestatus: "0",
						futurestatus: "10000",
						amount: "5000000.0",
						currentstatus: "0",
						units: 12,
					},
					{
						id: 18,
						name: "blbalba",
						result: getRandomInt(100),
						basestatus: "100",
						futurestatus: "1000",
						amount: "10000.0",
						currentstatus: "350",
						units: 11,
					},
				],
				amount: "1000000000.0",
			},
			{
				id: 18,
				name: "Орон сууцны санхүүжилтийн тогтолцоо, хууль, эрх зүйн орчныг боловсронгуй болгох",
				result: getRandomInt(100),
				subagenda: [
					{
						id: 15,
						name: "Иргэдийн орон сууцны хангамжийг нэмэгдүүлэх, нөхцөлийг сайжруулахад чиглэсэн хууль, эрх зүйн орчинг бүрдүүлэх",
						result: getRandomInt(100),
						basestatus: "225",
						futurestatus: "220",
						amount: "900000.0",
						currentstatus: "1",
						units: 6,
					},
					{
						id: 14,
						name: "Инженерийн бүрэн хангамжтай орон сууцтай болох өрх",
						result: getRandomInt(100),
						basestatus: "38.1",
						futurestatus: "66.6",
						amount: "900000000.0",
						currentstatus: "1",
						units: 8,
					},
				],
				amount: "50000000000.0",
			},
			{
				id: 22,
				name: "Төрийн халамж, дэмжлэг зайлшгүй шаардлагатай зорилтот бүлгийн иргэдийн орон сууцны хангамж, нөхцөлийг сайжруулах",
				result: getRandomInt(100),
				subagenda: [
					{
						id: 17,
						name: "Түрээсийн орон сууцанд  хамрагдах зорилтот бүлгийн иргэн",
						result: getRandomInt(100),
						basestatus: "0",
						futurestatus: "18488",
						amount: "10000000.0",
						currentstatus: "321",
						units: 7,
					},
				],
				amount: "8500040.0",
			},
			{
				id: 21,
				name: "Байгалийн нөөцийн хэмнэлттэй, хүлэмжийн хийн ялгарал багатай, ногоон хөгжлийн үзэл баримтлалд нийцсэн орон сууцны нийлүүлэлтийг дэмжих",
				result: getRandomInt(100),
				subagenda: [
					{
						id: 16,
						name: "Шинэ суурьшил, дагуул, хаяа хот, тосгодыг хөгжүүлэх хүрээнд шинээр ашиглалтад орох орон сууц",
						result: getRandomInt(100),
						basestatus: "4222",
						futurestatus: "20734",
						amount: "100000.0",
						currentstatus: "123",
						units: 10,
					},
				],
				amount: "1000000000.0",
			},
		],
		main_subject_name: "Барилга хот байгуулалтын яам",
		budget_type_name: "Гадаадын зээл, тусламж",
		contrib_subject: [
			{
				name: "Барилгын хөгжлийн төв",
			},
			{
				name: "Төрийн орон сууцны корпораци",
			},
			{
				name: "Улаанбаатар хотын захирагчийн алба",
			},
			{
				name: "Барилга хот байгуулалтын яам",
			},
			{
				name: "Дагуул, хот эдийн засгийн тусгай бүсийн хөгжлийн газар",
			},
		],
		name: "150000 орон сууц",
		budget_amount: "1000000.0",
		start_date: "2022-06-02",
		end_date: "2023-12-01",
		result: getRandomInt(100),
		created_at: "2022-04-11T12:52:10.704250+08:00",
		updated_at: "2022-06-02T12:32:37.545070+08:00",
		budget_type: 7,
		main_subject: 37,
		zorilgot_buleg: [3],
	},
]
