import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';

import BoxBackground from 'components/special/BoxBackground';

import LocationItem from './LocationItem';
import IconNumber from './IconNumber';

import label from 'utils/validate/label';
import { moneyFormat } from 'utils/format';

import css from '../style.module.css'

const GeneralInfo = ({ data={} }) => {

	const { project_info } = data

	const displayRooms = useMemo(
		() =>
		{
			let rooms=[]
			rooms = data?.rooms?.map(
				(el, index) =>
				{
					return <IconNumber key={index} count={el.room_count} code={el.room_code} title={el.room_name}/>
				}
			)
			rooms?.push(<IconNumber key={'asa'} count={data.total_room} code='total' title={'Нийт өрөө'}/>)
			return rooms
		},
		[data]
	)

    return (
		<BoxBackground title="ЕРӨНХИЙ МЭДЭЭЛЭЛ">
			<div className="position-relative w-100">
				<div></div>
				{
					data?.state_display
					&&
						<label className={data.state_display !== "ЗАРАГДСАН" ? css.statusGreen : css.statusRed}>{data.state_display}</label>
				}
			</div>
			<label className={css.subTitle}>
				<div className="d-flex">
					<div className={css.columnOne}>
						<span className={css.fieldName}>
							Төслийн нэр:
						</span>
					</div>
					<div className={css.columnTwo}>
						<label className={`${css.valueName} fw-normal`}>{project_info?.project_name}</label>
					</div>
				</div>
				<div className="d-flex">
					<div className={css.columnOne}>
						<span className={css.fieldName}>
							Төслийн тайлбар:
						</span>
					</div>
					<div className={css.columnTwo}>
						<label className={`${css.valueName} fw-normal`}>{project_info?.description}</label>
					</div>
				</div>
				<div className="d-flex">
					<div className={css.columnOne}>
						<span className={css.fieldName}>
							М<sup>2</sup> үнэ:
						</span>
					</div>
					<div className={css.columnTwo}>
						<label className={`${css.valueName} fw-normal`}>{moneyFormat(project_info?.price_mkv || 0)}</label>
					</div>
				</div>
				<div className="d-flex">
					<div className={css.columnOne}>
						<span className={css.fieldName}>
							Өмчлөх төрөл:
						</span>
					</div>
					<div className={css.columnTwo}>
						<label className={`${css.valueName} fw-normal`}>{project_info?.property_types}</label>
					</div>
				</div>
				<div className="d-flex">
					<div className={css.columnOne}>
						<span className={css.fieldName}>
							{label['use_date']}:
						</span>
					</div>
					<div className={css.columnTwo}>
						<label className={`${css.valueName} fw-normal`}>{project_info?.use_date}</label>
					</div>
				</div>
				<div className="d-flex">
					<div className={css.columnOne}>
						<span className={css.fieldName}>
							{label['is_rent']}:
						</span>
					</div>
					<div className={css.columnTwo}>
						<label className={`${css.valueName} fw-normal`}>{data?.is_rent ? 'Тийм' : 'Үгүй'}</label>
					</div>
				</div>
				<div className="d-flex">
					<div className={css.columnOne}>
						<span className={css.fieldName}>
							Хаяг:
						</span>
					</div>
					<div className={css.columnTwo}>
						<label className={`${css.valueName} fw-normal`}>
							{data?.project_info?.unit1_info?.name}, {data?.project_info?.unit2_info?.name},{" "}
							{data?.project_info?.unit3_info?.name}
							{data?.project_info?.unit4 && ", " + data?.project_info?.unit4}
						</label>
					</div>
				</div>
			</label>
			<div>
				<Row className={css.items}>{displayRooms}</Row>
			</div>
			{/* <label className={css.subTitle}>
				Байршилын мэдээлэл: <LocationItem data={data?.apartment_info?.apartment_location_info[0]} />{" "}
			</label> */}
		</BoxBackground>
	)
}

export default GeneralInfo;
