import React from 'react';
import { Route, Routes } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';

import NewsPageList from './main'
import NewsPageAction from './NewsPageAction'

import NewsRoutes from './News'

/** Мэдээтэй холбоотой хуудсууд */
const NewsPageRoutes = () =>
{
    return (
        <Routes>
            <Route
                path='/'
                element={
                    <BoxBackground
                        title='Мэдээний хуудас'
                    >
                        <NewsPageList />
                    </BoxBackground>
                }
            />
            <Route
                path='/:pageId/'
                element={
                    <BoxBackground
                        title='Мэдээний хуудас'
                    >
                        <NewsPageAction />
                    </BoxBackground>
                }
            />
            <Route
                path='/:pageId/news/*'
                element={
                    <NewsRoutes />
                }
            />
        </Routes>
    );
}

export default NewsPageRoutes;
