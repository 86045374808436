import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Button } from 'react-bootstrap';

import CreateButton from 'components/main/CudButtons/Create';
import DeleteButton from 'components/main/CudButtons/Delete';
import UpdateButton from 'components/main/CudButtons/Update';
import GetOneDetail from 'components/special/GetOneDetail';
import CTable from 'components/main/Table';

import useLoader from 'hooks/useLoader';
import useModal from 'hooks/useModal';
import useApi from 'hooks/useApi';

import { adminPageRoles } from 'utils/consts/roles';
import { timeZoneToDateString } from 'utils/format';

const HelperMain = () => {

    const [ rows, setRows ] = useState([]);

    const navigate = useNavigate();

    const helperApi = useApi().helper

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })

    const { setDisplay } = useModal()

    /** Modal гаргаж ирэх funct */
    const displayDetail = (video_url) =>
    {
        setDisplay(
            {
                header:
                {
                    title: "Бичлэг",
                },
                body: (
                    <video controls className='w-100 my-3'>
                        <source src={video_url} type='video/mp4' />
                    </video>
                ),
                size: "lg"
            }
        )
    }

    /** Хүснэгтийн багана */
    const columns =
    [
        {
            field: "name",
            headerName: 'Үйл ажиллагааны нэр',
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "target_group",
            headerName: 'Role',
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "pdf",
            headerName: 'PDF',
            order: false,
            fieldComponent: (fieldValue, index, row) => {
                if (fieldValue)
                {
                    return <a href={fieldValue} target='_blank'><i className="fa fa-file-pdf text-danger" aria-hidden="true"></i></a>
                }
                return null
            }
        },
        {
            field: "created_at",
            order: false,
            fieldComponent: (value) => timeZoneToDateString(value)
        },
        {
            field: "updated_at",
            order: false,
            fieldComponent: (value) => timeZoneToDateString(value)
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтний утгыг авах */
    const getData = async () =>
    {
        const { success, data } = await fetchData(helperApi.get())
        if(success)
        {
            setRows(data)
        }
    }

    // back руу хүсэлт шидэх
    useEffect(
        () =>
        {
            getData()
        },
        []
    )

    /** Хүснэгтийн мөрийн утгыг устгах */
    const deleteRow = async (id) =>
    {
        await fetchData(helperApi.delete(id))
        await getData()
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        {/* <UpdateButton className='me-2' onClick={() => navigate(`${item.id}/`)} needRoles={adminPageRoles} /> */}
                        <DeleteButton onClick={() => deleteRow(item.id)} needRoles={adminPageRoles} />
                        <Button className='m-0 ms-2' variant="outline-primary" onClick={() => displayDetail(item.video)}>Бичлэг</Button>
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
        <Row>
            <Col sm={12}>
                <CreateButton btnClassName='floatRight' onClick={() => navigate('create/')} needRoles={adminPageRoles} />
            </Col>
            <Col>
                <CTable
                    isLoading={isLoading}
                    columns={columns}
                    sequenceColumn={sequenceColumn}
                    extraColumns={extraColumns}
                    rows={rows}
                />
            </Col>
        </Row>
    );
}

export default HelperMain;
