import React from 'react'

import useToast from 'hooks/useToast'

import CButton from 'components/main/Button'

export default function TestToast()
{

    const { addToast } = useToast()

    return (
        <div>
            <CButton
                variant={"success"}
                onClick={
                    () =>
                    {
                        addToast(
                            {
                                text: "Амжилттай боллоо",
                                type: "success"
                            }
                        )
                    }
                }
            >
                SUCCESS
            </CButton>
            <CButton
                variant={"danger"}
                onClick={
                    () =>
                    {
                        addToast(
                            {
                                text: "Амжилтгүй боллоо",
                                type: "error"
                            }
                        )
                    }
                }
            >
                DANGER
            </CButton>
            <CButton
                variant={"warning"}
                onClick={
                    () =>
                    {
                        addToast(
                            {
                                text: "Анхаарна уууу",
                                type: "warning"
                            }
                        )
                    }
                }
            >
                warning
            </CButton>
        </div>
    )
}
