import useMapContext from "hooks/useMapContext"
import { useEffect, useState } from "react"
import { useMemo } from "react"

import css from '../../style.module.css'

export default function RoomChoice({ fns })
{
    const { allFilters } = useMapContext()
    const [ activeItem, setActive ] = useState(allFilters['total-room'] ?? null)

    const options = [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
    ]

    const handleOnChoose = (value) =>
    {
        allFilters['total-room'] = value
        setActive(value)
    }

    useEffect(
        () =>
        {
            fns.room = setActive
        },
        []
    )

    const displayItems = useMemo(
        () =>
        {
            if(!options) return
            return options.map(
                (element, index) =>
                {
                    return (
						<div
                            key={index}
							onClick={() => handleOnChoose(element.value)}
							className={`${css.chooseItem} ${activeItem === element.value ? css.choosedItem : ""}`}
                        >
							{element.label}
						</div>
					)
                }
            )
        },
        [options]
    )

    return (
        <div>
            <h6>Өрөөний тоо</h6>
            <div className={css.chooseItemContainer}>
                {displayItems}
            </div>
        </div>
    )
}
