import Residence from 'pages/Company/Project/ProjectTabs/Residence';
import ResidenceAction from 'pages/Company/Project/ProjectTabs/Residence/ResidenceAction';
import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { searchParamsToObject } from 'utils/browser';

const ResidenceList = ({ mapProjectId, removeOverlay }) =>
{
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ residenceId, setResidenceId ] = useState(null)
    const getResId = (id) =>
    {
        setResidenceId(id)
    }

    const removeOverlayHandler = () =>
    {
        removeOverlay()
    }

    const dispalyBody = useMemo(
        () =>
        {
            if(searchParamsToObject(searchParams).tab !== 'residence') return null
            if(residenceId)
            {
                return <ResidenceAction removeOverlay={removeOverlayHandler} mapProjectId={mapProjectId} residenceId={residenceId} />
            }
            else
            {
                return <Residence getResId={getResId} mapProjectId={mapProjectId}/>
            }
        },
        [ residenceId, mapProjectId ]
    )

    return dispalyBody
}

export default ResidenceList
