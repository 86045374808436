
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ProjectMapMain from './main'

const ProjectMap = () =>
{
    return (
        <Routes>
            <Route path="/" element={<ProjectMapMain />} />
        </Routes>
    );
}

export default ProjectMap;
