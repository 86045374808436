import LineSlider from "components/main/Form/InputTypes/LineSlider"
import BuildingPriceAnalytacs from "pages/Home/Map/BuildingPriceAnalytacs"

const TestLineSlider = ({ isRange=true }) =>
{

    return (
        <div>
            {/* <LineSlider/>
             */}
             <BuildingPriceAnalytacs/>
        </div>
    )
}

export default TestLineSlider
