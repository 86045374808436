import React, { useEffect, useMemo, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Modify } from 'ol/interaction';
import { Collection } from 'ol'

import useMap from 'hooks/map/useMap'
import useLoader from 'hooks/useLoader'
import useApi from 'hooks/useApi'
import useMapContext from 'hooks/useMapContext'

import CButton from 'components/main/Button'
import MapControls from 'components/special/MapControls'
import Scale from 'components/special/MapControls/Scale'

import OverlayDetail from './Overlays'

import { styleFunction, selectStyle, styleOfAu, styleOfCadaster, activeCadaster } from 'hooks/map/helper'

const config = {
    isProject: false,
    isCreateProject: false,
    cadasterID: "",
    point: [],
    feature: {},
    isApartment: false,
    apartmentId: 0,
    projectId: 0,
}

export default function ProjectMapMain()
{
    const [ searchParams, setSearchParams ] = useSearchParams()

    const [ interactions, setInteraction ] = useState({})
    const [ layers, setLayers ] = useState([])
    const [ apartments, setApartments ] = useState([])
    const [ resetFeatures, setResetFeatures ] = useState([])

    const [ projects, setProjects ] = useState([])

    const { map, cMap } = useMap({ firstZoom: 16.2 })
    const navigate = useNavigate()
    const projectMapApi = useApi().project.onmap
    const axios = useApi({ isDisplayToast: false }).instance
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })

    const { setChoosedFeature } = useMapContext()

    /** plan ий json ийг арилгах нь */
    const removePlan = () =>
    {
        let removeIdx = []
        layers.map(
            (layer, idx) =>
            {
                if (layer.get("name") === "plan")
                {
                    cMap.deleteLayer(layer)
                    removeIdx.push(idx)
                }
            }
        )
        removeIdx.map(
            (idx, index) =>
            {
                layers.splice(idx, 1)
            }
        )
    }

    /** cadaster style ийг буцаах нь */
    const resetCadasterStyle = () =>
    {
        let removeIdx = []
        resetFeatures.map(
            (feature, idx) =>
            {
                feature.setStyle(styleOfCadaster)
                removeIdx.push(idx)
            }
        )
        removeIdx.map(
            (idx, index) =>
            {
                resetFeatures.splice(idx, 1)
            }
        )
    }

    /** custom overlay ийг харуулах */
    const displayOverlay = () =>
    {
        const popElem = document.getElementById("custom-ol-popup-project")
        popElem.classList.remove("none")
    }

    /** custom overlay ийг нуух
     * @param {Boolean} resetMap Датагаа дахин дуудах эсэх
     */
    const removeOverlay = (resetMap=false) =>
    {
        const popElem = document.getElementById("custom-ol-popup-project")
        popElem.classList.add("none")
        cMap.clearSelect()
        removePlan()
        resetCadasterStyle()
        cMap.userMakerSetCoordinate([0, 0])
        if (resetMap)
        {
            layers.map((layer) =>
                cMap.deleteLayer(layer)
            )
            layers.splice(0, layers.length)
            setChoosedFeature({})
            getProjects()
            setAuWFS()
        }
    }

    /** Тухайн нэг et дээр дарах үед */
    const handleEtFeature = (event) =>
    {
        setChoosedFeature({ ...config })
        removePlan()

        const feature = event?.selected?.[0]

        if (!feature)
        {
            removeOverlay()
            return
        }

        displayOverlay()

        const featureName = feature.get("name")

        /** Тухайн feature рүү үсрэх */
        const extent = feature.getGeometry().getExtent()
        cMap.fitToExtent(extent, { duration: 500, padding: [ 500, 100, 500, 600 ] })

        // Тухайн et ийн general plan ийг харуулах
        const project = feature.get("data")
        if (project.general_plan)
        {
            axios
                .get(project.general_plan + `?dt=${Date.now()}`)
                .catch(err => err)
                .then(
                    (data) =>
                    {
                        if (data?.success !== false)
                        {
                            const features = cMap.parseGeoJsonToFeature(data)
                            features.map(
                                (feat) =>
                                {
                                    feat.set("cproject", project)
                                }
                            )
                            const { vectorLayer, vectorSource } = cMap.addVectorLayer(features, null, "plan", (...args) => styleFunction(...args, false))
                            layers.push(vectorLayer)
                        }
                    }
                )
        }

        // TODO:  тухайн дарагдсан цэгийн координатыг засварлах боломжтой болгох
        // if (featureName === 'point')
        // {
        //     if (interactions['modify'])
        //     {
        //         map.removeInteraction(interactions['modify'])
        //     }

        //     const _features = new Collection([feature])
        //     const modify = new Modify({
        //         features: _features,
        //     });
        //     modify.setActive(true)
        //     interactions['modify'] = modify
        //     const target = document.getElementById('map')
        //     modify.on(['modifystart', 'modifyend'], function (evt) {
        //         target.style.cursor = evt.type === 'modifystart' ? 'grabbing' : 'default';
        //         if (evt.type === "modifyend")
        //         {
        //             const pixel = map.getEventPixel(evt.mapBrowserEvent);
        //             let cadasterID = ""
        //             map.forEachFeatureAtPixel(
        //                 pixel,
        //                 function (f, layer)
        //                 {
        //                     console.log("layer", layer, f);
        //                     /** кадастер байвал id авах тул утга сольсон */
        //                     if (layer && layer.get("name") === "gs_au_5")
        //                     {
        //                         cadasterID = f.get("localid")
        //                     }
        //                     return f
        //                 }
        //             );
        //             // setChoosedFeature({ ...config, feature, isProject: true, projectId: project.id, point:  })
        //         }
        //     });
        //     map.addInteraction(modify)
        // }

        // NOTE:  нэгж талбарыг өнгөөр ялгаж харуулах
        let cadasterIds = project['cadaster_id'] ?? ""
        cadasterIds = cadasterIds.split(',')

        let mapExtent = map.getView().calculateExtent(map.getSize());
        const _layers = map.getLayers().getArray()
        const cadasterLayer = _layers.find((layer) => layer.get("name") === "gs_au_5")
        const cadasterSource = cadasterLayer.getSource()
        cadasterSource.forEachFeatureInExtent(mapExtent, function(feature)
        {
            const cadasterId = feature.get("localid")
            const foundIndex = cadasterIds.findIndex((el) => el === cadasterId);
            if (foundIndex > -1)
            {
                // style ийг өөрчлөх нь
                feature.setStyle(activeCadaster)
                cadasterIds.splice(foundIndex, 1)
                resetFeatures.push(feature)
            }
        });

        setSearchParams({ tab: "project" })
        setChoosedFeature({ ...config, feature, isProject: true, projectId: project.id })
    }

    /** газрын зураг дээр feature гүй газар дарах үед overlay ийг нуух */
    const handleMapClick = (event) =>
    {
        /** Дарагдсан газар feature байгаа эсэх */
        const clickedFeature = map.forEachFeatureAtPixel(
            event.pixel,
            (feature) =>
            {
                return feature
            }
        )

        /** Дарагдсан газар feature байхгүй бол overlay нуух*/
        if (!clickedFeature)
        {
            removeOverlay()

            cMap.userMakerSetCoordinate(event.coordinate)
            handleCreate(event.coordinate)
        }
        else {
            const planLayer = layers.find(layer => layer.get("name") === "plan")
            if (planLayer)
            {
                const clickedFeatureOlId = clickedFeature.ol_uid
                /** Тухайн general plan ий feature үүд */
                const layerFeatures = planLayer.getSource().getFeatures()
                /** Тухайн general plan ий feature үүдийн дарагдасан feature */
                const clickedBuilding = layerFeatures.find(
                    (feature) =>
                    {
                        return feature.ol_uid === clickedFeatureOlId
                    }
                )

                /** тухайн барилга дээр дарах үед style ийг нь өгө */
                if (clickedBuilding)
                {

                    if (!clickedBuilding.get("Text") || clickedBuilding.get("Text") != "Барилга")
                    {
                        return
                    }

                    const clonedStyle = planLayer.getStyle()(clickedBuilding).clone()
                    const strokeColor = clonedStyle.getStroke().getColor()

                    /** Хуучин өнгийг нь буцаах нь */
                    layerFeatures.map(
                        (feature, idx) =>
                        {
                            clonedStyle.getStroke().setColor(strokeColor)
                            feature.setStyle(undefined)
                        }
                    )

                    const clonedStyle2 = planLayer.getStyle()(clickedBuilding).clone()
                    clonedStyle2.getStroke().setColor("green")
                    clonedStyle2.getStroke().setWidth(5)
                    clickedBuilding.setStyle(clonedStyle2)

                    setSearchParams({ tab: "apartment" })
                    setChoosedFeature({ ...config, feature: clickedBuilding, isApartment: true, apartmentId: clickedBuilding.get("apartment_id"), projectId: clickedBuilding.get("cproject")?.id })
                }
                return
            }
            const casterLayer = layers.find(layer => layer.get("name") === "gs_au_5")
            if (casterLayer)
            {

                const pixel = map.getEventPixel(event.originalEvent);
                let cadasterID = ""
                let hasPoint = false
                map.forEachFeatureAtPixel(
                    pixel,
                    function (f, layer)
                    {
                        /** кадастер байвал id авах тул утга сольсон */
                        if (layer.get("name") === "gs_au_5")
                        {
                            cadasterID = f.get("localid")
                        }

                        if (f.get('name') === "point")
                        {
                            hasPoint = true
                        }

                        return f
                    }
                );
                if (!hasPoint)
                {
                    removePlan()
                    resetCadasterStyle()

                    cMap.userMakerSetCoordinate(event.coordinate)
                    handleCreate(event.coordinate, cadasterID)
                }
                else {
                    cMap.userMakerSetCoordinate([0, 0])
                    removePlan()
                    resetCadasterStyle()
                }
            }
            else {
                cMap.userMakerSetCoordinate([0, 0])
                removePlan()
                resetCadasterStyle()
            }
        }

    }

    /** project уудыг газрын зураг дээр харуулах */
    const projectsOnMap = async (projects) =>
    {
        let etFeatures = []
        let pointFeatures = []
        Promise.all(projects.map(
            async (project, idx) =>
            {
                //  et file байвал et file ийг нь харуулах
                if (project.et_file)
                {
                    const data = await axios.get(project.et_file + `?dt=${Date.now()}`).catch(err => err)
                    if (data?.success !== false)
                    {
                        const features = cMap.parseGeoJsonToFeature(data)
                        features.map(
                            (feature, idx) =>
                            {
                                // feature.set('cMap-style', styles[feature.getGeometry().getType()])
                                feature.set('text', project.name)
                                feature.set("data", project)
                                feature.set("name", "et")
                                etFeatures.push(feature)
                            }
                        )
                    }
                }
                //  байхгүй бол Point ийг газрын зурган дээр харуулах
                else if (project.general_point)
                {
                    const pointFeature = cMap.parseGeoJsonToFeature(project.general_point)
                    pointFeature.map(
                        (feature, idx) =>
                        {
                            // feature.set('cMap-style', styles[feature.getGeometry().getType()])
                            feature.set('text', project.name)
                            feature.set("data", project)
                            feature.set("name", "point")
                            pointFeatures.push(feature)
                        }
                    )
                }
            }
        )).then((data) =>
        {
            const mapFeatures = pointFeatures.concat(etFeatures)
            const { vectorLayer, vectorSource } = cMap.addVectorLayer(mapFeatures, null, "et_point", (...args) => styleFunction(...args, true))
            cMap.createSelect(
                'click',
                (evt) => handleEtFeature(evt),
                selectStyle,
                [vectorLayer],
            )
            layers.push(vectorLayer)
            cMap.removeLoader()
        })
    }

    /** Газрын зураг дээр харуулах project уудыг дуудах нь */
    const getProjects = async () =>
    {
        cMap.addLoader()
        const { success, data, error, errors } = await fetchData(projectMapApi.get()).catch(err => err)
        if (success)
        {
            await projectsOnMap(data)
            setProjects(data)
        }
    }

    /** Төсөл шинээр үүсгэх overlay ийг харуулах */
    const handleCreate = (point, cadasterID)  =>
    {
        cMap.clearSelect()
        removePlan()
        displayOverlay()
        setSearchParams({ tab: "project" })
        setChoosedFeature({ ...config, isCreateProject: true, point, cadasterID })
    }

    /** Газрын зурган дээр хил заагийг WFS ээр харуулах */
    const setAuWFS = () =>
    {
        const wfsUrl = "https://geo.nsdi.gov.mn/geoserver/gp_bnd/wfs"
        const cadasterWfsUrl = 'https://geo.nsdi.gov.mn/geoserver/gp_cp/wfs'

        const typeNameCadaster = "gp_cp:gp_layer_cadastral_parcel_cp_view"
        const typeNameBagHoroo = "gp_bnd:gp_layer_c_4thorder_administrativeunit_au_view"
        const typeNameSumDuureg = "gp_bnd:gp_layer_c_3rdorder_administrativeunit_au_view"
        const typeNameAimagNiislel = "gp_bnd:gp_layer_c_2ndorder_administrativeunit_au_view"
        const typeNameUls = "gp_bnd:gp_layer_c_1storder_administrativeunit_au_view"

        const vectorSourceCadaster = cMap.wfsFromGeoserver(cadasterWfsUrl, typeNameCadaster)
        const vectorSourceBagHoroo = cMap.wfsFromGeoserver(wfsUrl, typeNameBagHoroo)
        const vectorSourceSumDuureg = cMap.wfsFromGeoserver(wfsUrl, typeNameSumDuureg)
        const vectorSourceAimagNiislel = cMap.wfsFromGeoserver(wfsUrl, typeNameAimagNiislel)
        const vectorSourceUls = cMap.wfsFromGeoserver(wfsUrl, typeNameUls)
        const { vectorLayer: vectorLayerCadaster, vectorSource: vectorSourcegCadaster } = cMap.addVectorLayer([], vectorSourceCadaster, 'gs_au_5', styleOfCadaster, { minZoom: 16, maxZoom: 22 })
        const { vectorLayer: vectorLayerBagHoroo, vectorSource: vectorSourcegUnit1 } = cMap.addVectorLayer([], vectorSourceBagHoroo, 'gs_au_4', styleOfAu, { minZoom: 14.5, maxZoom: 22 })
        const { vectorLayer: vectorLayerSumDuureg, vectorSource: vectorSourcegUnit2 } = cMap.addVectorLayer([], vectorSourceSumDuureg, 'gs_au_3', styleOfAu, { minZoom: 10, maxZoom: 14.5 })
        const { vectorLayer: vectorLayerAimagNiislel, vectorSource: vectorSourcegUnit3 } = cMap.addVectorLayer([], vectorSourceAimagNiislel, 'gs_au_2', styleOfAu, { minZoom: 5, maxZoom: 10 })
        const { vectorLayer: vectorLayerUls } = cMap.addVectorLayer([], vectorSourceUls, 'gs_au_1', styleOfAu, { minZoom: 1, maxZoom: 5 })

        layers.push(vectorLayerCadaster)
        layers.push(vectorLayerBagHoroo)
        layers.push(vectorLayerSumDuureg)
        layers.push(vectorLayerAimagNiislel)
        layers.push(vectorLayerUls)
    }

    useEffect(
        () =>
        {
            if (map)
            {
                map.on(
                    "click",
                    handleMapClick
                )
                setChoosedFeature({})
                getProjects()
                setAuWFS()
            }
        },
        [map]
    )

    return (
        <div className='position-relative'>
            <Row className='mb-3 justify-content-end'>
                <Col className="">
                    <CButton
                        onClick={() => navigate('/gov/project/form/')}
                    >
                        <i className='fal fa-table'/>
                    </CButton>
                </Col>
            </Row>
            <div id='map' style={{ height: "75vh", position: "relative", overflow: 'hidden' }}>
                <MapControls
                    map={map}
                    showButtons={["fs"]}
                    cMap={cMap}
                    // extraBtns={
                    //     [
                    //         {
                    //             icon: "far fa-layer-plus",
                    //             onClick: () => handleCreate(),
                    //             title: "Төсөл үүсгэх"
                    //         }
                    //     ]
                    // }
                />
                <Scale map={map} cMap={cMap} />
                <OverlayDetail removeOverlay={removeOverlay} cMap={cMap} />
            </div>
        </div>
    )
}
