import { useEffect, useState } from 'react'

import useLoader from 'hooks/useLoader'
import useApi from 'hooks/useApi'

import CForm from "components/main/Form";
import useModal from 'hooks/useModal';

const stateChoices = [
    {
        value: 2,
        label: 'Зарагдсан',
    },
    {
        value: 1,
        label: 'Зарагдаагүй',
    },
    {
        value: 3,
        label: 'Захиалгат',
    },
]
export default function BuildingAction({
    door_number,
    floor,
    id,
    input_number,
    is_delete,
    is_rent,
    state,
    state_name,
    updated_at,
    project,
    residence,
    apartment,
    residences=[],
    refresh
})
{

    const [ initValue, setInitValue ] = useState(
        {
            door_number,
            floor,
            input_number,
            state,
            residence,
        }
    )
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
    const buildingApi = useApi().building
    const { setHide } = useModal()

    const btnOnSubmit = async (formData) =>
    {
        let fData = { ...formData, project: project.id, apartment: apartment.id }
        const { errors, success, data } = await fetchData(buildingApi.put(fData.project, fData.apartment, id, fData)).catch(error => error)
        if (success)
        {
            refresh()
            return setHide()
        }
        else return errors
    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "door_number",
                type: "text",
                disabled: isLoading,
                colMd: 4
            },
            {
                inputType: "input",
                registerName: "floor",
                type: "text",
                disabled: isLoading,
                colMd: 4
            },
            {
                inputType: "input",
                registerName: "input_number",
                type: "number",
                disabled: isLoading,
                colMd: 4
            },
            {
                inputType: "select",
                registerName: "state",
                options: stateChoices,
                disabled: isLoading,
            },
            {
                inputType: "select",
                registerName: "residence",
                disabled: isLoading,
                options: residences,
            },
        ],
        button: {
            onSubmit: btnOnSubmit,
            disabled: isLoading,
            isLoading: isLoading,
            title: 'Хадгалах',
        },
        validate: {
            validateMode: "all",
        },
    }

    return (
        <div>
            <CForm form={form} inputValues={initValue} />
        </div>
    )
}
