import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import useApi from 'hooks/useApi';

export default function Editor(
    {
        watch,
        setValue,
        register,
    }
)
{
    const imageApi = useApi({ isDisplayToast: false }).image
    const editorValue = watch(register.name)

    const uploadImageCallBack = (blobInfo, progress) => new Promise(async (resolve, reject) =>
    {
        // Сонгогдсон зургийг авах
        const file = blobInfo.blob()

        const formData = new FormData()
        formData.append('image', file)

        const { success: rspSuccess, data, error } = await imageApi.upload(formData).catch(err => err)
        if (rspSuccess)
        {
            resolve(process.env.REACT_APP_SERVER_PUBLIC_URL + data.url)
        }
        else
        {
            reject(error)
        }
    })

    const handleChange = (value) =>
    {
        setValue(register.name, value)
    }

    return (
        <TinyEditor
            apiKey='9x3jf0v96beppvu6a7tvqou11bofilx0pz90w9d0vk9q9xmb'
            onEditorChange={(value) =>
                handleChange(value)
            }
            value={editorValue}
            init={{
                setup: function(editor)
                {
                },
                selector: 'textarea#full-featured',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
                plugins: 'image link code',
                toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | forecolor | backcolor | image link',
                menubar: true,
                resize: true,
                // imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',

                file_picker_types: 'image',
                image_title: true,
                images_upload_handler: uploadImageCallBack,
                paste_data_images: true,
                image_advtab: true,
                importcss_append: true,
                height: 600,
            }}
        />
    )
}
