import { useEffect, useMemo, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useLocation } from "react-router-dom"

import DatepickerInput from "components/main/Datepicker/DatepickerInput"
import CSelect from "components/main/Form/InputTypes/Select"

import DiffPriceLoader from "./SkeletonLoader"

import useApi from "hooks/useApi"
import useLoader from "hooks/useLoader"

import { getRandomInt } from "utils"
import { searchParamsToObject } from "utils/browser"
import { moneyFormat, numberToKillo, quantityFormat } from "utils/format"

import css from '../../OverLayBuilding/style.module.css'
import DataNotFound from "components/main/DataNotFound"
import { ONE_MONTH_TO_MILLISECONDS } from "utils/consts"

const selectType = [
	{ value: 0, label: "Худалдах" },
	{ value: 1, label: "Түрээслэх" },
]

const BuildingPriceDiffrence = ({ code, selectedDataType, sortType, endDate }) =>
{
	const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
	const priceApi = useApi().getAnalytac

	const [ unit1Data, setUnit1Data ] = useState([])

	useEffect(
		async () =>
		{
			let codes = []
			codes.push(parseInt(code))
			const body = {
				codes
			}
			const { success, data } = await fetchData(
				selectedDataType === 0
					? priceApi.postHudaldaa(body, "0", Date.now() - endDate.value * ONE_MONTH_TO_MILLISECONDS, Date.now(), sortType)
					: priceApi.postTurees(body, "0", Date.now() - endDate.value * ONE_MONTH_TO_MILLISECONDS, Date.now(), sortType),
			).catch(err => err)
			if(success)
			{
				setUnit1Data(data[0])
			}
		},
		[code, selectedDataType, endDate]
	)

	const monthsDiffrence = (data, isPercent=true) =>
	{
		let onePercent = data?.first_price / 100
		let diffrence = data?.last_price - data?.first_price
		if(isPercent)
		{
			return (diffrence / onePercent).toFixed(1)
		}
		return diffrence.toFixed(0)
	}

	const otherUnitsDiffrence = (data, isPercent=true) =>
	{
		if(!data?.other_unit_avg) return 0
		let onePercent = data?.other_unit_avg / 100
		let diffrence = data?.avg - data?.other_unit_avg
		if(isPercent)
		{
			return (diffrence / onePercent).toFixed(1)
		}
		return diffrence.toFixed(0)
	}

	const displayOthers = useMemo(
		() =>
		{
			let names = ['аймаг/хот', 'сум/дүүрэг', 'баг/хороо']
			if(code.length === 2) return names[0]
			if(code.length === 4) return names[1]
			if(code.length === 6) return names[2]
		},
		[code]
	)

    return (
		<Col className={`${css.boxWrapper} ${css.priceDiffCard}`} style={{ position: 'relative' }}>
			<div className={css.boxWrapperTitle}>
				<div style={{ marginLeft: "5px" }}>{unit1Data?.name}</div>
			</div>
			{isLoading ? (
				<DiffPriceLoader />
			) : unit1Data ? (
				<>
					<Row className={css.analytacItems}>
						<div className={css.analytacPrice}>{moneyFormat(unit1Data?.avg)}₮</div>
						<div className={css.analytacText}>дундаж үнэ</div>
						<Col className={css.analytacItem}>
							<div className="text-center me-2" style={{ fontSize: "18px" }}>
								{numberToKillo(monthsDiffrence(unit1Data, false))}
								<span style={{ fontSize: "13px" }}>K</span>
							</div>
							<div className={css.analytacText}>{endDate.label.includes('жил') ? endDate.label + 'ийн'  : endDate.label + 'ын'} өөрчлөлт</div>
							<div
								className={`${css.analytacPercent} ${
									monthsDiffrence(unit1Data) > 0 ? "success" : monthsDiffrence(unit1Data) === 0 ? "pending" : "danger"
								}
							`}>
								<div
									className={`${
										monthsDiffrence(unit1Data) > 0
											? "borderSuccess"
											: monthsDiffrence(unit1Data) === 0
											? "borderPending"
											: "borderDanger"
									}`}>
									<i className={`fas fa-caret-${monthsDiffrence(unit1Data) >= 0 ? "up" : "down"} me-1`}></i>
									{monthsDiffrence(unit1Data)} %
								</div>
							</div>
						</Col>
						<Col className={css.analytacItem}>
							<div className="text-center" style={{ fontSize: "18px"}}>{quantityFormat(unit1Data?.total_apart)}</div>
							<div className={css.analytacText}>орон сууцын тоо  </div>
						</Col>
						<Col className={css.analytacItem}>
							<div className="text-center me-2" style={{ fontSize: "18px" }}>
								{numberToKillo(otherUnitsDiffrence(unit1Data, false))}
								<span style={{ fontSize: "13px" }}>K</span>
							</div>
							<div className={css.analytacText}>{displayOthers} - уудын дундажаас</div>
							<div
								className={`${css.analytacPercent} ${
									otherUnitsDiffrence(unit1Data) > 0 ? "success" : otherUnitsDiffrence(unit1Data) === 0 ? "pending" : "danger"
								}
							`}>
								<div
									className={`${
										otherUnitsDiffrence(unit1Data) > 0
											? "borderSuccess"
											: otherUnitsDiffrence(unit1Data) === 0
											? "borderPending"
											: "borderDanger"
									}`}>
									<i className={`fas fa-caret-${otherUnitsDiffrence(unit1Data) >= 0 ? "up" : "down"} me-1`}></i>
									{otherUnitsDiffrence(unit1Data)} %
								</div>
							</div>
						</Col>
					</Row>
				</>
			) : (
				<DataNotFound absolute={false}/>
			)}
		</Col>
	)
}

export default BuildingPriceDiffrence
