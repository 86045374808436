import React from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';

import DangerInfo from '../DangerInfo';

import css from '../style.module.css'

const Item = ({ name, description, status, displayWord, pdf, error_message }) =>
{

    const pdfUrl = process.env.REACT_APP_SERVER_PUBLIC_URL + pdf

    // <Document className={'d-flex justify-content-center'} file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
    //     <Page pageNumber={pageNumber} />
    // </Document>

    return (
        <Row className={css.item}>
            <Accordion>
                <Accordion.Item className={css.accordItem} eventKey={name}>
                    <Accordion.Header className={css.accordHeader}>
                        <Col>
                            <i className={`fas fa-file me-2 ${css.fileIcon}`}/> {name}
                        </Col>
                        <Col className={css.desc}>{description}</Col>
                        <Col>{displayWord(status)}</Col>
                    </Accordion.Header>
                    <Accordion.Body>
                        {
                            status !== 'Амжилтгүй'
                            ?
                                <iframe className={css['container-pdf']} src={`${pdfUrl}`}></iframe>
                            :
                                <DangerInfo info={error_message}/>
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Row>
    );
}

export default Item;
