import React, { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap'

import { counter } from 'utils';

import useModal from 'hooks/useModal';

import Badge from 'components/main/Badge';
import CButton from 'components/main/Button';
import ResidenceInfo from '../ResidenceInfo';

import css from '../style.module.css'

const TotalMembers = ({
    id,
    realId,
    joined,
    number,
    resideneceInfo,
    join,
    isEnd,
    winUser,
}) => {

    const { lotteryId } = useParams()
    const { setDisplay } = useModal()

    useEffect(
        () =>
        {
            if(number === 0) return
            counter(id, parseInt(number/1.2), number, 1)
        },
        []
    )

    const displayDetail = () =>
    {
        setDisplay(
            {
                header:
                {
                    title: "Сонголт хийх"
                },
                body: <ResidenceInfo resideneceInfo={resideneceInfo} isEnd={isEnd} joined={joined} lotteryId={lotteryId} realId={realId} />,
                size: "lg",
                footer:
                {
                    show: true
                }
            }
        )
    }

    return (
        <div className="position-relative">
            {
                join
                ?
                    <Badge
                        text={"Бүртгүүлсэн"}
                        style={{
                            left: '-10px',
                            top: '-10px'
                        }}
                    />
                :
                    ""
            }
            <span className={css.moreDot} onClick={() => displayDetail()}>
                <i className="far fa-ellipsis-v"></i>
            </span>
            <Row className={`${css.number}`}>
                <Col sm={4} md={4} lg={4} className="col-4">
                    <span className={css.numberTitle + " " + css.title1}></span>
                    <section className="d-flex align-items-center justify-content-center">
                        <div className={css.iconExpress}>
                            <i className="far fa-trophy"></i>
                        </div>
                        <span>{winUser}</span>
                    </section>
                </Col>
                <Col sm={4} md={4} lg={4} className={css.zuraas + " col-4"}>
                    <span className={css.numberTitle + " " + css.title2}></span>
                    <section className="d-flex align-items-center justify-content-center">
                        <div className={css.iconExpress}>
                            <i className="far fa-users"></i>
                        </div>
                        <span id={id}>
                            {number === 0 && number}
                        </span>
                    </section>
                </Col>
                <Col sm={4} md={4} lg={4} className={css.zuraas + " col-4"}>
                    <span className={css.numberTitle}>{'Сонголтын тоо:'}</span>
                    <section className="d-flex align-items-center justify-content-center">
                        <div className={css.iconExpress}>
                            <i className="far fa-home"></i>
                        </div>
                        <span>
                            {resideneceInfo && resideneceInfo.length > 0 ? resideneceInfo.length : 0}
                        </span>
                    </section>
                </Col>
		    </Row>
            {
                !isEnd && !joined
                &&
                    <div className='d-flex justify-content-center mt-3'>
                        <CButton variant="second" onClick={() => displayDetail()}>
                            Бүртгүүлэх
                        </CButton>
                    </div>
            }
        </div>
	)
}

export default TotalMembers;
