import { useState, useLayoutEffect, useContext } from "react";
import { Group, Tile, Tile as TileLayer } from 'ol/layer';
import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import { OSM, XYZ } from 'ol/source';
import {get as getProjection} from 'ol/proj';
import {getTopLeft, getWidth} from 'ol/extent';
import Map from 'ol/Map';
import View from 'ol/View';

import CMap, { CENTER_OF_MAP, FIRST_ZOOM_LEVEL, FEATURE_PROJECTION, DATA_PROJECTION } from "./CMap";
import { oldApartmentValidate } from "utils/validate";


/** Газрын үндсэн газрын зургийг үүсгэж хувьсагчийг энэчээнээс авах
 * @param {string} projection газрын зургийн projection
 * @param {string} mapId газрын зураг үүсгэх container ийн ID
*/
export default function useMap({ projection=FEATURE_PROJECTION, mapId="map", firstZoom=FIRST_ZOOM_LEVEL })
{
    const [ map, setMap ] = useState(
        {
            map: null,
            cMap: null
        }
    )

    const handleMap = () =>
    {

        // DEBUG: газрын зураг render хийгдсэн байгаа эсэхийг шалгаж дахиж нэмэх эсэхээ шийдэх нь
        const alreadyMap = document.querySelectorAll(`#${mapId} > .ol-viewport`);
        if (alreadyMap.length > 0)
            return

        const nsdiBaseUrl = "https://geo.nsdi.gov.mn/geoserver/gwc/service/wmts/"
        const osSource = new OSM()

        const displayProjection = DATA_PROJECTION

        const dprojection = getProjection(displayProjection);
        const projectionExtent = dprojection.getExtent();
        const size = getWidth(projectionExtent) / 512;
        let resolutions = new Array(19);
        let matrixIds = new Array(19);
        for (let z = 0; z < 19; ++z) {
            // generate resolutions and matrixIds arrays for this WMTS
            resolutions[z] = size / Math.pow(2, z);
            matrixIds[z] = displayProjection + ':' + z;
        }

        const nsdiTileSource = new WMTS({
            url: nsdiBaseUrl,
            layer: "MasterMap",
            matrixSet: displayProjection,
            format: 'image/png',
            projection: dprojection,
            tileGrid: new WMTSTileGrid({
                origin: getTopLeft(projectionExtent),
                resolutions: resolutions,
                matrixIds: matrixIds,
                tileSize: 256,
            }),
            style: "",
            wrapX: false,
        })

        const tileLayer = new TileLayer(
            {
                source: nsdiTileSource,
                visible: true,
                title: "NSDI",
                opacity: .85,
            }
        )

        const googleMap = new Tile({
            source: new XYZ({
                url: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}'
            }),
            visible: false,
            title: 'Google'
        })

        const arcTopoMap = new Tile({
            source: new XYZ({
                url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}'
            }),
            visible: false,
            title: 'ArcGIS'
        })

        const OpenStreetMap = new Tile({
            source: new XYZ({
                url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
            }),
            visible: false,
            title: 'OpenStreetMap'
        })

        const baseLayerGroup = [
            googleMap, arcTopoMap, OpenStreetMap, tileLayer
        ]

        // tileLayer
        //     .getSource()
        //     .once(
        //         "tileloaderror",
        //         (err) =>
        //         {
        //             /** nsdi алдаа гарвал os ийн baselayer харуулах нь */
        //             tileLayer.setSource(osSource)
        //         }
        //     )

        const newMap = new Map({
            layers: baseLayerGroup,
            target: mapId,
            view: new View(
                {
                    maxZoom: 22,
                    projection: projection,
                    center: CENTER_OF_MAP,
                    zoom: firstZoom,
                    minZoom: 5,
                }
            ),
            controls: []
        });

        const cCMap = new CMap(newMap)
        cCMap.newIconFeature()

        setMap(
            {
                map: newMap,
                cMap: cCMap
            }
        )
    }

    // /** Анх ороод газрын зураг үүсгэх */
    useLayoutEffect(
        () =>
        {
            handleMap()
        },
        []
    )

    return map
}
