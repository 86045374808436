import ProjectAction from "pages/Company/Project/ProjectAction"

export default function ProjectMapAction({ removeOverlay, point, cadasterID })
{

    const handleDone = () =>
    {
        removeOverlay(true)
    }

    return (
        <ProjectAction id="create" handleDone={handleDone} point={point} cadasterID={cadasterID} />
    )
}
