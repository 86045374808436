import React, { createContext, useContext, useState } from 'react';

import useApi from 'hooks/useApi';

export const RoomContext = createContext()

/** Өрөөний төрөлтэй холбоотой функц болон state үүд байна */
export default function RoomContextProvider(props)
{
    /** Өрөөний төрлийг дуудах state */
    const [ roomTypes, setRoomTypes ] = useState([])

    const roomTypeApi = useApi({ isDisplayToast: false }).roomType

    /** Өрөөний төрлийн жагсаалтыг авах нь */
    const getRoomTypes = async () =>
    {
        const { success, data, error } = await roomTypeApi.get().catch(err => err)
        if (success)
        {
            setRoomTypes(data)
        }
    }

    return (
        <RoomContext.Provider
            value={{
                getRoomTypes,
                roomTypes
            }}
        >
            {props.children}
        </RoomContext.Provider>
    )
}

export const useRoom = () =>
{
    return useContext(RoomContext)
}
