import React, { useEffect, useState } from "react"
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

import DataNotFound from "components/main/DataNotFound"

import { quantityFormat } from "utils/format"

import css from '../style.module.css'
import { getRandomInt } from "utils"

const fakeData = [
	{ name: 'Орон сууц', count: getRandomInt(250, 100) },
	{ name: 'Үйлчилгээ', count: getRandomInt(100, 10) },
	{ name: 'Төрийн байгууллага', count: getRandomInt(2, 1) },
]

const OrgzColumnChart = ({ data }) => {

    const [ emptyData, setEmptyData ] = useState(false)
	useEffect(() => {
        // if(!data) return setEmptyData(true)
        // setEmptyData(false)
        // let pieData = data.filter(e => e.name !== 'Нийт дүн')

		let root = am5.Root.new("orgZChartDiv")

		root.setThemes([am5themes_Animated.new(root)])

			var chart = root.container.children.push(
				am5xy.XYChart.new(root, {
					panX: true,
					panY: true,
					wheelX: "panX",
					wheelY: "zoomX",
					pinchZoomX: true,
				}),
			)

			var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}))
			cursor.lineY.set("visible", false)

			var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 })
			// xRenderer.labels.template.setAll({
			// 	rotation: -50,
			// 	centerY: am5.p50,
			// 	centerX: am5.p100,
			// 	paddingRight: 15,
			// })

			var xAxis = chart.xAxes.push(
				am5xy.CategoryAxis.new(root, {
					maxDeviation: 0.3,
					categoryField: "name",
					renderer: xRenderer,
					tooltip: am5.Tooltip.new(root, {}),
				}),
			)

			var yAxis = chart.yAxes.push(
				am5xy.ValueAxis.new(root, {
					maxDeviation: 0.3,
					categoryField: "count",
					renderer: am5xy.AxisRendererY.new(root, {}),
				}),
			)

			xAxis.get("renderer").labels.template.setAll({
				oversizedBehavior: "wrap",
				maxWidth: 100,
				textAlign: "center",
			  });

			var series = chart.series.push(
				am5xy.ColumnSeries.new(root, {
					name: "Series 1",
					xAxis: xAxis,
					yAxis: yAxis,
					valueYField: "count",
					sequencedInterpolation: true,
					categoryXField: "name",
					tooltip: am5.Tooltip.new(root, {
						labelText: "{name}: {valueY}",
					}),
				}),
			)

			series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 })
			series.columns.template.adapters.add("fill", function (fill, target) {
				return chart.get("colors").getIndex(series.columns.indexOf(target))
			})

			series.columns.template.adapters.add("stroke", function (stroke, target) {
				return chart.get("colors").getIndex(series.columns.indexOf(target))
			})

			xAxis.data.setAll(fakeData)
			series.data.setAll(fakeData)

			chart.children.unshift(
                am5.Label.new(root, {
                    text: "Нийт барилга",
                    fontSize: 15,
                    fill: "#272B33",
                    x: am5.percent(50),
                    y: am5.percent(0),
                    centerX: am5.percent(50),
                    centerY: am5.percent(50),
                }),
            )

			series.bullets.push(function () {
				return am5.Bullet.new(root, {
					locationX: 0.5,
					locationY: 1,
					sprite: am5.Label.new(root, {
						centerX: am5.p50,
						text: "{valueY}",
						fill: am5.color(0xffffff),
						populateText: true,
					}),
				})
			})

			series.appear(1000)
			chart.appear(1000, 100)

		return () => {
			root.dispose()
		}
	}, [fakeData])

	return (
		<div
			className={css.customBottom}
			id="orgZChartDiv"
			style={{ width: "100%", height: "400px", position: "relative", border: '1px solid rgba(0,0,0,0.15)', borderRadius: '5px' }}>
			<div className="hidelogo"></div>
            {emptyData && <div><DataNotFound /></div>}
		</div>
	)
}

export default OrgzColumnChart
