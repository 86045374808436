import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { apartmentValidate } from 'utils/validate';
import { BUTETS_TYPE, ZEREGLEL_TYPE } from 'utils/consts';
import { dataToValueLabel } from 'utils/format'
import label from 'utils/validate/label';
import useAuth from 'hooks/useAuth';

const ApartmentAction = ({ proId=null, apartId=null, getGeneralPlan=null, isDetail=false }) => {

    const [ inputValues, setInputValues ] = useState('');
    const [ hasExtraFields, setExtraFields ] = useState(false)

    const [ zorilgotBuleg, setZorilgotBuleg ] = useState([]);
    const [ dohiololType, setDohiololType ] = useState([])
    const [ windowtypes, setWindowtypes ] = useState([])
    const [ doortypes, setDoortypes ] = useState([])
    const [ advantage, setAdvantage ] = useState([])
    const [ liftType, setLiftType ] = useState([])
    const [ useType, setUseType ] = useState([])
    const [ menu, setMenu ] = useState([])

    const navigate = useNavigate();

    const { userDetail } = useAuth()
    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const apartmentApi = useApi().apartment
    const zorilgotBulegApi = useApi().zorilgotBuleg
    const apartmentGISApi = useApi({ isDisplayToast: false }).apartment
    const advantageApi = useApi().advantage
    const windowtypeApi = useApi().windowtype
    const doortypeApi = useApi().doortype

    let params = useParams()
    let projectId = proId
    let isMap = true
    if (apartId === null || apartId === undefined)
    /** Газрын зургаас форм гаргаж байвал header харуулахгүй */
    if (apartId === null || apartId === undefined)
    {
        isMap = false
        apartId = params.apartId
    }

    if (projectId === null)
    {
        isMap = false
        projectId = params.id
    }

    /** create бол true, update бол false буцаана */
    const isCreate = apartId === 'create'

    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data, errors } = await fetchData(apartmentApi.getOne(projectId, apartId)).catch( err => err )
                if (success)
                {
                    setInputValues({...data.data[0], ...data.data[0].apartment_outside_info[0]})
                }
            }

            Promise.all([
				fetchData(windowtypeApi.get()),
				fetchData(doortypeApi.get()),
				fetchData(advantageApi.get()),
				fetchData(apartmentApi.getUseType()),
				fetchData(apartmentApi.getLiftType()),
				fetchData(apartmentApi.getMenu()),
				fetchData(apartmentApi.getDohiololType()),
				fetchData(zorilgotBulegApi.getAll()),
			]).then(resp => {
				setWindowtypes(dataToValueLabel(resp[0].data, "id", "window_type"))
				setDoortypes(dataToValueLabel(resp[1].data, "id", "door_type"))
				setAdvantage(dataToValueLabel(resp[2].data, "id", "name"))

                setUseType(dataToValueLabel(resp[3].data, "id", "name"))
                setLiftType(dataToValueLabel(resp[4].data, "id", "name"))
                setMenu(dataToValueLabel(resp[5].data, "id", "name"))
                setDohiololType(dataToValueLabel(resp[6].data, "id", "name"))
                setZorilgotBuleg(resp[7].data)
			})
        },
        []
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        const fData = new FormData()
        const config =
        {
            headers: { 'content-type': 'multipart/form-data' }
        }
        fData.append('advantage', `[${formData.advantage}]`)
        fData.append('butets_type', formData.butets_type)
        fData.append('name', formData.name)
        fData.append('service_floor', formData.service_floor)
        fData.append('total_ail', formData.total_ail)
        fData.append('project', projectId)
        fData.append('total_floor', formData.total_floor)
        fData.append('total_service_area', formData.total_service_area)
        fData.append('total_under_floor', formData.total_under_floor)
        fData.append('window_type', `[${formData.window_type}]`)
        fData.append('door_type', `[${formData.door_type}]`)

        fData.append('has_guardian', formData.has_guardian ? 'True': 'False')
        fData.append('has_garrage', formData.has_garrage ? 'True': 'False')
        fData.append('has_garden', formData.has_garden ? 'True': 'False')
        fData.append('has_wall', formData.has_wall ? 'True': 'False')
        fData.append('has_toy_square', formData.has_toy_square ? 'True': 'False')
        fData.append('has_extra_light', formData.has_extra_light ? 'True': 'False')
        fData.append('has_domofon', formData.has_domofon ? 'True': 'False')
        fData.append('has_camera', formData.has_camera ? 'True': 'False')
        fData.append('has_suh', formData.has_suh ? 'True': 'False')
        fData.append('is_shaard_hangasan', formData.is_shaard_hangasan ? 'True': 'False')

        fData.append('use_type', `[${formData.use_type}]`)
        fData.append('lift_ype', `[${formData.lift_ype}]`)
        fData.append('dohiolol', `[${formData.dohiolol}]`)
        fData.append('zorilgot_buleg', `[${formData.zorilgot_buleg}]`)
        fData.append('zereglel', formData.zereglel)
        // fData.append('parking_type', `[${formData.parking_type}]`)

        // for( let parkingType in formData ){
        //     if(parkingType.startsWith('parkingg_'))
        //     {
        //         fData.append(parkingType, parseInt(formData[parkingType]))
        //     }
        // }

        // Засаж байх үед шинэ нэрийг оноож өгөх нь
        if (!isCreate && getGeneralPlan)
        {
            const apartGeoData = getGeneralPlan(apartId, formData.name)
            fData.append("point", `[${apartGeoData.point}]`)
            fData.append("general_plan", apartGeoData.general_plan)
        }

        if (isCreate)
        {
            fData.append('real_photo', formData.real_photo[0])

            const { errors, success, data } = await fetchData(apartmentApi.post(projectId, fData, config)).catch(error => error)
            if (success)
            {
                const apartGeoData = getGeneralPlan(data.id, data.name)
                const body = {
                    point: apartGeoData.point,
                    general_plan: apartGeoData.general_plan
                }
                await fetchData(apartmentGISApi.put(projectId, data.id, body)).catch(err => err)
                return !isMap && navigate(-1)
            }
            else return errors
        }

        if(inputValues.real_photo !== formData.real_photo) fData.append('real_photo', formData.real_photo[0])

        const { errors, success, data } = await fetchData(apartmentApi.put(projectId, apartId, fData, config)).catch(error => error)
        if (success) !isMap && navigate(-1)
        else return errors
    }

    const displayExtraFields = useMemo(
        () =>
        {
            if(hasExtraFields || !isCreate)
            {
                return [
                    {
                        inputType: "radio",
                        type: "checkbox",
                        registerName: "has_guardian",
                        disabled: false,
                        readOnly: false,
                        options: [
                            { label: label['has_guardian'] },
                        ],
                        label: {
                            empty: true,
                            hide: true
                        },
                        colMd: 4,
                    },
                    {
                        inputType: "radio",
                        type: "checkbox",
                        registerName: "has_garrage",
                        disabled: false,
                        readOnly: false,
                        options: [
                            { label: label['has_garrage'] },
                        ],
                        label: {
                            empty: true,
                            hide: true
                        },
                        colMd: 4,
                    },
                    {
                        inputType: "radio",
                        type: "checkbox",
                        registerName: "has_garden",
                        disabled: false,
                        readOnly: false,
                        options: [
                            { label: label['has_garden'] },
                        ],
                        label: {
                            empty: true,
                            hide: true
                        },
                        colMd: 4,
                    },
                    {
                        inputType: "radio",
                        type: "checkbox",
                        registerName: "has_wall",
                        disabled: false,
                        readOnly: false,
                        options: [
                            { label: label['has_wall'] },
                        ],
                        label: {
                            empty: true,
                            hide: true
                        },
                        colMd: 4,
                    },
                    {
                        inputType: "radio",
                        type: "checkbox",
                        registerName: "has_toy_square",
                        disabled: false,
                        readOnly: false,
                        options: [
                            { label: label['has_toy_square'] },
                        ],
                        label: {
                            empty: true,
                            hide: true
                        },
                        colMd: 4,
                    },
                    {
                        inputType: "radio",
                        type: "checkbox",
                        registerName: "has_extra_light",
                        disabled: false,
                        readOnly: false,
                        options: [
                            { label: label['has_extra_light'] },
                        ],
                        label: {
                            empty: true,
                            hide: true
                        },
                        colMd: 4,
                    },
                    {
                        inputType: "radio",
                        type: "checkbox",
                        registerName: "has_domofon",
                        disabled: false,
                        readOnly: false,
                        options: [
                            { label: label['has_domofon'] },
                        ],
                        label: {
                            empty: true,
                            hide: true
                        },
                        colMd: 4,
                    },
                    {
                        inputType: "radio",
                        type: "checkbox",
                        registerName: "has_camera",
                        disabled: false,
                        readOnly: false,
                        options: [
                            { label: label['has_camera'] },
                        ],
                        label: {
                            empty: true,
                            hide: true
                        },
                        colMd: 4,
                    },
                    {
                        inputType: "radio",
                        type: "checkbox",
                        registerName: "has_suh",
                        disabled: false,
                        readOnly: false,
                        options: [
                            { label: label['has_suh'] },
                        ],
                        label: {
                            empty: true,
                            hide: true
                        },
                        colMd: 4,
                    },
                    {
                        inputType: "radio",
                        type: "checkbox",
                        registerName: "is_shaard_hangasan",
                        disabled: false,
                        readOnly: false,
                        options: [
                            { label: label['is_shaard_hangasan'] },
                        ],
                        label: {
                            empty: true,
                            hide: true
                        },
                        colMd: 4,
                        defaultValue: true
                    },
                ]
            }
            return []
        },
        [hasExtraFields]
    )

    /** Form утгууд */
    const form = {
        inputs: [
            {
                inputType: "input",
                registerName: "name",
                type: "text",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                label: {
                    text: "Apartment-ийн нэр: ",
                },
                placeholder: "Apartment-ийн нэр",
                disabled: isDetail || isLoading,
            },
            {
                inputType: "select",
                registerName: "advantage",
                isMulti: true,
                disabled: isDetail || isLoading,
                options: advantage,
            },
            {
                inputType: "select",
                registerName: "window_type",
                isMulti: true,
                disabled: isDetail || isLoading,
                options: windowtypes,
            },
            {
                inputType: "select",
                registerName: "door_type",
                isMulti: true,
                disabled: isDetail || isLoading,
                options: doortypes,
            },
            {
                inputType: "select",
                registerName: "use_type",
                disabled: isLoading,
                options: useType,
                isMulti: true,
                colMd: 6
            },
            {
                inputType: "select",
                registerName: "lift_ype",
                disabled: isLoading,
                options: liftType,
                isMulti: true,
                colMd: 6
            },
            {
                inputType: "select",
                registerName: "dohiolol",
                disabled: isLoading,
                isMulti: true,
                options: dohiololType,
                colMd: 6
            },
            {
                inputType: "select",
                registerName: "zereglel",
                disabled: isLoading,
                options: menu,
                colMd: 6
            },
            {
                inputType: "select",
                registerName: "butets_type",
                disabled: isDetail || isLoading,
                options: BUTETS_TYPE,
                colMd: 6
            },
            userDetail.user_role.is_tosk && {
                inputType: "select",
                registerName: "zorilgot_buleg",
                disabled: isLoading,
                options: zorilgotBuleg,
                isMulti: true,
            },
            {
                inputType: "imageUpload",
                registerName: "real_photo",
                colMd: 12
            },
            {
                inputType: "input",
                registerName: "service_floor",
                type: "number",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isDetail || isLoading,
                colMd: 4
            },
            {
                inputType: "input",
                registerName: "total_floor",
                type: "number",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isDetail || isLoading,
                colMd: 4
            },
            {
                inputType: "input",
                registerName: "total_ail",
                type: "number",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isDetail || isLoading,
                colMd: 4
            },
            {
                inputType: "input",
                registerName: "total_service_area",
                type: "number",
                step: 0.01,
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isDetail || isLoading,
                colMd: 4
            },
            {
                inputType: "input",
                registerName: "total_under_floor",
                type: "number",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isDetail || isLoading,
                colMd: 4
            },
            {
                component: <hr/>
            },
            isCreate
            ?
                {
                    inputType: "radio",
                    type: "checkbox",
                    registerName: "GG",
                    disabled: isLoading,
                    readOnly: false,
                    options: [
                        { label: "Нэмэлт мэдээлэл бөглөх" },
                    ],
                    label: {
                        hide: true,
                        empty: true,
                    },
                    colMd: 12,
                    onChange: event => setExtraFields(event.target.checked)
                }
            :
                {
                    component: <div className='mb-3'>Нэмэлт мэдээлэл</div>
                },
            ...displayExtraFields
        ],
        button: {
            onSubmit: async (data) => {
                return btnOnSubmit(data);
            },
            disabled: isDetail || isLoading,
            isLoading: isLoading,
            title: isCreate ? "Үүсгэх" : "Хадгалах",
        },
        validate: {
            validateMode: "all",
            yupValidate: apartmentValidate,
        },
    };

    return (
        <CForm inputValues={inputValues} form={form}/>
    );
}

export default ApartmentAction;
