import { useEffect, useState } from "react"

import CButton from 'components/main/Button'
import BoxBackground from "components/special/BoxBackground"

import useApi from "hooks/useApi"
import useLoader from "hooks/useLoader"

import { fakeAgendaResult } from "./fakeData"
import ProgramChart from "./ProgramChart"
import AgendaChart from "./AgendaChart"
import SubAgendaChart from "./SubAgendaChart"
import useConfig from "hooks/useConfig"
import { timeZoneToDateString } from "utils/format"

const initChoosedProgramId = 4
// const initChoosedProgramId = 0

const ProgramResult = () =>
{
    const [ choosedProgram, setChoosedProgram ] = useState()
	const [ choosedAgenda, setChoosedAgenda ] = useState()
	const [ resultDate, setResultDate ] = useState('')

	const { getConfig } = useConfig()

	const resultApi = useApi().programResult
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: true, absolute: true })

    const [ chartData, setChartData ] = useState([])

	const getData = async () =>
	{
		const { success, data } = await fetchData(resultApi.getAllResult()).catch( err => err )
		if(success)
		{
			// setChartData(fakeAgendaResult)
			// setChoosedProgram(fakeAgendaResult[initChoosedProgramId])
			setChartData(data)
			setChoosedProgram(data[initChoosedProgramId])
		}
	}

	const updateDate = async () =>
	{
		const rsp = await getConfig('HUTULBUR_RESULT')
		if (Object.keys(rsp).length)
		{
			setResultDate(timeZoneToDateString(rsp['HUTULBUR_RESULT']))
		}
	}

	useEffect(
        async () =>
        {
            getData()
			updateDate()
        },
        []
    )

	/** result ийн утгуудийг шинэчлэх */
	const handleRefresh = () =>
	{
		fetchData(resultApi.refresh())
		.then(
			({ success, data }) =>
			{
				if (success)
				{
					getData()
					setResultDate(timeZoneToDateString(data))
				}
			}
		)
	}

    return (
		<>
			{
				isLoading
				&&
					Loader
			}
			<BoxBackground
				title="Хөтөлбөр үр дүн"
				body={
					<>
						<div className="d-flex justify-content-start">
							<CButton
								onClick={handleRefresh}
							>
								Шинэчлэх <i className="fal fa-redo"></i>
							</CButton>
						</div>
						<div>
							<small className="text-secondary">
								Сүүлд шинэчилсэн огноо {resultDate}
							</small>
						</div>
						<ProgramChart setChoosedProgram={setChoosedProgram} chartData={chartData} />
					</>
				}
			/>
			<BoxBackground
				className="mt-2"
				title=""
				body={
					<>
						<AgendaChart
							setChoosedProgram={setChoosedProgram}
							chartData={chartData}
							getAgendaData={setChoosedAgenda}
							choosedProgram={choosedProgram}
						/>
					</>
				}
			/>
			<BoxBackground
				className="mt-2"
				title=""
				body={<SubAgendaChart choosedProgram={choosedProgram} setChoosedAgenda={setChoosedAgenda} choosedAgenda={choosedAgenda} />}
			/>
		</>
	)
}

export default ProgramResult
