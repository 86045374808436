import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import CButton from 'components/main/Button';

import BuildingImageCreate from './BuildingImageCreate';
import BuildingImageUpdate from './BuildingImageUpdate';

const BuildingImageAction = ({ projectId, buildingId }) => {

    const [ isCreate, setIsCreate ] = useState(false)

    return (
		<div>
			<Row>
				<Col>
					{
                        !isCreate
                        &&
                        <CButton onClick={() => setIsCreate(true)} className="floatRight">
                            Үүсгэх
                        </CButton>
					}
				</Col>
			</Row>
			{
                !isCreate
                ?
                    <BuildingImageUpdate projectId={projectId} buildingId={buildingId} />
                :
                    <BuildingImageCreate setIsCreate={setIsCreate} projectId={projectId} buildingId={buildingId} />
			}
		</div>
	)
}

export default BuildingImageAction;
