import BackButton from 'components/main/BackButton'
import CForm from 'components/main/Form'
import useApi from 'hooks/useApi'
import useLoader from 'hooks/useLoader'
import React, { useEffect, useState } from 'react'
import { UL_HODLOH_TURUL } from 'utils/consts'
import { dataToValueLabel } from 'utils/format'
import { borluulaltValidate } from 'utils/validate'

const BorluulaltCreate = ({ setCreate, projectId, apartId }) =>
{

    const [ sales, setSales ] = useState([])

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })

    const borluulaltApi = useApi().apartment.borluulalt
    const saleApi = useApi().allSales

    const btnOnSubmit = async (formData) =>
    {
        const body = {
            ...formData,
            apartment: apartId
        }
        const { success, data, errors } = await fetchData(borluulaltApi.post(projectId, apartId, body)).catch(err => err)
        if(success)
        {
            setCreate(false)
        }
    }

    useEffect(
        async () =>
        {
            const { success, data } = await fetchData(saleApi.get()).catch(err => err)
            if(success)
            {
                setSales(dataToValueLabel(data, "id", "manager"))
            }
        },
        []
    )

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "price_mkv",
                label: {
                    text: "м² ийн үнэ"
                },
                placeholder: "м² ийн үнэ оруулна уу",
                type: "text",
                disabled: isLoading,
            },
            {
                inputType: "input",
                registerName: "total_price",
                type: "text",
                disabled: isLoading,
            },
            {
                inputType: "select",
                registerName: "property_type",
                disabled: isLoading,
                options: UL_HODLOH_TURUL
            },
            {
                inputType: "select",
                registerName: "sales",
                disabled: isLoading,
                options: sales
            }
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: 'Үүсгэх',
        },
        validate: {
            validateMode: "all",
            yupValidate: borluulaltValidate,
        },
    }

    return (
        <div>
            <BackButton onClick={() => setCreate(false)} />
            <CForm form={form} />
        </div>
    )
}

export default BorluulaltCreate
