
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import LicenseType from '.';

import LicenseTypeAction from './LicenseTypeAction';

import BoxBackground from 'components/special/BoxBackground';

const LicenseTypeCrudMain = () => {
    return (
        <Routes>
            <Route path="/" element={<BoxBackground title='Тусгай зөвшөөрлийн төрөл' body={<LicenseType/>} />} />
            <Route path="/:id/" element={<LicenseTypeAction/>} />
        </Routes>
    );
}

export default LicenseTypeCrudMain;
