import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import useLoader from "hooks/useLoader";
import useAuth from "hooks/useAuth";
import useConfig from "hooks/useConfig";

import RoomContextProvider from "context/special/roomContext"

import Toast from "components/main/Toast";
import Modal from "components/main/Modal";
import IFrame from 'components/main/IFrame'

import RoleRequired from 'components/main/RoleRequired'
import ShowComponent from 'components/special/ShowComponent'
import Header from 'components/special/Header'

import Company from "./Company";
import Admin from "./Admin";
import Test from "./Test";
import Sign from "./Sign";
import Home from "./Home";
import User from './User'
import Gov from './Gov'
import Building from './Building'
import OAuth2 from './OAuth2'

import { NotFound } from './Error'
import Abouts from './Helper/Abouts'
import Legal from './Helper/Legal'
import Rules from './Helper/Rules'

import Lottery from "./ChooseSongonOrHutulbur/Lottery/routes";
import NewsRoute from "./Helper/News/routes";
import { materialPageRoles, companyPageRoles, adminPageRoles, govPageRoles, userPageRoles, financialAdminPageRoles, finanialEmployeePageRoles, adminForNews, bhTuvPageRoles } from 'utils/consts/roles'

export default function Pages()
{

    const { Loader, fetchData, isLoading } = useLoader({ isFullScreen: true, initValue: true })
    const { getRoomChoices } = useConfig()
    const { getDetail } = useAuth()

    /** Сайтад хэрэгтэй мэдээллүүдийг татаж авах нь */
    const getSiteDatas = async () =>
    {
         await Promise.all(
            [
                getDetail(),
                getRoomChoices()
            ]
        )
    }

    useEffect(
        () =>
        {
            fetchData(getSiteDatas())
        },
        []
    )

    if (isLoading)
    {
        return Loader
    }

    return (
        <>
            <Toast />
            <Modal />
            <IFrame />
            <ShowComponent noComponentPaths={["admin", "test", "company", "gov", "user"]} setHeaderSize={['building']}>
                <Header />
            </ShowComponent>
            <Routes>
                <Route
                    path="/"
                    element={
                        <RoomContextProvider>
                            <Home />
                        </RoomContextProvider>
                    }
                />
                <Route path="/abouts/" element={<Abouts />} />
                <Route path="/Legal/*" element={<Legal />} />
                <Route path="/rules/*" element={<Rules />} />
                <Route path="/test/*" element={<Test />} />
                <Route
                    path="/company/*"
                    element={
                        <RoleRequired
                            needRoles={[ ...companyPageRoles, ...finanialEmployeePageRoles, ...materialPageRoles, ...bhTuvPageRoles ]}
                        >
                            <Company />
                        </RoleRequired>
                    }
                />
                <Route
                    path="/admin/*"
                    element={
                        <RoleRequired needRoles={[ ...adminPageRoles, ...adminForNews ]}>
                            <Admin />
                        </RoleRequired>
                    }
                />
                <Route path="/news/*" element={<NewsRoute />} />
                <Route path="/sign/*" element={<Sign />} />
                <Route
                    path="/gov/*"
                    element={
                        <RoleRequired needRoles={[ ...govPageRoles, ...financialAdminPageRoles ]}>
                            <Gov />
                        </RoleRequired>
                    }
                />
                <Route
                    path="/user/*"
                    element={
                        <RoleRequired needRoles={userPageRoles}>
                            <User />
                        </RoleRequired>
                    }
                />
                <Route
                    path="/building/*"
                    element={
                        <Building />
                    }
                />
                <Route
                    path='/songon/*'
                    element={
                        <Lottery />
                    }
                />
                <Route
                    path='/Dan/DanRedirect'
                    element={
                        <OAuth2 />
                    }
                />

                <Route path="*" element={<NotFound />} />
            </Routes>
            {/* <ShowComponent noComponentPaths={["admin", "company", "test", "gov", "user"]}>
                <MiniFooter />
            </ShowComponent> */}
        </>
    );
}
