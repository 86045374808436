
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Sales from '.';

import SalesAction from './SalesAction';

import BoxBackground from 'components/special/BoxBackground';

const SalesCrudMain = () => {
    return (
        <Routes>
            <Route path="/" element={<BoxBackground title='Маркетингийн алба' body={<Sales/>} />} />
            <Route path="/:id/" element={<SalesAction/>} />
        </Routes>
    );
}

export default SalesCrudMain;
