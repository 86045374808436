import React, { useEffect, useState } from 'react';

const TableCheckbox = ({ value, disabled, valueName, onChange }) => {
	const [toggler, setToggler] = useState(value)

    const handleChange = async () =>
    {
        if(disabled) return
        setToggler(!toggler)
        onChange({ [valueName]: !toggler })
    }

    useEffect(
        () =>
        {
            setToggler(value)
        },
        [disabled, value]
    )

	return (
		<div className='cursorPointer' onClick={() => handleChange()}>
			{toggler ? (
				<i className={`fas fa-check-circle text-success ${disabled ? "textGray" : ""}`} />
			) : (
				<i className={`far fa-circle text-darkBlue  ${disabled ? "textGray" : ""}`} />
			)}
		</div>
	)
}

export default TableCheckbox;
