export const MAIN_DARK_COLOR = '#111318'
export const WHITE = '#fff'
export const MAIN_BLUE = '#005b9a'

export const CHART_LINE_COLOR = '#212529'

export const MAIN_DOGEBLUE_COLOR = '#0ec6d5'
export const MAIN_DARKBLUE_COLOR = '#0191c8'

export const NOTIFICATION_COLORS = {
    success: '#00b050',
    pending: '#ffc000',
    danger: '#FF3E3E',
    normal: '#00afb9',
}
