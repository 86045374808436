import React from 'react';
import { Route, Routes } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';

import EmployeeCreate from './EmployeeCreate';
import Employee from '.';

const EmployeeMain = () => {
    return (
        <Routes>
            <Route path="/" element={<BoxBackground title='Ажилчид' body={<Employee/>} />} />
            <Route path="/:id" element={<EmployeeCreate/>} />
        </Routes>
    );
}

export default EmployeeMain;
