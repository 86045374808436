import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import css from './style.module.css'

const StepWay = ({ activeStep=0, steps=[] }) => {

    const displaySteps = useMemo(
        () =>
        {
            return steps.map(
                (element, index) =>
                {
                    return (
                        <div key={index} className={css.step}>
                            <div className={`${css.icon} ${activeStep === index ? css.activeIcon : '' } ${activeStep > index ? css.activedIcon : '' }`}>
                                <i className={ activeStep > index ? 'fas fa-check' : element.icon}/> <br/>
                            </div>
                            <div>
                                {element.name}
                            </div>
                        </div>
					)
                }
            )
        },
        [steps]
    )

    return (
        <>
            <div className={css.row}>
                {displaySteps}
                <hr className={css.middleLine}/>
            </div>
            <div className={css.content}>
                {steps[activeStep].component}
            </div>
        </>
    );
}

export default StepWay;
