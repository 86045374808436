import { useEffect } from "react";

const ModalOverLay = ({ map, cMap, setShouldClosePopup, shouldClosePopup, margin, onClose }) =>
{
    useEffect(
        () =>
        {
            if(shouldClosePopup)
            {
                handleClose()
            }
        },
        [shouldClosePopup]
    )

    useEffect(
        () =>
        {
            if (map)
            {
                cMap.addOverlay({ margin })
            }
        },
        [map]
    );

    /** overlay ийг хаах нь */
    const handleClose = () =>
    {
        if (onClose)
            onClose()
        cMap.displayOverlay(undefined)
        cMap.clearSelect()
        setShouldClosePopup(false)
    }

    return (
        <div id="popupMap">
        </div>
    )
}

export default ModalOverLay
