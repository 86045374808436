import useMapContext from 'hooks/useMapContext'
import Menu from './Menu'

export default function Units({ fns })
{

    const { allFilters } = useMapContext()

    const clearFilter = () =>
    {
        delete allFilters['unit1-code']
        delete allFilters['unit2-code']
        delete allFilters['unit3-code']
    }

    const handleClick = (event) =>
    {
        const [whatUnit, id, code, label] = event.value.split("||")
        allFilters[`unit${whatUnit}-code`] = code
        if (!code)
        {
            delete allFilters[`unit${whatUnit}-code`]
        }
    }

    return (
        <div className='mb-3'>
            <Menu
                onClick={handleClick}
                fns={fns}
                clearFilter={clearFilter}
            />
        </div>
    )
}
