import React, { useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

/**
 * @param {boolean} disabled input disabled эсэх
 * @param {boolean} readOnly input readonly байх эсэх
 *
 * @param {function} onChange input утга солигдоход ажиллах fucnt
 *
 * @param {Array} errors yup ийн validate алдаа
 * @param {object} register useform -д бүртгүүлэх
 *
 * @param {Array} options radio ний сонголтууд
 *
 * @returns
 */
const RadioCheck = ({ watch, errors, disabled, onChange=null, readOnly, register, options=[], setValue, type="checkbox", defaultValue=null, className, inputClassName }) =>
{
    let selectedOption = watch ? watch(register.name) : undefined

    useEffect(
        () =>
        {
            if (![null, undefined, ""].includes(selectedOption))
            {
                return
            }
            // NOTE: анхны зүгээр default утгыг нь оноох
            /** DEFAULT УТГА байгаа эсэхийг нь шалгаж setvalue хийх нь */
            options.map(
                (option, idx) =>
                {
                    if ("defaultChecked" in option)
                    {
                        if (option.defaultChecked === true)
                        {
                            const value = type === "radio"
                                        ? option.value
                                        : option.defaultChecked
                            setValue(
                                register?.name,
                                value
                            )
                        }
                    }
                }
            )
        },
        [options, setValue]
    )

    const handleChange = (event) =>
    {
        if (setValue)
        {
            setValue(
                register?.name,
                type === "radio"
                ?
                    event.target.value
                :
                    event.target.checked
            )
        }
        onChange
            && onChange(event)
    }

    return (
        <Form.Group>
            <InputGroup className={inputClassName}>
                {
                    options.map(
                        (option, idx) =>
                        {
                            return (
                                <Form.Check
                                    readOnly={readOnly}
                                    {...register}
                                    key={idx}
                                    inline
                                    className={className}
                                    disabled={disabled}
                                    isInvalid={!!errors?.[register?.name]?.message}
                                    label={option?.label}
                                    type={type}
                                    {
                                        ...![null, undefined, ""].includes(selectedOption)
                                        ? { checked: (option.value ?? true) + "" === selectedOption + "" }
                                        // ? { checked: (type === "checkbox" ? option.label : (option.value ?? true)) + "" === selectedOption + ""}
                                        : {}
                                    }
                                    value={option.value ?? true}
                                    onChange={handleChange}
                                    defaultChecked={defaultValue && defaultValue}
                                    id={`radio-${register?.name}-${option.label}`}
                                />
                            )
                        }
                    )
                }
            </InputGroup>
        </Form.Group>
    );
};

export default RadioCheck;
