import BoxBackground from 'components/special/BoxBackground';
import { Route, Routes } from 'react-router-dom';
import React from 'react';

import Gov from '.';

import GovAction from './GovAction';

const GovCrudMain = () => {
    return (
        <Routes>
            <Route path="/" element={<BoxBackground title='Төрийн байгууллагууд' body={<Gov/>} />} />
            <Route path="/:id/" element={<GovAction/>} />
        </Routes>
    );
}

export default GovCrudMain;
