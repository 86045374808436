import React, { useEffect, useState } from 'react'

import Select from 'components/main/Form/InputTypes/Select'
import CustomInputGroup from 'components/main/Form/CustomInputGroup'

export default function SelectSearch({
    options,
    label,
    register,
    setValue,
    watch,
    onChange
})
{
    const queryKey = register?.name || ""

    let value = ""
    value = watch(queryKey)

    useEffect(
        () =>
        {
            onChange && onChange(value)
        },
        [value]
    )

    return (
        <CustomInputGroup label={{ text: label, empty: true }} md={12}>
            <Select
                options={options || []}
                isSearchable={true}
                setValue={setValue}
                value={value}
                register={register}
            >
            </Select>
        </CustomInputGroup>
    )
}
