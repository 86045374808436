import UpdateButton from 'components/main/CudButtons/Update'
import ShowBtn from 'components/main/CudButtons/Show'

import { finanialEmployeePageRoles, financialAdminPageRoles } from 'utils/consts/roles'
import { moneyFormat } from 'utils/format'
import label from 'utils/validate/label'

import css from '../../style.module.css'

const OneBuildingInfo = ({ data={}, goUpdate }) =>
{
    return (
		<div className={css.itemWrapper}>
			<div className="me-2 d-flex justify-content-between align-items-center">
				<div>
					<span style={{ fontWeight: "500" }}>{label["dugaar"]}:</span> {data.geree_info.dugaar}
				</div>
				<div className='d-flex'>
					<div className="me-3">
						<ShowBtn onClick={() => goUpdate(data.id, "detail")} needRoles={[ ...finanialEmployeePageRoles, ...financialAdminPageRoles ]} />
					</div>
					<UpdateButton onClick={() => goUpdate(data.id)} needRoles={finanialEmployeePageRoles} position="top" />
				</div>
			</div>
			<div>
				<span style={{ fontWeight: "500" }}>{label["geree_gerchilgee_dugaar"]}:</span> {data.geree_info.geree_gerchilgee_dugaar}
			</div>
			<div>
				<span style={{ fontWeight: "500" }}>{label["full_name"]}:</span> {data.building_info.full_name}
			</div>
			<hr style={{ margin: "5px 0" }} />
			<div className={css.iconscontainer}>
				<div className={css.iconItem}>
					<i className="fa-solid fa-arrows-left-right-to-line"></i>
					<div>
						<div>{data.mkv}</div>
						<div>{label["mkv"]}</div>
					</div>
				</div>
				<div className={css.iconItem}>
					<i className="fa-solid fa-money-bill-wave"></i>
					<div>
						<div>{moneyFormat(data.price)}</div>
						<div>{label["price"]}</div>
					</div>
				</div>
				<div className={css.iconItem}>
					<i className="fa-solid fa-diagram-project"></i>
					<div>
						<div>{data.uil_ajillagaanii_turul_display}</div>
						<div>{label["uil_ajillagaanii_turul"]} </div>
					</div>
				</div>
				<div className={css.iconItem}>
					<i className="fas fa-car-building"></i>
					<div>
						<div>{data.ul_hudluh_hurungiin_turul_display}</div>
						<div>{label["ul_hudluh_hurungiin_turul"]}</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default OneBuildingInfo
