import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import Datatable from 'components/main/Datatable';
import CreateButton from 'components/main/CudButtons/Create';

import useApi from 'hooks/useApi';
import useAuth from 'hooks/useAuth';

import { PERM_IS_FROM_BUILDING, PERM_IS_FROM_PROJECT, PERM_IS_ORG_ADMIN } from 'utils/consts';

const Employee = () =>
{
    const { companyId } = useAuth()
    const employeeApi = useApi().employee

    const navigate = useNavigate()

    const columns =
    [
        {
            field: "user",
            headerName: "И-мэйл",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue.email}</span>,
            searchField: 'user__email'
        },
        {
            field: "user",
            headerName: "Овог",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue.last_name}</span>,
            searchField: 'user__last_name'
        },
        {
            field: "user",
            headerName: "Нэр",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue.first_name}</span>,
            searchField: 'user__first_name'
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    return (
        <Row>
            <Col sm={12}>
                <CreateButton btnClassName='floatRight' onClick={() => navigate('create/')} needRoles={[PERM_IS_ORG_ADMIN, PERM_IS_FROM_BUILDING, PERM_IS_FROM_PROJECT]} />
            </Col>
            <Col>
                <Datatable
                    columns={columns}
                    sequenceColumn={sequenceColumn}
                    request={
                        {
                            axios: employeeApi.get,
                            params: [companyId]
                        }
                    }
                />
            </Col>
        </Row>
    );
}

export default Employee;
