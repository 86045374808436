import React, { Component, useEffect, useState } from 'react';
import DatePicker from "react-datepicker";

import useMobile from 'hooks/useMobile';

import { MAIN_DARKBLUE_COLOR } from 'utils/consts/colors';
import { timeZoneToDateString } from 'utils/format';
import { range } from 'utils/array';

import "react-datepicker/dist/react-datepicker.css";
import './style.css'

/**
 * Дизайн засах бол style.css-ээр янзална уу...
 * @param {Date} selected Datepicker анхны утга сонгогдсон болгоно
 *
 * @param {Date} minDate Сонголт хийх боломжтой хугацаа хамгийн багадаа
 * @param {Date} maxDate Сонголт хийх боломжтой хугацаа хамгийн ихдээ
 *
 * @param {Date Interval} includeDateIntervals
 *      {[
            { start: subDays(new Date(), 5), end: addDays(new Date(), 5) },
        ]} ийн утга дамжуулна. Сонголт хийх боломжтой interval юм

 * @param {Boolean} withPortal Datepicker modal шиг гарж ирэх эсэх?
 * @param {Boolean} showDisabledMonthNavigation maxDate, minDate байсан тохиолдолд popper ийн сар шилжих товчийг харуулахгүй
 * @param {Boolean} closeOnScroll Datepicker-ийн хугацаа сонгох popper гаргачаад scroll хийхэд popper ийг хаах
 * @param {Boolean} selectsRange Datepicker нь DatePickerRange эсэх?
 * @param {Boolean} shouldCloseOnSelect Popper оос утга сонгогдсон бол popper ийг хаах
 * @param {Boolean} todayButton Өнөөдөрийг сонгох товч гаргах эсэх
 *
 * @param {Component} Input Datepicker ийн input
 * @param {Component} footer Хугацаа сонгох popper-ийн доор нь гарна
 *
 * @param {css.ClassName} calendarClassName Өдөр сонгох хэсгийн class
 * @param {css.ClassName} popperClassName Хугацаа сонгох popper ийн css
 *
 * @param {Function} setValue useform-ийн register -д сонгогдсон утга өгөх
 *
 * @param {'top-end, top-start, bottom-end, bottom-start'} popperPlacement Хугацаа сонгох popper ийн байрлал
 * @returns
 */
const CYearpicker = ({
    footer,
    closeOnScroll=false,
    popperPlacement,
    selectsRange=false,
    calendarClassName,
    popperClassName,
    todayButton=false,
    withPortal=false,
    minDate=null,
    maxDate=null,
    showDisabledMonthNavigation=false,
    includeDateIntervals=null,
    shouldCloseOnSelect=true,
    clearButtonClassName,
    firstYearDropDown=2000,
    selected=null,
    setValue,
    register,
    readOnly,
    disabled,
    errors,
    setIsOpen,
    onChange=null,
    Input,
    label,
    showTimeSelect=false,
}) =>
{
    /** Ганц хугацаа сонгох бол энэхүү state д хадгална */
    const [date, setDate] = useState(selected);

    //Анхны утга өгөх
    useEffect(
        () =>
        {
            if(Array.isArray(selected))
            {
                setDate([
                    new Date(selected[0]),
                    new Date(selected[1]),
                ])
                return
            }
            if(selected)
            {
                return setDate(new Date(selected))
            }
        },
        [selected]
    )

    const { isMobile } = useMobile()

    /** Сар харуулах нь Header  хэсэгт */
    const months = [
        "1-р сар",
        "2-р сар",
        "3-р сар",
        "4-р сар",
        "5-р сар",
        "6-р сар",
        "7-р сар",
        "8-р сар",
        "9-р сар",
        "10-р сар",
        "11-р сар",
        "12-р сар",
    ];

    /** Хэдээс хэдэн он харуулахаа өгнө */
    const years = range(firstYearDropDown, (new Date()).getFullYear());

    /** он сонгох Dropdown ы утгууд */
    // TODO: он сонгодог dropdown хийх!!!
    const options = () =>
    {
        return years.map((option, index) =>
            {
                return {
                    value: years[years.length - index - 1],
                    name: years[years.length - index - 1]
                }
            }
        )
    }

    /** Өөрийн Header ийг харуулах нь  */
    const customHeader = ({
        date,
        changeYear,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => (
        <div
            style={{
                margin: 10,
                display: "flex",
                justifyContent: "space-between",
                alignItems: 'center'
            }}
        >
            <button type="button" className='clearlyButton' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <i className="fa-solid fa-circle-chevron-left fs-15" style={{ color: MAIN_DARKBLUE_COLOR }}></i>
                {/* <i className="fa-solid fa-chevron-left"></i> */}
                {/* <i className="fa-solid fa-circle-chevron-left"></i> */}
            </button>
            <label style={{ fontWeight: '400' }}>
                {date.getFullYear()}
                оны {months[date.getMonth()]}
            </label>
            <button type={"button"} className='clearlyButton' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <i className="fa-solid fa-circle-chevron-right fs-15" style={{ color: MAIN_DARKBLUE_COLOR }}></i>
            </button>
        </div>
    )

    /** Гадаад нэрийг монгол руу шилжүүлэх нь */
    const showDayName = (nameOfDay) =>
    {
        let obj = {
            Sunday: 'Ням',
            Monday: 'Дав',
            Tuesday: 'Мяг',
            Wednesday: 'Лха',
            Thursday: 'Пүр',
            Friday: 'Баа',
            Saturday: 'Бям',
        };
        return obj?.[nameOfDay];
    }

    const handleCalendarClose = () => setIsOpen(false)
    const handleCalendarOpen = () => setIsOpen(true)

    return (
        <DatePicker
            formatWeekDay={nameOfDay => showDayName(nameOfDay)}
            todayButton={todayButton}
            selected={selectsRange ? null : date}
            calendarClassName={calendarClassName}
            popperClassName={popperClassName}
            popperPlacement={popperPlacement}
            closeOnScroll={closeOnScroll}
            selectsRange={selectsRange}
            // shouldCloseOnSelect={shouldCloseOnSelect}
            readOnly={readOnly}
            onChange={(update) =>
                {
                    !showTimeSelect && setValue && setValue(register?.name, update !== null ? timeZoneToDateString(update, false) : undefined, { shouldValidate: true })
                    showTimeSelect && setValue && setValue(register?.name, update !== null ? timeZoneToDateString(update, true) : undefined, { shouldValidate: true })
                    setDate(update)
                    onChange && onChange(update)
                }
            }
            isClearable={true}
            customInput={Input ? <Input/> : null}
            withPortal={withPortal ? withPortal : isMobile}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            showTimeSelect={showTimeSelect}
            dateFormat={`yyyy он`}
            includeDateIntervals={includeDateIntervals}
            showDisabledMonthNavigation={showDisabledMonthNavigation}
            onCalendarClose={handleCalendarClose}
            onCalendarOpen={handleCalendarOpen}
            showYearPicker={true}
        >
            {footer}
        </DatePicker>
    );
}

export default CYearpicker;
