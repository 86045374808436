import React from 'react';
import { useNavigate, Link } from 'react-router-dom'
import { Container } from 'react-bootstrap';

import { HOME_PAGE } from 'utils/consts'

import css from '../style.module.css'

const TopHeader = () => {

    const navigate = useNavigate()

    return (
        <Container fluid className={`${css.topHeader} d-flex align-items-center`}>
            <Container className="d-flex align-items-center justify-content-between">
                <Link to={HOME_PAGE}>
                    <img style={{ width: "250px", cursor: "pointer" }} src={process.env.PUBLIC_URL + "/images/logo021.png"} alt={"headerLogo"} />
                </Link>
                <div style={{ color: '#fff'}}>
                    <span className="me-3"><i className="fal fa-phone-alt"></i> +976 7600-3333</span>
                    <span><i className="fal fa-envelope"></i> oronsuuts.info@gmail.com</span>
                </div>
            </Container>
        </Container>
    );
}

export default TopHeader;
