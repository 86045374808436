
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * Тухайн children ийг хуудасны замаас хамаарч харуулах эсэхийг шийдэх
 * @param {Component}   children
 * @param {Array}       noComponentPaths ямар зам дээр харуулахгүй байх
 * @param {Array}     setHeaderSize        header байх юм бол дээрээс зай авах хуудсууд
 */
export default function Index({ children, noComponentPaths=[], setHeaderSize=[] })
{
    const location = useLocation()

    //  одоо байгаа page нь header харагдахгүй жагсаалтанд байгаа эсэхийг олох нь
    const noHeader = noComponentPaths.find(
        (el) => {
            return location.pathname.indexOf(el) !== -1
        }
    )

    const isBodyOfHeader = setHeaderSize.find(
        (el) =>
        {
            return location.pathname.indexOf(el) === 1
        }
    )

    useEffect(
        () =>
        {
            function setHeightToBody()
            {
                /** header ийн доор байрлах body хэсэг */
                const bodyOfHeaderElement = document.getElementById("body-of-header")
                /** header ийн element */
                const header = document.getElementsByTagName("header")?.[0]
                /** хэрвээ header ийн element байх юм бол өндрийг авч body дээр margin авна */
                if (header)
                {
                    /** header ийн өндөр */
                    const marginTop = header.offsetHeight

                    /** body дээр margin top авсан нь */
                    bodyOfHeaderElement.style.marginTop = `${marginTop}px`
                }
            }

            /** хэрвээ header байвал header ийн өндрийг авч body дээр margin авна */
            if (isBodyOfHeader)
            {
                setHeightToBody()
                window.addEventListener("resize", setHeightToBody)
            }
            return () =>
            {
                window.removeEventListener("resize", setHeightToBody)
            }
        },
        [setHeaderSize]
    )

    if (noHeader)
    {
        return <div className='d-none'>
            {children}
        </div>
    }

    if (isBodyOfHeader)
    {
        return (
            <div id={"body-of-header"}>
                {children}
            </div>
        )
    }

    return children
}
