import React, { useState } from 'react';

/**
 * @param {boolean} initValue Toggler -ийн анхны утга
 * @param {icon} togglerOn Toggler ийн асаалттай байхад гарах icon
 * @param {icon} togglerOff Toggler ийн унтраалттай байхад гарах icon
 * @param {boolean} displayToggler Toggler ийг дэлгэцэнд харуулах эсэх
 * @return Toggler-{компонент}, togglerState- тогглерийн {state}, setTogglerState- тогглерийн төлөв шинэчлэх {setState} буцаана
 */
export default function useToggler({
    initValue=false,
    togglerOn=<i className="fa-solid fa-hexagon-plus"/>,
    togglerOff=<i className="fa-solid fa-hexagon-minus"/>,
    displayToggler=true
})
{
    const [ togglerState, setTogglerState ] = useState(initValue)

    const toggle = () =>
    {
        setTogglerState(!togglerState)
    }

    return {
        Toggler: displayToggler && (
            <span onClick={() => toggle()} style={{ cursor: "pointer", marginLeft: '5px' }}>
                {togglerState ? togglerOn : togglerOff}
            </span>
        ),
        togglerState,
        setTogglerState,
    };
}
