import React from 'react';

import Layout from 'components/special/Layout';

import routes from './routes';

const UserPage = () => {
    return (
        <Layout sidebarTitle='ҮНДСЭН ЦЭС' links={routes}/>
    );
}

export default UserPage;
