import useMapContext from "hooks/useMapContext"
import { useEffect, useRef } from "react"
import { useMemo, useState } from "react"

import css from './style.module.css'
import { FILTER_PERPAGE, FILTER_MAXPAGE } from "context/special/mapContext"

import { styleClusterPoint, selectClusterFeatureStyle } from "hooks/map/helper"
import useToast from "hooks/useToast"
import axios from "axios"

export default function SearchResults({
    data,
    search,
})
{
    const { cMapState } = useMapContext()
    const initPagination = {
        page: 1,
        perPage: FILTER_PERPAGE,
        maxPage: data.maxPage || FILTER_MAXPAGE,
    }
    const [ pagination, setPagination ] = useState(initPagination)
    const { addToast } = useToast()

    const didMount = useRef(false);
    const paginationDots = "..."
    const showStep = 2

    useEffect(
        () =>
        {
            if (didMount.current) {
                search(pagination)
            } else {
                didMount.current = true;
            }
        },
        [pagination.page]
    )

    useEffect(
        () =>
        {
            // хайгаад утга ирсэн үе
            setPagination(
                (prev) => (
                    {
                        ...prev,
                        page: data.page ?? 1,
                        maxPage: data?.maxPage ?? FILTER_MAXPAGE
                    }
                )
            )
        },
        [data]
    )

    /** Тухайн хуудас руу үсрэх нь */
    const jumpPage = (toPage) =>
    {
        if (toPage === paginationDots)
        {
            return
        }

        setPagination(
            (prev) => (
                {
                    ...prev,
                    page: toPage
                }
            )
        )
    }

    /** хамгийн эхний хуудас руу үсрэх */
    const firstPage = () =>
    {
        setPagination(
            (prev) => (
                {
                    ...prev,
                    page: 1
                }
            )
        )
    }

    /** өмнөх хуудас руу үсрэх */
    const prevPage = () =>
    {
        setPagination(
            (prev) => ({
                ...prev,
                page: Math.max(1, prev.page - 1)
            })
        )
    }

    /** дараачийн хуудас руу үсрэх */
    const nextPage = () =>
    {
        setPagination(
            (prev) => ({
                ...prev,
                page: Math.min(prev.maxPage, prev.page + 1)
            })
        )
    }

    /** сүүлийн хуудас руу үсрэх нь */
    const lastPage = () =>
    {
        setPagination(
            (prev) => ({
                ...prev,
                page: prev.maxPage
            })
        )
    }

    /** тухайн сонгосон барилга руу нисэж очих */
    const jumpToProject = async (event, sdi, isEvent) =>
    {

        if (!sdi.general_plan && !sdi.et_file && !sdi.general_point)
        {
            addToast(
                {
                    text: "Геом өгөгдөл байхгүй байна.",
                    time: 3000,
                    type: "warning"
                }
            )
            return
        }

        // TODO:  тухайн төсөл рүү үсэрч очих
        let feature
        if (sdi.et_file)
        {
            const data = await axios.get(process.env.REACT_APP_SERVER_URL + sdi.et_file + `?dt=${Date.now()}`).catch(err => err)
            if (data?.status === 200)
            {
                const fdata = data.data
                feature = cMapState.parseGeoJsonToFeature(fdata)
            }
        }
        else if (sdi.general_point)
        {
            feature = cMapState.parseGeoJsonToFeature(sdi.general_point)
        }

        if (feature?.length > 0)
        {
            feature = feature[0]
        }

        if (feature)
        {
            const extent = feature.getGeometry().getExtent()
            cMapState.fitToExtent(extent, { duration: 2000, padding: [ 400, 400, 400, 400 ] })
        }

    }

    const result = useMemo(
        () =>
        {
            if (!data.data?.length)
            {
                return <div className="d-flex justify-content-center align-items-center"><span>Хайлтын үр дүн байхгүй байна</span></div>
            }

            const pages = []
            if (pagination.page !== 1)
            {
                pages.push(pagination.page - 1)
            }
            const maxToo = Math.min(pagination.page + showStep - 1, pagination.maxPage)
            for (let index = pagination.page; index <= maxToo; index++)
            {
                pages.push(index)
            }

            if (pagination.page > showStep)
            {
                pages.unshift(paginationDots)
            }

            if (maxToo !== pagination.maxPage)
            {
                pages.push(paginationDots)
            }

            return (
                <div>
                    {
                        data.data.map(
                            (sdi, idx) =>
                            {

                                return (
                                    <div className={css.item} key={idx} onClick={() => jumpToProject(null, sdi)}>
                                        <div className={css.itemInfo}>
                                            <div>
                                                {sdi.name}
                                            </div>
                                            <div className={css.location}>
                                                {sdi.unit1_name}, {} {sdi.unit2_name}, {} {sdi.unit3_name}, {} {sdi.unit4}
                                            </div>
                                        </div>
                                        {
                                            sdi.general_plan || sdi.et_file || sdi.general_point
                                            ? (
                                                <div className="d-flex justify-content-end align-items-center ms-2">
                                                    <i className="fas fa-arrow-right"></i>
                                                </div>
                                            )
                                            : null
                                        }
                                    </div>
                                )
                            }
                        )
                    }

                    <div className="d-flex justify-content-center">
                        <span className={css.pItem} onClick={firstPage}>
                            <i className="far fa-chevron-double-left"></i>
                        </span>
                        <span className={css.pItem} onClick={prevPage}>
                            <i className="far fa-chevron-left"></i>
                        </span>
                        {
                            pages.map(
                                (page, idx) =>
                                {
                                    return (
                                        <span
                                            key={idx}
                                            className={`${css.pItem} ${pagination.page === page ? css.active : ""}`}
                                            onClick={() => jumpPage(page)}
                                        >
                                            {page}
                                        </span>
                                    )
                                }
                            )
                        }
                        <span className={css.pItem} onClick={nextPage}>
                            <i className="far fa-chevron-right"></i>
                        </span>
                        <span className={css.pItem} onClick={lastPage}>
                            <i className="far fa-chevron-double-right"></i>
                        </span>
                    </div>

                </div>
            )

        },
        [data, pagination]
    )

    const filterCount = useMemo(
        () =>
        {
            return data.total || 0
        },
        [data]
    )

    return (
        <div className="pb-3">
            <h6 className='p-1'>Хайлтын үр дүн ({filterCount})</h6>
            {result}
        </div>
    )
}
