import React from 'react';

import Layout from 'components/special/Layout';

import fakeLinks from './routes';

const TestPage = () => {

    return (
        <Layout sidebarTitle='ҮНДСЭН ЦЭС' links={fakeLinks}/>
    );
}

export default TestPage;

