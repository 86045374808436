import { useParams } from "react-router-dom"

import CForm from "components/main/Form"

import useApi from "hooks/useApi"
import useLoader from "hooks/useLoader"

import { activityValidate } from "utils/validate"
import label from "utils/validate/label"

const DuplicateForm = ({ copyApartId, setHide, projectId, getData }) =>
{
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })

    const apartApi = useApi().apartment

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        const { errors, success, data } = await fetchData(apartApi.duplicate(projectId, copyApartId, formData)).catch(error => error)
        if (success)
        {
            getData(projectId)
            return setHide(true)
        }
        else return errors
    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "name",
                label: {
                    text: `${label['apartment']}ийн нэр:`
                },
                type: "text",
                disabled: isLoading,
                colMd: 12
            }
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: 'Үүсгэх',
        },
        validate: {
            validateMode: "all",
            yupValidate: activityValidate,
        },
    }
    return (
        <CForm form={form} />
    )
}

export default DuplicateForm
