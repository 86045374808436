import React from 'react'

/** True false аар нь ялгаж icon харуулах нь */
export default function AccessIcons({ isTrue })
{
    if (isTrue)
        return (
            <i className="fal fa-check-circle text-success"></i>
        )
    return (
        <i className="fal fa-times-circle text-danger"></i>
    )
}
