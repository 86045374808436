import { isValidElement } from "react"
import { isElement } from "react-dom/test-utils"

/**
 * TEST ний үед хэрэглэгдэнэ kindType монгол нэртэй байхад өнгө болгох
 * @param {string} kindType захиалгын төрөл
 * @returns өнгө буцна
 */
export const kindTypeToColor = (kindType) => kindType === 'BUY' ? 'green' : "red"

/**
 * Тестний үед хэрэглэгдэх kindtype монгол нэрийг англи болгох
 * @param {string} kindType захиалгын төрөл
 * @returns english name
 */
export const kindTypeToEngName = (kindType) => kindType === 'Авах' ? 'BUY' : "SELL"

/**
 * Англи buy sell ийг Монгол болгох нь
 * @param {string} kindType захиалгын төрөл
 * @returns mn name
 */
export const kindTypeToMnName = (kindType) => kindType === 'BUY' ? 'АВАХ' : "ЗАРАХ"

/**
 * Захиалгын state ийг гадаад нэрнээс монгол нэр ийг авах
 * @param {string} state төлөв нь гадаад нэрээр
 * @return mn name
 */
export function getMnNameState(state) {

    const obj = {
        "ACTIVE": "Идэвхтэй",
        "REJECTED": "Цуцлагдсан",
        "CONFIRMED": "Биелэгдсэн"
    }

    return obj?.[state] || state
}

/**
 * тооны өмнө 0 ээр дүүргэж өгөх нь
 * @param {number} num тоо
 * @param {number} spliceNo
 * @returns 0 ээр дүүрсэн тоо
 */
export const fullZero = (num, spliceNo=-2) => ("0" + num).slice(spliceNo)

/**
 * 2 талын object харьцуулж 1 дээр key ийн утган дээр 2 ийн утгыг оноох нь
 * зөвхөн object л байна
 * @param {object} main үндсэн object
 * @param {object} sub  үндсэний утгыг солих object
 */
export function setOptions(main, sub)
{
    const result = Object.keys(main).reduce(
        (prevValue, currentValue) =>
        {
            let value = sub?.[currentValue]

            //  хэрэв дотроо дахиад object агуулж байвал function aa дахиж дуудна
            if (typeof main[currentValue] === "object" && typeof value === 'object' && !isValidElement(value))
            {
                value = setOptions(main[currentValue], sub[currentValue])
            }

            prevValue[currentValue] = typeof value !== 'undefined' ? value : main[currentValue]
            return prevValue
        },
        {}
    )

    return result
}

/**
 * Object оос value -г авах нь ганц л value авна
 * @param {object} object object
 * @returns object ийн value {string}
 */
export function getValueFromObj(object) {
    const values = Object.values(object)
    if (!values.length)
    {
        return object
    }

    return values[0]
}

/**
 * Серверээс ирсэн хүсэлтээс numberDecimal ийг авах нь
 * @param {object} object
 */
export function getNumberDecimal(object) {
    return parseFloat(object['$numberDecimal'])
}

/** state ийн монгол нэрнээс хамаарч өнгийг авах нь
 * @param {string} stateMn
*/
export function getColorState(stateMn) {
    const obj = {
        "Идэвхтэй": "tx-green",
        "Цуцлагдсан": "tx-red",
        "Биелэгдсэн": "text-info",
    }
    return obj?.[stateMn] || ''
}

/**
 * Гүйлгээний төрлийн өнгийг авах нь
 * @param {string} kind төрөл
 */
export function getColorTransactionKind(kind)
{
    const obj = {
        "DEPOSIT": "tx-green",
        "WITHDRAW": "tx-red",
        "BUY": "tx-green",
        "SELL": "tx-red",
    }
    return obj?.[kind] || ''
}

/**
 * Гүйлгээний төрлийн гадаад үгийг Монгол болгох
 * @param {string} kind гадаад нэртэйгээр
 */
export function changeTransactionKindName(kind)
{
    const obj = {
        "DEPOSIT": "Орлого",
        "WITHDRAW": "Зарлага",
        "BUY": "Авсан",
        "SELL": "Зарсан",
    }

    return obj?.[kind] || kind
}

/** random тоо буцаана */
export function getRandomInt(max=10, min=0, fixed=0) {
    return parseFloat(((Math.random() * (max - min + 1)) + min).toFixed(fixed));
}

/**
 * @param {html id} id Уг counter Ийг гаргах html байрлал id гаар
 * @param {int} start counter хэдээс эхлэх
 * @param {int} end хэд хүртэл
 * @param {int} duration 1 тоо нь дээр хэр удах ms хугацаа
 */
export function counter(id, start=0, end, duration)
{
    let obj = document.getElementById(id),
        current = start,
        range = end - start,
        increment = end > start ? 1 : -1,
        step = Math.abs(Math.floor(duration ? duration : (end * 20) / range)),
        timer = setInterval(() =>
            {
                current += increment;
                obj.textContent = current;
                if (current === end)
                {
                    clearInterval(timer);
                }
            },
            step
        );
}

/** тухайн үгний эхний үсгийг том болгох */
export function capitalizeFirstLetter(string)
{
    return (string || "").charAt(0).toUpperCase() + (string || "").slice(1);
}

/** lastname болон firstname ээс тухайн хүний нэрийг гаргах
 * @param {string} firstName тухайн хүний өөрийн нэр
 * @param {string} lastName тухайн хүний Овог
*/
export function makeFullName(lastName, firstName)
{
    const firstLetter = (lastName || "").charAt(0).toUpperCase()
    return `${firstLetter}. ${capitalizeFirstLetter(firstName)}`
}

/** dom element эсэхийг шалгах нь */
export function isDOMTypeElement(element)
{
    return isElement(element) && typeof element.type === 'string';
}

/** Тухайн нэг component ийн props дээр нэмэлтээр props нэмэхээр бол энийг ашиглаж болно */
export function addPropsToComponent(Component, extraProps)
{
    let validElement = isDOMTypeElement(Component)
    if (validElement) return Component
    return <Component.type {...Component.props} {...extraProps} />;
}

export const isHttpImage = (image) =>
{
    if(image.startsWith('http')) return true
    return false
}

export const checkEmpty = (v) =>
{
    return v ? v : "-"
}

export const checkStatement = (v) =>
{
    return v ? 'Тийм' : "Үгүй"
}
