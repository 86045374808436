import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';
import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { projectValidate } from 'utils/validate';
import { dataToValueLabel, timeZoneToDateString } from 'utils/format';
import { HEREGJILT, PROPERTY } from 'utils/consts';

const ProjectAction = ({ id=null, handleDone=null, isDetail=false }) => {

    const [ inputValues, setInputValues ] = useState('');

    const [ units, setUnits ] = useState([])
    const [ realInputValues, setRealInputValues ] = useState({})

    const [ budgetTypes, setBudgetTypes ] = useState([])
    const [ currentUsage, setCurrentUsage ] = useState([])
    const [ allSales, setAllSales ] = useState([])
    const [ parkingType, setParkingType ] = useState([])

    const [ selectedParking, setSelectedParking ] = useState([])

    const navigate = useNavigate();
    let params = useParams()
    /** Газрын зургаас форм гаргаж байвал header харуулахгүй */
    let hasTitle = false
    let type = null
    if (id === null)
    {
        hasTitle = true
        id = params.id
        type = params.type
    }

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })

    const parkingTypeApi = useApi({ isDisplayToast: false }).parkingtype
    const budgettypeApi = useApi({ isDisplayToast: false }).budgetType
    const allSalesApi = useApi({ isDisplayToast: false }).allSales
    const projectApi = useApi({ isDisplayToast: false }).project
    const nowUsageApi = useApi({ isDisplayToast: false }).nowUsage
    const unitApi = useApi({ isDisplayToast: false }).unit

    /** create бол true, update бол false буцаана */
    let isCreate = id === "create"
    let isOld = type === 'old'

    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data } = await fetchData(projectApi.getOne(id, isOld))
                if (success)
                {
                    setInputValues(data)
                }
            }

            Promise.all([fetchData(budgettypeApi.get()), fetchData(parkingTypeApi.getParkingType()), fetchData(allSalesApi.get()), fetchData(nowUsageApi.get()) ]).then(
                resp =>
                {
                    setBudgetTypes(dataToValueLabel(resp[0].data, 'id', 'name'))
                    setParkingType(dataToValueLabel(resp[1].data, 'id', 'name'))
                    setAllSales(dataToValueLabel(resp[2].data, 'id', 'manager'))
                    setCurrentUsage(dataToValueLabel(resp[3].data, 'id', 'name'))
                }
            )
        },
        []
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        const fData = new FormData()
        fData.append('project_name', formData.project_name)
        fData.append('approval', formData.approval)
        fData.append('price_mkv', formData.price_mkv)
        fData.append('description', formData.description)
        fData.append('unit4', formData.unit4)
        fData.append('property', formData.property_type)
        fData.append('total_service_area', formData.total_service_area)
        fData.append('sales_manager', formData.sales_manager)
        fData.append('budget_type', formData.budget_type)
        fData.append('total_ail', formData.total_ail)
        fData.append('budget_amount', formData.budget_amount)
        fData.append('parking_type', `[${formData.parking_type}]`)
        fData.append('is_project', true)
        fData.append('approval_date', timeZoneToDateString(formData.approval_date, false))
        fData.append('use_date', timeZoneToDateString(formData.use_date, false))
        fData.append('current_use', formData.current_use)
        fData.append("is_end", isOld)
        fData.append('tuluwluguu_heregjilt', formData.tuluwluguu_heregjilt)

        for( let parkingType in formData ){
            if(parkingType.startsWith('parkingg_'))
            {
                fData.append(parkingType, parseInt(formData[parkingType]))
            }
        }

        const config =
        {
            headers: { 'content-type': 'multipart/form-data' }
        }

        if (isCreate)
        {

            for (var x = 0; x < formData.eskiz_info.length; x++)
            {
                fData.append("eskiz", formData.eskiz_info[x]);
            }
            fData.append('unit1', units.unit1)
            fData.append('unit2', units.unit2)
            fData.append('unit3', units.unit3)
            fData.append('et_file', formData.et_file[0])
            fData.append('general_plan', formData.general_plan[0])

            const { errors, success, data } = await fetchData(projectApi.post(fData)).catch(error => error)
            if (success)
            {
                return handleDone ? handleDone() : navigate(-1)
            }
            else return errors
        }

        fData.append('unit1', units.unit1)
        fData.append('unit2', units.unit2)
        fData.append('unit3', units.unit3)
        // Зургийг солисон эсэхийг шалгана
        if(formData.delete_eskiz)
        {
            for (var x = 0; x < formData.delete_eskiz.length; x++)
            {
                fData.append('delete_eskiz', formData.delete_eskiz[x]);
            }
        }
        if(formData.add_eskiz || formData.add_eskiz.length !== 0)
        {
            for (var x = 0; x < formData.add_eskiz.length; x++)
            {
                fData.append('add_eskiz', formData.add_eskiz[x]);
            }
        }
        if(inputValues?.general_plan !== formData.general_plan) fData.append('general_plan', formData.general_plan[0])
        if(inputValues?.et_file !== formData.et_file)
        {
            fData.append('et_file', formData.et_file[0])
        }

        const { errors, success, data } = await fetchData(projectApi.put(id, fData, config)).catch(error => error)
        if (success) handleDone ? handleDone() : navigate(-1)
        else return errors
    }

    useEffect(
        () =>
        {
            if(inputValues)
            {
                const values = {
                    ...inputValues,
                    unit1: inputValues.unit1.name,
                    unit2: inputValues.unit2.name,
                    unit3: inputValues.unit3.name,
                    parking_type: inputValues.parking_info.map((el) => el.parking_type),
                    sales_manager: inputValues.sales_manager?.id,
                    budget_type: inputValues.budget_type?.id,
                    current_use: inputValues.current_use?.id,
                    is_end: isOld,
                    approval_date: new Date(inputValues.approval_date),
                    use_date: new Date(inputValues.use_date),
                }

                const parkingCount = inputValues?.parking_info?.map((element) =>
                {
                    return { ['parkingg_' + element.parking_type]: element.count }
                })

                Object.assign(values, ...parkingCount)

                setRealInputValues(values)
                setSelectedParking(inputValues.parking_info.map((el) => { return { value: el.parking_type, label: el.name } }))
                setUnits(
                    {
                        unit1: inputValues.unit1?.id,
                        unit2: inputValues.unit2?.id,
                        unit3: inputValues.unit3?.id
                    }
                )
            }
        },
        [inputValues]
    )

    const handleEtFile = async (e, { setValue }) =>
    {
        const fData = new FormData()
        fData.append("file", e.target.files[0])
        const { success, data } = await fetchData(unitApi.findUnit(fData))
        if(success)
        {

            setValue("unit1", data.unit1.name)
            setValue("unit2", data.unit2.name)
            setValue("unit3", data.unit3.name)

            setUnits({
                unit1: data.unit1.id,
                unit2: data.unit2.id,
                unit3: data.unit3.id,
            })
        }
    }

    const displayParkingNumber = useMemo(
        () =>
        {
            if(selectedParking.length === 0) return []
            let parkingInputs = []
            parkingInputs =  selectedParking.map(
                (element) =>
                {
                    return {
                        inputType: "input",
                        registerName: "parkingg_" + element.value,
                        type: "number",
                        label: {
                            text: element.label + ":",
                        },
                        placeholder: "Машины зогсоолын тоо",
                        disabled: isDetail || isLoading,
                        colMd: 12 / selectedParking.length <= 2 ? 2 : 12 / selectedParking.length
                    }
                }
            )
            parkingInputs.unshift({ component: <hr /> })
            parkingInputs.push({ component: <hr/> })
            return parkingInputs
        },
        [selectedParking, isLoading, isDetail]
    )

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "project_name",
                type: "text",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "input",
                registerName: "approval",
                type: "text",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "datePicker",
                registerName: "approval_date",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "input",
                registerName: "price_mkv",
                type: "number",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "input",
                registerName: "budget_amount",
                type: "number",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "select",
                registerName: "parking_type",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6,
                options: parkingType,
                isMulti: true,
                onChange: evnet => setSelectedParking(evnet)
            },
            ...displayParkingNumber,
            {
                inputType: "select",
                registerName: "current_use",
                disabled: isDetail || isLoading,
                options: currentUsage,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "input",
                registerName: "total_ail",
                type: "number",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "input",
                registerName: "total_service_area",
                type: "number",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "input",
                registerName: "description",
                type: "textArea",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "fileUpload",
                registerName: "et_file",
                fileType: 'geojson',
                onChange: handleEtFile,
                colMd: !hasTitle ? 12 : 6,
                disabled: isDetail || isLoading,
            },
            {
                inputType: "fileUpload",
                registerName: "general_plan",
                fileType: 'geojson',
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "input",
                registerName: "unit1",
                disabled: true,
                colMd: 4
            },
            {
                inputType: "input",
                registerName: "unit2",
                disabled: true,
                colMd: 4
            },
            {
                inputType: "input",
                registerName: "unit3",
                disabled: true,
                colMd: 4
            },
            {
                inputType: "multiImageUpload",
                registerName: "eskiz_info",
                disabled: isDetail || isLoading,
                colMd: 12,
            },
            {
                inputType: "input",
                registerName: "unit4",
                type: "textArea",
                disabled: isDetail || isLoading,
                colMd: 12
            },
            {
                inputType: "datePicker",
                registerName: "use_date",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "select",
                registerName: "tuluwluguu_heregjilt",
                disabled: isDetail || isLoading,
                options: HEREGJILT,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "select",
                registerName: "sales_manager",
                disabled: isDetail || isLoading,
                options: allSales,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "select",
                registerName: "property_type",
                disabled: isDetail || isLoading,
                options: PROPERTY,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "select",
                registerName: "budget_type",
                disabled: isDetail || isLoading,
                options: budgetTypes,
                colMd: !hasTitle ? 12 : 6
            },
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isDetail || isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
            className: isDetail ? "d-none" : ""
        },
        validate: {
            validateMode: "all",
            yupValidate: projectValidate(isCreate),
        },
    }

    const body = <CForm inputValues={realInputValues} form={form}/>

    return (
        hasTitle
        ?
            <BoxBackground title={isCreate ? isOld ? 'Хуучин төсөл үүсгэх' : "Шинэ төсөл үүсгэх" : 'Төсөл засах'} body={body}/>
        :
            body
    );
}

export default ProjectAction;
