import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useRole from 'hooks/useRole';
import useToast from 'hooks/useToast';

import { PERM_READ } from 'utils/consts';

const WARNING_TEXT = "Танд эрх байхгүй байна"

const RoleRequired = ({ needRoles=[], children, isNavigate=true, to="/", warningText=WARNING_TEXT }) =>
{

    const { addToast } = useToast()
    let hasPerm = useRole(needRoles, PERM_READ)
    const location = useLocation()

    /** Эрх байхгүй бол alert харуулах */
    useEffect(
        () =>
        {
            if (!hasPerm && isNavigate)
            {
                addToast(
                    {
                        text: warningText,
                        time: 3000,
                        type: "warning"
                    }
                )
            }
        },
        [location, hasPerm]
    )

    if (hasPerm)
    {
        return children
    }
    else if (isNavigate)
    {
        return <Navigate to={to} state={{ from: location }} replace />
    }

    return null
}

export default RoleRequired;
