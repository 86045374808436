import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import useApi from "hooks/useApi"
import useMapContext from "hooks/useMapContext"
import { useEffect, useState } from "react"
import { objectToQuery, objectToQueryString } from "utils/browser"
import useLoader from 'hooks/useLoader'

import css from 'pages/Home/Map/FilterAnalyze/style.module.css'
import DataNotFound from "components/main/DataNotFound"

export default function PriceGraph({ feature })
{
    const tabName = feature.tabName
    const featureData = feature.feature
    const data = featureData.getProperties ? featureData.getProperties() : {}
    const [ listData, setData ] = useState([])
    const { allFilters } = useMapContext()
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: true })

    const api = useApi()

    useEffect(
        () =>
        {
            getData()
        },
        [tabName, featureData]
    )

    const getData = async () =>
    {
        let code = data['code']
        let type = {
            "first": "shz",
            "second": "borluulalt",
        }[tabName]
        const query = objectToQueryString(allFilters)
        const { success, data: list } = await fetchData(api.priceGraph(code, type, `&${query}`)).catch(err => err)
        if (success)
        {
            setData(list)
        }
    }

    const fixData = (list) =>
    {
        const newList = {}
        const uliral = {
            "1": "1",
            "2": "1",
            "3": "1",
            "4": "2",
            "5": "2",
            "6": "2",
            "7": "3",
            "8": "3",
            "9": "3",
            "10": "4",
            "11": "4",
            "12": "4",
        }
        list.forEach(
            element =>
            {
                const date = new Date(element.month)
                const month = date.getMonth() + 1
                const year = date.getFullYear()
                const culiral = uliral[month + ""]
                const ognoo = `${year}-${culiral}Q`
                if (newList[ognoo])
                {
                    const oldPrice = newList[ognoo]['price']
                    const oldCount = newList[ognoo]['count']
                    const sumCount = oldCount + element.count

                    const oldTotal = oldPrice * oldCount
                    const newTotal = element.avg_price * element.count

                    const avgPrice = (oldTotal + newTotal) / sumCount
                    newList[ognoo] = {
                        "ognoo": ognoo,
                        "price": avgPrice,
                        "count": sumCount
                    }
                }
                else {
                    newList[ognoo] = {
                        "ognoo": ognoo,
                        "price": element.avg_price,
                        "count": element.count
                    }
                }
                newList[ognoo].price = parseFloat(Math.round(newList[ognoo].price))
            }
        );
        return newList
    }

    useEffect(
        () =>
        {

            if (!listData.length)
            {
                return
            }

            const newData = Object.values(fixData(listData))
            var root = am5.Root.new("priceChart");
            var chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panY: false,
                    layout: root.verticalLayout
                })
            );

            const yRenderer = am5xy.AxisRendererY.new(root, {})
            // Craete Y-axi
            var yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    maxDeviation: 0.3,
                    renderer: yRenderer,
                    numberFormat: "##,###.##### ₮",
                })
            );

            // Create X-Axis
            var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 0 });
            xRenderer.labels.template.setAll({
                rotation: 90,
                fontSize: "9px",
            });
            var xAxis = chart.xAxes.push(
                am5xy.CategoryAxis.new(root, {
                    maxDeviation: 0.3,
                    renderer: xRenderer,
                    categoryField: "ognoo",
                    tooltip: am5.Tooltip.new(root, {}),
                })
            );
            xAxis.data.setAll(newData);

            var paretoAxisRenderer = am5xy.AxisRendererY.new(root, { opposite: true });
            var paretoAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                renderer: paretoAxisRenderer,

            }));

            // Create series
            var series1 = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: "Тоо ширхэг",
                    xAxis: xAxis,
                    yAxis: paretoAxis,
                    valueYField: "count",
                    categoryXField: "ognoo",
                })
            );
            series1.columns.template.setAll({
                tooltipText: "{categoryX}: {valueY}ш \n Дундаж үнэ: {price}₮",
                tooltipY: 0,
                strokeOpacity: 0,
                cornerRadiusTL: 6,
                cornerRadiusTR: 6
            });
            series1.data.setAll(newData);

            var series2 = chart.series.push(
                am5xy.LineSeries.new(root, {
                    name: "Дундаж үнэ",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "price",
                    valueXField: "ognoo",
                    categoryXField: "ognoo",
                    pointerOrientation: "horizontal",
                })
            );
            series2.strokes.template.setAll({
                strokeWidth: 2,
            });
            series2.fills.template.setAll({
                visible: false
            });
            series2.bullets.push(function() {
                return am5.Bullet.new(root, {
                    locationY: 0.5,
                    sprite: am5.Circle.new(root, {
                        radius: 5,
                        stroke: series2.get("stroke"),
                        strokeWidth: 2,
                        fill: root.interfaceColors.get("background")
                    })
                });
            });
            series2.data.setAll(newData);

            // Add legend
            var legend = chart.children.push(am5.Legend.new(root, {
                x: am5.percent(60),
                centerX: am5.percent(50),
            }));
            legend.data.setAll(chart.series.values);
            return () => {
				root.dispose()
			}
        },
        [listData]
    )

    if (isLoading)
    {
        return <div className="d-flex justify-content-center align-items-center">
            {Loader}
            Уншиж байна..
        </div>
    }

    if (!listData.length)
    {
        return (
            <DataNotFound fontSize={14} text='Үнийн мэдээлэл оруулаагүй байна.' absolute={false} />
        )
    }

    return (
        <div>
            <p></p>
            <div id="priceChart"
                style={{ minWidth: "400px", maxWidth: "600px", height: "400px", position: "relative", border: "1px solid rgba(0,0,0,0.1)", borderRadius: "10px" }}
            >
    			<div className="hidelogo"></div>
            </div>
            <ul className={css.sanamj}>
                <li className={css.sanamjLi}>
                    <i className='fa fa-info-circle text-primary me-1'></i>
                    Санамж:
                    <ol>
                        Q бол улирал
                    </ol>
                </li>
            </ul>
        </div>
    )
}
