import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import CreateButton from 'components/main/CudButtons/Create';
import DeleteButton from 'components/main/CudButtons/Delete';
import UpdateButton from 'components/main/CudButtons/Update';
import MapButton from 'components/main/CudButtons/Map';
import { DESC, ASC } from 'components/main/Table/Thead';
import Datatable from 'components/main/Datatable';
import OverlayElement from 'components/main/OverlayElement';
import RoleContainer from 'components/main/RoleRequired/Container';
import DuplicateButton from 'components/main/CudButtons/Duplicate';

import ApartmentDetails from './ApartmentDetails';
import Borluulalt from './Borluulalt';

import useCState from 'hooks/useCState';
import useLoader from 'hooks/useLoader';
import useModal from 'hooks/useModal';
import useApi from 'hooks/useApi';

import { companyPageRoles } from 'utils/consts/roles';
import DuplicateForm from './DuplicateForm';
import ApartStateChange from './ApartStateChange';
import label from 'utils/validate/label';

const Apartment = () => {

    const navigate = useNavigate();
    const dataTableRef = useRef(null)

    const apartmentApi = useApi().apartment;
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })
    const { setDisplay, setHide, showWarning } = useModal()

    const { checkedList, setCheckedList } = useCState()

    const { id: projectId } = useParams()

    const displayBorluulaltModal = (id) =>
    {
        setDisplay(
            {
                header:
                {
                    title: label['building'] + ' зураг оруулах',
                },
                body: <Borluulalt projectId={projectId} apartId={id} />,
                size: "xl",
                footer:
                {
                    show:true
                },
                variant: 'main'
            }
        )
    }

    /** Modal гаргаж ирэх funct */
    const displayDetail = (id) =>
    {
        setDisplay(
            {
                header:
                {
                    title: label['apartment'] + " дэлгэрэнгүй",
                },
                body: <ApartmentDetails api={apartmentApi.getOne(projectId, id)}/>,
                size: "lg",
                footer:
                {
                    show:false
                }
            }
        )
    }

    const getData = () =>
    {
        dataTableRef.current.firstGet = true
        dataTableRef.current.reData({}, null, [projectId])
    }

    /** Хүснэгтийн багана */
    const columns =
    [
        {
            field: "name",
            headerName: "Apartment нэр",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span className='tablerow-detail' onClick={() => displayDetail(row.id)}>{fieldValue}</span>,
        },
        {
            field: "butets_type_name",
            headerName: "Барилгын материалын төрөл",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>,
            searchField: false
        },
        {
            field: "total_ail",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>,
        },
        {
            field: "state_name",
            order: false,
            filter: {
                filterOptions: [
                    {
                        value: '1',
                        label: 'Баригдаж буй',
                        default: true
                    },
                    {
                        value: '2',
                        label: 'Баригдсан',
                    },
                    {
                        value: '3',
                        label: 'Нураасан',
                    }
                ],
                fieldName: 'c_state'
            },
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>,
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтийн мөрийн утгыг устгах */
    const deleteRow = async (id) =>
    {
        await fetchData(apartmentApi.delete(projectId, id))
        getData()
    }

    const duplicateApartment = async (id) =>
    {
        setDisplay({
			header: {
				title: "Орон сууц хуулж үүсгэх",
			},
			body: (
				<DuplicateForm getData={getData} projectId={projectId} setHide={setHide} copyApartId={id}/>
			),
			size: "lg",
			footer: {
				show: false,
			},
		})
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.id}/`)} needRoles={companyPageRoles}/>
                        <DeleteButton className='me-2' onClick={() => deleteRow(item.id)} needRoles={companyPageRoles}/>
                        <MapButton className={'me-2'} isChoosed={item?.is_choosed} onClick={() => navigate(`${item.id}/chooselocation/`)} needRoles={companyPageRoles}/>
                        <DuplicateButton onClick={() => duplicateApartment(item.id)} needRoles={companyPageRoles}/>
                        <RoleContainer needRoles={companyPageRoles}>
                            <OverlayElement
                                text='Борлуултын түүх'
                            >
                                <div className='cursorPointer ms-2' style={{ color: 'green' }} onClick={ () => displayBorluulaltModal(item.id) }><i className="fas fa-history"></i></div>
                            </OverlayElement>
                        </RoleContainer>
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    /** нураасан төлөвт оруулах гэсэн анхааруулгын модал гаргах */
    const handleClick= (data) =>
    {
        setDisplay({
			header: {
				title: "Барилгын төлөв шинэчлэх нь",
			},
			body: (
				<ApartStateChange
                    getData={getData}
                    projectId={projectId}
                    checkedList={checkedList}
                    setHide={setHide}
                    checkCount={checkedList.isAll && checkedList.unChecked.length === 0 ? 'бүх' : checkedList.checked.length === 0 ? checkedList.total - checkedList.unChecked.length : checkedList.checked.length}
                />
			),
			size: "lg",
			footer: {
				show: false,
			},
		})
    }

    const checkBoxOptions = {
        show: true,
        onClick: () => handleClick(),
        btnText: `Сонгогдсон утгуудыг засах (${checkedList.isAll && checkedList.unChecked.length === 0 ? 'бүгд' : checkedList.checked.length === 0 ? checkedList.total - checkedList.unChecked.length : checkedList.checked.length})`,
        rowsElement: 'id'
    }

    return (
        <Row>
            <Col sm={12}>
                <CreateButton btnClassName='floatRight' onClick={() => navigate('create/')} needRoles={companyPageRoles}/>
            </Col>
            <Col>
                <Datatable
                    dRef={dataTableRef}
                    columns={columns}
                    sequenceColumn={sequenceColumn}
                    extraColumns={extraColumns}
                    checkBoxOptions={checkBoxOptions}
                    tableOptions={
                        {
                            defaultSort: {
                                type: ASC,
                                field: "name"
                            }
                        }
                    }
                    request={
                        {
                            axios: apartmentApi.get,
                            params: [projectId]
                        }
                    }
                    isFirstGetData={true}
                />
            </Col>
        </Row>
    );
}

export default Apartment;
