
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Apartment from '.';

import ApartmentAction from './ApartmentAction';
import ApartmentMap from './Map';

const ApartmentCrudMain = () => {
    return (
        <Routes>
            <Route path="/" element={<Apartment/>} />
            <Route path="/:apartId/" element={<ApartmentAction/>} />
            <Route path="/:apartId/chooselocation/" element={<ApartmentMap/>} />
        </Routes>
    );
}

export default ApartmentCrudMain;
