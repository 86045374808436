import React, { useEffect, useState, useMemo } from 'react';

import useLoader from 'hooks/useLoader';

import label from 'utils/validate/label';

import css from './style.module.css'

/**
 * @param {api} api дэлгэрэнгүй гаргах мэдээлэлийг авах api
 * @param {array} ignoreList дэлгэрэнгүй дээр харуулахгүй утгууд
 * @returns дэлгэрэнгүй буцаана
 */
const GetOneDetail = ({ api, ignoreList }) => {

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })

    const [ detail, setDetail ] = useState({})

    useEffect(
        async () =>
        {
            const rsp = await fetchData(api)
            if (rsp.success)
            {
                setDetail(rsp.data)
            }
        },
        []
    )

    const displayElement = (element) =>
    {
        if(typeof element === 'array')
        {
            console.log('array');
        }
        if(element === null || element === undefined) return
        if(typeof element === 'number') return element
        if(typeof element === 'object') return
        if(typeof element === 'boolean') return
        if(element?.includes('.jpg') || element?.includes('.png') || element?.includes('.jpeg')) return <img  width={200} height={200} src={element} />
        return element
    }

    const displayDetails = useMemo(
        () =>
        {
            return Object.entries(detail)
                .map(
                    (element, index) =>
                    {
                        if(ignoreList?.includes(element[0])) return
                        if(element[0] === 'id') return
                        if(!displayElement(element[1])) return
                        return  <div className="d-flex"  key={index}>
                                    <div className={css.columnOne}>
                                        <span className={css.fieldName}>{label[element[0]]}:</span>
                                    </div>
                                    <div className={css.columnTwo} >
                                        <span className={css.valueName}>{displayElement(element[1])}</span>
                                    </div>
                                </div>
                    }
                )
        },
        [detail, api]
    )

    return (
        isLoading
        ?
            Loader
        :
            <div>
                {displayDetails}
            </div>
    );
}

export default GetOneDetail;
