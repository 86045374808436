import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import CreateButton from 'components/main/CudButtons/Create';
import DeleteButton from 'components/main/CudButtons/Delete';
import UpdateButton from 'components/main/CudButtons/Update';
import GetOneDetail from 'components/special/GetOneDetail';
import Datatable from 'components/main/Datatable';

import useLoader from 'hooks/useLoader';
import useModal from 'hooks/useModal';
import useApi from 'hooks/useApi';

import { PERM_IS_FROM_BUILDING, PERM_IS_FROM_PROJECT, PERM_IS_ORG_ADMIN } from 'utils/consts';

const Sales = () => {

    const navigate = useNavigate();
    const dataTableRef = useRef(null)

    const salesApi = useApi().sales
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })
    const { setDisplay } = useModal()

    /** Modal гаргаж ирэх funct */
    const displayDetail = (id) =>
    {
        setDisplay(
            {
                header:
                {
                    title: "Маркетингийн албаны дэлгэрэнгүй",
                },
                body: <GetOneDetail api={salesApi.getOne(id)}/>,
                size: "lg",
                footer:
                {
                    show:false
                }
            }
        )
    }

    const getData = () =>
    {
        dataTableRef.current.firstGet = true
        dataTableRef.current.reData({}, null, [])
    }

    /** Хүснэгтийн багана */
    const columns =
    [
        {
            field: "manager",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span className='tablerow-detail' onClick={() => displayDetail(row.id)}>{fieldValue}</span>
        },
        {
            field: "email",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span className='tablerow-detail' onClick={() => displayDetail(row.id)}>{fieldValue}</span>
        },
        {
            field: "website",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтийн мөрийн утгыг устгах */
    const deleteRow = async (id) =>
    {
        await fetchData(salesApi.delete(id))
        getData()
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.id}/`)} needRoles={[PERM_IS_ORG_ADMIN, PERM_IS_FROM_BUILDING, PERM_IS_FROM_PROJECT]}/>
                        <DeleteButton onClick={() => deleteRow(item.id)} needRoles={[PERM_IS_ORG_ADMIN, PERM_IS_FROM_BUILDING, PERM_IS_FROM_PROJECT]} />
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
        <Row>
            <Col sm={12}>
                <CreateButton btnClassName='floatRight' onClick={() => navigate('create/')} needRoles={[PERM_IS_ORG_ADMIN, PERM_IS_FROM_BUILDING, PERM_IS_FROM_PROJECT]}/>
            </Col>
            <Col>
                <Datatable
                    dRef={dataTableRef}
                    columns={columns}
                    sequenceColumn={sequenceColumn}
                    extraColumns={extraColumns}
                    request={
                        {
                            axios: salesApi.get,
                            params: []
                        }
                    }
                    isFirstGetData={true}
                />
            </Col>
        </Row>
    );
}

export default Sales;
