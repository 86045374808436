import SlowInput from 'components/main/SlowInput';
import React from 'react';

import css from '../style.module.css'

const SearchInput = ({ searchCallback }) => {
    return <SlowInput callback={searchCallback} className={`${css.seachContainer} seachContainer mb-2 col-md-12`} />
}

export default SearchInput;
