import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

import useLoader from "hooks/useLoader"
import useApi from "hooks/useApi"

import CSelect from "components/main/Form/InputTypes/Select"
import BoxBackground from "components/special/BoxBackground"

import { dataToValueLabel } from "utils/format"
import CForm from "components/main/Form"

const Songon = () =>
{
    const songonAdminApi = useApi().songon.admin
    const { isLoading, fetchData, Loader } = useLoader({ isSmall: false, hasBackground: false })
    const [ projectType, setProjectType ] = useState('new')
    const [ projects, setProjects ] = useState([])
    const navigate = useNavigate()

    useEffect(
        async () =>
        {
            const { success, data } =  await fetchData(songonAdminApi.projects())
            if (success) setProjects(data)
        },
        []
    )

    const handleOnSelect = (projectId) => navigate(`${projectType}/${projectId}/`)

    const projectOptions = useMemo(
        () => dataToValueLabel(projects[projectType], 'id', 'project_name'),
        [projectType, projects]
    )

     /** Form утгууд */
     const form = {
		inputs: [
            {
                inputType: "chooseItem",
                registerName: "status",
                disabled: isLoading,
                options: [
                    { value: 'new', label: 'Шинэ' },
                    { value: 'old', label: 'Хуучин' },
                ],
                colMd: 2,
                label: {
                    text: 'Төлөв:',
                    empty: true
                },
                onChange: value => setProjectType(value),
                defaultValue: 'new'
            },
			{
				inputType: "select",
				registerName: "project",
				onChange: value => handleOnSelect(value.value),
				disabled: isLoading,
				options: projectOptions,
				colMd: 10,
                label: {
                    empty: true
                }
			},
		],
		button: {
            show: false
		},
		validate: {
			validateMode: "all",
			yupValidate: null,
		},
	}

    return (
        <BoxBackground
            title='Сонгон шалгаруулалт үүсгэх төсөл сонгох'
            body={
                isLoading
                ?
                    Loader
                :
					<CForm form={form} />
            }
        />
    )
}

export default Songon
