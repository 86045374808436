import React from 'react';

import css from '../../style.module.css'

const IconItem = ({ icon, onClick=null, text='', className='', active, code, setActiveIcon, isLink }) => {

	const handleClick = () =>
	{
		if(code === active)
		{
			return setActiveIcon(0)
		}
		setActiveIcon(code)
	}

    return (
		<div className={`${css.iconContainer} ${active === code ? css.iconContainerActive : css.iconContainerInactive}`}>
			<div className={`${css.iconDugui} ${className}`}  onClick={ () => handleClick() } >
				<i className={icon}></i>
			</div>
			{code === active ? isLink ? <a target={'_blank'} href={text}>{text}</a> : text: ''}
		</div>
	)
}

export default IconItem;
