import { Col, Row } from "react-bootstrap"

import Slider from "components/main/Slider"
import OrgzColumnChart from "./OrgzColumnChart"
import AgeColumnChart from "./AgeColumnChart"
import OrgColumnChart from "./OrgColumnChart"

import css from '../../style.module.css'
import { useMemo } from "react"
import { moneyFormatString } from "utils/format"

const icons = {
    'Эмэгтэй': 'fas fa-venus',
    'Эрэгтэй': 'fas fa-mars',
    'Нийт өрхийн тоо': 'fas fa-home',
	"Дундаж м2 үнэ": "fas tugrug",
	"Дундаж м2": "fas fa-home",
}

export default function Statistic({ data, apartment, cMap, active })
{
    const diplayGender = useMemo(
        () =>
        {
            let newData = []
            if(!data) return
            const urhiin_too = { count: data.nemelt.urhiin_too, name: 'Нийт өрхийн тоо' }
            const dundaj_mkv_une = { count: moneyFormatString(apartment?.average_mkv, 1) || 0, name: 'Дундаж м2 үнэ' }
            const dundaj_mkv = { count: apartment?.average_residence_mkv ? parseFloat(apartment?.average_residence_mkv).toFixed(2) : 0, name: 'Дундаж м2' }
            const gender = data.stats.hun_amiin_stats.filter(e => e.name !== 'Нийт дүн')

            newData = [ ...gender, urhiin_too, dundaj_mkv_une, dundaj_mkv ]
            const datas = newData.map(
                (element, index) =>
                {
                    return (
                        <div key={index} xs={4} className={css.apartSmallItem}>
							<span>{element.count}</span>
							<i className={icons[element.name]} />
							<div>{element.name}</div>
						</div>
                    )
                }
            )
			return (
				<Slider
					freeMode={false}
					autoplay={true}
					autoplayDelay={2000}
					spaceBetween={10}
					pagination={true}
					navigation={false}
					items={datas}
					slidesPerView={1}
					loop={true}
					breakpoints={{
						640: {
							slidesPerView: 3,
							spaceBetween: 10,
						},
						768: {
							slidesPerView: 3,
							spaceBetween: 10,
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 10,
						},
						1280: {
							slidesPerView: 3,
							spaceBetween: 10,
						},
					}}
				/>
			)

        },
        [data, apartment]
    )
    return (
		<Col md={12} className='mt-2'>
			<Row>
				<Col md={7}>
					<Row className="mt-4">
						{diplayGender}
					</Row>
					<Row>
						<Col md={12}>
							<OrgColumnChart chartData={data?.stats?.orgs} />
						</Col>
					</Row>
				</Col>
				<Col md={5}>
					<OrgzColumnChart />
				</Col>
			</Row>
			<Row className="mt-3">
				<Col>
					<AgeColumnChart data={data?.stats?.nasjilt_stats} />
				</Col>
			</Row>
		</Col>
	)
}
