import React, { useEffect, useMemo, useState } from "react"

import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

const crudMnNames = {
    get: 'Авах',
    post: 'Үүсгэх',
    put: 'Өөрчлөх',
    delete: 'Устгах',
}

const CrudColumnChart = ({ accessLog }) => {
	useEffect(() => {
        let newData = []
        newData = Object.entries(accessLog).map(
            (element) =>
            {
                return {
                    name: crudMnNames[element[0]],
                    value: element[1],
                    bulletSettings: { src: process.env.REACT_APP_SERVER_URL + `/media/images/${element[0]}.png` },
                }
            }
        )
		let root = am5.Root.new("crudChartDiv")

		root.setThemes([am5themes_Animated.new(root)])

		let chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				panX: false,
				panY: false,
				wheelX: "none",
				wheelY: "none",
			}),
		)

		let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}))
		cursor.lineY.set("visible", true)

		let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 })

		let xAxis = chart.xAxes.push(
			am5xy.CategoryAxis.new(root, {
				maxDeviation: 0,
				categoryField: "name",
				renderer: xRenderer,
				tooltip: am5.Tooltip.new(root, {}),
			}),
		)

		xRenderer.grid.template.set("visible", true)

		let yRenderer = am5xy.AxisRendererY.new(root, {})
		let yAxis = chart.yAxes.push(
			am5xy.ValueAxis.new(root, {
				maxDeviation: 0,
				min: 0,
				extraMax: 0.1,
				renderer: yRenderer,
			}),
		)

		yRenderer.grid.template.setAll({
			strokeDasharray: [2, 2],
		})

		let series = chart.series.push(
			am5xy.ColumnSeries.new(root, {
				name: "Series 1",
				xAxis: xAxis,
				yAxis: yAxis,
				valueYField: "value",
				sequencedInterpolation: true,
				categoryXField: "name",
				tooltip: am5.Tooltip.new(root, { dy: -25, labelText: "{valueY}" }),
			}),
		)

		series.columns.template.setAll({
			cornerRadiusTL: 5,
			cornerRadiusTR: 5,
		})

		series.columns.template.adapters.add("fill", (fill, target) => {
			return chart.get("colors").getIndex(series.columns.indexOf(target))
		})

		series.columns.template.adapters.add("stroke", (stroke, target) => {
			return chart.get("colors").getIndex(series.columns.indexOf(target))
		})

		series.bullets.push(function () {
			return am5.Bullet.new(root, {
				locationY: 1,
				sprite: am5.Picture.new(root, {
					templateField: "bulletSettings",
					width: 50,
					height: 25,
					centerX: am5.p50,
					centerY: am5.p50,
					shadowColor: am5.color(0x000000),
					shadowBlur: 4,
					shadowOffsetX: 4,
					shadowOffsetY: 4,
					shadowOpacity: 0.6,
				}),
			})
		})

		xAxis.data.setAll(newData)
		series.data.setAll(newData)

		series.appear(1000)
		chart.appear(1000, 100)
        return () =>
        {
            root.dispose()
        }
	}, [accessLog])

	return (
		<div id="crudChartDiv" style={{ width: "100%", height: "500px", position: "relative", border: '1px solid rgba(0,0,0,0.1)', borderRadius: '10px', marginBottom: "3px" }}>
			<div className="hidelogo"></div>
		</div>
	)
}

export default CrudColumnChart
