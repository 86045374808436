import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import CreateButton from 'components/main/CudButtons/Create';
import DeleteButton from 'components/main/CudButtons/Delete';
import UpdateButton from 'components/main/CudButtons/Update';
import GetOneDetail from 'components/special/GetOneDetail';
import CTable from 'components/main/Table';

import useLoader from 'hooks/useLoader';
import useModal from 'hooks/useModal';
import useApi from 'hooks/useApi';

import { PERM_IS_ORG_ADMIN, PERM_IS_FROM_BUILDING, PERM_IS_FROM_PROJECT } from 'utils/consts';
import { adminPageRoles } from 'utils/consts/roles';
import { timeZoneToDateString } from 'utils/format';

const ActivityTypeMain = () => {

    const [ rows, setRows ] = useState([]);

    const navigate = useNavigate();

    const activityApi = useApi().activity

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })

    const { setDisplay } = useModal()

    /** Modal гаргаж ирэх funct */
    const displayDetail = (id) =>
    {
        setDisplay(
            {
                header:
                {
                    title: "Тусгай зөвшөөрлийн дэлгэрэнгүй",
                },
                body: <GetOneDetail api={activityApi.getOne(id)}/>,
                size: "lg",
                footer:
                {
                    show:false
                }
            }
        )
    }

    /** Хүснэгтийн багана */
    const columns =
    [
        {
            field: "name",
            headerName: 'Үйл ажиллагааны нэр',
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "created_at",
            order: false,
            fieldComponent: (value) => timeZoneToDateString(value)
        },
        {
            field: "updated_at",
            order: false,
            fieldComponent: (value) => timeZoneToDateString(value)
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтний утгыг авах */
    const getData = async () =>
    {
        const { success, data } = await fetchData(activityApi.get())
        if(success)
        {
            setRows(data)
        }
    }

    // back руу хүсэлт шидэх
    useEffect(
        () =>
        {
            getData()
        },
        []
    )

    /** Хүснэгтийн мөрийн утгыг устгах */
    const deleteRow = async (id) =>
    {
        await fetchData(activityApi.delete(id))
        await getData()
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.id}/`)} needRoles={adminPageRoles} />
                        <DeleteButton onClick={() => deleteRow(item.id)} needRoles={adminPageRoles} />
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
        <Row>
            <Col sm={12}>
                <CreateButton btnClassName='floatRight' onClick={() => navigate('create/')} needRoles={adminPageRoles} />
            </Col>
            <Col>
                <CTable
                    isLoading={isLoading}
                    columns={columns}
                    sequenceColumn={sequenceColumn}
                    extraColumns={extraColumns}
                    rows={rows}
                />
            </Col>
        </Row>
    );
}

export default ActivityTypeMain;
