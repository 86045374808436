import React from 'react';
import { Route, Routes } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';

import SanhvvTable from './SanhvvTable';

import BuySellAction from './SanhvvAction/BuySellAction';
import TureesAction from './SanhvvAction/TureesAction';
import ZassanAction from './SanhvvAction/ZassanAction';
import MenegmentAction from './SanhvvAction/MenegmentAction';
import BusadAction from './SanhvvAction/BusadAction';
import ZohihGuilgeeAction from './SanhvvAction/ZohihGuilgeeAction';

const NestedTables = () => {
    return (
		<Routes>
			<Route path="/" element={<BoxBackground title="Санхүүгийн зохицуулах хороо" body={<SanhvvTable />} />} />
			<Route path="/buy-sell/:id/" element={<BuySellAction />} />
			<Route path="/turees/:id/" element={<TureesAction />} />
			{/* <Route path="/zassan/:id/" element={<ZassanAction />} />
			<Route path="/menegment/:id/" element={<MenegmentAction />} />
			<Route path="/busad/:id/" element={<BusadAction />} />
			<Route path="/zohih-guilgee/:id/" element={<ZohihGuilgeeAction />} /> */}
		</Routes>
	)
}

export default NestedTables;
