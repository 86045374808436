import React, { useEffect } from 'react';

import CButton from 'components/main/Button';

import useLoader from 'hooks/useLoader';
import useApi from 'hooks/useApi';
import useAuth from 'hooks/useAuth';
import useToast from 'hooks/useToast';

import css from 'utils/css/separatedInput/style.module.css'

const SeparatedInput = ({ setShowCodeInput }) => {

	const { isLoading, Loader, fetchData } = useLoader({ isSmall: true })
	const mailApi = useApi().user.mail

	const { addToast } = useToast()

	const { getDetail } = useAuth()

	const keyDownFunction = (inputElements, e, index) =>
	{
		// if the keycode is backspace & the current field is empty
					// focus the input before the current. Then the event happens
					// which will clear the "before" input box.
		if (e.keyCode === 8 && e.target.value === "") inputElements[Math.max(0, index - 1)].focus()
	}

	const inputFunction = (inputElements, e, index) =>
	{
		// take the first character of the input
		// this actually breaks if you input an emoji like 👨‍👩‍👧‍👦....
		// but I'm willing to overlook insane security code practices.
		const [first, ...rest] = e.target.value
		e.target.value = first ?? "" // first will be undefined when backspace was entered, so set the input to ""
		const lastInputBox = index === inputElements.length - 1
		const insertedContent = first !== undefined
		if (insertedContent && !lastInputBox) {
			// continue to input the rest of the string
			inputElements[index + 1].focus()
			inputElements[index + 1].value = rest.join("")
			inputElements[index + 1].dispatchEvent(new Event("input"))
		}
	}

    useEffect(
        () =>
        {
			const inputElements = [...document.querySelectorAll("input.code-input")]
			inputElements.forEach((ele, index) => {
				ele.addEventListener("keydown", e => {
					keyDownFunction(inputElements, e, index)
				})
				ele.addEventListener("input", e => {
					inputFunction(inputElements, e, index)
				})
			})
			return () =>
			{
				window.removeEventListener('keydown', keyDownFunction)
				window.removeEventListener('input', inputFunction)
			}
		},
        []
    )

    const onSubmit = async (e) =>
	{
		e.preventDefault()

		const code = [...document.getElementsByClassName("code-input")]
			.filter(({ name }) => name)
			.map(({ value }) => value)
			.join("")
		if(code.length !== 6) return addToast({ text: '6 оронтой баталгаажуулах кодыг оруулна уу', type: 'error' })
		const { success } = await fetchData(mailApi.sendNumber({ number: code })).catch(err => err)
		if(success)
		{
			setShowCodeInput(false)
			getDetail()
		}
	}

    return (
			<form className={css.form} onSubmit={e => onSubmit(e)}>
				<div>Таны и-мэйл хаягруу илгээсэн баталгаажуулах 6 оронтой тоог оруулна уу</div>
				<div className={css.inputs}>
					<input disabled={isLoading} type='number' name='code' className="code-input"/>
					<input disabled={isLoading} type='number' name='code' className="code-input"/>
					<input disabled={isLoading} type='number' name='code' className="code-input"/>
					<input disabled={isLoading} type='number' name='code' className="code-input"/>
					<input disabled={isLoading} type='number' name='code' className="code-input"/>
					<input disabled={isLoading} type='number' name='code' className="code-input"/>
				</div>
				<CButton isLoading={isLoading} className='mt-2' type="submit" >Баталгаажуулах</CButton>
			</form>
	)
}

export default SeparatedInput;
