
import React from 'react';

export const Medeelel = () => {
    return (
        <div>
            Medeelel
        </div>
    );
}

export const Tailan = () => {
    return (
        <div>
            Tailan
        </div>
    );
}

export const User1 = () => {
    return (
        <div>
            User1
        </div>
    );
}

export const User2 = () => {
    return (
        <div>
            User2
        </div>
    );
}

export const grahic = () => {
    return (
        <div>
            grahic
        </div>
    );
}

export const grapic1 = () => {
    return (
        <div>
            grahic1
        </div>
    );
}

export const grapic2 = () => {
    return (
        <div>
            grahic2
        </div>
    );
}

const fakeLinks = [
    {
        name: "Мэдээллийн давхарга",
        path: "/navlink1/*",
        icon: <i className="fa-solid fa-arrow-up-a-z"></i>,
        component: Medeelel,
        layout: "/test/page",
    },
    {
        name: "Тайлан",
        path: "/navlink2/",
        component: Tailan,
        icon: <i className="fa-solid fa-arrow-right-to-city"></i>,
        layout: "/test/page",
    },
    {
        name: "Хэрэглэгч",
        path: "/sub/*",
        icon: <i className="fa-solid fa-arrow-up-a-z"></i>,
        subMenu: [
            {
                name: "Хэрэглэгч1",
                component: User1,
                path: "/submenu1/*",
            },
            {
                name: "Хэрэглэгч2",
                component: User2,
                path: "/submenu2/*",
            },
        ],
        layout: "/test/page",
    },
    {
        name: "График ",
        path: "/navlink4/*",
        icon: <i className="fa-solid fa-arrow-right-to-city"></i>,
        subMenu: [
            {
                name: "График1",
                component: grapic1,
                path: "/grapic1/*",
            },
            {
                name: "График2",
                component: grapic2,
                path: "/grapic2/*",
            },
        ],
        layout: "/test/page",
    },
    {
        name: "Мэдээ мэдээлэл",
        path: "/navlink5/*",
        component: Medeelel,
        icon: <i className="fa-solid fa-arrow-right-to-city"></i>,
        layout: "/test/page",
    },
];

export default fakeLinks;
