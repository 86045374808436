import { useEffect, useMemo, useState } from 'react'

import { SmallLoader } from 'components/main/Loader'

import css from '../../style.module.css'

const ChooseItem = ({ options, defaultValue, setValue=null, register, isLoading=false, onChange, getValues, setError }) =>
{
    const [ activeItem, setActiveItem ] = useState(null)

    useEffect(
        () =>
        {
            setActiveItem(defaultValue)
        },
        [defaultValue]
    )

    const handleOnChoose = (value) =>
    {
        if(isLoading) return
        setActiveItem(value)
        setValue && setValue(register?.name, value)
        onChange
        ? onChange(value, { setError, registerName: register.name, onChange: register.onChange, getValues })
        : register.onChange(value);
    }

    const displayItems = useMemo(
        () =>
        {
            if(!options) return
            return options.map(
                (element, index) =>
                {
                    return (
						<div
                            key={index}
							onClick={() => handleOnChoose(element.value)}
							className={`${css.chooseItem} ${activeItem === element.value ? css.choosedItem : ""}`}>
							{element.label}
						</div>
					)
                }
            )
        },
        [options, activeItem]
    )

    return (
        <div className={css.chooseItemContainer}>
            {displayItems}
        </div>
    )
}

export default ChooseItem
