import Slider from 'rc-slider';
import { useEffect, useMemo, useState } from 'react';

import 'rc-slider/assets/index.css';
import css from '../../style.module.css'
import '../style.css'

const LineSlider = ({ isRange=false, isLoading, defaultValueRange=[null, null], defaultValue=0, register, onChange, setValue, max=150, min=0, step=1, errors, marks, watch, className }) =>
{
    const [ values, setValues ] = useState(defaultValueRange ? defaultValueRange : defaultValue)

    const sliderValue = watch && watch(register?.name)

    const handleOnChange = (value) =>
    {
        setValues(value)
        onChange && onChange(value, register?.name)
        setValue && setValue(register?.name, value)
    }

    const defaultMarks = useMemo(
        () =>
        {
            let oneStep = max / 10
            let marks = {}
            for(var step = oneStep; step <= max; step = step + oneStep)
            {
                marks[step] = Math.ceil(step)
            }
            return marks
        },
        [min ,max]
    )

    const displayValues = useMemo(
        () =>
        {
            if(typeof values === 'number')
            {
                return <div className={css.sliderValues}>{values}</div>
            }
            return <div className={css.sliderValues}>{values[0]} - {values[1]}</div>
        },
        [values]
    )

    return (
		<div className={`mb-5 m-2 d-flex ${className}`}>
            <Slider
                range={isRange}
                allowCross={false}
                max={max}
                disabled={isLoading}
                min={min}
                marks={marks ? marks : defaultMarks}
                step={step}
                value={sliderValue}
                draggableTrack
                onChange={handleOnChange}
            />
		</div>
	)
}

export default LineSlider
