import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

import useApi from 'hooks/useApi';

import CForm from 'components/main/Form'

import { registerValidate } from "utils/validate";
import { dataToValueLabel } from 'utils/format';
import { SIGN_IN_PAGE } from 'utils/consts';
import css from '../style.module.css'
import useLoader from 'hooks/useLoader';

export default function SignUp()
{

    const [ companies, setCompanies ] = useState([])

    const companyApi = useApi().company
    const companyV2Api = useApi().company.v2

    const { isLoading, fetchData, Loader } = useLoader({ isSmall: true })

    const navigate = useNavigate()

    useEffect(
        async () =>
        {
            const { success, data, error } = await companyApi.getPublic()
            if (success)
            {
                setCompanies(dataToValueLabel(data, 'id', 'name'))
            }
        },
        []
    )

    const btnSubmit = async (data, { reset }) =>
    {
        const { success, errors } = await fetchData(companyV2Api.post(data)).catch(err => err)
        if (success)
        {
            navigate(SIGN_IN_PAGE)
            return
        }
        return errors
    }

    const signupInputs = {
        inputs: [
            {
                inputType: "input",
                registerName: "email",
                type: "email",
                placeholder: "И-мэйлээ оруулна уу",
                label: {
                    text: "Цахим шуудан",
                },
                colMd: 12,
                disabled: isLoading
            },
            {
                inputType: "select",
                registerName: "company",
                placeholder: "Байгууллага сонгоно уу",
                async: true,
                options: companies,
                label: {
                    text: "Байгууллага",
                    empty: false,
                },
                helperText: "Эхний 2 тэмдэгтийг оруулна уу",
                colMd: 12,
                disabled: isLoading
            },
        ],
        button: {
            title: "Бүртгүүлэх",
            variant: 'main',
            onSubmit: btnSubmit,
            isLoading: isLoading
        },
        validate: {
            validateMode: "all",
            yupValidate: registerValidate,
        }
    }

    return(
        <Container fluid>
            <Row>
                <Col lg={6} sm={12} className={`${css.loginBanner} p-0`}></Col>
                <Col lg={6} sm={12}>
                    <div className={css.signupSection}>
                        <div style={{ width: '400px' }}>
                            <h3 className={`${css.signTypeTitle} text-darkBlue mt-2 mb-4`}>Бүртгүүлэх</h3>
                            <CForm form={signupInputs} />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
