import React from 'react';
import { useNavigate } from 'react-router-dom';

import ImageFetch from 'components/main/Image';

import { timeZoneToDateString } from 'utils/format';
import { NEWS_PAGE_NAME } from 'utils/consts';

import css from '../../style.module.css'

const Item = ({ date, desc, image, id, pageId }) => {

    const navigate = useNavigate()

    const navigater = () =>
    {
        navigate(`/${NEWS_PAGE_NAME}/${pageId}/${id}/`)
    }

    return (
        <div>
            <div className={css.imageDesc}>
                <div className={css.imageContainerx}>
                    <ImageFetch onClick={navigater} className={css.smallImage} src={image} />
                </div>
                <div className='cursorPointer' onClick={navigater}>{desc}</div>
            </div>
            <div>
                <span className={css.newsSmallText}>
                    <i className={`far fa-clock tx-blue ${css.txBlue}`}/> {timeZoneToDateString(date)}
                </span>
            </div>
        </div>
    );
}

export default Item;
