import React, { useRef } from 'react'
import { Col, Row } from 'react-bootstrap';
import { Routes, Route, useNavigate } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground'
import Form from 'components/main/Form';
import Datatable from 'components/main/Datatable';
import { DESC } from 'components/main/Table/Thead';
import UpdateButton from 'components/main/CudButtons/Update';
import AccessIcons from 'components/special/AccessIcons';

import { bhRegisterValidate } from 'utils/validate';
import { govPageRoles } from 'utils/consts/roles';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';
import useAuth from 'hooks/useAuth'

import UserAction from 'pages/Gov/UserAction'

export default function BhTuv()
{
    const { userDetail: { user_role } } = useAuth()
    const bhApi = useApi().bh
    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const dataTableRef = useRef(null)
    const navigate = useNavigate()

    const regist = async (body) =>
    {
        const { success, data, errors } = await fetchData(bhApi.register(body)).catch(error => error)
        if (!success)
        {
            return errors
        }
        else {
            getData()
        }
    }

    const registerInputs =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "email",
                type: "email",
                label:
                {
                    text: "Цахим шуудан",
                    empty: false,
                },
                colMd: 12
            },
        ],
        button:
        {
            onSubmit: regist,
            title: "Бүртгүүлэх",
            disabled: isLoading,
            isLoading: isLoading,
        },
        validate:
        {
            validateMode: "all",
            yupValidate: bhRegisterValidate
        }
    }

    //DataTable дата авах
    const getData = () =>
    {
        dataTableRef.current.firstGet = true
        dataTableRef.current.reData({}, null, [])
    }

    /** Хүснэгтийн багана */
    const columns = [
        {
            field: "last_name",
            order: true,
        },
        {
            field: "first_name",
            order: true,
        },
        {
            field: "email",
            order: true,
        },
        {
            field: "is_active",
            headerName: "Нэвтрэх эрх",
            order: true,
            fieldComponent: (fieldValue, index, row) => <AccessIcons isTrue={fieldValue} />,
            width: 120,
            filter: {
                filterOptions: [
                    {
                        value: '1',
                        label: 'Эрхтэй',
                        default: true
                    },
                    {
                        value: '0',
                        label: 'Эрхгүй',
                    },
                ],
                fieldName: 'c_is_active'
            }
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.id}/`)} needRoles={govPageRoles} />
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
        <Row>
            <Col className='mb-3' xl={6}>
                <BoxBackground
                    title={"Бүртгэх"}
                    body={
                        <Form form={registerInputs} />
                    }
                />
            </Col>
            <Col className='' xl={12}>
                <Routes>
                    <Route path='' element={
                        <BoxBackground
                            title={"Ажилчдын жагсаалт"}
                            body={
                                <Datatable
                                    dRef={dataTableRef}
                                    columns={columns}
                                    sequenceColumn={sequenceColumn}
                                    extraColumns={extraColumns}
                                    tableOptions={{
                                        defaultSort: {
                                            field: "first_name",
                                            type: DESC,
                                        },
                                    }}
                                    request={{
                                        axios: bhApi.list,
                                        params: [],
                                    }}
                                    isFirstGetData={true}
                                />
                            }
                        />
                    } />
                    <Route
                        path=':userId/'
                        element={<UserAction />}
                    />
                </Routes>
            </Col>
        </Row>
    )
}
