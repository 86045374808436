import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ProgramDetail from '../ProgramLayout/ProgramDetail';

import GoTop from 'components/special/GoTop';

import LotteryDetail from './LotteryDetail';

import LotteryMain from '.';
import RoleRequired from 'components/main/RoleRequired';
import { userPageRoles } from 'utils/consts/roles';
import { SIGN_IN_PAGE } from 'utils/consts';
import Petitioner from '../Petitioner';

const Lottery = () => {
    return (
		<Routes>
			<Route
				path="/"
				element={
					<GoTop>
						<LotteryMain />
					</GoTop>
				}
			/>
			<Route
				path="/:lotteryId/"
				element={
					<GoTop>
						<LotteryDetail />
					</GoTop>
				}
			/>
			<Route
				path="/:lotteryId/register/:bulegId/:resId/*"
				element={
					<RoleRequired needRoles={userPageRoles} to={SIGN_IN_PAGE} warningText={"Та нэвтэрсэн байх ёстой"}>
						<GoTop>
							<Petitioner />
						</GoTop>
					</RoleRequired>
				}
			/>
			<Route
				path="/:lotteryId/hutulbur/"
				element={
					<GoTop>
						<ProgramDetail />
					</GoTop>
				}
			/>
		</Routes>
	)
}

export default Lottery;
