import React, { useEffect, useState } from "react"
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

import useLoader from "hooks/useLoader"
import useApi from "hooks/useApi"

import { quantityFormat } from "utils/format"
import DataNotFound from "components/main/DataNotFound"
import { CRUD_COLOR } from "utils/consts"

const CrudLineChart = ({ date, crudType }) => {
	const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false, absolute: "false" })
	const accessApi = useApi().adminAccessFront

	const [chartData, setChartData] = useState()

	const getData = async () => {
		const { success, data, errors } = await fetchData(accessApi.getChart(date[0], date[1])).catch(err => err)
		if (success) {
			setChartData(data)
		}
	}

	useEffect(() => {
		getData()
	}, [date])

	useEffect(() => {
		if (!chartData) return
		let root = am5.Root.new("crudLineChart")

		// Set themes
		// https://www.amcharts.com/docs/v5/concepts/themes/
		root.setThemes([am5themes_Animated.new(root)])

		// Create chart
		// https://www.amcharts.com/docs/v5/charts/xy-chart/
		let chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				panX: true,
				panY: true,
				wheelX: "panX",
				wheelY: "zoomX",
				pinchZoomX: true,
			}),
		)

		let xAxis = chart.xAxes.push(
			am5xy.DateAxis.new(root, {
				maxDeviation: 1,
				baseInterval: {
					timeUnit: "day",
					count: 1,
				},
				renderer: am5xy.AxisRendererX.new(root, {
					minGridDistance: 50,
					pan: "zoom",
				}),
				tooltip: am5.Tooltip.new(root, {}),
			}),
		)
		xAxis.get("dateFormats")["day"] = "MM/dd"

		let yAxis = chart.yAxes.push(
			am5xy.ValueAxis.new(root, {
				renderer: am5xy.AxisRendererY.new(root, {}),
			}),
		)

		Object.entries(chartData).map(element => {
			let totalNumber = 0
			element[1].map(element => (totalNumber = totalNumber + element.count))

			let series = chart.series.push(
				am5xy.LineSeries.new(root, {
					name: element[0],
					xAxis: xAxis,
					yAxis: yAxis,
					valueYField: "count",
					valueXField: "day",
					legendValueText: "{valueY}",
					legendRangeValueText: quantityFormat(totalNumber),
					stroke: CRUD_COLOR[element[0]],
					// tooltip: am5.Tooltip.new(root, {
					// 	pointerOrientation: "horizontal",
					// 	labelText: "[bold]{name}[/]\n{categoryX}: {valueY}",
					// }),
				}),
			)
			series.crudType = element[0]

			let tooltip = am5.Tooltip.new(root, {
				labelText: element[0].toLocaleUpperCase() + ": {valueY}",
				pointerOrientation: "horizontal",
				getFillFromSprite: false,
				getStrokeFromSprite: true,
				getLabelFillFromSprite: true,
			})

			tooltip.get("background").setAll({
				fill: CRUD_COLOR[element[0]],
				strokeOpacity: 0,
			})

			series.set("tooltip", tooltip)

			if (element[0] === crudType) {
				series.strokes.template.setAll({
					strokeWidth: 3,
				})
			} else {
				series.strokes.template.setAll({
					strokeWidth: 1,
				})
			}
			series.data.setAll(
				element[1].map(element => {
					return { day: new Date(element.day).getTime(), count: element.count }
				}),
			)
			series.appear()
		})

		// Add cursor
		// https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
		let cursor = chart.set(
			"cursor",
			am5xy.XYCursor.new(root, {
				behavior: "none",
			}),
		)
		cursor.lineY.set("visible", false)

		chart.set(
			"scrollbarX",
			am5.Scrollbar.new(root, {
				orientation: "horizontal",
			}),
		)

		let legend = chart.rightAxesContainer.children.push(
			am5.Legend.new(root, {
				width: 150,
				paddingLeft: 15,
				height: am5.percent(100),
			}),
		)

		legend.itemContainers.template.events.on("pointerover", function (e) {
			let itemContainer = e.target

			// As series list is data of a legend, dataContext is series
			let series = itemContainer.dataItem.dataContext

			chart.series.each(function (chartSeries) {
				if (chartSeries != series) {
					chartSeries.strokes.template.setAll({
						strokeOpacity: 0.15,
						stroke: am5.color(0x000000),
					})
				} else {
					chartSeries.strokes.template.setAll({
						strokeWidth: 3,
					})
				}
			})
		})

		legend.itemContainers.template.events.on("pointerout", function (e) {
			chart.series.each(function (chartSeries) {
				if (chartSeries.crudType === crudType) {
					chartSeries.strokes.template.setAll({
						strokeOpacity: 1,
						strokeWidth: 3,
						stroke: chartSeries.get("stroke"),
					})
				} else {
					chartSeries.strokes.template.setAll({
						strokeOpacity: 1,
						strokeWidth: 1,
						stroke: chartSeries.get("stroke"),
					})
				}
			})
		})

		legend.itemContainers.template.set("width", am5.p100)
		legend.valueLabels.template.setAll({
			width: am5.p100,
			textAlign: "right",
		})

		// It's is important to set legend data after all the events are set on template, otherwise events won't be copied
		legend.data.setAll(chart.series.values)

		// Make stuff animate on load
		// https://www.amcharts.com/docs/v5/concepts/animations/
		chart.appear(1000, 100)
		return () => {
			root.dispose()
		}
	}, [chartData, crudType])

	return (
		<div
			id="crudLineChart"
			style={{ width: "100%", height: "500px", position: "relative", border: "1px solid rgba(0,0,0,0.1)", borderRadius: "10px" }}>
			<div className="hidelogo"></div>
			{!chartData && !isLoading && <DataNotFound />}
			{isLoading && <div style={{ marginTop: "20px" }}>{Loader}</div>}
		</div>
	)
}

export default CrudLineChart
