import React, { Fragment, useEffect, useMemo } from 'react'
import { FullScreen, Zoom } from 'ol/control';

import OverlayElement from 'components/main/OverlayElement';
import UserLocation from './Location';

import useMapContext from 'hooks/useMapContext';

import BaseLayer from './BaseLayerSwitcher'

import './style.css'

const initButtons = ["fs", "location", "layers"]
/** Газрын зураг дээрх товчнууд
 * @param {HTMLElement} map газрын зургийн element
 */
export default function MapControls({ map, showButtons=initButtons, cMap, extraBtns=[] })
{

    const showFs = showButtons.includes("fs")
    const showLocation = showButtons.includes("location")
    const showLayers = showButtons.includes("layers")
    const showAlwayDrag = showButtons.includes("drag")

    const { alwaysDrag, setAlwaysDrag, setShowBaseLayer } = useMapContext()

    /** FullScreen товчйиг газрын зураг дээр нэмэх нь */
    const fullScreenControl = () =>
    {
        if (!showFs) return
        /** fullScreen ICON ийг үүсгэж fullScreen товч дээр оноох нь */
        const fullScreenIcon = document.createElement("i")
        // <i className="fa fa-maximize"></i>
        fullScreenIcon.className = "fa fa-maximize"

        /** томруулдаг товчийг нэмэх нь */
        const fullscreen = new FullScreen(
            {
                target: "map-fullscreen",
                className: "ol-fs",
                label: fullScreenIcon,
            }
        )
        map.addControl(fullscreen)
    }

    /** zoom лэдэг товчийг газрын зурагдээр нэмэх нь */
    const zoomControl = () =>
    {
        /** газрын зураг дээр zoom товч байгааг шалгах */
        if (cMap)
        {
            const controls = cMap.getControls()
            const foundZoom = controls.findIndex(
                (control, idx) =>
                {
                    return control.constructor.name === "Zoom"
                }
            )

            /** байвал дахиж нэмэхгүй */
            if (foundZoom !== -1)
                return
        }

        /** zoom level plus товчний icon ийг үүсгэх element */
        const zoomPlusIcon = document.createElement("i")
        // <i className="fa fa-plus"></i>
        zoomPlusIcon.className = "fa fa-plus"

        /** zoom level plus товчний icon ийг үүсгэх element */
        const zoomMinusIcon = document.createElement("i")
        // <i className="fa fa-plus"></i>
        zoomMinusIcon.className = "fa fa-minus"


        const zoomControl = new Zoom(
            {
                target: "map-zoom",
                zoomInLabel: zoomPlusIcon,
                zoomOutLabel: zoomMinusIcon
            }
        )
        map.addControl(zoomControl)

    }

    useEffect(
        () =>
        {
            if (map)
            {
                fullScreenControl()
                zoomControl()
            }
        },
        [map]
    )

    const displayDragBtn = useMemo(
        () =>
        {
            if(showAlwayDrag)
            {
                if (alwaysDrag)
                {
                    return (
                        <div onClick={() => setAlwaysDrag(!alwaysDrag)} className="btn btn-primary cborder">
                            <i className="far fa-hand-paper"></i>
                        </div>
                    )
                }
                else
                {
                    return (
                        <div onClick={() => setAlwaysDrag(!alwaysDrag)} className="btn btn-primary cborder">
                            <i className="far fa-hand-rock"></i>
                        </div>
                    )
                }
            }
            return
        },
        [showAlwayDrag, alwaysDrag]
    )

    const extraBtnsComp = useMemo(
        () =>
        {

            if (extraBtns.length === 0)
            {
                return null
            }

            return (
                <div className='map-controls mb-2'>
                    {
                        extraBtns.map(
                            (btn, idx) =>
                            {
                                const element = (
                                    <div
                                        className={`btn btn-primary cborder ${btn.disabled ? 'disabled' : ""}`}
                                        onClick={btn.onClick}
                                    >
                                        <i className={btn.icon}></i>
                                    </div>
                                )

                                if (btn.title)
                                {
                                    return (
                                        <OverlayElement
                                            key={idx}
                                            placement='left'
                                            text={btn.title}
                                        >
                                            {element}
                                        </OverlayElement>
                                    )
                                }

                                return <Fragment key={idx}>
                                    {element}
                                </Fragment>
                            }
                        )
                    }
                </div>
            )
        },
        [extraBtns]
    )

    return (
        <div className='map-controls-container-left'>
            {extraBtnsComp}
            <div className={`map-controls`}>
                { displayDragBtn }
                {
                    showFs
                    &&
                        <OverlayElement
                            placement='left'
                            text={"Бүтэн дэлгэцээр харах"}
                        >
                            <div className="btn btn-primary cborder" id={"map-fullscreen"}></div>
                        </OverlayElement>
                }
                {
                    showLocation
                    &&
                        <UserLocation cMap={cMap} />
                }
                {
                    showLayers
                    &&
                        <OverlayElement
                            placement='left'
                            text={"Суурь давхарга"}
                        >
                            <div onClick={() => setShowBaseLayer(prev => !prev)} className="btn btn-primary cborder"><i className="fas fa-layer-group"></i></div>
                        </OverlayElement>
                }
            </div>
            <div className="map-controls-zoom">
                <div className="btn btn-primary cborder" id={"map-zoom"}></div>
            </div>
            <BaseLayer map={map}/>
        </div>
    )
}
