import { useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect } from "react"
import { useState } from "react"
import { Col, Row } from 'react-bootstrap';

import BoxBackground from "components/special/BoxBackground"
import Datatable from "components/main/Datatable"
import UpdateButton from "components/main/CudButtons/Update"
import { DESC } from "components/main/Table/Thead"
import AccessIcons from "components/special/AccessIcons"
import CreateButton from 'components/main/CudButtons/Create';

import useApi from "hooks/useApi"
import useLoader from 'hooks/useLoader'
import { PERM_IS_FINANCIAL_REGULATORY_COMMISSION_SUPER } from 'utils/consts';

import { financialAdminPageRoles, govPageRoles } from "utils/consts/roles"

export default function Users()
{
    const companyApi = useApi().company
    const { id } = useParams()
    const dataTableRef = useRef()
    const navigate = useNavigate()
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: true, absolute: true })

    const [ companyDetail, setCompanyDetail ] = useState()

    const getCompanyData = async () =>
    {
        const { success, data, error, errors } = await fetchData(companyApi.shortDetail(id)).catch(err => err)
        if (success)
        {
            setCompanyDetail(data)
        }
    }

    useEffect(
        () =>
        {
            getCompanyData()
        },
        []
    )

    //DataTable дата авах
    const getData = () =>
    {
        dataTableRef.current.firstGet = true
        dataTableRef.current.reData({}, null, [])
    }

    /** Хүснэгтийн багана */
    const columns = [
        {
            field: "last_name",
            order: true,
        },
        {
            field: "first_name",
            order: true,
        },
        {
            field: "email",
            order: true,
        },
        {
            field: "register",
            order: true,
        },
        {
            field: "is_active",
            headerName: "Нэвтрэх эрх",
            order: true,
            fieldComponent: (fieldValue, index, row) => <AccessIcons isTrue={fieldValue} />,
            width: 120,
            filter: {
                filterOptions: [
                    {
                        value: '1',
                        label: 'Эрхтэй',
                        default: true
                    },
                    {
                        value: '0',
                        label: 'Эрхгүй',
                    },
                ],
                fieldName: 'c_is_active'
            }
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.user_id}/`)} needRoles={[ ...govPageRoles, ...financialAdminPageRoles ]} />
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
        <BoxBackground
            title={`${companyDetail?.company ?? ""} Хэрэглэгчид`}
        >
            {isLoading && Loader}
            <Row>
                <Col sm={12}>
                    <CreateButton btnClassName='floatRight' onClick={() => navigate('create/')} needRoles={[ ...govPageRoles, ...financialAdminPageRoles ]} />
                </Col>
                <Col>
                    <Datatable
                        dRef={dataTableRef}
                        columns={columns}
                        sequenceColumn={sequenceColumn}
                        extraColumns={extraColumns}
                        tableOptions={{
                            defaultSort: {
                                field: "first_name",
                                type: DESC,
                            },
                        }}
                        request={{
                            axios: companyApi.users,
                            params: [id],
                        }}
                        isFirstGetData={true}
                    />
                </Col>
            </Row>
        </BoxBackground>
    )
}
