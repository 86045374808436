import React, { useEffect, useState, useMemo } from 'react';

import useLoader from 'hooks/useLoader';

import AccessIcons from 'components/special/AccessIcons'
import { timeZoneToDateString } from 'utils/format';

import css from './style.module.css'

/**
 * @param {api} api дэлгэрэнгүй гаргах мэдээлэлийг авах api
 * @param {array} ignoreList дэлгэрэнгүй дээр харуулахгүй утгууд
 * @returns дэлгэрэнгүй буцаана
 */
const GetOneDetail = ({ api, ignoreList="user_role" }) => {

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })

    const [ detail, setDetail ] = useState({})

    useEffect(
        async () =>
        {
            const rsp = await fetchData(api).catch(err => err)
            if (rsp.success)
            {
                setDetail(rsp.data)
            }
        },
        []
    )

    return (
        isLoading
        ?
            Loader
        :
            <div>
                <div className="d-flex">
                    <div className={css.columnOne}>
                        <b className={css.fieldName}>id:</b>
                    </div>
                    <div className={css.columnTwo}>
                        <span className={css.valueName}>
                            {detail.id}
                        </span>
                    </div>
                </div>
                <div className="d-flex">
                    <div className={css.columnOne}>
                        <b className={css.fieldName}>Овог:</b>
                    </div>
                    <div className={css.columnTwo}>
                        <span className={css.valueName}>
                            {detail.last_name}
                        </span>
                    </div>
                </div>
                <div className="d-flex">
                    <div className={css.columnOne}>
                        <b className={css.fieldName}>Нэр:</b>
                    </div>
                    <div className={css.columnTwo}>
                        <span className={css.valueName}>
                            {detail.first_name}
                        </span>
                    </div>
                </div>
                <div className="d-flex">
                    <div className={css.columnOne}>
                        <b className={css.fieldName}>Хүйс:</b>
                    </div>
                    <div className={css.columnTwo}>
                        <span className={css.valueName}>
                            {detail.gender_display}
                        </span>
                    </div>
                </div>
                <div className="d-flex">
                    <div className={css.columnOne}>
                        <b className={css.fieldName}>Регистер:</b>
                    </div>
                    <div className={css.columnTwo}>
                        <span className={css.valueName}>
                            {detail.register}
                        </span>
                    </div>
                </div>
                <div className="d-flex">
                    <div className={css.columnOne}>
                        <b className={css.fieldName}>Цахим хаяг:</b>
                    </div>
                    <div className={css.columnTwo}>
                        <span className={css.valueName}>
                            {detail.email}
                        </span>
                    </div>
                </div>
                <div className="d-flex">
                    <div className={css.columnOne}>
                        <b className={css.fieldName}>Орох эрх байгаа эсэх:</b>
                    </div>
                    <div className={css.columnTwo}>
                        <span className={css.valueName}>
                            {<AccessIcons isTrue={detail.is_active} />}
                        </span>
                    </div>
                </div>
                <div className="d-flex">
                    <div className={css.columnOne}>
                        <b className={css.fieldName}>Бүртгүүлсэн огноо:</b>
                    </div>
                    <div className={css.columnTwo}>
                        <span className={css.valueName}>
                            {timeZoneToDateString(detail.joined_date)}
                        </span>
                    </div>
                </div>
            </div>
    );
}

export default GetOneDetail;
