import { useEffect, useRef } from 'react'
import { useMemo } from 'react'

import useMapContext from 'hooks/useMapContext'

import css from '../../style.module.css'

export default function MkvFilter({ fns })
{

    const { allFilters } = useMapContext()

    const minRef = useRef()
    const maxRef = useRef()
    const selectRef = useRef()

    const filters = [
        {
            "name": "40м² -с доош",
            "min": 0,
            "max": 40,
        },
        {
            "name": "40м² - 100м²",
            "min": 40,
            "max": 100,
        },
        {
            "name": "100м² - 150м²",
            "min": 100,
            "max": 150,
        },
        {
            "name": "150м² -с дээш",
            "min": 150,
            "max": null,
        },
    ]

    useEffect(
        () =>
        {
            fns.mkv = {
                minRef,
                maxRef,
                selectRef,
            }
        },
        []
    )

    const options = useMemo(
        () =>
        {
            return filters.map(
                (option, idx) =>
                {
                    return (
                        <option value={idx} key={idx}>
                            {option.name}
                        </option>
                    )
                }
            )
        },
        [filters]
    )

    const handleSelect = (event) =>
    {
        const index = event.target.value
        const option = filters[index]

        const min = option?.min ?? null
        const max = option?.max ?? null

        minRef.current.value = min
        maxRef.current.value = max

        allFilters['price_mkv_min'] = min
        allFilters['price_mkv_max'] = max
    }

    return (
        <div className={css.choiceContainer}>
            <h6>Талбай</h6>
            <div className='d-flex align-items-center'>
                <select ref={selectRef} name="" id="" className='form-select me-1' onChange={handleSelect}>
                    <option value=""> --- </option>
                    {options}
                </select>
                <input ref={minRef} type="number" className='form-control me-1' placeholder='бага' />
                ~
                <input ref={maxRef} type="number" className='form-control me-1' placeholder='их' />
                m<sup>2</sup>
            </div>
        </div>
    )
}
