import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import AnimatedDeleteBtn from 'components/main/CudButtons/AnimatedDeleteBtn';
import CButton from 'components/main/Button';

import { arrayRemoveElement, getReadableFileSizeString } from 'utils/format';

import css from '../../style.module.css'
import { isHttpImage } from 'utils';

const initCurrentImage = {
    image: null,
    isServer: false
}

const MultiImageUpload = ({ id='multiImage', initBoxWord='Зураг оруулах (чирч оруулах эсвэл Файл оруулах товч нь дээр дарна уу)', register, errors, btnTitle, setValue, inputValues, values, placeholder, multiple=true, getValues, resetField, disabled }) => {

    const [ images, setImages ] = useState([])
    const [ currentImage, setCurrentImage ] = useState(initCurrentImage)

    const [ names, setNames ] = useState('')
    const [ sizes, setSizes ] = useState(0)
    const [ serverImages, setServerImages ] = useState(null)
    const [ boxWord, setBoxWord ] = useState(initBoxWord)

    let [ deleteEskiz ] = useState([])
    let [ addEskiz, setAddEskiz ] = useState([])

    useEffect(
        () =>
        {
            setServerImages(values)
        },
        [values]
    )

    useEffect(
        () =>
        {
            const initialValue = 0;
            const sumWithInitial = Array.from(images).reduce(
              (previousValue, currentValue) => previousValue + currentValue.size,
              initialValue
            );
            setSizes(sumWithInitial)
            let namez = ''
            Array.from(images).map(
                (element, index) =>
                {
                    namez = namez + element.name + ', '
                    setNames(namez)
                }
            )
            if(images.length === 0)
            {
                setSizes(0)
                setNames('')
            }
        },
        [images]
    )

    const showInput = () => document.getElementById(id).click()

    const removeImage= (image, index) =>
    {
        addEskiz = Array.from(addEskiz).filter((item, idx) => index !== idx)
        setAddEskiz(addEskiz)

        let clonedImages = images
        clonedImages = Array.from(clonedImages).filter(function(item, idx) {
            return idx !== index
        })
        setImages(clonedImages)
    }

    useEffect(
        () =>
        {
            setValue("add_eskiz", addEskiz)
        },
        [addEskiz]
    )

    const displayThumbailImages = useMemo(
        () =>
        {
            setCurrentImage({ image: images[0], isServer: false })
            setValue(register.name, images)
            return Array.from(images).map(
                (element, index) =>
                {
                    return (
						<Col md={4} key={index} className={css.imageWrapper}>
							<img
								onClick={() => setCurrentImage({ image: element, isServer: false })}
								className={`${css.thumImage}`}
								src={URL.createObjectURL(element)}
							/>
                            {
                                !disabled
                                &&
                                    <AnimatedDeleteBtn onClick={() => removeImage(element, index)} className={css.deleteIcon} />
                            }
						</Col>
					)
                }
            )
        },
        [images]
    )

    const removeServerimage = (element, index) =>
    {
        let newServerImage = arrayRemoveElement(serverImages, index)
        deleteEskiz.push(element.id)
        setValue("delete_eskiz", deleteEskiz)

        if(newServerImage.length === 0) return setServerImages(null)
        setServerImages(newServerImage)
    }


    const displayValuesImage = useMemo(
        () =>
        {
            if(!serverImages || serverImages.length === 0)
            {
                setCurrentImage(initCurrentImage)
                return []
            }
            setCurrentImage({
				image: isHttpImage(serverImages[0].eskiz) ? serverImages[0].eskiz : process.env.REACT_APP_SERVER_PUBLIC_URL + serverImages[0].eskiz,
				isServer: true,
			})
            return serverImages.map(
                (element, index) =>
                {
                    return (
						<Col md={4} key={index} className={css.imageWrapper}>
							<img
								onClick={() =>
                                    setCurrentImage({
                                        image: isHttpImage(element.eskiz) ? element.eskiz : process.env.REACT_APP_SERVER_PUBLIC_URL + element.eskiz,
                                        isServer: true,
                                    })
								}
								className={`${css.thumImage}`}
								src={isHttpImage(element.eskiz) ? element.eskiz : process.env.REACT_APP_SERVER_PUBLIC_URL + element.eskiz}
							/>
                            {
                                !disabled
                                &&
							        <AnimatedDeleteBtn onClick={() => removeServerimage(element, index)} className={css.deleteIcon} />
                            }
						</Col>
					)
                }
            )
        },
        [serverImages, disabled]
    )

    const dragOver = (event, element) => {
        event.preventDefault()
        if (disabled) return
        element.classList.add(css.activeDragAndDrop)
        setBoxWord("Одоо тавьж болно")
    }

    const dragLeave = (event, element) => {
        if (disabled) return
        element.classList.remove(css.activeDragAndDrop)
        setBoxWord("Энд оруулаарай...")
    }

    const dragDrop = (event, element) => {
        event.preventDefault()
        if (disabled) return
        setImages([...images, ...event.dataTransfer.files])
        setBoxWord(initBoxWord)
    }

     // TODO: drag hiideg bolgoh, list iig mon adil oorchildog baih
    useEffect(
        () =>
        {
			const dropArea = document.querySelectorAll(".drag-area")

            for (let i = 0; i < dropArea.length; i++) {
                dropArea[i].addEventListener("dragover",  event => dragOver(event, dropArea[i]))
                dropArea[i].addEventListener("dragleave", event => dragLeave(event, dropArea[i]))
                dropArea[i].addEventListener("drop", event => dragDrop(event, dropArea[i]))
            }
            return () =>
            {
                const dropArea = document.querySelectorAll(".drag-area")

                for (let i = 0; i < dropArea.length; i++) {
                    dropArea[i].removeEventListener("dragover",  event => dragOver(event, dropArea[i]))
                    dropArea[i].removeEventListener("dragleave", event => dragLeave(event, dropArea[i]))
                    dropArea[i].removeEventListener("drop", event => dragDrop(event, dropArea[i]))
                }
            }
		},
        [images]
    )

    useEffect(
        () =>
        {
            setValue("add_eskiz", images)
        },
        [images]
    )

    const handleAddImage = (e) =>
    {
        setImages([...images, ...e.target.files])
    }

    return (
        <>
            <Row>
                <Col sm={12} md={12} lg={7}>
                    <div className={`${css.imgContainer} drag-area`} disabled={disabled} onClick={() => !disabled && showInput()}>
                        {
                            currentImage.image
                            ?
                                <img src={currentImage.isServer ? currentImage.image : URL.createObjectURL(currentImage.image)} />
                            :
                                <div className={css.dragAndDrop}>
                                    <i className="fas fa-cloud-download-alt"></i><br/>
                                    <span>{boxWord}</span>
                                </div>
                        }
                    </div>
                </Col>
                <Col sm={12} md={12} lg={5}>
                    <Row className={`${css.thumContainer} drag-area`}>
                        {displayValuesImage.length !== 0 && displayValuesImage}
                        {displayThumbailImages.length !== 0 && displayThumbailImages}
                    </Row>
                    {
                        !disabled
                        &&
                            <Row>
                                <div className="d-flex justify-content-between">
                                    {!serverImages ? <div className="mt-2">Файлын хэмжээ: {getReadableFileSizeString(sizes) === '0.1 kb' ? 0 : getReadableFileSizeString(sizes)}</div> : null}
                                    <CButton className="mt-3 ms-auto" onClick={() => showInput()}>
                                        {btnTitle ? btnTitle : "Файл оруулах"}
                                    </CButton>
                                </div>
                            </Row>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <input
                        {...register}
                        type="file"
                        id={id}
                        style={{ display: "none" }}
                        accept="image/*"
                        multiple={multiple}
                        onChange={(e) => {
                            !disabled && handleAddImage(e)
                        }}
                        disabled={disabled}
                    />
                </Col>
            </Row>
        </>
    );
}

export default MultiImageUpload;
