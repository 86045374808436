import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'

import CreateButton from 'components/main/CudButtons/Create'
import Datatable from 'components/main/Datatable'

import useApi from 'hooks/useApi'

import BorluulaltCreate from './BorluulaltCreate'

import { DESC } from 'components/main/Table/Thead'
import { PERM_IS_FROM_BUILDING, PERM_IS_FROM_PROJECT, PERM_IS_ORG_ADMIN } from 'utils/consts'
import { moneyFormat, timeZoneToDateString } from 'utils/format'

const sequenceColumn =
{
    headerName: "#",
    startNumber: 1,
    order: false,
    width: 50
}

const Borluulalt = ({ projectId, apartId }) =>
{

    const [ isCreate, setCreate ] = useState(false)
    const borluulaltApi = useApi().apartment.borluulalt

    const columns =
    [
        {
            field: "sales_manager",
            order: true,
            fieldComponent: (fieldValue, index, row) => fieldValue.manager,
            searchField: "sales__manager"
        },
        {
            field: "sales_manager",
            order: true,
            fieldComponent: (fieldValue, index, row) => fieldValue.contact,
            searchField: "sales__contact"
        },
        {
            field: "sales_manager",
            order: true,
            fieldComponent: (fieldValue, index, row) => fieldValue.email,
            searchField: "sales__email"
        },
        {
            field: "price_mkv",
            headerName: "м² ийн үнэ",
            order: true,
            fieldComponent: (fieldValue, index, row) => moneyFormat(fieldValue),
        },
        {
            field: "total_price",
            order: true,
            fieldComponent: (fieldValue, index, row) => moneyFormat(fieldValue),
        },
        {
            field: "created_at",
            order: true,
            fieldComponent: (fieldValue, index, row) => timeZoneToDateString(fieldValue)
        }
    ]

    return (
		<Row>
			<Col sm={12}>
                {
                    !isCreate &&
                    <CreateButton
                        btnClassName="floatRight"
                        onClick={() => setCreate(true)}
                        needRoles={[PERM_IS_ORG_ADMIN, PERM_IS_FROM_BUILDING, PERM_IS_FROM_PROJECT]}
                    />
                }
			</Col>
			<Col>
            {
                !isCreate
                ?
                    <Datatable
                        columns={columns}
                        sequenceColumn={sequenceColumn}
                        request={{
                            axios: borluulaltApi.get,
                            params: [projectId, apartId],
                        }}
                        isFirstGetData={true}
                        tableOptions={{
                            defaultSort: {
                                field: "created_at",
                                type: DESC,
                            },
                        }}
                        paginationConfig={{
                            container: {
                                isShow: true,
                                className: ""
                            },
                            checkboxActionBtn: {
                                className: "checkboxActionBtn col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-3",
                                isShow: true,
                                position: "center",
                                order: 2,
                            },
                            containerPages: {
                                className: "containerPages col-sm-2 col-md-2 col-lg-2 col-xl-2 mt-3",
                                isShow: true,
                                position: "center",
                                order: 2,
                            },
                            containerPerPage: {
                                className: "containerPerPage col-sm-12 col-md-12 col-lg-12 col-xl-12",
                                isShow: false,
                                position: "center",
                                order: 2,
                            },
                            containerPagination: {
                                className: "containerPagination col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-3",
                                isShow: true,
                                position: "center",
                                order: 3,
                            },
                            containerJumpPage: {
                                className: "containerJumpPage col-sm-5 col-md-5 col-lg-5 col-xl-5 mt-3",
                                isShow: true,
                                position: "center",
                                order: 2,
                            },
                        }}
                    />
                :
                    <BorluulaltCreate projectId={projectId} apartId={apartId} setCreate={setCreate}/>
            }
            </Col>
		</Row>
	)
}

export default Borluulalt
