import React from 'react'

export default function Badge(
    {
        style,
        text
    }
)
{
    return (
        <div className='emblem' style={style}><p>{text}</p></div>
    )
}
