import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import Datatable from 'components/main/Datatable';
import CreateButton from 'components/main/CudButtons/Create';
import DeleteButton from 'components/main/CudButtons/Delete';
import { DESC } from 'components/main/Table/Thead';

import useApi from 'hooks/useApi';
import { materialPageRoles } from 'utils/consts/roles';
import { moneyFormat } from 'utils/format';


const PriceList = () => {

    const navigate = useNavigate();
    const { id } = useParams()
    const priceApi = useApi().material.name.price
    const dtRef = useRef()

    /** DATATABLE шинэчлэх */
    const getData = () =>
    {
        /** Datatable Oc1 restart хийнэ */
        if (!dtRef.current) return
        dtRef.current.firstGet = true
        dtRef.current.reData({}, null, [id])
    }

    useEffect(
        () =>
        {
            getData()
        },
        [id]
    )

    /** Хүснэгтийн багана */
    const columns =
    [
        {
            field: "date",
            headerName: "Огноо",
        },
        {
            field: "price",
            headerName: "Үнэ",
            order: true,
            fieldComponent: (fieldValue, index, row) => moneyFormat(fieldValue),
        },
        {
            field: "unit",
            headerName: "Хэмжүүр",
            order: true,
        },
    ]

    const deleteRow = async (priceId) =>
    {
        const { success } = await priceApi.delete(id, priceId)
        if (success)
        {
            getData()
        }
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <DeleteButton onClick={() => deleteRow(item.id)} needRoles={materialPageRoles} />
                    </div>
                )
            },
            headerName: "",
        }
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    return (
        <Row>
            <Col sm={12}>
                <CreateButton btnClassName='floatRight' onClick={() => navigate('create/')} needRoles={materialPageRoles} />
            </Col>
            <Col>
                <Datatable
					dRef={dtRef}
                    columns={columns}
                    extraColumns={extraColumns}
                    sequenceColumn={sequenceColumn}
                    tableOptions={{
                        defaultSort: {
                            field: "date",
                            type: DESC,
                        },
                    }}
                    request={
                        {
                            axios: priceApi.get,
                            params: [id],
                        }
                    }
                    paginationConfig={{
                        container: {
                            isShow: true,
                            className: ""
                        },
                        checkboxActionBtn: {
                            className: "checkboxActionBtn col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-3",
                            isShow: true,
                            position: "center",
                            order: 2,
                        },
                        containerPages: {
                            className: "containerPages col-sm-2 col-md-2 col-lg-2 col-xl-2 mt-3",
                            isShow: true,
                            position: "center",
                            order: 2,
                        },
                        containerPerPage: {
                            className: "containerPerPage col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            isShow: false,
                            position: "center",
                            order: 2,
                        },
                        containerPagination: {
                            className: "containerPagination col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-3",
                            isShow: true,
                            position: "center",
                            order: 3,
                        },
                        containerJumpPage: {
                            className: "containerJumpPage col-sm-5 col-md-5 col-lg-5 col-xl-5 mt-3",
                            isShow: true,
                            position: "center",
                            order: 2,
                        },
                    }}
                />
            </Col>
        </Row>
    );
}

export default PriceList;
