import React from 'react';

import label from 'utils/validate/label';

import css from '../GetOneDetail/style.module.css'

const DisplaySingleValue = ({ item, details, formatter, name, isBoolean }) => {
    return (
		<div className="d-flex">
			<div className={css.columnOne}>
				<span className={css.fieldName} style={{ fontWeight: "500" }}>{name ? name : label?.[item]}:</span>
			</div>
			<div className={css.columnTwo} >
				<span className={css.valueName}>
		 			{formatter ? formatter(details?.[item]) : isBoolean ? details?.[item] ? 'Тийм' : 'Үгүй' : details?.[item]}{" "}
				</span>
			</div>
		</div>
	)
}

export default DisplaySingleValue;
