import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import "@szhsin/react-menu/dist/index.css";
import useMapContext from 'hooks/useMapContext';
import { useMemo, useState } from 'react';
import { useEffect } from 'react';

export default function Units({ onClick, onChange, boundingBoxRef=null })
{
    const { units, getUnitsList } = useMapContext()

    useEffect(
        () =>
        {
            if (!units.length)
            {
                getUnitsList()
            }
        },
        []
    )

    const handleClick = (e) =>
    {
        if (onClick)
            onClick(e)
    }

    const unitsFn = (list, unit) =>
    {
        unit++
        return list.map(
            (item, idx) =>
            {
                if (item?.nodes?.length)
                {
                    return (
                        <div
                            key={idx}
                            onClick={e => handleClick({ value: `${unit}||${item.id}||${item.code}||${item.label}` })}
                        >
                            <SubMenu overflow='auto' position='auto' label={item.label}>
                                {unitsFn(item.nodes, unit)}
                            </SubMenu>
                        </div>
                    )
                }
                return (
                    <MenuItem value={`${unit}||${item.id}||${item.code}||${item.label}`} key={idx}>{item.label}</MenuItem>
                )
            }
        )
    }

    const onMenuChange = (e) =>
    {
        const state = e.open
        const element = document.getElementById("menuicon")
        if (state)
        {
            element.classList.remove("fa-caret-up")
            element.classList.add("fa-caret-down")
        }
        else {
            element.classList.remove("fa-caret-down")
            element.classList.add("fa-caret-up")
        }
        onChange && onChange(e)
    }

    const unitsComp = useMemo(
        () =>
        {
            let unit = 0
            return (
                <Menu
                    overflow={"auto"}
                    position={"auto"}
                    menuButton={
                        <MenuButton
                            style={{
                                background: "unset",
                                color: "#212529",
                                fontSize:" 14px",
                                border: "1px solid #ccc",
                                transition: "0.4s ease-out",
                                width: "100%",
                                padding: "5px",
                                borderRadius: "3px",
                            }}
                        >
                            Засаг захиргааны сонголт
                            { } <i className="fas fa-caret-up" id='menuicon'></i>
                        </MenuButton>
                    }
                    onMenuChange={onMenuChange}
                    onItemClick={handleClick}
                >
                    {unitsFn(units, unit)}
                </Menu>
            )
        },
        [units]
    )

    return unitsComp
}
