import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import DeleteButton from 'components/main/CudButtons/Delete';
import UpdateButton from 'components/main/CudButtons/Update';
import CreateButton from 'components/main/CudButtons/Create';
import CTable from 'components/main/Table';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';
import { PERM_IS_SUPER_USER } from 'utils/consts';

const SelectRoom = () => {

    const { isLoading, fetchData } = useLoader({ isSmall: true })

    const [ rows, setRows ] = useState();

    const navigate = useNavigate();

    const selectRoomApi = useApi().selectRoom

    /** Хүснэгтийн багана */
    const columns = [
        {
            field: 'name',
            headerName: 'Өрөөний нэр',
            order: false,
            fieldComponent: (fieldValue) => <span>{fieldValue}</span>
        },
        {
            field: 'code',
            headerName: 'Өрөөний код',
            order: false,
            fieldComponent: (fieldValue) => <span>{fieldValue}</span>
        },
        {
            field: 'icon',
            headerName: 'Icon',
            order: false,
            fieldComponent: (fieldValue) => <span><i className={fieldValue}></i></span>
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтний утгыг авах */
    const getData = async () =>
    {
        const resp = await fetchData(selectRoomApi.get())
        if(resp.success)
        {
            setRows(resp.data)
        }
    }

    useEffect(
        () =>
        {
            getData()
        },
        []
    )

    /** Хүснэгтийн мөрийн утгыг устгах */
    const deleteRow = async (id) =>
    {
        await fetchData(selectRoomApi.delete(id))
        await getData()
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.id}/`)} needRoles={[ PERM_IS_SUPER_USER ]} />
                        <DeleteButton onClick={() => deleteRow(item.id)} needRoles={[ PERM_IS_SUPER_USER ]}/>
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
        <Row>
            <Col sm={12}>
                <CreateButton btnClassName='floatRight' onClick={() => navigate('create/')} needRoles={[ PERM_IS_SUPER_USER ]}/>
            </Col>
            <Col>
                <CTable
                    isLoading={isLoading}
                    columns={columns}
                    sequenceColumn={sequenceColumn}
                    extraColumns={extraColumns}
                    rows={rows}
                />
            </Col>
        </Row>
    );
}

export default SelectRoom;
