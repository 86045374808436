
import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';
import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { reportOc1Validate } from 'utils/validate';
import {
    HOUSING_TYPES,
    OMCHIIN_HELBER_TYPES,
    BARILGA_HIITS_TYPES,
} from 'utils/consts';
import { dataToValueLabel } from 'utils/format';

const ReportCreateOc2 = () =>
{

    const { id, year } = useParams()

    const navigate = useNavigate()

    const [ inputValues, setInputValues ] = useState('');
    const [ unit1, setUnit1 ] = useState([])
    const [ selectUnit1, setSelectUnit1 ] = useState('')
    const [ unit2, setUnit2 ] = useState([])
    const [ selectUnit2, setSelectUnit2 ] = useState('')
    const [ unit3, setUnit3 ] = useState([])

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })

    const unitApi = useApi().unit
    const reportApi = useApi().report

    /** create бол true, update бол false буцаана */
    const isCreate = id === undefined

    useEffect(
        async () =>
        {
            /** Эхлээд Unit-үүдийг утгыг авчирна */
            const unitRsp = await fetchData(unitApi.get())
            if(unitRsp.success)
            {
                setUnit1(unitRsp.data)
            }

            /** Create биш Update бол утгуудыг form-уудад оноож өгнө */
            if (!isCreate)
            {
                const { success, data } = await fetchData(reportApi.getOc2One(id))
                if (success)
                {
                    /** Формуудад утгаа оноож байна */
                    setInputValues(data)

                    /** Unit-үүдийн утгыг оноох */
                    setSelectUnit1(data.unit1)
                    setSelectUnit2(data.unit2)

                }
            }
        },
        []
    )

    const secondUnits = useMemo(
        () =>
        {
            if(!selectUnit1) return []
            let unit1Id = typeof selectUnit1 === "object" ? selectUnit1.id : selectUnit1
            const unit = unit1?.find(
                (el) =>
                {
                    return el.id === unit1Id
                }
            )
            setUnit2(unit?.unit2)
            return dataToValueLabel(unit?.unit2, 'id', 'name', null, "code")
        },
        [selectUnit1, unit1]
    )

    const thirdUnits = useMemo(
        () =>
        {
            if(!selectUnit2) return []
            let unit2Id = typeof selectUnit2 === "object" ? selectUnit2.id : selectUnit2
            const unit = unit2?.find(
                (el) =>
                {
                    return el.id === unit2Id
                }
            )
            return dataToValueLabel(unit?.unit3, 'id', 'name', null, 'code')
        },
        [unit2, selectUnit2]
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData, { reset, resetField }) =>
    {
        /** хоосон бөглөөгүй утгуудыг object-ээс устгах */
        Object.keys(formData).forEach(key =>
            {
                if (!formData[key])
                {
                    delete formData[key]
                }
            })

        /** сонгосон оны утгыг онооно */
        formData['year'] = year

        /** Шинээр үүсгэх */
        if (isCreate)
        {
            const { errors, success, data } = await fetchData(reportApi.createOc2(formData)).catch(error => error)
            if (success) return navigate(`/company/report/${year}/`)
            else return errors
        }
        /** Утгыг шинэчлэх */
        const { errors, success, data } = await fetchData(reportApi.updateOc2(formData, id)).catch(error => error)
        if (success) return navigate(`/company/report/${year}/`)
        else return errors
    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "select",
                registerName: "unit1",
                onChange: value => setSelectUnit1(value.value),
                options: dataToValueLabel(unit1, 'id', 'name'),
                colMd: 4,
            },
            {
                inputType: "select",
                registerName: "unit2",
                onChange: value => setSelectUnit2(value.value),
                nested: 'unit1',
                options: secondUnits,
                colMd: 4,
            },
            {
                inputType: "select",
                registerName: "unit3",
                nested: 'unit1',
                options: thirdUnits,
                colMd: 4,
                label: {
                    empty: false
                }
            },
            {
                inputType: "input",
                registerName: "unit4",
                type: "textArea",
                label:
                {
                    empty: true,
                },
                colMd: 12,
            },
            {
                component: (
                    <hr/>
                )
            },
            {
                inputType: "input",
                registerName: "passport_number",
                type: "text",
                label:
                {
                    empty: true,
                },
                colMd: 4,
            },
            {
                inputType: "input",
                registerName: "niit_suuts",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 4,
            },
            {
                inputType: "input",
                registerName: "ahiglaltad_oruulsan_on",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 4,
            },
            {
                inputType: "select",
                registerName: "housing_type",
                label:
                {
                    empty: true,
                },
                colMd: 6,
                options: HOUSING_TYPES,
            },
            {
                inputType: "select",
                registerName: "omch_helber",
                label:
                {
                    empty: true,
                },
                colMd: 6,
                options: OMCHIIN_HELBER_TYPES,
            },
            {
                component: (
                    <span><hr/><p>Өрөөний тоогоор</p></span>
                )
            },
            {
                inputType: "input",
                registerName: "oroonii_too_1",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 3,
            },
            {
                inputType: "input",
                registerName: "oroonii_too_2",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 3,
            },
            {
                inputType: "input",
                registerName: "oroonii_too_3",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 3,
            },
            {
                inputType: "input",
                registerName: "oroonii_too_4",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 3,
            },
            {
                inputType: "input",
                registerName: "oroonii_too_5",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 3,
            },
            {
                inputType: "input",
                registerName: "oroonii_too_6",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 3,
            },
            {
                inputType: "input",
                registerName: "oroonii_too_7_plus",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 3,
            },
            {
                component: (
                    <span><hr/><p>Зориулалтаар</p></span>
                )
            },
            {
                inputType: "input",
                registerName: "suutsnii",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 3,
                step: 0.000000000001
            },
            {
                inputType: "input",
                registerName: "aguulahiin",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 3,
                step: 0.000000000001
            },
            {
                inputType: "input",
                registerName: "uilchilgeenii",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 3,
                step: 0.000000000001
            },
            {
                inputType: "input",
                registerName: "avto_zogsool",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 3,
                step: 0.000000000001
            },
            {
                component: (
                    <hr/>
                )
            },
            {
                inputType: "input",
                registerName: "barilga_niit_talbai",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 4,
                step: 0.000000000001
            },
            {
                inputType: "input",
                registerName: "davhriin_too",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 4,
            },
            {
                inputType: "input",
                registerName: "ortsnii_too",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 4,
            },
            {
                inputType: "input",
                registerName: "tsahilgaan_shatnii_too",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 4,
            },
            {
                inputType: "input",
                registerName: "niit_avto_zogsool",
                type: "number",
                label:
                {
                    empty: true,
                },
                colMd: 4,
            },
            {
                inputType: "select",
                registerName: "barilgiin_undsen_hiits",
                label:
                {
                    empty: true,
                },
                colMd: 4,
                options: BARILGA_HIITS_TYPES,
            },
            {
                component: (
                    <span><hr/><p>Байршлын цэг</p></span>
                )
            },
            {
                inputType: "input",
                registerName: "urtrag",
                type: "number",
                colMd: 6,
                label:
                {
                    empty: true,
                },
                placeholder: 'xxx.xxxxxx °',
                step: 0.0000000000001
            },
            {
                inputType: "input",
                registerName: "orgorog",
                type: "number",
                colMd: 6,
                label:
                {
                    empty: true,
                },
                placeholder: 'xxx.xxxxxx °',
                step: 0.0000000000001
            },
            {
                component: (
                    <hr/>
                )
            },
            {
                inputType: "input",
                registerName: "shiidver_garsan_on",
                type: "textArea",
                label:
                {
                    empty: true,
                },
                colMd: 12,
            },
            {
                inputType: "input",
                registerName: "buulgasan_shaltgaan",
                type: "textArea",
                label:
                {
                    empty: true,
                },
                colMd: 12,
            },
        ],
        button: {
            onSubmit: btnOnSubmit,
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "all",
            yupValidate: reportOc1Validate,
        },
    }

    return (
        <>
            <BoxBackground
                title={
                    isCreate
                    ?
                    'АЙМАГ, НИЙСЛЭЛ НУТАГ ДЭВСГЭРТ АШИГЛАЛТАД ОРУУЛСАН БАРИЛГА БАЙГУУЛАМЖИЙН МЭДЭЭЛЭЛ, БҮРТГЭЛИЙН МАЯГТ үүсгэх'
                    :
                    'АЙМАГ, НИЙСЛЭЛ НУТАГ ДЭВСГЭРТ АШИГЛАЛТАД ОРУУЛСАН БАРИЛГА БАЙГУУЛАМЖИЙН МЭДЭЭЛЭЛ, БҮРТГЭЛИЙН МАЯГТ засах'
                }
                body={<CForm inputValues={inputValues} form={form}/>}
            />
        </>
    )
}

export default ReportCreateOc2;
