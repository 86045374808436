import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';
import CForm from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import cYup, { projectValidate, validates } from 'utils/validate';
import { dataToValueLabel, timeZoneToDateString } from 'utils/format';
import { TONOGLOL, OMCH_HELBER, HEREGJILT, TOLOVSHIL, HUCH_CHADAL_NEGJ, BARILGA_AJLIN_TOROL } from 'utils/consts';
import { transform } from 'ol/proj'
import NemdegInput from './NemdegInput';

export const DATA_PROJECTION = 'EPSG:4326'
export const FEATURE_PROJECTION = 'EPSG:3857'

const ProjectAction = ({ id=null, handleDone=null, isDetail=false, point=false, cadasterID="" }) => {

    const [ inputValues, setInputValues ] = useState('');

    const [ units, setUnits ] = useState([])
    const [ realInputValues, setRealInputValues ] = useState({})

    const [ budgetTypes, setBudgetTypes ] = useState([])
    const [ currentUsage, setCurrentUsage ] = useState([])
    const [ allSales, setAllSales ] = useState([])
    const [ parkingType, setParkingType ] = useState([])
    const [ propertyType, setPropertyTypes] = useState([])
    const [ barilgaZoriulalts, setBarilgaZoriulalts] = useState([])
    const [ barilgaTorol, setBarilgaTorol ] = useState([])
    const [ projectCompanies, setProjectCompanies] = useState([])
    const [ companies, setCompanies ] = useState([])
    const [ govorg, setGovorg ] = useState([])
    const [ constructions, setConstructions ] = useState([])
    const [ barilgaAjlinTorol, setBarilgaAjlinTorol ] = useState([])
    const [ companyRegisterResponse, setCompanyRegisterResponse ] = useState({})

    const [ measurementValues, setMeasurementValues ] = useState([])
    const [ barilgiinZoriulalt, selectedBarilgiinZoriulalt ] = useState(null)
    const [ selectedParking, setSelectedParking ] = useState([])
    const [ selectedPropertyType, setSelectedPropertyType ] = useState([])
    const [ selectedBudget, setSelectedBudget ] = useState([])
    const [ tonoglolUsage, setTonoglolUsage ] = useState([])
    const [ omchiinHelberUsagem, setOmchiinHelber ] = useState([])
    const [ komisPeoples, setKomisPeoples ] = useState([])

    const navigate = useNavigate();
    let params = useParams()
    /** Газрын зургаас форм гаргаж байвал header харуулахгүй */
    let hasTitle = false
    let type = null
    let isMap = false
    if (id === null)
    {
        hasTitle = true
        id = params.id
        type = params.type
        isMap = false
    }
    else{
        isMap =true
    }
    let isFromMap = !hasTitle

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })

    const parkingTypeApi = useApi({ isDisplayToast: false }).parkingtype
    const budgettypeApi = useApi({ isDisplayToast: false }).budgetType
    const allSalesApi = useApi({ isDisplayToast: false }).allSales
    const projectApi = useApi({ isDisplayToast: true }).project
    const nowUsageApi = useApi({ isDisplayToast: false }).nowUsage
    const unitApi = useApi({ isDisplayToast: false }).unit
    const propertyApi = useApi({ isDisplayToast: false }).propertyType
    const barilgaZoriulaltApi = useApi({ isDisplayToast: false }).barilgaZoriulalt
    const projectCompaniesApi = useApi({ isDisplayToast: false }).projectCompanyDetails
    const companyApi = useApi({ isDisplayToast: false }).company
    const govOrgApi = useApi({ isDisplayToast: false }).gov

    /** create бол true, update бол false буцаана */
    let isCreate = id === "create"
    let isOld = type === 'old' || (isMap ? realInputValues.is_end : false)
    let validation = projectValidate(isCreate, isFromMap, isOld)

    useEffect(
        async () =>
        {
            if (point && point.length)
            {
                const transFormedCoords = transform(point, FEATURE_PROJECTION, DATA_PROJECTION)
                const { success, data } = await fetchData(unitApi.findUnit({ transFormedCoords }))
                if (success)
                {
                    setUnits({
                        unit1: data.unit1.id,
                        unit2: data.unit2.id,
                        unit3: data.unit3.id,
                    })
                    setRealInputValues(
                        (prevValue) => (
                            {
                                ...prevValue,
                                "unit1": data.unit1.name,
                                "unit2": data.unit2.name,
                                "unit3": data.unit3.name,
                            }
                        )
                    )
                }
            }

            /** shine tusul bwal */
            if (!isOld)
            {
                setRealInputValues((prev) => ({ ...prev, tuluwluguu_heregjilt: 1 }))
            }
        },
        [point]
    )

    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data } = await fetchData(projectApi.getOne(id))
                if (success)
                {
                    setInputValues(data)
                    setKomisPeoples(data['komis_gishud'])
                }
            }

            Promise.all(
                [
                    fetchData(budgettypeApi.get()),
                    fetchData(parkingTypeApi.getParkingType()),
                    fetchData(allSalesApi.get()),
                    fetchData(nowUsageApi.get()),
                    fetchData(propertyApi.getPropertyType()),
                    // fetchData(barilgaZoriulaltApi.getBarilgaZoriulalts()),
                    fetchData(projectCompaniesApi.get()),
                    // fetchData(companyApi.getAll()),
                    fetchData(govOrgApi.get()),
                    fetchData(companyApi.construction()),
                    fetchData(companyApi.getBarilgaAjlinTorol()),
                    fetchData(companyApi.getBarilgaBaiguulagchAllList()),
                ]).then(
                resp =>
                {
                    setBudgetTypes(dataToValueLabel(resp[0].data, 'id', 'name'))
                    setParkingType(dataToValueLabel(resp[1].data, 'id', 'name'))
                    setAllSales(dataToValueLabel(resp[2].data, 'id', 'manager'))
                    setCurrentUsage(dataToValueLabel(resp[3].data, 'id', 'name'))
                    setPropertyTypes(dataToValueLabel(resp[4].data, 'id', 'name'))
                    // setBarilgaZoriulalts(dataToValueLabel(resp[5].data, 'id', 'name', ['name', 'code'], undefined, ' - '))
                    setProjectCompanies(dataToValueLabel(resp[5].data, 'id', 'name', ['name', 'register_number'], undefined, ' - '))
                    // setCompanies(dataToValueLabel(resp[6].data, 'id', 'company'))
                    setGovorg(dataToValueLabel(resp[6].data, 'id', 'name'))
                    setConstructions(dataToValueLabel(resp[7].data, 'id', 'name'))
                    setBarilgaAjlinTorol(dataToValueLabel(resp[8].data, 'id', 'name'))
                    setCompanies(dataToValueLabel(resp[9].data, 'name', 'name'))
                }
            )
        },
        []
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        const fData = new FormData()
        fData.append('project_name', formData.project_name)
        fData.append('company_register_number', formData.company_register_number)
        fData.append('approval', formData.approval)
        fData.append('price_mkv', formData.price_mkv)
        fData.append('gadna_tohijiltiin_talbai', formData.gadna_tohijiltiin_talbai)
        fData.append('huuhdiin_togloomiin_talbai', formData.huuhdiin_togloomiin_talbai)
        fData.append('description', formData.description)
        fData.append('unit4', formData.unit4)
        fData.append('tonoglol', formData.tonoglol)
        fData.append('omch_helber', formData.omchiin_helber)
        fData.append('total_service_area', formData.total_service_area)
        fData.append('sales_manager', formData.sales_manager)
        fData.append('budget_type', `[${formData.budget_type}]`)
        fData.append('total_ail', formData.total_ail)
        fData.append('parking_type', `[${formData.parking_type}]`)
        fData.append('construction', `[${formData.construction}]`)
        fData.append('property_type', `[${formData.property_type}]`)
        fData.append('is_project', true)
        fData.append('approval_date', timeZoneToDateString(formData.approval_date, false))
        fData.append('use_date', timeZoneToDateString(formData.use_date, false))
        fData.append('current_use', formData.current_use)
        fData.append('tuluwluguu_heregjilt', formData.tuluwluguu_heregjilt)

        fData.append('unit5', formData.unit5);
        formData.architecture_tolovlolt_datetime && fData.append('architecture_tolovlolt_datetime', timeZoneToDateString(formData.architecture_tolovlolt_datetime, false));
        fData.append('architecture_tolovlolt_number', formData.architecture_tolovlolt_number);
        formData.zurag_tosol_datetime && fData.append('zurag_tosol_datetime', timeZoneToDateString(formData.zurag_tosol_datetime, false));
        fData.append('zurag_tosol_number', formData.zurag_tosol_number);
        formData.tolovshil_angilal && fData.append('tolovshil_angilal', formData.tolovshil_angilal);
        fData.append('heseg_blok', formData.heseg_blok);
        fData.append('huchin_chadal', formData.huchin_chadal);
        fData.append('huchin_chadal_hemjih_negj', formData.huchin_chadal_hemjih_negj);
        fData.append('tehnik_tsahilgan_hangamj', formData.tehnik_tsahilgan_hangamj);
        fData.append('tehnik_dulan_hangamj', formData.tehnik_dulan_hangamj);
        fData.append('tehnik_us_hangamj', formData.tehnik_us_hangamj);
        fData.append('tehnik_holbo_dohiolol', formData.tehnik_holbo_dohiolol);
        formData.barilgiin_zoriulalt && fData.append('barilgiin_zoriulalt', formData.barilgiin_zoriulalt);
        fData.append('barilga_ajlin_gerchilge_dugar', formData.barilga_ajlin_gerchilge_dugar);
        fData.append('barilga_ajlin_sungasan_tailbar', formData.barilga_ajlin_sungasan_tailbar);
        fData.append('nemelt_orchlolt_tailbar', formData.nemelt_orchlolt_tailbar);
        fData.append('komisPeoples', `${JSON.stringify(komisPeoples)}`);
        // formData.barilga_zahialagch && fData.append('barilga_zahialagch', formData.barilga_zahialagch);
        // formData.barilga_zohiogch && fData.append('barilga_zohiogch', formData.barilga_zohiogch);
        // formData.barilga_ajil_guitsetgegch && fData.append('barilga_ajil_guitsetgegch', formData.barilga_ajil_guitsetgegch);
        // formData.barilga_tuslan_guitsetgegch && fData.append('barilga_tuslan_guitsetgegch', formData.barilga_tuslan_guitsetgegch);
        fData.append('barilga_ajlin_torol', formData.barilga_ajlin_torol);
        // formData.barilga_omchlogch && fData.append('barilga_omchlogch', formData.barilga_omchlogch);
        fData.append('barilga_omchlogch', formData.barilga_omchlogch_select ? formData.barilga_omchlogch_select : '');
        fData.append('zovshorol_olgoson_baigullaga', formData.zovshorol_olgoson_baigullaga_select ? formData.zovshorol_olgoson_baigullaga_select : '');
        formData.komis_zohion_baigulagch && fData.append('komis_zohion_baigulagch', formData.komis_zohion_baigulagch);
        fData.append('komis_dugaar', formData.komis_dugaar);

        formData.barilga_ajlin_gerchilge_ogno && fData.append('barilga_ajlin_gerchilge_ogno', timeZoneToDateString(formData.barilga_ajlin_gerchilge_ogno, false));
        formData.barilga_ajlin_gerchilge_hugatsa && fData.append('barilga_ajlin_gerchilge_hugatsa', timeZoneToDateString(formData.barilga_ajlin_gerchilge_hugatsa, false));
        formData.barilga_ajlin_sungasan_ogno && fData.append('barilga_ajlin_sungasan_ogno', timeZoneToDateString(formData.barilga_ajlin_sungasan_ogno, false));
        formData.nemelt_orchlolt_ogno && fData.append('nemelt_orchlolt_ogno', timeZoneToDateString(formData.nemelt_orchlolt_ogno, false));
        formData.ashiglaltad_ognoo && fData.append('ashiglaltad_ognoo', timeZoneToDateString(formData.ashiglaltad_ognoo, false));
        formData.komis_ognoo && fData.append('komis_ognoo', timeZoneToDateString(formData.komis_ognoo, false));

        if (isOld && formData.aorson_ognoo)
        {
            fData.append('aorson_ognoo', timeZoneToDateString(formData.aorson_ognoo, false))
        }

        if (isMap)
            fData.append("is_end", formData.is_end)
        else
            fData.append("is_end", isOld)

        for( let parkingType in formData ){
            if(parkingType.startsWith('parkingg_'))
            {
                fData.append(parkingType, parseInt(formData[parkingType]))
            }
        }

        for( let BudgetType in formData ){
            if(BudgetType.startsWith('budgetg_'))
            {
                fData.append(BudgetType, parseInt(formData[BudgetType]))
            }
        }

        const config =
        {
            headers: { 'content-type': 'multipart/form-data' }
        }

        let measurementFormValues = []

        let measurementkeys = Object.keys(formData).filter((val) => val.startsWith('measurement-'))

        if (measurementkeys.length > 0)
        {
            for (let measurementkey of measurementkeys)
            {
                if (typeof formData[measurementkey] == 'object')
                {
                    for (let miniMeasurementKeys of Object.keys(formData[measurementkey]))
                    {
                        measurementFormValues.push({
                            name: `${measurementkey.replace('measurement-', '')}.${miniMeasurementKeys}`,
                            value: formData[measurementkey][miniMeasurementKeys],
                        })
                    }
                }
                else
                {
                    measurementFormValues.push({
                        name: measurementkey.replace('measurement-', ''),
                        value: formData[measurementkey],
                    })
                }
            }
        }

        fData.append('measurementFormValues', JSON.stringify(measurementFormValues))

        if (isCreate)
        {

            for (var x = 0; x < formData.eskiz_info.length; x++)
            {
                fData.append("eskiz", formData.eskiz_info[x]);
            }
            fData.append('unit1', units.unit1)
            fData.append('unit2', units.unit2)
            fData.append('unit3', units.unit3)
            if (formData.et_file[0])
            {
                fData.append('et_file', formData.et_file[0])
            }
            if (formData.general_plan[0])
            {
                fData.append('general_plan', formData.general_plan[0])
            }

            if (point && point.length)
            {
                const transFormedCoords = transform(point, FEATURE_PROJECTION, DATA_PROJECTION)
                fData.append('general_point', transFormedCoords)
            }
            fData.append('cadaster_id', cadasterID)

            const { errors, success, data } = await fetchData(projectApi.post(fData)).catch(error => error)
            if (success)
            {
                return handleDone ? handleDone() : navigate(-1)
            }
            else return errors
        }

        fData.append('unit1', units.unit1)
        fData.append('unit2', units.unit2)
        fData.append('unit3', units.unit3)
        // Зургийг солисон эсэхийг шалгана
        if(formData.delete_eskiz)
        {
            for (var x = 0; x < formData.delete_eskiz.length; x++)
            {
                fData.append('delete_eskiz', formData.delete_eskiz[x]);
            }
        }
        if(formData.add_eskiz || formData.add_eskiz.length !== 0)
        {
            for (var x = 0; x < formData.add_eskiz.length; x++)
            {
                fData.append('add_eskiz', formData.add_eskiz[x]);
            }
        }
        if(inputValues?.general_plan !== formData.general_plan[0] && formData.general_plan[0]) {
            fData.append('general_plan', formData.general_plan[0])
        }
        if(inputValues?.et_file !== formData.et_file[0] && formData.et_file[0])
        {
            fData.append('et_file', formData.et_file[0])
        }

        const { errors, success, data } = await fetchData(projectApi.put(id, fData, config)).catch(error => error)
        if (success) handleDone ? handleDone() : navigate(-1)
        else return errors
    }

    const handleBasicType = async (value) =>
    {
        const { success, data } = await fetchData(companyApi.barilgaTorolByZoriulalt(value))
        if(success)
        {
            setBarilgaTorol(dataToValueLabel(data, 'id', 'name', ['name', 'code'], undefined, ' - '))
        }
    }

    const handleBarilgaTorol = async (value) =>
    {
        const { success, data } = await fetchData(companyApi.barilgaZoriulaltByTorol(value))
        if (success)
        {
            setBarilgaZoriulalts(dataToValueLabel(data, 'id', 'name', ['name', 'code'], undefined, ' - '))
        }
    }

    const handleBarilgaZoriulalt = async (value) =>
    {
        const { success, data } = await fetchData(companyApi.measurementByBarZoriulalt(value))
        if (success)
        {
            setMeasurementValues(data)
        }
    }

    const setCompanyRegisterNumber = async (value) =>
    {
        if (value && value.length > 6)
        {
            let cdata = {
                'stateregnumber': value
            }
            const { success, data } = await fetchData(companyApi.getBarilgaService(cdata))
            if (success)
            {
                setCompanyRegisterResponse(data)
            }
        }
    }

    useEffect(
        async () =>
        {
            if(inputValues)
            {
                const values = {
                    ...inputValues,
                    unit1: inputValues.unit1.name,
                    unit2: inputValues.unit2.name,
                    unit3: inputValues.unit3.name,
                    parking_type: inputValues.parking_info.map((el) => el.parking_type),
                    construction: inputValues.construction.map((el) => el.id),
                    property_type: inputValues.property_info.map((el) => el.property_type),
                    budget_type: inputValues.bugdet_info.map((el) => el.budget_type),
                    sales_manager: inputValues.sales_manager?.id,
                    current_use: inputValues.current_use?.id,
                    barilgiin_zoriulalt: inputValues.barilgiin_zoriulalt?.id,
                    barilga_ajlin_torol: inputValues.barilga_ajlin_torol?.id,
                    tonoglol: inputValues.tonoglol,
                    omchiin_helber: inputValues.omch_helber,
                    is_end: isMap ? inputValues.is_end : isOld,
                    approval_date: new Date(inputValues.approval_date),
                    use_date: new Date(inputValues.use_date),
                    aorson_ognoo: new Date(inputValues.aorson_ognoo),
                    architecture_tolovlolt_datetime: inputValues.architecture_tolovlolt_datetime ? new Date(inputValues.architecture_tolovlolt_datetime) : null,
                    zurag_tosol_datetime: inputValues.zurag_tosol_datetime ? new Date(inputValues.zurag_tosol_datetime) : null,
                    barilga_ajlin_gerchilge_ogno: inputValues.barilga_ajlin_gerchilge_ogno ? new Date(inputValues.barilga_ajlin_gerchilge_ogno) : null,
                    barilga_ajlin_gerchilge_hugatsa: inputValues.barilga_ajlin_gerchilge_hugatsa ? new Date(inputValues.barilga_ajlin_gerchilge_hugatsa) : null,
                    barilga_ajlin_sungasan_ogno: inputValues.barilga_ajlin_sungasan_ogno ? new Date(inputValues.barilga_ajlin_sungasan_ogno) : null,
                    nemelt_orchlolt_ogno: inputValues.nemelt_orchlolt_ogno ? new Date(inputValues.nemelt_orchlolt_ogno) : null,
                    ashiglaltad_ognoo: inputValues.ashiglaltad_ognoo ? new Date(inputValues.ashiglaltad_ognoo) : null,
                    komis_ognoo: inputValues.komis_ognoo ? new Date(inputValues.komis_ognoo) : null,
                }

                if (inputValues.current_use?.id)
                {
                    await handleBasicType(inputValues.current_use?.id)
                    await handleBarilgaTorol(inputValues.barilgiin_zoriulalt.barilgiin_torol)
                    await handleBarilgaZoriulalt(inputValues.barilgiin_zoriulalt?.id)
                }

                setSelectedParking(inputValues.parking_info.map((el) => { return { value: el.parking_type, label: el.name } }))
                setSelectedPropertyType(inputValues.property_info.map((el) => { return { value: el.property_type, label: el.name } }))
                setSelectedBudget(inputValues.bugdet_info.map((el) => { return { value: el.budget_type, label: el.name} }))

                const parkingCount = inputValues?.parking_info?.map((element) =>
                {
                    let _key = 'parkingg_' + element.parking_type
                    return { [_key]: element.count }
                })

                const BudgetCount = inputValues?.bugdet_info?.map((element) =>
                {
                    let _key = 'budgetg_' + element.budget_type
                    return { [_key]: element.amount }
                })


                Object.assign(values, ...parkingCount)
                Object.assign(values, ...BudgetCount)
                Object.assign(values, { 'barilgiin_torol': inputValues?.barilgiin_zoriulalt?.barilgiin_torol })
                Object.assign(values, { 'barilgiin_zoriulalt': inputValues?.barilgiin_zoriulalt?.id })


                if (inputValues.unit_measurement_values)
                {
                    for (let item of inputValues.unit_measurement_values)
                    {
                        let name = `measurement-${item.unit_measurement_name}`
                        Object.assign(values, { [name]: item.value })
                    }
                }

                setRealInputValues(values)
                setUnits(
                    {
                        unit1: inputValues.unit1?.id,
                        unit2: inputValues.unit2?.id,
                        unit3: inputValues.unit3?.id
                    }
                )
            }
        },
        [inputValues]
    )

    const handleEtFile = async (e, { setValue }) =>
    {
        const fData = new FormData()
        fData.append("file", e.target.files[0])
        const { success, data } = await fetchData(unitApi.findUnit(fData))
        if(success)
        {

            setValue("unit1", data.unit1.name)
            setValue("unit2", data.unit2.name)
            setValue("unit3", data.unit3.name)

            setUnits({
                unit1: data.unit1.id,
                unit2: data.unit2.id,
                unit3: data.unit3.id,
            })
        }
    }

    const displayParkingNumber = useMemo(
        () =>
        {
            if(selectedParking.length === 0) return []
            let parkingInputs = []
            let exptraYups = {}
            parkingInputs =  selectedParking.map(
                (element) =>
                {
                    let _key = "parkingg_" + element.value
                    exptraYups[_key] = validates.positiveNumber
                    return {
                        inputType: "input",
                        registerName: _key,
                        type: "number",
                        label: {
                            text: element.label + ":",
                        },
                        placeholder: "Машины зогсоолын тоо",
                        disabled: isDetail || isLoading,
                        colMd: 12 / selectedParking.length <= 2 ? 2 : 12 / selectedParking.length
                    }
                }
            )
            validation = validation.concat(cYup.object().shape(exptraYups))
            parkingInputs.unshift({ component: <hr /> })
            parkingInputs.push({ component: <hr/> })
            return parkingInputs
        },
        [selectedParking, isLoading, isDetail]
    )

    const displayBudgetNumber = useMemo(
        () =>
        {
            if(selectedBudget.length === 0) return []
            let budgetInputs = []
            let exptraYups = {}
            budgetInputs =  selectedBudget.map(
                (element) =>
                {
                    let _key = "budgetg_" + element.value
                    exptraYups[_key] = validates.positiveNumber
                    return {
                        inputType: "input",
                        registerName: _key,
                        type: "number",
                        label: {
                            text: element.label + ":",
                        },
                        placeholder: "Хөрөнгө оруулалтын хэмжээ",
                        disabled: isDetail || isLoading,
                        colMd: 12
                    }
                }
            )
            validation = validation.concat(cYup.object().shape(exptraYups))
            budgetInputs.unshift({ component: <hr /> })
            budgetInputs.push({ component: <hr/> })
            return budgetInputs
        },
        [selectedBudget, isLoading, isDetail]
    )

    const displayMeasurement = useMemo(
        () =>
        {
            let measurementInputs = []

            if (measurementValues.length > 0)
            {
                for (let measurementData of measurementValues)
                {
                    measurementInputs.push(
                    {
                        inputType: "input",
                        registerName: `measurement-${measurementData}`,
                        type: "text",
                        label: {
                            text: measurementData + ":",
                        },
                        placeholder: `${measurementData} утгыг оруулна уу`,
                        disabled: isDetail || isLoading,
                        colMd: 6
                    })
                }
            }

            return measurementInputs
        },
        [measurementValues, isDetail]
    )

    const displayCompanyRegister = useMemo(
        () =>
        {
            let companyData = []

            if (Object.keys(companyRegisterResponse).length > 0)
            {
                companyData.push(
                {
                    value: companyRegisterResponse?.response?.result?.customername,
                    inputType: "input",
                    registerName: `company-name`,
                    type: "text",
                    label: {
                        text: 'Нэр' + ":",
                    },
                    disabled: true,
                    colMd: 6
                },)
            }

            return companyData
        },
        [companyRegisterResponse, isDetail]
    )

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                component: (
                    <div>
                        <h6>БАРИЛГЫН АЖЛЫГ ЭХЛҮҮЛЭХ, ҮРГЭЛЖЛҮҮЛЭХ ЗӨВШӨӨРӨЛТЭЙ ХОЛБООТОЙ МЭДЭЭЛЭЛ</h6>
                        <hr />
                    </div>
                )
            },
            isMap
            &&
                {
                    inputType: "radio",
                    type: "radio",
                    registerName: "is_end",
                    disabled: isDetail || isLoading,
                    options: [
                        { value: false, label: "Шинэ төсөл", defaultChecked: true },
                        { value: true, label: "Хуучин төсөл"},
                    ],
                    label: {
                        text: "Төслийн төрөл:",
                        empty: false,
                    },
                },
            {
                inputType: "input",
                registerName: "project_name",
                type: "text",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "input",
                registerName: "approval",
                type: "text",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "datePicker",
                registerName: "approval_date",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "input",
                registerName: "price_mkv",
                type: "number",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "input",
                registerName: "gadna_tohijiltiin_talbai",
                type: "number",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "input",
                registerName: "huuhdiin_togloomiin_talbai",
                type: "number",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "select",
                registerName: "tonoglol",
                disabled: isDetail || isLoading,
                options: TONOGLOL,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "select",
                registerName: "parking_type",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6,
                options: parkingType,
                isMulti: true,
                onChange: evnet => setSelectedParking(evnet)
            },
            {
                inputType: "select",
                registerName: "construction",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6,
                options: constructions,
                isMulti: true,
            },
            ...displayParkingNumber,
            {
                inputType: "select",
                registerName: "current_use",
                disabled: isDetail || isLoading,
                options: currentUsage,
                colMd: !hasTitle ? 12 : 6,
                onChange: event => handleBasicType(event.value),
            },
            {
                inputType: "select",
                registerName: "barilgiin_torol",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6,
                options: barilgaTorol,
                onChange: event => handleBarilgaTorol(event.value),
                placeholder: 'Барилгын төрөл сонгоно уу'
            },
            {
                inputType: "select",
                registerName: "barilgiin_zoriulalt",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6,
                options: barilgaZoriulalts,
                onChange: event => handleBarilgaZoriulalt(event.value),
            },
            ...displayMeasurement,
            // {
            //     inputType: "input",
            //     registerName: "total_ail",
            //     type: "number",
            //     disabled: isDetail || isLoading,
            //     colMd: !hasTitle ? 12 : 6
            // },
            // {
            //     inputType: "input",
            //     registerName: "total_service_area",
            //     type: "number",
            //     disabled: isDetail || isLoading,
            //     colMd: !hasTitle ? 12 : 6
            // },
            {
                inputType: "input",
                registerName: "description",
                type: "textArea",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "fileUpload",
                registerName: "et_file",
                fileType: 'geojson',
                onChange: handleEtFile,
                colMd: !hasTitle ? 12 : 6,
                disabled: isDetail || isLoading,
            },
            {
                inputType: "fileUpload",
                registerName: "general_plan",
                fileType: 'geojson',
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "input",
                registerName: "unit1",
                disabled: true,
                colMd: 4
            },
            {
                inputType: "input",
                registerName: "unit2",
                disabled: true,
                colMd: 4
            },
            {
                inputType: "input",
                registerName: "unit3",
                disabled: true,
                colMd: 4
            },
            {
                inputType: "multiImageUpload",
                registerName: "eskiz_info",
                disabled: isDetail || isLoading,
                colMd: 12,
            },
            {
                inputType: "input",
                registerName: "unit4",
                type: "textArea",
                disabled: isDetail || isLoading,
                colMd: 12,
                label: {
                    text: 'Гудамж/хороолол:',
                    empty: false
                },
            },
            {
                inputType: "input",
                registerName: "unit5",
                type: "textArea",
                disabled: isDetail || isLoading,
                colMd: 12
            },
            {
                inputType: "datePicker",
                registerName: "use_date",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            },
            isOld
            ? {
                inputType: "datePicker",
                registerName: "aorson_ognoo",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6
            }
            : {},
            {
                inputType: "select",
                registerName: "tuluwluguu_heregjilt",
                disabled: isDetail || isLoading,
                options: HEREGJILT,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "select",
                registerName: "sales_manager",
                sort: true,
                disabled: isDetail || isLoading,
                options: allSales,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "select",
                registerName: "omchiin_helber",
                disabled: isDetail || isLoading,
                options: OMCH_HELBER,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "select",
                registerName: "property_type",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6,
                sort: true,
                options: propertyType,
                isMulti: true,
            },
            {
                inputType: "select",
                registerName: "budget_type",
                disabled: isDetail || isLoading,
                options: budgetTypes,
                sort: true,
                colMd: !hasTitle ? 12 : 6,
                isMulti: true,
                onChange: event => setSelectedBudget(event)
            },
            {
                component: (
                    <div>
                        <hr />
                        <h6>Архитектур төлөвлөлтийн даалгавар</h6>
                    </div>
                )
            },
            {
                inputType: "datePicker",
                registerName: "architecture_tolovlolt_datetime",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6,
                label: {
                    text: 'Огноо:',
                    empty: false
                },
            },
            {
                inputType: "input",
                registerName: "architecture_tolovlolt_number",
                type: "text",
                disabled: isDetail || isLoading,
                colMd: 6,
                label: {
                    text: 'Дугаар:',
                    empty: false
                },
            },
            {
                component: (
                    <div>
                        <hr />
                        <h6>Зураг төслийн магадлалын ерөнхий дүгнэлт</h6>
                    </div>
                )
            },
            {
                inputType: "datePicker",
                registerName: "zurag_tosol_datetime",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6,
                label: {
                    text: 'Огноо:',
                    empty: false
                },
            },
            {
                inputType: "input",
                registerName: "zurag_tosol_number",
                type: "text",
                disabled: isDetail || isLoading,
                colMd: 6,
                label: {
                    text: 'Дугаар:',
                    empty: false
                },
            },
            {
                component: (
                    <hr />
                )
            },
            {
                inputType: "select",
                registerName: "tolovshil_angilal",
                disabled: isDetail || isLoading,
                options: TOLOVSHIL,
                colMd: !hasTitle ? 12 : 6
            },
            {
                inputType: "input",
                registerName: "heseg_blok",
                type: "text",
                disabled: isDetail || isLoading,
                colMd: 6,
            },
            {
                inputType: "select",
                registerName: "huchin_chadal_hemjih_negj",
                disabled: isDetail || isLoading,
                options: HUCH_CHADAL_NEGJ,
                colMd: !hasTitle ? 12 : 6,
            },
            {
                inputType: "input",
                registerName: "huchin_chadal",
                type: "number",
                disabled: isDetail || isLoading,
                colMd: 6,
            },
            {
                inputType: "radio",
                type: "checkbox",
                registerName: "tehnik_tsahilgan_hangamj",
                disabled: false,
                readOnly: false,
                options: [
                    { label: "Цахилгаан хангамжтай эсэх", defaultChecked: false },
                ],
                colMd: 6,
                label: {
                    text: "Цахилгаан хангамжтай эсэх",
                    empty: false,
                },
            },
            {
                inputType: "radio",
                type: "checkbox",
                registerName: "tehnik_dulan_hangamj",
                options: [
                    { label: "Дулаан хангамжтай эсэх", defaultChecked: false },
                ],
                colMd: 6,
            },
            {
                inputType: "radio",
                type: "checkbox",
                registerName: "tehnik_us_hangamj",
                options: [
                    { label: "Ус хангамж, ариутгах татуургатай эсэх", defaultChecked: false },
                ],
                colMd: 6,
            },
            {
                inputType: "radio",
                type: "checkbox",
                registerName: "tehnik_holbo_dohiolol",
                options: [
                    { label: "Холбоо, дохиололтой эсэх", defaultChecked: false },
                ],
                colMd: 6,
            },
            {
                inputType: "input",
                registerName: "company_register_number",
                type: "text",
                disabled: isDetail || isLoading,
                colMd: 6,
                onChange: event => setCompanyRegisterNumber(event.target.value)
            },
            ...displayCompanyRegister,
            // {
            //     inputType: "select",
            //     registerName: "barilga_zahialagch",
            //     disabled: isDetail || isLoading,
            //     async: true,
            //     colMd: 6,
            //     colMd: !hasTitle ? 12 : 6,
            //     options: companies,
            //     helperText: "Эхний 2 тэмдэгтийг оруулна уу",
            // },
            // {
            //     inputType: "select",
            //     registerName: "barilga_zohiogch",
            //     disabled: isDetail || isLoading,
            //     async: true,
            //     colMd: 6,
            //     colMd: !hasTitle ? 12 : 6,
            //     options: companies,
            //     helperText: "Эхний 2 тэмдэгтийг оруулна уу",
            // },
            {
                inputType: "input",
                registerName: "zuragiin_shipr",
                type: "text",
                disabled: isDetail || isLoading,
                colMd: 6,
            },
            // {
            //     inputType: "select",
            //     registerName: "barilga_ajil_guitsetgegch",
            //     disabled: isDetail || isLoading,
            //     async: true,
            //     colMd: 6,
            //     colMd: !hasTitle ? 12 : 6,
            //     options: companies,
            //     helperText: "Эхний 2 тэмдэгтийг оруулна уу",
            // },
            // {
            //     inputType: "select",
            //     registerName: "barilga_tuslan_guitsetgegch",
            //     disabled: isDetail || isLoading,
            //     async: true,
            //     colMd: 6,
            //     colMd: !hasTitle ? 12 : 6,
            //     options: companies,
            //     helperText: "Эхний 2 тэмдэгтийг оруулна уу",
            // },
            {
                component: (
                    <div>
                        <hr />
                        <h6>Барилгын ажлын зөвшөөрлийн гэрчилгээ олгосон</h6>
                    </div>
                )
            },
            {
                inputType: "datePicker",
                registerName: "barilga_ajlin_gerchilge_ogno",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6,
                label: {
                    text: 'Огноо:',
                    empty: false
                },
            },
            {
                inputType: "input",
                registerName: "barilga_ajlin_gerchilge_dugar",
                type: "text",
                disabled: isDetail || isLoading,
                colMd: 6,
                label: {
                    text: 'Дугаар:',
                    empty: false
                },
            },
            {
                inputType: "datePicker",
                registerName: "barilga_ajlin_gerchilge_hugatsa",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6,
                label: {
                    text: 'Хүчинтэй хугацаа:',
                    empty: false
                },
            },
            {
                component: (
                    <div>
                        <hr />
                        <h6>Барилгын ажлын зөвшөөрлийн хугацаа сунгасан</h6>
                    </div>
                )
            },
            {
                inputType: "datePicker",
                registerName: "barilga_ajlin_sungasan_ogno",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6,
                label: {
                    text: 'Огноо:',
                    empty: true
                },
            },
            {
                inputType: "input",
                registerName: "barilga_ajlin_sungasan_tailbar",
                type: "textArea",
                disabled: isDetail || isLoading,
                colMd: 12,
                label: {
                    text: 'Тайлбар:',
                    empty: true
                },
            },
            {
                component: (
                    <div>
                        <hr />
                        <h6>Нэмэлт өөрчлөлт</h6>
                    </div>
                )
            },
            {
                inputType: "datePicker",
                registerName: "nemelt_orchlolt_ogno",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6,
                label: {
                    text: 'Огноо:',
                    empty: true
                },
            },
            {
                inputType: "input",
                registerName: "nemelt_orchlolt_tailbar",
                type: "textArea",
                disabled: isDetail || isLoading,
                colMd: 12,
                label: {
                    text: 'Тайлбар:',
                    empty: true
                },
            },
            {
                inputType: "select",
                registerName: "zovshorol_olgoson_baigullaga_select",
                disabled: isDetail || isLoading,
                async: true,
                colMd: 6,
                options: companies,
                helperText: "Эхний 2 тэмдэгтийг оруулна уу",
                placeholder: 'Барилгын ажил эхлүүлэх, үргэлжлүүлэх зөвшөөрөл олгосон байгууллагын нэр:',
                label: {
                    text: 'Барилгын ажил эхлүүлэх, үргэлжлүүлэх зөвшөөрөл олгосон байгууллагын нэр:',
                    empty: false
                }
            },
            {
                inputType: "input",
                registerName: "zovshorol_olgoson_baigullaga",
                type: "text",
                disabled: true,
                colMd: 6,
                placeholder: ' ',
                label: {
                    text: 'Барилгын ажил эхлүүлэх, үргэлжлүүлэх зөвшөөрөл олгосон байгууллагын нэр хадгалагдсан утга:',
                    empty: true
                }
            },
            ...displayBudgetNumber,
            {
                component: (
                    <div>
                        <h6>БАРИЛГА, БАЙГУУЛАМЖИЙГ АШИГЛАЛТАНД ОРУУЛСАН МЭДЭЭЛЭЛ</h6>
                        <hr />
                    </div>
                )
            },
            {
                inputType: "select",
                registerName: "barilga_ajlin_torol",
                disabled: isDetail || isLoading,
                options: barilgaAjlinTorol,
                colMd: !hasTitle ? 12 : 6,
            },
            {
                inputType: "datePicker",
                registerName: "ashiglaltad_ognoo",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6,
                label: {
                    text: 'Ашиглалтад орсон огноо:',
                    empty: false
                },
            },
            {
                inputType: "select",
                registerName: "barilga_omchlogch_select",
                disabled: isDetail || isLoading,
                async: true,
                colMd: 6,
                colMd: !hasTitle ? 12 : 6,
                options: companies,
                helperText: "Эхний 2 тэмдэгтийг оруулна уу",
            },
            {
                inputType: "input",
                registerName: "barilga_omchlogch",
                type: "text",
                disabled: true,
                colMd: 6,
                placeholder: ' '
            },
            {
                inputType: "select",
                registerName: "komis_zohion_baigulagch",
                disabled: isDetail || isLoading,
                colMd: 6,
                colMd: !hasTitle ? 12 : 6,
                options: govorg,
            },
            {
                component: (
                    <div>
                        <h6>Комиссын үйл ажиллагаа</h6>
                        <hr />
                    </div>
                )
            },
            {
                inputType: "datePicker",
                registerName: "komis_ognoo",
                disabled: isDetail || isLoading,
                colMd: !hasTitle ? 12 : 6,
                label: {
                    text: 'Огноо:',
                    empty: false
                },
            },
            {
                inputType: "input",
                registerName: "komis_dugaar",
                type: "text",
                disabled: isDetail || isLoading,
                colMd: 6,
            },
            {
                component: (
                    <div>
                        <hr />
                    </div>
                )
            },
            {
                component: <NemdegInput returnData={setKomisPeoples} firstDatas={komisPeoples} />
            },
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isDetail || isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
            className: isDetail ? "d-none" : ""
        },
        validate: {
            validateMode: "all",
            yupValidate: validation,
        },
    }

    const body = <CForm inputValues={realInputValues} form={form}/>

    return (
        hasTitle
        ?
            <BoxBackground title={isCreate ? isOld ? 'Хуучин төсөл үүсгэх' : "Шинэ төсөл үүсгэх" : 'Төсөл засах'} body={body}/>
        :
            body
    );
}

export default ProjectAction;
