import useMapContext from 'hooks/useMapContext';
import { useEffect, useState } from 'react';
import { useMemo } from 'react';

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import CButton from 'components/main/Button';

import css from '../style.module.css'

import MkvFilter from './MkvFilter';
import PriceMinMax from './PriceMinMax'

import { styleOfPrice } from 'hooks/map/helper'
import { objectToQuery, objectToQueryString } from 'utils/browser';

const wmsURL = `${process.env.REACT_APP_SERVER_URL}/price/`
function Population(props)
{
    const { allFilters, resetAllFilters, cMapState, setSelectedFeature } = useMapContext()
    const initFilters = {
        zoriulalt: allFilters['zoriulalt'] ?? 'apartment',
        borluulalt: allFilters['borluulalt'] ?? 'hudaldah',
        category: allFilters['category'] ?? 'public',
    }

    const SELECT_2 = ['category']

    const [ filters, setFilters ] = useState(initFilters)
    const [ fns, setFns ] = useState({
        tabName: "first"
    })

    const zoriulalts = [
        {
            name: "Орон сууц",
            key: "apartment",
        },
        {
            name: "Үйлчилгээний орон сууц",
            key: "customer",
        },
        {
            name: "Оффис",
            key: "office",
        },
    ]

    const borluulalt = [
        {
            name: "Худалдах",
            key: "hudaldah",
        },
        {
            name: "Түрээс(сарын)",
            key: "turees",
        },
    ]

    const category = [
        {
            name: "Нийтийн байр",
            key: "public",
        },
        {
            name: "Орон сууц",
            key: "apartment",
        },
    ]

    const handleFilter = (value, key) =>
    {
        setFilters(
            (prev) =>
            (
                {
                    ...prev,
                    [key]: value
                }
            )
        )
        allFilters[key] = value
        filterToMap()
    }

    useEffect(
        () =>
        {
            resetFilters('first')
            filterToMap()
        },
        []
    )

    const zoriulaltChoies = useMemo(
        () =>
        {
            return zoriulalts.map(
                (zoriulalt, idx) =>
                {
                    return (
                        <div key={idx} className={`${css.choicesItem} ${filters.zoriulalt === zoriulalt.key ? css.active : ""}`} onClick={() => handleFilter(zoriulalt.key, 'zoriulalt')}>
                            {zoriulalt.name}
                        </div>
                    )
                }
            )
        },
        [zoriulalts, filters]
    )

    const borluulaltTurul = useMemo(
        () =>
        {
            return borluulalt.map(
                (borluulalt, idx) =>
                {
                    return (
                        <div key={idx} className={`${css.choicesItem} ${filters.borluulalt === borluulalt.key ? css.active : ""}`} onClick={() => handleFilter(borluulalt.key, 'borluulalt')}>
                            {borluulalt.name}
                        </div>
                    )
                }
            )
        },
        [borluulalt, filters]
    )

    const categoryChoies = useMemo(
        () =>
        {
            return category.map(
                (category, idx) =>
                {
                    return (
                        <div key={idx} className={`${css.choicesItem} ${filters.category === category.key ? css.active : ""}`} onClick={() => handleFilter(category.key, 'category')}>
                            {category.name}
                        </div>
                    )
                }
            )
        },
        [category, filters]
    )

    /** Борлуулалтын feature дээр дарах үед графикийг харуулах */
    const handleClickBorlulalt = (event) =>
    {
        const hasSelected = event.selected.length
        if (hasSelected)
        {
            const selectedFeatures = event.selected
            const selectedFeature = selectedFeatures[0]
            let extent = selectedFeature.getGeometry().getExtent();
            let selectedCoordinate = cMapState.getCenterFromExtent(extent);
            cMapState.displayOverlay(selectedCoordinate)
            setSelectedFeature({
                feature: {
                    feature: selectedFeature,
                    tabName: fns.tabName,
                },
                state: "price-filter"
            })
        }
        else {
            cMapState.displayOverlay(undefined)
        }
    }

    const handleHoverBorlulalt = (event) =>
    {
        const hasSelected = event.selected.length
        const hasDeSelected = event.deselected.length
        if (hasDeSelected && !hasSelected)
        {
            cMapState.displayOverlay(undefined)
        }

        if (hasSelected)
        {
            const selectedFeatures = event.selected
            if (selectedFeatures.length)
            {
                const selectedFeature = selectedFeatures[0]
                let extent = selectedFeature.getGeometry().getExtent();
                let selectedCoordinate = cMapState.getCenterFromExtent(extent);
                cMapState.displayOverlay(selectedCoordinate)
                setSelectedFeature({ feature: selectedFeature, state: "price-hover" })
            }
        }
    }

    const filterToMap = () =>
    {
        cMapState.displayOverlay(undefined)
        const clqFilter = ``
        const borluulaltObj = {
            "hudaldah": "hudaldah",
            "turees": "turees",
        }

        const borluulaltTurul = allFilters['borluulalt']
        const reverse = {
            "hudaldah": "turees",
            "turees": "hudaldah"
        }

        if (borluulaltTurul)
        {
            let beforeShowed = false
            cMapState
                .map
                .getLayers()
                .forEach(
                    (layer, idx) =>
                    {
                        if (layer && layer.get("name") && layer.get("name").startsWith("price-"))
                        {
                            //  өмнөхөө нуух
                            layer.setVisible(false)
                        }
                        if (layer && layer.get("name") === `price-${borluulaltTurul}`)
                        {
                            //  өмнөхөө харуулах
                            layer.setVisible(true)
                            beforeShowed = true
                        }
                    }
                )

            if (!beforeShowed)
            {
                const layerType = borluulaltObj[borluulaltTurul]
                const sourceAu1 = cMapState.wfsFromBackup(wmsURL, layerType, 'unit1')
                const sourceAu2 = cMapState.wfsFromBackup(wmsURL, layerType, 'unit2')
                const sourceAu3 = cMapState.wfsFromBackup(wmsURL, layerType, 'unit3')
                const { vectorLayer: vlAu1, vectorSource: vsAu1 } = cMapState.addVectorLayer([], sourceAu1, `price-${borluulaltTurul}`, (...args) => styleOfPrice(fns.tabName, ...args), { minZoom: 1, maxZoom: 12 })
                const { vectorLayer: vlAu2, vectorSource: vsAu2 } = cMapState.addVectorLayer([], sourceAu2, `price-${borluulaltTurul}`, (...args) => styleOfPrice(fns.tabName, ...args), { minZoom: 12, maxZoom: 16 })
                const { vectorLayer: vlAu3, vectorSource: vsAu3 } = cMapState.addVectorLayer([], sourceAu3, `price-${borluulaltTurul}`, (...args) => styleOfPrice(fns.tabName, ...args), { minZoom: 16, maxZoom: 22 })
                // cMapState.createSelect(
                //     "hover",
                //     handleHoverBorlulalt,
                //     null,
                //     [vlAu1, vlAu2, vlAu3],
                //     [],
                //     `${borluulaltTurul}-hover`
                // )
                cMapState.createSelect(
                    "click",
                    handleClickBorlulalt,
                    null,
                    [vlAu1, vlAu2, vlAu3],
                    [],
                    borluulaltTurul
                )
            }
        }
        else
        {
            const layerType = "borluulah"

            let beforeShowed = false
            cMapState
                .map
                .getLayers()
                .forEach(
                    (layer, idx) =>
                    {
                        if (layer && layer.get("name") && layer.get("name").startsWith("price-"))
                        {
                            //  өмнөхөө нуух
                            layer.setVisible(false)
                        }
                        if (layer && layer.get("name") === `price-${layerType}`)
                        {
                            //  өмнөхөө харуулах
                            layer.setVisible(true)
                            beforeShowed = true
                        }
                    }
                )

            if (!beforeShowed)
            {
                const sourceAu1 = cMapState.wfsFromBackup(wmsURL, layerType, 'unit1')
                const sourceAu2 = cMapState.wfsFromBackup(wmsURL, layerType, 'unit2')
                const sourceAu3 = cMapState.wfsFromBackup(wmsURL, layerType, 'unit3')
                const { vectorLayer: vlAu1, vectorSource: vsAu1 } = cMapState.addVectorLayer([], sourceAu1, `price-${layerType}`, (...args) => styleOfPrice(fns.tabName, ...args), { minZoom: 1, maxZoom: 12 })
                const { vectorLayer: vlAu2, vectorSource: vsAu2 } = cMapState.addVectorLayer([], sourceAu2, `price-${layerType}`, (...args) => styleOfPrice(fns.tabName, ...args), { minZoom: 12, maxZoom: 16 })
                const { vectorLayer: vlAu3, vectorSource: vsAu3 } = cMapState.addVectorLayer([], sourceAu3, `price-${layerType}`, (...args) => styleOfPrice(fns.tabName, ...args), { minZoom: 16, maxZoom: 22 })
                // cMapState.createSelect(
                //     "hover",
                //     handleHoverBorlulalt,
                //     null,
                //     [vlAu1, vlAu2, vlAu3],
                //     [],
                //     `${layerType}-hover`
                // )
                cMapState.createSelect(
                    "click",
                    handleClickBorlulalt,
                    null,
                    [vlAu1, vlAu2, vlAu3],
                    [],
                    layerType
                )
            }

        }

    }

    const resetFilters = (name) =>
    {
        Object.keys(initFilters).map(
            (key, idx) =>
            {
                //  Борлуулах үнэ
                if (name === "second")
                {
                    if (SELECT_2.includes(key))
                    {
                        allFilters[key] = initFilters[key]
                    }
                }
                //  Борлуулсан үнэ
                else {
                    if (!SELECT_2.includes(key))
                    {
                        allFilters[key] = initFilters[key]
                    }
                }
            }
        )
    }

    const handleTabSelect = (name) =>
    {
        resetAllFilters()
        setFilters(initFilters)
        resetFilters(name)
        fns.tabName = name
        filterToMap()
    }

    /** Борлуулcан үнийн сонголтоор хайх  */
    const handleSearchFirst = () =>
    {
        cMapState.displayOverlay(undefined)
        const notFilterKey = ['borluulalt']
        const _filters = {}
        const layerType = allFilters['borluulalt']
        Object.keys(allFilters)
            .filter(key => !notFilterKey.includes(key))
            .map(
                (key, idx) =>
                {
                    _filters[key] = allFilters[key]
                }
            )

        const mainLayers = ['price-hudaldah', 'price-turees']
        const layerName = `price-${layerType}-filter`
        cMapState
            .map
            .getLayers()
            .forEach(
                (layer, idx) =>
                {
                    if (layer && layer.get("name") && layer.get("name").startsWith('price-') && layer.get("name").endsWith('-filter'))
                    {
                        //  өмнө нь хайгдсан layer ийг устгах н
                        cMapState.map.removeLayer(layer)
                    }
                    if (layer && layer.get("name") && mainLayers.includes(layer.get("name")) && layer.getVisible())
                    {
                        //  үндсэн том давхаргыг нуух
                        layer.setVisible(false)
                    }
                }
            )

        const extraQuery = objectToQueryString(_filters)
        const sourceAu1 = cMapState.wfsFromBackup(wmsURL, layerType, 'unit1', `&${extraQuery}`)
        const sourceAu2 = cMapState.wfsFromBackup(wmsURL, layerType, 'unit2', `&${extraQuery}`)
        const sourceAu3 = cMapState.wfsFromBackup(wmsURL, layerType, 'unit3', `&${extraQuery}`)
        const { vectorLayer: vlAu1, vectorSource: vsAu1 } = cMapState.addVectorLayer([], sourceAu1, layerName, (...args) => styleOfPrice(fns.tabName, ...args), { minZoom: 1, maxZoom: 12 })
        const { vectorLayer: vlAu2, vectorSource: vsAu2 } = cMapState.addVectorLayer([], sourceAu2, layerName, (...args) => styleOfPrice(fns.tabName, ...args), { minZoom: 12, maxZoom: 16 })
        const { vectorLayer: vlAu3, vectorSource: vsAu3 } = cMapState.addVectorLayer([], sourceAu3, layerName, (...args) => styleOfPrice(fns.tabName, ...args), { minZoom: 16, maxZoom: 22 })
        // cMapState.createSelect(
        //     "hover",
        //     handleHoverBorlulalt,
        //     null,
        //     [vlAu1, vlAu2, vlAu3],
        //     [],
        //     `${borluulaltTurul}-hover`
        // )
        cMapState.createSelect(
            "click",
            handleClickBorlulalt,
            null,
            [vlAu1, vlAu2, vlAu3],
            [],
            borluulaltTurul
        )
    }

    /** Борлуулах үнийн сонголтоор хайх  */
    const handleSearchSecond = () =>
    {
        cMapState.displayOverlay(undefined)
        const mainLayers = "borluulah"
        const layerName = `price-${mainLayers}-filter`
        cMapState
            .map
            .getLayers()
            .forEach(
                (layer, idx) =>
                {
                    if (layer && layer.get("name") && layer.get("name").startsWith('price-') && layer.get("name").endsWith('-filter'))
                    {
                        //  өмнө нь хайгдсан layer ийг устгах н
                        cMapState.map.removeLayer(layer)
                    }
                    if (layer && layer.get("name") && layer.get("name") === `price-${mainLayers}` && layer.getVisible())
                    {
                        //  үндсэн том давхаргыг нуух
                        layer.setVisible(false)
                    }
                }
            )

        const extraQuery = objectToQueryString(allFilters)
        const sourceAu1 = cMapState.wfsFromBackup(wmsURL, mainLayers, 'unit1', `&${extraQuery}`)
        const sourceAu2 = cMapState.wfsFromBackup(wmsURL, mainLayers, 'unit2', `&${extraQuery}`)
        const sourceAu3 = cMapState.wfsFromBackup(wmsURL, mainLayers, 'unit3', `&${extraQuery}`)
        const { vectorLayer: vlAu1, vectorSource: vsAu1 } = cMapState.addVectorLayer([], sourceAu1, layerName, (...args) => styleOfPrice(fns.tabName, ...args), { minZoom: 1, maxZoom: 12 })
        const { vectorLayer: vlAu2, vectorSource: vsAu2 } = cMapState.addVectorLayer([], sourceAu2, layerName, (...args) => styleOfPrice(fns.tabName, ...args), { minZoom: 12, maxZoom: 16 })
        const { vectorLayer: vlAu3, vectorSource: vsAu3 } = cMapState.addVectorLayer([], sourceAu3, layerName, (...args) => styleOfPrice(fns.tabName, ...args), { minZoom: 16, maxZoom: 22 })
        // cMapState.createSelect(
        //     "hover",
        //     handleHoverBorlulalt,
        //     null,
        //     [vlAu1, vlAu2, vlAu3],
        //     [],
        //     `${layerName}-hover`
        // )
        cMapState.createSelect(
            "click",
            handleClickBorlulalt,
            null,
            [vlAu1, vlAu2, vlAu3],
            [],
            layerName
        )
    }

    /** Борлуулах үнийн сонголтыг болиулах  */
    const handleClearFirst = () =>
    {
        cMapState.displayOverlay(undefined)
        fns.mkv.minRef.current.value = null
        fns.mkv.maxRef.current.value = null
        fns.mkv.selectRef.current.value = ""
        fns.price.minRef.current.value = null
        fns.price.maxRef.current.value = null
        const layerType = fns.tabName === "first" ? `price-${allFilters['borluulalt']}` : "borluulah"
        cMapState
            .map
            .getLayers()
            .forEach(
                (layer, idx) =>
                {
                    if (layer && layer.get("name") && layer.get("name").startsWith('price-') && layer.get("name").endsWith('-filter'))
                    {
                        //  хайгдсан хайлтын layer ийг устгах н
                        cMapState.map.removeLayer(layer)
                    }
                    if (layer && layer.get("name") === layerType)
                    {
                        //  үндсэн том давхаргыг харуулах
                        layer.setVisible(true)
                    }
                }
            )
    }

    /** Борлуулсан үнийн сонголтыг болиулах  */
    const handleClearSecond = () =>
    {
        handleClearFirst()
    }

    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="first" onSelect={handleTabSelect}>
            <Row>
                <Col sm={12}>
                    <Nav variant="pills" className="row">
                        <Nav.Item className='col-6'>
                            <Nav.Link eventKey="first" className={"filter-price-tab"}>Борлуулсан үнэ</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='col-6'>
                            <Nav.Link eventKey="second" className={"filter-price-tab"}>Борлуулж буй үнэ</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={12}>
                    <Tab.Content>
                         <Tab.Pane eventKey="first">

                            <div className={css.choiceContainer}>
                                <h6>Борлуулалтын хэлбэр</h6>
                                <div className={css.choices}>
                                    {borluulaltTurul}
                                </div>
                            </div>

                            <hr />

                            <MkvFilter fns={fns} />

                            <hr />

                            <PriceMinMax fns={fns} />

                            <div className='d-flex justify-content-end align-items-end mt-2'>
                                <CButton
                                    variant="primary"
                                    className="ms-2"
                                    onClick={handleSearchFirst}
                                >
                                    Хайх
                                </CButton>
                                <CButton
                                    variant="secondary"
                                    className="ms-2"
                                    style={
                                        {
                                            margin: "10px"
                                        }
                                    }
                                    onClick={handleClearFirst}
                                >
                                    Болиулах
                                </CButton>
                            </div>

                        </Tab.Pane>
                        <Tab.Pane eventKey="second">

                            <div>
                                <PriceMinMax fns={fns} />
                            </div>

                            <div className='d-flex justify-content-end align-items-end mt-2'>
                                <CButton
                                    variant="primary"
                                    className="ms-2"
                                    onClick={handleSearchSecond}
                                >
                                    Хайх
                                </CButton>
                                <CButton
                                    variant="secondary"
                                    className="ms-2"
                                    style={
                                        {
                                            margin: "10px"
                                        }
                                    }
                                    onClick={handleClearSecond}
                                >
                                    Болиулах
                                </CButton>
                            </div>

                        </Tab.Pane>
                    </Tab.Content>

                    <div>
                        <ul className={css.sanamj}>
                            {/* <li className={css.sanamjLi}>
                                Тайлбар:
                                <ol>
                                    Засар захиргаагааг насны ангилалаар харах нь
                                </ol>
                            </li> */}
                            <li className={css.sanamjLi}>
                                <i className='fa fa-info-circle text-primary me-1'></i>
                                Санамж:
                                <ol>
                                    Сүүлийн 4 жилийн үнийн мэдээллийг харуулж байгаа болно
                                </ol>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </Tab.Container>
    );
}

export default Population;
