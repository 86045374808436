import {
    PERM_IS_FROM_BUILDING,
    PERM_IS_CITIZEN,
    PERM_IS_FROM_PROJECT,
    PERM_IS_NEWS_ADMIN,
    PERM_IS_ONLY_READ,
    PERM_IS_READ_GRAPHIC,
    PERM_IS_ORG_ADMIN,
    PERM_IS_SUPER_USER,
    PERM_IS_WILL_RENT,
    PERM_IS_ZB_OF_RENT,
    PERM_IS_GOVERNMENT_ORGANIZATION,
    PERM_IS_FINANCIAL_REGULATORY_COMMISSION_SUPER,
    PERM_IS_FINANCIAL_EMPLOYEE,
    PERM_IS_TOSK,
    PERM_IS_MATERIAL,
    PERM_IS_BHTUV,
} from "."

export const adminForNews = [PERM_IS_NEWS_ADMIN]
export const companyPageRoles = [PERM_IS_ORG_ADMIN, PERM_IS_ONLY_READ, PERM_IS_FROM_BUILDING, PERM_IS_FROM_PROJECT]
export const companyWithoutProject = [PERM_IS_ONLY_READ, PERM_IS_FROM_BUILDING, PERM_IS_FROM_PROJECT]
export const adminPageRoles = [PERM_IS_SUPER_USER]
export const materialPageRoles = [PERM_IS_MATERIAL]
export const govPageRoles = [PERM_IS_GOVERNMENT_ORGANIZATION, PERM_IS_ONLY_READ]
export const userPageRoles = [PERM_IS_CITIZEN]
export const financialAdminPageRoles = [PERM_IS_FINANCIAL_REGULATORY_COMMISSION_SUPER]
export const finanialEmployeePageRoles = [PERM_IS_FINANCIAL_EMPLOYEE]
export const toskPageRoles = [PERM_IS_TOSK]
export const bhTuvPageRoles = [PERM_IS_BHTUV]
