import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import BuildingCards from "./Cards"

import useApi from "hooks/useApi";
import useLoader from "hooks/useLoader";
import useMapContext from "hooks/useMapContext";

import PieChartMap from "../PieChartMap";
import PopulationInfo from "./Population";
import PriceGraph from "./PriceGraph";
import PriceHover from "./PriceHover";

import css from './style.module.css'

import { objectToQuery } from "utils/browser";
import ProjectStep from "./ProjectStep";

export default function OverlayBuilding({ cMap, auSource })
{

    const { selectedFeature: clickedApartment, allFilters } = useMapContext()
    const [ buildings, setBuildings ] = useState([])

    const apartments = clickedApartment?.feature || {}
    const state = clickedApartment?.state || {}

    const { Loader, fetchData, isLoading } = useLoader({})
    const mapApi = useApi().map
    const location = useLocation()

    /**  Барилгын датаг apartment ээс хайж олох  */
    const getBuildings = async (apartmentId) =>
    {
        const query = objectToQuery(allFilters)
        const { success, data, error } = await mapApi.getBuildings(apartmentId, `?${query}`).catch(err => err)
        if (success)
            return data
        return []
    }

    /** clustered feature ээс building ийн мэдээллүүдийг цуглуулж state -д оноох */
    const getBuildingsFromClFeature = async () =>
    {
        let foundBuildings = []
        /** дарагдсан cluster feature доторх feature үүдийн apartment уудын жагсаалт */
        /** Тухайн apartment -д байгаа барилгуудын жагсаалтыг авах */
        for (const index in apartments)
        {
            const currentFeature = apartments[index]
            const buildingsOfApart = await getBuildings(currentFeature.apartmentId)
            foundBuildings.push({ data: buildingsOfApart, id: currentFeature.apartmentId })

        }
        setBuildings(foundBuildings)
    }

    useEffect(
        () =>
        {
            if (!apartments) return
            if (Object.keys(apartments).length === 0) return
            if (!['population', "price-filter", 'project-step'].includes(state))
            {
                fetchData(getBuildingsFromClFeature())
            }
        },
        [apartments]
    )

    if (isLoading)
    {
        return (
            <div>
                {Loader}
            </div>
        )
    }

    if (state === 'population')
    {
        return <PopulationInfo feature={apartments} />
    }

    if (state === "pie")
    {
        return <PieChartMap auCode={clickedApartment?.codes} auSource={auSource} />
    }

    if (state === "project-step")
    {
        return <ProjectStep feature={apartments} />
    }

    if (state === "price-filter")
    {
        return <PriceGraph feature={apartments} />
    }

    if (state === "price-hover")
    {
        return <PriceHover feature={apartments} />
    }

    if (!buildings || buildings.length === 0)
    {
        return (
            <div className="text-center text-secondary">
                Мэдээлэл олдсонгүй
            </div>
        )
    }

    if (state === 'clicked')
    {
        return (
            <div>
                <BuildingCards buildings={buildings}/>
            </div>
        )
    }
}
