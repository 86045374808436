import css from './style.module.css'

export default function Container({ title, handleClose, children })
{
    return (
        <div>
            <div className="title">
                {title} <div className={css.customTimes} onClick={handleClose}></div>
            </div>
            <div className="body">
                {children}
            </div>
        </div>
    )
}
