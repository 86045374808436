
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import BuildingCreate from './BuildingAction/BuildingCreate';
import BuildingAction from './BuildingAction';

import Building from '.';


const BuildingCrudMain = () => {
    return (
        <Routes>
            <Route path="/" element={<Building/>} />
            <Route path="/apart/:apartId/" element={<BuildingAction/>} />
            <Route path="/apart/:apartId/create" element={<BuildingCreate/>} />
        </Routes>
    );
}

export default BuildingCrudMain;
