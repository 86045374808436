import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SelectRoomAction from './SelectRoomAction'
import SelectRoom from '.';

import BoxBackground from 'components/special/BoxBackground';

const SelectRoomCrudMain = () => {
    return (
        <Routes>
            <Route path="/*" element={<BoxBackground title='Өрөөний сонголт' body={<SelectRoom />} />} />
            <Route path="/:id/" element={<SelectRoomAction />} />
        </Routes>
    );
}

export default SelectRoomCrudMain;
