import { useEffect, useState } from "react"

import css from '../../style.module.css'
import FilterOptions from "./FilterOptions"
const FilterButton = ({ idx, filter, handleFilterChange }) =>
{
    const [ showFilter, setShowFilter ] = useState({
        show: false,
        thId: ''
    })

    return (
		<div className={css.filterIconContainer}>
			<span onClick={() => setShowFilter({ show: !showFilter.show, thId: idx })} className={css.filterIcon}>
				<i className={`fas fa-sort-amount-down`}></i>
			</span>
			<div className={css.filterOptions}>
				<FilterOptions
					handleFilterChange={handleFilterChange}
					options={filter.filterOptions}
                    fieldName={filter.fieldName}
					idx={idx}
					thId={showFilter.thId}
					show={showFilter.show}
				/>
			</div>
		</div>
	)
}

export default FilterButton
