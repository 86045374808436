import React from 'react'

import css from './style.module.css'

// gr ийн голд нь гарчиг гаргаж харуулах
export default function HrText({ children })
{
    return (
        <div className={`${css.strike} my-3`}>
            <div className={css.body}>
                {children}
            </div>
        </div>
    )
}
