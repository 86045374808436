/**
 * ms to date
 * @param {int} ms ms ирнэ
 * @return date
 */
export function msToDate(ms) {
    //  NOTE:  date нь заавал блалал
    const date = Date.now() //

    //  TODO:  date ийг local уу оруулах.

    return
}

/**
 * Өнөөдрийн онийг авах нь
 */
export function getTodayYear()
{
    /** Өнөөөдөрийн он сар өдөр */
    const today = Date.now();
    /** Өнөөдрийн он */
    const year = new Date(today).getFullYear()
    return year
}

// Оруулсан date ийн өмнөх сарын эхлэх төгсгөлийг өгөх болно
export const getPrevMonthDate = (date=new Date()) =>
{
    let dates = {
        start: null,
        end: null
    }
    dates.end = new Date(date.getFullYear(), date.getMonth(), 0).getTime()
    dates.start = new Date(date.getFullYear(), date.getMonth() - 1, 1).getTime()
    return dates
}

// Оруулсан date ийн өмнөх 7хоногийн эхлэх төгсгөлийг өгөх болно( Даваа, баасан )
export function getLastWeeksDate(date=new Date()) {
    let dates = {
        start: null,
        end: null,
    }
	dates.end = date.setTime(date.getTime() - (date.getDay() ? date.getDay() : 7) * 24 * 60 * 60 * 1000)
	dates.start = date.setTime(date.getTime() - 6 * 24 * 60 * 60 * 1000)

    return dates
}

// Оруулсан date ийн өмнөх өдрйиг болно
export function getPreviousDay(date = new Date()) {
	const previous = new Date(date.getTime())
	previous.setDate(date.getDate() - 1)

	return previous
}

// Одоо оруулсан сарын эхлэх төгсгөл
export const getCurrentMonthDate = (date = new Date()) =>
{
    let dates = {
        start: null,
        end: null,
    }
    dates.start = new Date(date.getFullYear(), date.getMonth(), 1).getTime()
    dates.end = new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime()
    return dates
}

export const getCurrentWeekDate = (today = new Date()) =>
{
    let dates = {
        start: null,
        end: null,
    }
    dates.start = new Date(today.setDate(today.getDate() - today.getDay())).getTime()
    dates.end = new Date(today.setDate(today.getDate() - today.getDay() + 6)).getTime()
    return dates
}
