import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { searchParamsToObject } from 'utils/browser';
import label from 'utils/validate/label';

import css from '../style.module.css'

const ProjectResTabs = ({ setKey }) => {

    const [ searchParams, setSearchParams ] = useSearchParams()

    const handleOnSelect = (value) =>
    {
        setKey(value)
        setSearchParams({ tab: value })
    }

    useEffect(
        () =>
        {
            if(!searchParamsToObject(searchParams).tab)
            {
                setSearchParams({ tab: 'project' })
            }
        },
        []
    )

	return (
		<div className={css.tabsContainer}>
            <div className={`${css.tab} ${searchParamsToObject(searchParams).tab === 'project' ? css.activeTab : ""}`} onClick={ () => handleOnSelect('project')}>{label['project']}</div>
            <div className={`${css.tab} ${searchParamsToObject(searchParams).tab === 'residence' ? css.activeTab : ""}`} onClick={ () => handleOnSelect('residence')}>{label['residence']}</div>
        </div>
	)
}

export default ProjectResTabs
