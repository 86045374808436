import React, { createContext, useState } from 'react';

import useToggler from 'hooks/useToggler';
import useMobile from 'hooks/useMobile';

export const HeaderContext = createContext()

export default function HeaderContextProvider(props)
{
    const [ showSidebar, setDisplaySideBar ] = useState(false);

    const { isMobile } = useMobile(800)

    const { Toggler, togglerState, setTogglerState } = useToggler({
        togglerOn: <i className="fa fa-bars" style={{ fontSize: "20px", color: "#005b9a" }}></i>,
        togglerOff: <i className="fa fa-bars" style={{ fontSize: "20px", color: "#005b9a" }}></i>,
        displayToggler: showSidebar && isMobile,
    });

    return (
        <HeaderContext.Provider value={{ Toggler, togglerState, setTogglerState, setDisplaySideBar }}>
            {props.children}
        </HeaderContext.Provider>
    );
}
