import { useEffect, useRef, useState } from 'react'

import useApi from 'hooks/useApi'
import useModal from 'hooks/useModal'
import useAuth from 'hooks/useAuth'

import Datatable from 'components/main/Datatable'
import AccessIcons from 'components/special/AccessIcons'
import OverlayElement from 'components/main/OverlayElement'

import BuildingAction from './action'

import { DESC, ASC } from 'components/main/Table/Thead';

import { dataToValueLabel } from 'utils/format';
import label from 'utils/validate/label'

import BuildingImageAction from 'pages/Company/Project/ProjectTabs/Building/BuildingAction/BuildingImageAction'
// import Borluulalt from 'pages/Company/Project/ProjectTabs/Apartment/Borluulalt'
import useCState from 'hooks/useCState'
import UpdateMany from 'pages/Company/Project/ProjectTabs/Building/BuildingAction/UpdateMany'


const sequenceColumn =
{
    headerName: "#",
    startNumber: 1,
    order: false,
    width: 50
}
export default function BuldingList({ projectId, apartId })
{
    const bApi = useApi().building
    const dataTableRef = useRef(null)
    const { userDetail } = useAuth()

    const { setDisplay, setShow, show } = useModal()

    const [ residences, setResidences ] = useState([]);
    const residenceApi = useApi().residence

    useEffect(
        () =>
        {
            residenceApi.getAll(projectId, apartId)
                .then(
                    ({ success, data, error }) =>
                    {
                        setResidences(dataToValueLabel(data, 'id', "full_name"))
                    }
                )
        },
        []
    )

    /** Хүснэгтний утгыг авах */
    const getData = async () =>
    {
        if (!dataTableRef.current) return
        dataTableRef.current.firstGet = true
        dataTableRef.current.reData({}, null, [projectId, apartId], bApi.get)
    }

    /** засвар оруулах нь */
    const handleClick = (row) =>
    {
        setDisplay(
            {
                header: {
                    title: `${row.door_number} тоот засах нь`,
                },
                body: (
                    <BuildingAction {...row} residences={residences} refresh={getData} />
                ),
                size: "xl",
                footer: {
                    show: false,
                },
            }
        )
    }

    let columns = [
        {
            field: "door_number",
            order: true,
            fieldComponent: (fieldValue, index, row) => (
                <div className='d-flex justify-content-evenly'>
                    <OverlayElement
                        text='Зураг оруулах'
                    >
                        <div className='cursorPointer' style={{ color: 'purple' }} onClick={ () => displayImageAction(row.id) }><i className="far fa-image"></i></div>
                    </OverlayElement>
                    {/* <OverlayElement
                        text='Борлуултын түүх'
                    >
                        <div className='cursorPointer' style={{ color: 'green' }} onClick={ () => displayBorluulaltModal(row.id) }><i className="fas fa-history"></i></div>
                    </OverlayElement> */}
                    <span className='tablerow-detail' onClick={() => handleClick(row)} >{fieldValue}</span>
                </div>
            ),
        },
        {
            field: "input_number",
            order: true,
            fieldComponent: (fieldValue, index, row) => (
                fieldValue
            ),
        },
        {
            field: "residence",
            headerName: "Өрөөний ЗБ",
            order: true,
            fieldComponent: (fieldValue, index, row) => {
                return fieldValue?.total_mkv ? `${fieldValue?.total_mkv}мкв \n ${fieldValue?.total_room} өрөө` : ""
            },
			searchField: false,
        },
        {
            field: "state_name",
            headerName: "Төлөв",
            order: true,
			searchField: false,
            fieldComponent: (fieldValue, index, row) => {
                return fieldValue

            },
            filter: {
                filterOptions: [
                    {
                        value: '2',
                        label: 'Зарагдсан',
                    },
                    {
                        value: '1',
                        label: 'Зарагдаагүй',
                    },
                    {
                        value: '3',
                        label: 'Захиалгат',
                    },
                ],
                fieldName: 'c_state'
            },
        },
        userDetail?.user_role?.is_tosk &&
        {
            field: "is_rent",
            order: true,
            fieldComponent: (fieldValue, index, row) => <AccessIcons isTrue={fieldValue} />,
            searchField: false
        }
    ]

    /** Modal гаргаж ирэх funct */
    const displayImageAction = (id) =>
    {
        setDisplay(
            {
                header:
                {
                    title: label['building'] + ' зураг оруулах',
                },
                body: <BuildingImageAction projectId={projectId} buildingId={id} />,
                size: "xl",
                footer:
                {
                    show:true
                },
                variant: 'main'
            }
        )
    }

    // const displayBorluulaltModal = (id) =>
    // {
    //     setDisplay(
    //         {
    //             header:
    //             {
    //                 title: label['building'] + ' зураг оруулах',
    //             },
    //             body: <Borluulalt projectId={projectId} apartId={apartId} id={id} />,
    //             size: "xl",
    //             footer:
    //             {
    //                 show:true
    //             },
    //             variant: 'main'
    //         }
    //     )
    // }

    const handleClick2= () =>
    {
        setDisplay(
            {
                header: {
                    title: "Олноор нь өөрчлөх",
                },
                body: <UpdateMany getData={getData} projectId={projectId} apartId={apartId} setShow={setShow}/>,
                size: "xl",
            }
        )
    }

    const { checkedList } = useCState()

    const checkBoxOptions = {
        show: true,
        onClick: () => handleClick2(),
        btnText: `Засах (${checkedList.isAll && checkedList.unChecked.length === 0 ? 'бүгд' : checkedList.checked.length === 0 ? checkedList.total - checkedList.unChecked.length : checkedList.checked.length})`,
        rowsElement: 'id'
    }

    return (
        <div>
            <Datatable
                dRef={dataTableRef}
                columns={columns}
                sequenceColumn={sequenceColumn}
                checkBoxOptions={checkBoxOptions}
                tableOptions={{
                    defaultSort: {
                        field: "created_at",
                        type: DESC,
                    },
                }}
                request={{
                    axios: bApi.get,
                    params: [projectId, apartId],
                }}
                paginationConfig={{
                    container: {
                        isShow: true,
                        className: ""
                    },
                    checkboxActionBtn: {
                        className: "checkboxActionBtn col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-3",
                        isShow: true,
                        position: "center",
                        order: 2,
                    },
                    containerPages: {
                        className: "containerPages col-sm-2 col-md-2 col-lg-2 col-xl-2 mt-3",
                        isShow: true,
                        position: "center",
                        order: 2,
                    },
                    containerPerPage: {
                        className: "containerPerPage col-sm-12 col-md-12 col-lg-12 col-xl-12",
                        isShow: false,
                        position: "center",
                        order: 2,
                    },
                    containerPagination: {
                        className: "containerPagination col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-3",
                        isShow: true,
                        position: "center",
                        order: 3,
                    },
                    containerJumpPage: {
                        className: "containerJumpPage col-sm-5 col-md-5 col-lg-5 col-xl-5 mt-3",
                        isShow: true,
                        position: "center",
                        order: 2,
                    },
                }}
                perPage={50}
                isFirstGetData={true}
            />
        </div>
    )
}
