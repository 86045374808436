import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';
import useToggler from 'hooks/useToggler';
import useAuth from 'hooks/useAuth';
import useMapContext from 'hooks/useMapContext';

import CreateButton from 'components/main/CudButtons/Create';
import CButton from 'components/main/Button';
import DeleteButton from 'components/main/CudButtons/Delete';
import UpdateButton from 'components/main/CudButtons/Update';
import Datatable from 'components/main/Datatable';

import { tableColumns1, tableColumns2, tableColumns3, tableColumns4, tableColumns5, tableColumns6 } from "./TableColumns"

import { finanialEmployeePageRoles } from 'utils/consts/roles';
import CXZMap from './Map';
import { ASC, DESC } from 'components/main/Table/Thead';
import { timeZoneToDateString } from 'utils/format';

const SanhvvTable = () => {

    const [ tableColumns, setTableColumns ] = useState({})
    const [ tableName, setTableName ] = useState('')

    const dataTableRef = useRef(null)
    const { tableId } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const { userDetail } = useAuth()
    const { getSHZOldGeoids } = useMapContext()

    const { Toggler, togglerState, setTogglerState } = useToggler({
        togglerOn: (
            <CButton onClick={() => setTogglerState(v => !v)}>
                <i className="fal fa-table"></i>
            </CButton>
        ),
        togglerOff: (
            <CButton onClick={() => setTogglerState(v => !v)}>
                <i className="fal fa-map"></i>
            </CButton>
        ),
    });

    const { Loader, fetchData, isLoading } = useLoader({})
    const api = useApi()
    const buildingTableApi = api.buildingTable
    const buildingTableSourse = api.source

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50,
    }

    useEffect(
        () =>
        {
            setTogglerState(false)
            //data gaa awah
            return () =>
            {
                if (!dataTableRef.current) return
                dataTableRef.current.cancel()
            }
        },
        [location]
    )

    const getColumns = (name) =>
    {
        let columns = {
            'buy-sell': {
                columns: tableColumns1,
                title: 'ҮЛ ХӨДЛӨХ ЭД ХӨРӨНГӨ, ТҮҮНТЭЙ ХОЛБООТОЙ ЭРХИЙГ ХУДАЛДАХ, ХУДАЛДАН АВАХ, ШИЛЖҮҮЛЭХ ҮЙЛ АЖИЛЛАГААНЫ ЗУУЧЛАЛЫН МЭДЭЭ'
            },
            'turees': {
                columns: tableColumns2,
                title: 'ҮЛ ХӨДЛӨХ ЭД ХӨРӨНГӨ, ТҮҮНТЭЙ ХОЛБООТОЙ ЭРХИЙГ ТҮРЭЭСЛҮҮЛЭХ, ТҮРЭЭСЛЭХ, ХӨЛСЛӨХ, ХӨЛСЛҮҮЛЭХ ҮЙЛ АЖИЛЛАГААНЫ ЗУУЧЛАЛЫН МЭДЭЭ'
            },
            // 'zassan': {
            //     columns: tableColumns3,
            //     title: 'ҮЛ ХӨДЛӨХ ЭД ХӨРӨНГИЙГ ХУДАЛДАН АВЧ, ЗАСВАРЛАН БОРЛУУЛАХ ҮЙЛЧИЛГЭЭНИЙ МЭДЭЭ'
            // },
            // 'menegment': {
            //     columns: tableColumns4,
            //     title: 'ҮЛ ХӨДЛӨХ ЭД ХӨРӨНГИЙН МЕНЕЖМЕНТИЙН ҮЙЛЧИЛГЭЭНИЙ МЭДЭЭ'
            // },
            // 'busad': {
            //     columns: tableColumns5,
            //     title: 'ҮЛ ХӨДЛӨХ ЭД ХӨРӨНГӨ ЗУУЧЛАЛЫН ҮЙЛ АЖИЛЛАГААНД ХАМААРАХ БУСАД ҮЙЛЧИЛГЭЭНИЙ МЭДЭЭ',
            //     notMap: true,
            // },
            // 'zohih-guilgee': {
            //     columns: tableColumns6,
            //     title: 'МЭДЭЭЛБЭЛ ЗОХИХ ГҮЙЛГЭЭ, ХАРИЛЦАГЧИЙН БҮРТГЭЛИЙН МЭДЭЭ',
            //     notMap: true,
            // },
        }
        return columns?.[name]
    }

   /** Хүснэгтний утгыг авах */
    const getData = async () =>
    {
        if (!dataTableRef.current) return
        dataTableRef.current.firstGet = true
        dataTableRef.current.reData({}, null, [], buildingTableApi[tableId].get)
    }

    useEffect(
        () =>
        {
            setTableColumns(getColumns(tableId)?.columns)
            setTableName(tableId)
            getData()
            if (!getColumns(tableId)?.notMap)
            {
                getSHZOldGeoids(tableId)
            }
        },
        [tableId]
    )

    /** Устгах нь */
    const handleDelete = async (id) =>
    {
        await fetchData(buildingTableApi[tableName].delete(id))
        getData()
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${tableName}/${item.id}/`)} needRoles={finanialEmployeePageRoles}/>
                        <DeleteButton onClick={!isLoading && (() => handleDelete(item.id))} needRoles={finanialEmployeePageRoles}/>
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    const toggle = useMemo(
        () =>
        {
            return Toggler
        },
        [Toggler]
    )

    const dipslayTable = useMemo(
        () =>
        {
            if(tableName === '') return
            if(tableName === undefined) return
            return (
                <Datatable
					dRef={dataTableRef}
                    // hasSearch={false}
					tableId="headerTable"
					downloadAble={true}
					excelInfo={{
						title: "Хэвлэсэн огноо",
						date: timeZoneToDateString(Date.now()),
					}}
                    tableOptions={{
                        defaultSort: {
                            field: "created_at",
                            type: ASC,
                        },
                    }}
                    printHeader={`
                        <div style="display: flex; flex-direction: column; margin: 10px">
                            <div style="justify-content: center; align-items: center; flex: 1; width: 650px; margin: auto;">
                                <h5 style="text-align: center;">${getColumns(tableName)?.title}</h5>
                                <h6 style="text-align: center;">"${userDetail?.employee?.company_name || ""}"</h6>
                            </div>
                            <div style="text-align: end; margin-right: 10px;">
                                ${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}
                            </div>
                        </div>
                    `}
					columns={tableColumns?.columns || []}
					colSpanHeader={tableColumns?.colSpanHeader || []}
					sequenceColumn={sequenceColumn}
					extraColumns={extraColumns}
					request={{
						axios: buildingTableApi[tableName].get,
						params: [],
                        source: buildingTableSourse
					}}
					isFirstGetData={true}
				/>
            )
        },
        [tableName, toggle, Toggler, userDetail]
    )

    return (
		<Row>
			<div className="text-center mb-2 mt-2 fs-15" style={{ fontWeight: "500", maxWidth: "600px", margin: "0 auto" }}>
				<span>{getColumns(tableName)?.title}</span>
			</div>
			<Col sm={12}>
                {
                    !getColumns(tableName)?.notMap
                    &&
                        <div className='floatRight mb-2'>
                            {toggle}
                        </div>
                }
                {
                    !togglerState
                    &&
                        <CreateButton
                            btnClassName="floatRight mb-2"
                            onClick={() => navigate(`${tableName}/create/`)}
                            needRoles={finanialEmployeePageRoles}
                        />
                }
			</Col>
			<Col>
                {
                    togglerState
                    ?
                        <CXZMap />
                    :
                        dipslayTable
                }
			</Col>
		</Row>
	)
}

export default SanhvvTable;
