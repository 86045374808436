import React, { useEffect ,useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Badge } from 'react-bootstrap';

import DeleteButton from 'components/main/CudButtons/Delete';
import ShowNotification from './ShowNotification';

import useApi from 'hooks/useApi';
import useModal from 'hooks/useModal';
import useLoader from 'hooks/useLoader';

import { timeZoneToDateString } from 'utils/format';
import { userPageRoles } from 'utils/consts/roles';

import css from './style.module.css'
import './style.css'
import OverlayElement from 'components/main/OverlayElement';
import DataNotFound from 'components/main/DataNotFound';

export const typeDisplay = {
    "ЧУХАЛ": ['warning', 'dark'],
    "ЯЛСАН": ['success', 'light'],
    "ЭНГИЙН": ['primary', 'light'],
}

const UserNotification = () => {

    const [ showDropDown, setShowDropDown ] = useState(false)
    const [ showTrash, setShowTrash ] = useState(false)
    const [ myData, setData ] = useState([])
    const [ newNotif, setNewNotif ] = useState([])
    const [ isAll, setIsAll ] = useState(true)
    const [ limit, setLimit ] = useState(3)
    const [ loading, setLoading ] = useState(false)

    const navigate = useNavigate()

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: false, hasBackground: false })
    const notiApi = useApi().notification
    const { setDisplay, showWarning } = useModal()

    const getData = async () =>
    {
        const { success, data, errors } = await fetchData(notiApi.get()).catch(err => err)
        if(success)
        {
            setData(data.sort((function(a, b){return new Date(b.notification_info.created_at) - new Date(a.notification_info.created_at)})))
            setNewNotif(data.sort((function(a, b){return new Date(b.notification_info.created_at) - new Date(a.notification_info.created_at)})).filter( e => e.state_display === "Шинэ" ))
        }
    }

    const deleteAll = async () =>
    {
        const { success, data, errors } = await fetchData(notiApi.deleteAll()).catch(err => err)
        if(success)
        {
            await getData()
            setShowDropDown(true)
        }
    }

    const readAll = async () =>
    {
        const { success, data, errors } = await fetchData(notiApi.readAll()).catch(err => err)
        if(success)
        {
            await getData()
            setShowDropDown(true)
        }
    }

    useEffect(async () => {
		await getData()
	}, [showDropDown])

    const deleteNot = async (id) =>
    {
        await fetchData(notiApi.delete(id))
        await getData()
        setShowDropDown(true)
    }

    const displayDetail = async (el) =>
    {
        const { success, data } = await fetchData(notiApi.getOne(el.id))
        await getData()
        setDisplay(
            {
                header:
                {
                    title: "Мэдэгдлийн дэлгэрэнгүй",
                },
                body: <ShowNotification el={data} />,
                size: "lg",
                footer:
                {
                    show: false
                }
            }
        )
    }

    const hideDot = async (id) =>
    {
        await fetchData(notiApi.getOne(id))
        await getData()
        setShowDropDown(true)
    }

    const displayNotification = useMemo(
        () =>
        {
			if (isAll)
				return myData.map((el, idx) => {
					if (idx < limit)
						return (
							<div key={idx} className="d-flex align-items-center">
								<Dropdown.Item
									style={{ position: "relative" }}
									onMouseEnter={() => setShowTrash(el.id)}
									onMouseLeave={() => setShowTrash("")}>
									<div className={css.dot}>
										{el.state_display === "Шинэ" ? (
											<OverlayElement text="Уншсан болгох">
												<div className={css.status} onClick={() => hideDot(el.id)}></div>
											</OverlayElement>
										) : null}
									</div>
									<div className={css.notificationItem} onClick={() => displayDetail(el)}>
										<div>
											<span className={css.notificationTitle}>
												{el.notification_info.title}{" "}
												<Badge pill text={typeDisplay[el.type_display][1]} bg={typeDisplay[el.type_display][0]}>
													{el.type_display}
												</Badge>
											</span>

											<span className={css.notificationTime}>
												<i className="far fa-clock"></i> {timeZoneToDateString(el.notification_info.created_at)}
											</span>
										</div>
									</div>
									{showTrash === el.id && (
										<DeleteButton
											className={`${css.trashButton} ${css.trash}`}
											hasToolTip={false}
											onClick={() => deleteNot(el.id)}
											needRoles={userPageRoles}
										/>
									)}
								</Dropdown.Item>
							</div>
						)
				})
			if (!isAll)
				return newNotif.map((el, idx) => {
					if (el.state_display === "Шинэ" && idx < limit)
						return (
							<div key={idx} className="d-flex align-items-center">
								<Dropdown.Item
									style={{ position: "relative" }}
									onMouseEnter={() => setShowTrash(el.id)}
									onMouseLeave={() => setShowTrash("")}>
									<div className={css.dot}>
										{el.state_display === "Шинэ" ? (
											<OverlayElement text="Уншсан болгох">
												<div className={css.status} onClick={() => hideDot(el.id)}></div>
											</OverlayElement>
										) : null}
									</div>
									<div className={css.notificationItem} onClick={() => displayDetail(el)}>
										<div>
											<span className={css.notificationTitle}>
												{el.notification_info.title}{" "}
												<Badge pill text={typeDisplay[el.type_display][1]} bg={typeDisplay[el.type_display][0]}>
													{el.type_display}
												</Badge>
											</span>

											<span className={css.notificationTime}>
												<i className="far fa-clock"></i> {timeZoneToDateString(el.notification_info.created_at)}
											</span>
										</div>
									</div>
									{showTrash === el.id && (
										<DeleteButton
											className={`${css.trashButton} ${css.trash}`}
											hasToolTip={false}
											onClick={() => deleteNot(el.id)}
											needRoles={userPageRoles}
										/>
									)}
								</Dropdown.Item>
							</div>
						)
				})
		},
        [myData, showTrash, isAll, limit, newNotif]
    )

    const displayNewNumber = useMemo(
        () =>
        {
            if(myData.length === 0) return
            return myData.filter(elemnt => elemnt.state_display === "Шинэ").length

        },
        [myData]
    )

    const seeAll = () =>
    {
        navigate('/user/notification/')
        setShowDropDown(false)
    }

    const readMore = () =>
    {
        setLoading(true)
        setTimeout(
            () =>
            {
                setLoading(false)
                setLimit(limit + 3)
            },
            500
        )
    }

    const toggleIsAll = (boolean) =>
    {
        setIsAll(boolean)
        setLimit(3)
    }

    /** товч дарахад эхлээд modal харуулж баталгаажуулах */
    const handleModal = (text, onClick) =>
    {
        showWarning(
            {
                type: "warning",
                question: text,
                onClick: onClick,
            }
        )
    }

    return (
        <div className="position-relative noti">
            <Dropdown show={showDropDown} >
                <Dropdown.Toggle onClick={() => setShowDropDown(!showDropDown)} variant="ghost" id="dropdown-basic">
                    <i className="far fa-bell text-darkBlue fs-18"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className={css.desc}>
                        Мэдэгдлүүд
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className={css.buttons}>
                            <div onClick={() => toggleIsAll(true)} className={isAll ? css.active : ""}>Бүгд</div>
                            <div onClick={() => toggleIsAll(false)} className={!isAll ? css.active : ""}>Уншаагүй</div>
                        </div>
                        <div className={'d-flex'}>
                            <OverlayElement text="Бүгдийн устгах">
                                <div onClick={() => handleModal('Бүх мэдэгдлүүдийг устгахдаа итгэлтэй байна уу?', deleteAll)} className={css.gotoTable}><i className="fas fa-trash"></i></div>
                            </OverlayElement>
                            <OverlayElement text="Бүгдийн уншсан болгох">
                                <div onClick={() => handleModal('Бүх мэдэгдлүүдийг уншсан болгохдоо итгэлтэй байна уу?', readAll)} className={css.gotoTable}><i className="fas fa-envelope-open"></i></div>
                            </OverlayElement>
                            <OverlayElement text="Хүснэгтээр харах">
                                <div onClick={() => seeAll()} className={css.gotoTable}><i className="fas fa-external-link"></i></div>
                            </OverlayElement>
                        </div>
                    </div>
                    <div className={css.notifList}>
                    {
                        isAll
                        ?
                        myData.length === 0 ? <DataNotFound absolute={false} /> : displayNotification
                        :
                        newNotif.length === 0 ? <DataNotFound absolute={false} /> : displayNotification
                    }
                    </div>
                    {loading && Loader}
                    {isAll && myData.length > limit && <div className={`${css.seeAll} ${loading && css.disabled}`} onClick={() => readMore()} >Илүүг харах</div>}
                    {!isAll && newNotif.length > limit && <div className={`${css.seeAll} ${loading && css.disabled}`} onClick={() => readMore()} >Илүүг харах</div>}
                </Dropdown.Menu>
            </Dropdown>
            <div className={css.Badge}>
                <label>{displayNewNumber  || 0}</label>
            </div>
        </div>
    );
}

export default UserNotification;
