import { useMemo } from "react"
import { Col } from "react-bootstrap"

import CSelect from "components/main/Form/InputTypes/Select"

import { dataToValueLabel } from "utils/format"

const BuildingAnalytacSelects = ({ units, setSelectedUnits, selectedUnits, selectType, handleSelectType, sortTypes, setSortType, sortType, endDate, setEndDate, dateTypes, selectedDataType }) =>
{
    const unit2Options = useMemo(
        () =>
        {
            const foundUnit2 = units.find(
                (element) =>
                {
                    if(element.code === selectedUnits['unit1-code'])
                    {
                        return element
                    }
                }
            )
            return foundUnit2?.unit2
        },
        [selectedUnits, units]
    )

    const unit3Options = useMemo(
        () =>
        {
            const foundUnit3 = units.find(
                (element) =>
                {
                    if(element.code === selectedUnits['unit1-code'])
                    {
                        return element
                    }
                }
            )?.unit2?.find(
                (element) =>
                {
                    if(element.code === selectedUnits['unit2-code'])
                    {
                        return element
                    }
                }
            )
            return foundUnit3?.unit3
        },
        [selectedUnits, units]
    )

    const handleUnitSelect = (value, whatUnit) =>
    {
        if(whatUnit === "unit1-code") return setSelectedUnits({ [whatUnit]: value })
        if(whatUnit === "unit2-code") return setSelectedUnits({ "unit1-code": selectedUnits["unit1-code"], [whatUnit]: value })
        if(whatUnit === "unit3-code") return setSelectedUnits({ ...selectedUnits, [whatUnit]: value })
    }

    return (
        <>
            <Col className="mb-1" style={{ minWidth: '160px', padding: '2px' }}><CSelect onChange={(value) => handleUnitSelect(value.value, 'unit1-code')} nested={true} nestedValue={selectedUnits['unit1-code']} value={selectedUnits['unit1-code']} placeholder='Аймаг/хот' options={dataToValueLabel(units, 'code', 'name')} /></Col>
            <Col className="mb-1" style={{ minWidth: '160px', padding: '2px' }}><CSelect onChange={(value) => handleUnitSelect(value.value, 'unit2-code')} nested={true} nestedValue={selectedUnits['unit2-code']} value={selectedUnits['unit2-code']} placeholder='Сум/дүүрэг' options={dataToValueLabel(unit2Options, 'code', 'name')} /></Col>
            <Col className="mb-1" style={{ minWidth: '160px', padding: '2px' }}><CSelect onChange={(value) => handleUnitSelect(value.value, 'unit3-code')} nested={true} nestedValue={selectedUnits['unit3-code']} value={selectedUnits['unit3-code']} placeholder='Баг/хороо' options={dataToValueLabel(unit3Options, 'code', 'name')} /></Col>
            <Col className="mb-1" style={{ minWidth: '160px', padding: '2px' }}><CSelect onChange={handleSelectType} value={selectedDataType} options={selectType} /></Col>
            <Col className="mb-1" style={{ minWidth: '160px', padding: '2px' }}><CSelect onChange={(e) => setSortType(e.value)} value={sortType} options={sortTypes} /></Col>
            <Col className="mb-1" style={{ minWidth: '160px', padding: '2px' }}><CSelect onChange={(e) => setEndDate({ value: e.value, label: e.label })} value={endDate.value} options={dateTypes} /></Col>
            {/* <Col className="mb-1" style={{ minWidth: '160px', padding: '2px' }}><DatepickerInput onChange={handleDate} selectsRange={true} /></Col> */}
        </>
    )
}

export default BuildingAnalytacSelects
