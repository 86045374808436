import { Form } from "react-bootstrap"

import { setCookie } from "utils/browser"

export default function AlertBody({ cookieName })
{

    const handleCheck = (event) =>
    {
        if (cookieName)
        {
            setCookie(cookieName, event.target.checked)
        }
    }

    return (
        <div className="row">
            <div className="col-12 d-flex justify-content-center">
                <i className="fas fa-exclamation-triangle text-warning" style={{ fontSize: "50px" }}></i>
            </div>
            <div className="d-flex mt-3 text-center">
                Газрын зурган дээрээс байршилаа сонгож цэг хатгаад тухайн газрын барилгын мэдээллийг хайна
            </div>
            {
                cookieName
                &&
                    <div className="mt-3">
                        <Form.Check
                            onChange={handleCheck}
                            type={"checkbox"}
                            id="dont-show"
                            label={"Дахиж харуулахгүй"}
                        />
                    </div>
            }
        </div>
    )
}
