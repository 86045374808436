import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/** Хуудас руу анх хуудсыг дээшээ тулгах нь */
export default function GoTop({ children })
{

    const location = useLocation()

    useEffect(
        () =>
        {
            window.scrollTo(0, 0)
        },
        [location]
    )

    return children
}
