import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'

import Datatable from 'components/main/Datatable'
import DatepickerInput from 'components/main/Datepicker/DatepickerInput'
import { DESC } from 'components/main/Table/Thead'
import BoxBackground from 'components/special/BoxBackground'

import useApi from 'hooks/useApi'
import useLoader from 'hooks/useLoader'

import { ONE_MONTH_TO_MILLISECONDS } from 'utils/consts'
import { timeZoneToDateString } from 'utils/format'

const sequenceColumn =
{
    headerName: "#",
    startNumber: 1,
    order: false,
    width: 50
}

const ErrorAccess = () =>
{
    const dRef = useRef(null)
    const [ date, setDate ] = useState([Date.now() - 3 * ONE_MONTH_TO_MILLISECONDS, Date.now()])

    const accessApi = useApi().adminAccessFront

    const didmount = useRef(true)

     /** Хүснэгтийн багана */
     const columns = [
        {
            field: "code",
            headerName: "Алдаа №",
            order: true,
            fieldComponent: (fieldValue, index, row) => fieldValue
        },
        {
            field: "data",
            headerName: "Body",
            order: false,
            fieldComponent: (fieldValue, index, row) => fieldValue
        },
        {
            field: "datetime",
            headerName: "Огноо",
            order: true,
            fieldComponent: (fieldValue, index, row) => timeZoneToDateString(fieldValue),
            minWidth: 100
        },
        {
            field: "description",
            headerName: "Тайлбар",
            order: false,
            fieldComponent: (fieldValue, index, row) => fieldValue,
            minWidth: 150
        },
        {
            field: "method",
            headerName: "Method",
            order: true,
            fieldComponent: (fieldValue, index, row) => fieldValue,
            filter: {
                filterOptions: [
                    {
                        value: 'GET',
                        label: 'GET',
                    },
                    {
                        value: 'POST',
                        label: 'POST',
                    },
                    {
                        value: 'PUT',
                        label: 'PUT',
                    },
                    {
                        value: 'DELETE',
                        label: 'DELETE',
                    },
                ],
                fieldName: 'c_method'
            }
        },
        {
            field: "scheme",
            headerName: "Schema",
            order: true,
            fieldComponent: (fieldValue, index, row) => fieldValue
        },
        {
            field: "url",
            headerName: "Хандсан хаяг",
            order: true,
            fieldComponent: (fieldValue, index, row) => fieldValue,
        },
        {
            field: "headers",
            headerName: "Headers",
            order: false,
            fieldComponent: (fieldValue, index, row) => fieldValue,
        },
    ]

    const handleDate = (value) =>
    {
        if(new Date(value[0]).getTime() === 0 || new Date(value[1]).getTime() === 0) return
		setDate([
			new Date(value[0]).getTime(),
			new Date(value[1]).getTime() !== 0 ? new Date(value[1]).getTime() : Date.now(),
		])
    }

    /** Хүснэгтний утгыг авах */
    const getData = async () =>
    {
        if (!dRef.current) return
        dRef.current.firstGet = true
        dRef.current.reData({}, null, [date[0], date[1]])
    }

    useEffect(
        () =>
        {
            if (!didmount.current)
            {
                getData()
            }
            else {
                didmount.current = false
            }
        },
        [date]
    )

    return (
		<>
			<Row>
				<Col md={2}>
					<DatepickerInput maxDate={Date.now()} selected={date} onChange={handleDate} selectsRange={true} />
				</Col>
			</Row>
			<BoxBackground title="Систем дээр гарсан алдаа" className='mt-2'>
				<Datatable
					dRef={dRef}
					columns={columns}
					sequenceColumn={sequenceColumn}
					request={{
						axios: accessApi.getErrorPagination,
						params: [date[0], date[1]],
					}}
					tableOptions={{
						defaultSort: {
							type: DESC,
							field: "datetime",
						},
					}}
				/>
			</BoxBackground>
		</>
	)
}

export default ErrorAccess
