import useMapContext from "hooks/useMapContext"
import { useEffect, useState } from "react"

const Menu = ({
    onClick,
    onChange,
    fns,
    clearFilter,
}) =>
{

    const { units, getUnitsList } = useMapContext()

    const [ unit1Value, setUnit1Value ] = useState('')
    const [ unit2Value, setUnit2Value ] = useState('')
    const [ unit3Value, setUnit3Value ] = useState("")

    useEffect(
        () =>
        {
            if (!units.length)
            {
                getUnitsList()
            }
            fns.au = clear
        },
        []
    )

    const clear = () =>
    {
        setUnit1Value("")
        setUnit2Value("")
        setUnit3Value("")
        clearFilter()
    }

    const handleChange = (e) =>
    {
        const selectedValue = e.target.value
        setUnit1Value(selectedValue)
        setUnit2Value("")
        setUnit3Value("")
        if (onClick)
        {
            onClick({ value : selectedValue })
            onClick({ value : `2||||||` })
            onClick({ value : `3||||||` })
        }
    }

    const handleUnit2Change = (e) =>
    {
        const selectedValue = e.target.value
        setUnit2Value(selectedValue)
        setUnit3Value("")
        if (onClick) {
            onClick({ value : selectedValue })
            onClick({ value : `3||||||` })
        }
    }

    const handleUnit3Change = (e) =>
    {
        const selectedValue = e.target.value
        setUnit3Value(selectedValue)
        if (onClick)
            onClick({ value : selectedValue })
    }

    return (
        <div>
            <div className="form-group mb-3">
                <label htmlFor="unit1" className="form-label">Аймаг/хот:</label>
                <select id="unit1" className="form-select" placeholder="Аймаг/хот сонгох" value={unit1Value} onChange={handleChange}>
                    <option value="">--- Сонгох ---</option>
                    {
                        units.map(
                            (item, idx) =>
                            {
                                return (
                                    <option value={`${1}||${item.id}||${item.code}||${item.label}`} key={idx}>{item.label}</option>
                                )
                            }
                        )
                    }
                </select>
            </div>
            <div className="form-group mb-3">
                <label htmlFor="unit2" className="form-label">Сум/дүүрэг:</label>
                <select id="unit2" className="form-select" placeholder="Сум/дүүрэг сонгох" value={unit2Value} onChange={handleUnit2Change}>
                    <option value="">--- Сонгох ---</option>
                    {
                        units.find(e => e.id +"" === unit1Value.split("||")?.[1])?.nodes?.map(
                            (item, idx) =>
                            {
                                return (
                                    <option value={`${2}||${item.id}||${item.code}||${item.label}`} key={idx}>{item.label}</option>
                                )
                            }
                        )
                    }
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="unit3" className="form-label">Баг/хороо:</label>
                <select id="unit3" className="form-select" placeholder="Баг/хороо сонгох" value={unit3Value} onChange={handleUnit3Change}>
                    <option value="">--- Сонгох ---</option>
                    {
                        units.find(e => e.id +"" === unit1Value.split("||")?.[1])?.nodes?.find(e => e.id + ""=== unit2Value.split("||")?.[1])?.nodes?.map(
                            (item, idx) =>
                            {
                                return (
                                    <option value={`${3}||${item.id}||${item.code}||${item.label}`} key={idx}>{item.label}</option>
                                )
                            }
                        )
                    }
                </select>
            </div>
        </div>
    )
}
export default Menu
