import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import CButton from 'components/main/Button';
import CreateButton from 'components/main/CudButtons/Create';
import DeleteButton from 'components/main/CudButtons/Delete';
import UpdateButton from 'components/main/CudButtons/Update';
import GetOneDetail from 'components/special/GetOneDetail';
import AddMore from 'components/main/CudButtons/AddMore';
import { ASC, DESC } from 'components/main/Table/Thead';
import Datatable from 'components/main/Datatable';
import AccessIcons from 'components/special/AccessIcons';

import useLoader from 'hooks/useLoader';
import useModal from 'hooks/useModal';
import useApi from 'hooks/useApi';

import { companyPageRoles } from 'utils/consts/roles';
import { moneyFormat, timeZoneToDateString } from 'utils/format';

const Project = () => {

    const navigate = useNavigate();
    const dataTableRef = useRef(null)

    const [ excelProjectDate, setExcelProjectDate ] = useState('');
    const [ excelProjectUrl, setExcelProjectUrl ] = useState('');
    const [ excelTypeProjectUrl, setExcelTypeProjectUrl ] = useState('');

    const projectApi = useApi().project
    const reportApi = useApi().report

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })

    const { setDisplay } = useModal()

    useEffect(
        () =>
        {
            getExcelProject()
        },
        []
    )

    /** Modal гаргаж ирэх funct */
    const displayDetail = (id) =>
    {
        setDisplay(
            {
                header:
                {
                    title: "Төслийн дэлгэрэнгүй",
                },
                body: <GetOneDetail ignoreList={['unit1', 'unit2', 'unit3', 'hutulbur', 'budget_type', 'sales_manager', 'is_project']} api={projectApi.getOne(id)}/>,
                size: "lg",
                footer:
                {
                    show:false
                }
            }
        )
    }

    /** Хүснэгтийн багана */
    const columns =
    [
        {
            field: "project_name",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span className='tablerow-detail' onClick={() => navigate(`${row.id}/details/`)} >{fieldValue}</span>
        },
        // {
        //     field: "budget_amount",
        //     order: true,
        //     fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue || 0)}</span>
        // },
        {
            field: "total_ail",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "use_date",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "price_mkv",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue)}</span>
        },
        {
            field: "is_end",
            headerName: "Хуучин эсэх",
            order: true,
            // filter: {
            //     filterOptions: [
            //         {
            //             value: '0',
            //             label: 'Шинэ төсөл',
            //             default: true
            //         },
            //         {
            //             value: '1',
            //             label: 'Хуучин төсөл',
            //         },
            //     ],
            //     fieldName: 'c_is_end'
            // },
            fieldComponent: (fieldValue, index, row) => <span>{<AccessIcons isTrue={fieldValue}></AccessIcons>}</span>
        },
        {
            field: "created_at",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue)}</span>
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтний утгыг авах */
    const getData = async () =>
    {
        dataTableRef.current.firstGet = true
        dataTableRef.current.reData({}, null, [])
    }

    /** Хүснэгтийн мөрийн утгыг устгах */
    const deleteRow = async (id) =>
    {
        await fetchData(projectApi.delete(id))
        getData()
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.is_end ? "old" : "new"}/${item.id}/`)} needRoles={companyPageRoles} />
                        <DeleteButton className='me-2' onClick={() => deleteRow(item.id)} needRoles={companyPageRoles} />
                        <AddMore onClick={() => navigate(`${item.id}/details/`)} needRoles={companyPageRoles} />
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    async function getExcelProject()
    {
        const { errors, success, data } = await fetchData(reportApi.createExcelOne()).catch(error => error)
        if (success)
        {
            setExcelProjectUrl(data)
            if (data) setExcelProjectDate(`${data.substring(0, 4)}-${data.substring(4, 6)}-${data.substring(6, 8)} ${data.substring(8, 10)}:${data.substring(10, 12)}:${data.substring(12, 14)}`)
            else setExcelProjectDate('')
        }
    }

    async function updateExcelTypeProject()
    {
        const { errors, success, data } = await fetchData(reportApi.createExcelTypeProject()).catch(error => error)
        if (success)
        {
            setExcelTypeProjectUrl(data)
        }
        else return errors
    }

    async function updateExcelProject()
    {
        const { errors, success, data } = await fetchData(reportApi.createExcelProject()).catch(error => error)
        if (success)
        {
            setExcelProjectUrl(data)
            if (data) setExcelProjectDate(`${data.substring(0, 4)}-${data.substring(4, 6)}-${data.substring(6, 8)} ${data.substring(8, 10)}:${data.substring(10, 12)}:${data.substring(12, 14)}`)
            else setExcelProjectDate('')
        }
        else return errors
    }

    async function updateExcels()
    {
        await updateExcelTypeProject()
        await updateExcelProject()
    }

    return (
        <Row>
            <Col sm={12}>
                <CButton
                    className="floatRight ms-2 customMyButtons"
                    onClick={() => navigate('/company/project/')}
                >
                    <i className='fal fa-map'/>
                </CButton>
                <CreateButton btnClassName='floatRight ms-2 customMyButtons' title='Шинэ төсөл үүсгэх' onClick={() => navigate('new/create/')} needRoles={companyPageRoles} />
                <CreateButton btnClassName='floatRight customMyButtons' title='Хуучин төсөл үүсгэх' onClick={() => navigate('old/create/')} needRoles={companyPageRoles} />
            </Col>
            <Col>
                <Datatable
                    dRef={dataTableRef}
                    columns={columns}
                    sequenceColumn={sequenceColumn}
                    extraColumns={extraColumns}
                    tableOptions={
                        {
                            defaultSort: {
                                type: DESC,
                                field: "created_at"
                            }
                        }
                    }
                    request={
                        {
                            axios: projectApi.get,
                            params: []
                        }
                    }
                    isFirstGetData={true}
                />
            </Col>
            <hr />
            <Col xs={12}>
                <label className='ms-3'>Excel татах</label>&nbsp;&nbsp;
                <span className='text-secondary' style={{ fontSize: '12px' }}>
                    Сүүлд шинэчлэгдсэн: ({excelProjectDate})
                </span>
                <CreateButton
                    title='Шинэчлэх'
                    needRoles={companyPageRoles}
                    btnClassName={`floatRight ${ isLoading ? 'disabled' : '' }`}
                    onClick={() => updateExcels()}
                />
            </Col>
            <Col xs={12}>
                <a
                    className={`btn btn-success ${ excelProjectUrl ? excelProjectUrl : 'd-none' } ${ isLoading ? 'disabled' : '' }`}
                    href={`${process.env.REACT_APP_SERVER_URL}/media/report/${excelProjectUrl}`}
                    download={excelProjectUrl}
                    style={{ fontSize: '13px' }}
                ><i className={`far fa-file-excel`}></i>{" "}Excel Төсөл</a>
                <a
                    className={`btn btn-success ms-3 ${ excelTypeProjectUrl ? excelTypeProjectUrl : 'd-none' } ${ isLoading ? 'disabled' : '' }`}
                    href={`${process.env.REACT_APP_SERVER_URL}/media/report/${excelTypeProjectUrl}`}
                    download={excelTypeProjectUrl}
                    style={{ fontSize: '13px' }}
                ><i className={`far fa-file-excel`}></i>{" "}Excel Төсөлийн төрөл</a>
            </Col>
        </Row>
    );
}

export default Project;
