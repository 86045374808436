import React, { useEffect } from 'react'

import CForm from "components/main/Form"

import useConfig from 'hooks/useConfig'
import useLoader from 'hooks/useLoader'
import BoxBackground from 'components/special/BoxBackground'

import { videoUrlValidation } from 'utils/validate'

export default function VideoUrl()
{
    const { getConfig, configs, updateConfig } = useConfig()
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })

    useEffect(
        () =>
        {
            fetchData(getConfig("ABOUT_PAGE_VIDEO_URL"))
        },
        []
    )

    const handleSubmit = async (data) =>
    {
        let needConvert = true
        const { success, errors } = await updateConfig(data, needConvert)
        if (!success)
        {
            return errors
        }
    }

    const registerInputs =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "ABOUT_PAGE_VIDEO_URL",
                label: {
                    text: "Холбоос"
                },
                placeholder: "Холбоосыг оруулна уу",
                disabled: isLoading,
                isLoading: isLoading,
                colMd: 12
            },
        ],
        button:
        {
            onSubmit: handleSubmit,
            title: "Хадгалах",
            disabled: isLoading,
            isLoading: isLoading,
        },
        validate:
        {
            validateMode: "all",
            yupValidate: videoUrlValidation
        }
    }

    return (
        <BoxBackground title='Бичлэгний холбоос (хуучирсан)'>
            <CForm
                inputValues={configs}
                form={registerInputs}
            />
        </BoxBackground>
    )
}
