import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';

import UnitsForm from 'components/special/UnitsForm'

import useMapContext from 'hooks/useMapContext';
import useLoader from 'hooks/useLoader';

import { styleOfAparts, selectStyle } from 'hooks/map/helper';
import ResetBtn from './reset'


export default function AUSearch({ isOpen })
{

    const {
		setSearchResets,
		searchResets,
		getAuBoundary,
		resetSearch,
		getBuildingFromNsdi,
		setChoosedFeature,
		setShouldClosePopup,
		setSelectedUnits,
		cMapState,
		shzGeoIds,
		getSHZOldGeoids,
        alwaysDrag,
	} = useMapContext()

    const { isLoading, fetchData, Loader } = useLoader({})
    const unitRef = useRef(null)
    const { tableId } = useParams()
    const [ vectors, setVectors ] = useState()

    useEffect(
        () =>
        {
            setSearchResets(
                v => ({ ...v, shzUnit: handleReset })
            )
        },
        []
    )

    /** тухайн барилгын feqature дээр дарах үед overlay гаргах */
    const handleFeature = (event) =>
    {
        const feature = event?.selected?.[0]

        if (!feature)
        {
            cMapState.displayOverlay(undefined)
            return
        }

        const _clickedCoordinate = event.mapBrowserEvent.coordinate
        const centerOfFeature = cMapState.getCenterFromExtent(feature.getGeometry().getExtent())
        cMapState.displayOverlay(_clickedCoordinate)

        setShouldClosePopup(true)
        setChoosedFeature({ feature, isNew: false, isFromMap: true })
    }

    const handleDragBoxFeature = (event, features) =>
    {
        // let geoMetries = features.array_.map(
        //     (element) =>
        //     {
        //         return turf.polygon(element.getGeometry().getCoordinates()[0][0])
        //     }
        // )

        // console.log(geoMetries)
        // var intersection = turf.intersect(turf.featureCollection(geoMetries))
        // console.log(intersection)
        // var intersection = turf.intersect(turf.featureCollection(geoMetries))
        // console.log(intersection)
    }

    /** nsdi аас ирсэн барилга дээр хуучин орон сууцийг тэмдэглэх нь */
    const setApartToFeature = (layer) =>
    {
        const clonedGeoIds = [ ...shzGeoIds ]
        const source = layer.getSource()
        for (const feature of source.getFeatures())
        {
            /** бүх апарт оноогдоод дууссан үед давталт болиулах */
            if (clonedGeoIds.length === 0)
            {
                break
            }

            const featureId = feature.getId()
            const foundApart = clonedGeoIds.findIndex(e => e === featureId)
            /** хэрвээ апарт олдсон бол */
            if (foundApart !== -1)
            {
                const aStyle = styleOfAparts(
                    {
                        name: feature.get("text"),
                    }
                )
                feature.setStyle(aStyle)
                feature.set("cMapState-style", aStyle)
                clonedGeoIds.splice(foundApart, 1)
            }
        }
    }

    /** барилгын датаг дуудаж газрын зурган дээр харуулж select хйиж болгох */
    const setOnBuild = async (searchObj) =>
    {
        const vectorLayer = await getBuildingFromNsdi(searchObj, true)
        setVectors(vectorLayer)
        setApartToFeature(vectorLayer)
        if (cMapState)
        {
            cMapState.createSelect(
                'click',
                (evt) => handleFeature(evt),
                selectStyle,
                [vectorLayer]
            )
        }
    }

    // useEffect(
    //     () =>
    //     {
    //         if (cMapState && vectors)
    //         {
    //             cMapState.createSelectArea(
    //                 (event, selectedFeatures) => handleDragBoxFeature(event, selectedFeatures),
    //                 [vectors],
    //                 alwaysDrag
    //             )
    //         }
    //     },
    //     [alwaysDrag, cMapState, vectors]
    // );

    /** хайсны дараа хилийн дата болон барилгын датаг дуудах нь */
    const handleSubmit = async (data) =>
    {
        cMapState.addLoader()
        await searchResets.shzProject && searchResets.shzProject({}, cMapState)

        let searchObj = {
            'unit1-code': data.unit1,
            'unit2-code': data.unit2,
            'unit3-code': data.unit3
        }
        fetchData(
            Promise.all(
                [
                    await getAuBoundary(searchObj),
                    await setOnBuild(searchObj)
                ]
            )
        )
        cMapState.removeLoader()
    }

    const handleReset = async (event, form) =>
    {
        unitRef && unitRef.current && unitRef.current.reset()
        await resetSearch()
        if (form?.reset)
        {
            form?.reset()
        }
    }

    return (
        <>
            {isLoading && Loader}
            <UnitsForm
                onSubmit={handleSubmit}
                btnName={"Done"}
                setSelectedUnits={setSelectedUnits}
                uRef={unitRef}
                extraButtons={
                    <ResetBtn handleReset={handleReset} />
                }
            />
        </>
    )
}
