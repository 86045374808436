import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CTable from 'components/main/Table';
import BoxBackground from 'components/special/BoxBackground';
import IsSpecial from 'components/special/isSpecial';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { timeZoneToDateString } from 'utils/format';

const WonSongonList = () => {

    const { isLoading, fetchData } = useLoader({ isSmall: true })

    const [ rows, setRows ] = useState();

    const requestApi = useApi().wonSongon

    const navigate = useNavigate();

    /** Хүснэгтийн багана */
    const columns =
    [
        {
            field: 'songon',
            headerName: 'Сонгон шалгаруулалтын нэр',
            order: false,
            fieldComponent: (fieldValue) => <span className='tablerow-detail' onClick={() => navigate(`/songon/${fieldValue.id}/`)} >{fieldValue.name}</span>
        },
        {
            field: 'zoriltot_buleg',
            headerName: 'Зорилтот бүлгийн нэр',
            order: false,
            fieldComponent: (fieldValue) => <span>{fieldValue.name}</span>
        },
        {
            field: 'created_at',
            headerName: 'Огноо',
            order: false,
            fieldComponent: (fieldValue) => <span>{timeZoneToDateString(fieldValue)}</span>
        },
        {
            field: 'is_won',
            order: false,
            fieldComponent: (fieldValue) => <IsSpecial fieldValue={fieldValue} />
        }
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтийн утгыг авах */
    const getData = async () =>
    {
        const resp = await fetchData(requestApi.get())
        if(resp.success)
        {
            setRows(resp.data)
        }
    }

    useEffect(
        () =>
        {
            getData()
        },
        []
    )

    return (
        <BoxBackground
            title="Сонгон шалгаруулалтанд оролцсон түүх"
            body={
                <CTable
                    isLoading={isLoading}
                    columns={columns}
                    sequenceColumn={sequenceColumn}
                    rows={rows}
                />
            }
        />
    );
}

export default WonSongonList;
