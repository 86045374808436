import useAuth from "./useAuth"

import {
    PERM_IS_CITIZEN,
    PERM_IS_FROM_BUILDING,
    PERM_IS_FROM_PROJECT,
    PERM_IS_NEWS_ADMIN,
    PERM_IS_ONLY_READ,
    PERM_IS_ORG_ADMIN,
    PERM_IS_READ_GRAPHIC,
    PERM_IS_SUPER_USER,
    PERM_IS_WILL_RENT,
    PERM_IS_ZB_OF_RENT,
    PERM_IS_FINANCIAL_EMPLOYEE,
    PERM_IS_FINANCIAL_REGULATORY_COMMISSION_SUPER,
    PERM_IS_GOVERNMENT_ORGANIZATION,
    PERM_IS_TOSK,
    NO_NEED_PERM,

    PERM_CREATE,
    PERM_READ,
    PERM_UPDATE,
    PERM_DELETE,
    PERM_IS_MATERIAL,
    PERM_IS_BHTUV,
} from 'utils/consts'

const INIT_PERM = {
    [PERM_CREATE]: false,
    [PERM_READ]: false,
    [PERM_UPDATE]: false,
    [PERM_DELETE]: false,
}

/** Role уудын эрх */
const PERM_OF_PERM = {
    /** иргэн мөн эсэх */
    [PERM_IS_CITIZEN]: {
        ...INIT_PERM,
        [PERM_CREATE]: true,
        [PERM_READ]: true,
        [PERM_UPDATE]: true,
        [PERM_DELETE]: true,
    },
    /** барилга угсралтын компани */
    [PERM_IS_FROM_BUILDING]: {
        ...INIT_PERM,
        [PERM_CREATE]: true,
        [PERM_READ]: true,
        [PERM_UPDATE]: true,
        [PERM_DELETE]: true,
    },
    /** зураг төслийн компани */
    [PERM_IS_FROM_PROJECT]: {
        ...INIT_PERM,
        [PERM_CREATE]: true,
        [PERM_READ]: true,
        [PERM_UPDATE]: true,
        [PERM_DELETE]: true,
    },
    /** мэдээний админ */
    [PERM_IS_NEWS_ADMIN]: {
        ...INIT_PERM,
        [PERM_CREATE]: true,
        [PERM_READ]: true,
        [PERM_UPDATE]: true,
        [PERM_DELETE]: true,
    },
    /** бүх мэдээлэл харах */
    [PERM_IS_ONLY_READ]: {
        ...INIT_PERM,
        [PERM_READ]: true
    },
    /** Байгууллагын админ */
    [PERM_IS_ORG_ADMIN]: {
        ...INIT_PERM,
        [PERM_CREATE]: true,
        [PERM_READ]: true,
        [PERM_UPDATE]: true,
        [PERM_DELETE]: true,
    },
    /** graphic хардаг */
    [PERM_IS_READ_GRAPHIC]: {
        ...INIT_PERM,
        [PERM_READ]: true,
    },
    /** системийн админ мөн эсэх */
    [PERM_IS_SUPER_USER]: {
        ...INIT_PERM,
        [PERM_CREATE]: true,
        [PERM_READ]: true,
        [PERM_UPDATE]: true,
        [PERM_DELETE]: true,
    },
    /** түрээсийн орон сууцад хамрагдах иргэн */
    [PERM_IS_WILL_RENT]: {
        ...INIT_PERM,
        [PERM_CREATE]: true,
        [PERM_READ]: true,
        [PERM_UPDATE]: true,
        [PERM_DELETE]: true,
    },
    /** түрээсийн орон сууц хөтөлбөрийн зохион байгуулагч */
    [PERM_IS_ZB_OF_RENT]: {
        ...INIT_PERM,
        [PERM_CREATE]: true,
        [PERM_READ]: true,
        [PERM_UPDATE]: true,
        [PERM_DELETE]: true,
    },
    [PERM_IS_FINANCIAL_EMPLOYEE]: {
        ...INIT_PERM,
        [PERM_CREATE]: true,
        [PERM_READ]: true,
        [PERM_UPDATE]: true,
        [PERM_DELETE]: true,
    },
    [PERM_IS_FINANCIAL_REGULATORY_COMMISSION_SUPER]: {
        ...INIT_PERM,
        [PERM_CREATE]: true,
        [PERM_READ]: true,
        [PERM_UPDATE]: true,
        [PERM_DELETE]: true,
    },
    [PERM_IS_GOVERNMENT_ORGANIZATION]: {
        ...INIT_PERM,
        [PERM_CREATE]: true,
        [PERM_READ]: true,
        [PERM_UPDATE]: true,
        [PERM_DELETE]: true,
    },
    [PERM_IS_TOSK]: {
        ...INIT_PERM,
        [PERM_CREATE]: true,
        [PERM_READ]: true,
        [PERM_UPDATE]: true,
        [PERM_DELETE]: true,
    },
    [PERM_IS_MATERIAL]: {
        ...INIT_PERM,
        [PERM_CREATE]: true,
        [PERM_READ]: true,
        [PERM_UPDATE]: true,
        [PERM_DELETE]: true,
    },
    [PERM_IS_BHTUV]: {
        ...INIT_PERM,
        [PERM_CREATE]: true,
        [PERM_READ]: true,
    }
}

/** Хэрэглэгчийн ROLE оос хамаарч CRUD байгааг шалгах
 * @param {[string]} needRoles тухайн газарт хэрэгтэй Role ийн нэрс
 * @param {string} needPerm тухайн газрын хэрэглэх Perm ийн нэр
*/
export default function useRole(needRoles, needPerm)
{
    const { userDetail } = useAuth()

    /** Тухайн нэвтэрсэн хэрэглэгчийн ROLE */
    const userRole = userDetail.user_role

    if (needRoles === NO_NEED_PERM)
    {
        return true
    }

    return needRoles.find(
        (needRole, idx) =>
        {
            const role = userRole?.[needRole]
            /** Role д эрх байхгүй бол алгасна */
            if (!role)
                return false

            const permOfPerms = PERM_OF_PERM[needRole]
            const hasPerm = permOfPerms[needPerm]
            return hasPerm
        }
    )
}
