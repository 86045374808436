import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import DatepickerInput from "../Datepicker/DatepickerInput";
import YearpickerInput from "../Yearpicker/YearpickerInput"

import ImageUpload from "./InputTypes/ImageUpload";
import CRadioCheck from "./InputTypes/RadioCheck";
import FileUpload from "./InputTypes/FileUpload";
import CSelect from "./InputTypes/Select";
import Editor from "./InputTypes/Editor";
import CInput from "./InputTypes/Input";

import CustomInputGroup from "./CustomInputGroup";
import CButton from "../Button";

import label from "utils/validate/label";
import MultiImageUpload from "./InputTypes/MultiImageUpload";
import ChooseItem from "./InputTypes/ChooseItem";
import { addPropsToComponent } from "utils";
import LineSlider from "./InputTypes/LineSlider";

/**
 * @param {array} form.inputs Ямар input оруулах аа өгнө. Ж: input, datePicker, select
 * @param {object} form.button form ийн submit товчний тохиргоо
 * @param {object} form.validate form ийн validate тохиргоо
 * @returns Иж бүрэн form буцаана
 */
const CForm = ({ form, inputValues, extraButtons, defaultValue = null }) =>
{
    /** mode onBlur нь input ээс focus гарах үед validate эсэхийг шалгана */
    const { resetField, getFieldState, register, handleSubmit, formState, setValue, setError, getValues, watch, reset } = useForm(
		{
	        mode: form.validate.validateMode,
			resolver: !!form?.validate?.yupValidate ? yupResolver(form.validate.yupValidate) : null,
		}
	);

	/** Form-ийн validate Errors хадгална */
    const { errors, isSubmitSuccessful } = formState;

	// useEffect(
	// 	() =>
	// 	{
	// 		if(isSubmitSuccessful)
	// 		{
	// 			reset();
	// 		}
	// 	},
	// 	[formState, reset, isSubmitSuccessful]
	// )

	useEffect(
		() =>
		{
			if(defaultValue === null) return
			for(let key in defaultValue)
			{
				setValue(key, defaultValue[key])
			}
		},
		[defaultValue]
	)

    /** Input-ийг validate шалгахгүй болгоно */
    const notRequiredValidate = (registerName) =>
    {
		if (form?.validate?.yupValidate?.fields?.[registerName])
		{
			form.validate.yupValidate.fields[registerName].spec.nullable = true;
			delete form.validate.yupValidate.fields[registerName].exclusiveTests.required;
			form.validate.yupValidate.fields[registerName].spec.presence = "optional";
			form.validate.yupValidate.fields[registerName].transforms.push((v, o) => o === '' ? null : v)
			const filteredTests = form.validate.yupValidate.fields[registerName].tests.filter((e, index) =>
			{
				return e.OPTIONS.name !== "required";
			});
			form.validate.yupValidate.fields[registerName].tests = filteredTests;
		}
    }

    // Анхны утга байгаа бол useform луу setValue хийх register name ээр нь
    useEffect(
        () =>
        {
            if(inputValues && Object.keys(inputValues).length)
            {
                form.inputs.map((element) =>
                {
                    /** select -д дотор байгаа useeffect -т утга оноодог болон энэ 2 уралдаж ажиллаад байгаа болохоор
                     * select үеээс бусад үед ажилладаг болгосон
                     */
                    if (element.inputType)
                    {
						if (inputValues?.[element.registerName])
						{
							setValue(element.registerName, inputValues?.[element.registerName])
						}
                    }
                })
            }
        },
        [inputValues]
    )

    const getFormsInput = (inputType, element, index) =>
    {
        if (element?.label?.empty) notRequiredValidate(element.registerName)
        let inputs = {
			input: (
				<CustomInputGroup
					md={element.colMd}
					text={label[element.registerName] + ":"}
					key={index + element.registerName}
					errors={errors}
					registerName={element.registerName}
					label={element?.label}
					className={element.className || ""}
					helperText={element.helperText}
				>
					<CInput
						value={element.value}
						errors={errors}
						getFieldState={getFieldState}
						setValue={setValue}
						disabled={element.disabled}
						onChange={element.onChange}
						placeholder={element.placeholder ? element.placeholder : label[element.registerName] + " оруулна уу"}
						readOnly={element.readOnly}
						register={register(element.registerName)}
						setError={setError}
						type={element.type}
						step={element.step}
						getValues={getValues}
					/>
				</CustomInputGroup>
			),
			radio: (
				<CustomInputGroup
					md={element.colMd}
					text={label[element.registerName] + ":"}
					key={index + element.registerName}
					errors={errors}
					registerName={element.registerName}
					label={element?.label}
					helperText={element.helperText}
				>
					<CRadioCheck
						options={element.options}
						watch={watch}
						errors={errors}
						type={element.type}
						disabled={element.disabled}
						setValue={setValue}
						onChange={element.onChange}
						placeholder={element.placeholder ? element.placeholder : label[element.registerName] + " оруулна уу"}
						readOnly={element.readOnly}
						defaultValue={element.defaultValue}
						register={register(element.registerName)}
					/>
				</CustomInputGroup>
			),
			checkbox: (
				<CustomInputGroup
					md={element.colMd}
					text={label[element.registerName] + ":"}
					key={index + element.registerName}
					errors={errors}
					registerName={element.registerName}
					label={element?.label}
					helperText={element.helperText}
				>
					<CRadioCheck
						options={element.options}
						watch={watch}
						errors={errors}
						type={element.type}
						disabled={element.disabled}
						setValue={setValue}
						onChange={element.onChange}
						placeholder={element.placeholder ? element.placeholder : label[element.registerName] + " оруулна уу"}
						readOnly={element.readOnly}
						register={register(element.registerName)}
					/>
				</CustomInputGroup>
			),
			datePicker: (
				<CustomInputGroup
					md={element.colMd}
					text={label[element.registerName] + ":"}
					key={index + element.registerName}
					errors={errors}
					registerName={element.registerName}
					label={element?.label}
					helperText={element.helperText}
				>
					<DatepickerInput
						setValue={setValue}
						disabled={element.disabled}
						selected={inputValues?.[element.registerName]}
						key={index}
						minDate={element.minDate}
						maxDate={element.maxDate}
						readOnly={element.readOnly}
						errors={errors}
						showTimeSelect={element.showTimeSelect}
						onChange={element.onChange}
						register={register(element.registerName)}
						inputPlaceholder={element.placeholder ? element.placeholder : label[element.registerName] + " сонгоно уу"}
						selectsRange={element.selectsRange}
					/>
				</CustomInputGroup>
			),
			yearPicker: (
				<CustomInputGroup
					md={element.colMd}
					text={label[element.registerName] + ":"}
					key={index + element.registerName}
					errors={errors}
					registerName={element.registerName}
					label={element?.label}
					helperText={element.helperText}
				>
					<YearpickerInput
						setValue={setValue}
						disabled={element.disabled}
						selected={inputValues?.[element.registerName]}
						key={index}
						minDate={element.minDate}
						maxDate={element.maxDate}
						readOnly={element.readOnly}
						errors={errors}
						showTimeSelect={element.showTimeSelect}
						onChange={element.onChange}
						register={register(element.registerName)}
						inputPlaceholder={element.placeholder ? element.placeholder : label[element.registerName] + " сонгоно уу"}
						selectsRange={element.selectsRange}
					/>
				</CustomInputGroup>
			),
			select: (
				<CustomInputGroup
					md={element.colMd}
					text={label[element.registerName] + ":"}
					key={index + element.registerName}
					errors={errors}
					registerName={element.registerName}
					label={element?.label}
					helperText={element.helperText}
				>
					<CSelect
						isMulti={element.isMulti}
						disabled={element.disabled}
						setValue={setValue}
						nestedValue={inputValues?.[element.nested]}
						nested={element.nested}
						sort={element.sort}
						value={element.value ? element.value : inputValues?.[element.registerName]}
						inputValues={inputValues}
						onChange={element.onChange}
						key={index}
						shouldReset={element.shouldReset}
						nestedObjectKey={element.nestedObjectKey}
						options={element.options}
						_async={element.async}
						errors={errors}
						watch={watch}
						register={register(element.registerName)}
						placeholder={element.placeholder ? element.placeholder : label[element.registerName] + " сонгоно уу"}
						selectsRange={element.selectsRange}
					/>
				</CustomInputGroup>
			),
			imageUpload: (
				<CustomInputGroup
					md={element.colMd}
					text={label[element.registerName] + ":"}
					key={index + element.registerName}
					errors={errors}
					registerName={element.registerName}
					label={element?.label}
					helperText={element.helperText}
				>
					<ImageUpload
						setValue={setValue}
						value={inputValues?.[element.registerName]}
						errors={errors}
						disabled={element.disabled}
						multiple={element.multiple}
						register={register(element.registerName)}
						id={element.registerName}
						placeholder={element.placeholder ? element.placeholder : label[element.registerName] + " оруулна уу"}
					/>
				</CustomInputGroup>
			),
			fileUpload: (
				<CustomInputGroup
					md={element.colMd}
					text={label[element.registerName] + ":"}
					key={index + element.registerName}
					errors={errors}
					registerName={element.registerName}
					label={element?.label}
					helperText={element.helperText}
				>
					<FileUpload
						setValue={setValue}
						value={inputValues?.[element.registerName]}
						errors={errors}
						disabled={element.disabled}
						onChange={element.onChange}
						fileType={element.fileType}
						register={register(element.registerName)}
						id={element.registerName}
						placeholder={element.placeholder ? element.placeholder : label[element.registerName] + " оруулна уу"}
					/>
				</CustomInputGroup>
			),
			editor: (
				<CustomInputGroup
					md={element.colMd}
					text={label[element.registerName] + ":"}
					key={index + element.registerName}
					errors={errors}
					registerName={element.registerName}
					label={element?.label}
					helperText={element.helperText}
				>
					<Editor watch={watch} setValue={setValue} register={register(element.registerName)} />
				</CustomInputGroup>
			),
			multiImageUpload: (
				<CustomInputGroup
					md={element.colMd}
					text={label[element.registerName] + ":"}
					key={index + element.registerName}
					errors={errors}
					registerName={element.registerName}
					label={element?.label}
					helperText={element.helperText}
				>
					<MultiImageUpload
						register={register(element.registerName)}
						id={element.registerName}
						errors={errors}
						disabled={element.disabled}
						setValue={setValue}
						getValues={getValues}
						resetField={resetField}
						btnTitle={element.btnTitle}
						values={inputValues?.[element.registerName]}
						placeholder={element.placeholder}
					/>
				</CustomInputGroup>
			),
			chooseItem: (
				<CustomInputGroup
					md={element.colMd}
					text={label[element.registerName] + ":"}
					key={index + element.registerName}
					errors={errors}
					registerName={element.registerName}
					label={element?.label}
					helperText={element.helperText}
				>
					<ChooseItem
						register={register(element.registerName)}
						errors={errors}
						setValue={setValue}
						isLoading={element.isLoading}
						options={element.options}
						onChange={element.onChange}
						defaultValue={element.defaultValue ? element.defaultValue : inputValues?.[element.registerName]}
					/>
				</CustomInputGroup>
			),
			lineSlider: (
				<CustomInputGroup
					md={element.colMd}
					text={label[element.registerName] + ":"}
					key={index + element.registerName}
					errors={errors}
					registerName={element.registerName}
					label={element?.label}
					helperText={element.helperText}
				>
					<LineSlider
						register={register(element.registerName)}
						errors={errors}
						isRange={element.isRange}
						setValue={setValue}
						defaultValueRange={element.defaultValueRange ? element.defaultValueRange : inputValues?.[element.registerName]}
						isLoading={element.isLoading}
						onChange={element.onChange}
						max={element.max}
						min={element.min}
						step={element.step}
						defaultValue={element.defaultValue ? element.defaultValue : inputValues?.[element.registerName]}
					/>
				</CustomInputGroup>
			)
		}
        return inputs?.[inputType]
    }

    const displayInputs = () =>
    {
        const inputs = form.inputs.map(
            (element, index) =>
            {
                if (element.component?.colMd) return <Col md={element.component?.colMd || 12} key={index}>{element.component}</Col>
                if (element.component) return <Fragment key={index}>{addPropsToComponent(element.component, { setValue, setError, errors, register, watch, inputValues })}</Fragment>
                return getFormsInput(element.inputType, element, index)
            }
        );
        return inputs;
    };

    const sendData = async (data) =>
    {
        const errors = await form.button.onSubmit(data, { reset, resetField })
        if (!!!errors) return
        for (const [key, value] of Object.entries(errors))
        {
            setError(key, {
                type: "manual",
                message: value.map(
                    (element, index) =>
                    {
                        return (
                            <Fragment key={index}>
                                <span>{element}</span>
                                <br />
                            </Fragment>
                        );
                    }
                ),
            });
        }
    }

    return (
		<Form onSubmit={handleSubmit(sendData)} autoComplete="off">
			<Row>
                {displayInputs()}
			</Row>
			<CButton
				show={form.button.show}
				variant={form.button.variant}
				disabled={form.button.disabled}
				isLoading={form.button.isLoading}
				type="submit"
				className={form.button.className}
            >
				{form.button.title}
			</CButton>
            {extraButtons && addPropsToComponent(extraButtons, { reset, resetField })}
		</Form>
	)
};

export default CForm;
