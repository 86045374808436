import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import CrudColumnChart from './CrudColumnChart';
import CrudLineChart from './CrudLineChart';

import DatepickerInput from 'components/main/Datepicker/DatepickerInput';
import BoxBackground from 'components/special/BoxBackground';
import CSelect from 'components/main/Form/InputTypes/Select';
import { DESC } from 'components/main/Table/Thead';
import Datatable from 'components/main/Datatable';

import useLoader from 'hooks/useLoader';
import useApi from 'hooks/useApi';

import { CRUDTYPES, DATETYPES, ONE_MONTH_TO_MILLISECONDS } from 'utils/consts';
import { timeZoneToDateString } from 'utils/format';

const sequenceColumn =
{
    headerName: "#",
    startNumber: 1,
    order: false,
    width: 50
}

const CrudAccess = () => {

    const dataTableRef = useRef(null)
	const [ date, setDate ] = useState([Date.now() - 3 * ONE_MONTH_TO_MILLISECONDS, Date.now()])
    const [ crudType, setCrudType ] = useState('get')

    const [ accessLog, setAccessLog ] = useState({})
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
    const adminAccessApi = useApi().adminAccessFront

    // column чартын датаг авах
    const getAccessData = async () =>
    {
        const { success, data, errors } = await fetchData(adminAccessApi.getRequestLog(date[0], date[1])).catch(err => err)
        if(success)
        {
            setAccessLog(data)
        }
    }

     /** Хүснэгтийн багана */
     const columns = [
			{
				field: "status_code",
				headerName: "Статус код",
				order: true,
				fieldComponent: (fieldValue, index, row) => fieldValue,
			},
			{
				field: "url",
				headerName: "Хандсан хаяг",
				order: true,
				fieldComponent: (fieldValue, index, row) => fieldValue,
			},
			{
				field: "remote_ip",
				headerName: "Remote ip",
				order: false,
				fieldComponent: (fieldValue, index, row) => fieldValue,
			},
			{
				field: "user",
				headerName: "Хэрэглэгч ID",
				order: true,
				fieldComponent: (fieldValue, index, row) => fieldValue,
				searchField: 'user__id'
			},
			{
				field: "user_name",
				headerName: "Нэр",
				order: false,
				fieldComponent: (fieldValue, index, row) => fieldValue,
				searchField: 'user__first_name'
			},
			{
				field: "datetime",
				headerName: "Огноо",
				order: true,
				fieldComponent: (fieldValue, index, row) => timeZoneToDateString(fieldValue),
				// searchField: false
			},
			{
				field: "query_string",
				headerName: "Query",
				order: false,
				fieldComponent: (fieldValue, index, row) => fieldValue,
                maxWidth: 300,
				// searchField: false
			},
		]

    //DataTable дата авах
    const getData = () =>
    {
        dataTableRef.current.firstGet = true
        dataTableRef.current.reData({}, null, [crudType, date[0], date[1]])
    }


	useEffect(
		() =>
		{
			getAccessData()
		},
		[date]
	)

    useEffect(
        () =>
        {
			getData()
        },
        [crudType, date]
    )

	const handleDate = (value) =>
	{
		if(new Date(value[0]).getTime() === 0 || new Date(value[1]).getTime() === 0) return
		setDate([
			new Date(value[0]).getTime(),
			new Date(value[1]).getTime() !== 0 ? new Date(value[1]).getTime() : Date.now(),
		])
	}

    return (
		<>
			<Row className="mb-2">
				<Col md={2}>
					<DatepickerInput maxDate={Date.now()} selected={date} onChange={handleDate} selectsRange={true} />
				</Col>
				<Col md={2}>
					<CSelect onChange={val => setCrudType(val.value)} value={crudType} options={CRUDTYPES} />
				</Col>
			</Row>
			<BoxBackground title="Хүсэлт">
				<Row className="mb-3 mt-3">
					<Col md={4}>
						<CrudColumnChart accessLog={accessLog} />
					</Col>
					<Col md={8}>
						<CrudLineChart crudType={crudType} date={date} />
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<Datatable
							dRef={dataTableRef}
							columns={columns}
							sequenceColumn={sequenceColumn}
							tableOptions={{
								defaultSort: {
									field: "datetime",
									type: DESC,
								},
							}}
							request={{
								axios: adminAccessApi.getRequestLogPaginition,
								params: [crudType, date[0], date[1]],
							}}
							isFirstGetData={true}
						/>
					</Col>
				</Row>
			</BoxBackground>
		</>
	)
}

export default CrudAccess;
