
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SongonList from './SongonList';

import SongonAction from './SongonAction';
import Songon from '.';
import BoxBackground from 'components/special/BoxBackground';

const SongonMain = () => {
    return (
        <Routes>
            <Route path="/" element={<Songon /> } />
            <Route path="/:projectType/:projectId/" element={<BoxBackground title='Сонгон шалгаруулалтын жагсаалт' body={<SongonList /> } />} />
            <Route path="/:projectType/:projectId/:songonId/" element={<SongonAction />} />
        </Routes>
    );
}

export default SongonMain;
