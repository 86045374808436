import React from 'react';

const TableInput = ({ value, onChange, valueName, disabled, width='80px', isTextArea=false }) => {

    const handleChange = (value) =>
    {
        onChange({[valueName]: value.trim()})
    }
    if(isTextArea)
    {
        return <textarea rows={2} cols={50} className='form-control' style={{ width: width, margin: '0 auto' }} disabled={disabled} value={value} onChange={ e => handleChange(e.target.value)  } />
    }
    return  <input className='form-control' style={{ width: width, height: '25px', margin: '0 auto' }} disabled={disabled} value={value} onChange={ e => handleChange(e.target.value)  } />
}

export default TableInput;
