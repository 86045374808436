import { quantityFormat } from "utils/format";

export default function PopulationInfo({ feature })
{

    const properties = feature.getProperties()

    return (
        <div>
            <h6>{properties['location']}</h6>
            <ul>
                <li>
                    <b>Хүн амын тоо:</b> {quantityFormat(properties['count'])}
                </li>
                <li>
                    <b>Насны ангилал:</b> {properties['age']}
                </li>
                <li>
                    <b>Он:</b> {properties['year']} он
                </li>
            </ul>
        </div>
    )
}
