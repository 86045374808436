import React, { useState, useEffect } from 'react';

import BoxBackground from 'components/special/BoxBackground';
import CTable from 'components/main/Table';
import DeleteButton from 'components/main/CudButtons/Delete';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { timeZoneToDateString } from 'utils/format';
import { PERM_IS_SUPER_USER } from 'utils/consts';
import CButton from 'components/main/Button';
import useModal from 'hooks/useModal';
import { Badge, Col, Row } from 'react-bootstrap'
import { typeDisplay } from 'components/special/UserNotification';
import ShowNotification from 'components/special/UserNotification/ShowNotification';

const NotificationList = () => {

    const [ rows, setRows ] = useState();

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const notiApi = useApi().notification
    const { showWarning, setDisplay } = useModal()

    const displayDetail = async (el) =>
    {
        setDisplay(
            {
                header:
                {
                    title: "Мэдэгдлийн дэлгэрэнгүй",
                },
                body: <ShowNotification el={el} />,
                size: "lg",
                footer:
                {
                    show: false
                }
            }
        )
        notiApi.getOne(el.id)
    }


    const getData = async () =>
    {
        const resp = await fetchData(notiApi.get())
        if(resp.success)
        {
            setRows(resp.data.sort((function(a, b){return new Date(b.notification_info.created_at) - new Date(a.notification_info.created_at)})))
        }
    }

    const readAll = async () =>
    {
        await fetchData(notiApi.readAll()).catch(err => err)
        await getData()
    }
    const deleteAll = async () =>
    {
        await fetchData(notiApi.deleteAll())
        await getData()
    }
    const columns = [
		{
			field: "notification_info",
			headerName: "Мэдэгдлийн гарчиг",
			order: false,
			fieldComponent: (fieldValue, index, row) => (
				<div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => displayDetail(row)}>
					{fieldValue.title}{" "}
					<Badge pill text={typeDisplay[row.type_display][1]} bg={typeDisplay[row.type_display][0]}>
						{row.type_display}
					</Badge>
				</div>
			),
		},
		{
			field: "notification_info",
			headerName: "Огноо",
			order: false,
			fieldComponent: fieldValue => <span>{timeZoneToDateString(fieldValue.created_at)}</span>,
		},
	]

    const sequenceColumn =
    {
        headerName: '#',
        startNumber: 1,
        order: false,
        width: 50
    }

    useEffect(
        () =>
        {
            getData()
        },
        []
    )

    const deleteRow = async (id) =>
    {
        await fetchData(notiApi.delete(id))
        await getData()
    }

    const extraColumns =
    [
        {
            order: false,
            field: (item) =>
            {
                return (
                    <div className='all-center'>
                        <DeleteButton onClick={() => deleteRow(item.id)} needRoles={[ PERM_IS_SUPER_USER ]} />
                    </div>
                )
            }
        }
    ]

    /** товч дарахад эхлээд modal харуулж баталгаажуулах */
    const handleModal = (text, onClick) =>
    {
        showWarning(
            {
                type: "warning",
                question: text,
                onClick: onClick,
            }
        )
    }

    return (
		<BoxBackground title="Мэдэгдлүүд">
            <div style={{ display: "flex", gap: "5px", marginBottom: "10px", justifyContent: 'flex-end' }}>
                <CButton
                    icon={"fas fa-envelope-open"}
                    isLoading={isLoading}
                    onClick={() => handleModal("Бүх мэдэгдлүүдийг уншсан болгохдоо итгэлтэй байна уу?", readAll)}>
                    Бүгдийн уншсан болгох
                </CButton>
                <CButton
                    icon={"fas fa-trash"}
                    isLoading={isLoading}
                    onClick={() => handleModal("Бүх мэдэгдлүүдийг устгахдаа итгэлтэй байна уу?", deleteAll)}>
                    Бүгдийн устгах
                </CButton>
            </div>
			<CTable isLoading={isLoading} columns={columns} sequenceColumn={sequenceColumn} extraColumns={extraColumns} rows={rows} />
		</BoxBackground>
	)
}

export default NotificationList;
