/**
 * Renders a progress bar.
 * @param {HTMLElement} el The target element.
 * @class
 */
export default class Progress {
    constructor(el)
    {
        this.el = el;
        this.loading = 0;
        this.loaded = 0;
    }
}
/**
 * Increment the count of loading tiles.
 */
Progress.prototype.addLoading = function () {
    ++this.loading;
    this.update();
};

/**
 * Increment the count of loaded tiles.
 */
Progress.prototype.addLoaded = function () {
    ++this.loaded;
    this.update();
};

/**
 * Update the progress bar.
 */
Progress.prototype.update = function () {
    const width = ((this.loaded / this.loading) * 100).toFixed(1) + '%';
    this.el.style.width = width;
};

/**
 * Show the progress bar.
 */
Progress.prototype.show = function () {
    this.el.style.visibility = 'visible';
};

/**
 * Hide the progress bar.
 */
Progress.prototype.hide = function () {
    const style = this.el.style;
    setTimeout(function () {
      style.visibility = 'hidden';
      style.width = 0;
    }, 250);
};

