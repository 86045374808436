import React from 'react';

import ImageFetch from 'components/main/Image';
import Slider from 'components/main/Slider';

import css from '../style.module.css'

const BuildingInfo = ({ projectName, description, use, location, eskiz }) => {
    return (
        <div>
            <div className="d-flex mt-3">
                <div className={css.columnOne}>
                    <span className={css.fieldName}>
                        Төслийн нэр:
                    </span>
                </div>
                <div className={css.columnTwo}>
                    <span className={css.valueName}>
                        <div>{projectName}</div>
                    </span>
                </div>
            </div>
            <div className="d-flex">
                <div className={css.columnOne}>
                    <span className={css.fieldName}>
                        Төслийн тайлбар:
                    </span>
                </div>
                <div className={css.columnTwo}>
                    <span className={css.valueName}>
                        <div>{description}</div>
                    </span>
                </div>
            </div>
            <div className="d-flex">
                <div className={css.columnOne}>
                    <span className={css.fieldName}>
                        Төслийн зориулалт:
                    </span>
                </div>
                <div className={css.columnTwo}>
                    <span className={css.valueName}>
                        <div>{use}</div>
                    </span>
                </div>
            </div>
            <div className="d-flex">
                <div className={css.columnOne}>
                    <span className={css.fieldName}>
                        Хаяг:
                    </span>
                </div>
                <div className={css.columnTwo}>
                    <span className={css.valueName}>
                        {location.h1} {location.h2} {location.h3} {location.h4}
                    </span>
                </div>
            </div>
            <div className="d-flex">
                <div className={css.columnOne}>
                    <span className={css.fieldName}>
                        Эскиз зураг:
                    </span>
                </div>
                <div className={css.columnTwo}>
                    <span className={css.valueName}>
                        {
                            eskiz
                            &&
                                <Slider
                                    freeMode={false}
                                    autoplay={true}
                                    autoplayDelay={2000}
                                    spaceBetween={30}
                                    pagination={true}
                                    navigation={false}
                                    items={(
                                        eskiz.map(
                                            (esk, idx) =>
                                            {
                                                return (
                                                    <ImageFetch
                                                        className="w-100"
                                                        src={"/media/"+esk}
                                                        alt={"Эскиз зураг"}
                                                    />
                                                )
                                            }
                                        )
                                    )}
                                    slidesPerView={1}
                                    loop={true}
                                    breakpoints={{
                                        640: {
                                            slidesPerView: 1,
                                            spaceBetween: 10,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 10,
                                        },
                                        1280: {
                                            slidesPerView: 3,
                                            spaceBetween: 10,
                                        },
                                    }}
                                />
                        }
                    </span>
                </div>
            </div>
        </div>
    );
}

export default BuildingInfo;
