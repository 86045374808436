import useApi from 'hooks/useApi'
import useLoader from 'hooks/useLoader'
import { useEffect, useState } from 'react'
import { kindTypeToColor } from 'utils'

export default function Detail({ id })
{

    const [ data, setData ] = useState({})

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false, initValue: true })
    const api = useApi().adminAccessFront

    const getData = async () =>
    {
        const { success, data, error, errors } = await fetchData(api.error500.get(id)).catch(err => err)
        if (success)
        {
            setData(data)
        }
    }

    useEffect(
        () =>
        {
            getData()
        },
        []
    )

    if (isLoading)
    {
        return Loader
    }

    return (
        <div>
            <h6>{data.scheme} {data.method} {data.url}</h6>
            <h6>HEADERS</h6>
            <div>
                <pre><code>{data.headers}</code></pre>
            </div>
            <h6>EXCEPTION</h6>
            <div>
                <pre><code>{data.description}</code></pre>
            </div>
            <h6>body</h6>
            <div>
                <pre><code>{data.data}</code></pre>
            </div>
        </div>
    )
}
