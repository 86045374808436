import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {getCenter} from 'ol/extent';

import BoxBackground from 'components/special/BoxBackground';
import CForm from 'components/main/Form';

import useMapContext from 'hooks/useMapContext';
import useLoader from 'hooks/useLoader';
import useApi from 'hooks/useApi';

import MapChoice from '../MapChoice'

import { buySellValidate, OldbuySellValidate, OldbuySellValidate2, buySellValidate2, OldbuyOldSellValidate } from 'utils/validate';
import { dataToValueLabel, timeZoneToDateString } from 'utils/format';
import { UIL_AJILLAGAANII_TURUL, UL_HODLOH_TURUL, NSDI_2D_BUILDING, NSDI_CADASTER, UL_HODLOH_HORONGIIN_TURUL_CHOICES_V2, UL_HODLOH_HORONGIIN_TURUL_CHOICES_V3 } from 'utils/consts';
import { capitalizeFirstLetter } from 'utils'
import { transform } from 'ol/proj';
import { DATA_PROJECTION, FEATURE_PROJECTION } from 'hooks/map/CMap';

const BuySellAction = ({ hasTitle=true, isNew=null, setTogglerState, updateId }) => {

    const [ estateTypes, setEstateTypes ] = useState({})

    const [ choosedNew, setChoosedNew ] = useState(null)

    const [ inputValues, setInputValues ] = useState('');
    const [ realValues, setRealValues ] = useState('')

    const [ projectNested, setProjectNested ] = useState([]);
    const [ apartmentNested, setApartmentNested ] = useState([])
    const [ units, setUnits ] = useState([])
    const [ unit2Options, setUnit2Options ] = useState([])
    const [ selectUnit1, setUnit1 ] = useState('')
    const [ selectUnit2, setUnit2 ] = useState('')
    const [ labelType, setLabelType ] = useState(NSDI_2D_BUILDING)

    const [ project, setProject ] = useState({});
    const [ apartment, setApartment ] = useState({});
    const [ buildingsOfApart, setBuildings ] = useState([]);
    const [ activitys, setActivitys ] = useState([])
    const [ gerchilgees, setGerchilgees ] = useState([])
    let { id, tableId } = useParams()

    const { fetchData, isLoading, Loader } = useLoader({ absolute: true })
    const buildingTableApi = useApi().buildingTable
    const projectNestedApi = useApi().projectNested
    const unitApi = useApi().unit
    const activityApi = useApi().activity

    const { selectedUnits, choosedFeature, setShouldClosePopup } = useMapContext()

    const navigate = useNavigate()

    /** create бол true, update бол false буцаана */
    let isCreate = !hasTitle ? true : id === 'create'
    isCreate = !isCreate ? isCreate : updateId ? false : true
    id = updateId ? updateId : id

    /** Регистрээр хэрэглэгчийн датаг авах нь */
    const getUserData = async (event, { onChange, getFieldState, registerName, setValue, getValues, setError }) =>
    {
        await onChange(event)
        let turul = getValues('ul_hudluh_hurungiin_turul')
        const { invalid } = getFieldState(registerName)
        if(turul===4 || turul===5)
            turul = 2
        if (!turul)
            return
        if (!invalid && turul)
        {
            const body =
            {
                register: event.target.value,
                turul: turul
            }
            const { success, data, errors } = await fetchData(buildingTableApi.userData(body))
            if (success)
            {
                const ulTurulObj = UL_HODLOH_TURUL.find(e => e.value === turul)
                const turulLabel = turul === 2 ? "Үл хөдлөх" : ulTurulObj.label
                const { firstname, lastname, property } = data
                const etgeediinNer = `${capitalizeFirstLetter(lastname)} ${capitalizeFirstLetter(firstname)}`
                setValue("irgen_huuliin_etgeedin_ner", etgeediinNer, { shouldValidate: true })
                if(turul !== 1)
                {
                    setGerchilgees(property.filter(e => e.serviceType.toLowerCase() === turulLabel.toLowerCase()))
                    // setGerchilgees([])
                }
            }
            else {
                setError(registerName, { type: 'custom', message: 'Дахин шалгана уу' })
            }
        }
        else {
            setValue("irgen_huuliin_etgeedin_ner", "")
            setValue("geree_gerchilgee_dugaar", "")
            setValue("full_name", "")
            setValue("mkv", "")
            setGerchilgees([])
        }
    }

    const handleChangeGerchilgees = (selectedValue, { setSelectedOption, setValue }) =>
    {
        const gerchilgeeObj = gerchilgees.find(e => e['propertyNationRegisterNumber'] === selectedValue.value)
        setValue("mkv", gerchilgeeObj['propertySize'])
        setValue("full_name", gerchilgeeObj['fullAddress'])
    }

    const handleChangeGerchilgeesV2 = (event, { setValue }) =>
    {
        console.log('event', event, gerchilgees)
        const gerchilgeeObj = gerchilgees.find(e => e['propertyNationRegisterNumber'] === event.target.value)
        if (gerchilgeeObj)
        {
            setValue("mkv", gerchilgeeObj['propertySize'])
            setValue("full_name", gerchilgeeObj['fullAddress'])
        }
        else
        {
            setValue("mkv", '')
            setValue("full_name", '')
        }
    }

    useEffect(
        async () =>
        {
            if(!isNew)
            {
                setEstateTypes(UL_HODLOH_TURUL.filter(
                    (element, index) =>
                    {
                        if(index === 0) return
                        return element
                    }
                ))
            }
            else
            {
                setEstateTypes([UL_HODLOH_TURUL[0]])
            }

            if (isNew === true)
            {
                const { success, data, error } = await fetchData(buildingTableApi.buildingOfApartment(choosedFeature.feature.get("apartment_id"), tableId)).catch(err => err)
                if (success)
                {
                    setBuildings(dataToValueLabel(data, 'id', 'name'))
                }
            }
            else {
                const unitRsp = await fetchData(unitApi.get())
                const projectNestedRsp = await fetchData(projectNestedApi.get('new'))
                if(unitRsp.success && projectNestedRsp.success)
                {
                    setProjectNested(projectNestedRsp.data)
                    setUnits(unitRsp.data)
                }
            }
            const activityRsp = await fetchData(activityApi.get())
            if (activityRsp.success)
            {
                setActivitys(dataToValueLabel(activityRsp.data, 'id', 'name'))
            }

        },
        [isNew]
    )

    useEffect(
        async () =>
        {

            if (!isCreate)
            {
                const { success, data } = await fetchData(buildingTableApi['buy-sell'].getOne(id))
                if (success)
                {
                    setInputValues(data)
                }
            }
        },
        []
    )

    useEffect(
        () =>
        {
            if(inputValues === '') return
            let zadalsanData = {
                irgen_huuliin_etgeedin_ner: inputValues.hariltsagch_info.irgen_huuliin_etgeedin_ner,
                register: inputValues.hariltsagch_info.register,
                project: inputValues.building_info?.building?.project,
                apartment: inputValues.building_info?.building?.apartment,
                building: inputValues.building_info?.building?.id,
                uil_ajillagaanii_turul: parseInt(inputValues.uil_ajillagaanii_turul),
                ul_hudluh_hurungiin_turul: inputValues.ul_hudluh_hurungiin_turul,
                ul_hudluh_hurungiin_turul_v2: inputValues.ul_hudluh_hurungiin_turul_v2,
                ul_hudluh_hurungiin_turul_v3: inputValues.ul_hudluh_hurungiin_turul_v3,
                hudaldagch_ner: inputValues.hudaldagch_ner,
                hudaldagch_register: inputValues.hudaldagch_register,
                hudaldagch_itgemjleleer_toloolj_bui: inputValues.hudaldagch_itgemjleleer_toloolj_bui,
                itgemjleleer_toloolj_bui: inputValues.itgemjleleer_toloolj_bui,
                ipotek_hamragdaj_baisan_eseh: inputValues.ipotek_hamragdaj_baisan_eseh,
                ul_hodloh_horongiin_gerchilge_dugar: inputValues.ul_hodloh_horongiin_gerchilge_dugar,
                ul_hodloh_horongiin_zah_gere_dugar: inputValues.ul_hodloh_horongiin_zah_gere_dugar,
                medeelliin_ezen_zovshorson_eseh: inputValues.medeelliin_ezen_zovshorson_eseh,
                mkv: inputValues.mkv,
                price: inputValues.price,
                ognoo: new Date(inputValues.geree_info?.ognoo),
                geree_gerchilgee_dugaar: inputValues.geree_info?.geree_gerchilgee_dugaar,
                shimtgel_dun: inputValues.geree_info?.shimtgel_dun,
                dugaar: inputValues.geree_info?.dugaar,
                huwi: inputValues.geree_info?.huwi,
                unit1: inputValues.bairshil_info?.aimar_hot_info?.id,
                unit2: inputValues.bairshil_info?.sum_duureg_info?.id,
                unit3: inputValues.bairshil_info?.bag_horoo_info?.id,
                full_name: inputValues.building_info?.full_name,
                geoid: inputValues.building_info?.geo_id,
                point: inputValues.building_info?.point_coordinates
            }
            if(inputValues.building_info)
            {
                setProject(inputValues.building_info.building?.project)
                setApartment(inputValues.building_info.building?.apartment)
            }
            if(inputValues.bairshil_info)
            {
                setUnit1(inputValues.bairshil_info.aimar_hot_info?.id)
                setUnit2(inputValues.bairshil_info.sum_duureg_info?.id)
            }
            setRealValues(zadalsanData)
            if (inputValues.ul_hudluh_hurungiin_turul !== 1)
            {
                setChoosedNew(false)
            }
        },
        [inputValues]
    )

    const apartments = useMemo(
        () =>
        {
            if(!project) return null
            let unit1Id = typeof project === "object" ? project.id : project
            const unit = projectNested?.find(
                (el) =>
                {
                    return el.id === unit1Id
                }
            )
            setApartmentNested(unit?.apartments)
            return dataToValueLabel(unit?.apartments, 'id', 'name')
        },
        [project, projectNested]
    )

    const buildings = useMemo(
        () =>
        {
            if(!apartment) return null
            let unit1Id = typeof apartment === "object" ? apartment.id : apartment
            const unit = apartmentNested?.find(
                (el) =>
                {
                    return el.id === unit1Id
                }
            )
            return dataToValueLabel(unit?.buildings, 'id', 'name')
        },
        [apartment, apartmentNested]
    )

    const secondUnits = useMemo(
        () =>
        {
            if(!selectUnit1) return null
            let unit1Id = typeof selectUnit1 === "object" ? selectUnit1.id : selectUnit1
            const unit = units?.find(
                (el) =>
                {
                    return el.id === unit1Id
                }
            )
            setUnit2Options(unit?.unit2)
            return dataToValueLabel(unit?.unit2, 'id', 'name', null, "code")
        },
        [selectUnit1, units]
    )

    const thirdUnits = useMemo(
        () =>
        {
            if(!selectUnit2) return []
            let unit2Id = typeof selectUnit2 === "object" ? selectUnit2.id : selectUnit2
            const unit = unit2Options?.find(
                (el) =>
                {
                    return el.id === unit2Id
                }
            )

            return dataToValueLabel(unit?.unit3, 'id', 'name', null, 'code')
        },
        [unit2Options, selectUnit2]
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData, { reset }) =>
    {
        const point = choosedFeature.isFromMap
                ?   transform(getCenter(choosedFeature?.feature.getGeometry().getExtent()), FEATURE_PROJECTION, DATA_PROJECTION)
                :   formData.point
                    ? transform(formData.point, FEATURE_PROJECTION, DATA_PROJECTION)
                    : null

        let form_shimtgel_dun = (formData.price / 100) * formData.huwi

        const body = {
            uil_ajillagaanii_turul: formData.uil_ajillagaanii_turul,
            ul_hudluh_hurungiin_turul: formData.ul_hudluh_hurungiin_turul,
            ul_hudluh_hurungiin_turul_v2: formData.ul_hudluh_hurungiin_turul_v2,
            ul_hudluh_hurungiin_turul_v3: formData.ul_hudluh_hurungiin_turul_v3,
            hudaldagch_itgemjleleer_toloolj_bui: formData.hudaldagch_itgemjleleer_toloolj_bui,
            hudaldagch_ner: formData.hudaldagch_ner,
            hudaldagch_register: formData.hudaldagch_register,
            itgemjleleer_toloolj_bui: formData.itgemjleleer_toloolj_bui,
            ipotek_hamragdaj_baisan_eseh: formData.ipotek_hamragdaj_baisan_eseh,
            ul_hodloh_horongiin_gerchilge_dugar: formData.ul_hodloh_horongiin_gerchilge_dugar,
            ul_hodloh_horongiin_zah_gere_dugar: formData.ul_hodloh_horongiin_zah_gere_dugar,
            medeelliin_ezen_zovshorson_eseh: formData.medeelliin_ezen_zovshorson_eseh,
            mkv: formData.mkv,
            price: formData.price,
            hariltsagch: {
                irgen_huuliin_etgeedin_ner: formData.irgen_huuliin_etgeedin_ner,
                register: formData.register,
            },
            geree: {
                ognoo: formData.ognoo,
                dugaar: formData.dugaar,
                geree_gerchilgee_dugaar: formData.geree_gerchilgee_dugaar,
                shimtgel_dun: form_shimtgel_dun,
                huwi: formData.huwi,
            },
            barilga: {
                building: formData.building,
                apartment: isNew ? choosedFeature?.feature?.get("apartment_id") : apartment,
                point: point
            },
            bairshil: {
                aimag_hot: "",
                sum_duureg: "",
                bag_horoo: "",
            },
        }

        const oldBody = {
            uil_ajillagaanii_turul: formData.uil_ajillagaanii_turul,
            ul_hudluh_hurungiin_turul: formData.ul_hudluh_hurungiin_turul,
            ul_hudluh_hurungiin_turul_v2: formData.ul_hudluh_hurungiin_turul_v2,
            ul_hudluh_hurungiin_turul_v3: formData.ul_hudluh_hurungiin_turul_v3,
            hudaldagch_itgemjleleer_toloolj_bui: formData.hudaldagch_itgemjleleer_toloolj_bui,
            hudaldagch_ner: formData.hudaldagch_ner,
            hudaldagch_register: formData.hudaldagch_register,
            itgemjleleer_toloolj_bui: formData.itgemjleleer_toloolj_bui,
            ipotek_hamragdaj_baisan_eseh: formData.ipotek_hamragdaj_baisan_eseh,
            ul_hodloh_horongiin_gerchilge_dugar: formData.ul_hodloh_horongiin_gerchilge_dugar,
            ul_hodloh_horongiin_zah_gere_dugar: formData.ul_hodloh_horongiin_zah_gere_dugar,
            medeelliin_ezen_zovshorson_eseh: formData.medeelliin_ezen_zovshorson_eseh,
            mkv: formData.mkv,
            price: formData.price,
            hariltsagch: {
                irgen_huuliin_etgeedin_ner: formData.irgen_huuliin_etgeedin_ner,
                register: formData.register,
            },
            geree: {
                ognoo: formData.ognoo,
                dugaar: formData.dugaar,
                geree_gerchilgee_dugaar: formData.geree_gerchilgee_dugaar,
                shimtgel_dun: form_shimtgel_dun,
                huwi: formData.huwi,
            },
            bairshil: {
                aimag_hot: typeof isNew === 'boolean' && !isNew ? selectedUnits.unit1 : formData.unit1,
                sum_duureg: typeof isNew === 'boolean' && !isNew ? selectedUnits.unit2 : formData.unit2,
                bag_horoo: typeof isNew === 'boolean' && !isNew ? selectedUnits.unit3 : formData.unit3,
            },
            barilga: {
                full_name: formData.full_name,
                geo_id: choosedFeature.isFromMap ? choosedFeature?.feature?.getId() : formData.geoid,
                point: point
            }
        }
        // if(!typeof isNew === 'boolean')
        // {
        //     delete body.barilga.geo_id
        //     delete oldBody.barilga.geo_id
        // }

        // if (isCreate)
        // {
        //     const { errors, success, data } = await fetchData(buildingTableApi['buy-sell'].post(choosedNew ? body : oldBody)).catch(error => error)
        //     if(success)
        //     {
        //         setTogglerState && setTogglerState(false)
        //         reset()
        //         typeof isNew !== 'boolean' && navigate(-1)
        //     }
        //     if (!success) return errors
        //     return

        // }
        // const { errors, success, data } = await fetchData(buildingTableApi['buy-sell'].put(choosedNew ? body : oldBody, id)).catch(error => error)
        // if(success)
        //     {
        //         setTogglerState && setTogglerState(false)
        //         reset()
        //         typeof isNew !== 'boolean' && navigate(-1)
        //         return
        //     }
        // if (!success) return errors
    }

    const handleIsNew = (svalue, { setValue }) =>
    {
        const value = typeof svalue === "object" ? svalue.value : svalue
        if(value === 1)
        {
            setValue("register", "")
            setValue("irgen_huuliin_etgeedin_ner", "")
            setValue("geree_gerchilgee_dugaar", "")
            setValue("full_name", "")
            setValue("mkv", "")
            setGerchilgees([])
            setChoosedNew(true)
        }
        else {
            setValue("register", "")
            setValue("irgen_huuliin_etgeedin_ner", "")
            setValue("geree_gerchilgee_dugaar", "")
            setValue("full_name", "")
            setValue("mkv", "")
            setGerchilgees([])
            setChoosedNew(false)
        }

        if(value === 3)
        {
            setLabelType(NSDI_CADASTER)
        }
        else
        {
            setLabelType(NSDI_2D_BUILDING)
        }

    }

    const displaySomeInputs = useMemo(
        () =>
        {
            if (isNew == true)
            {
                return [
                    {
                        component: (
                            <hr />
                        )
                    },
                    {
                        inputType: "select",
                        registerName: "building",
                        options: buildingsOfApart,
                        colMd: 6,
                        disabled: !isCreate,
                    },
                ]
            }
            if(typeof choosedNew !== 'boolean') return []
            if(typeof isNew === 'boolean' && !isNew) return (
                [
                    {
                        inputType: "input",
                        registerName: "full_name",
                        type: "textArea",
                        colMd: 12
                    },
                ]
            )
            if(!choosedNew) return [
                {
                    component: (
                        <hr />
                    )
                },
                {
                    inputType: "select",
                    registerName: "unit1",
                    onChange: value => setUnit1(value.value),
                    disabled: isLoading,
                    sort: true,
                    options: dataToValueLabel(units, 'id', 'name'),
                    disabled: !isCreate,
                    colMd: 4
                },
                {
                    inputType: "select",
                    registerName: "unit2",
                    onChange: value => setUnit2(value.value),
                    disabled: isLoading,
                    sort: true,
                    nested: 'unit1',
                    options: secondUnits,
                    disabled: !isCreate,
                    colMd: 4
                },
                {
                    inputType: "select",
                    registerName: "unit3",
                    disabled: isLoading,
                    nested: 'unit1',
                    sort: true,
                    options: thirdUnits,
                    disabled: !isCreate,
                    colMd: 4
                },
                {
                    inputType: "input",
                    registerName: "full_name",
                    type: "textArea",
                    colMd: 12
                },
                {
                    component: <MapChoice units={thirdUnits} disabled={!isCreate} labelType={ labelType }/>
                }
            ]
            return [
                {
                    component: (
                        <hr />
                    )
                },
                {
                    inputType: "select",
                    registerName: "project",
                    onChange: value => setProject(value.value),
                    options: dataToValueLabel(projectNested, 'id', 'name'),
                    colMd: 12,
                    disabled: !isCreate,
                },
                {
                    inputType: "select",
                    registerName: "apartment",
                    onChange: value => setApartment(value.value),
                    nested: 'project',
                    options: apartments,
                    colMd: 6,
                    disabled: !isCreate,
                },
                {
                    inputType: "select",
                    registerName: "building",
                    nested: 'apartment',
                    options: buildings,
                    colMd: 6,
                    disabled: !isCreate,
                },
                {
                    component: (
                        <hr />
                    )
                },
            ]
        },
        [choosedNew, projectNested, apartments, buildings, selectUnit1, units, unit2Options, selectUnit2, isNew, buildingsOfApart, thirdUnits, labelType]
    )

    const displayEstateTypes = useMemo(
        () =>
        {
            if (isCreate) {
                return {
					inputType: "select",
					registerName: "ul_hudluh_hurungiin_turul",
					onChange: handleIsNew,
                    disabled: !isCreate,
					options: UL_HODLOH_TURUL,
                    colMd: 12
				}
			}
            else
            {
				return {
					inputType: "select",
					registerName: "ul_hudluh_hurungiin_turul",
					onChange: handleIsNew,
                    shouldReset: isNew,
                    disabled: !isCreate,
					options: UL_HODLOH_TURUL,
                    colMd: 12
				}
			}
        },
        [isNew, estateTypes, UL_HODLOH_TURUL]
    )

    const gerchilgeesMemo = useMemo(
        () =>
        {
            return gerchilgees.map(
                (item, idx) =>
                {
                    return {
                        label: item['propertyNationRegisterNumber'],
                        value: item['propertyNationRegisterNumber'],
                    }
                }
            )
        },
        [gerchilgees]
    )

    /** Form утгууд */
    const form =
    {
        inputs: [
            displayEstateTypes,
            {
                inputType: "select",
                registerName: "ul_hudluh_hurungiin_turul_v2",
                colMd: 12,
                options: UL_HODLOH_HORONGIIN_TURUL_CHOICES_V2,
            },
            {
                inputType: "select",
                registerName: "ul_hudluh_hurungiin_turul_v3",
                colMd: 12,
                options: UL_HODLOH_HORONGIIN_TURUL_CHOICES_V3,
            },
            {
                component: (
                    <hr />
                )
            },
            {
                component: (
                    <p className='fs-6'>Худалдагч</p>
                )
            },
            {
                inputType: "input",
                registerName: "hudaldagch_ner",
                type: "text",
            },
            {
                inputType: "input",
                registerName: "hudaldagch_register",
                type: "text",
            },
            {
                inputType: "radio",
                type: "checkbox",
                registerName: "hudaldagch_itgemjleleer_toloolj_bui",
                disabled: false,
                readOnly: false,
                label: '',
                options: [
                    { label: "Итгэмжлэлээр төлөөлж буй эсэх" },
                ],
                label: {
                    empty: true,
                },
                colMd: 12
            },
            {
                component: (
                    <hr />
                )
            },
            {
                component: (
                    <p className='fs-6'>Худалдан авагч</p>
                )
            },
            {
                inputType: "input",
                registerName: "register",
                type: "text",
                onChange: getUserData,
            },
            {
                inputType: "input",
                registerName: "irgen_huuliin_etgeedin_ner",
                type: "text",
            },
            {
                inputType: "radio",
                type: "checkbox",
                registerName: "itgemjleleer_toloolj_bui",
                disabled: false,
                readOnly: false,
                options: [
                    { label: "Итгэмжлэлээр төлөөлж буй эсэх" },
                ],
                label: {
                    text: 'Итгэмжлэлээр төлөөлж буй эсэх:',
                    empty: true,
                },
                colMd: 12
            },
            {
                inputType: "radio",
                type: "checkbox",
                registerName: "ipotek_hamragdaj_baisan_eseh",
                disabled: false,
                readOnly: false,
                options: [
                    { label: "Ипотекийн зээлд хамрагдсан эсэх" },
                ],
                label: {
                    text: 'Ипотекийн зээлд хамрагдсан эсэх:',
                    empty: true,
                },
                colMd: 12
            },
            {
                component: (
                    <hr />
                )
            },
            choosedNew
            ?
                {
                    inputType: "input",
                    registerName: "geree_gerchilgee_dugaar",
                    type: "text",
                    label: {
                        empty: true,
                    },
                    disabled: !isCreate,
                }
            :
                // {
                //     inputType: "select",
                //     registerName: "geree_gerchilgee_dugaar",
                //     options: gerchilgeesMemo,
                //     onChange: handleChangeGerchilgees,
                //     disabled: !isCreate,
                // },
                {
                    inputType: "input",
                    registerName: "geree_gerchilgee_dugaar",
                    type: "text",
                    // onChange: event => handleChangeGerchilgeesV2(event.target.value),
                    onChange: handleChangeGerchilgeesV2,
                    label: {
                        empty: true,
                    },
                    disabled: !isCreate,
                },
            {
                inputType: "input",
                registerName: "dugaar",
                type: "text",
                disabled: !isCreate,
            },
            ...displaySomeInputs,
            isNew === true || !choosedNew
            &&
                {
                    inputType: "input",
                    registerName: "mkv",
                    type: "number",
                    disabled: true,
                    step: 0.01,
                },
            {
                inputType: "input",
                registerName: "price",
                type: "number",
                step: 0.01,
                disabled: !isCreate,
            },
            {
                inputType: 'select',
                registerName: 'uil_ajillagaanii_turul',
                sort: true,
                options: activitys,
                colMd: isNew ? 6 : choosedNew ? 6 : 12,
            },
            {
                inputType: 'input',
                registerName: 'ul_hodloh_horongiin_gerchilge_dugar',
                type: "text",
                colMd: 6,
            },
            {
                inputType: 'input',
                registerName: 'ul_hodloh_horongiin_zah_gere_dugar',
                type: "text",
                colMd: 6,
            },
            {
                component: (
                    <hr />
                )
            },
            {
                inputType: "datePicker",
                registerName: "ognoo",
            },
            // {
            //     inputType: "input",
            //     registerName: "shimtgel_dun",
            //     type: "number",
            //     step: 0.01,
            //     disabled: !isCreate,
            // },
            {
                inputType: "input",
                registerName: "huwi",
                type: "number",
                disabled: !isCreate,
                step: 0.01,
            },
            {
                inputType: "radio",
                type: "checkbox",
                registerName: "medeelliin_ezen_zovshorson_eseh",
                disabled: false,
                readOnly: false,
                options: [
                    { label: "Мэдээллийн эзэн зөвшөөрсөн эсэх" },
                ],
                label: {
                    text: 'Мэдээллийн эзэн зөвшөөрсөн эсэх:',
                    empty: true,
                },
                colMd: 12
            },
        ],
        button: {
            onSubmit: btnOnSubmit,
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "onChange",
            yupValidate: (
                typeof isNew === 'boolean'
                ?
                    isNew
                    ?
                        buySellValidate2
                    :
                        OldbuySellValidate2
                :
                    choosedNew
                    ?
                        buySellValidate
                    :
                        labelType == 1
                        ?
                        OldbuyOldSellValidate
                        :
                        OldbuySellValidate
            ),
        },
    }

    return (
        <BoxBackground title={hasTitle ? 'Зуучлалын мэдээ' : ''} body={
            <>
                {isLoading && Loader}
                <div className="text-center mb-2 mt-2 fs-15" style={{ fontWeight: "500", maxWidth: "600px", margin: "0 auto" }}>
                    <span>ХУДАЛДАХ</span>
                </div>
                <CForm isLoading={isLoading} inputValues={realValues} form={form}/>
            </>
        } />
    );
}

export default BuySellAction;
