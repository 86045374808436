export const REEX = 'test'
export const MONEY_REGEX = /\d(?=(\d{3})+\.)/g
export const MONEY_REGEX_0 = /(.)(?=(\d{3})+$)/g
export const QUANTITY_REGEX = /(.)(?=(\d{3})+$)/g

export const EXTRA_SEQUENCE_KEY  = '_index'

//  Арилжааны төрлийн код
export const FEED_FEE_CODE = "feedFee"
export const FEED_FEE_MN = "Ердийн"
export const ALL_OR_NOT_CODE = "allOrNot"
export const ALL_OR_NOT_MN = "Бүтэн"

export const NOT_FOUND = '/notfound/'
export const SIGN_OUT = '/sign/out/'
export const USER_PAGE = '/user/'
export const SIGN_IN_PAGE = '/sign/in/'
export const HOME_PAGE = '/'

export const DEFAULT_PROJECT_ID = "620613196f5d0c4bebcc4c01"

export const NO_IMAGES = {
    "53x53": process.env.PUBLIC_URL + "/images/53x53.jpg",
    "160x160": process.env.PUBLIC_URL + "/images/160x160.jpg",
    "260x260": process.env.PUBLIC_URL + "/images/260x260.jpg",
    "400x245": process.env.PUBLIC_URL + "/images/400x245.jpg",
    "620x350": process.env.PUBLIC_URL + "/images/620x350.jpg",
    "826x673": process.env.PUBLIC_URL + "/images/826x673.jpg",
    "1400x700": process.env.PUBLIC_URL + "/images/1400x700.jpg"
}

export const STATIC_PAGE_ABOUTS_CODE = '101'
export const STATIC_PAGE_ABOUTS_TERMS = '102'
export const STATIC_PAGE_ABOUTS_PRIVACY = '103'
export const STATIC_PAGE_ABOUTS_FEE = '104'

export const NSDI_2D_BUILDING = 1
export const NSDI_CADASTER = 2

/** иргэн мөн эсэх */
export const PERM_IS_CITIZEN = "is_citizen"
/** барилга угсралтын компани */
export const PERM_IS_FROM_BUILDING = "is_from_building"
/** зураг төслийн компани */
export const PERM_IS_FROM_PROJECT = "is_from_project"
/** мэдээний админ */
export const PERM_IS_NEWS_ADMIN = "is_news_admin"
/** бүх мэдээлэл харах */
export const PERM_IS_ONLY_READ = "is_only_read"
/** Байгууллагын админ */
export const PERM_IS_ORG_ADMIN = "is_org_admin"
/** graphic хардаг */
export const PERM_IS_READ_GRAPHIC = "is_read_graphic"
/** системийн админ мөн эсэх */
export const PERM_IS_SUPER_USER = "is_super_user"
/** түрээсийн орон сууцад хамрагдах иргэн */
export const PERM_IS_WILL_RENT = "is_will_rent"
/** түрээсийн орон сууц хөтөлбөрийн зохион байгуулагч */
export const PERM_IS_ZB_OF_RENT = "is_zb_of_rent"
/** төрийн байгууллагын ажилтан эсэх */
export const PERM_IS_GOVERNMENT_ORGANIZATION = 'is_government_organization'
/** санхүүгийн зохицуулах хорооны админ эсэх */
export const PERM_IS_FINANCIAL_REGULATORY_COMMISSION_SUPER = "is_financial_regulatory_commission_super"
/** санхүүгийн зохицуулах хорооны ажилтан эсэх */
export const PERM_IS_FINANCIAL_EMPLOYEE = "is_financial_employee"
/** TOSK ажилтан эсэх */
export const PERM_IS_TOSK = "is_tosk"
/** Материал оруулдаг хүн эсэх */
export const PERM_IS_MATERIAL = "is_material"
/** Барилгын хөгжлийн төв эсэх */
export const PERM_IS_BHTUV = "is_bhtuv"

export const NO_NEED_PERM = "no_need_perm"
export const PERM_CREATE = "create"
export const PERM_READ = "read"
export const PERM_UPDATE = "update"
export const PERM_DELETE = "delete"

export const ZEREGLEL_TYPE = [
    {
        value: 1,
        label: "Тансаг"
    },
    {
        value: 2,
        label: "Бага дунд"
    },

    {
        value: 3,
        label: "Нийтийн"
    },
    {
        value: 4,
        label: "Амины"
    },
]

export const PROPERTY = [
    { value: 1, label: "Эзэмших" },
    { value: 2, label: "Өмчлөх" },
    { value: 3, label: "Түрээслэх" },
];

export const TONOGLOL = [
    { value: 1, label: "Налуу зам" },
    { value: 2, label: "Цахилгаан шат" },
    { value: 4, label: "Налуу зам, цахилгаан шаттай" },
    { value: 3, label: "Тоноглол байхгүй" },
]

export const BUTETS_TYPE = [
    { value: 1, label: 'Угсармал' },
    { value: 2, label: 'Цутгамал' },
    { value: 3, label: 'Төмөр бетон' },
    { value: 4, label: 'Блок' },
    { value: 5, label: 'Тоосго'},
    { value: 6, label: 'Мод'},
    { value: 7, label: 'Мод болон тоосго'},
    { value: 8, label: 'ӨРӨГТ'},
    { value: 9, label: 'Төмөр'},
    { value: 10,label:  'Бусад'},
]

export const STATES = [
    { value: 1, label: 'Зарагдаагүй' },
    { value: 2, label: 'Зарагдсан' },
    { value: 3, label: 'Захиалгат' },
]

export const IS_RENT = [
    { value: true, label: 'Тийм' },
    { value: false, label: 'Үгүй' },
]

export const MAIN_TOAST_THEME = "colored"

export const UL_HODLOH_TURUL = [
    { value: 1, label: 'Орон сууц (шинэ)' },
    { value: 2, label: 'Орон сууц (хуучин)' },
    { value: 3, label: 'Газар' },
    { value: 4, label: 'Арилжаа' },
    { value: 5, label: 'Үйлдвэрлэл' },
]

export const UL_HODLOH_TURUL_TUREES = [
    { value: 2, label: 'Орон сууц (хуучин)' },
    { value: 3, label: 'Газар' },
    { value: 4, label: 'Арилжаа' },
    { value: 5, label: 'Үйлдвэрлэл' },
]

export const UIL_AJILLAGAANII_TURUL = [
    { value: 1, label: 'Ажиллагаа 1' },
    { value: 2, label: 'Ажиллагаа 2' },
    { value: 3, label: 'Ажиллагаа 3' },
    { value: 4, label: 'Ажиллагаа 4' },
    { value: 5, label: 'Ажиллагаа 5' },
]

export const GUILGEE_TURUL = [
    { value: 1, label: 'БМГ' },
    { value: 2, label: 'ГТТГ' },
    { value: 3, label: 'СГ' },
]
export const ROOM_ICONS = {
    "kitchen": "far fa-bath",
    "toilet": "fal fa-toilet",
    "bedroom": "far fa-bed",
    "livingroom": "fal fa-couch",
    'total': "far fa-th-large"
}

export const RESULT_ICONS = {
    "school": "fal fa-school",
    "kindergarten": "fal fa-baby-carriage",
    "bus-stop": "fal fa-bus",
    "supermarket": "fal fa-shopping-cart",
    "hospital": "fal fa-hospital",
    "emiin_san": "fal fa-clinic-medical",
}

export const YES_OR_NO_CHOICE = [
    { value: "1", label: "Тийм" },
    { value: "0", label: "Үгүй" }
]

export const NOT_FIRST_QUERY_KEYS = ['project-name', 'unit1-code', 'unit2-code', 'unit3-code']


export const LEGAL_PAGE_NAME = 'legal'
export const RULES_PAGE_NAME = 'rules'
export const NEWS_PAGE_NAME = 'news'

export const ORG_PROP_TYPES = [
    {
        value: 1,
        label: 'ХК'
    },
    {
        value: 2,
        label: 'ХХК'
    },
    {
        value: 3,
        label: 'ТББ'
    },
    {
        value: 4,
        label: 'ГХОХХК'
    },
    {
        value: 5,
        label: 'ТӨСЛИЙН НЭГЖ'
    },
    {
        value: 6,
        label: 'ТӨРИЙН ӨМЧИД ҮЙЛДВЭРИЙН ГАЗАР'
    },
    {
        value: 7,
        label: 'ОРОН НУТГИЙН ӨМЧИД ҮЙЛДВЭРИЙН ГАЗАР'
    },
]

export const ONE_MONTH_TO_MILLISECONDS = 2629800000

export const ACCESS_FIELD_NAMES = [
	"building-is-rent",
	"unit1-code",
	"unit2-code",
	"unit3-code",
	"project-name",
	"zereglel-type",
	"mkv-end",
	"mkv-begin",
	"total-room",
	"floor-begin",
	"floor-end",
    "butets-type",
    "room-choice"
]

export const ACCESS_FIELD_ENG_NAMES = {
    'Аймаг/хот': "unit1-code",
    'Сум/Дүүрэг': "unit2-code",
    'Баг/хороо': "unit3-code",
    'Төслийн нэр': "project-name",
    'Зэрэглэл': "zereglel-type",
    "м² дуусах": "mkv-end",
    "м² эхлэх": "mkv-begin",
    "Өрөө": "total-room",
    "Давхар дуусах": "floor-end",
    "Давхар эхлэх": "floor-begin",
    "Түрээс": "building-is-rent",
    "Барилгын бүтэц": "butets-type",
    "Өрөөний төрөл": "room-choice"
}
export const ACCESS_FIELD_NAMES_SELECT = [
    { value: "unit1-code", label: 'Аймаг/хот' },
    { value: "unit2-code", label: 'Сум/дүүрэг'},
    { value: "unit3-code", label: 'Баг/хороо'},
    { value: "project-name", label: 'Төсөл'},
    { value: "zereglel-type", label: 'Зэрэглэл'},
    { value: "mkv-end", label: "м² хүртэл"},
    { value: "mkv-begin", label: "м² эхлэх"},
    { value: "total-room", label: "Нийт өрөө"},
    { value: "floor-begin", label: "Давхар эхлэх"},
    { value: "floor-end", label: "Давхар хүртэл"},
    { value: "butets-type", label: "Барилгын бүтэц"},
    { value: "room-choice", label: "Өрөөний төрөл"},
]

export const DATETYPES = [
	{ value: 0, label: 'Статистик харах хугацаа сүүлийн:', isDisabled: true },
	{ value: 1, label: '1 сар' },
	{ value: 2, label: '2 сар' },
	{ value: 3, label: '3 сар' },
	{ value: 6, label: '6 сар' },
	{ value: 12, label: '1 жил' },
	{ value: 24, label: '2 жил' },
	{ value: 36, label: '3 жил' },

]

export const HOUSING_TYPES = [
    { value: 1, label: 'Нийтийн зориулалттай (1)' },
    { value: 2, label: 'Амины (2)' }
]

export const OMCHIIN_HELBER_TYPES = [
    { value: 1, label: 'Төрийн өмч (1)' },
    { value: 2, label: 'Хувийн өмч (2)' }
]

export const BAIRSHIL_TYPES = [
    { value: 1, label: 'Нийслэлд (1)' },
    { value: 2, label: 'Аймгийн төвд (2)' },
    { value: 3, label: 'Сумын төвд (3)' },
    { value: 4, label: 'Хөдөө (4)' },
]

export const TSEVER_USNII_TYPES = [
    { value: 1, label: 'Төвлөрсөн (1)' },
    { value: 2, label: 'Төвлөрсөн бус (2)' }
]

export const HALUUN_USNII_TYPES = [
    { value: 1, label: 'Төвлөрсөн (1)' },
    { value: 2, label: 'Бие даасан/ бойлер (2)' },
    { value: 3, label: 'Халуун усгүй (3)' }
]

export const ARIUTGAH_USNII_TYPES = [
    { value: 1, label: 'Төвлөрсөн (1)' },
    { value: 2, label: 'Бие даасан/ бохир усны цооног (2)' }
]


export const HALAALT_USNII_TYPES = [
    { value: 1, label: 'Төвлөрсөн (1)' },
    { value: 2, label: 'Бие даасан/ бохир усны цооног (2)' }
]


export const NALUU_ZAM_TYPES = [
    { value: 1, label: 'Налуу зам, цахилгаан шаттай (1)' },
    { value: 2, label: 'Тоноглол байхгүй (2)' }
]

export const BARILGA_HIITS_TYPES = [
    { value: 1, label: 'Бетон карказан (1)' },
    { value: 2, label: 'Төмөр карказан (2)' },
    { value: 3, label: 'Бүрэн цутгамал (3)' },
    { value: 4, label: 'Тоосго (4)' },
    { value: 5, label: 'Угсармал (5)' },
    { value: 6, label: 'Модон (6)' },
    { value: 7, label: 'Тоосго (7)' },
    { value: 8, label: 'Канад технологи (8)' },
]

export const DAN_GANZ_BARILGA_TYPES = [
    { value: 1, label: 'Хотхон хэлбэрээр (1)' },
    { value: 2, label: 'Дангаараа ганц барилга (2)' }
]


export const CRUDTYPES = [
    { value: 'get', label: 'Авах (GET)' },
    { value: 'post', label: 'Үүсгэх (POST)' },
    { value: 'put', label: 'Өөрчлөх (PUT)' },
    { value: 'delete', label: 'Устгах (DELETE)' },
]

export const CRUD_COLOR = {
    get: '#60AFFE',
    post: '#49CC90',
    put: '#FCA131',
    delete: '#F93F3E',
}

export const ORG_TYPES = [
    { value: 1, label: 'Сургууль' },
    { value: 2, label: 'Эмийн сан' },
    { value: 3, label: 'Эмнэлэг' },
    { value: 4, label: 'Цэцэрлэг' },
]

export const OMCH_HELBER = [
    { value: 1, label: 'Төрийн өмч' },
    { value: 2, label: 'Хувийн өмч' },
]

export const HEREGJILT = [
    { value: 1, label: 'Төслийн талбай' },
    { value: 2, label: 'Хэрэгжиж буй төсөл' },
    { value: 3, label: 'Дууссан төсөл' },
]

export const HEREGJILT_COLOR = {
    1: "gray",
    2: "orange",
    3: "blue",
}

export const TOLOVSHIL = [
    { value: 1, label: 'Бага' },
    { value: 2, label: 'Дунд' },
    { value: 3, label: 'Өндөр' },
    { value: 4, label: 'Онцгой' },
]

export const HUCH_CHADAL_NEGJ = [
    { value: 1, label: 'Айл' },
    { value: 2, label: 'Ор' },
    { value: 3, label: 'Суудал' },
    { value: 4, label: 'Машин' },
    { value: 5, label: 'м2' },
    { value: 6, label: 'м3' },
    { value: 7, label: 'кВт' },
    { value: 8, label: 'м' },
    { value: 9, label: 'км' },
]

export const BARILGA_AJLIN_TOROL = [
    { value: 1, label: 'Барилга байгууламжийг засварлах, тоноглох' },
    { value: 2, label: 'Гадна талбайн тохижилт' },
    { value: 3, label: 'Барилга байгууламж шинээр барих' },
    { value: 4, label: 'Барилга байгууламжийг өргөтгөх, шинэчлэх' },
    { value: 5, label: 'Буулгалтын ажил' },
]

export const UL_HODLOH_HORONGIIN_TURUL_CHOICES_V2 = [
    { value: 1, label: 'Тансаг зэрэглэлийн орон сууц' },
    { value: 2, label: 'Нийтийн орон сууц' },
    { value: 3, label: 'Зуслангийн байшин' },
    { value: 4, label: 'Хашаа байшин' },
]

export const UL_HODLOH_HORONGIIN_TURUL_CHOICES_V3 = [
    { value: 1, label: 'Арилжааны зориулттай ҮХХ' },
    { value: 2, label: 'Газар' },
    { value: 3, label: 'Үйлдвэрийн зориулалттай ҮХХ' },
    { value: 4, label: 'Авто зогсоол' },
]

export const USER_ROLE_CHOICES = [
    { value: 'is_super_user', label: 'системийн админ мөн эсэх' },
    { value: 'is_zb_of_rent', label: 'түрээсийн орон сууц хөтөлбөрийн зохион байгуулагч' },
    { value: 'is_news_admin', label: 'мэдээний админ' },
    { value: 'is_read_graphic', label: 'graphic хардаг' },
    { value: 'is_citizen', label: 'иргэн мөн эсэх' },
    { value: 'is_will_rent', label: 'түрээсийн орон сууцад хамрагдах иргэн' },
    { value: 'is_only_read', label: 'бүх мэдээлэл харах' },
    { value: 'is_from_building', label: 'барилга угсралтын компани' },
    { value: 'is_from_project', label: 'зураг төслийн компани' },
    { value: 'is_org_admin', label: 'Байгууллагын админ' },
    { value: 'is_government_organization', label: 'Төрийн байгууллага' },
    { value: 'is_financial_regulatory_commission_super', label: 'Санхүүгийн зохицуулах хороо' },
    { value: 'is_financial_employee', label: 'Санхүүгийн зохицуулах хороотой холбоотой' },
    { value: 'is_material', label: 'Материал бүртгэл' },
    { value: 'is_bhtuv', label: 'Барилгын хөгжлийн төв эсэх' },
]
