import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import CreateButton from 'components/main/CudButtons/Create';
import DeleteButton from 'components/main/CudButtons/Delete';
import UpdateButton from 'components/main/CudButtons/Update';
import GetOneDetail from 'components/special/GetOneDetail';
import CTable from 'components/main/Table';

import useLoader from 'hooks/useLoader';
import useModal from 'hooks/useModal';
import useApi from 'hooks/useApi';

import { govPageRoles } from 'utils/consts/roles';
import label from 'utils/validate/label';
import { timeZoneToDateString } from 'utils/format';

/** Дэд арга хэмжээ буцаана */
const SubAgenda = () => {

    const [ rows, setRows ] = useState([]);
    const navigate = useNavigate();
    const { agendaId, programId } = useParams()

    const subAgendaApi = useApi().subAgenda
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
    const { setDisplay } = useModal()

    /** Modal гаргаж ирэх funct */
    const displayDetail = (id) =>
    {
        setDisplay(
            {
                header:
                {
                    title: label['subagenda'] + " дэлгэрэнгүй",
                },
                body: <GetOneDetail api={subAgendaApi.getOne(programId, agendaId, id)}/>,
                size: "lg",
                footer:
                {
                    show:false
                }
            }
        )
    }

    /** Хүснэгтийн багана */
    const columns =
    [
        {
            field: "subagenda",
            order: false,
            width: 400,
            minWidth: 400,
            maxWidth: 400,
        },
        {
            field: "units",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue?.name}</span>
        },
        {
            field: "basestatus",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "currentstatus",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "futurestatus",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "end_date",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "result",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "created_at",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue)}</span>
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }


    /** Хүснэгтний утгыг авах */
    const getData = async () =>
    {
        const resp = await fetchData(subAgendaApi.get(programId, agendaId))
        if(resp.success)
        {
            setRows(resp.data)
        }
    }

    // back руу хүсэлт шидэх
    useEffect(
        () =>
        {
            getData()
        },
        []
    )

    /** Хүснэгтийн мөрийн утгыг устгах */
    const deleteRow = async (id) =>
    {
        await fetchData(subAgendaApi.delete(programId, agendaId, id))
        await getData()
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.id}/`)} needRoles={govPageRoles}/>
                        <DeleteButton onClick={() => deleteRow(item.id)} needRoles={govPageRoles} />
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
        <Row>
            <Col sm={12}>
                <CreateButton btnClassName='floatRight' onClick={() => navigate('create/')} needRoles={govPageRoles}/>
            </Col>
            <Col>
                <CTable
                    isLoading={isLoading}
                    columns={columns}
                    sequenceColumn={sequenceColumn}
                    extraColumns={extraColumns}
                    rows={rows}
                />
            </Col>
        </Row>
    );
}

export default SubAgenda;
