import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'

import DatepickerInput from 'components/main/Datepicker/DatepickerInput'
import BoxBackground from 'components/special/BoxBackground'

import SignDonutChart from './SignDonutChart'
import SignToday from './SignToday'
import SignAccessDifference from './SignAccessDifference'
import SignAccessLineChart from './SignAccessLineChart'

import useApi from 'hooks/useApi'
import useLoader from 'hooks/useLoader'

import { ONE_MONTH_TO_MILLISECONDS } from 'utils/consts'

const SignAccess = () =>
{
    const [ date, setDate ] = useState([Date.now() - 3 * ONE_MONTH_TO_MILLISECONDS, Date.now()])
    const [ logged, setLogged ] = useState()
    const signAccessApi = useApi().adminAccessFront

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })


    const getData = async () =>
    {
        const { success, data, errors } = await fetchData(signAccessApi.getSignLog(date[0], date[1])).catch(err => err)
        if(success)
        {
            setLogged(data)
        }
    }

    useEffect(
        () =>
        {
            getData()
        },
        [date]
    )

    const handleDate = (value) =>
    {
        if(new Date(value[0]).getTime() === 0 || new Date(value[1]).getTime() === 0) return
		setDate([
			new Date(value[0]).getTime(),
			new Date(value[1]).getTime() !== 0 ? new Date(value[1]).getTime() : Date.now(),
		])
    }

    return (
		<>
			<Row>
				<Col md={2}>
					<DatepickerInput maxDate={Date.now()} selected={date} onChange={handleDate} selectsRange={true} />
				</Col>
			</Row>
			<BoxBackground title="Системд нэвтэрсэн хандалт" className='mt-2'>
				<Row>
					<Col md={8}>
						<SignToday logged={logged} />
					</Col>
					<Col md={4}>
						<SignAccessDifference />
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<SignDonutChart logged={logged} />
                    </Col>
					<Col md={8}>
						<SignAccessLineChart date={date} />
                    </Col>
				</Row>
			</BoxBackground>
		</>
	)
}

export default SignAccess
