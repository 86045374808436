import React, { Fragment, useContext, useMemo, useRef } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import { HeaderContext } from 'context/special/headerContext';

import useMobile from 'hooks/useMobile';

import Sidebar from 'components/main/Sidebar';
import RoleRequired from 'components/main/RoleRequired';

import css from './style.module.css'

const Layout = ({ links=[], sidebarTitle, sidebarOptions, pathIsId=false, IdName='/:Id/*', isLoading, chooseItemText }) =>
{

    const navigate = useNavigate()
    const { setTogglerState, togglerState } = useContext(HeaderContext)

    const { isMobile } = useMobile(800)

    /** Цэсний route бэлдэх */
    const mainRoutes = () =>
    {
        return links.map((route, idx) =>
        {
            return (
                <Fragment key={idx}>
                    {
                        route.firstActive &&
                        <Route
                            path={`/`}
                            element={<Navigate to={`${route.layout}${route.path.replace('/*','/')}`} replace />}
                        />
                    }
                    <Route
                        path={pathIsId ? IdName : route.path}
                        element={
                            <RoleRequired needRoles={route?.needRoles ? route.needRoles : []} isNavigate={route.isNavigate || false}>
                                {route.component ? <route.component /> : null}
                            </RoleRequired>
                        }
                    />
                    {route.subMenu && subRoutes(route)}
                </Fragment>
            );
        });
    }

    /** Дэд цэсний route бэлдэх */
    const subRoutes = (routeWithSubMenu) =>
    {
        return routeWithSubMenu.subMenu.map((route, idx) =>
        {
            return (
				<Fragment key={idx}>
                    {
                        route.firstActive &&
                        <Route
                            path={`${routeWithSubMenu.path.replace("/*", "")}`}
                            element={<Navigate to={`${routeWithSubMenu.layout}${routeWithSubMenu.path.replace("/*", "")}${route.path.replace("/*", "/")}`} />}
                        />
                    }
					<Route
                        path={`${routeWithSubMenu.path.replace("/*", "")}${route.pathId ? route.pathId : route.path}`}
                        element={
                            <RoleRequired needRoles={route?.needRoles ? route.needRoles : []} isNavigate={route.isNavigate || false}>
                                {route.component ? <route.component /> : null}
                            </RoleRequired>
                        }
                    />
				</Fragment>
			)
        });
    }

    const location = useLocation()

    const displayChooseOptions = useMemo(
        () =>
        {
            let link = links?.[0]?.layout

            if(link?.slice(-1) !== '/')
            {
                link = links?.[0]?.layout + '/'
            }

            if(location.pathname === link && !links?.[0]?.exact)
            {
                return (
					<div className={css.chooseItem}>
						<div>
							<i className="fal fa-ballot-check"></i><br/>
							<span>{chooseItemText ? chooseItemText : 'Цэснээс сонгоно уу'}</span>
						</div>
					</div>
				)
            }
            return null
        },
        [location.pathname, links]
    )

    return (
        <>
            {isMobile && togglerState && (
                <div onClick={() => setTogglerState(false)} className="offcanvas-backdrop fade show" bis_skin_checked="1"></div>
            )}
            <div className={css.sideBar}>
                <Sidebar isLoading={isLoading} {...sidebarOptions} sideBarTitle={sidebarTitle} links={links} />
            </div>
            <Container fluid>
                <div className={css.mainContent}>
                    <Routes>{mainRoutes()}</Routes>
                    {
                       displayChooseOptions
                    }
                </div>
            </Container>
        </>
    );
}

export default Layout;
