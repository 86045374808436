import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import useRole from 'hooks/useRole';

import { PERM_CREATE } from 'utils/consts'

/** crud үйлдэл хийхдээ энэхүү update ийг ашиглана */
const DuplicateButton = ({ onClick, className, needRoles=[] }) =>
{
    const hasPerm = useRole(needRoles, PERM_CREATE)

    if (!hasPerm)
        return null

    return (
        <OverlayTrigger
            placement="left"
            delay={{ show: 0, hide: 0 }}
            overlay={<Tooltip id="button-tooltip-2">Хуулбар үүсгэх</Tooltip>}
        >
            <div onClick={onClick} style={{ cursor: 'pointer' }}>
                <i className={`fas fa-copy ${className}`}></i>
            </div>
        </OverlayTrigger>
    );
}

export default DuplicateButton
