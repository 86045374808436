import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import ImageFetch from 'components/main/Image';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { quantityFormat, timeZoneToDateString } from 'utils/format';
import { makeFullName } from 'utils';

import css from '../style.module.css'

const NewsMore = ({ isNews }) => {

	const [ data, setData ] = useState({})
	const { newsId, pageId: paramPageId } = useParams()

	const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })
	const newsApi = useApi().news

	const getNewsData = async () =>
	{
		const { success, data } = await fetchData(newsApi.front.getOne( paramPageId, newsId))
			if(success)
			{
				setData(data)
			}
	}

	useEffect(
		async () =>
		{
			await getNewsData()
		},
		[newsId]
	)

    return (
			<div className={`${css.oneNews}`}>
				{
					isLoading
					?
						<div style={{ marginTop: '20vh', marginBottom: '20vh' }}>
							{Loader}
						</div>
					:
						<>
							<div className="mb-2">
								<div className={css.newsTitle}>{data.title}</div>
								{isNews && <div className="mt-1 fs-13">Мэдээг оруулсан {makeFullName(data.reporter_info?.last_name, data.reporter_info?.first_name)}</div>}
							</div>
							<div className={css.titlesImageContainer}>
								<ImageFetch className={css.oneImage} src={data.image} />
							</div>
							<div className={css.longDesc}>
								<div>
									{data.commentary}
								</div><br/>
								<div dangerouslySetInnerHTML={{ __html: data.content }}></div>
							</div>
							<div className={css.numberContainer}>
								<div className={css.newsSmallText}>
									<i className={`far fa-clock tx-blue ${css.txBlue}`} /> {timeZoneToDateString(data.created_at)}
								</div>
								<div className={css.newsSmallText}>
									<i className={`far fa-eye tx-blue ${css.txBlue}`} /> {quantityFormat(data.count)}
								</div>
							</div>
						</>
				}
			</div>
	)
}

export default NewsMore;
