import React, { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import CreateButton from 'components/main/CudButtons/Create';
import DeleteButton from 'components/main/CudButtons/Delete';
import UpdateButton from 'components/main/CudButtons/Update';
import GetOneDetail from 'components/special/GetOneDetail';
import { ASC, DESC } from 'components/main/Table/Thead';
import Datatable from 'components/main/Datatable';
import AccessIcons from 'components/special/AccessIcons'

import useModal from 'hooks/useModal';
import useApi from 'hooks/useApi';

import { toskPageRoles } from 'utils/consts/roles';
import { moneyFormat, timeZoneToDateString } from 'utils/format';
import CButton from 'components/main/Button';
import AddMore from 'components/main/CudButtons/AddMore';
import OldSongonWinner from './OldSongonWinner';

const SongonList = () =>
{
    const navigate = useNavigate();
    const dataTableRef = useRef(null)
    const songonApi = useApi().songon
    const { setDisplay, setShow } = useModal()

    let { projectId, projectType } = useParams()

    /** Modal гаргаж ирэх funct */
    const displayDetail = (id) =>
    {
        setDisplay(
            {
                header:
                {
                    title: "Төслийн дэлгэрэнгүй",
                },
                body: (
                    <GetOneDetail
                        ignoreList={["pdf_url", "hutulbur", "is_end"]}
                        api={songonApi.getOne(id)}
                    />
                ),
                size: "lg",
                footer:
                {
                    show:false
                }
            }
        )
    }

    /** Хүснэгтийн багана */
    const columns =
    [
        {
            field: "name",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span className='tablerow-detail' onClick={() => displayDetail(row.id)}>{fieldValue}</span>,
            // searchField: false
        },
        {
            field: "project_name",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>,
            searchField: 'project__project_name'
        },
        {
            field: "start_date",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue || 0)}</span>,
        },
        {
            field: "end_date",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue || 0)}</span>,
        },
        {
            field: "is_end",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{<AccessIcons isTrue={fieldValue} />}</span>,
        },
        {
            field: "created_at",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue)}</span>,
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтний утгыг авах */
    const getData = async () =>
    {
        dataTableRef.current.firstGet = true
        dataTableRef.current.reData({}, null, [projectId])
    }

    /** Хүснэгтийн мөрийн утгыг устгах */
    const deleteRow = async (id) =>
    {
        await songonApi.delete(id)
        getData()
    }

    const dispayWinnerModal = (item) =>
    {
        setDisplay(
            {
                header:
                {
                    title: "Дууссан сонгон шалгаруулалтын ялагч бүртгэх",
                },
                body: (
                    <OldSongonWinner setShow={setShow} songonId={item.id} songon={item}/>
                ),
                size: "xl",
                footer:
                {
                    show:false
                },
                backdrop: 'static',
            }
        )
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.id}/`)} needRoles={toskPageRoles} />
                        <DeleteButton className='me-2' onClick={() => deleteRow(item.id)} needRoles={toskPageRoles} />
                        { projectType === 'old' && <AddMore tooltipText='Ялсан хүмүүсийг бүртгэх' icon='fas fa-users' onClick={() => dispayWinnerModal(item)} needRoles={toskPageRoles} /> }
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
		<Row>
			<Col sm={12}>
				<div className="d-flex floatRight">
                    {
                        projectType === 'new'
                        ?
                            <CreateButton onClick={() => navigate("create/")} needRoles={toskPageRoles} />
                        :
                            <CreateButton icon='fas fa-scroll-old' title='Дууссан сонгон шалгаруулалт бүртгэх' onClick={() => navigate("create/")} needRoles={toskPageRoles} />
                    }
				</div>
			</Col>
			<Col>
				<Datatable
					dRef={dataTableRef}
					columns={columns}
					sequenceColumn={sequenceColumn}
					extraColumns={extraColumns}
					tableOptions={{
						defaultSort: {
							type: DESC,
							field: "created_at",
						},
					}}
					request={{
						axios: songonApi.admin.pagination,
						params: [projectId],
					}}
					isFirstGetData={true}
				/>
			</Col>
		</Row>
	)
}

export default SongonList;
