import { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';

const duration = 300;

const defaultStyle = {
  transition: `${duration}ms ease-in-out`,
  height: 0,
  overflow: 'hidden'
}

const transitionStyles = {
  entering: {  height: '100px' },
  entered:  {  height: '100px' },
};

const TestTransitionGroup = () =>
{
    const [ show, setShow ] = useState(false)
    useEffect(
        () =>
        {
            setTimeout(
                () =>
                {
                    setShow(!show)
                },
                2000
            )
        },
        [show]
    )
    return (
		<>
			<Transition in={show} timeout={duration}>
				{state => (
					<div
						style={{
							...defaultStyle,
							...transitionStyles[state],
						}}>
						I'm a fade Transition!<br/>
						I'm a fade Transition!<br/>
						I'm a fade Transition!<br/>
						I'm a fade Transition!<br/>
						I'm a fade Transition!<br/>
					</div>
				)}
			</Transition>
			<button onClick={() => setShow(!show)}>asdas</button>
		</>
	)
}

export default TestTransitionGroup
