import React from "react"
import ContentLoader from "react-content-loader"

const TabSkeLoader = props => (
	<ContentLoader speed={2} width={400} height={160} viewBox="0 0 400 160" backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props}>
		<rect x="15" y="90" rx="3" ry="3" width="250" height="18" />
		<rect x="15" y="60" rx="3" ry="3" width="250" height="18" />
		<rect x="15" y="30" rx="3" ry="3" width="250" height="18" />
		<rect x="15" y="0" rx="3" ry="3" width="250" height="18" />
	</ContentLoader>
)

export default TabSkeLoader
