import React from 'react';

import { POPUP_MOBILE_ID } from 'hooks/map/CMap';

import '../../style.css'

const Mobile = ({ children, handleClose }) =>
{
    return (
        <div className="popup-mobile-container d-none" id={POPUP_MOBILE_ID}>
            <span onClick={handleClose} className="popup-close-button"><i className="fa-solid fa-xmark"></i></span>
            {children}
        </div>
    );
}

export default Mobile;
