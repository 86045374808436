import React from 'react';
import { Col } from 'react-bootstrap';

import css from '../style.module.css'

const SpecialItem = ({ text, status=true }) => {
    return (
		<Col sm={6} md={4} className={css.specialitem}>
			{status ? <i className={`far fa-check ${css.statusTrue}`}></i> : <i className={`far fa-times ${css.statusFalse}`}></i>}
			<span>{text}</span>
		</Col>
	)
}

export default SpecialItem;
