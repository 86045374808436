import React, { useState } from 'react';

const TestTable = () => {
    return (
		<table>
			<thead>
				<tr>
					<th></th>
					<th >gg212</th>
					<th colSpan={3}>GGG???d asasdadsads </th>
					<th></th>
					<th></th>
					<th></th>
					<th></th>
					<th colSpan={3}>GGG???d asasdadsads </th>
					<th></th>
					<th></th>
					<th></th>
				</tr>
				<tr>
					<th>dd1</th>
					<th>dd2</th>
					<th>dd</th>
					<th>mm</th>
					<th>yyyy</th>
					<th>dd</th>
					<th>mm</th>
					<th>yyyy</th>
					<th>dd</th>
					<th>mm</th>
					<th>yyyy</th>
					<th>dd</th>
					<th>mm</th>
					<th>yyyy</th>
					<th>dd</th>
					<th>mm</th>
					<th>yyyy</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>P</td>
					<td>O</td>
					<td>14</td>
					<td>06</td>
					<td>1978</td>
				</tr>
				<tr>
					<td>V</td>
					<td>B</td>
					<td>12</td>
					<td>01</td>
					<td>1985</td>
				</tr>
				<tr>
					<td>L</td>
					<td>L</td>
					<td>01</td>
					<td>03</td>
					<td>1956</td>
				</tr>
				<tr>
					<td>E</td>
					<td>P</td>
					<td>28</td>
					<td>02</td>
					<td>1979</td>
				</tr>
			</tbody>
		</table>
	)
}

export default TestTable;
