import React, { useEffect, useState } from "react"
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

import DataNotFound from "components/main/DataNotFound"

import { quantityFormat } from "utils/format"

import css from '../style.module.css'

const mnNames = {
    1: 'Сургууль',
    2: 'Эмийн сан',
    3: 'Эмнэлэг',
    4: 'Цэцэрлэг',
}

const OrgColumnChart = ({ chartData }) =>
{
    const [ emptyData, setEmptyData ] = useState(false)
    useEffect(
        () =>
        {
            if(!chartData) return 

            const newData = chartData.map(
                (elemnt) =>
                {
                    return { ...elemnt, type: mnNames[elemnt.type] }
                }
            )

			let root = am5.Root.new("orgColumhChartDIv")

			root.setThemes([am5themes_Animated.new(root)])

			let chart = root.container.children.push(
				am5xy.XYChart.new(root, {
					panX: false,
					panY: false,
					wheelX: "none",
					wheelY: "none",
				}),
			)

			chart.zoomOutButton.set("forceHidden", true)

			let yRenderer = am5xy.AxisRendererY.new(root, {
				minGridDistance: 30,
			})

			let yAxis = chart.yAxes.push(
				am5xy.CategoryAxis.new(root, {
					maxDeviation: 0,
					categoryField: "type",
					renderer: yRenderer,
					tooltip: am5.Tooltip.new(root, { themeTags: ["axis"] }),
				}),
			)

			let xAxis = chart.xAxes.push(
				am5xy.ValueAxis.new(root, {
					maxDeviation: 0,
					min: 0,
					extraMax: 0.1,
					renderer: am5xy.AxisRendererX.new(root, {}),
				}),
			)

			let series = chart.series.push(
				am5xy.ColumnSeries.new(root, {
					name: "Series 1",
					xAxis: xAxis,
					yAxis: yAxis,
					valueXField: "count",
					categoryYField: "type",
					tooltip: am5.Tooltip.new(root, {
						pointerOrientation: "left",
						labelText: "{valueX}",
					}),
				}),
			)

			series.columns.template.setAll({
				cornerRadiusTR: 5,
				cornerRadiusBR: 5,
			})

			series.columns.template.adapters.add("fill", function (fill, target) {
				return chart.get("colors").getIndex(series.columns.indexOf(target))
			})

			series.columns.template.adapters.add("stroke", function (stroke, target) {
				return chart.get("colors").getIndex(series.columns.indexOf(target))
			})

			yAxis.data.setAll(newData)
			series.data.setAll(newData)

			chart.set(
				"cursor",
				am5xy.XYCursor.new(root, {
					behavior: "none",
					xAxis: xAxis,
					yAxis: yAxis,
				}),
			)

            chart.children.unshift(
                am5.Label.new(root, {
                    text: "Төрийн үйлчилгээ",
                    fontSize: 15,
                    fill: "#272B33",
                    x: am5.percent(50),
                    y: am5.percent(0),
                    centerX: am5.percent(50),
                    centerY: am5.percent(50),
                }),
            )

			series.bullets.push(function () {
				return am5.Bullet.new(root, {
					locationX: 1,
					locationY: 0.5,
					sprite: am5.Label.new(root, {
						centerX: am5.p100,
						centerY: am5.p50,
						text: "{valueX}",
						fill: am5.color(0xffffff),
						populateText: true,
					}),
				})
			})

            series.appear(1000)
			chart.appear(1000, 100)

			return () => {
				root.dispose()
			}
		},
        [chartData]
    )

    return (
		<div
			className={css.customBottom}
			id="orgColumhChartDIv"
			style={{ width: "100%", height: "250px", position: "relative", border: '1px solid rgba(0,0,0,0.15)', borderRadius: '5px'  }}>
			<div className="hidelogo"></div>
			{emptyData && (
				<div>
					<DataNotFound />
				</div>
			)}
		</div>
	)
}

export default OrgColumnChart
