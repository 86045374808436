import ChooseItem from "components/main/Form/InputTypes/ChooseItem"
import { useMemo } from "react"

const TestSelectItem = () =>
{
    const fakeData = [
        {
            value: '1',
            label: '1',
        },
        {
            value: '2',
            label: '2',
        },
        {
            value: '3',
            label: '3',
        },
        {
            value: '4',
            label: '4',
        },
    ]



    return (
           <div className="m-5">
                <ChooseItem data={fakeData}/>
           </div>
        )
}

export default TestSelectItem
