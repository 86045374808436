import React, { Component, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

/**
 *
 * @param {object} label -ийн тохиргоо
 * @param {boolan} label.empty label дээр * харуулах эсэх энэ input хоосон байж болох бол true илгээнэ
 * @param {boolean} label.hide input-ийн label ийг харуулах эсэх
 * @param {String} label.text label-ийн харагдах text
 *
 * @param {String} placeholder input ийн placeholder
 * @param {string} type input н ямар төрөлтэй? email, password, number etc...
 *
 * @param {boolean} disabled input disabled эсэх
 * @param {boolean} readOnly input readonly байх эсэх
 *
 * @param {function} onChange input утга солигдоход ажиллах fucnt
 *
 * @param {Array} errors yup ийн validate алдаа
 * @param {object} register useform -д бүртгүүлэх
 *
 * @param {Component} inputGroupStart input-ийн эхэнд ямар icon гаргах
 * @param {Component} inputGroupEnd input-ийн төгсгөлд ямар icon гаргах
 *
 * @param {Function} setError onchange үед seterror ийг явуулах шаардлага гарав
 *
 * @returns
 */
const CInput = ({ value, label, errors, disabled, onChange=null, placeholder, readOnly, getFieldState, setValue, register, type, inputGroupStart, inputGroupEnd, step, setError, getValues, className, realValue }) =>
{
    return (
        <Form.Group className={className}>
            <InputGroup >
                {inputGroupStart && <InputGroup.Text>{inputGroupStart}</InputGroup.Text>}
                <Form.Control
                    disabled={disabled}
                    {...register}
                    id={register?.name}
                    onChange={
                        (e) =>
                        {
                            onChange
                            ? onChange(e, { setValue, setError, register, registerName: register.name, onChange: register.onChange, getValues, getFieldState })
                            : register.onChange(e);
                        }
                    }
                    type={type}
                    as={type === 'textArea' ? 'textarea' : undefined}
                    rows={type === 'textArea' ? 3 : undefined}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    isInvalid={!!errors?.[register.name]?.message}
                    defaultValue={value}
                    value={realValue}
                    step={step}
                />
                {inputGroupEnd && <InputGroup.Text>{inputGroupEnd}</InputGroup.Text>}
            </InputGroup>
        </Form.Group>
    );
};

export default CInput;
