import React from 'react'

import styles from '../style.module.css'

export default function index() {
    return (
        <div className={`${styles.fullScreen} ${styles.loader} ${styles.backgroundBlack}`}>
            <div className={`${styles.reverseSpinner}`}>
            </div>
        </div>
    )
}
