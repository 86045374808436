import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import CreateButton from 'components/main/CudButtons/Create';
import DeleteButton from 'components/main/CudButtons/Delete';
import UpdateButton from 'components/main/CudButtons/Update';
import Datatable from 'components/main/Datatable';
import { DESC } from 'components/main/Table/Thead';
import OverlayElement from 'components/main/OverlayElement';

import ResidenceDetails from './ResidenceDetails';
import AddRoom from './AddRoom';

import useLoader from 'hooks/useLoader';
import useModal from 'hooks/useModal';
import useApi from 'hooks/useApi';

import { companyPageRoles } from 'utils/consts/roles';

const Residence = ({ mapProjectId=null, getResId=null }) => {

    const navigate = useNavigate();
    const dataTableRef = useRef(null)

    const residenceApi = useApi().residence;
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })
    const { setDisplay } = useModal()

    let { id: projectId } = useParams();
    projectId = mapProjectId ? mapProjectId : projectId

    /** Modal гаргаж ирэх funct */
    const displayDetail = (id) =>
    {
        setDisplay(
            {
                header:
                {
                    title: "Residence дэлгэрэнгүй",
                },
                body: <ResidenceDetails api={residenceApi.getOne(projectId, id)}/>,
                size: "lg",
                footer:
                {
                    show:false
                }
            }
        )
    }

    const getData = () =>
    {
        dataTableRef.current.firstGet = true
        dataTableRef.current.reData({}, null, [projectId])
    }

    /** Хүснэгтийн багана */
    const columns =
    [
        !mapProjectId &&
        {
            field: "project",
            headerName: "Төсөл",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue?.project_name}</span>,
            searchField: 'project__project_name',
        },
        {
            field: "total_mkv",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>,
            // searchField: false
        },
        {
            field: "total_room",
            order: true,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>,
            // searchField: false
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтийн мөрийн утгыг устгах */
    const deleteRow = async (resId) =>
    {
        await fetchData(residenceApi.delete(projectId, resId))
        getData()
    }

    /** тухайн residence ийн нийт өрөөний тоогоор өрөө бүртгэх
     */
    const handleRoom = (item) =>
    {
        setDisplay(
            {
                header:
                {
                    title: "Өрөө оруулах",
                },
                body: <AddRoom residenceId={item.id} totalRoom={item.total_room} projectId={item?.project?.id} totalMkv={item.total_mkv} />,
                size: "xl",
                backdrop: 'static',
                footer:
                {
                    show: false
                }
            }
        )
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => mapProjectId ? getResId(item.id) : navigate(`${item.id}/`)} needRoles={companyPageRoles}/>
                        <DeleteButton onClick={() => deleteRow(item.id)} needRoles={companyPageRoles}/>
                        <div className='ms-2'>
                            <OverlayElement
                                text="Өрөө оруулах"
                            >
                                <i role="button" onClick={() => handleRoom(item)} className="far fa-home-lg"></i>
                            </OverlayElement>
                        </div>
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
        <Row>
            <Col sm={12}>
                <CreateButton btnClassName='floatRight' onClick={() =>  mapProjectId ? getResId('create') : navigate('create/')} needRoles={companyPageRoles}/>
            </Col>
            <Col>
                <Datatable
                    dRef={dataTableRef}
                    columns={columns.filter(element => element)}
                    sequenceColumn={sequenceColumn}
                    extraColumns={extraColumns}
                    tableOptions={
                        {
                            defaultSort: {
                                type: DESC,
                                field: "total_mkv"
                            }
                        }
                    }
                    request={
                        {
                            axios: residenceApi.get,
                            params: [projectId]
                        }
                    }
                    isFirstGetData={true}
                />
            </Col>
        </Row>
    );
}

export default Residence;
