import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

import LotteryCard from 'components/special/LotteryCard';
import CButton from 'components/main/Button';

import useApi from 'hooks/useApi'
import useLoader from 'hooks/useLoader';
import useToggler from 'hooks/useToggler';
import useCState from 'hooks/useCState';

import css from '../style.module.css'
import useAuth from 'hooks/useAuth';
import { dataToValueLabel } from 'utils/format';
import CForm from 'components/main/Form';
import DataNotFound from 'components/main/DataNotFound';

const LotteryMain = () => {

    const navigate = useNavigate()
    const [ lotteries, setLotteries ] = useState([])
    const [ finished, setFinished ] = useState(false)

    const [ units, setUnits ] = useState([])
    const [ unit2Options, setUnit2Options ] = useState([])

    const { userDetail, isLogged } = useAuth()
    const [ unitDefault, setUnitDefault ] = useState({ unit1: '', unit2: '',unit3: ''})
    const [ togglerState, setTogglerState ] = useState(null)

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: false, hasBackground: false })
    const { initState, params, setParams, selectUnit1, setUnit1, selectUnit2, setUnit2, selectUnit3, setUnit3, gotUserDetail, setGotUserDetail, setFirst } = useCState()
    const lotteryApi = useApi().songon
    const unitApi = useApi().unit
    const [ didMounted, setDidMounted ] = useState(false)

    useEffect(
        async () =>
        {
            if(!selectUnit1 && !selectUnit2 && !selectUnit3) return
            if(didMounted) return
			const { success, data } = await fetchData(
				lotteryApi.get(togglerState, 0, params.limit, selectUnit1 === "0" ? null : selectUnit1, selectUnit2, selectUnit2 !== "" ? selectUnit3 : ""),
			)
			if (success) {
				setLotteries(data)
				if (Math.ceil(data.length % initState.limit !== 0)) setFinished(true)
			}
            setDidMounted(true)
		},
        [selectUnit1, selectUnit2, selectUnit3, togglerState]
    )

    useEffect(
        async () =>
        {
            const { data, success } = await fetchData(unitApi.get())
            if(success) setUnits(data)
        },
        []
    )

    const getData = async (skip, limit) =>
    {
        if(skip === 0) return
        const lotteryRsp = await fetchData(lotteryApi.get(togglerState, skip, limit, selectUnit1 === "0" ? null : selectUnit1, selectUnit2, selectUnit3))
        if (lotteryRsp.success)
        {
            setLotteries([...lotteries, ...lotteryRsp.data])
            if( lotteryRsp.data.length < initState.limit ) setFinished(true)
        }
    }

    const handleReadMore = async () =>
    {
        await setParams({ skip: params.skip + initState.limit, limit: params.limit + initState.limit })
        await getData(params.skip + initState.limit, params.limit + initState.limit)
    }

    const getDataWithUnit = async (key, value) => {
		setParams(initState)
		let object = {
			unit1_code: {
                fn:  () => {
                    setUnit1(value)
                    setFirst(false)
                },
                query: value === '0' ? '' : [value]
            },
			unit2_code: {
                fn:  () => setUnit2(value),
                query: [null, value]
            },
			unit3_code: {
                fn:  () => setUnit3(value),
                query: [null, null, value]
            },
		}
		object?.[key]?.fn()
		const { data, success } = await fetchData(
			lotteryApi.get(
				togglerState,
				initState.skip,
				initState.limit,
				...object?.[key]?.query
			),
		)
		if (success) {
			setLotteries(data)
			if (data.length < initState.limit) setFinished(true)
			if (data.length >= initState.limit) setFinished(false)
		}
	}

    useEffect(() =>
        {
            if(gotUserDetail) return
            setUnit1(userDetail.unit1 || '11')
            setUnitDefault({ unit1: userDetail.unit1 })
            setGotUserDetail(true)
        },
        [userDetail]
    )

    useEffect(
        async () =>
        {
            if(typeof togglerState !== 'boolean') return
            setParams(initState)
            setFinished(false)
            const lotteryRsp = await fetchData(lotteryApi.get(togglerState, 0, initState.limit, selectUnit1 === "0" ? null : selectUnit1, selectUnit2, selectUnit3))
            if (lotteryRsp.success)
            {
                setLotteries(lotteryRsp.data)
            }
        },
        [togglerState]
    )

    const displaySongon = useMemo(
        () =>
        {
            return lotteries.map(
                (element, index) =>
                {
                    return (
                        <Col lg={4} md={6} sm={12} className="mb-4" key={index}>
                            <LotteryCard
                                isJoinedUser={element.is_joined}
                                onClickTitle={() => navigate(`${element.id}/`)}
                                title={element.name}
                                image={element?.project?.eskiz}
                                players={element.songonshalgaruulaltuser_count}
                                status={!element.is_end}
                                date={[element.end_date, element.start_date]}
                                navigateTo={`${element.id}/`}
                            />
                        </Col>
                    )
                }
            )
        },
        [lotteries]
    )
    const secondUnits = useMemo(
        () =>
        {
            // if(!selectUnit2) return null
            if(!selectUnit2.startsWith(selectUnit1)) setUnit2('')
            let unit1Id = typeof selectUnit1 === "object" ? selectUnit1.code : selectUnit1
            const unit = units?.find(
                (el) =>
                {
                    return el.code === unit1Id
                }
            )
            setUnit2Options(unit?.unit2)
            return dataToValueLabel(unit?.unit2, 'code', 'name')
        },
        [selectUnit1, units]
    )

    const thirdUnits = useMemo(
        () =>
        {
            // if(!selectUnit3) return null
            if(!selectUnit3.startsWith(selectUnit2)) setUnit3('')
            let unit2Id = typeof selectUnit2 === "object" ? selectUnit2.code : selectUnit2
            const unit = unit2Options?.find(
                (el) =>
                {
                    return el.code === unit2Id
                }
            )
            return dataToValueLabel(unit?.unit3, 'code', 'name', null, 'code')
        },
        [selectUnit2, unit2Options]
    )

    const handleStatus = (value) => {
        setTogglerState(value !== '1' ? true : false)
    }

     /** Form утгууд */
     const form = {
		inputs: [
			{
				inputType: "select",
				registerName: "unit1",
				onChange: value => getDataWithUnit('unit1_code' , value.value),
                value: selectUnit1,
				// disabled: false,
                disabled: !userDetail?.user_role?.is_tosk,
				options: [{ value: '0', label: 'Бүгд' }, ...dataToValueLabel(units, "code", "name")],
				colMd: 3,
                label: {
                    empty: true
                },
                helperText: "Бүртгэлтэй харьяа аймаг/хот -д оролцоно"
			},
			{
				inputType: "select",
				registerName: "unit2",
				onChange: value => getDataWithUnit('unit2_code' , value.value),
                value: selectUnit2,
				disabled: isLoading,
				nested: "unit1",
				options: secondUnits,
				colMd: 3,
                label: {
                    empty: true
                }
			},
			{
				inputType: "select",
				registerName: "unit3",
				onChange: value => getDataWithUnit('unit3_code' , value.value),
                value: selectUnit3,
				nested: "unit2",
				nestedObjectKey: "code",
				disabled: isLoading,
				options: thirdUnits,
				colMd: 3,
                label: {
                    empty: true
                }
			},
            {
				inputType: "chooseItem",
				registerName: "status",
				disabled: isLoading,
				options: [
                    { value: '1', label: 'Идэвхтэй' },
                    { value: '0', label: 'Дууссан' },
                ],
				colMd: 3,
                label: {
                    text: 'Төлөв:',
                    empty: true
                },
                onChange: handleStatus,
                defaultValue: '1'
			},
		],
		button: {
            show: false
		},
		validate: {
			validateMode: "all",
			yupValidate: null,
		},
	}

    return (
		<Container className="skipHeader">
			<Row className="mb-3 d-flex align-items-center">
				<Col md={12}>
					<CForm inputValues={unitDefault} form={form} />
				</Col>
				{/* <Col md={3} className="d-flex align-items-center">Төлөв: {Toggler}</Col> */}
			</Row>
			<Row>
				{displaySongon.length === 0 ? <DataNotFound text='Сонгон шалгаруулалт олдсонгүй' absolute={false} /> : displaySongon}
				{isLoading ? <div className="mt-3 mb-3">{Loader}</div> : null}
				{lotteries.length !== 0 && !finished && (
					<div className={css.readMoreBtn}>
						<CButton isLoading={isLoading} variant="second" style={{ width: "300px" }} onClick={() => handleReadMore()}>
							Илүүг харуулах
						</CButton>
					</div>
				)}
			</Row>
		</Container>
	)
}

export default LotteryMain;
