import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import useRole from 'hooks/useRole';
import useModal from 'hooks/useModal';

import { PERM_DELETE } from 'utils/consts'

export const WARNING_TEXT = "Та устгахдаа итгэлтэй байна уу ?"

/** crud үйлдэл хийхдээ энэхүү delete ийг ашиглана */
const DeleteButton = ({ onClick, containerClassName="", className, needRoles=[], warningText=WARNING_TEXT, style={}, placement='right', overlayText="Устгах", hasToolTip=true }) =>
{
    const { showWarning } = useModal()

    const hasPerm = useRole(needRoles, PERM_DELETE)

    if (!hasPerm)
        return null

    /** товч дарахад эхлээд modal харуулж баталгаажуулах */
    const handleModal = () =>
    {
        showWarning(
            {
                type: "warning",
                question: warningText,
                onClick: onClick,
            }
        )
    }

    return (
        hasToolTip
        ?
            <OverlayTrigger
                placement={placement}
                delay={{ show: 0, hide: 0 }}
                overlay={<Tooltip id="button-tooltip-2">{overlayText}</Tooltip>}
            >
                <div className={containerClassName} onClick={handleModal} style={{ cursor: 'pointer', ...style }}>
                    <i className={`far fa-trash text-danger ${className}`}></i>
                </div>
            </OverlayTrigger>
        :
            <div onClick={handleModal} style={{ cursor: 'pointer', ...style }}>
                <i className={`far fa-trash text-danger ${className}`}></i>
            </div>
    )
}

export default DeleteButton;
