import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import CreateButton from 'components/main/CudButtons/Create';
import DeleteButton from 'components/main/CudButtons/Delete';
import UpdateButton from 'components/main/CudButtons/Update';
import Datatable from 'components/main/Datatable';

import useApi from 'hooks/useApi';

import { moneyFormat } from 'utils/format';
import { govPageRoles } from 'utils/consts/roles';

/** Хөтөлбөр буцаана */
const Program = () => {

    const navigate = useNavigate();
    const programApi = useApi().program
    const dataTableRef = useRef(null)

    /** Modal гаргаж ирэх funct */
    const displayDetail = (id) =>
    {
        navigate(`${id}/detail/`)
    }

    /** Хүснэгтийн багана */
    const columns =
    [
        {
            field: "name",
            headerName: "Нэр",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span className='tablerow-detail' onClick={() => displayDetail(row.id)}>{fieldValue}</span>
        },
        {
            field: "budget_amount",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue)}</span>
        },
        {
            field: "result",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "start_date",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "end_date",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    const getData = () =>
    {
        dataTableRef.current.firstGet = true
        dataTableRef.current.reData({}, null, [])
    }

    /** Хүснэгтийн мөрийн утгыг устгах */
    const deleteRow = async (id) =>
    {
        await programApi.delete(id)
        getData()
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.id}/`)} needRoles={govPageRoles} />
                        <DeleteButton onClick={() => deleteRow(item.id)} needRoles={govPageRoles} />
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
        <Row>
            <Col sm={12}>
                <CreateButton btnClassName='floatRight' onClick={() => navigate('create/')} needRoles={govPageRoles}/>
            </Col>
            <Col>
                <Datatable
                    dRef={dataTableRef}
                    columns={columns}
                    sequenceColumn={sequenceColumn}
                    extraColumns={extraColumns}
                    // tableOptions
                    request={
                        {
                            axios: programApi.get,
                            params: []
                        }
                    }
                    isFirstGetData={true}
                />
            </Col>
        </Row>
    );
}

export default Program;
