import React, { useState } from 'react'

import SeparatedInput from './SeparatedInput'

import CForm from 'components/main/Form'

import useAuth from 'hooks/useAuth'
import useApi from 'hooks/useApi'
import useLoader from 'hooks/useLoader'

import { changeMailValidate } from 'utils/validate'

export default function EmailChange()
{
    const { userDetail } = useAuth()
    const [ showCodeInput, setShowCodeInput ] = useState(false)

    const mailApi = useApi().user.mail
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })

    const { email } = userDetail

    const btnOnSubmit = async (formData) =>
    {
        setShowCodeInput(false)
        const { success } = await fetchData(mailApi.sendMail(formData)).catch(err => err)
        if(success)
        {
            setShowCodeInput(true)
        }
    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "mail",
                type: "text",
                isLoading: isLoading,
                label: {
                    text: "И-мэйл: ",
                },
                placeholder: email,
                colMd: 12
            },
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            title: showCodeInput ? 'Дахин илгээх' : "Шинэчлэх",
            isLoading: isLoading
        },
        validate: {
            validateMode: "all",
            yupValidate: changeMailValidate
        },
    }

    return (
        <>
            <CForm form={form} />
            {showCodeInput && <SeparatedInput setShowCodeInput={setShowCodeInput} />}
        </>
	)
}
