import React, { useState, useEffect, useRef } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import Icon from 'components/main/Icon'

import label from 'utils/validate/label'

import css from '../style.module.css'
import FilterButton from './Filter'
import RadioCheck from 'components/main/Form/InputTypes/RadioCheck'

export const SequenceColName = "sequence"
export const ASC = 'asc'
export const DESC = 'desc'


export default function Thead({ columns, sequenceColumn, extraColumns, onOrderChange, className, options, isLoading, colSpanHeader=[], handleFilterChange, hasCheckbox=false, onCheckAll })
{
    const [orderField, setOrderField] = useState({
        field: options?.defaultSort?.field ? options?.defaultSort?.field : SequenceColName,
        type: options?.defaultSort?.type ? options?.defaultSort?.type : ASC
    })

    const isDidMount = useRef(false)

    /**
     * orderField state ийн утга солигдох болгонд
     * onOrderChange руу утгыг буцаах нь
     */
    useEffect(
        () =>
        {
            if (!isDidMount.current)
            {
                if (!onOrderChange)
                    return
                const filterColumns = columns.filter(
                    (col, idx) =>
                    {
                        return col.filter
                    }
                )
                let filters = {}
                filterColumns.map(
                    (col, idx) =>
                    {
                        let filterCol = col.filter
                        const filterName = filterCol.fieldName
                        const options = filterCol.filterOptions
                        const defaultFilters = options.filter(
                            (fil, idx) =>
                            {
                                return fil.default === true
                            }
                        )
                        let values = defaultFilters.map(el => el.value)
                        filters[filterName] = values
                    }
                )
                onOrderChange(orderField, { match: filters })
            }
            else {
                isDidMount.current = true;
                if (onOrderChange && isDidMount.current)
                {
                    onOrderChange(orderField)
                }
            }
        },
        [orderField]
    )

    /**
     * Эрэмбэлэлтийг өөрчлөх үед
     */
    const onClickOrder = (field) => {

        if (isLoading) return

        if (orderField.field === field) {
            setOrderField({
                field,
                type: orderField.type === ASC ? DESC : ASC
            })
            return
        }

        setOrderField({
            field,
            type: ASC
        })
    }

    /**
     * Эрэмблэдэг icon ийг харуулах нь
     */
    const orderIcon = (field) => {

        if (field !== orderField.field) {
            return null
        }

        return (
            orderField.type === ASC
            ? <Icon icon={'fa fa-arrow-up fa-xs'} />
            : <Icon icon={'fa fa-arrow-down fa-xs'} />
        )
    }

    /**
     * Хүснэгтийн дэс дугаарыг харуулах багана
     */
    const getSequenceColName = () => {
        let _col = null
        if (sequenceColumn) {

            const hasOrder = typeof sequenceColumn?.order === 'boolean' ? sequenceColumn.order : true

            _col = (
                <th
                    className={sequenceColumn.className}
                    style={{
                        width: sequenceColumn?.width ? sequenceColumn?.width + 'px' : "",
                        minWidth: sequenceColumn.minWidth ? sequenceColumn.minWidth + 'px' : "",
                        maxWidth: sequenceColumn.maxWidth ? sequenceColumn.maxWidth + 'px' : "",
                    }}
                    onClick={() => sequenceColumn?.order && onClickOrder(SequenceColName)}
                    role={hasOrder ? 'button' : ""}
                >
                    {sequenceColumn.headerName} {hasOrder ? orderIcon(SequenceColName) : null}
                </th>
            )
        }
        return _col
    }

    /**
     * Хүснэгтийн checkbox харуулах багана
     */
    const getCheckBox = () => {
        let _col = null
        if (hasCheckbox) {

            const hasOrder = typeof sequenceColumn?.order === 'boolean' ? sequenceColumn.order : true

            _col = (
				<th style={{ width: '0' }}>
                    <input type="checkbox" onChange={onCheckAll} />
					{/* <RadioCheck inputClassName='justify-content-center' className='me-0' options={[{ value: 'all' }]} onChange={onCheckAll} /> */}
				</th>
			)
        }
        return _col
    }

    /**
     * Багануудын нэрсийг харуулах нь
     */
    const displayColumns = (columnsData) => {

        if (!columnsData || columnsData.length === 0) {
            return null
        }

        const _columns = columnsData.map((col, idx) => {

            const hasOrder = typeof col?.order === 'boolean' ? col.order : true

            const th = !col.hide && (
				<th
					key={idx}
					className={`${col.className}`}
					style={{
						width: col?.width ? col?.width + "px" : "",
						minWidth: col?.minWidth ? col?.minWidth + "px" : "",
						maxWidth: col?.maxWidth ? col?.maxWidth + "px" : "",
					}}
					role={hasOrder ? "button" : ""}
                >
					<div className={col.filter && css.headerDiv} onClick={() => hasOrder && onClickOrder(col.field)}>
						{col.headerName ? col.headerName : label[col.field]} {hasOrder ? orderIcon(col.field) : null}
					</div>
					{col.filter && <FilterButton handleFilterChange={handleFilterChange} filter={col.filter} idx={idx} />}
				</th>
			)

            if (col.description) {
                return (
                    <OverlayTrigger
                        key={idx}
                        placement='bottom'
                        overlay={
                            <Tooltip id={`tooltip-bottom`}>
                                {col.description}
                            </Tooltip>
                        }
                    >
                        {th}
                    </OverlayTrigger>
                )
            }
            else {
                return th
            }
        })

        return _columns
    }

    const displayColSpanHeader = () =>
    {
        let thHeader = colSpanHeader.map(
            (element, index) =>
            {
                if(element.rowspan)
                {
                    return <th {...element.order ? { role: "button" } : {}} onClick={() => element.order && onClickOrder(element.field)} key={index} rowSpan={element.rowspan}>{element.text ? element.text : label[element.label]} {element.order ? orderIcon(element.field) : null}</th>
                }
                return <th key={index} colSpan={element.colspan}>{element.text ? element.text : label[element.label]}</th>
            }
        )
        if (extraColumns)
        {
            const extraHeaders = extraColumns.map(
                (element, index) =>
                {
                    if(element.rowspan)
                    {
                        return <th key={`${-1 - index}`} rowSpan={element.rowspan}>{element.text ? element.text : label[element.label]}</th>
                    }
                    return <th {...element.order ? { role: "button" } : {}} onClick={() => element.order && onClickOrder(element.field)} key={`${-1 - index}`} colSpan={element.colspan}>{element.text ? element.text : label[element.label]} {element.order ? orderIcon(element.field) : null}</th>
                }
            )
            thHeader = thHeader.concat(extraHeaders)
        }
        thHeader.unshift(<th key={'first'}></th>)
        // thHeader.unshift(<th key={'second'}></th>)
        return thHeader
    }

    return (
		<thead>
			{colSpanHeader.length !== 0 && <tr>{displayColSpanHeader()}</tr>}
			<tr className={className}>
                {getCheckBox()}
				{getSequenceColName()}
				{displayColumns(columns)}
				{displayColumns(extraColumns)}
			</tr>
		</thead>
	)
}
