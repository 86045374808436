import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { searchParamsToObject } from 'utils/browser';
import label from 'utils/validate/label';

import css from '../../style.module.css'

const ApartTabs = ({ setKey, apartmentId }) => {

    const [ searchParams, setSearchParams ] = useSearchParams()

    const handleOnSelect = (value) =>
    {
        setKey(value)
        setSearchParams({ tab: value })
    }

    useEffect(
        () =>
        {
            if(!searchParamsToObject(searchParams).tab)
            {
                setSearchParams({ tab: 'apartment' })
            }
        },
        []
    )

	return (
		<div className={css.tabsContainer}>
            <div className={`${css.tab} ${searchParamsToObject(searchParams).tab === 'apartment' ? css.activeTab : ""}`} onClick={ () => handleOnSelect('apartment')}>{label['apartment']}</div>
            {apartmentId && <div className={`${css.tab} ${searchParamsToObject(searchParams).tab === 'building' ? css.activeTab : ""}`} onClick={ () => handleOnSelect('building')}>{label['building']}</div>}
            {apartmentId && <div className={`${css.tab} ${searchParamsToObject(searchParams).tab === 'borluulalt' ? css.activeTab : ""}`} onClick={ () => handleOnSelect('borluulalt')}>{"Борлуулалт"}</div>}
        </div>
	)
}

export default ApartTabs
