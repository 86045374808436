import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import ImageUpload from 'components/main/Form/InputTypes/ImageUpload'
import CButton from 'components/main/Button';
import CInput from 'components/main/Form/InputTypes/Input';
import CSelect from 'components/main/Form/InputTypes/Select';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';
import useToast from 'hooks/useToast';

import css from '../style.module.css'

const CreateRoom = ({ inputs, residenceId, totalRoom, projectId, initInputState, getRooms, roomTypesAll }) => {

    const [ changedData, setChangedData ] = useState(initInputState[0])
    const [ errors, setErrors ] = useState({});

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })
    const { addToast, clearAllToast } = useToast()
    const roomApi = useApi().residence.room


    const handleOnChange = (event) =>
    {
		if (event.value) {
			return setChangedData({
				...changedData,
				room_type: event.value,
			})
		}

		if (parseFloat(event.target.value) <= 0) {
			addToast({ text: "Эерэг тоо оруулна уу", type: "warning" })
			return setErrors({
				...errors,
				[event.target.name]: {
					message: "Эерэг тоо оруулна уу",
				},
			})
		} else {
			delete errors[event.target.name]
		}

		if (event.target.name === "image") {
			return setChangedData({
				...changedData,
				image: event.target.files[0],
			})
		}
		if (event.target.name === "room_count") {
			let rooms = 0
			inputs.map((element, index) => {
				rooms = rooms + element.room_count
			})
			rooms = rooms + parseInt(event.target.value || 0)
			if (totalRoom < rooms) {
				setErrors({
					...errors,
					room_count: {
						message: "Боломжтой өрөөний тооноос хэтэрлээ",
					},
				})
				addToast({ text: "Боломжтой өрөөний тооноос хэтэрлээ", type: "warning" })
			} else {
				clearAllToast()
				delete errors.room_count
			}
		}
		setChangedData({
			...changedData,
			[event.target.name]: event.target.value,
		})
	}

    const createRoom = async () =>
    {
        if(Object.keys(errors).length !== 0 ) return
        delete changedData.id

        const formData = new FormData()

        formData.append('room_type', changedData.room_type)
        formData.append('room_mkv', changedData.room_mkv)
        formData.append('room_count', changedData.room_count)
        formData.append('image', changedData.image)
        formData.append('residence', changedData.residence)

        const { success } = await fetchData(roomApi.post(projectId, residenceId, formData)).catch((err) => err)
        if(success)
        {
            setChangedData(initInputState[0])
            getRooms()
        }
    }

    return (
        <div className="mt-3 mb-3" key={'index'}>
            <div className={css.inputContainer}>
                <Row>
                    <Col md={4}>
                        <label>Өрөө</label>
                        <CSelect
                            value={changedData.room_type}
                            className={css.inputSize}
                            disabled={isLoading}
                            onChange={handleOnChange}
                            options={roomTypesAll}
                        />
                    </Col>
                    <Col md={4}>
                        <label>
                            м<sup>2</sup>
                        </label>
                        <CInput
                            realValue={changedData.room_mkv}
                            register={{ name: "room_mkv" }}
                            type="number"
                            disabled={isLoading}
                            errors={errors}
                            onChange={handleOnChange}
                            step="0.01"
                            className={css.inputSize}
                        />
                    </Col>
                    <Col md={4}>
                        <label>Тоо</label>
                        <CInput
                            realValue={changedData.room_count}
                            type="number"
                            disabled={isLoading}
                            register={{ name: "room_count" }}
                            onChange={e => handleOnChange(e)}
                            errors={errors}
                            className={css.inputSize}
                        />
                    </Col>
                    <Col className='mt-3'>
                        <ImageUpload
                            value={changedData.image}
                            errors={errors}
                            disabled={isLoading}
                            id='zurag'
                            multiple={false}
                            register={{ name: "image", onChange: handleOnChange }}
                        />
                    </Col>
                </Row>
                <CButton isLoading={isLoading} className={`${css.buttonSize} mt-2`} onClick={() => createRoom()} >
                    Үүсгэх
                </CButton>
            </div>
            <br />
        </div>
    );
}

export default CreateRoom;
