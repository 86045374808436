import { useEffect } from 'react'

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";

export default function MkvGraphic(
   { resideneceInfo }
)
{

    useEffect(
        () =>
        {
            let root = am5.Root.new("pie-mkv");
            let chart = root.container.children.push(
                am5percent.PieChart.new(root, {
                    layout: root.verticalHorizontal,
                    tooltip: am5.Tooltip.new(root, { //дээр нь hover лох үе
                        labelText: "{category}мк: {count}"
                    })
                })
            );

            let data = resideneceInfo

            // Create series
            let series = chart.series.push(
                am5percent.PieSeries.new(root, {
                    name: "Series",
                    valueField: "count",
                    categoryField: "total_mkv",
                    legendLabelText: "{total_mkv}мк:",
                    legendValueText: "{count}",
                    alignLabels: false,
                })
            );
            series.labels.template.setAll(
                {
                    text: "{valuePercentTotal.formatNumber('0.00')}%[/]",
                    textType: "circular",
                    inside: true,
                }
            )
            series.labels.template.adapters.add("disabled", (value, target) =>
            {
                if (parseInt(target.dataItem.dataContext.count) < 2)
                {
                    target.hide()
                }
            })
            series.data.setAll(data);

            let legend = chart.children.push(am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50),
                y: 5,
                layout: root.gridLayout,
                useDefaultMarker: true,
            }));
            legend.markerRectangles.template.setAll({
                cornerRadiusTL: 10,
                cornerRadiusTR: 10,
                cornerRadiusBL: 10,
                cornerRadiusBR: 10,
            });

            legend.data.setAll(series.dataItems);
            return () =>
            {
                root.dispose()
            }
        },
        [resideneceInfo]
    )

    return (
        <div id='pie-mkv' className='position-relative' style={{ width: "100%", height: "550px" }}>
			<div className="hidelogo" style={{ background: "#f5f6fa" }}></div>
        </div>
    )
}
