
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ProjectTabs from '.';

import BuildingCrudMain from './Building/main';
import ApartmentCrudMain from './Apartment/main';
import ResidenceCrudMain from './Residence/main';
import Map from './Map';
import Detail from './Details';
import BoxBackground from 'components/special/BoxBackground';

const TabRoutes = () => {
    return (
		<>
			<ProjectTabs />
			<BoxBackground
				title=""
				body={
					<Routes>
						<Route path="/" element={<Detail />} />
						<Route path="/apartment/*" element={<ApartmentCrudMain />} />
						<Route path="/residence/*" element={<ResidenceCrudMain />} />
						<Route path="/building/*" element={<BuildingCrudMain />} />
						<Route path="/map/*" element={<Map />} />
					</Routes>
				}
			/>
		</>
	)
}

export default TabRoutes;
