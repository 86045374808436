import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CForm from 'components/main/Form';
import BoxBackground from 'components/special/BoxBackground';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { agendaValidate } from 'utils/validate';
import { dataToValueLabel } from 'utils/format';

/** Agenda дээр create, update хийнэ */
const AgendaAction = () => {
    const [ inputValues, setInputValues ] = useState('');
    const [ programs, setPrograms ] = useState([]);

    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })
    const navigate = useNavigate();
    const { programId, id } = useParams()

    const programApi = useApi().program
    const agendaApi = useApi().agenda

    /** true бол create, false бол update буцаана */
    const isCreate = id === 'create'

    /** хөтөлбөр уудыг авна */
    useEffect(
        async () =>
        {
            const programRsp = await fetchData(programApi.getAll())
            if (programRsp.success)
            {
                setPrograms(dataToValueLabel(programRsp.data, 'id', 'name'))
            }
        },
        []
    )

    /** create, update гэдгээс хамаарч утга авна */
    useEffect(
        async () =>
        {
            if (!isCreate)
            {
                const { success, data, error } = await fetchData(agendaApi.getOne(programId, id))
                if (success)
                {
                    setInputValues(data)
                }
            }
        },
        []
    )

    /** form ийн button дарах үед ажиллах function */
    const btnOnSubmit = async (formData) =>
    {
        formData.hutulbur = programId
        if (isCreate)
        {
            // үүсгэх үед
            const { success, data, errors } = await fetchData(agendaApi.post(programId, formData)).catch((error) => error)
            if (success) return navigate(-1)
            else return errors
        }
        // засах үед
        const { success, data, errors } = await fetchData(agendaApi.put(programId, formData, id)).catch((error) => error)
        if (success) navigate(-1)
        else return errors
    }

    /** Form утгууд */
    const form =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "agenda",
                type: "textArea",
                placeholder: "Зорилтын нэр оруулна уу",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
                label: {
                    text: "Зорилтын нэр: ",
                    empty: false,
                },
            },
            {
                inputType: "input",
                registerName: "amount",
                type: "number",
                placeholder: "Арга хэмжээний хөрөнгө оруулна уу",
                inputGroup: {
                    start: <i className="fa-solid fa-address-book"></i>,
                },
                disabled: isLoading,
                label: {
                    text: "Хөрөнгийн хэмжээ: ",
                    empty: false,
                },
            },
        ],
        button: {
            onSubmit: async (data) =>
            {
                return btnOnSubmit(data)
            },
            disabled: isLoading,
            isLoading: isLoading,
            title: isCreate ? 'Үүсгэх' : 'Хадгалах',
        },
        validate: {
            validateMode: "all",
            yupValidate: agendaValidate,
        },
    }
    return (
        <CForm inputValues={inputValues} form={form}/>
    );
}

export default AgendaAction;
