import React from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';

import BoxBackground from 'components/special/BoxBackground';
import Datatable from 'components/main/Datatable';
import TableCheckbox from 'components/main/TableCheckbox';
import OverlayElement from 'components/main/OverlayElement';

import useLoader from 'hooks/useLoader';
import useApi from 'hooks/useApi';
import useRole from 'hooks/useRole';

import { ASC } from 'components/main/Table/Thead';
import { financialAdminPageRoles, govPageRoles } from 'utils/consts/roles';
import { PERM_READ } from 'utils/consts';

import Users from './Users'

import UserAction from 'pages/Gov/UserAction'

const Companies = () =>
{
	const isCompany = useRole(govPageRoles, PERM_READ)
    const companyApi = useApi().company
	const navigate = useNavigate()

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false, hasBackground: false })

    const toggleTOSK = async (id, value, name) =>
    {
        await fetchData(companyApi.put(id, {[name]: value}))
    }

    const columns = [
		{
			field: "company",
			order: true,
		},
		{
			field: "employee_count",
			order: true,
		},
		// {
		// 	field: "director",
		// 	order: true,
		// 	fieldComponent: (fieldValue, index, row) => fieldValue,
		// },
		// {
		// 	field: "is_financial",
		// 	order: true,
		// 	fieldComponent: (fieldValue, index, row) =>
		// 		<div className='all-center'>
		// 			<TableCheckbox row={row} fieldValue={fieldValue} onChange={toggleTOSK} name={'is_financial'}/>
		// 		</div>,
		// },
	]

	if (isCompany)
	{
		columns.push({
			field: "is_tosk",
			order: true,
			fieldComponent: (fieldValue, index, row) => (
				<div className='all-center'>
					<TableCheckbox row={row} fieldValue={fieldValue} onChange={toggleTOSK} name={'is_tosk'}/>
				</div>
			),
		})
	}

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

	/** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
						<div>
							<OverlayElement
								placement='top'
								text='Хэрэглэгчид'
							>
								<div role={"button"} onClick={() => navigate(`${item.id}/`)}>
									<i className='far fa-arrow-right text-info'></i>
								</div>
							</OverlayElement>
						</div>
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
		<Routes>
			<Route
				path={""}
				element={
					<BoxBackground
						title="Жагсаалт"
						body={
							<Datatable
								columns={columns}
								sequenceColumn={sequenceColumn}
								extraColumns={extraColumns}
								tableOptions={
									{
										defaultSort: {
											type: ASC,
											field: "company"
										}
									}
								}
								request={{
									axios: companyApi.companies,
									params: [],
								}}
							/>
						}
					/>
				}
			/>
			<Route
				path={":id/"}
				element={<Users />}
			/>
			<Route
				path=":id/:userId/"
				element={<UserAction />}
			/>
		</Routes>
	)
}

export default Companies;
