import React, { useEffect, useMemo } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import WaveButton from 'components/special/WaveButton'
import BorderTitle from 'components/special/BorderTitle'

import useApi from 'hooks/useApi'
import useConfig from 'hooks/useConfig'

import Title from 'components/special/Title'

import ServiceItem from './service'

const ABOUT_CODE = "aboutus"

export default function Abouts()
{

    const { getConfig, configs } = useConfig()
    const newsApi = useApi().news.front

    const getAbout = async () =>
    {
        /** хуудсыг дуудах */
        const { success, data, error } = await newsApi.get(ABOUT_CODE, 0, 1)
        if (success)
        {
            const news = data[0]
            /** хуудсыг мэдээг дуудах about нь өөрөө нэг мэдээтэй учраас эхний мэдээг харуулах нь */
            const { success, data: newsData, error } = await newsApi.getOne(news.page, news.id)
            if (success)
            {
                const element = document.getElementById("about-content")
                element.innerHTML = newsData.content
            }
        }
    }

    const getConfigs = async () =>
    {
        await getConfig("ABOUT_PAGE_VIDEO_URL,ABOUT_PAGE_OUR_SERVICES")
    }

    useEffect(
        () =>
        {
            Promise.all(
                [
                    getAbout(),
                    getConfigs()
                ]
            )

        },
        []
    )

    const serviceItems = useMemo(
        () =>
        {
            const config = configs['ABOUT_PAGE_OUR_SERVICES'] ? JSON.parse(configs['ABOUT_PAGE_OUR_SERVICES']) : []
            return config.map(
                (service, idx) =>
                {
                    return (
                        <Col md={3} className="mb-3" key={idx}>
                            <ServiceItem icon={service.icon} title={service.title} link={service.link} />
                        </Col>
                    )
                }
            )
        },
        [configs['ABOUT_PAGE_OUR_SERVICES']]
    )

    return (
        <div>
            <Container fluid className="p-0">
                <div style={{ height: "460px", marginTop: "90px" }} className="inner-cover position-relative">
                    <div className="text-light">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="inner-text">
                                    Орон сууцны бүртгэл, мэдээллийн нэгдсэн портал
                                </h2>
                                {/* <WaveButton
                                    link={configs['ABOUT_PAGE_VIDEO_URL']}
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} className='pt-0'>
                        <BorderTitle>
                            Бидний тухай
                        </BorderTitle>
                        <Col lg={12} className="mt-3">
                            <div className='fs-15' id='about-content'></div>
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Container className='mb-3'>
                <Row className='p-5'>
                    {serviceItems}
                </Row>
            </Container>
        </div>
    )
}
