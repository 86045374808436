import React from 'react';
import { Col } from 'react-bootstrap';

import Label from '../Label';

/**
 * @param {object} errors yup -аас ирсэн validate ийн алдаанууд
 *
 * @param {object} label -ийн тохиргоо
 * @param {boolean} label.empty label дээр * харуулах эсэх энэ input хоосон байж болох бол true илгээнэ
 * @param {boolean} label.hide input-ийн label ийг харуулах эсэх
 * @param {String} label.text label-ийн харагдах text
 * @returns
 */
const CustomInputGroup = ({ children, errors, registerName, label={}, text, md=6, className="", helperText="" }) =>
{
    return (
		<Col md={md} className={className}>
			<div key={label.text} className={label.text !== " " ? "mb-3" : ''}>
				{label.hide ? null : (
					<Label htmlFor={registerName} label={label.text ? label.text : text} empty={label.empty} registerName={registerName} />
				)}
				{children}
				<small className='text-secondary'>{helperText}</small>{}
				<div className="customFeedBack">{errors?.[registerName]?.message}</div>
			</div>
		</Col>
	)
}

export default CustomInputGroup;
