import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import CreateButton from 'components/main/CudButtons/Create';
import DeleteButton from 'components/main/CudButtons/Delete';
import UpdateButton from 'components/main/CudButtons/Update';
import CTable from 'components/main/Table';
import AccessIcons from 'components/special/AccessIcons'

import useLoader from 'hooks/useLoader';
import useApi from 'hooks/useApi';

import { adminPageRoles } from 'utils/consts/roles';

const UnitMeasureMain = () => {

    const [ rows, setRows ] = useState([]);

    const navigate = useNavigate();

    const unitMeasureApi = useApi().unitMeasure

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })

    /** Хүснэгтийн багана */
    const columns =
    [
		{
			field: "name",
			headerName: "Хэмжих нэгж",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>,
		},
		{
			field: "code",
			headerName: "Хэмжих нэгжийг илэрхийлэх код",
			order: false,
			fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>,
		},
		{
			field: "is_from_system",
			headerName: "Системээс утга авах",
			order: false,
			fieldComponent: (fieldValue, index, row) => (fieldValue ? "Тийм" : "Үгүй"),
		},
	]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтний утгыг авах */
    const getData = async () =>
    {
        const { success, data } = await fetchData(unitMeasureApi.get())
        if(success) setRows(data)
    }

    // back руу хүсэлт шидэх
    useEffect(() => getData(), [])

    /** Хүснэгтийн мөрийн утгыг устгах */
    const deleteRow = async (id) =>
    {
        await fetchData(unitMeasureApi.delete(id))
        await getData()
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.id}/`)} needRoles={adminPageRoles} />
                        <DeleteButton onClick={() => deleteRow(item.id)} needRoles={adminPageRoles} />
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
        <Row>
            <Col sm={12}>
                <CreateButton btnClassName='floatRight' onClick={() => navigate('create/')} needRoles={adminPageRoles} />
            </Col>
            <Col>
                <CTable
                    isLoading={isLoading}
                    columns={columns}
                    sequenceColumn={sequenceColumn}
                    extraColumns={extraColumns}
                    rows={rows}
                />
            </Col>
        </Row>
    );
}

export default UnitMeasureMain;
