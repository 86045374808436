import React, { useEffect, useState, useMemo } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import Countdown from "react-countdown";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import DataNotFound from 'components/main/DataNotFound';

import ProgramChooseInputs from "../ProgramChooseInputs";
import { fakeAgendaResult } from "../fakeData";

import useMobile from 'hooks/useMobile';
import label from "utils/validate/label";
import { moneyFormat, timeZoneToDateString } from "utils/format";
import { getColor } from "utils/color";
import { fullZero } from "utils";

import css from '../style.module.css'

const YEAR = 365

const AgendaChart = ({ choosedProgram, getAgendaData, setChoosedProgram, chartData }) =>
{
    const [ agendaChartData, setChartData ] = useState([])

	const { isMobile } = useMobile()

    useEffect(
        async () =>
        {
			// if(!choosedProgram) return
			// setChartData(fakeAgendaResult.find(
			// 	(element) =>
			// 	{
			// 		if(element.id === choosedProgram.id)
			// 		{
			// 			return element
			// 		}
			// 	}
			// ).agenda.map(
			setChartData(choosedProgram?.agenda ? choosedProgram.agenda.map(
				(element, index) =>
				{
					if (index === 0)
						getAgendaData({
							...element,
							full: 100,
							columnSettings: {
								fill: getColor((index + 1) / 4),
							},
						})
					return {
						...element,
						full: 100,
						columnSettings: {
							fill: getColor((index + 1) / 4),
						},
					}
				}
			) : [])
        },
        [choosedProgram]
    )

    useEffect(() => {
		if(agendaChartData.length === 0) return
		var root = am5.Root.new("radarChartDiv")

		root.setThemes([am5themes_Animated.new(root)])

		var chart = root.container.children.push(
			am5radar.RadarChart.new(root, {
				panX: false,
				panY: false,
				innerRadius: am5.percent(50),
				startAngle: 90,
				endAngle: 450,
				radius: am5.percent(100),
			}),
		)

		var xRenderer = am5radar.AxisRendererCircular.new(root, {
			minGridDistance: 50,
		})

		xRenderer.labels.template.setAll({
			visible: false,
		})

		xRenderer.grid.template.setAll({
			forceHidden: true,
		})

		var xAxis = chart.xAxes.push(
			am5xy.ValueAxis.new(root, {
				renderer: xRenderer,
				min: 0,
				max: 100,
				strictMinMax: true,
				numberFormat: "#'%'",
			}),
		)

		var yRenderer = am5radar.AxisRendererRadial.new(root, {
			minGridDistance: 20,
			axisAngle: 90,
		})
		// vgnvvd
		yRenderer.labels.template.setAll({
			visible: true,
			fontSize: 12,
			text: '{result}%',
			fill: '#595454',
		})

		yRenderer.grid.template.setAll({
			forceHidden: true,
		})


		var yAxis = chart.yAxes.push(
			am5xy.CategoryAxis.new(root, {
				categoryField: "name",
				renderer: yRenderer,
				background: am5.Rectangle.new(root, {
					fill: '#7F8386',
					opacity: 0.1,
					layer: 1,
				})
			}),
		)

		yAxis.data.setAll(agendaChartData)

		var series1 = chart.series.push(
			am5radar.RadarColumnSeries.new(root, {
				xAxis: xAxis,
				yAxis: yAxis,
				clustered: false,
				valueXField: "full",
				categoryYField: "name",
				fill: root.interfaceColors.get("alternativeBackground"),
			}),
		)

		series1.columns.template.setAll({
			fillOpacity: 0.08,
			strokeOpacity: 0,
			cornerRadius: 8,
		})

		series1.data.setAll(agendaChartData)

		var series2 = chart.series.push(
			am5radar.RadarColumnSeries.new(root, {
				xAxis: xAxis,
				yAxis: yAxis,
				clustered: false,
				valueXField: "result",
				categoryYField: "name",
			}),
		)

		series2.columns.template.setAll({
			strokeOpacity: 0,
			tooltipText: "{valueX}%",
			categoryField: "result",
			cornerRadius: 8,
			templateField: "columnSettings",
			cursorOverStyle: 'pointer',
		})

		series2.columns.template.events.on("click", function(ev) {
			getAgendaData(ev.target.dataItem.dataContext);
		});

		chart.children.unshift(
			am5.Label.new(root, {
				text: choosedProgram?.result + "%",
				fontSize: agendaChartData.length * 10,
				fill: "#777777",
				x: am5.percent(50),
				y: am5.percent(50),
				centerX: am5.percent(50),
				centerY: am5.percent(50),
			}),
		)

		chart.children.unshift(
			am5.Label.new(root, {
				text: label['agenda'],
				fontSize: 15,
				textAlign: "center",
				x: am5.percent(50),
				centerX: am5.percent(50),
				paddingTop: 0,
				paddingBottom: 0,
				y: 0
			}),
		)

		series2.data.setAll(agendaChartData)

		series1.appear(1000)
		series2.appear(1000)
		chart.appear(1000, 100)

		return () => {
			root.dispose()
		}
	}, [agendaChartData])

    const displayLegends = useMemo(
        () =>
        {
            return agendaChartData.map(
                (element, index) =>
                {
                    return (
						<div onClick={() => getAgendaData(element)} className={css.oneLegend} key={index}>
							<div className={css.legendMark} style={{ backgroundColor: element.columnSettings.fill }}></div> <div style={{ marginLeft: "20px" }}>{element.name} <strong>{element.result}%</strong></div>
						</div>
					)
                }
            )
        },
        [agendaChartData]
    )

	const calculateAndDisplayEndDate = useMemo(
		() =>
		{
			if(!choosedProgram) return
			let endTimeStamp = Date.parse(choosedProgram.end_date)

			if(endTimeStamp < new Date().getTime())
			{
				return (
					<div className={css.infoCard}>
						<div>
							<div className={css.infoCardText}> Хөтөлбөр дуусахад </div>
							<div className={css.infoCardNumber}>
								<div>Дууссан</div>
							</div>
						</div>
						<div className={css.infoCardIcon}>
							<i className="fas fa-stopwatch"></i>
						</div>
					</div>
				)
			}
			// Renderer callback with condition
			const renderer = ({ hours, minutes, seconds, completed, days }) => {
				const _days = days
				if (completed) {
					return <div>Дууссан</div>
				} else {
					return (
						<span>
							{_days > 0 ? _days >= YEAR && (_days - (YEAR * Math.floor(_days / YEAR))) > 0 ? `${Math.floor(_days / YEAR)} жил ${(_days - (YEAR * Math.floor(_days / YEAR)))} өдөр` : `${_days} өдөр` : `${fullZero(hours)}цаг ${fullZero(minutes)}мин ${fullZero(seconds)}сек`}
						</span>
					)
				}
			}

			return (
				<div className={css.infoCard}>
					<div>
						<div className={css.infoCardText}>Дуусах хугацаа</div>
						<div className={css.infoCardNumber}>
							{timeZoneToDateString(endTimeStamp)}
						</div>
						<div className={css.infoCardText}> Хөтөлбөр дуусахад </div>
						<div className={css.infoCardNumber}>
							<Countdown
								autoStart={true}
								date={endTimeStamp}
								renderer={renderer}
							/>
						</div>
					</div>
					<div className={css.infoCardIcon}>
						<i className="fas fa-stopwatch"></i>
					</div>
				</div>
			)
		},
		[choosedProgram]
	)

    return (
		<Container>
			<div style={{ position: "relative", padding: "0" }}>
				<div className={css.agendaTitles}>
					<ProgramChooseInputs
						choosedProgram={choosedProgram}
						setChoosedProgram={setChoosedProgram}
						chartData={chartData}
					/>
				</div>
			</div>
			<hr />
			<Row style={{ marginTop: "10px", position: 'relative' }}>
				<Col lg={3}>
					<div className={css.infoCard}>
						<div>
							<div className={css.infoCardText}> {label["budget_amount"]}</div>
							<div className={css.infoCardNumber}>{moneyFormat(choosedProgram?.budget_amount)}₮</div>
						</div>
						<div className={css.infoCardIcon}>
							<i className="fas fa-coins"></i>
						</div>
					</div>

					<div className={css.infoCard}>
						<div>
							<div className={css.infoCardText}> {label["main_subject"]}</div>
							<div className={css.infoCardNumber}>{choosedProgram?.main_subject_name}</div>
						</div>
						<div className={css.infoCardIcon}>
							<i className="fas fa-landmark"></i>
						</div>
					</div>

					{calculateAndDisplayEndDate}
				</Col>
				<Col lg={4}>
					{
						agendaChartData.length !== 0
						?
							<div id="radarChartDiv" style={{ height: "350px", position: "relative" }}>
								<div className="hidelogo"></div>
							</div>
						:
							!isMobile &&
							<DataNotFound absolute={true} />
					}
				</Col>
				<Col lg={5} className={css.agendaLegends}>
					{displayLegends}
				</Col>
			</Row>
		</Container>
	)
}

export default AgendaChart
