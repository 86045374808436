import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import Form from 'components/main/Form';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { songonValidate } from 'utils/validate';
import { dataToValueLabel } from 'utils/format';
import BoxBackground from 'components/special/BoxBackground';

const Selection = () =>
{
    const [ inputValues, setInputValues ] = useState('');

    const [ zorilgotBuleg, setZorilgotBuleg ] = useState([]);
    const [ residences, setResidences ] = useState([]);
    const [ selectedZorilt, setSelectedZorilt ] = useState([]);

    const navigate = useNavigate()
    const { fetchData, isLoading, Loader } = useLoader({ isSmall: true })

    const songonApi = useApi().songon
    const songonAminApi = useApi().songon.admin

    let { projectId, songonId, projectType } = useParams()

    const isOld = projectType === 'old'
    /** create бол true, update бол false буцаана */
    const isCreate = songonId === 'create'

    useEffect(
        async () =>
        {
            const zorilgotBulegRsp = await fetchData(songonApi.getBulegOfHutulbur(projectId))
            const residencesRsp = await fetchData(songonApi.admin.residences(projectId, isCreate))
            if (zorilgotBulegRsp.success && residencesRsp.success)
            {
                setZorilgotBuleg(dataToValueLabel(zorilgotBulegRsp.data, "cid", "cname"))
                setResidences(dataToValueLabel(residencesRsp.data, "id", "name", null, 'zoriltot_buleg'))
            }
            if (!isCreate)
            {
                const { success, data } = await fetchData(songonApi.getOne(songonId))
                if (success)
                {
                    data.zoriltotUser = data.zoriltot_buleg_count.map(element => element.zId)
                    const zoriltotBulegs = data.zoriltot_buleg_count
                    //  zoriltot bulgiin тоо оруулсан утгыг json дээр хадгалах нь
                    for (const idx in zoriltotBulegs)
                    {
                        const buleg = zoriltotBulegs[idx]
                        data[`bvleg_${buleg.zId}`] = buleg.user_count
                        data[`residences_${buleg.zId}`] = buleg.residenece_info.map(e => e.id)
                    }
                    setSelectedZorilt(dataToValueLabel(zoriltotBulegs, 'zId', 'name'))
                    setInputValues(data)
                }
            }
        },
        []
    )

    const selectOnChange = (value) =>
    {
        setSelectedZorilt(value)
    }

    const sendFormData = async (formData) =>
    {
        let zoriltotUser = []

        selectedZorilt.map(
            (element) =>
            {
                let zoriltotBuleg = element.value

                let buleg = "bvleg_"
                let residence = "residences_"

                let fullBvleg = buleg + zoriltotBuleg
                let fullResidence = residence + zoriltotBuleg

                zoriltotUser.push(
                    {
                        user_count: formData[fullBvleg],
                        residence: formData[fullResidence],
                        zoriltot_buleg: zoriltotBuleg
                    }
                )

                delete formData[fullBvleg]
                delete formData[fullResidence]
            }
        )

        formData.is_end = isOld
        formData.old_songon = isOld

        let body = { project: projectId, ...formData, zoriltotUser }

        if (isCreate)
        {
            const { errors, success, data } = await fetchData(songonAminApi.create(body)).catch(error => error)
            if (success) return navigate(-1)
            else return errors
        }

        const { errors, success, data } = await fetchData(songonAminApi.update(songonId, body)).catch(error => error)
        if (success) navigate(-1)
        else return errors
    }

    const zoriltotInputs = useMemo(
        () =>
        {
            let inputs = []
            selectedZorilt.map(
                (element, index) =>
                {
                    inputs.push(
                        {
                            component: <hr />
                        }
                    )
                    inputs.push(
                        {
                            inputType: "input",
                            registerName: 'bvleg_' + element.value,
                            type: "number",
                            placeholder: "Сонгогдох хүмүүсийн тоо",
                            label:
                            {
                                text: element.label + ':',
                                empty: false,
                            },
                            colMd: 6
                        }
                    )
                    inputs.push(
                        {
                            inputType: "select",
                            registerName: "residences_" + element.value,
                            placeholder: "Өрөөний зохион байгуулалтыг сонгоно уу",
                            label: {
                                text: "Өрөөний зохион байгуулалт",
                            },
                            isMulti: true,
                            disabled: isLoading,
                            options: residences.filter( el => el.extraValue.includes(element.value)),
                            colMd: 6
                        }
                    )
                }
            )

            return inputs
        },
        [selectedZorilt]
    )

    const registerInputs =
    {
        inputs: [
            {
                inputType: "input",
                registerName: "name",
                type: "text",
                placeholder: "Сонгон шалгаруулалтын нэр оруулна уу",
                label: {
                    text: "Сонгон шалгаруулалтын нэр: ",
                    empty: false,
                },
            },
            {
                inputType: "datePicker",
                disabled: isLoading,
                registerName: "end_date",
                showTimeSelect: true,
                maxDate: isOld && new Date(),
                label: {
                    text: isOld ? 'Дууссан хугацаа' : "Дуусах хугацаа"
                }
            },
            {
                inputType: "select",
                registerName: "zoriltotUser",
                disabled: isLoading,
                options: zorilgotBuleg,
                onChange: selectOnChange,
                isMulti: true,
            },
            ...zoriltotInputs,
        ],
        button:
        {
            onSubmit: sendFormData,
            title: isCreate ? "Үүсгэх" : "Засах",
        },
        validate:
        {
            validateMode: "all",
            yupValidate: songonValidate
        }
    }

    return <BoxBackground title={isCreate ? "Сонгон шалгаруулалт үүсгэх" : "Сонгон шалгаруулалт засах"} body={<Form inputValues={inputValues} form={registerInputs} />} />
}
export default Selection;
