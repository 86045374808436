import React from 'react';
import { useNavigate } from 'react-router-dom';

import CForm from 'components/main/Form';

import useLoader from 'hooks/useLoader';
import useAuth from 'hooks/useAuth';
import useApi from 'hooks/useApi';

import { newPasswordValidate } from 'utils/validate';

const NewPasswords = ({ setActiveStep, mail }) => {
    const passwordApi = useApi().user.password
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })

    const { isLogged } = useAuth()

    const navigate = useNavigate()

    const handleSubmitMail = async (body) =>
    {
        const newBody = {
            password: body.newPassword,
            verify_password: body.confirmPassword,
            email: mail
        }
        const { success, data } = await fetchData(passwordApi.sendPasswords(newBody))
        if(success)
        {
            isLogged
            ?
                setActiveStep(0)
            :
                navigate('/sign/in/')
        }
    }

    const mailForm = {
        inputs: [
            {
                inputType: "input",
                registerName: "newPassword",
                type: "password",
                disabled: isLoading,
                colMd: 12
            },
            {
                inputType: "input",
                registerName: "confirmPassword",
                type: "password",
                placeholder: 'Шинэ нууц үг давтан оруулна уу',
                disabled: isLoading,
                colMd: 12
            },
        ],
        button: {
            onSubmit: (data) => handleSubmitMail(data),
            title: "Шинэ нууц үг оруулах",
            variant: 'main',
            disabled: isLoading,
            isLoading: isLoading,
        },
        validate: {
            validateMode: "all",
            yupValidate: newPasswordValidate,
        }
    }
    return <CForm form={mailForm}/>;
}

export default NewPasswords;
