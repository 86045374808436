import { useEffect, useState } from "react"

export default function TableCheckbox({ row, fieldValue, onChange, name })
{

    const [ checked, setChecked ] = useState(fieldValue)

    const handleChange = (e) =>
    {
        const changedChecked = e.target.checked
        if (onChange)
        {
            onChange(row.id, changedChecked, name)
        }
        setChecked(changedChecked)
    }

    useEffect(
        () =>
        {
            setChecked(fieldValue)
        },
        [fieldValue]
    )

    return (
        <input type="checkbox" className='form-check-input' checked={checked} onChange={handleChange} />
    )
}
