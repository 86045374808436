import React from 'react'
import { Routes, Route } from 'react-router-dom'

import BuildingDetail from './BuildingDetail'

export default function Building()
{
    return (
        <Routes>
            <Route path='/:apartId/:residenceId/' element={<BuildingDetail />} />
        </Routes>
    )
}
