import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import CustomInputGroup from 'components/main/Form/CustomInputGroup';
import Select from 'components/main/Form/InputTypes/Select'

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import { dataToValueLabel } from 'utils/format';

const Building = () => {

    const [ allApart, setAllApart ] = useState([]);

    const navigate = useNavigate();
    const { id: projectId } = useParams()

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })
    const apartmentApi = useApi().apartment

    useEffect(
        async () =>
        {
            const apartmentRsp = await fetchData(apartmentApi.getAll(projectId))
            if(apartmentRsp.success)
            {
                setAllApart(dataToValueLabel(apartmentRsp.data, 'id', 'name'))
            }
        },
        []
    )

    const handleOnSelect = (value) =>
    {
        navigate('apart/' + value.value + '/')
    }

    return (
        <Row>
            <CustomInputGroup label={{empty: true}} text='Apartment сонгох:'>
                <Select options={allApart} onChange={handleOnSelect} />
            </CustomInputGroup>
        </Row>
    );
}

export default Building;
