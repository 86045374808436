import React from 'react';
import { Route, Routes } from 'react-router-dom';

import PropertyTypeAction from './PropertyTypeAction';
import PropertyType from '.';

import BoxBackground from 'components/special/BoxBackground';

const PropertyTypeCrudMain = () => {
    return (
        <Routes>
            <Route path="/*" element={<BoxBackground title='Өмчлөх төрөл' body={<PropertyType/>} />} />
            <Route path="/:id/" element={<PropertyTypeAction/>} />
        </Routes>
    );
}

export default PropertyTypeCrudMain;
