import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap'

import UpdateButton from 'components/main/CudButtons/Update';
import CreateButton from 'components/main/CudButtons/Create';
import OverlayElement from 'components/main/OverlayElement';
import { DESC, ASC } from 'components/main/Table/Thead';
import Datatable from 'components/main/Datatable';

import useCState from 'hooks/useCState';
import useLoader from 'hooks/useLoader';
import useModal from 'hooks/useModal';
import useAuth from 'hooks/useAuth';
import useApi from 'hooks/useApi';

import BuildingImageAction from './BuildingImageAction';
import TableSelect from './TableInputs/TableSelect';
import TableCheckbox from './TableInputs/Checkbox';
import TableInput from './TableInputs/TableInput';
import UpdateMany from './UpdateMany';

import { PERM_IS_FROM_BUILDING, PERM_IS_FROM_PROJECT, PERM_IS_ORG_ADMIN, STATES } from 'utils/consts';
import { dataToValueLabel } from 'utils/format';
import label from 'utils/validate/label';

const initData = {
    id: null,
    door_number: null,
    input_number: null,
    floor: null,
    state: null,
    residence: null,
    is_rent: null,
    description: null
}

const BuildingAction = ({  }) => {

    const [ canEdit, setCanEdit ] = useState(false);
    const [ changedDatas, setChangedDatas ] = useState(initData)
    const [ allRes, setAllRes ] = useState([]);

    const { id: projectId, apartId } = useParams()
    const dataTableRef = useRef(null)
    const navigate = useNavigate()
    const { setDisplay, setShow } = useModal()

    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })
    const { userDetail } = useAuth()
    const residenceApi = useApi().residence
    const buildingApi = useApi().building

    const { checkedList, setCheckedList } = useCState()

    /** Inpiut утга солигдоход утгын авах */
    const handleOnChange = (data) =>
    {
        setChangedDatas({...changedDatas, ...data})
    }

    const initColumns = useMemo(
        () =>
        {
            return [
				{
					field: "custom_door_number",
					order: true,
					fieldComponent: (fieldValue, index, row) => 
                        {
                            return (<TableInput
                                disabled={!(canEdit && changedDatas.id === row.id)}
                                onChange={handleOnChange}
                                value={
                                    changedDatas.id === row.id && typeof changedDatas.custom_door_number === "string"
                                        ? changedDatas.custom_door_number || ""
                                        : fieldValue || ""
                                }
                                valueName="custom_door_number"
                            />)

                        }
					,
                    // searchField: false
				},
				{
					field: "input_number",
					order: true,
					fieldComponent: (fieldValue, index, row) => (
						<TableInput
							disabled={!(canEdit && changedDatas.id === row.id)}
							onChange={handleOnChange}
							value={
								changedDatas.id === row.id && typeof changedDatas.input_number === "string"
									? changedDatas.input_number || ""
									: fieldValue || ""
							}
							valueName="input_number"
						/>
					),
                    // searchField: false
				},
				{
					field: "floor",
					order: true,
					fieldComponent: (fieldValue, index, row) => (
						<TableInput
							disabled={!(canEdit && changedDatas.id === row.id)}
							onChange={handleOnChange}
							value={changedDatas.id === row.id && typeof changedDatas.floor === "string" ? changedDatas.floor || "" : fieldValue || ""}
							valueName="floor"
						/>
					),
                    // searchField: false
				},
				{
					field: "state",
					order: true,
					fieldComponent: (fieldValue, index, row) => (
						<TableSelect
							value={changedDatas.id === row.id && typeof changedDatas.state === "string" ? changedDatas.state : fieldValue}
							disabled={!(canEdit && changedDatas.id === row.id)}
							onChange={handleOnChange}
							options={STATES}
							valueName="state"
						/>
					),
                    filter: {
                        filterOptions: [
                            {
                                value: '2',
                                label: 'Зарагдсан',
                            },
                            {
                                value: '1',
                                label: 'Зарагдаагүй',
                            },
                            {
                                value: '3',
                                label: 'Захиалгат',
                            },
                        ],
                        fieldName: 'c_state'
                    },
                    // searchField: false
				},
				{
					field: "residence",
					order: true,
					fieldComponent: (fieldValue, index, row) => (
						<TableSelect
							value={
								changedDatas.id === row.id && typeof changedDatas.residence === "string"
									? changedDatas.residence
									: fieldValue?.id
									? fieldValue?.id
									: "not"
							}
							disabled={!(canEdit && changedDatas.id === row.id)}
							onChange={handleOnChange}
							options={allRes}
							valueName="residence"
						/>
					),
                    searchField: false
				},
				{
					field: "description",
					order: true,
					fieldComponent: (fieldValue, index, row) => {
						return (
							<TableInput
								disabled={!(canEdit && changedDatas.id === row.id)}
								onChange={handleOnChange}
								isTextArea={canEdit && changedDatas.id === row.id}
								width="180px"
								value={
									changedDatas.id === row.id && typeof changedDatas.description === "string"
										? changedDatas.description || ""
										: fieldValue || ""
								}
								valueName="description"
							/>
						)
					},
                    // searchField: false
				},
                userDetail?.user_role?.is_tosk &&
                {
                    field: "is_rent",
                    order: true,
                    fieldComponent: (fieldValue, index, row) => (
                        <TableCheckbox
                            value={changedDatas.id === row.id && typeof changedDatas.is_rent === "boolean" ? changedDatas.is_rent : fieldValue}
                            disabled={!(canEdit && changedDatas.id === row.id)}
                            onChange={handleOnChange}
                            options={allRes}
                            valueName="is_rent"
                        />
                    ),
                    searchField: false
                }
			]
        },
        [canEdit, allRes, userDetail?.user_role?.is_tosk, changedDatas]
    )

    /** Өөрчлөх дарах үед ажиллах */
    const toggle = (item) =>
    {
        setChangedDatas({
            id: item.id,
            door_number: item.custom_door_number,
            input_number: item.input_number,
            floor: item.floor,
            state: item.state,
            residence: item.residence?.id,
            is_rent: item.is_rent,
            description: item.description
        })
        setCanEdit(true)
    }

    /** update хийхээ болих */
    const cancel = () =>
    {
		setChangedDatas(initData)
		setCanEdit(false)
	}

    const save = async () =>
    {
        const buildingRsp = await fetchData(buildingApi.put(projectId, apartId, changedDatas.id, changedDatas)).catch( err => err )
        if(buildingRsp.success)
        {
            getData()
            setCanEdit(false)
            setChangedDatas(initData)
        }
    }

    /** Modal гаргаж ирэх funct */
    const displayImageAction = (id) =>
    {
        setDisplay(
            {
                header:
                {
                    title: label['building'] + ' зураг оруулах',
                },
                body: <BuildingImageAction projectId={projectId} buildingId={id} />,
                size: "xl",
                footer:
                {
                    show:true
                },
                variant: 'main'
            }
        )
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        {
                            changedDatas.id === item.id && canEdit
                            ?
                                <>
                                    <i onClick={ () => save() } className="fs-15 text-success far fa-check me-1 cursorPointer"></i>
                                    <i onClick={ () => cancel() } className="fs-15 text-danger fal fa-times cursorPointer"></i>
                                </>
                            :
                                <>
                                    <UpdateButton className='me-2 text-darkBlue fs-12' onClick={() => toggle(item)} needRoles={[PERM_IS_ORG_ADMIN, PERM_IS_FROM_BUILDING, PERM_IS_FROM_PROJECT]}/>
                                    <OverlayElement
                                        text='Зураг оруулах'
                                    >
                                        <div className='cursorPointer me-2' style={{ color: 'purple' }} onClick={ () => displayImageAction(item.id) }><i className="far fa-image"></i></div>
                                    </OverlayElement>
                                </>

                        }
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    useEffect(
        async () =>
        {
            const residenceRsp = await fetchData(residenceApi.getAll(projectId, apartId))
            if(residenceRsp.success)
            {
                setAllRes(dataToValueLabel(residenceRsp.data, 'id', 'full_name'))
            }
        },
        []
    )

    const getData = () =>
    {
        dataTableRef.current.firstGet = true
        dataTableRef.current.reData({}, null, [projectId, apartId])
    }

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    const handleClick= (data) =>
    {
        setDisplay(
            {
                header: {
                    title: "Олноор нь өөрчлөх",
                },
                body: <UpdateMany getData={getData} projectId={projectId} apartId={apartId} setShow={setShow}/>,
                size: "xl",
            }
        )
    }

    const checkBoxOptions = {
        show: true,
        onClick: () => handleClick(),
        btnText: `Сонгогдсон утгуудыг засах (${checkedList.isAll && checkedList.unChecked.length === 0 ? 'бүгд' : checkedList.checked.length === 0 ? checkedList.total - checkedList.unChecked.length : checkedList.checked.length})`,
        rowsElement: 'id'
    }

    return (
        <Row>
            <Col sm={12}>
                <CreateButton btnClassName='floatRight mt-2 mb-2' onClick={() => navigate('create/')} needRoles={[PERM_IS_ORG_ADMIN, PERM_IS_FROM_BUILDING, PERM_IS_FROM_PROJECT]}/>
            </Col>
            <Col>
                <Datatable
                    dRef={dataTableRef}
                    columns={initColumns.filter(element => typeof element === 'object') }
                    sequenceColumn={sequenceColumn}
                    extraColumns={extraColumns}
                    checkBoxOptions={checkBoxOptions}
                    request={
                        {
                            axios: buildingApi.get,
                            params: [projectId, apartId]
                        }
                    }
                    tableOptions={
                        {
                            defaultSort: {
                                type: ASC,
                                field: "custom_door_number"
                            }
                        }
                    }
                    isFirstGetData={true}
                />
            </Col>
        </Row>
    );
}

export default BuildingAction;
