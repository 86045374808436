import { useEffect } from 'react';
import { useRef } from 'react';
import Form from 'react-bootstrap/Form';
import CButton from 'components/main/Button';

import useToast from 'hooks/useToast';
import useModal from 'hooks/useModal';
import useMobile from 'hooks/useMobile';

import { getCookie } from 'utils/browser';

import AlertBody from './Alert';

import useMapContext from 'hooks/useMapContext';
import useApi from 'hooks/useApi';

import { lifeStyleStyle, LIFESTYLE_ORG_IMAGE } from 'hooks/map/helper';

export default function LifeStyle()
{

    const COOKIE_NAME = 'dont_show_map_point'
    const MAX_RADIUS = 5000
    const DEFAULT_VALUE = 1000

    const lifeStyleApi = useApi().lifeStyle

    const raduisRef = useRef()

    const hospitalRef = useRef()
    const schoolRef = useRef()
    const emiinSanRef = useRef()
    const kinderGardenRef = useRef()
    const { isMobile } = useMobile()

    let beforeBuildType = []

    const { addToast } = useToast()
    const { setDisplay } = useModal()
    const { cMapState, allFilters } = useMapContext()
    let realValue = DEFAULT_VALUE

    const lifeStyles = [
        {
            label: 'Сургууль',
            value: 1,
            ref: schoolRef,
            defaultChecked: false,
            name: "school.kml",
        },
        {
            label: 'Эмийн сан',
            value: 2,
            ref: emiinSanRef,
            defaultChecked: false,
            name: "emiinsan.kml",
        },
        {
            label: 'Эмнэлэг',
            value: 3,
            ref: hospitalRef,
            defaultChecked: true,
            name: "hospital.kml",
        },
        {
            label: 'Цэцэрлэг',
            value: 4,
            ref: kinderGardenRef,
            defaultChecked: false,
            name: "kinder_garden.kml",
        },
    ]

    useEffect(
        () =>
        {
            allFilters['building_type'] = getTypeValue()
            buildTypeOnMap()
            return () =>
            {
                handleClear(true)
            }
        },
        []
    )

    /** Газрын зурган дээр дарах үед цэгийг харуулж radius татах */
    const setMapClick = (event) =>
    {
        cMapState.removeLayer('radius')

        const radius = raduisRef.current.value
        const coordinates = event.coordinate

        const { feature } = cMapState.createRadius(coordinates, radius)
        cMapState.changeCursor("default")

        allFilters['radius'] = radius
        allFilters['coordinate'] = coordinates
        radiusOnMap(undefined, undefined, feature)
    }

    /** Барилгын төрлийн утгыг ref ээс авах нь */
    const getTypeValue = () =>
    {
        let checked = []
        lifeStyles.map(
            (lifeStyle, idx) =>
            {
                if (lifeStyle.ref.current.checked)
                {
                    checked.push(lifeStyle.ref.current.value)
                }
            }
        )
        return checked
    }

    /** Зөвхөн барилгын төрөл ийн feature дээр дарах үед */
    const handleClickCount = (event) =>
    {
        const hasSelected = event.selected.length
        const hasDeSelected = event.deselected.length
        if (hasDeSelected && !hasSelected)
        {
            cMapState.displayOverlay(undefined)
        }

        if (hasSelected)
        {
            let extent = event.selected[0].getGeometry().getExtent();
            let center = cMapState.getCenterFromExtent(extent);
            cMapState.displayOverlay(center)
        }
    }

    const buildTypeOnMap = () =>
    {
        cMapState.displayOverlay(undefined)
        const layerName = "lifeStyle"
        const ext = '.kml'
        const mediaUrl = `${process.env.REACT_APP_SERVER_PUBLIC_URL}/media/kml/life`

        const checkedTypes = allFilters['building_type']
        const subName = checkedTypes.join("_")
        const beforeLayerName = `${layerName}-${beforeBuildType.join("_")}`

        const extraLayerName = `${layerName}-${subName}`

        let foundLayer = false
        cMapState
            .map
            .getLayers()
            .forEach(
                (layer) =>
                {
                    const lName = layer.get("name")
                    if (layer && lName && lName === beforeLayerName)
                    {
                        // нуух
                        layer.setVisible(false)
                    }
                    if (layer && lName && lName === extraLayerName)
                    {
                        // харуулах
                        layer.setVisible(true)
                        foundLayer = true
                    }
                }
            )

        if (!subName || foundLayer)
        {
            return
        }

        const au1Url = `${mediaUrl}/AU1/${subName}${ext}`
        const au2Url = `${mediaUrl}/AU2/${subName}${ext}`
        const au3Url = `${mediaUrl}/AU3/${subName}${ext}`
        const { vectorSource: au1Source, vectorLayer: au1Layer } = cMapState.createKMLLayer(au1Url, extraLayerName, { style: (...args) => lifeStyleStyle(checkedTypes, ...args), minZoom: 1, maxZoom: 12 })
        const { vectorSource: au2Source, vectorLayer: au2Layer } = cMapState.createKMLLayer(au2Url, extraLayerName, { style: (...args) => lifeStyleStyle(checkedTypes, ...args), minZoom: 12, maxZoom: 16 })
        const { vectorSource: au3Source, vectorLayer: au3Layer } = cMapState.createKMLLayer(au3Url, extraLayerName, { style: (...args) => lifeStyleStyle(checkedTypes, ...args), minZoom: 16, maxZoom: 22 })
        // const selectLayers = [au1Layer, au2Layer, au3Layer]
        // cMapState.createSelect(
        //     isMobile ? "click" : 'hover',
        //     handleClickCount,
        //     null,
        //     selectLayers,
        //     [],
        //     'lifeStyle'
        // )
    }

    /** Хайлтыг газрын зурган дээр харуулах */
    const radiusOnMap = async (checked, buildType, feature) =>
    {
        cMapState.removeLayer('radius-circle')
        const wmsURL = `${process.env.REACT_APP_GEOSEVER_URL}oronsuuts/wms`

        const types = allFilters['building_type']
        let propertyFilter = `type in (${types.join(",")})`

        const coords = allFilters['coordinate']
        const newRadius = cMapState.calcRadius(coords, allFilters['radius'])

        const [ x, y ] = coords
        const clqFilter = `DWITHIN(geom,Point(${x} ${y}),${newRadius},meters) and ${propertyFilter}`
        const layer = cMapState.addWMSLayer(wmsURL, "oronsuuts:orgs_view3857", { name: `radius-circle`, clqFilter })
    }

    /** Барилгын төрөл солигдох үед газрын зурган дээрх давхаргыг солих  */
    const handleOnChange = (event) =>
    {
        beforeBuildType = allFilters['building_type']
        let value = getTypeValue()
        allFilters['building_type'] = value
        buildTypeOnMap()
        if (allFilters['coordinate'])
        {
            radiusOnMap(event.target.checked, event.target.value)
        }
    }

    /** validate шалгах
     * Утгыг солих үед газрын зурган дээр хайсан утгыг арилгах
    */
    const handleRadius = (event) =>
    {
        const radius = raduisRef.current.valueAsNumber
        if (MAX_RADIUS < radius)
        {
            addToast(
                {
                    text: "0 оос 5000 ийн хооронд утга оруулна уу",
                    type: "warning"
                }
            )
            realValue = realValue
            raduisRef.current.value = realValue
            return
        }
        realValue = radius
    }

    /** Modal аар газрын зургаас цэг сонгох анхааруулыгн msg харуулах
     * Газрын зургаас цэг хатгах боломжтой болгох */
    const handleClickMapBtn = () =>
    {
        const hasCookie = getCookie(COOKIE_NAME)
        if (!hasCookie || hasCookie === "false")
        {
            setDisplay(
                {
                    header: {
                        title: "Анхааруулага",
                    },
                    body: <AlertBody cookieName={COOKIE_NAME}/>,
                    footer: {
                        show: false,
                    },
                }
            )
        }

        // NOTE:  Газрын зургаас цэг сонгодгыг идэвхжүүлэх
        cMapState.changeCursor("pointer")
        cMapState.map.once("click", setMapClick)

    }

    const handleClear = (isMount) =>
    {
        cMapState.changeCursor("default")
        cMapState.removeLayer('radius')
        cMapState.removeLayer('radius-circle')
        if (isMount !== true)
        {
            allFilters['radius'] = DEFAULT_VALUE
            allFilters['coordinate'] = null
        }
        if (raduisRef.current)
        {
            raduisRef.current.value = DEFAULT_VALUE
        }
    }

    return (
        <div>
            <h6>Барилгын төрөл</h6>
            <div className='row'>
                {
                    lifeStyles.map(
                        (lifeStyle, idx) =>
                        {
                            return (
                                <div className="mb-1 col-3" key={idx}>
                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <label htmlFor={`building-${lifeStyle.value}`} className="form-label">
                                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                                <img src={LIFESTYLE_ORG_IMAGE[lifeStyle.value]} width="50" height="50" alt="" />
                                                <span>{lifeStyle.label}</span>
                                            </div>
                                        </label>
                                        <div>
                                            <input
                                                id={`building-${lifeStyle.value}`}
                                                type="checkbox"
                                                name='building'
                                                className='form-check-input'
                                                value={lifeStyle.value}
                                                ref={lifeStyle.ref}
                                                onChange={handleOnChange}
                                                defaultChecked={lifeStyle.defaultChecked}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    )
                }
            </div>

            <hr />

            <h6>Радиус</h6>
            <div className='d-flex align-items-center'>
                <input ref={raduisRef} defaultValue={DEFAULT_VALUE} type={'number'} className='form-control' style={{ width: "60px" }} onChange={handleRadius} />
                <div>
                    m
                </div>
            </div>

            <div className='mt-2'>
                <CButton
                    variant="main"
                    style={{border: "1px solid transparent"}}
                    onClick={handleClickMapBtn}
                >
                    Газрын зургаас сонгох
                </CButton>
                <CButton
                    variant="secondary"
                    className="ms-2"
                    onClick={handleClear}
                >
                    Болиулах
                </CButton>
            </div>

            <hr />

        </div>
    )
}
