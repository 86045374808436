import React, { useEffect, useMemo, useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { Navigate, Route, Routes } from 'react-router-dom';

import MainNavLink from 'components/main/NavLink';

import useApi from 'hooks/useApi';
import useLoader from 'hooks/useLoader';

import LegalList from '../LegalList';
import TabSkeLoader from './TabSkeLoader';

import { RULES_PAGE_NAME } from 'utils/consts';

import css from '../style.module.css'

const TabContent = () => {
	const [ legals, setLegals ] = useState([])

	const { isLoading, Loader, fetchData } = useLoader({ isSmall:false })
	const newsApi = useApi().news.page

	const getAllLegal = async () =>
	{
		// TODO: legal ийн жагсаалтыг өгдөг API гаргах
		const { success, data } = await fetchData(newsApi.getAll(RULES_PAGE_NAME))
		if(success)
		{
			setLegals(data)
		}
	}

	useEffect(
		() =>
		{
			getAllLegal()
		},
		[]
	)

	const displayTabNavs = useMemo(
		() =>
		{
			return legals.map(
				(element, index) =>
				{
					return (
						<Nav.Item key={index}>
							<MainNavLink className={css.navLinkitem} activeClassName={css.navLinkActive} exact to={`/${RULES_PAGE_NAME}/${element.code}/`}>
								<div className="d-flex text-start align-items-center">
									{element.page_name} <i className="fas fa-chevron-right tx-blue ms-auto" />
								</div>
							</MainNavLink>
						</Nav.Item>
					)
				}
			)
		},
		[legals]
	)

	const exactRoute = useMemo(
		() =>
		{
			if(legals.length === 0) return
			return <Route path='/' element={<Navigate to={ legals[0].code + '/'} />} />
		},
		[legals]
	)

    return (
        <Col>
            <Tab.Container>
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className={`flex-column ${css.legalTabs}`}>
                            {!isLoading && displayTabNavs}
							{isLoading && <TabSkeLoader/>}
                        </Nav>
                    </Col>
                    <Col sm={9}>
						<Routes>
							<Route path='/:pageCode/' element={<LegalList/>} />
							{exactRoute}
						</Routes>
                    </Col>
                </Row>
            </Tab.Container>
        </Col>
    );
}

export default TabContent;
