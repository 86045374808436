import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import CreateButton from 'components/main/CudButtons/Create';
import DeleteButton from 'components/main/CudButtons/Delete';
import UpdateButton from 'components/main/CudButtons/Update';
import CTable from 'components/main/Table';

import useLoader from 'hooks/useLoader';
import useModal from 'hooks/useModal';
import useApi from 'hooks/useApi';

import { moneyFormat, timeZoneToDateString } from 'utils/format';
import GetOneDetail from 'components/special/GetOneDetail';

import { govPageRoles } from 'utils/consts/roles';
import label from 'utils/validate/label';

const Agenda = () => {

    const [ rows, setRows ] = useState([]);
    const navigate = useNavigate();
    const params = useParams()
    const { programId } = params

    const agendaApi = useApi().agenda
    const { isLoading, Loader, fetchData } = useLoader({ isSmall: false })
    const { setDisplay } = useModal()

    /** Modal гаргаж ирэх funct */
    const displayDetail = (id) =>
    {
        setDisplay(
            {
                header:
                {
                    title: label['agenda'] + " дэлгэрэнгүй",
                },
                body: <GetOneDetail api={agendaApi.getOne(programId ,id)}/>,
                size: "lg",
                footer:
                {
                    show:false
                }
            }
        )
    }

    /** Хүснэгтийн багана */
    const columns =
    [
        {
            field: "agenda",
            order: false,
            width: 800,
            minWidth: 800,
            maxWidth: 800,
        },
        {
            field: "amount",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{moneyFormat(fieldValue)}</span>,
        },
        {
            field: "result",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{fieldValue}</span>
        },
        {
            field: "created_at",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue)}</span>
        },
        {
            field: "updated_at",
            order: false,
            fieldComponent: (fieldValue, index, row) => <span>{timeZoneToDateString(fieldValue)}</span>
        },
    ]

    const sequenceColumn =
    {
        headerName: "#",
        startNumber: 1,
        order: false,
        width: 50
    }

    /** Хүснэгтний утгыг авах */
    const getData = async () =>
    {
        const { success, data } = await fetchData(agendaApi.get(programId))
        if(success)
        {
            setRows(data)
        }
    }

    // back руу хүсэлт шидэх
    useEffect(
        () =>
        {
            getData()
        },
        []
    )

    /** Хүснэгтийн мөрийн утгыг устгах */
    const deleteRow = async (id) =>
    {
        await fetchData(agendaApi.delete(programId, id))
        await getData()
    }

    /** Хүснэгтний үйлдэл */
    const extraColumns =
    [
        {
            order: false,
            field: (item, idx) =>
            {
                return (
                    <div className='all-center'>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 0, hide: 0 }}
                            overlay={<Tooltip id="button-tooltip-2">Шалгуур үзүүлэлт харах</Tooltip>}
                        >
                            <div className='me-2' onClick={() => navigate(`/gov/projectprogram/program/${programId}/detail/subAgenda/${item.id}/`)} style={{ cursor: 'pointer' }}>
                                <i className={`far fa-arrow-right`}></i>
                            </div>
                        </OverlayTrigger>
                        <UpdateButton className='me-2' onClick={() => navigate(`${item.id}/`)} needRoles={govPageRoles} />
                        <DeleteButton onClick={() => deleteRow(item.id)} needRoles={govPageRoles} />
                    </div>
                )
            },
            headerName: "Үйлдэл",
        }
    ]

    return (
        <Row>
            <Col sm={12}>
                <CreateButton btnClassName='floatRight' onClick={() => navigate('create/')} needRoles={govPageRoles} />
            </Col>
            <Col>
                <CTable
                    isLoading={isLoading}
                    columns={columns}
                    sequenceColumn={sequenceColumn}
                    extraColumns={extraColumns}
                    rows={rows}
                />
            </Col>
        </Row>
    );
}

export default Agenda;
