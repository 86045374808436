import CButton from 'components/main/Button';
import Sidebar from 'components/main/Sidebar';
import React from 'react';
import { Container } from 'react-bootstrap';
import { NavLink, Route, Routes } from 'react-router-dom';

import TestForm from './TestForm';
import TestPage from './TestPage';
import TestTable from './TestTable';
import TestToast from './TestToast';
import TestComponent from './TestComponent';
import TestDataTableFilter from './TestDataTableFilter';
import TestReactUse from './TestReactUse';
import TestTransitionGroup from './TestTransitionGroup';
import TestSelectItem from './TestSelectItem';
import TestLineSlider from './TestLineSlider';
import TestSlowInput from './TestSlowInput';

const Test = ({ togglerState }) => {
    return (
        <Container className="p-0" fluid>
            <CButton style={{ color: 'red'}} className='me-3' >
                <NavLink to={"table"}> <span style={{ color: 'red'}}>table</span> </NavLink>
            </CButton>
            <CButton  style={{ color: 'red'}} className='me-3' >
                <NavLink to={"form"}> <span style={{ color: 'red'}}>form</span> </NavLink>
            </CButton>
            <CButton  style={{ color: 'red'}} className='me-3' >
                <NavLink to={"page"}> <span style={{ color: 'red'}}>page</span> </NavLink>
            </CButton>
            <CButton  style={{ color: 'red'}} className='me-3' >
                <NavLink to={"toast"}> <span style={{ color: 'red'}}>toast</span> </NavLink>
            </CButton>
            <CButton  style={{ color: 'red'}} className='me-3' >
                <NavLink to={"component"}> <span style={{ color: 'red'}}>component</span> </NavLink>
            </CButton>
            <CButton  style={{ color: 'red'}} className='me-3' >
                <NavLink to={"delgerengvi"}> <span style={{ color: 'red'}}>DELGERENGVI</span> </NavLink>
            </CButton>
            <CButton  style={{ color: 'red'}} className='me-3' >
                <NavLink to={"datatalbeFilter"}> <span style={{ color: 'red'}}>datatalbeFilter</span> </NavLink>
            </CButton>
            <CButton  style={{ color: 'red'}} className='me-3' >
                <NavLink to={"reactuse"}> <span style={{ color: 'red'}}>reactuse</span> </NavLink>
            </CButton>
            <CButton  style={{ color: 'red'}} className='me-3' >
                <NavLink to={"transitiongroup"}> <span style={{ color: 'red'}}>transitiongroup</span> </NavLink>
            </CButton>
            <CButton  className='me-3' >
                <NavLink to={"selectItem"}> SELECT ITEM </NavLink>
            </CButton>
            <CButton  className='me-3' >
                <NavLink to={"LineSlider"}>LineSlider </NavLink>
            </CButton>
            <CButton  className='me-3' >
                <NavLink to={"SlowInput"}>SlowInput </NavLink>
            </CButton>
            <Routes>
                <Route path="/form/*" element={<TestForm />} />
                <Route path="/table/*" element={<TestTable />} />
                <Route path="/page/*" element={<TestPage />} />
                <Route path="/toast/*" element={<TestToast />} />
                <Route path="/component/*" element={<TestComponent />} />
                <Route path="/datatalbeFilter/*" element={<TestDataTableFilter />} />
                <Route path="/reactuse/*" element={<TestReactUse />} />
                <Route path="/transitiongroup/*" element={<TestTransitionGroup />} />
                <Route path="/selectItem/*" element={<TestSelectItem />}/>
                <Route path="/LineSlider/*" element={<TestLineSlider />}/>
                <Route path="/SlowInput/*" element={<TestSlowInput />}/>
            </Routes>
        </Container>
    );
}

export default Test;
