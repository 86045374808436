import React from 'react';

import Layout from 'components/special/Layout';

import Links from './routes';
import useRole from 'hooks/useRole';

import { PERM_READ } from 'utils/consts';
import { adminForNews } from 'utils/consts/roles';

const Index = () => {

    const hasNewsPerm = useRole(adminForNews, PERM_READ)
    if (hasNewsPerm)
    {
        Links[0]['firstActive'] = true
        Links[1]['firstActive'] = false
    }
    else {
        Links[0]['firstActive'] = false
        Links[1]['firstActive'] = true
    }

    return (
        <Layout sidebarTitle='ҮНДСЭН ЦЭС' links={Links} />
    );
}

export default Index;
